import { createReducer } from '@reduxjs/toolkit'

import {
  addFarm,
  changeLockTime,
  resetFarmInputs,
  resetFarmsState,
  setFarms,
  setLoaded,
  setLoading,
  typeInput,
} from './actions'

const DEFAULT_LOCK_TIME = 3600 * 24 * 30 // 30 days

export type Farm = {
  address: string
  stakingToken: string
  rewardTokens: string[]
  lockTimeMin: number
  lockTimeMax: number
  lockMultiplierMax: number
  totalLiquidityLocked: string
  rewardsPerDuration: string[]
  rewardsDuration: string
}
export type FarmsState = {
  farms: Farm[]
  loaded: boolean
  loading: boolean
  inputValue: string
  lockTimeValue: number
  lockTimeMultiplier: number | null
}

export const initalFarmsState: FarmsState = {
  farms: [],
  loaded: false,
  loading: false,
  inputValue: '',
  lockTimeValue: DEFAULT_LOCK_TIME,
  lockTimeMultiplier: null,
}

export default createReducer<FarmsState>(initalFarmsState, (builder) =>
  builder
    .addCase(resetFarmsState, () => initalFarmsState)
    .addCase(resetFarmInputs, (state) => {
      return {
        ...state,
        loaded: false,
        loading: false,
        inputValue: '',
        lockTimeValue: DEFAULT_LOCK_TIME,
        lockTimeMultiplier: null,
      }
    })
    .addCase(addFarm, (state, { payload }) => {
      const farms = state.farms.filter((farm) => farm.address !== payload.address)
      return {
        ...state,
        farms: [...farms, payload],
      }
    })
    .addCase(setFarms, (state, { payload }) => ({
      ...state,
      farms: payload,
      loaded: true,
    }))
    .addCase(setLoaded, (state) => ({
      ...state,
      loaded: true,
      loading: false,
    }))
    .addCase(setLoading, (state, { payload }) => ({
      ...state,
      loading: payload,
    }))
    .addCase(typeInput, (state, { payload }) => ({
      ...state,
      inputValue: payload,
    }))
    .addCase(changeLockTime, (state, { payload }) => ({
      ...state,
      lockTimeValue: payload,
    }))
)
