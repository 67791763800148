import { useWeb3React } from '@web3-react/core'
import AddressClaimModal from 'components/claim/AddressClaimModal'
import ConnectedAccountBlocked from 'components/ConnectedAccountBlocked'
import FirstTimeUserModal from 'components/FirstTimeUserModal'
import useAccountRiskCheck from 'hooks/useAccountRiskCheck'
import { useState, useEffect } from 'react'
import { useModalIsOpen, useToggleModal } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'

export default function TopLevelModals() {
  const addressClaimOpen = useModalIsOpen(ApplicationModal.ADDRESS_CLAIM)
  const addressClaimToggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM)

  const firstTimeUserOpen = useModalIsOpen(ApplicationModal.FIRST_TIME_USER)
  const firstTimeUserToggle = useToggleModal(ApplicationModal.FIRST_TIME_USER)

  const blockedAccountModalOpen = useModalIsOpen(ApplicationModal.BLOCKED_ACCOUNT)
  const { account } = useWeb3React()

  const [isFirstTimeUser, setIsFirstTimeUser] = useState(false)

  useEffect(() => {
    const firstTimeCheck = localStorage.getItem('firstTimeUser')
    if (firstTimeCheck === null) {
      setIsFirstTimeUser(true)
    }
  }, [])

  const handleAcceptTerms = () => {
    localStorage.setItem('firstTimeUser', 'false')
    setIsFirstTimeUser(false)
  }

  // useAccountRiskCheck(account)
  const open = Boolean(blockedAccountModalOpen && account)
  return (
    <>
      {isFirstTimeUser && (
        <FirstTimeUserModal isOpen={isFirstTimeUser} onAccept={handleAcceptTerms} onDismiss={firstTimeUserToggle} />
      )}
      <AddressClaimModal isOpen={addressClaimOpen} onDismiss={addressClaimToggle} />
      <ConnectedAccountBlocked account={account} isOpen={open} />
    </>
  )
}
