import { createContext, useContext, useState } from 'react'

type StepLineContextType = {
  state: { [key: string]: any }
  setState: (state: { [key: string]: any }) => void
  updateState: (state: { [key: string]: any }) => void
  clearState: () => void
}

const StepLineContext = createContext<StepLineContextType>({
  state: {},
  setState: () => {
    throw new Error(`StepLineContext must be used within a StepLineContextProvider`)
  },
  updateState: () => {
    throw new Error(`StepLineContext must be used within a StepLineContextProvider`)
  },
  clearState: () => {
    throw new Error(`StepLineContext must be used within a StepLineContextProvider`)
  },
})

export function useStepLineState<T extends object = { [key: string]: any }>(): Partial<T> {
  const context = useContext(StepLineContext)
  return context.state as Partial<T>
}

export function useStepLineStateUpdate<T extends object = { [key: string]: any }>() {
  const context = useContext(StepLineContext)
  return context.updateState as unknown as (state: Partial<T>) => void
}

export function useStepLineStateClean() {
  const context = useContext(StepLineContext)
  return context.clearState
}

export function useStepLineStateSet<T extends object = { [key: string]: any }>() {
  const context = useContext(StepLineContext)
  return context.setState as unknown as (state: Partial<T>) => void
}

export const StepLineContextProvider = ({ children }: { children: any }) => {
  const [state, setState] = useState<StepLineContextType['state']>({})

  const updateState = (newState: StepLineContextType['state']) => {
    setState((prevState) => {
      if (prevState.__allowChangeState || newState.__allowChangeState) {
        return { ...prevState, ...newState }
      }
      return prevState
    })
  }

  const clearState = () => {
    setState({})
  }

  return (
    <StepLineContext.Provider value={{ state, setState, updateState, clearState }}>{children}</StepLineContext.Provider>
  )
}

export default StepLineContextProvider
