import { ElementName, Event, EventName, PageName } from 'components/AmplitudeAnalytics/constants'
import { Trace } from 'components/AmplitudeAnalytics/Trace'
import { TraceEvent } from 'components/AmplitudeAnalytics/TraceEvent'
import { ButtonPrimary, ButtonText } from 'components/Button'
import PositionList from 'components/PositionList'
import HeaderRow from 'components/PositionList/HeaderRow'
import { PositionListFooter } from 'components/PositionList/styled'
import { useV3Positions } from 'hooks/useV3Positions'
import { useContext, useMemo } from 'react'
import { AlertTriangle } from 'react-feather'
import { Link } from 'react-router-dom'
import { useUserHideClosedPositions } from 'state/user/hooks'
import styled, { css, ThemeContext } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { PositionDetails } from 'types/position'

import { ReactComponent as Pools } from '../../../../assets/svg/icons/Pools.svg'
import { LoadingRows } from '../../Liquidity.styles'

export const MainContentWrapper = styled.main`
  background-color: ${({ theme }) => theme.cardPrimary};
  /* padding: 16px; */
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09);

  width: 100%;
`

const Wrapper = styled.div`
  width: 100%;
`

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 24px 0;
  max-width: 300px;
  /* min-height: 25vh; */
`

const IconStyle = css`
  width: 40px;
  height: 40px;
  margin-bottom: 0.5rem;
`

export const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  padding: 6px 16px;
  border: none;
  width: fit-content;

  background-color: transparent;

  border-radius: 4px;
  border: 1px solid #64748b;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex: 1 1 auto;
    width: 100%;
  `};

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  &:hover {
    background: ${({ theme }) => theme.stateOverlayHover};
  }
`
const NetworkIcon = styled(AlertTriangle)`
  ${IconStyle}
`

const InboxIcon = styled(Pools)`
  ${IconStyle}
`

function PositionsLoadingPlaceholder() {
  return (
    <LoadingRows>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </LoadingRows>
  )
}

export default function PoolV3({
  account,
  chainId,
  toggleWalletModal,
  searchParam,
}: {
  account?: string | null
  chainId?: number | null
  toggleWalletModal?: () => void
  searchParam?: string | null
}) {
  //   const { account, chainId } = useWeb3React()
  //   const toggleWalletModal = useToggleWalletModal()

  const theme = useContext(ThemeContext)
  const [userHideClosedPositions, setUserHideClosedPositions] = useUserHideClosedPositions()
  // const [gridMode, setGridMode] = useState(false)

  const { positions, loading: positionsLoading } = useV3Positions(account)

  const [openPositions, closedPositions] = positions?.reduce<[PositionDetails[], PositionDetails[]]>(
    (acc, p) => {
      acc[p.liquidity?.isZero() ? 1 : 0].push(p)
      return acc
    },
    [[], []]
  ) ?? [[], []]

  // Filter function
  const filterPositions = (position: PositionDetails) => {
    return position.token0 === searchParam || position.token1 === searchParam
  }

  // const filteredPositions = [...openPositions, ...(userHideClosedPositions ? [] : closedPositions)]
  // Apply filter based on searchParam
  const filteredPositions = useMemo(() => {
    return searchParam && searchParam.length === 42
      ? [...openPositions, ...(userHideClosedPositions ? [] : closedPositions)].filter(filterPositions)
      : [...openPositions, ...(userHideClosedPositions ? [] : closedPositions)]
  }, [openPositions, closedPositions, userHideClosedPositions, searchParam])

  const showConnectAWallet = Boolean(!account)

  return (
    <Trace page={PageName.POOL_PAGE} shouldLogImpression>
      <>
        <Wrapper>
          {positionsLoading ? (
            <PositionsLoadingPlaceholder />
          ) : filteredPositions && closedPositions && filteredPositions.length > 0 ? (
            <PositionList
              positions={filteredPositions}
              setUserHideClosedPositions={setUserHideClosedPositions}
              userHideClosedPositions={userHideClosedPositions}
            />
          ) : (
            <MainContentWrapper>
              <HeaderRow />
              <ErrorContainer>
                <ThemedText.DeprecatedBody color={theme.deprecated_text3} textAlign="center">
                  <InboxIcon strokeWidth={1} />
                  <div>
                    <>Your active V3 liquidity positions will appear here.</>
                  </div>
                </ThemedText.DeprecatedBody>
                {!showConnectAWallet && closedPositions.length > 0 && (
                  <ButtonText
                    style={{ marginTop: '.5rem' }}
                    onClick={() => setUserHideClosedPositions(!userHideClosedPositions)}
                  >
                    <>Show closed positions</>
                  </ButtonText>
                )}
                {showConnectAWallet && (
                  <TraceEvent
                    events={[Event.onClick]}
                    name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
                    properties={{ received_swap_quote: false }}
                    element={ElementName.CONNECT_WALLET_BUTTON}
                  >
                    <ButtonPrimary
                      style={{ marginTop: '1em', padding: '8px 16px', width: '140px' }}
                      onClick={toggleWalletModal}
                    >
                      <>Connect wallet</>
                    </ButtonPrimary>
                  </TraceEvent>
                )}
              </ErrorContainer>
              <PositionListFooter>
                <ResponsiveButtonPrimary
                  data-cy="join-pool-button"
                  id="join-pool-button"
                  as={Link}
                  to="/liquidity/v3/add/ETH"
                >
                  New Position
                </ResponsiveButtonPrimary>
              </PositionListFooter>
            </MainContentWrapper>
          )}
        </Wrapper>
      </>
    </Trace>
  )
}
