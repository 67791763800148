import { useWeb3React } from '@web3-react/core'
import { ButtonError } from 'components/Button'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { useApproveStep } from 'components/StepLine/common/ApproveStep'
import useStepWithNext from 'components/StepLine/context/hooks/useStepWithNext'
import StepLineModal from 'components/StepLine/StepLineModal'
import { useToken } from 'hooks/Tokens'
import { useCommunalFarmByIsland } from 'hooks/useCommunalFarms'
import { useFarmContract } from 'hooks/useContract'
import { useTokenBalance } from 'lib/hooks/useCurrencyBalance'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { useMemo, useState } from 'react'

import { IslandContextType } from '../context/IslandContextType'
import { useOnStake } from './hooks/useOnStakeCallback'
import { useSelectLockTimeStep } from './steps/SelectLockTimeStep'
import StakeStep from './steps/StakeStep'
const StakeView = ({ islandId }: IslandContextType) => {
  const [value, setValue] = useState('')
  const token = useToken(islandId)

  const { account } = useWeb3React()
  const balance = useTokenBalance(account, token || undefined)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const farm = useCommunalFarmByIsland(islandId)
  const farmContract = useFarmContract(farm?.address)

  const lockTimeSelectStep = useSelectLockTimeStep(islandId, value)

  const typedValue = useMemo(() => tryParseCurrencyAmount(value, token || undefined), [value, token])
  const isError = useMemo(() => typedValue && balance?.lessThan(typedValue), [typedValue])

  const approveStep = useApproveStep(typedValue, farm?.address, true)
  const { onStake, attemptingTxnStake, txHashStake, clear } = useOnStake(typedValue, farmContract)

  const onFinish = () => {
    clear()
    setIsConfirmationOpen(false)
    setValue('')
  }

  const stakeStep = useStepWithNext('StakeStep', StakeStep, {
    amount: typedValue,
    onStake,
    hash: txHashStake,
    attemptingTxn: attemptingTxnStake,
    pendingText: (
      <>
        Stake {typedValue?.toSignificant(6)} {typedValue?.currency.symbol}
      </>
    ),
    transactionWaiting: true,
    autoConfirm: true,
  })

  const steps: Step[] = [approveStep, lockTimeSelectStep, stakeStep]

  return (
    <>
      <StepLineModal
        isOpen={isConfirmationOpen}
        onDismiss={onFinish}
        onFinish={onFinish}
        header={<>Stake</>}
        steps={steps}
      />
      <CurrencyInputPanel
        value={value}
        onUserInput={setValue}
        onMax={() => {
          setValue(balance?.toExact() || '')
        }}
        // onCurrencySelect={handleCurrencyASelect}
        showMaxButton={true}
        currency={token}
        id="add-liquidity-input-tokena"
        showCommonBases
      />
      <ButtonError
        onClick={() => setIsConfirmationOpen(true)}
        disabled={isError || !value || parseFloat(value) === 0}
        error={isError}
      >
        {isError ? 'Insufficient balance' : 'Stake'}
      </ButtonError>
    </>
  )
}

export default StakeView
