import { NumberValue } from 'd3' // Import the NumberValue type
import { dayHourFormatter, monthDayFormatter, monthYearDayFormatter } from 'utils/formatChartTimes'

export type SnapshotType = {
  id: string
  timestamp: string | number
  totalValueLockedUSD: string
  tvlToken0: string
  tvlToken1: string
  _token0Amount: string
  _token1Amount: string
  _token0AmountUSD: string
  _token1AmountUSD: string
  upperTick: string
  lowerTick: string
  volumeUSD: string
  // volumeUSD # 24 hour possible?
  apr: string
  outputTokenPriceUSD: string
  vault: {
    upperTick: string
    lowerTick: string
    apr: {
      averageApr: string
    }
    pool: {
      volumeUSD: string
      volumeToken0: string
      volumeToken1: string
    }
    _token0AmountUSD: string
    _token1AmountUSD: string
    _token0Amount: string
    _token1Amount: string
    _token0: {
      id: string
      symbol: string
      volume: string
      volumeUSD: string
      totalValueLocked: string
      totalValueLockedUSD: string
    }
    _token1: {
      id: string
      symbol: string
      volume: string
      volumeUSD: string
      totalValueLocked: string
      totalValueLockedUSD: string
    }
  }
}

export function selectDataKey(snapshot: SnapshotType, unit: 'token0' | 'token1' | 'both'): number {
  switch (unit) {
    case 'token0':
      return parseFloat(snapshot.tvlToken0)
    case 'token1':
      return parseFloat(snapshot.tvlToken1)
    default:
      return parseFloat(snapshot.totalValueLockedUSD)
  }
}

export function selectTimeFormat(timeframe: '7' | '30' | '90' | 'max'): (timestamp: NumberValue) => string {
  let timestampFormatter
  switch (timeframe) {
    case '7':
      timestampFormatter = dayHourFormatter('en-US') // for a short timeframe, show hours
      break
    case '30':
      timestampFormatter = monthDayFormatter('en-US') // for a medium timeframe, show day and hour
      break
    default:
      timestampFormatter = monthYearDayFormatter('en-US') // for longer timeframes, show month and day
      break
  }
  return timestampFormatter
}

export function filterDataByTimeframe(data: SnapshotType[], timeframe: '7' | '30' | '90' | 'max'): SnapshotType[] {
  const mostRecentTimestamp = parseFloat(data[data.length - 1].timestamp.toString())
  let startTime: number

  switch (timeframe) {
    case '7':
      startTime = mostRecentTimestamp - 7 * 86400
      break
    case '30':
      startTime = mostRecentTimestamp - 30 * 86400
      break
    case '90':
      startTime = mostRecentTimestamp - 90 * 86400
      break
    default:
      startTime = parseFloat(data[0].timestamp.toString())
  }

  return data.filter((snapshot) => parseFloat(snapshot.timestamp.toString()) >= startTime)
}
