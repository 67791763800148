// Based mostly on https://github.com/Uniswap/interface/blob/main/src/theme/index.tsx

import { Color } from './styled'
import { opacify } from './utils'

// Generated from figma. could be nice
// const Light = {
//   colors: {
//     brandInvert: "#ffffff",
//     brandPrimary: "#3083dc",
//     brandText: "#1c1c1c",
//     buttonBorder: "#334155",
//     buttonDisable: "#64748b",
//     buttonPrimary: "#0f172a",
//     buttonSecondary: "#0f172a",
//     buttonSubtle2: "#e2e8f0",
//     buttonSubtle: "#f1f5f9",
//     buttonText: "#ffffff",
//     cardBorderSecondary: "#f1f5f9",
//     cardBorder: "#cbd5e1",
//     cardPrimary: "#ffffff",
//     cardSecondary: "#f8fafc",
//     cardTertiary: "#f1f5f9",
//     lineDefault: "#94a3b8",
//     misc: "#e2e8f0",
//     surfacePrimary: "#f8fafc",
//     systemAmber: "#d97706",
//     systemGreen: "#16a34a",
//     systemRed: "#dc2626",
//     textDefault: "#94a3b8",
//     textPrimary: "#0f172a",
//     textSecondary: "#64748b",
//   },
// };
// const Dark = {
//   colors: {
//     brandInvert: "#3083dc",
//     brandPrimary: "#ffffff",
//     brandText: "#ffffff",
//     buttonBorder: "#475569",
//     buttonDisable: "#64748b",
//     buttonPrimary: "#3083dc",
//     buttonSecondary: "#1e293b",
//     buttonSubtle2: "#1e293b",
//     buttonSubtle: "#1e293b",
//     buttonText: "#0f172a",
//     cardBorderSecondary: "#334155",
//     cardBorder: "#475569",
//     cardPrimary: "#1e293b",
//     cardSecondary: "#0f172a",
//     cardTertiary: "#0f172a",
//     lineDefault: "#cbd5e1",
//     misc: "#0f172a",
//     surfacePrimary: "#0f172a",
//     systemAmber: "#f59e0b",
//     systemGreen: "#22c55e",
//     systemRed: "#ef4444",
//     textDefault: "#64748b",
//     textPrimary: "#f8fafc",
//     textSecondary: "#94a3b8",
//   },
// };

export interface GlobalPalette {
  white: Color
  black: Color
  gray50: Color
  gray100: Color
  gray200: Color
  gray300: Color
  gray400: Color
  gray500: Color
  gray600: Color
  gray700: Color
  gray800: Color
  gray900: Color
  slate50: Color
  slate100: Color
  slate200: Color
  slate300: Color
  slate400: Color
  slate500: Color
  slate600: Color
  slate700: Color
  slate800: Color
  slate900: Color
  lineDefault: Color
  lineDark: Color
  lineLight: Color
  pink50: Color
  pink100: Color
  pink200: Color
  pink300: Color
  pink400: Color
  pink500: Color
  pink600: Color
  pink700: Color
  pink800: Color
  pink900: Color
  pinkVibrant: Color
  red50: Color
  red100: Color
  red200: Color
  red300: Color
  red400: Color
  red500: Color
  red600: Color
  red700: Color
  red800: Color
  red900: Color
  redVibrant: Color
  yellow50: Color
  yellow100: Color
  yellow200: Color
  yellow300: Color
  yellow400: Color
  yellow500: Color
  yellow600: Color
  yellow700: Color
  yellow800: Color
  yellow900: Color
  yellowVibrant: Color
  gold200: Color
  goldVibrant: Color
  green50: Color
  green100: Color
  green200: Color
  green300: Color
  green400: Color
  green500: Color
  green600: Color
  green700: Color
  green800: Color
  green900: Color
  greenVibrant: Color
  blue50: Color
  blue100: Color
  blue200: Color
  blue300: Color
  blue400: Color
  blue500: Color
  blue600: Color
  blue700: Color
  blue800: Color
  blue900: Color
  blueVibrant: Color
  magentaVibrant: Color
  purple900: Color
  networkEthereum: Color
  networkOptimism: Color
  networkOptimismSoft: Color
  networkPolygon: Color
  networkArbitrum: Color
  networkPolygonSoft: Color
  networkEthereumSoft: Color
}

export const colorsList = {
  blue200: '#69B1F0',
  blue300: '#4CA0ED',
  blue400: '#3083DC',
  blue500: '#2661B7',
  blue600: '#2661B7',
  darkBlue: '#19152D',
  white: '#FFFFFF',
  gray100: '#FAFAFA',
  gray200: '#F5F5F5',
  gray300: '#F0F0F0',
  gray500: '#C2C2C2',
  gray700: '#818181',
  gray900: '#3C3C3C',
  black: '#1C1C1C',
  slate50: '#F8FAFC',
  slate100: '#F1F5F9',
  slate200: '#E2E8F0',
  slate300: '#CBD5E1',
  slate400: '#94A3B8',
  slate500: '#64748B',
  slate600: '#475569',
  slate700: '#334155',
  slate800: '#1E293B',
  slate900: '#0F172A',
  red500: '#EF4444',
  red700: '#B91C1C',
  green500: '#22C55E',
  green700: '#15803D',
  yellow500: '#F59E0B',
  yellow700: '#B45309',
}

export const colors: GlobalPalette = {
  white: '#FFFFFF',
  black: '#1C1C1C',
  gray50: '#F8FAFC', // trying slates here
  gray100: '#FAFAFA',
  gray200: '#F5F5F5',
  gray300: '#F0F0F0',
  gray400: '#94A3B8',
  gray500: '#C2C2C2',
  gray600: '#475569',
  gray700: '#818181',
  gray800: '#1E293B',
  gray900: '#3C3C3C',
  slate50: '#F8FAFC',
  slate100: '#F1F5F9',
  slate200: '#E2E8F0',
  slate300: '#CBD5E1',
  slate400: '#94A3B8',
  slate500: '#64748B',
  slate600: '#475569',
  slate700: '#334155',
  slate800: '#1E293B',
  slate900: '#0F172A',
  lineDark: '#cbd5e1',
  lineLight: '#94a3b8',
  pink50: '#F9ECF1',
  pink100: '#FFD9E4',
  pink200: '#FBA4C0',
  pink300: '#FF6FA3',
  pink400: '#FB118E',
  pink500: '#C41969',
  pink600: '#8C0F49',
  pink700: '#55072A',
  pink800: '#350318',
  pink900: '#2B000B',
  pinkVibrant: '#F51A70',
  red50: '#FAECEA',
  red100: '#FED5CF',
  red200: '#FEA79B',
  red300: '#FD766B',
  red400: '#FA2B39',
  red500: '#C4292F',
  red600: '#891E20',
  red700: '#530F0F',
  red800: '#380A03',
  red900: '#240800',
  redVibrant: '#F14544',
  yellow50: '#F6F2D5',
  yellow100: '#DBBC19',
  yellow200: '#DBBC19',
  yellow300: '#BB9F13',
  yellow400: '#A08116',
  yellow500: '#F59E0B',
  yellow600: '#5D4204',
  yellow700: '#3E2B04',
  yellow800: '#231902',
  yellow900: '#180F02',
  yellowVibrant: '#FAF40A',
  // TODO: add gold 50-900
  gold200: '#EEB317',
  goldVibrant: '#FEB239',
  green50: '#E3F3E6',
  green100: '#BFEECA',
  green200: '#76D191',
  green300: '#40B66B',
  green400: '#209853',
  green500: '#22C55E',
  green600: '#0C522A',
  green700: '#053117',
  green800: '#091F10',
  green900: '#09130B',
  greenVibrant: '#5CFE9D',
  blue50: '#EDEFF8',
  blue100: '#DEE1FF',
  blue200: '#69B1F0', // our blue200 - 600
  blue300: '#4CA0ED',
  blue400: '#3083DC',
  blue500: '#2661B7',
  blue600: '#2661B7',
  blue700: '#09265E',
  blue800: '#0B193F',
  blue900: '#040E34',
  blueVibrant: '#587BFF',
  // TODO: add magenta 50-900
  magentaVibrant: '#FC72FF',
  purple900: '#1C0337',
  // TODO: add all other vibrant variations
  networkEthereum: '#627EEA',
  networkOptimism: '#FF0420',
  networkOptimismSoft: 'rgba(255, 4, 32, 0.16)',
  networkPolygon: '#A457FF',
  networkArbitrum: '#28A0F0',
  networkPolygonSoft: 'rgba(164, 87, 255, 0.16)',
  networkEthereumSoft: 'rgba(98, 126, 234, 0.16)',
  lineDefault: '#94a3b8',
}

export interface Palette {
  linePrimary: string
  userThemeColor: Color

  backgroundBackdrop: Color
  backgroundSurface: Color
  backgroundInteractive: Color
  backgroundFloating: Color
  backgroundModule: Color
  backgroundOutline: Color
  backgroundScrim: Color

  borderSecondary: Color

  textPrimary: Color
  textSecondary: Color
  textTertiary: Color

  accentAction: Color
  accentActive: Color
  accentSuccess: Color
  accentWarning: Color
  accentFailure: Color

  accentActionSoft: Color
  accentActiveSoft: Color
  accentSuccessSoft: Color
  accentWarningSoft: Color
  accentFailureSoft: Color

  accentTextDarkPrimary: Color
  accentTextDarkSecondary: Color
  accentTextDarkTertiary: Color

  accentTextLightPrimary: Color
  accentTextLightSecondary: Color
  accentTextLightTertiary: Color

  primaryGradient: Color

  warning500: Color
  warning700: Color
  error500: Color
  error700: Color
  success500: Color
  success700: Color

  hoverState: Color
  hoverDefault: Color

  cardPrimary: Color
  cardSecondary: Color
  cardTertiary: Color
  cardBorderPrimary: Color
  cardBorderSecondary: Color
  //
  // linePrimary: Color
  // lineDark: Color
  // lineLight: Color

  buttonPrimary: Color
  buttonSecondary: Color
  buttonSubtle1: Color
  buttonSubtle2: Color
  buttonBorder: Color
  buttonDisable: Color

  stateOverlayHover: Color
  stateOverlayPressed: Color

  white: Color
  black: Color
  none: Color

  chain_1: Color
  chain_3: Color
  chain_4: Color
  chain_5: Color
  chain_10: Color
  chain_137: Color
  chain_42: Color
  chain_69: Color
  chain_42161: Color
  chain_421611: Color
  chain_80001: Color
  chain_137_background: Color
  chain_10_background: Color
  chain_42161_background: Color

  flyoutDropShadow: Color
}

export const colorsLight: Palette = {
  userThemeColor: colors.blue400,

  none: 'transparent',

  backgroundBackdrop: colors.gray50,
  backgroundSurface: colors.white,
  backgroundModule: colors.gray50,
  backgroundInteractive: opacify(6, colors.gray700),
  backgroundFloating: opacify(8, colors.white),
  backgroundOutline: opacify(24, colors.gray500),
  backgroundScrim: opacify(60, colors.gray900),

  borderSecondary: colors.gray100,

  textPrimary: colors.gray900,
  textSecondary: colors.slate500,
  textTertiary: colors.gray300,

  accentAction: colors.blue500,
  accentActive: colors.blue400,
  accentSuccess: colors.green400,
  accentWarning: colors.gold200,
  accentFailure: colors.red400,

  accentActionSoft: opacify(12, colors.pink400),
  accentActiveSoft: opacify(12, colors.blue400),
  accentSuccessSoft: opacify(12, colors.green400),
  accentWarningSoft: opacify(12, colors.gold200),
  accentFailureSoft: opacify(12, colors.red400),

  accentTextDarkPrimary: opacify(80, colors.black),
  accentTextDarkSecondary: opacify(60, colors.black),
  accentTextDarkTertiary: opacify(24, colors.black),

  accentTextLightPrimary: colors.white,
  accentTextLightSecondary: opacify(60, colors.white),
  accentTextLightTertiary: opacify(12, colors.white),

  // KODIAK SPECIFIC
  primaryGradient: 'linear-gradient(180deg, #1C1C1C 0%, #3083DC 100%)',

  warning500: colors.yellow500,
  warning700: colors.yellow700,
  error500: colors.red500,
  error700: colors.red700,
  success500: colors.green500,
  success700: colors.green700,

  hoverState: opacify(24, colors.blue200),
  hoverDefault: opacify(8, colors.gray300),
  cardPrimary: colors.white,
  cardSecondary: colors.slate50,
  cardTertiary: colors.slate100,
  cardBorderPrimary: colors.slate300,
  cardBorderSecondary: colors.slate100,

  buttonPrimary: colors.slate900,
  buttonSecondary: colors.slate800,
  buttonSubtle1: colors.slate100,
  buttonSubtle2: colors.slate200,
  buttonBorder: colors.slate600,
  buttonDisable: colors.slate500,

  stateOverlayHover: opacify(8, colors.slate900),
  stateOverlayPressed: opacify(24, colors.slate900),

  white: colors.white,
  black: colors.black,

  chain_1: colors.networkEthereum,
  chain_3: colors.yellow400,
  chain_4: colors.pink400,
  chain_5: colors.green400,
  chain_10: colors.networkOptimism,
  chain_137: colors.networkPolygon,
  chain_42: colors.networkArbitrum,
  chain_69: colors.networkOptimism,
  chain_42161: colors.networkEthereum,
  chain_421611: colors.networkEthereum,
  chain_80001: colors.networkPolygon,
  chain_137_background: colors.purple900,
  chain_10_background: colors.red900,
  chain_42161_background: colors.blue900,

  flyoutDropShadow:
    '0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.12);',
  linePrimary: '',
}

export const colorsDark: Palette = {
  userThemeColor: colors.blue400,

  backgroundBackdrop: colors.slate900,
  backgroundSurface: colors.slate900,
  backgroundModule: opacify(8, colors.gray300),
  backgroundInteractive: colors.gray700,
  backgroundFloating: opacify(8, colors.gray700),
  backgroundOutline: opacify(24, colors.gray300),
  backgroundScrim: opacify(72, colors.gray900),

  borderSecondary: colors.gray700,

  textPrimary: colors.gray50,
  textSecondary: colors.gray400,
  textTertiary: colors.gray300,

  accentAction: colors.blue400,
  accentActive: colors.blue400,
  accentSuccess: colors.greenVibrant,
  accentWarning: colors.gold200,
  accentFailure: colors.red400,

  accentActionSoft: opacify(24, colors.blue400),
  accentActiveSoft: opacify(12, colors.blue400),
  accentSuccessSoft: opacify(12, colors.green400),
  accentWarningSoft: opacify(12, colors.gold200),
  accentFailureSoft: opacify(12, colors.red400),

  accentTextDarkPrimary: opacify(80, colors.black),
  accentTextDarkSecondary: opacify(60, colors.black),
  accentTextDarkTertiary: opacify(24, colors.black),

  accentTextLightPrimary: colors.white,
  accentTextLightSecondary: opacify(72, colors.white),
  accentTextLightTertiary: opacify(12, colors.white),

  // KODIAK
  primaryGradient: 'linear-gradient(180deg, #1C1C1C 0%, #3083DC 100%)',

  warning500: colors.yellow500,
  warning700: colors.yellow700,
  error500: colors.red500,
  error700: colors.red700,
  success500: colors.green500,
  success700: colors.green700,

  hoverState: opacify(24, colors.blue200),
  hoverDefault: opacify(8, colors.gray300),

  cardPrimary: colors.slate800,
  cardSecondary: colors.slate900,
  cardTertiary: colors.slate900,
  cardBorderPrimary: colors.slate600,
  cardBorderSecondary: colors.slate700,

  buttonPrimary: colors.blue400,
  buttonSecondary: colors.slate800,
  buttonSubtle1: colors.slate700,
  buttonSubtle2: colors.slate800,
  buttonBorder: colors.slate600,
  buttonDisable: colors.slate500,

  stateOverlayHover: opacify(8, colors.blue400),
  stateOverlayPressed: opacify(24, colors.blue400),

  white: colors.white,
  black: colors.black,
  none: 'transparent',

  chain_1: colors.networkEthereum,
  chain_3: colors.yellow400,
  chain_4: colors.pink400,
  chain_5: colors.green400,
  chain_10: colors.networkOptimism,
  chain_137: colors.networkPolygon,
  chain_42: colors.networkArbitrum,
  chain_69: colors.networkEthereum,
  chain_42161: colors.networkEthereum,
  chain_421611: colors.networkEthereum,
  chain_80001: colors.networkPolygon,
  chain_137_background: colors.purple900,
  chain_10_background: colors.red900,
  chain_42161_background: colors.blue900,

  flyoutDropShadow:
    '0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.12);',
  linePrimary: '',
}
