import { useWeb3React } from '@web3-react/core'
import { ElementName, Event, EventName } from 'components/AmplitudeAnalytics/constants'
import { TraceEvent } from 'components/AmplitudeAnalytics/TraceEvent'
import { ButtonLight, ButtonPrimary } from 'components/Button'
import { LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import CurrencyLogo from 'components/CurrencyLogo'
import FiatValue from 'components/FiatValue'
import { RowBetween, RowFixed } from 'components/Row'
import Slider from 'components/Slider'
import { TruncatedText } from 'components/swap/styleds'
import { MouseoverTooltip } from 'components/Tooltip'
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { useToken } from 'hooks/Tokens'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { useCalculationEarnings, useCommunalFarmApr } from 'hooks/useCommunalFarms'
import { useFarmContract } from 'hooks/useContract'
import { useSubgraphTokenValue } from 'hooks/useSubgraphTokenPrice'
import useCurrencyBalance from 'lib/hooks/useCurrencyBalance'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { useCallback, useContext, useMemo, useState } from 'react'
import { Text } from 'rebass'
import { useToggleWalletModal } from 'state/application/hooks'
import useInputValue from 'state/farms/hooks/useInputValue'
import useLockTimeValue, { useLockTimeMultiplier } from 'state/farms/hooks/useLockTimeValue'
import { ThemeContext } from 'styled-components'
import { Separator, ThemedText } from 'theme'
import formatMoney from 'utils/formatMoney'

import Column from '../../../components/Column/index'
import { useFarm } from './context/FarmContext'
import EarningsCalculationView from './EarningsCalculationView'
import { Dots, LockTimePositionHeader } from './styleds'
const ONE_DAY = 86400

const DepositView = () => {
  const theme = useContext(ThemeContext)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')

  const farm = useFarm()
  const currency = useToken(farm.stakingToken)
  const { account } = useWeb3React()
  const tokenBalance = useCurrencyBalance(account, currency || undefined)
  const [amount, setAmount] = useInputValue()
  const toggleWalletModal = useToggleWalletModal()
  const currencyAmount = useMemo(() => tryParseCurrencyAmount(amount, currency || undefined), [amount])
  const isValid = !!(
    tokenBalance &&
    currencyAmount &&
    (currencyAmount.lessThan(tokenBalance) || currencyAmount.equalTo(tokenBalance))
  )
  const [approval, approve] = useApproveCallback(currencyAmount, farm.address)

  const { lockTime, setLockTime } = useLockTimeValue(farm.lockTimeMin, farm.lockTimeMax)
  const multiplier = useLockTimeMultiplier(lockTime, farm)

  const farmContract = useFarmContract(farm.address)

  const estimate = useCallback(() => {
    return currencyAmount && farmContract?.estimateGas.stakeLocked(currencyAmount.quotient.toString(), lockTime)
  }, [farmContract, currencyAmount, lockTime, account])

  const send = useCallback(
    () => currencyAmount && farmContract?.stakeLocked(currencyAmount.quotient.toString(), lockTime),
    [farmContract, currencyAmount, lockTime, account]
  )

  // useEffect(() => {
  //   if (!account) return;

  //   farmContract?.earned(account).then(earned => {
  //     const earnedCurrencyAmounts = earned.map((earnedItem, index) => {
  //       return CurrencyAmount.fromRawAmount(
  //         farm.rewardTokens[index],
  //         JSBI.BigInt(earnedItem.toString()),
  //       )
  //     })
  //   })
  // });

  const ApproveButton = () => (
    <ButtonPrimary onClick={approve} disabled={approval === ApprovalState.PENDING} width="100%">
      {approval === ApprovalState.PENDING ? (
        <Dots>
          <>Approving {currency?.symbol}</>
        </Dots>
      ) : (
        <>Approve {currency?.symbol}</>
      )}
    </ButtonPrimary>
  )

  const onDeposit = () => {
    setShowConfirm(true)
    // const gas = estimate && await estimate();

    // if (gas) {
    //   const response = await send();
    // }
  }

  const HeaderModal = () => {
    const value = useSubgraphTokenValue(currencyAmount)
    const apr = useCommunalFarmApr(farm, multiplier)
    const earnings = useCalculationEarnings({
      farm,
      inputValue: amount,
      lockTimeValue: lockTime,
      multiplier,
    })
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const earningsValues = earnings.map((e) => useSubgraphTokenValue(e))
    const earningsTotalValue = earningsValues.reduce((a, b) => (a || 0) + (b || 0), 0)

    return (
      <AutoColumn gap="4px" style={{ marginTop: '12px' }}>
        <LightCard padding="0.75rem 1rem">
          <AutoColumn gap="8px">
            <RowBetween align="center">
              <RowFixed gap="0px">
                <TruncatedText
                  fontSize={24}
                  fontWeight={500}
                  // color={showAcceptChanges && trade.tradeType === TradeType.EXACT_OUTPUT ? theme.deprecated_primary1 : ''}
                >
                  {currencyAmount?.toSignificant(8)}
                </TruncatedText>
              </RowFixed>
              <RowFixed gap="0px">
                <CurrencyLogo currency={currency} size="20px" style={{ marginRight: '12px' }} />
                <Text fontSize={20} fontWeight={500}>
                  {currencyAmount?.currency.symbol}
                </Text>
              </RowFixed>
            </RowBetween>
            <RowBetween>{value && formatMoney(value)}</RowBetween>
          </AutoColumn>
        </LightCard>
        <LightCard>
          <AutoColumn gap="8px">
            <RowBetween>
              <RowFixed>
                <MouseoverTooltip
                  text={
                    <>
                      The amount you expect to receive at the current market price. You may receive less or more if the
                      market price changes while your transaction is pending.
                    </>
                  }
                  disableHover={false}
                >
                  <ThemedText.DeprecatedSubHeader color={theme.deprecated_text1}>
                    Lock period
                  </ThemedText.DeprecatedSubHeader>
                </MouseoverTooltip>
              </RowFixed>
              <ThemedText.DeprecatedBlack textAlign="right" fontSize={14}>
                {lockTime / ONE_DAY} days
              </ThemedText.DeprecatedBlack>
            </RowBetween>
            <RowBetween>
              <RowFixed>
                <MouseoverTooltip
                  text={
                    <>
                      The amount you expect to receive at the current market price. You may receive less or more if the
                      market price changes while your transaction is pending.
                    </>
                  }
                  disableHover={false}
                >
                  <ThemedText.DeprecatedSubHeader color={theme.deprecated_text1}>APR</ThemedText.DeprecatedSubHeader>
                </MouseoverTooltip>
              </RowFixed>
              <ThemedText.DeprecatedBlack textAlign="right" fontSize={14}>
                {apr?.toFixed(2)}%
              </ThemedText.DeprecatedBlack>
            </RowBetween>
            <RowBetween>
              <RowFixed>
                <MouseoverTooltip
                  text={<>The impact your trade has on the market price of this pool.</>}
                  disableHover={false}
                >
                  <ThemedText.DeprecatedSubHeader color={theme.deprecated_text1}>
                    Multiplier
                  </ThemedText.DeprecatedSubHeader>
                </MouseoverTooltip>
              </RowFixed>
              <ThemedText.DeprecatedBlack textAlign="right" fontSize={14}>
                x{multiplier.toFixed(2)}
              </ThemedText.DeprecatedBlack>
            </RowBetween>
            <Separator redesignFlag={false} />
            <RowBetween>
              <RowFixed style={{ marginRight: '20px', alignItems: 'end' }}>
                <MouseoverTooltip
                  text={
                    <>
                      The minimum amount you are guaranteed to receive. If the price slips any further, your transaction
                      will revert.
                    </>
                  }
                  disableHover={false}
                >
                  <ThemedText.DeprecatedSubHeader color={theme.deprecated_text3}>
                    Estimated Rewards
                  </ThemedText.DeprecatedSubHeader>
                </MouseoverTooltip>
              </RowFixed>
              <Column>
                {earnings.map((earn, index) => {
                  return (
                    <RowFixed key={index}>
                      <ThemedText.DeprecatedBlack textAlign="right" fontSize={14} color={theme.deprecated_text3}>
                        {earn?.toSignificant()} {earn?.currency.symbol} (<FiatValue value={earningsValues[index]} />)
                      </ThemedText.DeprecatedBlack>
                    </RowFixed>
                  )
                })}
              </Column>
            </RowBetween>
            {earnings.length > 1 && (
              <RowBetween>
                <RowFixed style={{ marginRight: '20px' }}>
                  <MouseoverTooltip text={<>Summary reward</>} disableHover={false}>
                    <ThemedText.DeprecatedSubHeader color={theme.deprecated_text3}>
                      Summary reward
                    </ThemedText.DeprecatedSubHeader>
                  </MouseoverTooltip>
                </RowFixed>
                <ThemedText.DeprecatedBlack textAlign="right" fontSize={14} color={theme.deprecated_text3}>
                  <FiatValue value={earningsTotalValue} />
                </ThemedText.DeprecatedBlack>
              </RowBetween>
            )}
          </AutoColumn>
        </LightCard>
      </AutoColumn>
    )
  }

  const BottomModal = () => {
    return (
      <ButtonPrimary
        style={{ marginTop: '12px' }}
        // onClick={onDeposit}
        disabled={!isValid || approval !== ApprovalState.APPROVED}
      >
        <Text fontSize={20} fontWeight={500}>
          <>Confirm </>
        </Text>
      </ButtonPrimary>
    )
  }
  const DepositButton = () => (
    <ButtonPrimary onClick={onDeposit} disabled={!isValid || approval !== ApprovalState.APPROVED}>
      <Text fontSize={20} fontWeight={500}>
        <>Supply </>
      </Text>
    </ButtonPrimary>
  )

  return (
    <>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={() => setShowConfirm(false)}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        content={() => (
          <ConfirmationModalContent
            title={<>You will stake</>}
            onDismiss={() => setShowConfirm(false)}
            topContent={HeaderModal}
            bottomContent={BottomModal}
          />
        )}
        pendingText="Pending"
        currencyToAdd={currency || undefined}
      />
      <CurrencyInputPanel
        value={amount}
        onUserInput={setAmount}
        onMax={() => tokenBalance && setAmount(tokenBalance.toExact())}
        showMaxButton={true}
        currency={currency}
        id="stake-input-token"
        showCommonBases
      />
      <LockTimePositionHeader>
        <div>Lock for {lockTime / ONE_DAY} day</div>
        <div>Multiplier: x{multiplier.toFixed(2)}</div>
      </LockTimePositionHeader>
      <Slider min={farm.lockTimeMin} max={farm.lockTimeMax} step={ONE_DAY} value={lockTime} onChange={setLockTime} />
      <EarningsCalculationView />
      {!account ? (
        <TraceEvent
          events={[Event.onClick]}
          name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
          properties={{ received_swap_quote: false }}
          element={ElementName.CONNECT_WALLET_BUTTON}
        >
          <ButtonLight onClick={toggleWalletModal}>
            <>Connect Wallet</>
          </ButtonLight>
        </TraceEvent>
      ) : approval === ApprovalState.APPROVED || approval === ApprovalState.UNKNOWN ? (
        <DepositButton />
      ) : (
        <ApproveButton />
      )}
    </>
  )
}

export default DepositView
