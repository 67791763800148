import { CurrencyAmount, Token } from '@kodiak-finance/sdk-core'
import { LightCard } from 'components/Card'
import Column, { AutoColumn } from 'components/Column'
import Loader from 'components/Loader'
import { RowBetween, RowFixed } from 'components/Row'
import useStepWithNext from 'components/StepLine/context/hooks/useStepWithNext'
import StepLineModal from 'components/StepLine/StepLineModal'
import TimeBetweenText from 'components/TimeBetweenText'
import { useXKDK } from 'hooks/useContract'
import useStaking from 'hooks/useStaking'
import { useCallback, useState } from 'react'
import { Lock, Unlock } from 'react-feather'
import { Text } from 'rebass'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { useTheme } from 'styled-components'

import FinalizeRedeemStep from './FinalizeRedeemStep'
import { WithdrawVestingButton } from './styleds'

const Item = ({
  endTime,
  kdkAmount,
  xKdkAmount,
  index,
}: {
  index: number
  endTime: number
  kdkAmount: CurrencyAmount<Token>
  xKdkAmount: CurrencyAmount<Token>
}) => {
  const theme = useTheme()
  const isLocked = endTime > Date.now() / 1000

  const xkdk = useXKDK()
  const [showModal, setShowModal] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState<string>('')
  const addTransaction = useTransactionAdder()

  const onRedeem = useCallback(() => {
    if (!xkdk) return
    setAttemptingTxn(true)
    xkdk.estimateGas
      .finalizeRedeem(index)
      .then((gas) => {
        xkdk
          .finalizeRedeem(index, { gasLimit: gas.mul(120).div(100) })
          .finally(() => setAttemptingTxn(false))
          .then((tx) => {
            setTxHash(tx.hash)
            addTransaction(tx, {
              type: TransactionType.STAKE_HARVEST_KDK,
              amount: `${kdkAmount.toSignificant(6)} ${kdkAmount.currency.symbol}`,
            })
          })
      })
      .catch(() => setAttemptingTxn(false))
  }, [index, xkdk, kdkAmount, addTransaction])

  const step = useStepWithNext('FinalizeRedeemStep', FinalizeRedeemStep, {
    amount: kdkAmount,
    onFinalize: onRedeem,
    pendingText: (
      <>
        Redeem {kdkAmount.toSignificant(6)} {kdkAmount.currency.symbol}
      </>
    ),
    attemptingTxn,
    hash: txHash,
    transactionWaiting: true,
  })

  return (
    <LightCard>
      <StepLineModal header={<>Redeem</>} isOpen={showModal} onDismiss={() => setShowModal(false)} steps={[step]} />
      <RowBetween>
        <Column>
          <Text fontSize={14} color={theme.textPrimary}>
            {kdkAmount.toSignificant(6)} {kdkAmount.currency.symbol}
          </Text>
          <Text style={{ textDecoration: 'line-through' }} fontSize={11} color={theme.textSecondary}>
            {xKdkAmount.toSignificant(6)} {xKdkAmount.currency.symbol}
          </Text>
        </Column>
        <RowFixed>
          {isLocked ? (
            <Lock color={theme.accentFailure} size={14} opacity={0.6} />
          ) : (
            <WithdrawVestingButton onClick={() => setShowModal(true)}>
              <Unlock color={theme.accentSuccess} size={14} opacity={0.6} />
            </WithdrawVestingButton>
          )}
          {isLocked && (
            <Text fontSize={12} marginLeft="4px">
              <TimeBetweenText date={new Date(endTime * 1000)}>
                <Loader />
              </TimeBetweenText>
            </Text>
          )}
        </RowFixed>
      </RowBetween>
    </LightCard>
  )
}

const VestingView = () => {
  const { redeems } = useStaking()

  return (
    <AutoColumn gap="sm">
      {redeems?.map((redeem, index) => (redeem ? <Item key={index} {...redeem} /> : null))}
    </AutoColumn>
  )
}

export default VestingView
