import { Currency, CurrencyAmount, Token } from '@kodiak-finance/sdk-core'
import { ButtonPrimary } from 'components/Button'
import { LightCard } from 'components/Card'
import { AutoColumn, ColumnCenter } from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import Row, { AutoRow, RowBetween, RowTitled } from 'components/Row'
import { useStepLineState, useStepLineStateUpdate } from 'components/StepLine/context/StepLineContext'
import { useCurrency } from 'hooks/Tokens'
import { CommunalFarmStake } from 'hooks/useCommunalFarms'
import { useIslandUnderlyingBalances } from 'hooks/useIslandPositionDetails'
import { Island } from 'hooks/useIslands'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'

type WithdrawBalanceConstructorStepProps = {
  selectedShares: CurrencyAmount<Currency> | undefined
  userBalanceShares: CurrencyAmount<Currency> | undefined
  stakes: CommunalFarmStake[] | null | undefined
  defaultActiveStakes?: CommunalFarmStake[] | null | undefined
  island: Island | null | undefined
  onSelect: (props: { islandLiquidity: CurrencyAmount<Token> | undefined; stakes: CommunalFarmStake[] }) => void
  next: () => void
}

const StakeItemCard = styled(LightCard)<{ active?: boolean }>`
  opacity: ${({ active }) => (active ? 1 : 0.4)};
  border: ${({ active, theme }) => `1px solid ${active ? theme.textSecondary : theme.backgroundOutline}`};
  cursor: pointer;
`

const UnderlyingViewWrapper = styled(AutoRow)`
  padding-left: 0.5rem;
  flex-wrap: nowrap;
  width: auto;
`

type WithdrawBalanceConstructorState = {
  activeStakes: CommunalFarmStake[]
}

function StakeItemView({
  stake,
  island,
  activeByDefault,
}: {
  stake: CommunalFarmStake
  island: Island | null | undefined
  activeByDefault?: boolean
}) {
  const [active, setActive] = useState(activeByDefault || false)
  const token0 = useCurrency(island?.token0.id)
  const token1 = useCurrency(island?.token1.id)
  const { activeStakes = [] } = useStepLineState<WithdrawBalanceConstructorState>()
  const update = useStepLineStateUpdate<WithdrawBalanceConstructorState>()

  const underlying = useIslandUnderlyingBalances(island || null, stake.liquidity, token0, token1)

  const onClick = () => {
    const newActive = !active
    setActive(newActive)
    update({
      activeStakes: [...activeStakes.filter((s) => s.kekId !== stake.kekId), ...(newActive ? [stake] : [])],
    })
  }

  return (
    <StakeItemCard active={active} onClick={onClick}>
      <AutoColumn>
        <RowBetween style={{ userSelect: 'none' }}>
          <UnderlyingView {...underlying} />
          <div>x{stake.lockMultiplier.toFixed(2)}</div>
        </RowBetween>
      </AutoColumn>
    </StakeItemCard>
  )
}

export function UnderlyingView({
  amount0,
  amount1,
  currency0,
  currency1,
}: {
  amount0: CurrencyAmount<Currency> | undefined
  amount1: CurrencyAmount<Currency> | undefined
  currency0?: Currency | null
  currency1?: Currency | null
}) {
  return (
    <UnderlyingViewWrapper gap="2px">
      <DoubleCurrencyLogo
        currency0={amount0?.currency || currency0 || undefined}
        currency1={amount1?.currency || currency1 || undefined}
        size={18}
      />
      <Text
        flexWrap="nowrap"
        fontSize={14}
        style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        overflow="hidden"
      >
        {amount0?.toSignificant(6) || '0'} {amount0?.currency.symbol || currency0?.symbol}/
        {amount1?.toSignificant(6) || '0'} {amount1?.currency.symbol || currency1?.symbol}
      </Text>
    </UnderlyingViewWrapper>
  )
}

function useRemaining(
  island: WithdrawBalanceConstructorStepProps['island'],
  userBalanceShares: CurrencyAmount<Token> | undefined,
  selectedShares: WithdrawBalanceConstructorStepProps['selectedShares'],
  defaultActiveStakes: WithdrawBalanceConstructorStepProps['stakes'] = []
) {
  const { activeStakes = [] } = useStepLineState<WithdrawBalanceConstructorState>()
  const update = useStepLineStateUpdate<WithdrawBalanceConstructorState>()
  useEffect(() => {
    if (!defaultActiveStakes || defaultActiveStakes.length === 0) {
      return
    }
    update({
      activeStakes: defaultActiveStakes,
    })
  }, [])
  const token0 = useCurrency(island?.token0.id)
  const token1 = useCurrency(island?.token1.id)

  const totalActiveStakesLiquidity = useMemo(() => {
    if (activeStakes.length === 0 || activeStakes[0].liquidity?.currency === undefined) {
      return undefined
    }

    const zero = CurrencyAmount.fromRawAmount(activeStakes[0].liquidity?.currency, '0')
    return activeStakes
      .filter((item) => item.liquidity !== undefined)
      .reduce((prev, curr) => {
        return prev.add(curr.liquidity!)
      }, zero)
  }, [activeStakes])
  const totalActiveStakesUnderlyingBalances = useIslandUnderlyingBalances(
    island || null,
    totalActiveStakesLiquidity,
    token0,
    token1
  )

  const activeIslandLiquidity = useMemo(() => {
    if (!selectedShares || !userBalanceShares) {
      return undefined
    }

    let activeLiquidity = userBalanceShares
    const total = totalActiveStakesLiquidity ? activeLiquidity.add(totalActiveStakesLiquidity) : activeLiquidity
    if (total.greaterThan(selectedShares)) {
      activeLiquidity = activeLiquidity.subtract(total.subtract(selectedShares as CurrencyAmount<Token>))
    }

    if (activeLiquidity.lessThan('0')) {
      return userBalanceShares.subtract(userBalanceShares)
    }
    return activeLiquidity
  }, [userBalanceShares, totalActiveStakesLiquidity])
  const activeIslandUnderlyingBalances = useIslandUnderlyingBalances(
    island || null,
    activeIslandLiquidity,
    token0,
    token1
  )

  const selectedUnderlyingBalances = useIslandUnderlyingBalances(
    island || null,
    selectedShares as CurrencyAmount<Token>,
    token0,
    token1
  )
  const required = useMemo(() => {
    if (!selectedUnderlyingBalances.amount0 || !selectedUnderlyingBalances.amount1) {
      return {
        amount0: undefined,
        amount1: undefined,
      }
    }
    let amount0 = selectedUnderlyingBalances.amount0
    let amount1 = selectedUnderlyingBalances.amount1

    if (totalActiveStakesUnderlyingBalances.amount0) {
      amount0 = amount0.subtract(totalActiveStakesUnderlyingBalances.amount0)
    }
    if (totalActiveStakesUnderlyingBalances.amount1) {
      amount1 = amount1.subtract(totalActiveStakesUnderlyingBalances.amount1)
    }

    if (activeIslandUnderlyingBalances.amount0) {
      amount0 = amount0.subtract(activeIslandUnderlyingBalances.amount0)
    }
    if (activeIslandUnderlyingBalances.amount1) {
      amount1 = amount1.subtract(activeIslandUnderlyingBalances.amount1)
    }

    if (amount0.equalTo('1')) {
      amount0 = CurrencyAmount.fromRawAmount(amount0.currency, '0')
    }
    if (amount1.equalTo('1')) {
      amount1 = CurrencyAmount.fromRawAmount(amount1.currency, '0')
    }

    return {
      amount0,
      amount1,
    }
  }, [selectedUnderlyingBalances, selectedShares, activeIslandUnderlyingBalances, totalActiveStakesUnderlyingBalances])

  return {
    totalActiveStakesLiquidity,
    totalActiveStakesUnderlyingBalances,
    activeIslandLiquidity,
    activeIslandUnderlyingBalances,
    activeStakes,
    required,
    userBalanceShares,
  }
}

function Remaining({
  totalActiveStakesUnderlyingBalances,
  activeIslandUnderlyingBalances,
  currency0,
  currency1,
  required,
  userBalanceShares,
}: {
  currency0?: Currency | null
  currency1?: Currency | null
} & ReturnType<typeof useRemaining>) {
  const theme = useContext(ThemeContext)

  return (
    <LightCard>
      <RowBetween>
        <Row grow="0" shrink="0" width="fit-content">
          <Text paddingRight="8px" fontSize={14}>
            Withdraw{userBalanceShares ? ' from Staking' : ''}
          </Text>
        </Row>
        <Row grow="1" style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} justify="flex-end">
          <UnderlyingView
            amount0={totalActiveStakesUnderlyingBalances.amount0}
            amount1={totalActiveStakesUnderlyingBalances.amount1}
            currency0={currency0}
            currency1={currency1}
          />
        </Row>
      </RowBetween>
      {userBalanceShares && (
        <RowTitled
          header={
            <Text paddingRight="8px" fontSize={14}>
              Withdraw from Island
            </Text>
          }
        >
          <UnderlyingView
            amount0={activeIslandUnderlyingBalances.amount0}
            amount1={activeIslandUnderlyingBalances.amount1}
            currency0={currency0}
            currency1={currency1}
          />
        </RowTitled>
      )}

      {required.amount0 === undefined ||
      required.amount1 === undefined ||
      required.amount0?.greaterThan('0') ||
      required.amount1?.greaterThan('0') ? (
        <RowBetween>
          <Row grow="0" shrink="0" width="fit-content">
            <Text paddingRight="8px" fontSize={14}>
              Remaining
            </Text>
          </Row>
          <Row grow="1" style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} justify="flex-end">
            <UnderlyingView
              amount0={required.amount0}
              amount1={required.amount1}
              currency0={currency0}
              currency1={currency1}
            />
          </Row>
        </RowBetween>
      ) : (
        <>
          {!required.amount0.equalTo('0') && !required.amount1.equalTo('0') && (
            <RowBetween>
              <Row grow="0" shrink="0" width="fit-content">
                <Text paddingRight="8px" fontSize={14}>
                  Extra withdrawal
                </Text>
              </Row>
              <Row grow="1" style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} justify="flex-end">
                <UnderlyingView
                  amount0={required.amount0?.subtract(required.amount0).subtract(required.amount0)}
                  amount1={required.amount1?.subtract(required.amount1).subtract(required.amount1)}
                  currency0={currency0}
                  currency1={currency1}
                />
              </Row>
            </RowBetween>
          )}
        </>
      )}
    </LightCard>
  )
}

export default function WithdrawBalanceConstructorStep({
  selectedShares,
  userBalanceShares,
  stakes,
  island,
  onSelect,
  next,
  defaultActiveStakes,
}: WithdrawBalanceConstructorStepProps) {
  const filteredStakes =
    stakes?.filter((stake) => !!stake) ||
    // .filter(stake => stake.endingTimestamp.getTime() < Date.now())
    []

  const token0 = useCurrency(island?.token0.id)
  const token1 = useCurrency(island?.token1.id)

  const remaining = useRemaining(
    island,
    userBalanceShares as CurrencyAmount<Token>,
    selectedShares,
    defaultActiveStakes
  )
  const onWithdraw = () => {
    onSelect({
      islandLiquidity: remaining.activeIslandLiquidity,
      stakes: remaining.activeStakes,
    })
    next()
  }
  const continueDisabled =
    !remaining.required.amount0 ||
    !remaining.required.amount1 ||
    remaining.required.amount0?.greaterThan('0') ||
    remaining.required.amount1?.greaterThan('0')
  return (
    <ColumnCenter gap="8px">
      {filteredStakes.map((item) => (
        <StakeItemView
          key={item.kekId}
          stake={item}
          island={island}
          activeByDefault={defaultActiveStakes?.some((s) => s.kekId === item.kekId)}
        />
      ))}

      <Remaining {...remaining} currency0={token0} currency1={token1} />
      <ButtonPrimary disabled={continueDisabled} onClick={onWithdraw}>
        {continueDisabled
          ? 'Insufficient liquidity'
          : remaining.activeIslandLiquidity?.equalTo('0') && remaining.activeStakes.length > 0
          ? 'Unstake'
          : 'Withdraw'}
      </ButtonPrimary>
    </ColumnCenter>
  )
}
