import Badge, { BadgeVariant } from 'components/Badge'
import styled from 'styled-components/macro'

import { ReactComponent as Closed } from '../../assets/svg/icons/Closed.svg'
import { ReactComponent as Warning } from '../../assets/svg/icons/Warning.svg'
import { MouseoverTooltip } from '../../components/Tooltip'

const BadgeWrapper = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: flex-end;

  ${Badge} {
    background: unset;
  }
`

const BadgeText = styled.div`
  font-weight: 500;
  font-size: 14px;
`

const ActiveText = styled(BadgeText)`
  color: ${({ theme }) => theme.success500};
`

const WarningText = styled(BadgeText)`
  color: ${({ theme }) => theme.warning500};
`

const ActiveDot = styled.span`
  background-color: ${({ theme }) => theme.success500};
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: 4px;
`

export default function RangeBadge({
  removed,
  inRange,
}: {
  removed: boolean | undefined
  inRange: boolean | undefined
}) {
  return (
    <BadgeWrapper className="badgeWrapper">
      {removed ? (
        <MouseoverTooltip text={<>Your position has 0 liquidity, and is not earning fees.</>}>
          <Badge variant={BadgeVariant.DEFAULT} className="range-badge">
            <BadgeText>
              <>Closed</>
            </BadgeText>
            <Closed width={16} height={16} />
          </Badge>
        </MouseoverTooltip>
      ) : inRange ? (
        <MouseoverTooltip
          text={<>The price of this pool is within your selected range. Your position is currently earning fees.</>}
        >
          <Badge variant={BadgeVariant.DEFAULT} className="range-badge">
            <ActiveText>
              <>In Range</>
            </ActiveText>
            <ActiveDot />
          </Badge>
        </MouseoverTooltip>
      ) : (
        <MouseoverTooltip
          text={
            <>The price of this pool is outside of your selected range. Your position is not currently earning fees.</>
          }
        >
          <Badge variant={BadgeVariant.WARNING} className="range-badge">
            <WarningText>
              <>Out of range</>
            </WarningText>
            <Warning width={16} height={16} />
          </Badge>
        </MouseoverTooltip>
      )}
    </BadgeWrapper>
  )
}
