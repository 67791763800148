import { useWeb3React } from '@web3-react/core'
import { ReactComponent as BurgerIcon } from 'assets/svg/icons/BurgerMenu.svg'
import { ReactComponent as CloseIcon } from 'assets/svg/icons/Close.svg'
import Identicon from 'components/Identicon'
import ThemeToggle from 'components/Toggle/ThemeToggle'
import useENSAvatar from 'hooks/useENSAvatar'
import { useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { animated, useSpring, useTransition } from 'react-spring'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components'
import { shortenAddress } from 'utils'

import { StyledNavLink } from '.'
import NetworkSelector from './NetworkSelector'
import { SchemaItemMultiple, SchemaItemSingle, useSchema } from './Schema'
import Web3Status from './Web3Status'

const BurgerButtonWrapper = styled.div`
  display: flex;
  padding: 8px;
  :hover {
    background-color: ${({ theme }) => theme.cardPrimary};
    border-radius: 8px;
  }
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
`

export const BurgerButton = ({ opened = false, onClick }: { opened?: boolean; onClick?: () => void }) => {
  const t = useTransition(opened, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 50 },
  })

  return (
    <BurgerButtonWrapper onClick={onClick}>
      {t((styles, item, _, key) => (
        <animated.div style={styles} key={key}>
          {!opened ? <BurgerIcon /> : <CloseIcon />}
        </animated.div>
      ))}
    </BurgerButtonWrapper>
  )
}

const BurgerMenuOverlay = styled.div`
  position: absolute;
  background: red;
  width: 100%;
  height: ${() => window.screen.height}px;
  top: 0;
  background: ${({ theme }) => theme.backgroundSurface};
`

const BurgerBlock = styled.div`
  padding-top: 20px;
  & > * {
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const BurgerMenuContent = styled.div`
  z-index: 10;
  position: relative;
  display: flex;
  flex-direction: column;
  & > ${BurgerBlock}:not(:last-child)::after {
    content: '';
    border-bottom: 1px solid ${({ theme }) => theme.cardBorderPrimary};
    width: 100%;
    display: block;
    padding-bottom: 20px;
  }
`

const BurgerMenuSubLinksWrapper = styled.div<{ opened: boolean }>`
  max-height: ${({ opened }) => `${opened ? 'auto' : '0px'}`};
  overflow: hidden;
  transition-property: max-height, height;
  transition-duration: 4s;
  // padding: ${({ opened }) => `${opened ? '20px' : '0px'}`} !important;
`

const BurgerDivider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  width: 100%;
`

const BurgerLink = ({
  id,
  to,
  isActive,
  label,
  external,
  sublinks,
  marginLeft,
  onClick,
}: { onClick?: () => void; sublinks?: SchemaItemMultiple['sublinks'] } & Omit<SchemaItemSingle, 'sublinks'> & {
    marginLeft?: string
    isActive?: boolean
  }) => {
  const [opened, setOpened] = useState(false)
  const switchOpened = () => {
    setOpened((prev) => !prev)
  }
  const handleClick = () => {
    switchOpened()
    onClick && onClick()
  }
  return (
    <>
      <StyledNavLink
        onClick={handleClick}
        id={id}
        to={to}
        isActive={isActive}
        style={{
          marginLeft,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          height: '64px',
        }}
        width="100%"
      >
        <span>
          {label}
          {external && (
            <>
              {' '}
              <sup>↗</sup>
            </>
          )}
        </span>
        {sublinks && (opened ? <ChevronUp /> : <ChevronDown />)}
      </StyledNavLink>
      <BurgerMenuSubLinksWrapper opened={opened}>
        {sublinks &&
          sublinks.map(({ id, href, label }) => (
            <BurgerLink key={id} id={id} to={href} label={label} marginLeft="20px" />
          ))}
      </BurgerMenuSubLinksWrapper>
    </>
  )
}

const BurgerControlsWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`

const BurgerAccountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  & > * {
    margin-right: 8px;
  }
  align-items: center;
`

export const BurgerAccount = () => {
  const { account } = useWeb3React()
  const avatar = useENSAvatar(account)
  return (
    <BurgerAccountWrapper>
      <Identicon /> {account ? shortenAddress(account, 6) : <></>}
    </BurgerAccountWrapper>
  )
}

export const BurgerMenu = ({ show, onClose }: { show: boolean; onClose?: () => void }) => {
  const schema = useSchema()
  const { account } = useWeb3React()

  const [darkMode, toggleDarkMode] = useDarkModeManager()

  const props = useSpring({
    transform: show ? 'translateX(0)' : 'translateX(-100%)',
    from: { transform: 'translateX(-100%)' },
    config: { duration: 100 },
  })

  return (
    <animated.div style={{ ...props, width: '100%' }}>
      <BurgerMenuOverlay />
      <BurgerMenuContent>
        <BurgerBlock>
          {schema.map((item) => {
            return item.to ? (
              <BurgerLink key={item.id} {...item} onClick={onClose} />
            ) : (
              <BurgerLink key={item.id} id={item.id} label={item.label} to="#" sublinks={item.sublinks} />
            )
          })}
        </BurgerBlock>
        <BurgerBlock>
          <BurgerControlsWrapper>
            <ThemeToggle onToggle={toggleDarkMode} isDarkMode={darkMode} />
            <NetworkSelector />
            {!account ? <Web3Status /> : <BurgerAccount />}
          </BurgerControlsWrapper>
        </BurgerBlock>
      </BurgerMenuContent>
    </animated.div>
  )
}
