import { CurrencyAmount } from '@kodiak-finance/sdk-core'
import { Token } from '@kodiak-finance/sdk-core'
import { ButtonError } from 'components/Button'
import { LightCard } from 'components/Card'
import Column, { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import Row, { RowBetween } from 'components/Row'
import ConfirmationTransactionStep, {
  ConfirmationTransactionStepProps,
} from 'components/StepLine/common/ConfirmationTransactionStep'
import TimeBetweenText from 'components/TimeBetweenText'
import { useMemo } from 'react'
import { Text } from 'rebass'
import { useTheme } from 'styled-components'
import { Separator } from 'theme'

type RedeemStepProps = {
  amount?: CurrencyAmount<Token>
  duration?: number
  next: () => void
  onRedeem: () => void
} & Omit<ConfirmationTransactionStepProps, 'children' | 'onConfirm'>

const RedeemStep = ({ amount, next, onRedeem, duration, ...rest }: RedeemStepProps) => {
  const theme = useTheme()
  const date = useMemo(() => {
    if (duration === undefined) return undefined

    return new Date(Date.now() + duration * 1000)
  }, [duration])

  return (
    <ConfirmationTransactionStep onConfirm={next} {...rest}>
      <Column gap="6px" width="100%">
        <LightCard>
          <AutoColumn gap="6px">
            <RowBetween>
              <Text color={theme.textPrimary} fontSize={18}>
                {amount?.toExact()}
              </Text>
              <Text>
                <Row>
                  <CurrencyLogo currency={amount?.currency} />
                  <Text marginLeft="4px" color={theme.textPrimary} fontSize={16}>
                    {amount?.currency.symbol}
                  </Text>
                </Row>
              </Text>
            </RowBetween>
            <Separator />
            <RowBetween>
              <Text color={theme.textSecondary} fontSize={14}>
                Unlock time
              </Text>
              <Text color={theme.textSecondary} fontSize={14}>
                {date !== undefined && <TimeBetweenText date={date} />}
              </Text>
            </RowBetween>
          </AutoColumn>
        </LightCard>
        <ButtonError onClick={onRedeem}>Redeem</ButtonError>
      </Column>
    </ConfirmationTransactionStep>
  )
}

export default RedeemStep
