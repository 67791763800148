import { useProtocolDataV3 } from 'hooks/useProtocolDayDatas'
import { useContext } from 'react'
import { ThemeContext } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { ExternalLink } from 'theme/components'
// import { ReactComponent as SearchIcon } from '../../assets/svg/icons/Search.svg'
import { formatDollarAmount } from 'utils/formatDollarAmt'

import * as S from '../Liquidity.styles'

export default function V3Header() {
  const theme = useContext(ThemeContext)

  const { data: protocolDataV3, isLoading: isDataLoadingV3, isError: isDataErrorV3 } = useProtocolDataV3()

  return (
    <S.Header>
      <S.HeaderTitleFlex>
        <ThemedText.DeprecatedBody fontSize="18px" fontWeight="700">
          V3 Pools
        </ThemedText.DeprecatedBody>

        <ThemedText.DeprecatedBody fontSize="14px" fontWeight="400" color={theme.textSecondary}>
          Provide liquidity in Kodiak v3 pools
        </ThemedText.DeprecatedBody>

        <ExternalLink href={process.env.REACT_APP_V3_ANALYTICS || ''}>Top V3 Pools ↗</ExternalLink>
      </S.HeaderTitleFlex>
      <S.HeaderBoxFlex>
        <S.HeaderBox>
          <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" color={theme.textSecondary} lineHeight="14px">
            TVL
          </ThemedText.DeprecatedBody>

          <ThemedText.DeprecatedBody fontSize="20px" fontWeight="400" lineHeight="28px">
            {formatDollarAmount(protocolDataV3?.tvlUSD)}
          </ThemedText.DeprecatedBody>
        </S.HeaderBox>

        <S.HeaderBoxDesktop>
          <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" color={theme.textSecondary} lineHeight="14px">
            Volume
          </ThemedText.DeprecatedBody>

          <ThemedText.DeprecatedBody fontSize="20px" fontWeight="400" lineHeight="28px">
            {formatDollarAmount(protocolDataV3?.totalVolumeUSD)}
          </ThemedText.DeprecatedBody>
        </S.HeaderBoxDesktop>
      </S.HeaderBoxFlex>
    </S.Header>
  )
}
