import { ReactNode } from 'react'

import { IslandContext } from './IslandContext'
import { IslandContextType } from './IslandContextType'

interface IslandProviderProps {
  children: ReactNode
  value: IslandContextType
}

export const IslandProvider: React.FC<IslandProviderProps> = ({
  children,
  value,
}: {
  children: ReactNode
  value: IslandContextType
}) => {
  return <IslandContext.Provider value={value}>{children} </IslandContext.Provider>
}
