import { createReducer } from '@reduxjs/toolkit'
import { SnapshotType } from 'pages/Islands/IslandPage/IslandAnalyticsCard/utils/chartHelpers'

import { setLoading, setSnapshotData } from './actions'

export type SnapshotState = {
  [islandId: string]: {
    snapshots: SnapshotType[]
    lastUpdate: number
    loading: boolean
  }
}

export const initialState: SnapshotState = {}

export default createReducer<SnapshotState>(initialState, (builder) => {
  builder.addCase(setLoading, (state, { payload }) => {
    state[payload.islandId] = {
      ...(state[payload.islandId] || { snapshots: [], lastUpdate: 0 }),
      loading: payload.loading,
    }
  })
  builder.addCase(setSnapshotData, (state, { payload }) => {
    const dateNow = Date.now()
    state[payload.islandId] = {
      snapshots: payload.snapshots,
      lastUpdate: dateNow,
      loading: false,
    }
  })
})
