import { MaxButton } from 'pages/Pool/styleds'
import { Text } from 'rebass'
import styled from 'styled-components/macro'

export const IslandCardFlex = styled.div`
  background: ${({ theme }) => theme.cardPrimary};
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  border-radius: 8px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09);
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const UnstakeManualUnlockButton = styled.button<{ clickable?: boolean }>`
  background: transparent;
  padding: 2px;
  border: none;
  &:hover {
    ${({ clickable }) =>
      clickable &&
      `
    cursor: pointer;
    opacity: 0.5;
    `}
  }
`

export const InnerIslandCardFlex = styled.div`
  background: ${({ theme }) => theme.cardSecondary};
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  border-radius: 8px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

export const CurrencyFlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const IslandManagementCard = styled(IslandCardFlex)`
  height: fit-content;
  width: 384px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
     width: 100%;
  `};
`

export const Option = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  cursor: pointer;
  padding: 4px 12px;
  text-align: center;
  gap: 4px;
  border-radius: 3px;
  background: ${({ active, theme }) => (active ? theme.cardPrimary : 'transparent')};
  /* border: 1px solid ${({ theme }) => theme.backgroundOutline}; */

  pointer-events: ${({ active }) => active && 'none'};
`

export const Switch = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px;
  border-radius: 6px;
  height: 36px;
  background: ${({ theme }) => theme.cardSecondary};
`

export const DepositWithdrawSwitch = styled(Switch)`
  height: 46px;
`

export const DepositWithdrawOption = styled(Option)`
  height: 100%;
`

export const TransactionInfoText = styled.p`
  color: ${({ theme }) => theme.textSecondary};

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const SmallMaxButton = styled(MaxButton)`
  font-size: 12px;
  width: 22%;
`

export const ResponsiveHeaderText = styled(Text)`
  font-size: 24px;
  font-weight: 500;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
     font-size: 24px;
  `};
`
