import { CurrencyAmount, Token } from '@kodiak-finance/sdk-core'
import { CommunalFarm } from 'abis/types'
import { useStepLineState } from 'components/StepLine/context/StepLineContext'
import { useCallback, useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { calculateGasMargin } from 'utils/calculateGasMargin'

import { SelectLockTimeState } from '../steps/SelectLockTimeStep'

export const useOnStake = (
  stakeAmount: CurrencyAmount<Token> | undefined,
  farmContract: CommunalFarm | null,
  clear?: () => void
) => {
  const { lockTime } = useStepLineState<SelectLockTimeState>()

  const [attemptingTxnStake, setAttemptingTxnStake] = useState(false)
  const pendingTextStake = 'Stake'
  const [txHashStake, setTxHashStake] = useState<string>('')
  const addTransaction = useTransactionAdder()

  const onStake = useCallback(
    async function onStake() {
      if (lockTime === undefined || !stakeAmount || !farmContract) {
        return
      }

      const args = [stakeAmount.quotient.toString(), lockTime.toString()] as [string, string]

      setAttemptingTxnStake(true)

      const estimatedGasLimit = await farmContract.estimateGas.stakeLocked(...args)

      try {
        const response = await farmContract.stakeLocked(...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
        })

        setTxHashStake(response.hash)

        addTransaction(response, {
          islandId: stakeAmount.currency.address,
          lockTime: lockTime.toString(),
          stakeAmount: stakeAmount.quotient.toString(),
          type: TransactionType.STAKE_LIQUIDITY_ISLAND,
        })

        clear && clear()
      } catch (e) {
        console.error(e)
      } finally {
        setAttemptingTxnStake(false)
      }
    },
    [attemptingTxnStake, pendingTextStake, txHashStake, addTransaction, stakeAmount, lockTime, farmContract]
  )

  return {
    onStake,
    attemptingTxnStake,
    pendingTextStake,
    txHashStake,
    clear: () => {
      setAttemptingTxnStake(false)
      setTxHashStake('')
      clear && clear()
    },
  }
}
