import { CurrencyAmount } from '@kodiak-finance/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { ReactComponent as KodiakToken } from 'assets/svg/icons/KodiakToken.svg'
import { ReactComponent as Locked } from 'assets/svg/icons/Locked.svg'
import { ReactComponent as Stopwatch } from 'assets/svg/icons/Stopwatch.svg'
import { ReactComponent as Unlocked } from 'assets/svg/icons/Unlocked.svg'
import { ReactComponent as Wallet } from 'assets/svg/icons/Wallet.svg'
import PoweredByKodiak from 'components/PoweredByKodiak'
import { useToken } from 'hooks/Tokens'
import { useKDK, useXKDK } from 'hooks/useContract'
import useStaking, { useDividends } from 'hooks/useStaking'
import { useSubgraphTokenValue } from 'hooks/useSubgraphTokenPrice'
import useTheme from 'hooks/useTheme'
import { useSingleCallResult } from 'lib/hooks/multicall'
import useCurrencyLogoURIs from 'lib/hooks/useCurrencyLogoURIs'
import sumCurrencyAmount from 'lib/utils/sumCurrencyAmount'
import { useCallback, useMemo, useState } from 'react'
import { ArrowRight, ExternalLink } from 'react-feather'
import { Link } from 'react-router-dom'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

import { formatUSDPriceWithCommas } from '../../nft/utils/currency'
import { ThemedText } from '../../theme'
import ConvertView from './ConvertView'
import RedeemView from './RedeemView'
import * as S from './styleds'
import VestingView from './VestingView'

export default function StakingPage() {
  const [direction, setDirection] = useState<'convert' | 'redeem' | 'vesting'>('convert')
  const { available, redeeming, allocated, redeems } = useStaking()
  const { totalAllocation, userAllocation, deallocationFee } = useDividends()
  const xKDK = useXKDK()
  const kdk = useKDK()
  const kdkToken = useToken(kdk?.address)
  const xKdkToken = useToken(xKDK?.address)

  const sum = useMemo(() => sumCurrencyAmount([available, redeeming, allocated]), [available, redeeming, allocated])
  const theme = useTheme()
  const isActiveVesting = (redeems?.length || 0) > 0
  const totalSupplyRaw = useSingleCallResult(xKDK, 'totalSupply', [])

  const totalSupply = useMemo(() => {
    if (totalSupplyRaw?.result && kdkToken && totalSupplyRaw.result[0]) {
      // totalSupply from xKDK, but return CurrencyAmount for KDK to calculate the price
      return CurrencyAmount.fromRawAmount(kdkToken, totalSupplyRaw.result[0].toString())
    }
    return undefined
  }, [totalSupplyRaw, kdkToken])

  const tvl = useSubgraphTokenValue(totalSupply)

  const { connector, chainId } = useWeb3React()
  const logoURL = useCurrencyLogoURIs(xKdkToken)[0]

  const addToken = useCallback(() => {
    if (!xKdkToken?.symbol || !connector.watchAsset) return
    connector.watchAsset({
      address: xKdkToken.address,
      symbol: xKdkToken.symbol,
      decimals: xKdkToken.decimals,
      image: logoURL,
    })
  }, [connector, logoURL, xKdkToken])

  return (
    <>
      <S.Wrapper>
        <>
          <S.StakingCardFlex>
            <S.FlexRowBetween>
              <S.FlexCol>
                <ThemedText.DeprecatedBody fontSize="18px" fontWeight="700" lineHeight="28px" color={theme.textPrimary}>
                  Token Staking
                </ThemedText.DeprecatedBody>

                <ThemedText.DeprecatedBody
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="20px"
                  color={theme.textSecondary}
                >
                  Convert, redeem, and manage your xKDK plugins allocations.
                </ThemedText.DeprecatedBody>
              </S.FlexCol>

              <S.ResponsiveFlexRowCenter>
                {tvl && (
                  <S.TopStatBox>
                    <ThemedText.DeprecatedBody
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="20px"
                      color={theme.textSecondary}
                    >
                      TVL
                    </ThemedText.DeprecatedBody>
                    <S.FlexRowCenter>
                      <KodiakToken />
                      <ThemedText.DeprecatedBody
                        fontSize="18px"
                        fontWeight="700"
                        lineHeight="28px"
                        color={theme.textPrimary}
                      >
                        {formatUSDPriceWithCommas(tvl)}
                      </ThemedText.DeprecatedBody>
                    </S.FlexRowCenter>
                  </S.TopStatBox>
                )}
              </S.ResponsiveFlexRowCenter>
            </S.FlexRowBetween>

            <S.FlexRowCenter>
              <S.ExternalLinkButton>
                <Link to="#" onClick={addToken}>
                  Add to wallet
                </Link>
                <ExternalLink size={20} />
              </S.ExternalLinkButton>
              <S.ExternalLinkButton>
                <Link to={`/swap/${kdk?.address}`} target="_blank">
                  Get KDK
                </Link>
                <ExternalLink size={20} />
              </S.ExternalLinkButton>
              {kdk && chainId && (
                <S.ExternalLinkButton>
                  <Link to={getExplorerLink(chainId, kdk?.address, ExplorerDataType.ADDRESS)} target="_blank">
                    Contract
                  </Link>
                  <ExternalLink size={20} />
                </S.ExternalLinkButton>
              )}
            </S.FlexRowCenter>
          </S.StakingCardFlex>

          <S.StakingCardFlex>
            <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" lineHeight="14px" color={theme.textPrimary}>
              Stats
            </ThemedText.DeprecatedBody>
            <S.ResponsiveFlexRowCenter>
              <S.InnerStakingCardFlex>
                <ThemedText.DeprecatedBody
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="20px"
                  color={theme.textSecondary}
                >
                  Total xKDK
                </ThemedText.DeprecatedBody>
                <S.FlexRowCenterBetween>
                  <ThemedText.DeprecatedBody
                    fontSize="18px"
                    fontWeight="700"
                    lineHeight="28px"
                    color={theme.textPrimary}
                  >
                    {sum?.toSignificant(6) || '0'}
                  </ThemedText.DeprecatedBody>

                  <Wallet height={20} width={20} fill={theme.buttonPrimary} />
                </S.FlexRowCenterBetween>
              </S.InnerStakingCardFlex>

              <S.InnerStakingCardFlex>
                <ThemedText.DeprecatedBody
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="20px"
                  color={theme.textSecondary}
                >
                  Available xKDK
                </ThemedText.DeprecatedBody>
                <S.FlexRowCenterBetween>
                  <ThemedText.DeprecatedBody
                    fontSize="18px"
                    fontWeight="700"
                    lineHeight="28px"
                    color={theme.textPrimary}
                  >
                    {available?.toSignificant(6) || '0'}
                  </ThemedText.DeprecatedBody>
                  <Unlocked height={16} width={16} color={theme.buttonPrimary} />
                </S.FlexRowCenterBetween>
              </S.InnerStakingCardFlex>

              <S.InnerStakingCardFlex>
                <ThemedText.DeprecatedBody
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="20px"
                  color={theme.textSecondary}
                >
                  Allocated xKDK
                </ThemedText.DeprecatedBody>
                <S.FlexRowCenterBetween>
                  <ThemedText.DeprecatedBody
                    fontSize="18px"
                    fontWeight="700"
                    lineHeight="28px"
                    color={theme.textPrimary}
                  >
                    {allocated?.toSignificant(6) || '0'}
                  </ThemedText.DeprecatedBody>
                  <Locked height={16} width={16} color={theme.buttonPrimary} />
                </S.FlexRowCenterBetween>
              </S.InnerStakingCardFlex>

              <S.InnerStakingCardFlex>
                <ThemedText.DeprecatedBody
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="20px"
                  color={theme.textSecondary}
                >
                  Redeeming xKDK
                </ThemedText.DeprecatedBody>
                <S.FlexRowCenterBetween>
                  <ThemedText.DeprecatedBody
                    fontSize="18px"
                    fontWeight="700"
                    lineHeight="28px"
                    color={theme.textPrimary}
                  >
                    {redeeming?.toSignificant(6) || '0'}
                  </ThemedText.DeprecatedBody>

                  <Stopwatch height={16} width={16} color={theme.buttonPrimary} />
                </S.FlexRowCenterBetween>
              </S.InnerStakingCardFlex>
            </S.ResponsiveFlexRowCenter>
          </S.StakingCardFlex>

          <S.CardRowFlex>
            <S.StakingCardWrapperFlex>
              <S.StakingCardFlex>
                <S.FlexRowBetween>
                  <S.FlexCol>
                    <ThemedText.DeprecatedBody
                      fontSize="14px"
                      fontWeight="500"
                      lineHeight="14px"
                      color={theme.textPrimary}
                    >
                      Protocol Rewards
                    </ThemedText.DeprecatedBody>

                    <ThemedText.DeprecatedBody
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="20px"
                      color={theme.textSecondary}
                      style={{ marginRight: '24px' }}
                    >
                      Earn rewards from the protocol by staking your xKDK here."
                    </ThemedText.DeprecatedBody>
                  </S.FlexCol>

                  <S.StakeButton as={Link} to="/staking/rewards">
                    <ThemedText.DeprecatedBody
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="24px"
                      color={theme.buttonPrimary}
                    >
                      Stake
                    </ThemedText.DeprecatedBody>
                    <ArrowRight size={14} />
                  </S.StakeButton>
                </S.FlexRowBetween>

                <S.InnerStakingCardFlex>
                  <S.FlexRowBetween>
                    <S.TransactionInfoText>Your allocation</S.TransactionInfoText>
                    <S.TransactionInfoText>
                      {formatCurrencyAmount(userAllocation, 2)} {userAllocation?.currency.symbol}
                    </S.TransactionInfoText>
                  </S.FlexRowBetween>
                  <S.FlexRowBetween>
                    <S.TransactionInfoText>Total allocations</S.TransactionInfoText>
                    <S.TransactionInfoText>
                      {formatCurrencyAmount(totalAllocation, 2)} {userAllocation?.currency.symbol}
                    </S.TransactionInfoText>
                  </S.FlexRowBetween>
                  <S.FlexRowBetween>
                    <S.TransactionInfoText>Deallocation fee</S.TransactionInfoText>
                    <S.TransactionInfoText>{deallocationFee}%</S.TransactionInfoText>
                  </S.FlexRowBetween>
                </S.InnerStakingCardFlex>
              </S.StakingCardFlex>
            </S.StakingCardWrapperFlex>

            <S.StakingCardWrapperFlex>
              <S.StakingCardFlex>
                <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" lineHeight="14px" color={theme.textPrimary}>
                  xKDK{direction === 'convert' ? ' Conversion' : ' Redemption'}
                </ThemedText.DeprecatedBody>
                <S.DepositWithdrawSwitch>
                  <S.DepositWithdrawOption
                    onClick={() => {
                      setDirection('convert')
                    }}
                    active={direction === 'convert'}
                  >
                    <>Convert</>
                  </S.DepositWithdrawOption>
                  <S.DepositWithdrawOption
                    onClick={() => {
                      // When switching to custom slippage, use `auto` value as a default.
                      setDirection('redeem')
                    }}
                    active={direction === 'redeem'}
                    tabIndex={-1}
                  >
                    <>Redeem</>
                  </S.DepositWithdrawOption>
                  {isActiveVesting && (
                    <S.DepositWithdrawOption
                      onClick={() => {
                        // When switching to custom slippage, use `auto` value as a default.
                        setDirection('vesting')
                      }}
                      active={direction === 'vesting'}
                      tabIndex={-1}
                    >
                      <>Vesting</>
                    </S.DepositWithdrawOption>
                  )}
                </S.DepositWithdrawSwitch>

                {direction === 'convert' ? <ConvertView /> : direction === 'vesting' ? <VestingView /> : <RedeemView />}
              </S.StakingCardFlex>
            </S.StakingCardWrapperFlex>
          </S.CardRowFlex>
        </>
      </S.Wrapper>

      {/* {onIslandSelect && (
        <IslandSearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onIslandSelect={handleIslandSelect}
          selectedIsland={islandDetails}
          showCommonBases={true}
          showIslandAmount={true}
          disableNonToken={true}
        />
      )} */}
      <PoweredByKodiak />
    </>
  )
}

// <AutoColumn gap="20px">
// {noLiquidity ||
//   (isCreate ? (
//     <ColumnCenter>
//       <BlueCard>
//         <AutoColumn gap="10px">
//           <ThemedText.DeprecatedLink fontWeight={600} color={'deprecated_primaryText1'}>
//             <>You are the first liquidity provider.</>
//           </ThemedText.DeprecatedLink>
//           <ThemedText.DeprecatedLink fontWeight={400} color={'deprecated_primaryText1'}>
//             <>The ratio of tokens you add will set the price of this pool.</>
//           </ThemedText.DeprecatedLink>
//           <ThemedText.DeprecatedLink fontWeight={400} color={'deprecated_primaryText1'}>
//             <>Once you are happy with the rate click supply to review.</>
//           </ThemedText.DeprecatedLink>
//         </AutoColumn>
//       </BlueCard>
//     </ColumnCenter>
//   ) : (
//     <ColumnCenter>
//       <BlueCard>
//         <AutoColumn gap="10px">
//           <ThemedText.DeprecatedLink fontWeight={400} color={'deprecated_primaryText1'}>
//             <>
//               <b>
//                 <>Tip:</>
//               </b>{' '}
//               When you add liquidity, you will receive pool tokens representing your position. These tokens
//               automatically earn fees proportional to your share of the pool, and can be redeemed at any
//               time.
//             </>
//           </ThemedText.DeprecatedLink>
//         </AutoColumn>
//       </BlueCard>
//     </ColumnCenter>
//   ))}
// <CurrencyInputPanel
//   value={formattedAmounts[Field.CURRENCY_A]}
//   onUserInput={onFieldAInput}
//   onMax={() => {
//     onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
//   }}
//   onCurrencySelect={handleCurrencyASelect}
//   showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
//   currency={currencies[Field.CURRENCY_A] ?? null}
//   id="add-liquidity-input-tokena"
//   showCommonBases
// />
// <ColumnCenter>
//   <Plus size="16" color={theme.deprecated_text2} />
// </ColumnCenter>
// <CurrencyInputPanel
//   value={formattedAmounts[Field.CURRENCY_B]}
//   onUserInput={onFieldBInput}
//   onCurrencySelect={handleCurrencyBSelect}
//   onMax={() => {
//     onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
//   }}
//   showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
//   currency={currencies[Field.CURRENCY_B] ?? null}
//   id="add-liquidity-input-tokenb"
//   showCommonBases
// />
// {currencies[Field.CURRENCY_A] && currencies[Field.CURRENCY_B] && pairState !== PairState.INVALID && (
//   <>
//     <LightCard padding="0px" $borderRadius={'8px'}>
//       <RowBetween padding="1rem">
//         <ThemedText.DeprecatedSubHeader fontWeight={500} fontSize={14}>
//           {noLiquidity ? (
//             <>Initial prices and pool share</>
//           ) : (
//             <>Prices and pool share</>
//           )}
//         </ThemedText.DeprecatedSubHeader>
//       </RowBetween>{' '}
//       <LightCard padding="1rem" $borderRadius={'0'}>
//         <PoolPriceBar
//           currencies={currencies}
//           poolTokenPercentage={poolTokenPercentage}
//           noLiquidity={noLiquidity}
//           price={price}
//         />
//       </LightCard>
//     </LightCard>
//   </>
// )}

// {addIsUnsupported ? (
//   <ButtonPrimary disabled={true}>
//     <ThemedText.DeprecatedMain mb="4px">
//       <>Unsupported Asset</>
//     </ThemedText.DeprecatedMain>
//   </ButtonPrimary>
// ) : !account ? (
//   <TraceEvent
//     events={[Event.onClick]}
//     name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
//     properties={{ received_swap_quote: false }}
//     element={ElementName.CONNECT_WALLET_BUTTON}
//   >
//     <ButtonLight onClick={toggleWalletModal}>
//       <>Connect Wallet</>
//     </ButtonLight>
//   </TraceEvent>
// ) : (
//   <AutoColumn gap={'md'}>
//     {(approvalA === ApprovalState.NOT_APPROVED ||
//       approvalA === ApprovalState.PENDING ||
//       approvalB === ApprovalState.NOT_APPROVED ||
//       approvalB === ApprovalState.PENDING) &&
//       isValid && (
//         <RowBetween>
//           {approvalA !== ApprovalState.APPROVED && (
//             <ButtonPrimary
//               onClick={approveACallback}
//               disabled={approvalA === ApprovalState.PENDING}
//               width={approvalB !== ApprovalState.APPROVED ? '48%' : '100%'}
//             >
//               {approvalA === ApprovalState.PENDING ? (
//                 <Dots>
//                   <>Approving {currencies[Field.CURRENCY_A]?.symbol}</>
//                 </Dots>
//               ) : (
//                 <>Approve {currencies[Field.CURRENCY_A]?.symbol}</>
//               )}
//             </ButtonPrimary>
//           )}
//           {approvalB !== ApprovalState.APPROVED && (
//             <ButtonPrimary
//               onClick={approveBCallback}
//               disabled={approvalB === ApprovalState.PENDING}
//               width={approvalA !== ApprovalState.APPROVED ? '48%' : '100%'}
//             >
//               {approvalB === ApprovalState.PENDING ? (
//                 <Dots>
//                   <>Approving {currencies[Field.CURRENCY_B]?.symbol}</>
//                 </Dots>
//               ) : (
//                 <>Approve {currencies[Field.CURRENCY_B]?.symbol}</>
//               )}
//             </ButtonPrimary>
//           )}
//         </RowBetween>
//       )}
//     <ButtonError
//       onClick={() => {
//         expertMode ? onAdd() : setShowConfirm(true)
//       }}
//       disabled={!isValid || approvalA !== ApprovalState.APPROVED || approvalB !== ApprovalState.APPROVED}
//       error={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}
//     >
//       <Text fontSize={20} fontWeight={500}>
//         {error ?? <>Supply</>}
//       </Text>
//     </ButtonError>
//   </AutoColumn>
// )}
// </AutoColumn>
