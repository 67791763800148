import { formatDistance } from 'date-fns'
import { useCallback, useLayoutEffect, useMemo, useState } from 'react'

export const useTimeBetweenText = (date0: Date, date1OrNow?: Date) => {
  const date1 = useMemo(() => date1OrNow || new Date(), [date1OrNow])
  const [distance, setDistance] = useState('')

  const updateDistance = useCallback(() => {
    setDistance(formatDistance(date0, date1, { addSuffix: true }))
  }, [date0, date1])

  useLayoutEffect(() => {
    updateDistance()
  }, [date0, date1])

  return distance.length > 0 ? distance : undefined
}

export default useTimeBetweenText
