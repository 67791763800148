import { CurrencyAmount, Token } from '@kodiak-finance/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { ButtonPrimary } from 'components/Button'
import Column from 'components/Column'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { useStepLineStateUpdate } from 'components/StepLine/context/StepLineContext'
import { useToken } from 'hooks/Tokens'
import { useXKDK } from 'hooks/useContract'
import { useTokenBalance } from 'lib/hooks/useCurrencyBalance'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { useMemo, useState } from 'react'

export type AllocationStepState = {
  amount: CurrencyAmount<Token> | undefined
}

const AllocateViewStep = ({ next }: { next: () => void }) => {
  const [inputKdk, setInputKdk] = useState('')
  const xKdk = useXKDK()
  const xKdkToken = useToken(xKdk?.address)
  const update = useStepLineStateUpdate<AllocationStepState>()

  const amount = useMemo(() => {
    const amount = tryParseCurrencyAmount(inputKdk, xKdkToken || undefined)
    update({ amount })
    return amount
  }, [inputKdk, xKdkToken])

  const { account } = useWeb3React()
  const balance = useTokenBalance(account, xKdkToken || undefined)

  return (
    <Column width="100%" gap="8px">
      <CurrencyInputPanel
        // hideBalance={true}
        value={inputKdk}
        onUserInput={setInputKdk}
        onMax={() => setInputKdk(balance?.toExact() ?? '0')}
        showMaxButton={true}
        currency={xKdkToken}
        label="kdk"
        id="convert-kdk"
      />
      <ButtonPrimary disabled={!amount || !amount.greaterThan('0')} onClick={next}>
        Allocate
      </ButtonPrimary>
    </Column>
  )
}

export default AllocateViewStep
