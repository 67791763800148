import { BigNumber } from 'ethers'
import { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { changeLockTime } from '../actions'
import { Farm } from '../reducer'

export const MULTIPLIER_PRECISION = BigNumber.from(10).pow(18)

type UseLockTimeResult = {
  lockTime: number
  setLockTime: (value: number) => void
}

export function useLockTimeMultiplier(value: number, farm?: Farm) {
  const bnValue = useMemo(() => BigNumber.from(value), [value])

  return useMemo(() => {
    if (!farm) return 1
    const lockMultiplier = MULTIPLIER_PRECISION.add(
      bnValue.mul(BigNumber.from(farm.lockMultiplierMax.toString()).sub(MULTIPLIER_PRECISION)).div(farm.lockTimeMax)
    )
    let ret = 0
    if (lockMultiplier.gt(farm.lockMultiplierMax.toString())) {
      ret = parseFloat(farm.lockMultiplierMax.toString())
    }
    ret = parseFloat(lockMultiplier.toString())

    return ret / 1e18
  }, [farm?.lockMultiplierMax, farm?.lockTimeMax, bnValue])
}

export default function useLockTimeValue(min = 0, max = 0): UseLockTimeResult {
  const state = useAppSelector((state) => state.farms.lockTimeValue)
  const dispatch = useAppDispatch()

  const setState = useCallback(
    (newValue: number) => {
      if (newValue < min) {
        newValue = min
      }
      if (newValue > max) {
        newValue = max
      }
      dispatch(changeLockTime(newValue))
    },
    [dispatch, state, min, max]
  )

  useEffect(() => {
    setState(state)
  }, [min, max])

  return {
    lockTime: state,
    setLockTime: setState,
  }
}
