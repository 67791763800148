import { useWeb3React } from '@web3-react/core'
import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import PoweredByKodiak from 'components/PoweredByKodiak'
import { RowBetween } from 'components/Row'
import { SearchIcon, SearchInput } from 'components/SearchModal/styleds'
import { SwitchLocaleLink } from 'components/SwitchLocaleLink'
import { isSupportedChain } from 'constants/chains'
import { useContext, useEffect, useState } from 'react'
import { AlertTriangle } from 'react-feather'
import { useLocation, useNavigate } from 'react-router-dom'
import { useToggleWalletModal } from 'state/application/hooks'
import styled, { css, ThemeContext } from 'styled-components/macro'
import { ThemedText } from 'theme'

import * as S from './Liquidity.styles'
import IslandHeader from './ProtocolHeaders/IslandHeader'
import V2Header from './ProtocolHeaders/V2Header'
import V3Header from './ProtocolHeaders/V3Header'
import Islands from './ProtocolLists/islands'
import PoolV2 from './ProtocolLists/v2'
import PoolV3 from './ProtocolLists/v3'

const ResponsiveButtonPrimary = styled(ButtonPrimary)<{ disableExpand?: boolean }>`
  border-radius: 6px;
  padding: 6px 16px;
  border: none;
  width: fit-content;
  ${({ disableExpand, theme }) =>
    !disableExpand &&
    theme.mediaWidth.upToSmall`
    flex: 1 1 auto;
    width: 100%;
  `};

  ${({ disableExpand, theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `};
`
export const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 48px 0;
  max-width: 300px;
  /* min-height: 25vh; */
`

export const TitleRow = styled(RowBetween)`
  color: ${({ theme }) => theme.deprecated_text2};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  `};
`

const MainContentWrapper = styled.main`
  background-color: ${({ theme }) => theme.cardSecondary};
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  display: flex;
  flex-direction: column;
`

const IconStyle = css`
  width: 40px;
  height: 40px;
  margin-bottom: 0.5rem;
`

export const NetworkIcon = styled(AlertTriangle)`
  ${IconStyle}
`

function WrongNetworkCard() {
  const theme = useContext(ThemeContext)
  return (
    <>
      <S.PageWrapper style={{ padding: 0 }}>
        <AutoColumn gap="lg" style={{ width: '100%' }}>
          {/* <TitleRow style={{ marginTop: '1rem' }} padding={'0'}>
              <ThemedText.DeprecatedBody fontSize={'20px'}><>Islands Overview</>}</ThemedText.DeprecatedBody>
            </TitleRow> */}

          <MainContentWrapper>
            <ErrorContainer>
              <ThemedText.DeprecatedBody color={theme.deprecated_text3} textAlign="center">
                <NetworkIcon strokeWidth={1.2} />
                <div data-testid="pools-unsupported-err">
                  <>Your connected network is unsupported.</>
                </div>
              </ThemedText.DeprecatedBody>
            </ErrorContainer>
          </MainContentWrapper>
        </AutoColumn>
      </S.PageWrapper>
      {/* <PoweredByKodiak /> */}
    </>
  )
}

const Liquidity = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { account, chainId } = useWeb3React()
  const toggleWalletModal = useToggleWalletModal()

  const [searchParam, setSearchParam] = useState('')

  // Handle search input change
  const handleSearch = (e) => {
    setSearchParam(e.target.value)
  }

  // Define possible paths
  const paths = {
    islands: '/liquidity/islands',
    v3: '/liquidity/v3',
    v2: '/liquidity/v2',
  }

  // Determine the initial view based on URL
  const initialView = location.pathname.includes(paths.v3)
    ? 'V3'
    : location.pathname.includes(paths.v2)
    ? 'V2'
    : 'Islands'

  // State to track the current view
  const [currentView, setCurrentView] = useState(initialView)

  // Update state and URL when the view is changed
  const changeView = (view) => {
    setCurrentView(view)
    navigate(paths[view.toLowerCase()])
  }

  // Effect to sync state with URL changes
  useEffect(() => {
    const view = location.pathname.includes(paths.v3) ? 'V3' : location.pathname.includes(paths.v2) ? 'V2' : 'Islands'
    setCurrentView(view)
  }, [location])

  // Determine which header to render based on the current view
  const renderHeader = () => {
    switch (currentView) {
      case 'Islands':
        return <IslandHeader />
      case 'V3':
        return <V3Header />
      case 'V2':
        return <V2Header />
      default:
        return null
    }
  }

  // Determine which list to render based on the current view
  const renderList = () => {
    switch (currentView) {
      case 'Islands':
        return <Islands searchParam={searchParam} />
      case 'V3':
        return (
          <PoolV3 account={account} chainId={chainId} toggleWalletModal={toggleWalletModal} searchParam={searchParam} />
        )
      case 'V2':
        return (
          <PoolV2 account={account} chainId={chainId} toggleWalletModal={toggleWalletModal} searchParam={searchParam} />
        )
      default:
        return null
    }
  }

  return (
    <S.PageWrapper>
      {renderHeader()}

      <S.FilterFlex>
        <S.Switch>
          <S.Option onClick={() => changeView('Islands')} active={currentView === 'Islands'}>
            <ThemedText.DeprecatedBody
              fontSize="12px"
              fontWeight="500"
              lineHeight="20px"
              margin="0 16px"
              // color={viewAllIslands ? theme.textPrimary : theme.textSecondary}
            >
              Islands
            </ThemedText.DeprecatedBody>
          </S.Option>
          <S.Option onClick={() => changeView('V3')} active={currentView === 'V3'} tabIndex={-1}>
            <ThemedText.DeprecatedBody
              fontSize="12px"
              fontWeight="500"
              lineHeight="20px"
              margin="0 16px"
              // color={!viewAllIslands ? theme.textPrimary : theme.textSecondary}
            >
              V3 Pools
            </ThemedText.DeprecatedBody>
          </S.Option>
          <S.Option onClick={() => changeView('V2')} active={currentView === 'V2'} tabIndex={-1}>
            <ThemedText.DeprecatedBody
              fontSize="12px"
              fontWeight="500"
              lineHeight="20px"
              margin="0 16px"
              // color={!viewAllIslands ? theme.textPrimary : theme.textSecondary}
            >
              V2 Pools
            </ThemedText.DeprecatedBody>
          </S.Option>
        </S.Switch>

        <S.SearchWrapper>
          <SearchIcon height={20} width={20} />

          <SearchInput placeholder="Search for a token symbol" onChange={(e) => handleSearch(e)} />
          {/* <SearchInput placeholder="Search for a token symbol" /> */}
        </S.SearchWrapper>
      </S.FilterFlex>

      {account && !isSupportedChain(chainId) ? <WrongNetworkCard /> : renderList()}
      <PoweredByKodiak />
      <SwitchLocaleLink />
      {/* {renderList()} */}
    </S.PageWrapper>
  )
}

export default Liquidity
