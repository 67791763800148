import { useWeb3React } from '@web3-react/core'
import ISLAND_ABI from 'abis/island.json'
import { Island as IslandContract } from 'abis/types'
import { ethers } from 'ethers'
import { useSingleCallResult } from 'lib/hooks/multicall'
import useCurrencyBalance from 'lib/hooks/useCurrencyBalance'
import ms from 'ms.macro'
import { useEffect, useMemo, useState } from 'react'
import { useIslandByIdQuery, useIslandsQuery } from 'state/data/enhanced'
import { KodiakVault } from 'state/data/generatedV3'
import { getContract } from 'utils'
import { unwrappedToken } from 'utils/unwrappedToken'

import { useToken } from './Tokens'
import { useIslandContract } from './useContract'

export type IslandUserPosition = {
  balanceShares?: string | number
  balanceSharesUSD?: string | number
}
export type SubgraphIsland = {
  token0: KodiakVault['_token0']
  token1: KodiakVault['_token1']
  token0Amount: KodiakVault['_token0Amount']
  token1Amount: KodiakVault['_token1Amount']
  token0AmountUSD: KodiakVault['_token0AmountUSD']
  token1AmountUSD: KodiakVault['_token1AmountUSD']
} & KodiakVault

export type Island = {
  userPosition: IslandUserPosition | null
  tickUpper: number
  tickLower: number
} & SubgraphIsland

interface UsePrepareSubgraphIslandsReturn {
  isLoading: boolean
  isFetching: boolean
  isUninitialized: boolean
  isError: boolean
  data: SubgraphIsland[] | null
  // isStale: boolean
  refetch: () => void
}

interface UseIslandsReturn {
  islands: Island[] | null
  isLoading: boolean
  isFetching: boolean
  isUninitialized: boolean
  isError: boolean
  // isStale: boolean
  refetch: () => void
}

interface UseIslandReturn {
  island: Island | null
  isLoading: boolean
  isFetching: boolean
  isUninitialized: boolean
  isError: boolean
  // isStale: boolean
  refetch?: () => void
}

// maximum number of blocks past which we consider the data stale
const MAX_DATA_BLOCK_AGE = 20

function prepareIsland(island: KodiakVault): SubgraphIsland {
  return {
    ...island,
    name: island.name,
    symbol: island.symbol,
    token0: island._token0,
    token1: island._token1,
    token0Amount: island._token0Amount,
    token1Amount: island._token1Amount,
    token0AmountUSD: island._token0AmountUSD,
    token1AmountUSD: island._token1AmountUSD,
  }
}

function usePrepareSubgraphIslands(): UsePrepareSubgraphIslandsReturn {
  // const latestBlock = useBlockNumber()
  const queryResult = useIslandsQuery({
    pollingInterval: ms`90s`,
  })

  const { data, isLoading, isFetching, isUninitialized, isError, refetch } = queryResult

  const allIslands = data?.kodiakVaults

  const preparedData = useMemo(() => {
    return allIslands?.map(prepareIsland)
  }, [allIslands])

  // const isStale =
  //   latestBlock && data?._meta?.block?.number && latestBlock - data._meta.block.number > MAX_DATA_BLOCK_AGE

  // useEffect(() => {
  //   if (isStale) {
  //     sendEvent('exception', { description: `Graph stale (latest block: ${latestBlock})` })
  //   }
  // }, [isStale, latestBlock])

  return {
    isLoading,
    isFetching,
    isUninitialized,
    isError,
    data: preparedData,
    // isStale,
    refetch,
  }
}

// Get all islands

export function useIslands(): UseIslandsReturn {
  const {
    data: allIslands,
    isLoading,
    isFetching,
    isUninitialized,
    isError,
    // isStale,
    refetch,
  } = usePrepareSubgraphIslands()

  console.log('useIslands:allIslands', allIslands)

  const [islands, setIslands] = useState<Island[] | null>(null)
  const { provider, account } = useWeb3React()

  console.log('useIslands:account', account)

  useEffect(() => {
    let isMounted = true // Flag to track the component's mount status

    const checkPositions = async () => {
      const updatedIslands = []

      for (const island of allIslands) {
        const islandInterface = getContract(island.id, ISLAND_ABI, provider, undefined)
        let hasPosition = false
        let userPosition = null
        let tickUpper
        let tickLower

        try {
          // This is where we check for the user's position if the account is defined.
          if (account) {
            const balance = await islandInterface.balanceOf(account)
            hasPosition = balance > 0

            if (hasPosition) {
              // Fetch additional user position details, like balance shares, USD value, etc.
              const decimals = await islandInterface.decimals() // Ensure your contract has this method
              // const formattedBalance = Number(balance) / Math.pow(10, decimals)
              const formattedBalance = ethers.utils.formatUnits(balance, decimals)
              const userBalanceSharesUSD = parseFloat(formattedBalance) * island.outputTokenPriceUSD
              userPosition = {
                balanceShares: formattedBalance,
                balanceSharesUSD: userBalanceSharesUSD,
                // ... any other user position details
              }
            }
          }

          // Fetch upper and lower ticks for all islands regardless of account presence.
          tickUpper = await islandInterface.upperTick()
          tickLower = await islandInterface.lowerTick()
        } catch (err) {
          console.error('Failed to fetch island data', err)
        }

        updatedIslands.push({
          ...island,
          // userHasPosition: hasPosition,
          userPosition,
          tickUpper,
          tickLower,
        })
      }

      if (isMounted) {
        setIslands(updatedIslands)
      }
    }

    // Now we don't wait for an account to be present to fetch tick information.
    if (allIslands && provider) {
      checkPositions()
    }

    return () => {
      isMounted = false // Flag the component as unmounted
    }
  }, [allIslands, account, provider])

  console.log('useIslands:finalIslands', islands)
  return {
    islands,
    isLoading,
    isFetching,
    isUninitialized,
    isError,
    // isStale,
    refetch,
  }
}

export function useIslandTicks(islandContract?: IslandContract | null) {
  const lowerTickResponse = useSingleCallResult(islandContract, 'lowerTick')
  const upperTickResponse = useSingleCallResult(islandContract, 'upperTick')

  return useMemo(() => {
    if (lowerTickResponse.error || upperTickResponse.error) {
      return {
        lowerTick: undefined,
        upperTick: undefined,
        error: true,
        loading: false,
      }
    }
    if (lowerTickResponse.loading || upperTickResponse.loading) {
      return {
        lowerTick: null,
        upperTick: null,
        error: false,
        loading: true,
      }
    }

    const lowerTick = lowerTickResponse.result?.[0] as number
    const upperTick = upperTickResponse.result?.[0] as number

    return {
      lowerTick,
      upperTick,
      error: false,
      loading: false,
    }
  }, [lowerTickResponse, upperTickResponse])
}

// Get a single island by id
export function useIsland(islandId?: string): UseIslandReturn {
  // const latestBlock = useBlockNumber()
  const { account } = useWeb3React()
  const { data, isLoading, isFetching, isUninitialized, isError, refetch, error } = useIslandByIdQuery(islandId)

  const subgraphIsland = data?.kodiakVault

  // console.log('hook:subgraphIsland', subgraphIsland)
  // console.log('replace:preparedIsland', { ...prepareIsland(subgraphIsland) })

  // const [island, setIsland] = useState<Island | null>(null)

  const islandInterface = useIslandContract(islandId)

  const { lowerTick, upperTick, loading: ticksLoading, error: tickError } = useIslandTicks(islandInterface)

  const islandToken = useToken(islandId)

  const islandCurrency = (islandToken && unwrappedToken(islandToken)) || undefined

  const userBalanceShares = useCurrencyBalance(account, islandCurrency)

  // Calculate whether the island data is stale
  // const isStale = useMemo(() => {
  //   return (
  //     latestBlock &&
  //     subgraphIsland?._meta?.block?.number &&
  //     latestBlock - subgraphIsland._meta.block.number > MAX_DATA_BLOCK_AGE
  //   )
  // }, [latestBlock, subgraphIsland])

  // Calculate the island state using useMemo
  const island: Island | null = useMemo(() => {
    if (!subgraphIsland || ticksLoading || tickError) {
      return null
    }

    const balanceSharesUSD =
      userBalanceShares && subgraphIsland.outputTokenPriceUSD
        ? parseFloat(userBalanceShares.toSignificant()) * subgraphIsland.outputTokenPriceUSD
        : undefined

    return {
      ...prepareIsland(subgraphIsland),
      userPosition: {
        balanceShares: userBalanceShares?.toSignificant(),
        balanceSharesUSD,
      },
      tickUpper: upperTick?.toString(),
      tickLower: lowerTick?.toString(),
    }
  }, [subgraphIsland, userBalanceShares, upperTick, lowerTick, ticksLoading, tickError])

  if (islandId === '' || !islandId)
    return {
      island: null,
      isLoading: false,
      isError: true,
      isUninitialized: true,
      isFetching: false,
      // isStale: false,
      refetch: undefined,
    }

  // Event for stale data
  // useEffect(() => {
  //   if (isStale) {
  //     sendEvent('exception', { description: `Graph stale (latest block: ${latestBlock})` })
  //   }
  // }, [isStale, latestBlock])

  return {
    island,
    isLoading,
    isFetching,
    isUninitialized,
    isError,
    error,
    // isStale,
    refetch,
  }
}
