import { useWeb3React } from '@web3-react/core'
import { ButtonPrimary } from 'components/Button'
import { LightCard, OutlineCard } from 'components/Card'
import Column, { AutoColumn } from 'components/Column'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import Row, { AutoRow, RowBetween } from 'components/Row'
import useStepWithNext from 'components/StepLine/context/hooks/useStepWithNext'
import StepLineModal from 'components/StepLine/StepLineModal'
import { useToken } from 'hooks/Tokens'
import { useXKDK } from 'hooks/useContract'
import useStaking from 'hooks/useStaking'
import { useTokenBalance } from 'lib/hooks/useCurrencyBalance'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { useMemo, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { Link, Text } from 'rebass'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled, { useTheme } from 'styled-components'
import { calculateGasMargin } from 'utils/calculateGasMargin'

import RedeemStep from './RedeemStep'

const StyledController = styled(OutlineCard)<{ active?: boolean; pulsing?: boolean; disabled?: boolean }>`
  border-color: ${({ active, theme }) => (active ? theme.deprecated_blue1 : theme.cardBorderPrimary)};
  border-radius: 8px;
  height: 32px;
  width: 32px;

  background: ${({ theme }) => theme.cardPrimary};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ disabled, theme }) =>
    !disabled
      ? `
    :hover {
      border-color: ${theme.deprecated_blue1};
      background: ${theme.deprecated_blue1};
    }
    cursor: pointer;
  `
      : `
    opacity: 0.5;
  `}

  overflow: hidden;

  user-select: none;
`

type RedeemDateSelectorProps = {
  min?: number
  max?: number
  current?: number
  onChange?: (current: number) => void
}
const RedeemDateSelector = ({ min, max, current, onChange }: RedeemDateSelectorProps) => {
  const { months, days } = useMemo(() => {
    if (!current) return {}
    const days = Math.floor(current / 86400)
    const months = Math.floor(days / 30)
    const remainingDays = days % 30

    return { months, days: remainingDays }
  }, [current])
  const theme = useTheme()

  const increment = () => {
    if (!onChange || !max) return
    const newValue = (current || 0) + 86400 * 15
    if (newValue > max) return onChange(max)
    onChange(newValue)
  }

  const decrement = () => {
    if (!onChange || !min) return
    const newValue = (current || 0) - 86400 * 15
    if (newValue < min) return onChange(min)
    onChange(newValue)
  }

  return (
    <RowBetween>
      <AutoColumn>
        <Text fontSize={18} fontWeight={600}>
          Redeem duration
        </Text>
        <Link onClick={() => onChange && max && onChange(max)}>
          <Text fontSize={14}>Set max</Text>
        </Link>
      </AutoColumn>
      <AutoRow gap="8px" width="fit-content">
        <StyledController onClick={decrement}>-</StyledController>
        <Column>
          <div>
            <Text fontSize={14} fontWeight={500} color={theme.textSecondary}>
              Months
            </Text>
          </div>
          <div>
            <Text fontSize={14} textAlign="center" fontWeight={500} color={theme.textPrimary}>
              {months || 0}
            </Text>
          </div>
        </Column>
        <Column>
          <div>
            <Text fontSize={14} fontWeight={500} color={theme.textSecondary}>
              Days
            </Text>
          </div>
          <div>
            <Text fontSize={14} textAlign="center" fontWeight={500} color={theme.textPrimary}>
              {days || 0}
            </Text>
          </div>
        </Column>
        <StyledController onClick={increment}>+</StyledController>
      </AutoRow>
    </RowBetween>
  )
}

const RedeemView = () => {
  const [inputKdk, setInputKdk] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const xKdk = useXKDK()
  const xKdkToken = useToken(xKdk?.address)
  const amount = useMemo(() => {
    return tryParseCurrencyAmount(inputKdk, xKdkToken || undefined)
  }, [inputKdk, xKdkToken])
  const { minRedeemDuration, maxRedeemDuration } = useStaking()
  const { account } = useWeb3React()
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [hash, setHash] = useState('')
  const theme = useTheme()
  const balance = useTokenBalance(account, xKdkToken || undefined)
  const [redeemDuration, setRedeemDuration] = useState(minRedeemDuration)
  const addTransaction = useTransactionAdder()

  const onRedeem = async () => {
    if (!xKdk || !amount || !redeemDuration) return
    const quotient = amount.quotient.toString()

    setAttemptingTxn(true)
    try {
      const gas = await xKdk.estimateGas.redeem(quotient, redeemDuration)
      const res = await xKdk.redeem(quotient, redeemDuration, {
        gasLimit: calculateGasMargin(gas),
      })
      if (res.hash) {
        setHash(res.hash)
        addTransaction(res, {
          type: TransactionType.STAKE_REDEEM,
          amount: `${amount?.toSignificant(6)} ${amount?.currency?.symbol}`,
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      setAttemptingTxn(false)
    }
  }

  const redeemStep = useStepWithNext('redeem-step', RedeemStep, {
    amount,
    duration: redeemDuration,
    attemptingTxn,
    pendingText: (
      <>
        Converting {amount?.toSignificant(6)} {amount?.currency.symbol}
      </>
    ),
    hash,
    autoConfirm: true,
    transactionWaiting: true,
    onRedeem,
  })

  const handleConvert = () => {
    if (amount && amount.greaterThan('0')) {
      setIsModalOpen(true)
    }
  }

  return (
    <>
      <StepLineModal
        steps={[redeemStep]}
        isOpen={isModalOpen}
        onDismiss={() => setIsModalOpen(false)}
        onFinish={() => setIsModalOpen(false)}
      />
      <CurrencyInputPanel
        // hideBalance={true}
        value={inputKdk}
        onUserInput={setInputKdk}
        onMax={() => setInputKdk(balance?.toExact() || '0')}
        showMaxButton={true}
        currency={xKdkToken}
        label="kdk"
        // onCurrencySelect={handleSelectCurrencyA}
        id="convert-kdk"
      />
      <RedeemDateSelector
        min={minRedeemDuration}
        max={maxRedeemDuration}
        current={redeemDuration}
        onChange={setRedeemDuration}
      />
      {redeemDuration !== maxRedeemDuration && (
        <LightCard>
          <Row opacity={0.8}>
            <Text marginRight="8px" color={theme.warning500}>
              <AlertCircle />
            </Text>
            <Text fontSize={14} color={theme.warning500} marginLeft="8px">
              Using the minimum duration you will only get x0.5 tokens, and using the maximum duration you will get x1
            </Text>
          </Row>
        </LightCard>
      )}
      <ButtonPrimary disabled={!amount || !amount.greaterThan('0')} onClick={handleConvert}>
        Convert
      </ButtonPrimary>
    </>
  )
}

export default RedeemView
