import { useCallback, useMemo } from 'react'

import { useStepLineState, useStepLineStateUpdate } from '../StepLineContext'

export const useStepLineNext = (key: string) => {
  const stateKey = useMemo(() => `__nexts/${key}`, [key])
  const state = useStepLineState()
  const value = state[stateKey]

  const update = useStepLineStateUpdate()
  const next = useCallback(() => update({ [stateKey]: true }), [value, update, key])
  const back = useCallback(() => update({ [stateKey]: false }), [value, update, key])
  return {
    condition: !!value,
    next,
    back,
  }
}
