import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { setLoading, setPrice } from '../actions'
import { TokenPrice } from '../reducer'

export const useSetTokenPrice = () => {
  const dispatch = useAppDispatch()
  const _setLoading = useCallback(
    (tokenId: string | undefined | null, loading: boolean) => {
      if (!tokenId) {
        return
      }
      dispatch(setLoading({ tokenId, loading }))
    },
    [dispatch]
  )

  const setTokenPrice = useCallback(
    (tokenId: string | undefined | null, price: number | undefined | null) => {
      if (!tokenId || !price) {
        return
      }

      dispatch(setPrice({ tokenId, price }))
    },
    [dispatch]
  )
  return { setTokenPrice, setLoading: _setLoading }
}

export const useTokenPriceState = (token: string | undefined | null): TokenPrice | undefined => {
  const tokenPrice = useAppSelector((state) => state.tokenPrice)
  if (typeof token === 'string') {
    return tokenPrice[token]
  }

  return undefined
}
