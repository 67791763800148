import { ReactComponent as KodiakToken } from 'assets/svg/icons/KodiakToken.svg'
import { ButtonPrimary } from 'components/Button'
import { LightCard } from 'components/Card'
import Column, { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import FiatValue from 'components/FiatValue'
import { LoadingRows } from 'components/Loader/styled'
import PoweredByKodiak from 'components/PoweredByKodiak'
import Row, { AutoRow, RowBetween } from 'components/Row'
import TimeBetweenText from 'components/TimeBetweenText'
import { useKodiakRewards } from 'hooks/useContract'
import { useDividends } from 'hooks/useStaking'
import useTheme from 'hooks/useTheme'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'

import { HoverText, ThemedText } from '../../theme'
import ManagerAllocationButtons from './ManageAllocationButtons'
import * as S from './styleds'
import UserAllocationInfo from './UserAllocationInfo'
import UserClaimDividends from './UserClaimDividends'

export default function DividendsPage() {
  const theme = useTheme()
  const { totalAllocation, deallocationFee, rewardsInfo, nextCycleStartTime, pendingRewardsAmount } = useDividends()
  const rewards = useKodiakRewards()
  const [harvesting, setHarvesting] = useState(false)
  const isActiveHarvest = pendingRewardsAmount.some((amount) => amount !== undefined && amount.greaterThan('0'))
  const harvestRewards = () => {
    setHarvesting(true)

    rewards?.estimateGas
      .harvestAllRewards()
      .then((gas) => {
        rewards
          ?.harvestAllRewards({
            gasLimit: calculateGasMargin(gas),
          })
          .finally(() => setHarvesting(false))
      })
      .catch(() => setHarvesting(false))
  }

  return (
    <>
      <S.Wrapper>
        <>
          <Link
            data-cy="visit-staking"
            style={{ textDecoration: 'none', width: 'fit-content', marginBottom: '0.5rem' }}
            to="/staking"
          >
            <HoverText>
              <>← Back to KDK Overview</>
            </HoverText>
          </Link>
          <S.StakingCardFlex>
            <S.FlexRowBetween>
              <S.FlexCol>
                <ThemedText.DeprecatedBody fontSize="18px" fontWeight="700" lineHeight="28px" color={theme.textPrimary}>
                  Pool Rewards
                </ThemedText.DeprecatedBody>

                <ThemedText.DeprecatedBody
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="20px"
                  color={theme.textSecondary}
                >
                  Allocate xKDK here to earn a share of protocol earnings in the form of real yield.
                </ThemedText.DeprecatedBody>
              </S.FlexCol>

              <S.ResponsiveFlexRowCenter>
                <S.TopStatBox>
                  <ThemedText.DeprecatedBody
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="20px"
                    color={theme.textSecondary}
                  >
                    Total Allocations
                  </ThemedText.DeprecatedBody>
                  <S.FlexRowCenter>
                    <KodiakToken />
                    <ThemedText.DeprecatedBody
                      fontSize="18px"
                      fontWeight="700"
                      lineHeight="28px"
                      color={theme.textPrimary}
                    >
                      {formatCurrencyAmount(totalAllocation, 1)}
                    </ThemedText.DeprecatedBody>
                  </S.FlexRowCenter>
                </S.TopStatBox>

                <S.TopStatBox>
                  <ThemedText.DeprecatedBody
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="20px"
                    color={theme.textSecondary}
                  >
                    Deallocation fee
                  </ThemedText.DeprecatedBody>
                  <ThemedText.DeprecatedBody
                    fontSize="18px"
                    fontWeight="700"
                    lineHeight="28px"
                    color={theme.textPrimary}
                  >
                    {deallocationFee ?? '-'}%
                  </ThemedText.DeprecatedBody>
                </S.TopStatBox>
              </S.ResponsiveFlexRowCenter>
            </S.FlexRowBetween>
          </S.StakingCardFlex>

          <S.CardRowFlex>
            <S.StakingCardWrapperFlex>
              <S.StakingCardFlex>
                <AutoColumn>
                  <Text>Current epoch</Text>
                </AutoColumn>

                {rewardsInfo.length === 0 && (
                  <LightCard>
                    <LoadingRows>
                      <div></div>
                    </LoadingRows>
                  </LightCard>
                )}
                {rewardsInfo ? (
                  <AutoColumn gap="4px">
                    {rewardsInfo.map((info, index) => (
                      <LightCard key={index}>
                        {info ? (
                          <AutoRow gap="8px">
                            <div>
                              <CurrencyLogo size="32px" currency={info?.currentCycleDistributedAmount?.currency} />
                            </div>
                            <Column>
                              <Text fontSize={14} color={theme.textSecondary}>
                                {info?.currentCycleDistributedAmount?.currency?.symbol}
                              </Text>
                              <Row>
                                <Text fontSize={14} color={theme.textPrimary}>
                                  {formatCurrencyAmount(info?.currentDistributionAmount, 4)}
                                </Text>
                                <Text fontSize={14} color={theme.textSecondary} marginLeft="4px">
                                  (<FiatValue currencyAmount={info?.currentDistributionAmount} />)
                                </Text>
                              </Row>
                            </Column>
                          </AutoRow>
                        ) : (
                          <LoadingRows>
                            <div></div>
                          </LoadingRows>
                        )}
                      </LightCard>
                    ))}
                  </AutoColumn>
                ) : (
                  <></>
                )}

                <AutoColumn>
                  <Text>Next epoch</Text>
                </AutoColumn>
                <AutoColumn gap="4px">
                  <LightCard>
                    {!nextCycleStartTime || isNaN(nextCycleStartTime) ? (
                      <LoadingRows>
                        <div></div>
                      </LoadingRows>
                    ) : (
                      <RowBetween>
                        <Text color={theme.textSecondary} fontSize={14}>
                          Remaining time
                        </Text>
                        <Text color={theme.textPrimary} fontSize={14}>
                          <TimeBetweenText date={new Date(nextCycleStartTime * 1000)} />
                        </Text>
                      </RowBetween>
                    )}
                  </LightCard>
                </AutoColumn>
              </S.StakingCardFlex>
            </S.StakingCardWrapperFlex>

            <S.StakingCardWrapperFlex>
              <S.StakingCardFlex>
                <RowBetween>
                  <Text>Your allocation</Text>
                  <ManagerAllocationButtons />
                </RowBetween>
                <UserAllocationInfo />
                {pendingRewardsAmount.some((item) => item !== undefined) && (
                  <>
                    <RowBetween>
                      <Text>Your Rewards</Text>
                      <AutoRow width="fit-content">
                        <ButtonPrimary disabled={!isActiveHarvest || harvesting} onClick={harvestRewards}>
                          Claim all
                        </ButtonPrimary>
                      </AutoRow>
                    </RowBetween>
                    <UserClaimDividends />
                  </>
                )}
              </S.StakingCardFlex>
            </S.StakingCardWrapperFlex>
          </S.CardRowFlex>
        </>
      </S.Wrapper>

      {/* {onIslandSelect && (
        <IslandSearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onIslandSelect={handleIslandSelect}
          selectedIsland={islandDetails}
          showCommonBases={true}
          showIslandAmount={true}
          disableNonToken={true}
        />
      )} */}
      <PoweredByKodiak />
    </>
  )
}
