import { ButtonPrimary } from 'components/Button'
import { CurrencySelect } from 'components/CurrencyInputPanel'
import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  width: 960px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
     width: 560px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
     width: 100%;
  `};
`

export const ExternalLinkButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  align-self: flex-start;
  color: ${({ theme }) => theme.buttonPrimary};

  a {
    color: ${({ theme }) => theme.buttonPrimary};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    transition: all 0.3s ease-in-out;
  }

  transition: all 0.3s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.hoverState};

    a {
      color: ${({ theme }) => theme.hoverState};
    }
  }
`

export const FlexRowCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const ResponsiveFlexRowCenter = styled(FlexRowCenter)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
     flex-direction: column;
  `};
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const StakingCardWrapperFlex = styled.div`
  display: flex;
  width: 100%;
`

export const StakingCardFlex = styled.div`
  background: ${({ theme }) => theme.cardPrimary};
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  border-radius: 8px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09);
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: fit-content;
`

export const CardRowFlex = styled.div`
  display: flex;
  gap: 16px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
     flex-direction: column;
  `};
`

export const InnerStakingCardFlex = styled.div`
  background: ${({ theme }) => theme.cardSecondary};
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  border-radius: 8px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

export const TopStatBox = styled(InnerStakingCardFlex)`
  width: 200px;
`

export const FlexRowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const FlexRowCenterBetween = styled(FlexRowBetween)`
  align-items: center;

  svg > path {
    fill: ${({ theme }) => theme.buttonPrimary};
  }
`

export const IslandInfoInnerBox = styled(InnerStakingCardFlex)`
  width: 160px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
     width: 30%;
  `};
`

export const IslandSelect = styled(CurrencySelect)`
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  gap: 16px;
  border-radius: 360px;
  background: ${({ theme }) => theme.cardSecondary};
  min-width: 180px;
  width: fit-content;
`

export const Option = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  cursor: pointer;
  padding: 4px 12px;
  text-align: center;
  gap: 4px;
  border-radius: 3px;
  background: ${({ active, theme }) => (active ? theme.cardPrimary : 'transparent')};
  /* border: 1px solid ${({ theme }) => theme.backgroundOutline}; */

  pointer-events: ${({ active }) => active && 'none'};
`

export const Switch = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px;
  border-radius: 6px;
  height: 36px;
  background: ${({ theme }) => theme.cardSecondary};
`

export const DepositWithdrawSwitch = styled(Switch)`
  height: 46px;
`

export const DepositWithdrawOption = styled(Option)`
  height: 100%;
`

export const TransactionInfoText = styled.p`
  color: ${({ theme }) => theme.textSecondary};

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const StakeButton = styled(ButtonPrimary)`
  background: transparent;
  border: 1px solid;
  border-color: ${({ theme }) => theme.buttonPrimary};
  gap: 4px;
  padding: 8px 16px;
  height: fit-content;
  width: fit-content;
  color: ${({ theme }) => theme.buttonPrimary};

  &:hover {
    background: ${({ theme }) => theme.stateOverlayHover};
  }

  &:focus {
    background: ${({ theme }) => theme.stateOverlayHover};
  }
`

export const WithdrawVestingButton = styled(`button`)`
  background: transparent;
  border: none;
  padding: 5px;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;

  transition: all 0.3s ease-in-out !important;

  &:hover {
    background: ${({ theme }) => theme.hoverState};
    transform: scale(1.2);
  }
  border-radius: 8px;
`
