import { Currency, CurrencyAmount } from '@kodiak-finance/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { ButtonPrimary } from 'components/Button'
import { LightCard } from 'components/Card'
import { AutoColumn, ColumnCenter } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import { AutoRow, RowBetween } from 'components/Row'
import { Dots } from 'components/swap/styleds'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { useTokenAllowance } from 'hooks/useTokenAllowance'
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Separator, ThemedText } from 'theme'

import { useStepLineState } from '../context/StepLineContext'
import Step from './Step'

export const useApproveStep = (amount?: CurrencyAmount<Currency>, spender?: string, hideCurrencyLogo = false): Step => {
  const [approvalState, approve] = useApproveCallback(amount, spender)
  return {
    condition: approvalState === ApprovalState.APPROVED,
    component: (
      <ApproveStep
        approve={approve}
        amount={amount}
        approvalState={approvalState}
        spender={spender}
        hideCurrencyLogo={hideCurrencyLogo}
      />
    ),
  }
}

type ApproveStepProps = {
  approve: () => Promise<void>
  approvalState: ApprovalState
  amount?: CurrencyAmount<Currency>
  spender?: string
  hideCurrencyLogo?: boolean
}
const ApproveStep = ({ approve, approvalState, amount, spender, hideCurrencyLogo = false }: ApproveStepProps) => {
  const theme = useContext(ThemeContext)
  const { account } = useWeb3React()
  const token = amount?.currency?.isToken ? amount.currency : undefined
  const currentAllowance = useTokenAllowance(token, account ?? undefined, spender)
  const { approvals } = useStepLineState()

  return (
    <ColumnCenter gap="8px">
      <LightCard>
        <AutoColumn gap="sm">
          <RowBetween>
            <AutoRow>
              <div>{amount?.toExact()}</div>
            </AutoRow>

            <AutoRow gap="2px" justify="flex-end">
              {!hideCurrencyLogo && <CurrencyLogo currency={amount?.currency}></CurrencyLogo>}
              <div>{amount?.currency.symbol}</div>
            </AutoRow>
          </RowBetween>
          <Separator />
          <RowBetween>
            <ThemedText.DeprecatedSubHeader color={theme.textSecondary}>
              Current allowance
            </ThemedText.DeprecatedSubHeader>
            <ThemedText.DeprecatedSubHeader color={theme.textSecondary}>
              {currentAllowance?.toExact()} {amount?.currency.symbol}
            </ThemedText.DeprecatedSubHeader>
          </RowBetween>
          <RowBetween>
            <ThemedText.DeprecatedSubHeader color={theme.textSecondary}>
              Requested allowance
            </ThemedText.DeprecatedSubHeader>
            <ThemedText.DeprecatedSubHeader color={theme.textSecondary}>∞</ThemedText.DeprecatedSubHeader>
          </RowBetween>
        </AutoColumn>
      </LightCard>
      <ButtonPrimary onClick={approve} disabled={![ApprovalState.NOT_APPROVED].includes(approvalState)}>
        {approvalState === ApprovalState.UNKNOWN ? (
          <ThemedText.DeprecatedSubHeader>
            Checking existing allowance
            <Dots />
          </ThemedText.DeprecatedSubHeader>
        ) : (
          <ThemedText.DeprecatedSubHeader>
            Approve {amount?.currency.symbol}
            {approvalState === ApprovalState.PENDING && <Dots />}
          </ThemedText.DeprecatedSubHeader>
        )}
      </ButtonPrimary>
    </ColumnCenter>
  )
}

export default ApproveStep
