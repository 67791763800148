import { TradeType } from '@kodiak-finance/sdk-core'

import { VoteOption } from '../governance/types'

export interface SerializableTransactionReceipt {
  to: string
  from: string
  contractAddress: string
  transactionIndex: number
  blockHash: string
  transactionHash: string
  blockNumber: number
  status?: number
}

/**
 * Be careful adding to this enum, always assign a unique value (typescript will not prevent duplicate values).
 * These values is persisted in state and if you change the value it will cause errors
 */
export enum TransactionType {
  APPROVAL = 0,
  SWAP,
  DEPOSIT_LIQUIDITY_STAKING,
  WITHDRAW_LIQUIDITY_STAKING,
  CLAIM,
  VOTE,
  DELEGATE,
  WRAP,
  CREATE_V3_POOL,
  ADD_LIQUIDITY_V3_POOL,
  ADD_LIQUIDITY_V2_POOL,
  ADD_LIQUIDITY_ISLAND,
  MIGRATE_LIQUIDITY_V3,
  COLLECT_FEES,
  REMOVE_LIQUIDITY_V3,
  REMOVE_LIQUIDITY_ISLAND,
  SUBMIT_PROPOSAL,
  QUEUE,
  EXECUTE,
  STAKE_LIQUIDITY_ISLAND,
  CLAIM_FARM_REWARDS,
  UNSTAKE_LIQUIDITY_ISLAND,
  STAKE_CONVERT,
  STAKE_REDEEM,
  DIVIDENDS_ALLOCATE,
  DIVIDENDS_DEALLOCATE,
  DIVIDENDS_HARVEST,
  STAKE_HARVEST_KDK,
}

export interface BaseTransactionInfo {
  type: TransactionType
}

export interface UnstakeLiquidityIslandTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.UNSTAKE_LIQUIDITY_ISLAND
  amount: string
}

export interface ClaimFarmRewardsTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.CLAIM_FARM_REWARDS
  rewards: {
    token: string
    amount: string
  }[]
}

export interface StakeLiquidityIslandTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.STAKE_LIQUIDITY_ISLAND
  lockTime: string
  stakeAmount: string
  islandId: string
}

export interface VoteTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.VOTE
  governorAddress: string
  proposalId: number
  decision: VoteOption
  reason: string
}

export interface QueueTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.QUEUE
  governorAddress: string
  proposalId: number
}

export interface ExecuteTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.EXECUTE
  governorAddress: string
  proposalId: number
}

export interface DelegateTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.DELEGATE
  delegatee: string
}

export interface ApproveTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.APPROVAL
  tokenAddress: string
  spender: string
}

interface BaseSwapTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.SWAP
  tradeType: TradeType
  inputCurrencyId: string
  outputCurrencyId: string
}

export interface ExactInputSwapTransactionInfo extends BaseSwapTransactionInfo {
  tradeType: TradeType.EXACT_INPUT
  inputCurrencyAmountRaw: string
  expectedOutputCurrencyAmountRaw: string
  minimumOutputCurrencyAmountRaw: string
}
export interface ExactOutputSwapTransactionInfo extends BaseSwapTransactionInfo {
  tradeType: TradeType.EXACT_OUTPUT
  outputCurrencyAmountRaw: string
  expectedInputCurrencyAmountRaw: string
  maximumInputCurrencyAmountRaw: string
}

export interface DepositLiquidityStakingTransactionInfo {
  type: TransactionType.DEPOSIT_LIQUIDITY_STAKING
  token0Address: string
  token1Address: string
}

export interface WithdrawLiquidityStakingTransactionInfo {
  type: TransactionType.WITHDRAW_LIQUIDITY_STAKING
  token0Address: string
  token1Address: string
}

export interface WrapTransactionInfo {
  type: TransactionType.WRAP
  unwrapped: boolean
  currencyAmountRaw: string
  chainId?: number
}

export interface ClaimTransactionInfo {
  type: TransactionType.CLAIM
  recipient: string
  uniAmountRaw?: string
}

export interface CreateV3PoolTransactionInfo {
  type: TransactionType.CREATE_V3_POOL
  baseCurrencyId: string
  quoteCurrencyId: string
}

export interface AddLiquidityV3PoolTransactionInfo {
  type: TransactionType.ADD_LIQUIDITY_V3_POOL
  createPool: boolean
  baseCurrencyId: string
  quoteCurrencyId: string
  feeAmount: number
  expectedAmountBaseRaw: string
  expectedAmountQuoteRaw: string
}

export interface AddLiquidityIslandTransactionInfo {
  type: TransactionType.ADD_LIQUIDITY_ISLAND
  islandId: string
  tokenA?: string
  tokenB?: string
  tokenAAmount: string
  tokenBAmount: string
  feeAmount: string
  expectedAmountBaseRaw: string
}

export interface AddLiquidityV2PoolTransactionInfo {
  type: TransactionType.ADD_LIQUIDITY_V2_POOL
  baseCurrencyId: string
  quoteCurrencyId: string
  expectedAmountBaseRaw: string
  expectedAmountQuoteRaw: string
}

export interface MigrateV2LiquidityToV3TransactionInfo {
  type: TransactionType.MIGRATE_LIQUIDITY_V3
  baseCurrencyId: string
  quoteCurrencyId: string
  isFork: boolean
}

export interface CollectFeesTransactionInfo {
  type: TransactionType.COLLECT_FEES
  currencyId0: string
  currencyId1: string
}

export interface RemoveLiquidityV3TransactionInfo {
  type: TransactionType.REMOVE_LIQUIDITY_V3
  baseCurrencyId: string
  quoteCurrencyId: string
  expectedAmountBaseRaw: string
  expectedAmountQuoteRaw: string
}

export interface RemoveLiquidityIslandTransactionInfo {
  type: TransactionType.REMOVE_LIQUIDITY_ISLAND
  islandId: string
  tokenA?: string
  tokenB?: string
  sharesBurned: string
  tokenAAmount: string
  tokenBAmount: string
}

export interface SubmitProposalTransactionInfo {
  type: TransactionType.SUBMIT_PROPOSAL
}

export interface StakeConvertTransactionInfo {
  type: TransactionType.STAKE_CONVERT
  amount: string
}

export interface StakeRedeemTransactionInfo {
  type: TransactionType.STAKE_REDEEM
  amount: string
}

export interface StakeHarvestKdkTransactionInfo {
  type: TransactionType.STAKE_HARVEST_KDK
  amount: string
}

export interface DividendsAllocateTransactionInfo {
  type: TransactionType.DIVIDENDS_ALLOCATE
  amount: string
}

export interface DividendsDeallocateTransactionInfo {
  type: TransactionType.DIVIDENDS_DEALLOCATE
  amount: string
}

export interface DividendsHarvestTransactionInfo {
  type: TransactionType.DIVIDENDS_HARVEST
}

export type TransactionInfo =
  | ApproveTransactionInfo
  | ExactOutputSwapTransactionInfo
  | ExactInputSwapTransactionInfo
  | ClaimTransactionInfo
  | VoteTransactionInfo
  | QueueTransactionInfo
  | ExecuteTransactionInfo
  | DelegateTransactionInfo
  | DepositLiquidityStakingTransactionInfo
  | WithdrawLiquidityStakingTransactionInfo
  | WrapTransactionInfo
  | CreateV3PoolTransactionInfo
  | AddLiquidityV3PoolTransactionInfo
  | AddLiquidityV2PoolTransactionInfo
  | AddLiquidityIslandTransactionInfo
  | MigrateV2LiquidityToV3TransactionInfo
  | CollectFeesTransactionInfo
  | RemoveLiquidityV3TransactionInfo
  | RemoveLiquidityIslandTransactionInfo
  | SubmitProposalTransactionInfo
  | StakeLiquidityIslandTransactionInfo
  | ClaimFarmRewardsTransactionInfo
  | UnstakeLiquidityIslandTransactionInfo
  | StakeConvertTransactionInfo
  | StakeRedeemTransactionInfo
  | DividendsAllocateTransactionInfo
  | DividendsDeallocateTransactionInfo
  | DividendsHarvestTransactionInfo
  | StakeHarvestKdkTransactionInfo

export interface TransactionDetails {
  hash: string
  receipt?: SerializableTransactionReceipt
  lastCheckedBlockNumber?: number
  addedTime: number
  confirmedTime?: number
  from: string
  info: TransactionInfo
}
