import '@reach/dialog/styles.css'
import 'inter-ui'
import 'polyfills'
import 'components/analytics'

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { useWeb3React } from '@web3-react/core'
import StepLineContextProvider from 'components/StepLine/context/StepLineContext'
import { FeatureFlagsProvider } from 'featureFlags'
import { BlockNumberProvider } from 'lib/hooks/useBlockNumber'
import { MulticallUpdater } from 'lib/state/multicall'
import { StrictMode, useMemo } from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'

import Blocklist from './components/Blocklist'
import Web3Provider from './components/Web3Provider'
import { LanguageProvider } from './i18n'
import App from './pages/App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import store from './state'
import ApplicationUpdater from './state/application/updater'
import ListsUpdater from './state/lists/updater'
import LogsUpdater from './state/logs/updater'
import TransactionUpdater from './state/transactions/updater'
import UserUpdater from './state/user/updater'
import ThemeProvider, { ThemedGlobalStyle } from './theme'
import RadialGradientByChainUpdater from './theme/RadialGradientByChainUpdater'

const queryClient = new QueryClient()

if (window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

function Updaters() {
  return (
    <>
      <RadialGradientByChainUpdater />
      <ListsUpdater />
      <UserUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
      <LogsUpdater />
    </>
  )
}

const apolloClients = {
  v3: {
    80001: new ApolloClient({
      uri: 'https://api.thegraph.com/subgraphs/name/kodiak-finance/kodiak-v3-dev',
      cache: new InMemoryCache(),
    }),
    2061: new ApolloClient({
      uri: 'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/kodiak-v3-testnet/latest/gn',
      cache: new InMemoryCache(),
    }),
    80085: new ApolloClient({
      uri: 'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/kodiak-v3-berachain-public-testnet/latest/gn',
      cache: new InMemoryCache(),
    }),
  },
  v2: {
    80001: new ApolloClient({
      uri: 'https://api.thegraph.com/subgraphs/name/kodiak-finance/kodiak-v2-dev',
      cache: new InMemoryCache(),
    }),
    2061: new ApolloClient({
      uri: 'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/kodiak-v2-testnet/latest/gn',
      cache: new InMemoryCache(),
    }),
    80085: new ApolloClient({
      uri: 'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/kodiak-v2-artio/latest/gn',
      cache: new InMemoryCache(),
    }),
  },
}
const defaultApolloClient = apolloClients.v3[80085]

function ApolloProviderForCurrentChain({ children }: { children: any }) {
  const { chainId } = useWeb3React()
  const client = useMemo(() => {
    if (!chainId || !(chainId in apolloClients)) {
      return defaultApolloClient
    }
    return apolloClients[chainId as keyof typeof apolloClients]
  }, [chainId])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export const useSubgraphClient = (subgraphVersion: 'v2' | 'v3') => {
  const { chainId } = useWeb3React()

  return useMemo(() => {
    const subgraphClients = apolloClients[subgraphVersion]
    if (!chainId || !subgraphClients || !(chainId in subgraphClients)) {
      return defaultApolloClient // Define a default client for unknown scenarios
    }
    return subgraphClients[chainId]
  }, [subgraphVersion, chainId])
}

const container = document.getElementById('root') as HTMLElement
createRoot(container).render(
  <StrictMode>
    <Provider store={store}>
      <FeatureFlagsProvider>
        <QueryClientProvider client={queryClient}>
          <HashRouter>
            <LanguageProvider>
              <Web3Provider>
                <ApolloProviderForCurrentChain>
                  <Blocklist>
                    <BlockNumberProvider>
                      <Updaters />
                      <ThemeProvider>
                        <ThemedGlobalStyle />
                        <StepLineContextProvider>
                          <App />
                        </StepLineContextProvider>
                      </ThemeProvider>
                    </BlockNumberProvider>
                  </Blocklist>
                </ApolloProviderForCurrentChain>
              </Web3Provider>
            </LanguageProvider>
          </HashRouter>
        </QueryClientProvider>
      </FeatureFlagsProvider>
    </Provider>
  </StrictMode>
)

if (process.env.REACT_APP_SERVICE_WORKER !== 'false') {
  serviceWorkerRegistration.register()
}
