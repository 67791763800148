import useWindowDimensions from './useWindowDimensions'

const useScreenSize = () => {
  const { width } = useWindowDimensions() || {}
  const isMobile = width && width <= 895
  const isDesktop = width && width >= 1024

  return { isMobile, isDesktop }
}

export default useScreenSize
