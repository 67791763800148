import { OutlineCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import Input from 'components/NumericalInput'
import { BodyWrapper } from 'pages/AppBody'
import styled from 'styled-components/macro'

export const PageWrapper = styled(BodyWrapper)<{ wide: boolean }>`
  max-width: ${({ wide }) => (wide ? '800px' : '480px')};
  width: 100%;

  padding: 24px;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  background: ${({ theme }) => theme.cardPrimary};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 480px;
  `};
`

export const Wrapper = styled.div<{ wide: boolean }>`
  position: relative;
  min-width: ${({ wide }) => (wide ? '480px' : '100%')};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    min-width: 400px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  min-width: 340px;
`};
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const SelectPairFlex = styled(FlexColumn)`
  width: 368px;
  gap: 16px;

  .input-container {
    background: transparent;
  }
`

export const ScrollablePage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 480px;
    margin: 0 auto;
  `};
`

export const DynamicSection = styled(AutoColumn)<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? '0.2' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};

  #add-liquidity-input-tokena,
  #add-liquidity-input-tokenb {
    /* border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.cardBorderPrimary}; */
    background: ${({ theme }) => theme.cardSecondary};
  }
`

export const CurrencyDropdown = styled(CurrencyInputPanel)`
  width: 48.5%;

  .open-currency-select-button {
    padding: 8px;
    gap: 16px;
    background: ${({ theme }) => theme.cardSecondary};
    border-radius: 360px;
  }
`

export const StyledOutlineCard = styled(OutlineCard)`
  background: ${({ theme }) => theme.cardSecondary};
  .start-price-input {
    background: ${({ theme }) => theme.cardSecondary};
  }
`

export const StyledInput = styled(Input)`
  background-color: ${({ theme }) => theme.deprecated_bg0};
  text-align: left;
  font-size: 18px;
  width: 100%;
`

/* two-column layout where DepositAmount is moved at the very end on mobile. */
export const ResponsiveTwoColumns = styled.div<{ wide: boolean }>`
  display: grid;
  grid-column-gap: 50px;
  grid-row-gap: 15px;
  grid-template-columns: ${({ wide }) => (wide ? '1fr 1fr' : '1fr')};
  grid-template-rows: max-content;
  grid-auto-flow: row;

  padding-top: 20px;

  border-top: 1px solid ${({ theme }) => theme.deprecated_bg2};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;

    margin-top: 0;
  `};
`

export const RightContainer = styled(AutoColumn)`
  grid-row: 1 / 3;
  grid-column: 2;
  height: fit-content;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  grid-row: 2 / 3;
  grid-column: 1;
  `};
`

export const StackedContainer = styled.div`
  display: grid;
`

export const StackedItem = styled.div<{ zIndex?: number }>`
  grid-column: 1;
  grid-row: 1;
  height: 100%;
  z-index: ${({ zIndex }) => zIndex};
`

export const MediumOnly = styled.div`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`

export const HideMedium = styled.div`
  display: none;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: block;
  `};
`
