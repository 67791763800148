import { gql, useQuery } from '@apollo/client'
import { useSubgraphClient } from 'index'
import { useEffect } from 'react'
import { useProtocolDataV3State, useSetProtocolDataV3 } from 'state/protocolData/hooks/useSetProtocolDataV3'

const PROTOCOL_DATA_V3_QUERY = gql`
  query protocolDataV3 {
    uniswapDayDatas {
      date
      volumeUSD
      feesUSD
      tvlUSD
    }
  }
`

// to deal with the outlier data in the subgraph, when a swap is made on a very low liquidity pool
const OUTLIER_THRESHOLD = 1e12

export function useProtocolDataV3() {
  const apolloClient = useSubgraphClient('v3')
  const { setProtocolData, setLoading } = useSetProtocolDataV3()
  const { protocolDatasV3 } = useProtocolDataV3State()

  const { data, loading, error, refetch } = useQuery(PROTOCOL_DATA_V3_QUERY, {
    client: apolloClient,
    // pollingInterval: ms`90s`,
  })

  const allDatas = data?.uniswapDayDatas

  // Processing the received data
  useEffect(() => {
    if (!loading && data) {
      // Filtering out extreme outliers before setting the data
      const filteredData = allDatas?.filter((data) => parseFloat(data.volumeUSD) < OUTLIER_THRESHOLD)
      setProtocolData(filteredData)
    }
    setLoading(loading)
  }, [data, loading, setProtocolData, setLoading])

  useEffect(() => {
    if (error) {
      console.error('Error fetching Protocol Data V3:', error)
    }
  }, [error])

  const tvlUSD = protocolDatasV3?.length ? parseFloat(protocolDatasV3[protocolDatasV3.length - 1].tvlUSD) : null

  const totalVolumeUSD = protocolDatasV3?.reduce((total, data) => {
    return total + parseFloat(data.volumeUSD)
  }, 0)

  const formattedData = {
    tvlUSD,
    totalVolumeUSD,
  }

  return {
    isLoading: loading,
    data: formattedData,
    isError: !!error,
    refetch,
  }
}
