export const UNI_LIST = 'https://tokens.uniswap.org'
export const UNI_EXTENDED_LIST = 'https://gateway.pinata.cloud/ipfs/QmaQvV3pWKKaWJcHvSBuvQMrpckV3KKtGJ6p3HZjakwFtX'
const AAVE_LIST = 'tokenlist.aave.eth'
const BA_LIST = 'https://raw.githubusercontent.com/The-Blockchain-Association/sec-notice-list/master/ba-sec-list.json'
const CMC_ALL_LIST = 'https://api.coinmarketcap.com/data-api/v3/uniswap/all.json'
const COINGECKO_LIST = 'https://tokens.coingecko.com/uniswap/all.json'
const COMPOUND_LIST = 'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json'
const GEMINI_LIST = 'https://www.gemini.com/uniswap/manifest.json'
const KLEROS_LIST = 't2crtokens.eth'
const ROLL_LIST = 'https://app.tryroll.com/tokens.json'
const SET_LIST = 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/set.tokenlist.json'
const WRAPPED_LIST = 'wrapped.tokensoft.eth'

export const OPTIMISM_LIST = 'https://static.optimism.io/optimism.tokenlist.json'
export const ARBITRUM_LIST = 'https://bridge.arbitrum.io/token-list-42161.json'
export const CELO_LIST = 'https://celo-org.github.io/celo-token-list/celo.tokenlist.json'

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// BERA MIGRATION: this is where our 'official' token list currently lives,
//                 update for more default tokens on platform
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
export const KODIAK_LIST =
  'https://gist.githubusercontent.com/xsubject/76b647c6fc038a79c625bf667e0a7413/raw/17d716a1e03178fb3fb0c8bc1a65df37ab9721dc/kodiak-token-list.json'

export const BERACHAIN_PRIVATE_TESTNET_LIST =
  'https://raw.githubusercontent.com/berachain/default-token-list/main/src/tokens/testnet/defaultTokenList.json'

export const BERACHAIN_ARTIO_TESTNET_LIST =
  'https://gist.githubusercontent.com/beracuda/15d822c4070a26d291cce4c774d91410/raw/0fef084924db51456b693e8a3190eec5cde86328/berachain_artio.json'

export const UNSUPPORTED_LIST_URLS: string[] = [BA_LIST]

// this is the default list of lists that are exposed to users
// lower index == higher priority for token import
const DEFAULT_LIST_OF_LISTS_TO_DISPLAY: string[] = [
  BERACHAIN_ARTIO_TESTNET_LIST,
  BERACHAIN_PRIVATE_TESTNET_LIST,
  KODIAK_LIST,
]

export const DEFAULT_LIST_OF_LISTS: string[] = [
  ...DEFAULT_LIST_OF_LISTS_TO_DISPLAY,
  ...UNSUPPORTED_LIST_URLS, // need to load dynamic unsupported tokens as well,
  KODIAK_LIST,
  BERACHAIN_PRIVATE_TESTNET_LIST,
  BERACHAIN_ARTIO_TESTNET_LIST,
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [
  UNI_LIST,
  GEMINI_LIST,
  KODIAK_LIST,
  BERACHAIN_PRIVATE_TESTNET_LIST,
  BERACHAIN_ARTIO_TESTNET_LIST,
]

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// BERA MIGRATION: Island and farm whitelists
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export const ALLOWED_ISLANDS: string[] = [
  // '0x3d36f63fd9e61899c523fa5a7bef3eea0872ec95',
  // '0xcbb766cea42c049d21187e003217c268938c14e1',
  '0xac9effd9dd4e1c3c617722d4aa85ede22fe6cae7', // BERA/HONEY
  '0x1afad44af831703f78ce36ad917f1983ea4c488f', // HONEY/STGUSDC
  '0x774f934a4b1d1d251dcee75a3ea32d69b379a573', // BERA/WETH

  // '0xf39b35286160e889d04ad227f34e2ecf5114f861', // BERA/HONEY ARTIO
  // '0x66f29a2962245a05f37cea823942c024b775fd51', // BERA/HONEY ARTIO
  // '0x9d277905376fc238a19106812cefda1eb57ded91', // BERA/WETH ARTIO
  '0x9d277905376fc238a19106812cefda1eb57ded91', // HONEY/STGUSDC ARTIO
  '0x15d5696803034179fca3abedfbf6878d98eb2bc0', // BERA/WETH ARTIO
  // '0xd1089eb204a008255ef7dc72e37140bfe1f8e21f',
  '0x51d39e958a0d0b855b55ad5648f842ab4d8467a7',
  '0x9d277905376fc238a19106812cefda1eb57ded91', // HONEY/STGUSDC ARTIO
  '0x15d5696803034179fca3abedfbf6878d98eb2bc0', // BERA/WETH ARTIO
  '0x51d39e958a0d0b855b55ad5648f842ab4d8467a7', // BERA/HONEY ARTIO
]

export const ISLANDS_TO_FARM: Record<string, string | undefined> = {
  '0x673ea76a10361753b424e43520affc02787f9938': '0x083538D6cFe7Fd57a857E81dd45D2c7f036E766d', // WMATIC/USDC
  '0xbab7584f9b7604102d44372fbd98ef927d42e066': '0xe45f997Aa0159Bf9695C9Ae5185C913D95fb2bDa', // WETH/USDC
  '0x3d36f63fd9e61899c523fa5a7bef3eea0872ec95': '0x0fCE40341b01e600841B06F3167d072B2d8a8DDA', // HONEY/USDC

  '0x1afad44af831703f78ce36ad917f1983ea4c488f': '0x2c1A82E94Ab0393854f6358F60B2ab442bf9b442', // HONEY/STGUSDC
  '0xac9effd9dd4e1c3c617722d4aa85ede22fe6cae7': '0x17dCE65a5bEcB51C807439cDD974B755686E09d6', // BERA/HONEY
  '0x774f934a4b1d1d251dcee75a3ea32d69b379a573': '0x5aadE5155FBDACfC2887F82F96d85AE5067C7f84', // BERA/WETH

  // '0x9d277905376fc238a19106812cefda1eb57ded91': '0x52ecEDC0F6551129e1535A66586CbC7FBbFEA746', // HONEY/STGUSDC ARTIO
  // '0x9d277905376fc238a19106812cefda1eb57ded91': '0xF59Eb0806789F6265706997f3938B7e26B99172E', // HONEY/STGUSDC ARTIO
  '0x9d277905376fc238a19106812cefda1eb57ded91': '0x7d8D89EFb676387EbE93Ecb36aE8EE9673B63fb1', // HONEY/STGUSDC ARTIO

  // '0x51d39e958a0d0b855b55ad5648f842ab4d8467a7': '0xbB5680550fF501EB3589a46Ea1FA0c4096Ae5c19', // BERA/HONEY ARTIO
  // '0x51d39e958a0d0b855b55ad5648f842ab4d8467a7': '0xE5ed81ab7e0d9ddf682eaCDdC5F0Fb5309916BC5', // BERA/HONEY ARTIO
  // '0x51d39e958a0d0b855b55ad5648f842ab4d8467a7': '0xC531Eb8308db19dbCb36a428CA55d3FF24687Dc4', // BERA/HONEY ARTIO
  '0x51d39e958a0d0b855b55ad5648f842ab4d8467a7': '0xd327cde091d43cc5233882c4f21df0c704dda005', // BERA/HONEY ARTIO
  '0x15d5696803034179fca3abedfbf6878d98eb2bc0': '0x79d7850fd4cd32c2ea184a12ecc133a7db348a45', // BERA/WETH ARTIO
}

// 1000000000000000
