import { CurrencyAmount } from '@kodiak-finance/sdk-core'
import { Token } from '@kodiak-finance/sdk-core'
import { ButtonError } from 'components/Button'
import { LightCard } from 'components/Card'
import Column, { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import Row, { RowBetween } from 'components/Row'
import ConfirmationTransactionStep, {
  ConfirmationTransactionStepProps,
} from 'components/StepLine/common/ConfirmationTransactionStep'
import { useDividends } from 'hooks/useStaking'
import { useMemo } from 'react'
import { ChevronRight } from 'react-feather'
import { Text } from 'rebass'
import { useTheme } from 'styled-components'
import { Separator } from 'theme'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
type AllocateStepProps = {
  amount?: CurrencyAmount<Token>
  next: () => void
  onDeallocate: () => void
} & Omit<ConfirmationTransactionStepProps, 'children' | 'onConfirm'>

const DeallocateStep = ({ amount, next, onDeallocate, ...rest }: AllocateStepProps) => {
  const { userAllocation, totalAllocation } = useDividends()
  const theme = useTheme()

  const calculatePercent = (userAllocation?: CurrencyAmount<Token>, totalAllocation?: CurrencyAmount<Token>) => {
    if (!userAllocation || !totalAllocation) return null
    return parseFloat(userAllocation.multiply(1e4).divide(totalAllocation).multiply(100).quotient.toString()) / 1e4
  }

  const currentPercent = useMemo(
    () => calculatePercent(userAllocation, totalAllocation),
    [userAllocation, totalAllocation]
  )
  const nextPercent = useMemo(
    () =>
      amount === undefined
        ? null
        : calculatePercent(userAllocation?.subtract(amount), totalAllocation?.subtract(amount)),
    [userAllocation, totalAllocation, amount]
  )

  return (
    <ConfirmationTransactionStep onConfirm={next} {...rest}>
      <Column gap="6px" width="100%">
        <LightCard>
          <AutoColumn gap="6px">
            <RowBetween>
              <Text color={theme.textPrimary} fontSize={18}>
                {amount?.toExact()}
              </Text>
              <Text>
                <Row>
                  <CurrencyLogo currency={amount?.currency} />
                  <Text marginLeft="4px" color={theme.textPrimary} fontSize={16}>
                    {amount?.currency.symbol}
                  </Text>
                </Row>
              </Text>
            </RowBetween>
            <Separator />
            <RowBetween>
              <Text color={theme.textSecondary} fontSize={14}>
                Allocation
              </Text>
              <Text>
                <Row>
                  <Text color={theme.accentFailure} fontSize={14}>
                    {formatCurrencyAmount(userAllocation, 4)}
                  </Text>
                  <ChevronRight size={16} color={theme.textSecondary} />
                  <Text color={theme.accentSuccess} fontSize={14}>
                    {amount && formatCurrencyAmount(userAllocation?.subtract(amount), 4)}
                  </Text>
                  <Text color={theme.textSecondary} fontSize={14} marginLeft="4px">
                    {amount?.currency.symbol}
                  </Text>
                </Row>
              </Text>
            </RowBetween>

            <RowBetween>
              <Text color={theme.textSecondary} fontSize={14}>
                Allocation share
              </Text>
              <Text>
                <Row>
                  <Text color={theme.accentFailure} fontSize={14}>
                    {currentPercent}%
                  </Text>
                  <ChevronRight size={16} color={theme.textSecondary} />
                  <Text color={theme.accentSuccess} fontSize={14}>
                    {nextPercent}%
                  </Text>
                  <Text color={theme.textSecondary} fontSize={14} marginLeft="4px">
                    {amount?.currency.symbol}
                  </Text>
                </Row>
              </Text>
            </RowBetween>
          </AutoColumn>
        </LightCard>
        <ButtonError onClick={onDeallocate}>Deallocate</ButtonError>
      </Column>
    </ConfirmationTransactionStep>
  )
}

export default DeallocateStep
