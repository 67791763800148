import { BigintIsh, CurrencyAmount } from '@kodiak-finance/sdk-core'
import { Percent, Price, Token } from '@kodiak-finance/sdk-core'
import { Position } from '@kodiak-finance/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import CurrencyLogo from 'components/CurrencyLogo'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import Loader from 'components/Loader'
import MultiCurrencyLogo from 'components/MultiCurrencyLogo'
import { getPriceOrderingFromPositionForUI } from 'components/PositionListItem'
import { MouseoverTooltip } from 'components/Tooltip'
import { useToken } from 'hooks/Tokens'
import {
  useCommunalFarmAverageApr,
  useCommunalFarmByIsland,
  useCommunalFarmLockedStakesOf,
} from 'hooks/useCommunalFarms'
import { useFarmContract } from 'hooks/useContract'
import { Island } from 'hooks/useIslands'
import useIsTickAtLimit from 'hooks/useIsTickAtLimit'
import { usePool } from 'hooks/usePools'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useContext, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Farm } from 'state/farms/reducer'
import { Bound } from 'state/mint/v3/actions'
import styled, { ThemeContext } from 'styled-components/macro'
import { MEDIA_WIDTHS, ThemedText } from 'theme'
import { formatAmount, formatDollarAmount } from 'utils/formatDollarAmt'
import { formatTickPrice } from 'utils/formatTickPrice'
import { unwrappedToken } from 'utils/unwrappedToken'

import { ReactComponent as SwitchHorizontal } from '../../../../assets/svg/icons/SwitchHorizontal.svg'
import * as S from '../styled'

const LinkRow = styled(Link)<{ row: number }>`
  min-height: 60px;
  display: grid;
  cursor: pointer;
  user-select: none;

  width: 100%;

  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.cardBorderSecondary};
  padding: 8px 16px;

  grid-template-columns: 200px 1fr 75px 75px 180px 80px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr repeat(5, 0) 100px;
    
  `};
  overflow: hidden;
  transition: background-color, border-color 0.3s ease-in-out;

  &:last-of-type {
    /* margin: 8px 0 0 0; */
    border-bottom-color: transparent;
    border-radius: 0 0 16px 16px;
  }
  /* 
  & > div:not(:first-child) {
    text-align: center;
  } */
  :hover {
    background-color: ${({ theme }) => theme.cardSecondary};
    border-color: ${({ theme }) => theme.cardBorderPrimary};
  }

  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    /* flex-direction: row; */
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    row-gap: 12px;
  `};
`

const StategyBadge = styled.div`
  background-color: ${({ theme }) => theme.cardSecondary};
  width: fit-content;
  color: ${({ theme }) => theme.textSecondary};
  border-radius: 6px;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 16px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `};
`

const DataLineItem = styled.div`
  font-size: 14px;
`

const RangeLineItem = styled(DataLineItem)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  // background-color: ${({ theme }) => theme.textSecondary};
  //   border-radius: 12px;
  //   padding: 8px 0;
`};
`

const DoubleArrow = styled(SwitchHorizontal)`
  /* margin: 0 2px; */
  fill: #64748b;
`

const RangeText = styled.div`
  /* background-color: ${({ theme }) => theme.deprecated_bg2}; */
  padding: 0.25rem 0;
  font-size: 12px;

  border-radius: 8px;

  color: ${({ theme }) => theme.textPrimary};
  display: flex;
  flex-direction: row;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 4px;
  }
`

export const ExtentsText = styled.span`
  color: ${({ theme }) => theme.textSecondary};
  font-size: 12px;
  margin-right: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const PlusButton = styled.div`
  color: ${({ theme }) => theme.textPrimary};
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  border-radius: 4px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 32px;
  width: 32px;

  &:hover {
    background: ${({ theme }) => theme.deprecated_bg2};
  }
`

const PrimaryPositionIdData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-right: 16px;
  }

  ${S.FlexCol} {
    gap: 4px;
  }
`

const PairText = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.textPrimary};
`

const GridCol = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`

interface IslandListItemProps {
  island: Island
  row: number
}

// export function getPriceOrderingFromPositionForUI(position?: Position): {
//   priceLower?: Price<Token, Token>
//   priceUpper?: Price<Token, Token>
//   quote?: Token
//   base?: Token
// } {
//   if (!position) {
//     return {}
//   }

//   const token0 = position.amount0.currency
//   const token1 = position.amount1.currency
// }

const useInverter = ({
  priceLower,
  priceUpper,
  quote,
  base,
  invert,
}: {
  priceLower?: Price<Token, Token>
  priceUpper?: Price<Token, Token>
  quote?: Token
  base?: Token
  invert?: boolean
}): {
  priceLower?: Price<Token, Token>
  priceUpper?: Price<Token, Token>
  quote?: Token
  base?: Token
} => {
  return {
    priceUpper: invert ? priceLower?.invert() : priceUpper,
    priceLower: invert ? priceUpper?.invert() : priceLower,
    quote: invert ? base : quote,
    base: invert ? quote : base,
  }
}

// function FarmApr({ farm }: { farm: Farm }) {
//   const theme = useContext(ThemeContext)

//   const apr = useCommunalFarmAverageApr(farm)
//   return <span style={{ color: theme.buttonPrimary }}>{formatAmount(apr.toFixed(2))}%</span>
// }

function FarmApr({ farm }: { farm: Farm }) {
  const theme = useContext(ThemeContext)

  return <span style={{ color: theme.buttonPrimary }}>{formatAmount(useCommunalFarmAverageApr(farm).toFixed(2))}%</span>
}

export default function IslandListItem({ island, row }: IslandListItemProps) {
  const theme = useContext(ThemeContext)

  // const { token0, token1, name, id, pool, lowerTick, upperTick } = island

  const { account, chainId, provider } = useWeb3React()

  const feeAmount = '2.5%'
  // const strategyType = ['Narrow', 'Pegged Price', 'Dynamic', 'Wide', 'Stable'][Math.floor(Math.random() * 5)]

  const token0 = useToken(island?.token0?.id)
  const token1 = useToken(island?.token1?.id)

  const currencyA = token0 && unwrappedToken(token0)
  const currencyB = token1 && unwrappedToken(token1)

  const farm = useCommunalFarmByIsland(island?.id)
  const farmContract = useFarmContract(farm?.address)
  const { totalLiquidityUSD } = useCommunalFarmLockedStakesOf(farm, account)

  const { result: rawEarned, error, ...rest } = useSingleCallResult(farmContract, 'earned', [account])

  // only showing first token currently so as to not break rules of hoodks -> beracuda
  const rewardToken = useToken(farm?.rewardTokens[0])

  const earned = useMemo(() => {
    // Check if rewardTokens is not available
    if (!rewardToken) return null

    // If rewardTokens is available, process rawEarned
    return (rawEarned as BigintIsh[][] | undefined)?.[0]?.map((item, index) => {
      return {
        token: rewardToken,
        amount: rewardToken ? CurrencyAmount.fromRawAmount(rewardToken!, item) : undefined,
      }
    })
  }, [rawEarned, rewardToken])

  // const rewardTokens = farm?.rewardTokens.map((rewardToken) => useToken(rewardToken))

  // const earned = useMemo(() => {
  //   // Check if rewardTokens is not available
  //   if (!rewardTokens) return null

  //   // If rewardTokens is available, process rawEarned
  //   return (rawEarned as BigintIsh[][] | undefined)?.[0]?.map((item, index) => {
  //     return {
  //       token: rewardTokens[index],
  //       amount: rewardTokens[index] ? CurrencyAmount.fromRawAmount(rewardTokens[index]!, item) : undefined,
  //     }
  //   })
  // }, [rawEarned, rewardTokens])

  const [, pool] = usePool(currencyA ?? undefined, currencyB ?? undefined, Number(island?.pool?.feeTier) ?? undefined)

  const position = useMemo(() => {
    if (
      pool &&
      island?.pool?.liquidity &&
      typeof island?.tickLower === 'number' &&
      typeof island?.tickUpper === 'number'
    ) {
      return new Position({
        pool,
        liquidity: island.pool.liquidity.toString(),
        tickLower: island.tickLower,
        tickUpper: island.tickUpper,
      })
    }
    return undefined
  }, [island?.pool?.liquidity, pool, island?.tickLower, island?.tickUpper])

  const tickAtLimit = useIsTickAtLimit(island?.pool?.feeTier, island?.tickLower, island?.tickUpper)

  // prices
  const pricesFromPosition = getPriceOrderingFromPositionForUI(position)
  const [manuallyInverted, setManuallyInverted] = useState(true)

  // handle manual inversion
  const { priceLower, priceUpper, base } = useInverter({
    priceLower: pricesFromPosition.priceLower,
    priceUpper: pricesFromPosition.priceUpper,
    quote: pricesFromPosition.quote,
    base: pricesFromPosition.base,
    invert: manuallyInverted,
  })

  const inverted = token1 ? base?.equals(token1) : undefined
  const currencyQuote = inverted ? currencyA : currencyB
  const currencyBase = inverted ? currencyB : currencyA

  // check if price is within range
  const outOfRange: boolean = pool
    ? pool.tickCurrent < island?.tickLower || pool.tickCurrent >= island?.tickUpper
    : false

  // const positionSummaryLink = '/pool/' + positionDetails.tokenId

  // const removed = position?.liquidity?.eq(0)

  // const outOfRange: boolean = pool ? pool.tickCurrent < tickLower || pool.tickCurrent >= tickUpper : false

  // const {
  //   islandCurrency,
  //   islandTotalSupply,
  //   islandUnderlyingBalances,
  //   userBalanceShares,
  //   userBalanceSharesUSD,
  //   userUnderlyingBalances,
  //   userLiquidityPercentage,
  // } = useIslandPositionDetails(island, currencyA, currencyB, account)

  const positionSummaryLink = '/liquidity/islands/' + island.id

  const islandMostRecentSnapshot = island?.dailySnapshots.slice().sort((a, b) => b.timestamp - a.timestamp)[0]

  // const removed = liquidity?.eq(0)
  // Convert fee string to human readable
  // TODO: move to it's own util to format island fees specifically, with additional helpers for formatting tvl, apr, user shares, etc.
  const convertString = (str) => {
    return str
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  }

  console.log(`Debug:island/farm`, farm)
  // const farmApr = useCommunalFarmAverageApr(farm)

  return (
    <LinkRow to={positionSummaryLink} row={row + 1}>
      <PrimaryPositionIdData>
        <DoubleCurrencyLogo
          currency0={currencyBase || undefined}
          currency1={currencyQuote || undefined}
          size={32}
          margin
        />
        <S.FlexCol>
          <PairText>
            {currencyQuote?.symbol}-{currencyBase?.symbol}
          </PairText>
          <StategyBadge>{new Percent(island?.pool?.feeTier, 1_000_000).toSignificant()}%</StategyBadge>
        </S.FlexCol>
      </PrimaryPositionIdData>

      <GridCol>
        {priceLower && priceUpper ? (
          <RangeLineItem>
            <RangeText>
              <ExtentsText style={{ paddingRight: '3px' }}>Min:</ExtentsText>
              <CurrencyLogo size="14px" currency={currencyQuote} />
              <span>{formatTickPrice(priceLower, tickAtLimit, Bound.LOWER)} =</span>
              <CurrencyLogo size="14px" currency={currencyBase} />
              <span>1.0</span>
            </RangeText>{' '}
            {/* <DoubleArrow /> */}
            <RangeText>
              <ExtentsText>Max:</ExtentsText>
              <CurrencyLogo size="14px" currency={currencyQuote} />
              <span>{formatTickPrice(priceUpper, tickAtLimit, Bound.UPPER)} =</span>
              <CurrencyLogo size="14px" currency={currencyBase} />
              <span>1.0</span>
            </RangeText>
          </RangeLineItem>
        ) : (
          <Loader />
        )}
      </GridCol>

      <GridCol>
        <ThemedText.DeprecatedBody fontSize="14px" fontWeight="400" lineHeight="24px" color={theme.textPrimary}>
          {formatDollarAmount(island?.totalValueLockedUSD || 0)}
        </ThemedText.DeprecatedBody>
      </GridCol>

      <GridCol>
        {/* <MouseoverTooltip
          // disableHover={!tooltip}
          text={
            <ThemedText.DeprecatedBody fontSize={'14px'} fontWeight="400" lineHeight="24px" color={theme.textPrimary}>
              {`Last updated ${timeSince(Number(islandMostRecentSnapshot?.timestamp))}`}
            </ThemedText.DeprecatedBody>
          }
        > */}
        <ThemedText.DeprecatedBody fontSize="14px" fontWeight="400" lineHeight="24px" color={theme.textPrimary}>
          {formatDollarAmount(Number(island?.volumeUSD) || 0)}
        </ThemedText.DeprecatedBody>
        {/* </MouseoverTooltip> */}
      </GridCol>

      <GridCol>
        {/* <ThemedText.DeprecatedBody fontSize={'14px'} fontWeight="400" lineHeight="24px" color={theme.textPrimary}>
          {farm
            ? `${parseFloat(island?.apr?.averageApr || 0).toFixed(2)}% > ${(<FarmApr farm={farm} />)}`
            : `${parseFloat(island?.apr?.averageApr || 0).toFixed(2)}%`}
        </ThemedText.DeprecatedBody> */}
        <MouseoverTooltip
          // disableHover={!tooltip}
          text={
            <ThemedText.DeprecatedBody fontSize="12px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
              {`Swap Fees: ${parseFloat(island?.apr?.averageApr || 0).toFixed(2)}%`}
              <br />
              {farm && (
                <>
                  Farm APR: <FarmApr farm={farm} />
                </>
              )}
            </ThemedText.DeprecatedBody>
          }
        >
          <S.FlexRowCenter style={{ gap: '8px' }}>
            <ThemedText.DeprecatedBody fontSize="14px" fontWeight="400" lineHeight="24px" color={theme.textSecondary}>
              {farm ? (
                <>
                  {`${parseFloat(island?.apr?.averageApr || 0).toFixed(2)}% > `}
                  <FarmApr farm={farm} />
                </>
              ) : (
                `${parseFloat(island?.apr?.averageApr || 0).toFixed(2)}%`
              )}
            </ThemedText.DeprecatedBody>
            {/* <MultiCurrencyLogo size={21} margin={true} currencies={[...(farm?.rewardTokens || [])]} /> */}

            <MultiCurrencyLogo size={21} margin={true} currencies={[...(farm?.rewardTokens || [])]} />
          </S.FlexRowCenter>
        </MouseoverTooltip>
      </GridCol>

      <GridCol>
        <MouseoverTooltip
          // disableHover={!tooltip}
          text={
            <ThemedText.DeprecatedBody fontSize="12px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
              {`Unlocked: ${formatDollarAmount(Number(island?.userPosition?.balanceSharesUSD || 0))}`}
              <br />
              {`Locked: ${formatDollarAmount(totalLiquidityUSD || 0)}`}
            </ThemedText.DeprecatedBody>
          }
        >
          <ThemedText.DeprecatedBody fontSize="14px" fontWeight="400" lineHeight="24px" color={theme.textSecondary}>
            {formatDollarAmount(Number(island?.userPosition?.balanceSharesUSD || 0) + (totalLiquidityUSD || 0))}
          </ThemedText.DeprecatedBody>
        </MouseoverTooltip>
      </GridCol>

      {/* <GridCol>
        <PlusButton>+</PlusButton>
      </GridCol> */}
    </LinkRow>
  )
}

// export default IslandListItem

// if token0 is a dollar-stable asset, set it as the quote token
// const stables = [DAI, USDC_MAINNET, USDT]
// if (stables.some((stable) => stable.equals(token0))) {
//   return {
//     priceLower: position.token0PriceUpper.invert(),
//     priceUpper: position.token0PriceLower.invert(),
//     quote: token0,
//     base: token1,
//   }
// }

// // if token1 is an ETH-/BTC-stable asset, set it as the base token
// const bases = [...Object.values(WRAPPED_NATIVE_CURRENCY), WBTC]
// if (bases.some((base) => base && base.equals(token1))) {
//   return {
//     priceLower: position.token0PriceUpper.invert(),
//     priceUpper: position.token0PriceLower.invert(),
//     quote: token0,
//     base: token1,
//   }
// }

// // if both prices are below 1, invert
// if (position.token0PriceUpper.lessThan(1)) {
//   return {
//     priceLower: position.token0PriceUpper.invert(),
//     priceUpper: position.token0PriceLower.invert(),
//     quote: token0,
//     base: token1,
//   }
// }

// // otherwise, just return the default
// return {
//   priceLower: position.token0PriceLower,
//   priceUpper: position.token0PriceUpper,
//   quote: token1,
//   base: token0,
// }
// }

// const currency0 = token0 ? unwrappedToken(token0) : undefined
// const currency1 = token1 ? unwrappedToken(token1) : undefined

// construct Position from details returned
// const [, pool] = usePool(currency0 ?? undefined, currency1 ?? undefined, feeAmount)

// const position = useMemo(() => {
//   if (pool) {
//     return new Position({ pool, liquidity: liquidity.toString(), tickLower, tickUpper })
//   }
//   return undefined
// }, [liquidity, pool, tickLower, tickUpper])

// const tickAtLimit = useIsTickAtLimit(feeAmount, tickLower, tickUpper)

// prices
// const { priceLower, priceUpper, quote, base } = getPriceOrderingFromPositionForUI(position)

// <RangeLineItem>
//         <RangeText>
//           <ExtentsText>Min:</ExtentsText>
//           {/* {formatTickPrice(priceLower, tickAtLimit, Bound.LOWER)} <HoverInlineText text={currencyQuote?.symbol} /> per{' '} */}
//           <HoverInlineText text={currencyA?.symbol ?? ''} />
//         </RangeText>{' '}
//         <DoubleArrow />
//         <RangeText>
//           <ExtentsText>Max:</ExtentsText>
//           {/* {formatTickPrice(priceUpper, tickAtLimit, Bound.UPPER)} <HoverInlineText text={currencyQuote?.symbol} /> per{' '} */}
//           <HoverInlineText maxCharacters={10} text={currencyA?.symbol} />
//         </RangeText>
//       </RangeLineItem>
