import { Currency, CurrencyAmount, Token } from '@kodiak-finance/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { ButtonPrimary } from 'components/Button'
import { LightCard } from 'components/Card'
import { AutoColumn, ColumnCenter } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import { AutoRow, RowBetween } from 'components/Row'
import Step from 'components/StepLine/common/Step'
import { Dots } from 'components/swap/styleds'
import { BigNumber } from 'ethers'
import { useXKDK } from 'hooks/useContract'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { Separator, ThemedText } from 'theme'
import { calculateGasMargin } from 'utils/calculateGasMargin'

enum ApproveUsageStepState {
  UNKNOWN = 'UNKNOWN',
  APPROVE = 'APPROVE',
  NOT_APPROVED = 'NOT_APPROVED',
  APPROVED = 'APPROVED',
}
export const useApproveUsageStep = (amount?: CurrencyAmount<Token>, spender?: string): Step => {
  const xKDK = useXKDK()
  const { account } = useWeb3React()
  const usageApprovals = useSingleCallResult(xKDK, 'usageApprovals', [account, spender])
  const usage = usageApprovals.result?.[0] as BigNumber
  const [approveState, setApproveState] = useState<ApproveUsageStepState>(ApproveUsageStepState.UNKNOWN)
  const [approving, setApproving] = useState<boolean>(false)

  const approve = async () => {
    if (!xKDK || !amount || !spender) return
    try {
      setApproveState(ApproveUsageStepState.APPROVE)
      setApproving(true)

      const gas = await xKDK.estimateGas.approveUsage(spender, amount.quotient.toString())
      const tx = await xKDK.approveUsage(spender, amount.quotient.toString(), {
        gasLimit: calculateGasMargin(gas),
      })
    } catch {
      setApproveState(ApproveUsageStepState.NOT_APPROVED)
      // eslint-disable-next-line no-empty
    } finally {
    }
  }

  useEffect(() => {
    if (amount && usage.gte(amount.quotient.toString())) {
      setApproveState(ApproveUsageStepState.APPROVED)
    } else if (!approving) {
      setApproveState(ApproveUsageStepState.NOT_APPROVED)
    }
  }, [usage, amount, spender, approving])

  return {
    title: 'Approve Usage',
    condition: approveState === ApproveUsageStepState.APPROVED,
    component: <ApproveUsageStep approve={approve} amount={amount} approvalState={approveState} spender={spender} />,
  }
}

type ApproveUsageStepProps = {
  approve: () => Promise<void>
  approvalState: ApproveUsageStepState
  amount?: CurrencyAmount<Currency>
  spender?: string
}
const ApproveUsageStep = ({ approve, approvalState, amount, spender }: ApproveUsageStepProps) => {
  const theme = useContext(ThemeContext)
  const { account } = useWeb3React()
  const token = amount?.currency?.isToken ? amount.currency : undefined

  return (
    <ColumnCenter gap="8px">
      <LightCard>
        <AutoColumn gap="sm">
          <RowBetween>
            <AutoRow>
              <div>{amount?.toExact()}</div>
            </AutoRow>

            <AutoRow gap="2px" justify="flex-end">
              <CurrencyLogo currency={amount?.currency}></CurrencyLogo>
              <div>{amount?.currency.symbol}</div>
            </AutoRow>
          </RowBetween>
          <Separator />
          <RowBetween>
            <ThemedText.DeprecatedSubHeader color={theme.textSecondary}>
              Requested allowance
            </ThemedText.DeprecatedSubHeader>
            <ThemedText.DeprecatedSubHeader color={theme.textSecondary}>
              {amount?.toSignificant(6)}
            </ThemedText.DeprecatedSubHeader>
          </RowBetween>
        </AutoColumn>
      </LightCard>
      <ButtonPrimary onClick={approve} disabled={![ApproveUsageStepState.NOT_APPROVED].includes(approvalState)}>
        {approvalState === ApproveUsageStepState.UNKNOWN ? (
          <ThemedText.DeprecatedSubHeader>
            Checking existing allowance
            <Dots />
          </ThemedText.DeprecatedSubHeader>
        ) : (
          <ThemedText.DeprecatedSubHeader>
            Approve {amount?.currency.symbol}
            {approvalState === ApproveUsageStepState.APPROVE && <Dots />}
          </ThemedText.DeprecatedSubHeader>
        )}
      </ButtonPrimary>
    </ColumnCenter>
  )
}

export default ApproveUsageStep
