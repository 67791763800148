import useScreenSize from 'hooks/useScreenSize'
import React from 'react'
import { useIsDarkMode } from 'state/user/hooks'

interface DefinedIFrameProps {
  pairAddress?: string
  title?: string
  allow?: string
  quoteToken?: string
  hideTxTable?: number
  hideSidebar?: number
  quoteCurrency?: 'TOKEN' | 'USD'
}

const DefinedIFrame: React.FC<DefinedIFrameProps> = ({
  pairAddress = '0x9e0759241a0fe84970318344a88dc517b7cbf5b1',
  title = 'Defined Embed',
  allow = 'clipboard-write',
  quoteToken = 'token0',
  hideTxTable = 0,
  hideSidebar = 1,
  quoteCurrency = 'USD',
}) => {
  const { isMobile } = useScreenSize()
  const isDarkMode = useIsDarkMode()
  // These methods allow the iframe to work on mobile for now.
  const hideSidebarMobile = isMobile ? 0 : hideSidebar
  const isEmbedded = isMobile ? 0 : 1

  const embedColorMode = isDarkMode ? 'NIGHT_OWL' : 'LIGHT'

  const dynamicSrc = `https://www.defined.fi/artio/${pairAddress}?quoteToken=${quoteToken}&embedded=${isEmbedded}&hideTxTable=${hideTxTable}&hideSidebar=${hideSidebarMobile}&embedColorMode=${embedColorMode}&quoteCurrency=${quoteCurrency}`

  return (
    <iframe
      height="100%"
      width="100%"
      id="defined-embed"
      title={title}
      frameBorder="0"
      src={dynamicSrc}
      allow={allow}
    />
  )
}

export default DefinedIFrame
