import { Currency, CurrencyAmount, Fraction, Percent, Price, Token } from '@kodiak-finance/sdk-core'
import { Position } from '@kodiak-finance/v3-sdk'
import RangeBadge from 'components/Badge/RangeBadge'
import CurrencyLogo from 'components/CurrencyLogo'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import Loader from 'components/Loader'
import { MouseoverTooltip } from 'components/Tooltip'
import { useToken } from 'hooks/Tokens'
import useIsTickAtLimit from 'hooks/useIsTickAtLimit'
import { usePool } from 'hooks/usePools'
import useStablecoinPrice from 'hooks/useStablecoinPrice'
import { useV3PositionFees } from 'hooks/useV3PositionFees'
import { useContext, useMemo } from 'react'
import { Bound } from 'state/mint/v3/actions'
import styled, { ThemeContext } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { PositionDetails } from 'types/position'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
import { formatDollarAmount } from 'utils/formatDollarAmt'
import { formatTickPrice } from 'utils/formatTickPrice'
import { unwrappedToken } from 'utils/unwrappedToken'

import { ReactComponent as SwitchHorizontal } from '../../assets/svg/icons/SwitchHorizontal.svg'
import { WRAPPED_NATIVE_CURRENCY } from '../../constants/tokens'
import * as S from './styled'

const RangeLineItem = styled(S.DataLineItem)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  // background-color: ${({ theme }) => theme.textSecondary};
  //   border-radius: 12px;
  //   padding: 8px 0;
`};
`

const DoubleArrow = styled(SwitchHorizontal)`
  /* margin: 0 2px; */
  fill: #64748b;
`

const RangeText = styled.div`
  /* background-color: ${({ theme }) => theme.deprecated_bg2}; */
  padding: 0.25rem 0;
  font-size: 12px;

  border-radius: 8px;

  color: ${({ theme }) => theme.textPrimary};
  display: flex;
  flex-direction: row;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 4px;
  }
`

export const ExtentsText = styled.span`
  color: ${({ theme }) => theme.textSecondary};
  font-size: 12px;
  margin-right: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

interface PositionListItemProps {
  positionDetails: PositionDetails
  row: number
}

export function getPriceOrderingFromPositionForUI(position?: Position): {
  priceLower?: Price<Token, Token>
  priceUpper?: Price<Token, Token>
  quote?: Token
  base?: Token
} {
  if (!position) {
    return {}
  }

  const token0 = position.amount0.currency
  const token1 = position.amount1.currency

  // if token0 is a dollar-stable asset, set it as the quote token
  // const stables = [DAI, USDC_MAINNET, USDT]
  const stables: any[] = []
  if (stables.some((stable) => stable.equals(token0))) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // if token1 is an ETH-/BTC-stable asset, set it as the base token
  const bases = [...Object.values(WRAPPED_NATIVE_CURRENCY)]
  if (bases.some((base) => base && base.equals(token1))) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // if both prices are below 1, invert
  if (position.token0PriceUpper.lessThan(1)) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // otherwise, just return the default
  return {
    priceLower: position.token0PriceLower,
    priceUpper: position.token0PriceUpper,
    quote: token1,
    base: token0,
  }
}

export default function PositionListItem({ positionDetails, row }: PositionListItemProps) {
  const {
    token0: token0Address,
    token1: token1Address,
    fee: feeAmount,
    liquidity,
    tickLower,
    tickUpper,
  } = positionDetails

  const theme = useContext(ThemeContext)

  const token0 = useToken(token0Address)
  const token1 = useToken(token1Address)

  const currencyA = token0 ? unwrappedToken(token0) : undefined
  const currencyB = token1 ? unwrappedToken(token1) : undefined

  // construct Position from details returned
  const [, pool] = usePool(currencyA ?? undefined, currencyB ?? undefined, feeAmount)

  const position = useMemo(() => {
    if (pool) {
      return new Position({ pool, liquidity: liquidity.toString(), tickLower, tickUpper })
    }
    return undefined
  }, [liquidity, pool, tickLower, tickUpper])

  const tickAtLimit = useIsTickAtLimit(feeAmount, tickLower, tickUpper)

  // prices
  const { priceLower, priceUpper, quote, base } = getPriceOrderingFromPositionForUI(position)

  // check if price is within range
  const outOfRange: boolean = pool ? pool.tickCurrent < tickLower || pool.tickCurrent >= tickUpper : false

  const positionSummaryLink = '/liquidity/v3/' + positionDetails.tokenId

  const removed = liquidity?.eq(0)

  const receiveWETH = false
  // fees
  const [feeValue0, feeValue1] = useV3PositionFees(pool ?? undefined, positionDetails?.tokenId, receiveWETH)

  // usdc prices always in terms of tokens useSubgraphTokenPrice
  const price0 = useStablecoinPrice(token0 ?? undefined)
  const price1 = useStablecoinPrice(token1 ?? undefined)

  const fiatValueOfFees: CurrencyAmount<Currency> | null = useMemo(() => {
    if (!price0 || !price1 || !feeValue0 || !feeValue1) return null

    // we wrap because it doesn't matter, the quote returns a USDC amount
    const feeValue0Wrapped = feeValue0?.wrapped
    const feeValue1Wrapped = feeValue1?.wrapped

    if (!feeValue0Wrapped || !feeValue1Wrapped) return null

    const amount0 = price0.quote(feeValue0Wrapped)
    const amount1 = price1.quote(feeValue1Wrapped)
    return amount0.add(amount1)
  }, [price0, price1, feeValue0, feeValue1])

  const fiatValueOfLiquidity: CurrencyAmount<Token> | null = useMemo(() => {
    if (!price0 || !price1 || !position) return null
    const amount0 = price0.quote(position.amount0)
    const amount1 = price1.quote(position.amount1)
    return amount0.add(amount1)
  }, [price0, price1, position])

  const inverted = token1 ? base?.equals(token1) : undefined
  const currencyQuote = inverted ? currencyA : currencyB
  const currencyBase = inverted ? currencyB : currencyA

  const feeValueUpper = inverted ? feeValue0 : feeValue1
  const feeValueLower = inverted ? feeValue1 : feeValue0

  return (
    <S.LinkRow to={positionSummaryLink} row={row + 1}>
      <S.PrimaryPositionIdData>
        <S.FlexRowCenter>
          <DoubleCurrencyLogo currency0={currencyA} currency1={currencyB} size={32} margin />
          <S.FlexCol style={{ gap: '0px' }}>
            <S.FlexRowCenter>
              <S.PairText>
                {currencyA?.symbol}-{currencyB?.symbol}
              </S.PairText>
              <S.StategyBadge>{new Percent(feeAmount, 1_000_000).toSignificant()}%</S.StategyBadge>
            </S.FlexRowCenter>

            <RangeBadge removed={removed} inRange={!outOfRange} />
          </S.FlexCol>
        </S.FlexRowCenter>
      </S.PrimaryPositionIdData>

      <S.GridCol>
        {priceLower && priceUpper ? (
          <RangeLineItem>
            <RangeText>
              <ExtentsText style={{ paddingRight: '3px' }}>Min:</ExtentsText>
              <CurrencyLogo size="14px" currency={currencyQuote} />
              <span>{formatTickPrice(priceLower, tickAtLimit, Bound.LOWER)} =</span>
              <CurrencyLogo size="14px" currency={currencyBase} />
              <span>1.0</span>
            </RangeText>{' '}
            {/* <DoubleArrow /> */}
            <RangeText>
              <ExtentsText>Max:</ExtentsText>
              <CurrencyLogo size="14px" currency={currencyQuote} />
              <span>{formatTickPrice(priceUpper, tickAtLimit, Bound.UPPER)} =</span>
              <CurrencyLogo size="14px" currency={currencyBase} />
              <span>1.0</span>
            </RangeText>
          </RangeLineItem>
        ) : (
          <Loader />
        )}
      </S.GridCol>

      <S.GridCol></S.GridCol>

      <S.GridCol>
        {fiatValueOfFees?.greaterThan(new Fraction(1, 100)) ? (
          <MouseoverTooltip
            // disableHover={!tooltip}
            text={
              <S.FlexCol>
                <S.FlexRowCenter>
                  <CurrencyLogo currency={feeValueUpper?.currency} size="20px" style={{ marginRight: '0.5rem' }} />
                  <ThemedText.DeprecatedMain>
                    {feeValueUpper ? formatCurrencyAmount(feeValueUpper, 4) : '-'}
                  </ThemedText.DeprecatedMain>
                </S.FlexRowCenter>

                <S.FlexRowCenter>
                  <CurrencyLogo currency={feeValueLower?.currency} size="20px" style={{ marginRight: '0.5rem' }} />
                  <ThemedText.DeprecatedMain>
                    {feeValueLower ? formatCurrencyAmount(feeValueLower, 4) : '-'}
                  </ThemedText.DeprecatedMain>
                </S.FlexRowCenter>
              </S.FlexCol>
            }
          >
            <ThemedText.DeprecatedLargeHeader color={theme.accentAction} fontSize="16px" fontWeight={400}>
              <>{formatDollarAmount(fiatValueOfFees.toFixed(2))}</>
            </ThemedText.DeprecatedLargeHeader>
          </MouseoverTooltip>
        ) : (
          <ThemedText.DeprecatedLargeHeader color={theme.textSecondary} fontSize="16px" fontWeight={400}>
            $0
          </ThemedText.DeprecatedLargeHeader>
        )}
      </S.GridCol>

      <S.GridCol>
        {fiatValueOfLiquidity?.greaterThan(new Fraction(1, 100)) ? (
          <MouseoverTooltip
            // disableHover={!tooltip}
            text={
              <S.FlexCol>
                <S.FlexRowCenter>
                  <CurrencyLogo currency={currencyQuote} size="20px" style={{ marginRight: '0.5rem' }} />
                  <ThemedText.DeprecatedMain>
                    {inverted ? position?.amount0.toSignificant(4) : position?.amount1.toSignificant(4)}
                  </ThemedText.DeprecatedMain>
                  {/* {typeof ratio === 'number' && !removed ? (
                    <Badge style={{ marginLeft: '10px' }}>
                      <ThemedText.DeprecatedMain fontSize={11}>
                        <>{inverted ? ratio : 100 - ratio}%</>
                      </ThemedText.DeprecatedMain>
                    </Badge>
                  ) : null} */}
                </S.FlexRowCenter>
                <S.FlexRowCenter>
                  <CurrencyLogo currency={currencyBase} size="20px" style={{ marginRight: '0.5rem' }} />

                  <ThemedText.DeprecatedMain>
                    {inverted ? position?.amount1.toSignificant(4) : position?.amount0.toSignificant(4)}
                  </ThemedText.DeprecatedMain>
                </S.FlexRowCenter>
              </S.FlexCol>
            }
          >
            <ThemedText.DeprecatedLargeHeader fontSize="16px" fontWeight={400} color={theme.textPrimary}>
              <>{formatDollarAmount(fiatValueOfLiquidity.toFixed(2))}</>
            </ThemedText.DeprecatedLargeHeader>
          </MouseoverTooltip>
        ) : (
          <ThemedText.DeprecatedLargeHeader color={theme.textPrimary} fontSize="16px" fontWeight={400}>
            $0
          </ThemedText.DeprecatedLargeHeader>
        )}
      </S.GridCol>
    </S.LinkRow>
  )
}
