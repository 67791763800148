import styled from 'styled-components'

export const MainContentWrapper = styled.main`
  background-color: ${({ theme }) => theme.cardPrimary};
  /* padding: 16px; */
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09);

  width: 100%;
`

export const HeaderCol = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;

  .title {
    cursor: help;
  }
`

// Subgrid for header
export const HeaderRow = styled.div`
  grid-area: header; // Assign to the header area of parent grid
  display: grid;
  /* grid-template-columns: 340px repeat(4, 135px) 1fr; */

  grid-template-columns: 200px 1fr 75px 75px 180px 80px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr repeat(5, 0) 100px;
    
  `};

  overflow: hidden;
  border-bottom: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  padding: 8px 16px;
  /* padding: 8px 16px 0 16px; */
  /* grid-template-areas: 'header1 header2 header3 header4 header5 header6'; */
  height: 60px;
`

// Subgrid for body
export const BodyRow = styled.div`
  grid-area: body; // Assign to the body area of parent grid
  display: grid;
  grid-template-columns: subgrid; // Inherit columns from parent grid
  /* grid-template-areas: 'body1 body2 body3 body4 body5 body6'; */
  height: 60px;
`

// Subgrid for footer
export const FooterRow = styled.div`
  grid-area: footer; // Assign to the footer area of parent grid
  display: grid;
  grid-template-columns: subgrid; // Inherit columns from parent grid
  /* grid-template-areas: 'footer1 footer2 footer3 footer4 footer5 footer6'; */
  height: 60px;
`

export const FlexRowCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
