import { BigintIsh, CurrencyAmount, Token } from '@kodiak-finance/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { ButtonPrimary } from 'components/Button'
import CurrencyLogo from 'components/CurrencyLogo'
import { useToken } from 'hooks/Tokens'
import { useFarmContract } from 'hooks/useContract'
import { useSubgraphTokenValue } from 'hooks/useSubgraphTokenPrice'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { InnerCardFlex } from 'pages/Farms/FarmPage/styleds'
import { Dots } from 'pages/Islands/styleds'
import { useContext, useMemo, useState } from 'react'
import { Text } from 'rebass'
import { Farm } from 'state/farms/reducer'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { ThemeContext } from 'styled-components'
import { ThemedText } from 'theme'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { formatDollarAmount } from 'utils/formatDollarAmt'

import { BlurBox, CurrencyFlexBetween, FlexRowCenter, IslandCardFlex, NoStakeCard } from '../styleds'

interface EarnedItemType {
  token: Token | null | undefined
  amount: CurrencyAmount<Token> | undefined
}

const EarnedTokenRow = ({ item }: { item: EarnedItemType }) => {
  const theme = useContext(ThemeContext)

  const fiatValue = useSubgraphTokenValue(item.amount)
  return (
    <CurrencyFlexBetween>
      <FlexRowCenter>
        <CurrencyLogo currency={item.token} size="20px" />
        <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
          {item.token?.symbol}
        </ThemedText.DeprecatedBody>
      </FlexRowCenter>

      <FlexRowCenter>
        <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
          {/* {userUnderlyingBalances?.amount0?.toFixed(2)} */}
          {item.amount?.toSignificant(6)}
        </ThemedText.DeprecatedBody>
        <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" lineHeight="20px" color={theme.buttonPrimary}>
          {/* {userUnderlyingBalances?.amount0?.toFixed(2)} */}
          {formatDollarAmount(fiatValue)}
        </ThemedText.DeprecatedBody>
      </FlexRowCenter>
    </CurrencyFlexBetween>
  )
}
const IslandFarmEarnedCard = ({ farm }: { farm: Farm }) => {
  const theme = useContext(ThemeContext)
  const farmContract = useFarmContract(farm.address)
  const { account } = useWeb3React()

  const { result: rawEarned, error, ...rest } = useSingleCallResult(farmContract, 'earned', [account])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const rewardTokens = farm.rewardTokens.map((rewardToken) => useToken(rewardToken))

  const earned = useMemo(
    () =>
      (rawEarned as BigintIsh[][] | undefined)?.[0]?.map((item, index) => {
        return {
          token: rewardTokens[index],
          amount: rewardTokens[index] ? CurrencyAmount.fromRawAmount(rewardTokens[index]!, item) : undefined,
        }
      }),
    [rawEarned, rewardTokens]
  )
  const isExistsEarned = useMemo(() => {
    if (!earned) return false
    return earned.some((item) => item.amount?.greaterThan('0'))
  }, earned)

  const addTransaction = useTransactionAdder()

  const [attemptingTx, setAttemptingTx] = useState(false)
  const [txError, setTxError] = useState('')

  const onClaim = async () => {
    if (!farmContract || !earned) return
    setAttemptingTx(true)

    try {
      const gas = await farmContract.estimateGas.getReward()
      const gasLimit = calculateGasMargin(gas)

      const response = await farmContract.getReward({
        gasLimit,
      })
      addTransaction(response, {
        type: TransactionType.CLAIM_FARM_REWARDS,
        rewards: earned.map((item) => ({
          token: item.token?.symbol || '',
          amount: item.amount?.toSignificant(6) || '',
        })),
      })
    } catch (e) {
      if ('reason' in e) {
        setTxError(e.reason)
      } else {
        throw e
      }
    } finally {
      setAttemptingTx(false)
    }
  }

  return (
    <>
      {earned?.length && isExistsEarned ? (
        <IslandCardFlex>
          <ThemedText.DeprecatedBody fontSize="14px" fontWeight="400" lineHeight="14px" color={theme.textPrimary}>
            Earned
          </ThemedText.DeprecatedBody>
          {earned?.map((item, index) => (
            <InnerCardFlex key={index}>
              <EarnedTokenRow key={`${index}-${item.token?.address}`} item={item} />
            </InnerCardFlex>
          ))}
          <ButtonPrimary onClick={onClaim} disabled={attemptingTx}>
            <Text fontSize={20} fontWeight={500}>
              <>Claim</>
              {attemptingTx && <Dots />}
            </Text>
          </ButtonPrimary>

          {txError}
        </IslandCardFlex>
      ) : (
        <NoStakeCard>
          <BlurBox>
            <ThemedText.DeprecatedBody fontSize="20px" fontWeight="500" lineHeight="14px" color={theme.textPrimary}>
              Deposit and stake liquidity to earn extra rewards
            </ThemedText.DeprecatedBody>
          </BlurBox>

          <ThemedText.DeprecatedBody fontSize="14px" fontWeight="400" lineHeight="14px" color={theme.textPrimary}>
            Earned
          </ThemedText.DeprecatedBody>
          <InnerCardFlex>
            <CurrencyFlexBetween>
              <FlexRowCenter>
                <CurrencyLogo currency={undefined} size="20px" />
                <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
                  KDK
                </ThemedText.DeprecatedBody>
              </FlexRowCenter>
              <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
                {/* {userUnderlyingBalances?.amount0?.toFixed(2)} */}
                {0}
              </ThemedText.DeprecatedBody>
            </CurrencyFlexBetween>
            {/* eslint-disable-next-line @typescript-eslint/no-empty-function*/}
            <ButtonPrimary onClick={() => {}} disabled={true}>
              <Text fontSize={20} fontWeight={500}>
                <>Claim</>
              </Text>
            </ButtonPrimary>
          </InnerCardFlex>
        </NoStakeCard>
      )}
    </>
  )
}

export default IslandFarmEarnedCard
