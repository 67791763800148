import CurrencyLogo from 'components/CurrencyLogo'
import Loader from 'components/Loader'
import { useLockedAmountOfAccount } from 'hooks/useCommunalFarms'
import { useSubgraphTokenValue } from 'hooks/useSubgraphTokenPrice'
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { ThemedText } from 'theme'
import formatMoney from 'utils/formatMoney'

import { useFarm } from './context/FarmContext'
import { CurrencyFlexBetween, FlexRowCenter, InnerCardFlex, PositionCardFlex, PositionDetails } from './styleds'

export const PositionCard = () => {
  const theme = useContext(ThemeContext)
  const farm = useFarm()
  const lockedAmount = useLockedAmountOfAccount(farm)
  const stakingToken = lockedAmount?.currency
  const value = useSubgraphTokenValue(lockedAmount)

  return (
    <PositionCardFlex>
      <PositionDetails>
        <ThemedText.DeprecatedBody fontSize="14px" fontWeight="400" lineHeight="14px" color={theme.textPrimary}>
          Your Position
        </ThemedText.DeprecatedBody>
        <ThemedText.DeprecatedBody fontSize="20px" fontWeight="400" lineHeight="28px" color={theme.textPrimary}>
          {/* ${value.toFixed(2)} */}
          {value !== null ? formatMoney(value) : <Loader></Loader>}
        </ThemedText.DeprecatedBody>
      </PositionDetails>
      <InnerCardFlex>
        <CurrencyFlexBetween>
          <FlexRowCenter>
            <CurrencyLogo currency={stakingToken} size="20px" />
            <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
              {stakingToken?.symbol}
            </ThemedText.DeprecatedBody>
          </FlexRowCenter>
          <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
            {/* {userUnderlyingBalances?.amount0?.toFixed(2)} */}
            {lockedAmount?.toSignificant()} {stakingToken?.symbol}
          </ThemedText.DeprecatedBody>
        </CurrencyFlexBetween>
      </InnerCardFlex>
    </PositionCardFlex>
  )
}

export default PositionCard
