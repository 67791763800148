import CurrencyLogo from 'components/CurrencyLogo'
import { useToken } from 'hooks/Tokens'
import { useCalculationEarnings } from 'hooks/useCommunalFarms'
import { useContext } from 'react'
import useInputValue from 'state/farms/hooks/useInputValue'
import { useLockTimeMultiplier } from 'state/farms/hooks/useLockTimeValue'
import { useAppSelector } from 'state/hooks'
import { ThemeContext } from 'styled-components'
import { ThemedText } from 'theme'

import { useFarm } from './context/FarmContext'
import {
  CurrencyFlexBetween,
  EarningsCalculationHeader,
  EarningsCalculationTokenFormat,
  EarningsCalculationWrapper,
  FlexRowCenter,
  InnerCardFlex,
} from './styleds'

const EarningsCalculationView = () => {
  const theme = useContext(ThemeContext)
  const farm = useFarm()
  const rewardTokens = farm.rewardTokens.map(useToken)
  const stakingToken = useToken(farm.stakingToken)

  const lockTimeValue = useAppSelector((state) => state.farms.lockTimeValue)
  const multiplier = useLockTimeMultiplier(lockTimeValue, farm)
  const [input] = useInputValue()

  const earnings = useCalculationEarnings({
    farm,
    inputValue: input,
    lockTimeValue,
    multiplier,
  })

  // const result = useMemo(() => {
  //   return rewardTokens.map((item, index) => {
  //     const amountPerToken = parseFloat(rewardsPerDuration[index]?.toExact() || "0");
  //     const amount = amountPerToken * parseFloat(input || "0");
  //     const lockTimeAmount = (amount / rewardsDuration) * lockTimeValue;
  //     const lockTimeAmountWithMultiplier = lockTimeAmount * multiplier;
  //     const value = useSubgraphTokenValue(
  //       tryParseCurrencyAmount<Token>(
  //         lockTimeAmountWithMultiplier.toString(),
  //         item || undefined
  //       )
  //     );

  //     return { value, currencyAmount: lockTimeAmountWithMultiplier }
  //   })
  // }, [rewardsPerDuration, rewardTokens, rewardsDuration, lockTimeValue, multiplier]);

  return (
    <>
      <EarningsCalculationHeader>By the end of the lock, you will have earned:</EarningsCalculationHeader>
      {rewardTokens.map((item, index) => {
        return (
          <InnerCardFlex key={index}>
            <CurrencyFlexBetween>
              <FlexRowCenter>
                <CurrencyLogo currency={item} size="20px" />
                <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
                  {item?.symbol}
                </ThemedText.DeprecatedBody>
              </FlexRowCenter>

              <EarningsCalculationWrapper>
                <EarningsCalculationTokenFormat>{earnings[index]?.toSignificant(2)}</EarningsCalculationTokenFormat>
                {/* <EarningsCalculationCashFormat>~{formatMoney(earnings[index])}</EarningsCalculationCashFormat> */}
              </EarningsCalculationWrapper>
            </CurrencyFlexBetween>
          </InnerCardFlex>
        )
      })}
    </>
  )
}

export default EarningsCalculationView
