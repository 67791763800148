import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { Currency, CurrencyAmount } from '@kodiak-finance/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { ElementName, Event, EventName } from 'components/AmplitudeAnalytics/constants'
import { TraceEvent } from 'components/AmplitudeAnalytics/TraceEvent'
import { sendEvent } from 'components/analytics'
import FiatValue from 'components/FiatValue'
import { useApproveStep } from 'components/StepLine/common/ApproveStep'
import useStepWithNext from 'components/StepLine/context/hooks/useStepWithNext'
import StepLineModal from 'components/StepLine/StepLineModal'
import { Switch } from 'components/Switch'
import { useToken } from 'hooks/Tokens'
import { useCommunalFarmByIsland } from 'hooks/useCommunalFarms'
import { useFarmContract } from 'hooks/useContract'
import { useInputToken } from 'hooks/useInputToken'
import React from 'react'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Text } from 'rebass'

import { ButtonError, ButtonLight, ButtonPrimary } from '../../../../components/Button'
import { AutoColumn } from '../../../../components/Column'
import CurrencyInputPanel from '../../../../components/CurrencyInputPanel'
import { useIsSwapUnsupported } from '../../../../hooks/useIsSwapUnsupported'
import useTransactionDeadline from '../../../../hooks/useTransactionDeadline'
import { useToggleWalletModal } from '../../../../state/application/hooks'
import { useDerivedMintInfo, useMintActionHandlers, useMintState } from '../../../../state/islands/mint/hooks'
import { Field } from '../../../../state/mint/actions'
import { useTransactionAdder } from '../../../../state/transactions/hooks'
import { TransactionType } from '../../../../state/transactions/types'
import { useIsExpertMode } from '../../../../state/user/hooks'
import { ThemedText } from '../../../../theme'
import { calculateGasMargin } from '../../../../utils/calculateGasMargin'
import { maxAmountSpend } from '../../../../utils/maxAmountSpend'
import { IslandContextType } from '../context/IslandContextType'
import { useOnStake } from './hooks/useOnStakeCallback'
import { useIslandDepositStep } from './steps/IslandDepositStep'
import { useSelectLockTimeStep } from './steps/SelectLockTimeStep'
import StakeStep from './steps/StakeStep'
import * as S from './styleds'

const DepositView: React.FC<IslandContextType> = ({
  currencyA,
  currencyB,
  wrappedNativeCurrency,
  feeAmount,
  islandId,
  router,
}) => {
  const toggleWalletModal = useToggleWalletModal() // toggle wallet when disconnected

  const expertMode = useIsExpertMode()
  const { account, chainId, provider } = useWeb3React()
  const navigate = useNavigate()

  const [singleSided, setSingleSided] = useState<boolean>(false)

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState()

  const noLiquidity = false
  const {
    dependentField,
    currencies,
    island,
    pool,
    poolState,
    currencyBalances,
    parsedAmounts,
    price,
    liquidityMinted,
    error,
    amount0Max,
    amount1Max,
    amount0Min,
    amount1Min,
    amountSharesMin,
    amountShares,
    singleSided: poolSingleSided,
  } = useDerivedMintInfo(islandId ?? undefined, currencyA ?? undefined, currencyB ?? undefined, currencyA ?? undefined)
  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity)

  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  // txn values
  const deadline = useTransactionDeadline() // custom from users settings
  const [txHash, setTxHash] = useState<string>('')

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    // [dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
    [dependentField]: parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field]),
      }
    },
    {}
  )

  const atMaxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
      }
    },
    {}
  )

  const [depositOption, setDepositOption] = useState<'depositOnly' | 'withStaking'>('depositOnly')

  console.log('island:router', router)

  const addTransaction = useTransactionAdder()

  const islandCurrency = useToken(island?.id)

  const estimatedReceived =
    islandCurrency && amountShares ? CurrencyAmount.fromRawAmount(islandCurrency, amountShares.toString()) : undefined
  const minReceived =
    islandCurrency && amountSharesMin
      ? CurrencyAmount.fromRawAmount(islandCurrency, amountSharesMin.toString())
      : undefined

  const [stakeAmount, resetStakeAmount] = useInputToken(estimatedReceived?.currency)

  const farm = useCommunalFarmByIsland(islandId)
  const farmContract = useFarmContract(farm?.address)

  const { onStake, attemptingTxnStake, pendingTextStake, txHashStake } = useOnStake(
    stakeAmount,
    farmContract,
    resetStakeAmount
  )

  async function onAdd() {
    if (!chainId || !provider || !account || !router) {
      console.warn(`onAdd -> chainId: ${chainId}, provider: ${provider}, account: ${account}, router: ${router}`)
      return
    }

    const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts
    if (
      !parsedAmountA ||
      !parsedAmountB ||
      !currencyA ||
      !currencyB ||
      !deadline ||
      !amount0Max ||
      !amount1Max ||
      !amount0Min ||
      !amount1Min ||
      !amountSharesMin ||
      !islandId ||
      !island
    ) {
      return
    }

    let estimate: typeof router.estimateGas.addLiquidityETH | typeof router.estimateGas.addLiquidity,
      method: (...args: any) => Promise<TransactionResponse>,
      args: [string, string, string, string, string, string, string],
      value: BigNumber | null,
      functionName: 'addLiquidity' | 'addLiquidityETH'

    if (currencyA.isNative || currencyB.isNative) {
      const tokenBIsETH = currencyB.isNative
      estimate = router.estimateGas.addLiquidityETH.bind(router.estimateGas)
      method = router.addLiquidityETH
      functionName = 'addLiquidityETH'
      args = [
        islandId,
        // tokenBIsETH ? '0' : amount0Max.toString(),
        // tokenBIsETH ? amount1Max.toString() : '0',
        parsedAmountA.quotient.toString(),
        parsedAmountB.quotient.toString(),
        amount0Min.toString(),
        amount1Min.toString(),
        amountSharesMin.toString(),
        account,
      ]
      value = BigNumber.from((tokenBIsETH ? parsedAmountB : parsedAmountA).quotient.toString())
    } else {
      estimate = router.estimateGas.addLiquidity.bind(router.estimateGas)
      method = router.addLiquidity
      functionName = 'addLiquidity'
      args = [
        islandId,
        parsedAmountA.quotient.toString(),
        parsedAmountB.quotient.toString(),
        amount0Min.toString(),
        amount1Min.toString(),
        amountSharesMin.toString(),
        account,
      ]
      value = null
    }
    const fn = router.populateTransaction[functionName]
    const transactionRequest = await router.populateTransaction[functionName](...args, value ? { value } : {})

    setAttemptingTxn(true)

    try {
      const estimatedGasLimit = await estimate(...args, value ? { value } : {})

      return method(...args, {
        ...(value ? { value } : {}),
        gasLimit: calculateGasMargin(estimatedGasLimit),
      })
        .then((response) => {
          addTransaction(response, {
            type: TransactionType.ADD_LIQUIDITY_ISLAND,
            islandId,
            tokenA: 'address' in currencyA ? currencyA.address : undefined,
            tokenB: 'address' in currencyB ? currencyB.address : undefined,
            tokenAAmount: amount0Max.quotient.toString(),
            tokenBAmount: amount1Max.quotient.toString(),
            feeAmount: feeAmount.toString(),
            expectedAmountBaseRaw: amountSharesMin.toString(),
          })

          setTxHash(response.hash)

          sendEvent({
            category: 'Liquidity',
            action: 'Add',
            label: [currencies[Field.CURRENCY_A]?.symbol, currencies[Field.CURRENCY_B]?.symbol].join('/'),
          })
        })
        .finally(() => {
          setAttemptingTxn(false)
        })
    } catch (error) {
      setAttemptingTxn(false)

      if (error?.code !== 4001) {
        console.error(error)
      }
    }
  }

  const pendingText = (
    <>
      Supplying {parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} {currencies[Field.CURRENCY_A]?.symbol} and{' '}
      {parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} {currencies[Field.CURRENCY_B]?.symbol}
    </>
  )

  //   const handleCurrencyASelect = useCallback(
  //     (currencyA: Currency) => {
  //       const newCurrencyIdA = currencyId(currencyA)
  //       if (newCurrencyIdA === currencyIdB) {
  //         navigate(`/add/v2/${currencyIdB}/${currencyIdA}`)
  //       } else {
  //         navigate(`/add/v2/${newCurrencyIdA}/${currencyIdB}`)
  //       }
  //     },
  //     [currencyIdB, navigate, currencyIdA]
  //   )
  //   const handleCurrencyBSelect = useCallback(
  //     (currencyB: Currency) => {
  //       const newCurrencyIdB = currencyId(currencyB)
  //       if (currencyIdA === newCurrencyIdB) {
  //         if (currencyIdB) {
  //           navigate(`/add/v2/${currencyIdB}/${newCurrencyIdB}`)
  //         } else {
  //           navigate(`/add/v2/${newCurrencyIdB}`)
  //         }
  //       } else {
  //         navigate(`/add/v2/${currencyIdA ? currencyIdA : 'ETH'}/${newCurrencyIdB}`)
  //       }
  //     },
  //     [currencyIdA, navigate, currencyIdB]
  //   )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('')
    }
    setTxHash('')
  }, [onFieldAInput, txHash])

  const addIsUnsupported = useIsSwapUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)
  const approveStep0 = useApproveStep(parsedAmounts[Field.CURRENCY_A], router?.address)
  const approveStep1 = useApproveStep(parsedAmounts[Field.CURRENCY_B], router?.address)
  const selectLockTimeStep = useSelectLockTimeStep(islandId, estimatedReceived?.toExact())

  const depositStep = useIslandDepositStep({
    noLiquidity,
    currencies,
    liquidityMinted,
    parsedAmounts,
    price,
    amountSharesMin: estimatedReceived?.toSignificant(6),
    onAdd,
    attemptingTxn,
    pendingText,
    hash: txHash,
    autoConfirm: true,
  })

  const approveLp = useApproveStep(stakeAmount, farmContract?.address, true)

  const stakeStep = useStepWithNext('StakeStep', StakeStep, {
    attemptingTxn: attemptingTxnStake,
    pendingText: pendingTextStake,
    hash: txHashStake,
    amount: stakeAmount,
    onStake,
  })

  const confirmationSteps = [
    approveStep0,
    approveStep1,
    depositOption === 'withStaking' ? selectLockTimeStep : undefined,
    depositStep,
    ...(depositOption === 'withStaking' ? [approveLp, stakeStep] : []),
  ]

  return (
    <>
      {/* <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        content={() => (
          <ConfirmationModalContent
            title={noLiquidity ? <>You are creating a pool</> : <>You will receive</>}
            onDismiss={handleDismissConfirmation}
            topContent={modalHeader}
            bottomContent={modalBottom}
          />
        )}
        pendingText={pendingText}
        // currencyToAdd={null}
      /> */}
      <StepLineModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        steps={confirmationSteps}
        header={<ThemedText.DeprecatedMediumHeader>Deposit Liquidity to Island</ThemedText.DeprecatedMediumHeader>}
        onFinish={handleDismissConfirmation}
        onStart={resetStakeAmount}
      />
      {/* 
      <S.Switch>
        <S.Option
          onClick={() => {
            setSingleSided(true)
          }}
          active={singleSided || poolSingleSided}
        >
          <>Single-sided</>
        </S.Option>
        <S.Option
          onClick={() => {
            // When switching to custom slippage, use `auto` value as a default.
            setSingleSided(false)
          }}
          active={!singleSided && !poolSingleSided}
          tabIndex={-1}
        >
          <>Double-sided</>
        </S.Option>
      </S.Switch> */}

      <CurrencyInputPanel
        value={formattedAmounts[Field.CURRENCY_A]}
        onUserInput={onFieldAInput}
        onMax={() => {
          onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
        }}
        // onCurrencySelect={handleCurrencyASelect}
        showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
        currency={currencies[Field.CURRENCY_A] ?? null}
        id="add-liquidity-input-tokena"
        showCommonBases
      />
      {/* {stakeAmount === undefined ? "undefined" : stakeAmount.toExact()} */}
      {!singleSided && !poolSingleSided && (
        <>
          <CurrencyInputPanel
            value={formattedAmounts[Field.CURRENCY_B]}
            onUserInput={onFieldBInput}
            // onCurrencySelect={handleCurrencyBSelect}
            onMax={() => {
              onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
            }}
            showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
            currency={currencies[Field.CURRENCY_B] ?? null}
            id="add-liquidity-input-tokenb"
            showCommonBases
          />
        </>
      )}

      <S.InnerIslandCardFlex>
        <S.CurrencyFlexBetween>
          <S.TransactionInfoText>Estimated Received</S.TransactionInfoText>
          <S.TransactionInfoText>
            {estimatedReceived?.toSignificant(6) ?? 0} {islandCurrency?.symbol?.replace('RAKIS', 'KODIAK')} (
            <FiatValue currencyAmount={estimatedReceived} />)
          </S.TransactionInfoText>
        </S.CurrencyFlexBetween>
        <S.CurrencyFlexBetween>
          <S.TransactionInfoText>Minimum Received</S.TransactionInfoText>
          <S.TransactionInfoText>
            {minReceived?.toSignificant(6) ?? 0} {islandCurrency?.symbol?.replace('RAKIS', 'KODIAK')} (
            <FiatValue currencyAmount={minReceived} />)
          </S.TransactionInfoText>
        </S.CurrencyFlexBetween>
        {/* <S.CurrencyFlexBetween>
          <S.TransactionInfoText>Performance Fee</S.TransactionInfoText>
          <S.TransactionInfoText>$13.41</S.TransactionInfoText>
        </S.CurrencyFlexBetween>
        <S.CurrencyFlexBetween>
          <S.TransactionInfoText>Withdrawal Fee</S.TransactionInfoText>
          <S.TransactionInfoText>$13.41</S.TransactionInfoText>
        </S.CurrencyFlexBetween> */}
      </S.InnerIslandCardFlex>

      {addIsUnsupported ? (
        <ButtonPrimary disabled={true}>
          <ThemedText.DeprecatedMain mb="4px">
            <>Unsupported Asset</>
          </ThemedText.DeprecatedMain>
        </ButtonPrimary>
      ) : !account ? (
        <TraceEvent
          events={[Event.onClick]}
          name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
          properties={{ received_swap_quote: false }}
          element={ElementName.CONNECT_WALLET_BUTTON}
        >
          <ButtonLight onClick={toggleWalletModal}>
            <>Connect Wallet</>
          </ButtonLight>
        </TraceEvent>
      ) : (
        <AutoColumn gap="md">
          {farmContract && (
            <Switch
              options={[
                { value: 'depositOnly', label: 'Deposit Only' },
                { value: 'withStaking', label: 'With Staking' },
              ]}
              activeValue={depositOption}
              setActiveValue={setDepositOption}
            />
          )}
          <ButtonError
            onClick={() => setShowConfirm(true)}
            disabled={!isValid}
            error={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}
          >
            <Text fontSize={20} fontWeight={500}>
              {error ?? <>Supply</>}
            </Text>
          </ButtonError>
        </AutoColumn>
      )}
      {/* ... same code you provided ... */}
    </>
  )
}

export default DepositView

// <AutoColumn gap="20px">
// {noLiquidity ||
//   (isCreate ? (
//     <ColumnCenter>
//       <BlueCard>
//         <AutoColumn gap="10px">
//           <ThemedText.DeprecatedLink fontWeight={600} color={'deprecated_primaryText1'}>
//             <>You are the first liquidity provider.</>
//           </ThemedText.DeprecatedLink>
//           <ThemedText.DeprecatedLink fontWeight={400} color={'deprecated_primaryText1'}>
//             <>The ratio of tokens you add will set the price of this pool.</>
//           </ThemedText.DeprecatedLink>
//           <ThemedText.DeprecatedLink fontWeight={400} color={'deprecated_primaryText1'}>
//             <>Once you are happy with the rate click supply to review.</>
//           </ThemedText.DeprecatedLink>
//         </AutoColumn>
//       </BlueCard>
//     </ColumnCenter>
//   ) : (
//     <ColumnCenter>
//       <BlueCard>
//         <AutoColumn gap="10px">
//           <ThemedText.DeprecatedLink fontWeight={400} color={'deprecated_primaryText1'}>
//             <>
//               <b>
//                 <>Tip:</>
//               </b>{' '}
//               When you add liquidity, you will receive pool tokens representing your position. These tokens
//               automatically earn fees proportional to your share of the pool, and can be redeemed at any
//               time.
//             </>
//           </ThemedText.DeprecatedLink>
//         </AutoColumn>
//       </BlueCard>
//     </ColumnCenter>
//   ))}
// <CurrencyInputPanel
//   value={formattedAmounts[Field.CURRENCY_A]}
//   onUserInput={onFieldAInput}
//   onMax={() => {
//     onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
//   }}
//   onCurrencySelect={handleCurrencyASelect}
//   showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
//   currency={currencies[Field.CURRENCY_A] ?? null}
//   id="add-liquidity-input-tokena"
//   showCommonBases
// />
// <ColumnCenter>
//   <Plus size="16" color={theme.deprecated_text2} />
// </ColumnCenter>
// <CurrencyInputPanel
//   value={formattedAmounts[Field.CURRENCY_B]}
//   onUserInput={onFieldBInput}
//   onCurrencySelect={handleCurrencyBSelect}
//   onMax={() => {
//     onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
//   }}
//   showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
//   currency={currencies[Field.CURRENCY_B] ?? null}
//   id="add-liquidity-input-tokenb"
//   showCommonBases
// />
// {currencies[Field.CURRENCY_A] && currencies[Field.CURRENCY_B] && pairState !== PairState.INVALID && (
//   <>
//     <LightCard padding="0px" $borderRadius={'8px'}>
//       <RowBetween padding="1rem">
//         <ThemedText.DeprecatedSubHeader fontWeight={500} fontSize={14}>
//           {noLiquidity ? (
//             <>Initial prices and pool share</>
//           ) : (
//             <>Prices and pool share</>
//           )}
//         </ThemedText.DeprecatedSubHeader>
//       </RowBetween>{' '}
//       <LightCard padding="1rem" $borderRadius={'0'}>
//         <PoolPriceBar
//           currencies={currencies}
//           poolTokenPercentage={poolTokenPercentage}
//           noLiquidity={noLiquidity}
//           price={price}
//         />
//       </LightCard>
//     </LightCard>
//   </>
// )}

// {addIsUnsupported ? (
//   <ButtonPrimary disabled={true}>
//     <ThemedText.DeprecatedMain mb="4px">
//       <>Unsupported Asset</>
//     </ThemedText.DeprecatedMain>
//   </ButtonPrimary>
// ) : !account ? (
//   <TraceEvent
//     events={[Event.onClick]}
//     name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
//     properties={{ received_swap_quote: false }}
//     element={ElementName.CONNECT_WALLET_BUTTON}
//   >
//     <ButtonLight onClick={toggleWalletModal}>
//       <>Connect Wallet</>
//     </ButtonLight>
//   </TraceEvent>
// ) : (
//   <AutoColumn gap={'md'}>
//     {(approvalA === ApprovalState.NOT_APPROVED ||
//       approvalA === ApprovalState.PENDING ||
//       approvalB === ApprovalState.NOT_APPROVED ||
//       approvalB === ApprovalState.PENDING) &&
//       isValid && (
//         <RowBetween>
//           {approvalA !== ApprovalState.APPROVED && (
//             <ButtonPrimary
//               onClick={approveACallback}
//               disabled={approvalA === ApprovalState.PENDING}
//               width={approvalB !== ApprovalState.APPROVED ? '48%' : '100%'}
//             >
//               {approvalA === ApprovalState.PENDING ? (
//                 <Dots>
//                   <>Approving {currencies[Field.CURRENCY_A]?.symbol}</>
//                 </Dots>
//               ) : (
//                 <>Approve {currencies[Field.CURRENCY_A]?.symbol}</>
//               )}
//             </ButtonPrimary>
//           )}
//           {approvalB !== ApprovalState.APPROVED && (
//             <ButtonPrimary
//               onClick={approveBCallback}
//               disabled={approvalB === ApprovalState.PENDING}
//               width={approvalA !== ApprovalState.APPROVED ? '48%' : '100%'}
//             >
//               {approvalB === ApprovalState.PENDING ? (
//                 <Dots>
//                   <>Approving {currencies[Field.CURRENCY_B]?.symbol}</>
//                 </Dots>
//               ) : (
//                 <>Approve {currencies[Field.CURRENCY_B]?.symbol}</>
//               )}
//             </ButtonPrimary>
//           )}
//         </RowBetween>
//       )}
//     <ButtonError
//       onClick={() => {
//         expertMode ? onAdd() : setShowConfirm(true)
//       }}
//       disabled={!isValid || approvalA !== ApprovalState.APPROVED || approvalB !== ApprovalState.APPROVED}
//       error={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}
//     >
//       <Text fontSize={20} fontWeight={500}>
//         {error ?? <>Supply</>}
//       </Text>
//     </ButtonError>
//   </AutoColumn>
// )}
// </AutoColumn>

// // wrapped onUserInput to clear signatures
// const onUserInput = useCallback(
//   (field: Field, typedValue: string) => {
//     return _onUserInput(field, typedValue)
//   },
//   [_onUserInput]
// )

// const onCurrencyAInput = useCallback(
//   (typedValue: string): void => onUserInput(Field.CURRENCY_A, typedValue),
//   [onUserInput]
// )
// const onCurrencyBInput = useCallback(
//   (typedValue: string): void => onUserInput(Field.CURRENCY_B, typedValue),
//   [onUserInput]
// )
