import { useContext } from 'react'

import { IslandContext } from '../context/IslandContext'

export const useIslandContext = () => {
  const context = useContext(IslandContext)
  if (!context) {
    throw new Error('useIslandContext must be used within an IslandProvider')
  }
  return context
}
