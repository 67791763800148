import { sendEvent } from 'components/analytics'
import { ButtonOutlined } from 'components/Button'
import { AutoRow } from 'components/Row'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { deleteURLParam } from 'utils/routes'

const Button = styled(ButtonOutlined).attrs(() => ({
  padding: '8px',
  $borderRadius: '8px',
}))`
  color: ${({ theme }) => theme.deprecated_text1};
  flex: 1;
`

export default function PresetsButtons({ setFullRange }: { setFullRange: () => void }) {
  const { search } = useLocation()
  const navigate = useNavigate()

  function handleSetFullRange() {
    let updatedSearch = deleteURLParam(search, 'minPrice')
    updatedSearch = deleteURLParam(updatedSearch, 'maxPrice')

    navigate({ search: updatedSearch }, { replace: true })

    setFullRange()
  }
  return (
    <AutoRow gap="4px" width="auto">
      <Button
        onClick={() => {
          handleSetFullRange()
          sendEvent({
            category: 'Liquidity',
            action: 'Full Range Clicked',
          })
        }}
      >
        <ThemedText.DeprecatedBody fontSize={12}>
          <>Full Range</>
        </ThemedText.DeprecatedBody>
      </Button>
    </AutoRow>
  )
}
