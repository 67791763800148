import { createContext, useContext } from 'react'
import { Farm } from 'state/farms/reducer'

const FarmContext = createContext<Farm | undefined>(undefined)

export const useFarm = (): Farm => {
  const farm = useContext(FarmContext)

  if (!farm) {
    throw new Error(`useFarm must be used within a FarmProvider`)
  }

  return farm
}

export const FarmProvider = ({ children, farm }: { children: any; farm: Farm }) => {
  return <FarmContext.Provider value={farm}>{children}</FarmContext.Provider>
}
