import { Percent } from '@kodiak-finance/sdk-core'
import { OutlineCard } from 'components/Card'
import { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components/macro'
import { opacify } from 'theme/utils'

import { ThemedText } from '../../theme'
import { AutoColumn } from '../Column'
import { RowBetween, RowFixed } from '../Row'
import { MouseoverTooltip } from '../Tooltip'
import { formatPriceImpact } from './FormattedPriceImpact'

const StyledCard = styled(OutlineCard)`
  padding: 12px;
  border: 1px solid ${({ theme }) => opacify(24, theme.error500)};
  border-radius: 6px;
`

interface PriceImpactWarningProps {
  priceImpact: Percent
}

export default function PriceImpactWarning({ priceImpact }: PriceImpactWarningProps) {
  const theme = useContext(ThemeContext)

  return (
    <StyledCard>
      <AutoColumn gap="8px">
        <MouseoverTooltip
          text={
            <>
              Executing a swap of this magnitude could potentially result in a significant price impact, considering the
              liquidity available in the pool. Please be aware that there might be a notable variance between the input
              token amount and the output token you receive.
            </>
          }
        >
          <RowBetween>
            <RowFixed>
              <ThemedText.DeprecatedSubHeader color={theme.deprecated_error}>
                <>Price impact warning</>
              </ThemedText.DeprecatedSubHeader>
            </RowFixed>
            <ThemedText.DeprecatedLabel textAlign="right" fontSize={14} color={theme.deprecated_error}>
              {formatPriceImpact(priceImpact)}
            </ThemedText.DeprecatedLabel>
          </RowBetween>
        </MouseoverTooltip>
      </AutoColumn>
    </StyledCard>
  )
}
