import { useProtocolDataV2 } from 'hooks/useProtocolDayDatasV2'
import { useContext } from 'react'
import { ThemeContext } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { ExternalLink } from 'theme/components'
// import { ReactComponent as SearchIcon } from '../../assets/svg/icons/Search.svg'
import { formatDollarAmount } from 'utils/formatDollarAmt'

import * as S from '../Liquidity.styles'

export default function V2Header() {
  const theme = useContext(ThemeContext)

  const { data: protocolDataV2, isLoading: isDataLoadingV2, isError: isDataErrorV2 } = useProtocolDataV2()

  return (
    <S.Header>
      <S.HeaderTitleFlex>
        <ThemedText.DeprecatedBody fontSize="18px" fontWeight="700">
          V2 Pools
        </ThemedText.DeprecatedBody>

        <ThemedText.DeprecatedBody fontSize="14px" fontWeight="400" color={theme.textSecondary}>
          Provide liquidity in Kodiak v2 pools
        </ThemedText.DeprecatedBody>

        <ExternalLink href={process.env.REACT_APP_V2_ANALYTICS || ''}>Top V2 Pools ↗</ExternalLink>
      </S.HeaderTitleFlex>
      <S.HeaderBoxFlex>
        <S.HeaderBox>
          <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" color={theme.textSecondary} lineHeight="14px">
            TVL
          </ThemedText.DeprecatedBody>

          <ThemedText.DeprecatedBody fontSize="20px" fontWeight="400" lineHeight="28px">
            {formatDollarAmount(protocolDataV2?.tvlUSD)}
          </ThemedText.DeprecatedBody>
        </S.HeaderBox>

        <S.HeaderBoxDesktop>
          <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" color={theme.textSecondary} lineHeight="14px">
            Volume
          </ThemedText.DeprecatedBody>

          <ThemedText.DeprecatedBody fontSize="20px" fontWeight="400" lineHeight="28px">
            {formatDollarAmount(protocolDataV2?.totalVolumeUSD)}
          </ThemedText.DeprecatedBody>
        </S.HeaderBoxDesktop>
      </S.HeaderBoxFlex>
    </S.Header>
  )
}
