import { CurrencySelect } from 'components/CurrencyInputPanel'
import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  width: 960px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
     width: 560px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
     width: 100%;
  `};
`

export const BackButton = styled.div`
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;

  align-self: flex-start;

  color: ${({ theme }) => theme.textSecondary};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  transition: all 0.3s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.textPrimary};
    font-weight: 700;
  }
`

export const FlexRowCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const CardRowFlex = styled.div`
  display: flex;
  gap: 16px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
     flex-direction: column;
  `};
`

export const AnalyticsAndManagementFlex = styled(CardRowFlex)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
     flex-direction: column-reverse;
  `};
`
export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const IslandCardFlex = styled.div`
  background: ${({ theme }) => theme.cardPrimary};
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  border-radius: 8px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09);
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const NoStakeCard = styled(IslandCardFlex)`
  height: 206px;
  position: relative;
`

export const BlurBox = styled.div`
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.2);
  height: fit-content;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 0 0 24px 0;

  position: absolute;

  ${({ theme }) => theme.mediaWidth.upToMedium`
      padding: 16px;
  `};

  border-radius: 8px;

  height: 100%;
  width: 100%;

  left: 0;
  top: 0;
`
export const InnerIslandCardFlex = styled.div`
  background: ${({ theme }) => theme.cardSecondary};
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  border-radius: 8px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

export const IslandInfoCard = styled(IslandCardFlex)`
  width: 100%;
  justify-content: space-between;

  ${({ theme }) => theme.mediaWidth.upToMedium`
     width: 100%;
  `};
`

export const IslandPositionCard = styled(IslandCardFlex)`
  /* width: 384px; */
  width: 560px;
  justify-content: space-between;
  gap: 50px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
     width: 100%;
  `};
`

export const IslandPositionDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const CurrencyFlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const IslandInfoInnerBox = styled(InnerIslandCardFlex)`
  width: 160px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
     width: 30%;
  `};
`

// Dropdown version

// export const IslandSelect = styled(CurrencySelect)`
//   display: flex;
//   /* padding: 8px 16px; */
//   align-items: flex-start;
//   gap: 16px;
//   border-radius: 360px;
//   /* background: ${({ theme }) => theme.cardSecondary}; */
//   background: transparent;
//   min-width: 180px;
//   width: fit-content;
// `

export const IslandSelect = styled(CurrencySelect)`
  display: flex;
  padding: 0;
  align-items: center;
  gap: 8px;
  /* border-radius: 360px; */
  /* background: ${({ theme }) => theme.cardSecondary}; */
  background: transparent;
  /* min-width: 180px; */
  width: fit-content;

  &:hover {
    background: transparent;
  }
`

export const IslandStrategyCard = styled(IslandCardFlex)`
  height: 404px;
  width: 560px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
     width: 100%;
  `};
`

export const IslandManagementCard = styled(IslandCardFlex)`
  height: fit-content;
  width: 384px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
     width: 100%;
  `};
`

export const Option = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  cursor: pointer;
  padding: 4px 12px;
  text-align: center;
  gap: 4px;
  border-radius: 3px;
  background: ${({ active, theme }) => (active ? theme.cardPrimary : 'transparent')};
  /* border: 1px solid ${({ theme }) => theme.backgroundOutline}; */

  pointer-events: ${({ active }) => active && 'none'};
`

export const Switch = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px;
  border-radius: 6px;
  height: 36px;
  background: ${({ theme }) => theme.cardSecondary};
`

export const DepositWithdrawSwitch = styled(Switch)`
  height: 46px;
`

export const DepositWithdrawOption = styled(Option)`
  height: 100%;
`

export const TransactionInfoText = styled.p`
  color: ${({ theme }) => theme.textSecondary};

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`
