import { StepRequired } from 'components/StepLine/common/Step'
import React from 'react'

import { useStepLineNext } from './useStepLineNext'

interface CommonProps {
  next: () => void
}

function useStepWithNext<T extends CommonProps, P extends Omit<T, 'next'>>(
  id: string,
  component: React.ComponentType<T>,
  props: P,
  extraCondition?: boolean,
  title?: string
): StepRequired {
  const Component = component
  const { condition, next } = useStepLineNext(id)

  return {
    title,
    condition: condition || (extraCondition !== undefined ? extraCondition : false),
    component: <Component {...(props as unknown as T)} next={next} />,
  }
}

export default useStepWithNext
