import { CurrencyAmount, Percent, Token } from '@kodiak-finance/sdk-core'
import { Pair } from '@kodiak-finance/v2-sdk'
import { useWeb3React } from '@web3-react/core'
import CurrencyLogo from 'components/CurrencyLogo'
import { ResponsiveButtonPrimary } from 'components/PositionList/styled'
import { MouseoverTooltip } from 'components/Tooltip'
import { useSubgraphTokenValue } from 'hooks/useSubgraphTokenPrice'
import JSBI from 'jsbi'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { ThemeContext } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { formatDollarAmount } from 'utils/formatDollarAmt'

import { BIG_INT_ZERO } from '../../constants/misc'
import { useTotalSupply } from '../../hooks/useTotalSupply'
import { useTokenBalance } from '../../state/connection/hooks'
import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/unwrappedToken'
import DoubleCurrencyLogo from '../DoubleLogo'
import { Dots } from '../swap/styleds'
import * as S from './styled'

interface V2PositionListItemProps {
  pair: Pair
  showUnwrapped?: boolean
  stakedBalance?: CurrencyAmount<Token> // optional balance to indicate that liquidity is deposited in mining pool
}

export default function V2PositionListItem({ pair, stakedBalance }: V2PositionListItemProps) {
  const { account } = useWeb3React()
  const theme = useContext(ThemeContext)

  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)

  const userDefaultPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  // if staked balance balance provided, add to standard liquidity amount
  const userPoolBalance = stakedBalance ? userDefaultPoolBalance?.add(stakedBalance) : userDefaultPoolBalance

  const poolTokenPercentage =
    !!userPoolBalance &&
    !!totalPoolTokens &&
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
      ? new Percent(userPoolBalance.quotient, totalPoolTokens.quotient)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined]

  const price0USD = useSubgraphTokenValue(pair.tokenAmounts[0])
  const price1USD = useSubgraphTokenValue(pair.tokenAmounts[1])

  const totalUserLiquidity = formatDollarAmount(parseFloat(price0USD + price1USD).toFixed(2))

  return (
    <S.V2ListItemFlex>
      <S.PrimaryPositionIdData>
        <S.FlexRowCenter>
          <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={32} margin />
          <S.FlexRowCenter>
            <S.PairText>
              {!currency0 || !currency1 ? (
                <Dots>
                  <>Loading</>
                </Dots>
              ) : (
                `${currency0.symbol}/${currency1.symbol}`
              )}
            </S.PairText>
          </S.FlexRowCenter>
        </S.FlexRowCenter>
      </S.PrimaryPositionIdData>

      <S.GridCol>
        <MouseoverTooltip
          // disableHover={!tooltip}
          text={
            <S.FlexCol>
              <S.FlexRowCenter style={{ justifyContent: 'space-between' }}>
                {token0Deposited ? (
                  <>
                    <S.FlexRowCenter>
                      <CurrencyLogo size="20px" style={{ marginRight: '8px' }} currency={currency0} />
                      <ThemedText.DeprecatedBody color={theme.textSecondary} fontSize="14px" fontWeight={400}>
                        {currency0.symbol}
                      </ThemedText.DeprecatedBody>
                    </S.FlexRowCenter>
                    <ThemedText.DeprecatedBody color={theme.textPrimary} fontSize="14px" fontWeight={500}>
                      {token0Deposited?.toSignificant(6)}
                    </ThemedText.DeprecatedBody>
                  </>
                ) : (
                  '-'
                )}
              </S.FlexRowCenter>

              <S.FlexRowCenter style={{ justifyContent: 'space-between' }}>
                {token1Deposited ? (
                  <>
                    <S.FlexRowCenter>
                      <CurrencyLogo size="20px" style={{ marginRight: '8px' }} currency={currency1} />
                      <ThemedText.DeprecatedBody color={theme.textSecondary} fontSize="14px" fontWeight={400}>
                        {currency1.symbol}
                      </ThemedText.DeprecatedBody>
                    </S.FlexRowCenter>
                    <ThemedText.DeprecatedBody color={theme.textPrimary} fontSize="14px" fontWeight={500}>
                      {token1Deposited?.toSignificant(6)}
                    </ThemedText.DeprecatedBody>
                  </>
                ) : (
                  '-'
                )}
              </S.FlexRowCenter>

              <S.FlexRowCenter>
                <ThemedText.DeprecatedBody color={theme.textPrimary} fontSize="14px" fontWeight={400}>
                  <>Pool tokens:</>
                </ThemedText.DeprecatedBody>
                <ThemedText.DeprecatedBody color={theme.textPrimary} fontSize="14px" fontWeight={400}>
                  {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
                </ThemedText.DeprecatedBody>
              </S.FlexRowCenter>
              {/* <S.FlexRowCenter>
                <ThemedText.DeprecatedBody color={theme.textPrimary} fontSize="14px" fontWeight={400}>
                  <>Pool share:</>
                </ThemedText.DeprecatedBody>
                <ThemedText.DeprecatedBody color={theme.textPrimary} fontSize="14px" fontWeight={400}>
                  {poolTokenPercentage ? (
                    <>{poolTokenPercentage.toFixed(2) === '0.00' ? '<0.01' : poolTokenPercentage.toFixed(2)} %</>
                  ) : (
                    '-'
                  )}
                </ThemedText.DeprecatedBody>
              </S.FlexRowCenter> */}
            </S.FlexCol>
          }
        >
          <ThemedText.DeprecatedLargeHeader color={theme.textPrimary} fontSize="16px" fontWeight={400}>
            {totalUserLiquidity ? totalUserLiquidity : '-'}
          </ThemedText.DeprecatedLargeHeader>
        </MouseoverTooltip>
      </S.GridCol>

      <S.GridCol>
        <ThemedText.DeprecatedLargeHeader color={theme.textPrimary} fontSize="16px" fontWeight={400}>
          {poolTokenPercentage ? (
            <>{poolTokenPercentage.toFixed(2) === '0.00' ? '<0.01' : poolTokenPercentage.toFixed(2)} %</>
          ) : (
            '-'
          )}
        </ThemedText.DeprecatedLargeHeader>
      </S.GridCol>

      <S.GridCol>
        {userDefaultPoolBalance && JSBI.greaterThan(userDefaultPoolBalance.quotient, BIG_INT_ZERO) && (
          <S.ManageButtonFlex>
            <ResponsiveButtonPrimary
              data-cy="add-v2-button"
              id="add-v2-button"
              as={Link}
              to={`/liquidity/v2/add/${currencyId(currency0)}/${currencyId(currency1)}`}
            >
              +
            </ResponsiveButtonPrimary>
            <ResponsiveButtonPrimary
              data-cy="remove-v2-button"
              id="remove-v2-button"
              as={Link}
              to={`/liquidity/v2/remove/${currencyId(currency0)}/${currencyId(currency1)}`}
            >
              -
            </ResponsiveButtonPrimary>
          </S.ManageButtonFlex>
        )}
        {/* {stakedBalance && JSBI.greaterThan(stakedBalance.quotient, BIG_INT_ZERO) && (
              <ButtonPrimary
                padding="8px"
                $borderRadius="8px"
                as={Link}
                to={`/uni/${currencyId(currency0)}/${currencyId(currency1)}`}
                width="100%"
              >
                <>Manage Liquidity in Rewards Pool</>
              </ButtonPrimary>
            )} */}
      </S.GridCol>
    </S.V2ListItemFlex>
  )
}
