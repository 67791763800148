import { gql } from '@apollo/client'
import { CurrencyAmount, Token } from '@kodiak-finance/sdk-core'
import { useSubgraphClient } from 'index'
import { useEffect, useMemo } from 'react'
import { TokenPriceQueryVariables } from 'state/data/generatedV3'
import { useSetTokenPrice, useTokenPriceState } from 'state/tokenPrice/hooks/useSetTokenPrice'

const QUERY = gql`
  query tokenPrice($tokenId: ID!) {
    token(id: $tokenId) {
      id
      derivedETH
    }
    bundle(id: "1") {
      ethPriceUSD
    }
  }
`

export const useSubgraphTokenPrice = (token: string | null | undefined) => {
  const tokenId = useMemo(() => token?.toLowerCase(), [token])
  const tokenPriceState = useTokenPriceState(tokenId)
  const { setTokenPrice, setLoading } = useSetTokenPrice()
  const apolloClient = useSubgraphClient('v3')
  const fetch = async () => {
    const _fetch = async () => {
      if (!tokenId) {
        return 0
      }

      setTokenPrice(tokenId, null)
      const { data: response } = await apolloClient.query<
        {
          token?: { id: string; derivedETH: string }
          bundle?: { ethPriceUSD: string }
        },
        TokenPriceQueryVariables
      >({
        query: QUERY,
        variables: {
          tokenId,
        },
      })

      if (!response || !response.token || !response.bundle) {
        return 0
      }

      const price = parseFloat(response.token.derivedETH) * parseFloat(response.bundle.ethPriceUSD)

      setTokenPrice(tokenId, price)
      return price
    }

    setLoading(tokenId, true)
    await _fetch()
    setLoading(tokenId, false)
  }

  useEffect(() => {
    if (tokenPriceState && tokenPriceState.loading) {
      return
    }

    if (!tokenPriceState || (tokenId && tokenPriceState.lastUpdate < Date.now() + 600)) {
      fetch()
    }
  }, [token])

  return tokenPriceState ? (tokenPriceState.loading ? null : tokenPriceState.value) : null
}

export const useSubgraphTokenValue = (amount: CurrencyAmount<Token> | null | undefined) => {
  const price = useSubgraphTokenPrice(amount?.currency.address)
  const memo = useMemo(() => {
    if (price === null) {
      return null
    }
    return parseFloat(amount?.toExact() || '0') * (price || 0)
  }, [amount, price])
  return memo
}

export const useSubgraphTokenValueWithEth = (amount: CurrencyAmount<Token> | null | undefined) => {
  const price = useSubgraphTokenPrice(amount?.currency?.wrapped?.address)
  const memo = useMemo(() => {
    if (price === null) {
      return null
    }
    return parseFloat(amount?.toExact() || '0') * (price || 0)
  }, [amount, price])
  return memo
}

export default useSubgraphTokenPrice
