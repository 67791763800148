import bearBanner from 'assets/images/bear-banner.png'
import { ButtonOutlined, ButtonPrimary } from 'components/Button'
import Modal from 'components/Modal'
import React, { ChangeEvent, ReactNode, useContext, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { ThemedText } from 'theme'
import { opacify } from 'theme/utils'

import AcceptTermsCheckbox from './AcceptTermsCheckbox'
import Terms from './Terms'

type FirstTimeUserModalProps = {
  onDismiss: () => void
  isOpen: boolean
  header?: ReactNode
  onAccept?: () => void
  onClose?: () => void
}

// Styled components
const ModalContent = styled.div`
  padding: 1rem;
`

// const AcceptTermsCheckbox = styled.input`
//   margin-right: 0.5rem;
//   height: 24px;
//   width: 24px;
// `

const AcceptTermsLabel = styled.label`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  margin-bottom: 1rem;
`

const ActionButton = styled.button`
  margin-top: 1rem;
`

const ErrorMessage = styled.div`
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.error500};
  background: ${({ theme }) => opacify(24, theme.error500)};

  padding: 1rem;
  /* color: red; */
  margin: 1rem 0;
`

const ModalInnerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0.5rem;
  margin-bottom: 1rem;

  border: 2px solid ${({ theme }) => theme.cardBorderSecondary};

  border-radius: 4px;
  background: ${({ theme }) => opacify(50, theme.cardSecondary)};
  :focus {
    outline: none;
  }
`

const ModalHeader = styled.div`
  height: 220px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const BearBanner = styled.img`
  height: auto;
  width: 100%;
  max-width: 420px;
  position: relative;
  bottom: 0;
  left: 0;
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`

const FirstTimeUserModal: React.FC<FirstTimeUserModalProps> = ({
  isOpen,
  onDismiss,
  onAccept,
  onClose,
  header,
  ...rest
}) => {
  const theme = useContext(ThemeContext)
  const [hasAgreed, setHasAgreed] = useState(false)
  const [error, setError] = useState('')

  const agreeError = 'Please check the box to agree to the terms and conditions before proceeding.'

  const handleDisagree = () => {
    setError(
      "You Must Agree to Use Our Services. Looks like you selected “I Disagree”, unfortunately you won't be able to use our services unless you agree to our terms of use agreement."
    )
  }

  const handleAccept = () => {
    if (!hasAgreed) {
      // Set an error that prompts the user to check the box
      setError(agreeError)
    } else {
      setError('')
      onAccept && onAccept()
    }
  }

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxWidth={800}>
      <ModalContent>
        <ModalHeader>
          <ThemedText.DeprecatedBody color={theme.textPrimary} fontWeight={600} fontSize={30}>
            Welcome to <span style={{ color: theme.buttonPrimary }}>Kodiak</span>{' '}
          </ThemedText.DeprecatedBody>
          <BearBanner src={bearBanner} alt="bear banner" />
        </ModalHeader>

        <ModalInnerContent tabIndex={0}>
          <Terms />
          {/* <ScrollableSection>
            <h3>Privacy Policy</h3>
            <p>[Privacy Policy content]</p>
          </ScrollableSection> */}
        </ModalInnerContent>
        <AcceptTermsLabel>
          <AcceptTermsCheckbox
            checked={hasAgreed}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setHasAgreed(e.target.checked)}
          />
          <ThemedText.DeprecatedBody color={error === agreeError ? theme.warning500 : theme.textSecondary}>
            I have read, understood, and agree to be bound by the terms and conditions, including any additional
            guidelines and future modifications outlined in the Public Testnet Terms of Use.
          </ThemedText.DeprecatedBody>
        </AcceptTermsLabel>

        <ButtonsWrapper>
          <ButtonPrimary padding="8px" $borderRadius="8px" onClick={handleAccept}>
            I Agree
          </ButtonPrimary>

          <ButtonOutlined padding="8px" $borderRadius="8px" onClick={handleDisagree}>
            I Disagree
          </ButtonOutlined>
        </ButtonsWrapper>

        {error && error !== agreeError && <ErrorMessage>{error}</ErrorMessage>}
      </ModalContent>
    </Modal>
  )
}

export default FirstTimeUserModal
