import { Currency, CurrencyAmount, Token } from '@kodiak-finance/sdk-core'
import { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import FiatValue from 'components/FiatValue'
import { RowBetween } from 'components/Row'
import { MouseoverTooltip } from 'components/Tooltip'
import { useSubgraphTokenValue } from 'hooks/useSubgraphTokenPrice'
import { useMemo } from 'react'
import { useTheme } from 'styled-components'
import { ThemedText } from 'theme'

import * as S from '../styleds'

type Stakes =
  | {
      kekId: string
      liquidity: CurrencyAmount<Token> | undefined
      endingTimestamp: Date
      lockMultiplier: number
    }[]
  | null
  | undefined

type IslandPositionCardProps = {
  totalFarmLiquidityUSD: number | undefined
  userBalanceSharesUSD: number | undefined
  combinedUserBalanceSharesUSD: number | undefined
  userBalanceShares: CurrencyAmount<Currency> | undefined
  combinedUserBalanceShares: CurrencyAmount<Currency> | undefined
  totalFarmLiquidity: CurrencyAmount<Token> | null | undefined
  islandCurrency: Currency | undefined
  userFarmUnderlyingBalances: {
    amount0: CurrencyAmount<Currency> | undefined
    amount1: CurrencyAmount<Currency> | undefined
  }
  combinedUserUnderlyingBalances: {
    amount0: CurrencyAmount<Currency> | undefined
    amount1: CurrencyAmount<Currency> | undefined
  }
  userUnderlyingBalances: {
    amount0: CurrencyAmount<Currency> | undefined
    amount1: CurrencyAmount<Currency> | undefined
  }
  currencyA: Currency | null
  currencyB: Currency | null
  token0: Token | null | undefined
  token1: Token | null | undefined
  stakes: Stakes
}

const useCalculatePositionValue = (stakes: Stakes | undefined, baseValue?: CurrencyAmount<Currency> | undefined) => {
  return useMemo(() => {
    let base = baseValue
    if (base === undefined) {
      if (stakes && stakes.length > 0 && stakes[0].liquidity?.currency) {
        base = CurrencyAmount.fromRawAmount(stakes[0].liquidity?.currency, 0)
      } else {
        return undefined
      }
    }

    return stakes?.reduce((acc, stake) => (stake.liquidity ? acc.add(stake.liquidity) : acc), base)
  }, [stakes, baseValue])
}

export default function IslandPositionCard({
  totalFarmLiquidityUSD,
  userBalanceSharesUSD,
  combinedUserBalanceSharesUSD,
  userBalanceShares,
  combinedUserBalanceShares,
  totalFarmLiquidity,
  islandCurrency,
  currencyA,
  currencyB,
  token0,
  token1,
  userUnderlyingBalances,
  combinedUserUnderlyingBalances,
  userFarmUnderlyingBalances,
  stakes: allStakes,
}: IslandPositionCardProps) {
  const { availableStakes, lockedStakes } = useMemo(() => {
    const filteredAllStakes = allStakes?.filter((item) => item.liquidity && item.liquidity.greaterThan('0'))
    const now = Date.now()

    const availableStakes = filteredAllStakes?.filter((stake) => stake.endingTimestamp.getTime() < now)
    const lockedStakes = filteredAllStakes?.filter((stake) => stake.endingTimestamp.getTime() >= now)

    return {
      availableStakes,
      lockedStakes,
    }
  }, [allStakes])

  const availableFarmBalanceShares = useCalculatePositionValue(availableStakes)
  const lockedFarmBalanceShares = useCalculatePositionValue(lockedStakes)

  const availableFarmBalanceSharesUSD = useSubgraphTokenValue(
    availableFarmBalanceShares as CurrencyAmount<Token> | null | undefined
  )
  const lockedFarmBalanceSharesUSD = useSubgraphTokenValue(
    lockedFarmBalanceShares as CurrencyAmount<Token> | null | undefined
  )

  const theme = useTheme()

  const Position = ({
    name,
    farmUSD,
    balanceUSD,
    balance,
    farmBalance,
    tooltip = true,
  }: {
    name: string
    farmUSD: number | null | undefined
    balanceUSD: number | null | undefined
    balance: CurrencyAmount<Currency> | undefined
    farmBalance: CurrencyAmount<Currency> | undefined
    tooltip?: boolean
  }) => (
    <AutoColumn>
      <ThemedText.DeprecatedBody fontSize="14px" fontWeight="400" lineHeight="14px" color={theme.textPrimary}>
        {name}
      </ThemedText.DeprecatedBody>
      <ThemedText.DeprecatedBody fontSize="20px" fontWeight="400" lineHeight="28px" color={theme.textPrimary}>
        <MouseoverTooltip
          disableHover={!tooltip}
          text={
            <>
              Wallet <FiatValue value={balanceUSD} />
              <br />
              Stake <FiatValue value={farmUSD} />
            </>
          }
        >
          <FiatValue value={[balanceUSD, farmUSD]} />
        </MouseoverTooltip>
      </ThemedText.DeprecatedBody>
      <ThemedText.DeprecatedBody fontSize="12px" fontWeight="400" lineHeight="20px" color={theme.textSecondary}>
        <MouseoverTooltip
          disableHover={!tooltip}
          text={
            <>
              Wallet {balance?.toSignificant(6) || 0}
              <br />
              Stake {farmBalance?.toSignificant(6) || 0}
            </>
          }
        >
          {(balance && farmBalance ? balance.add(farmBalance) : farmBalance ? farmBalance : balance)?.toFixed(2) ||
            '0.00'}{' '}
          {islandCurrency?.symbol}
        </MouseoverTooltip>
      </ThemedText.DeprecatedBody>
    </AutoColumn>
  )

  return (
    <S.IslandPositionCard>
      <S.IslandPositionDetails>
        <RowBetween>
          <Position
            name={!availableStakes && !lockedStakes ? 'My Deposits' : 'My Deposits'}
            farmUSD={totalFarmLiquidityUSD}
            balanceUSD={userBalanceSharesUSD}
            balance={userBalanceShares}
            farmBalance={totalFarmLiquidity || undefined}
          />
          {availableStakes &&
            (availableStakes.length > 0 ||
              (userBalanceShares?.greaterThan('0') && lockedStakes && lockedStakes.length > 0)) && (
              <Position
                name="Available"
                farmUSD={availableFarmBalanceSharesUSD}
                balanceUSD={userBalanceSharesUSD}
                balance={userBalanceShares}
                farmBalance={availableFarmBalanceShares || undefined}
              />
            )}
          {lockedStakes && lockedStakes.length > 0 && (
            <Position
              name="Locked"
              farmUSD={lockedFarmBalanceSharesUSD}
              balanceUSD={0}
              balance={undefined}
              farmBalance={lockedFarmBalanceShares || undefined}
              tooltip={false}
            />
          )}
        </RowBetween>
      </S.IslandPositionDetails>
      <S.InnerIslandCardFlex>
        <S.CurrencyFlexBetween>
          <S.FlexRowCenter>
            <CurrencyLogo currency={currencyA} size="20px" />
            <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
              {currencyA?.symbol}
            </ThemedText.DeprecatedBody>
          </S.FlexRowCenter>
          <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
            <MouseoverTooltip
              disableHover={!userFarmUnderlyingBalances?.amount0}
              text={
                <>
                  {userUnderlyingBalances?.amount0?.toSignificant(6)} +{' '}
                  {userFarmUnderlyingBalances?.amount0?.toSignificant(6)}{' '}
                  {combinedUserUnderlyingBalances?.amount0?.currency.symbol}
                </>
              }
            >
              {combinedUserUnderlyingBalances?.amount0?.toSignificant(6)}
            </MouseoverTooltip>
          </ThemedText.DeprecatedBody>
        </S.CurrencyFlexBetween>

        <S.CurrencyFlexBetween>
          <S.FlexRowCenter>
            <CurrencyLogo currency={currencyB} size="20px" />
            <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
              {currencyB?.symbol}
            </ThemedText.DeprecatedBody>
          </S.FlexRowCenter>
          <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
            <MouseoverTooltip
              disableHover={!userFarmUnderlyingBalances?.amount1}
              text={
                <>
                  {userUnderlyingBalances?.amount1?.toSignificant(6)} +{' '}
                  {userFarmUnderlyingBalances?.amount1?.toSignificant(6)}{' '}
                  {combinedUserUnderlyingBalances?.amount1?.currency.symbol}
                </>
              }
            >
              {combinedUserUnderlyingBalances?.amount1?.toSignificant(6)}
            </MouseoverTooltip>
          </ThemedText.DeprecatedBody>
        </S.CurrencyFlexBetween>
      </S.InnerIslandCardFlex>
    </S.IslandPositionCard>
  )
}
