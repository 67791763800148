import { CurrencyAmount, Token } from '@kodiak-finance/sdk-core'
import { ButtonPrimary } from 'components/Button'
import { LightCard } from 'components/Card'
import Column, { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import FiatValue from 'components/FiatValue'
import Loader from 'components/Loader'
import { RowBetween, RowFixed } from 'components/Row'
import ConfirmationTransactionStep, {
  ConfirmationTransactionStepProps,
} from 'components/StepLine/common/ConfirmationTransactionStep'
import { useStepLineState } from 'components/StepLine/context/StepLineContext'
import { TruncatedText } from 'components/swap/styleds'
import { MouseoverTooltip } from 'components/Tooltip'
import { useSubgraphTokenValue } from 'hooks/useSubgraphTokenPrice'
import { useContext, useMemo } from 'react'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components/macro'
import { Separator, ThemedText } from 'theme'

import { SelectLockTimeState } from './SelectLockTimeStep'

type StakeStepProps = {
  amount: CurrencyAmount<Token> | undefined
  onStake: () => void
  next: () => void
} & Omit<ConfirmationTransactionStepProps, 'onConfirm' | 'children'>

const ONE_DAY = 3600 * 24

const StakeStep = ({ next, onStake, amount, attemptingTxn, ...rest }: StakeStepProps) => {
  const {
    lockTime = 0,
    apr,
    multiplier = 1,
    earnings = [],
    rewardTokens = [],
  } = useStepLineState<SelectLockTimeState>()
  console.log(`Debug:test`, { earnings, rewardTokens })

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const earningsValues = earnings.map((earning) => useSubgraphTokenValue(earning))
  const earningTotalValue = useMemo(() => {
    return earningsValues.reduce((a, b) => (a || 0) + (b || 0), 0)
  }, [earningsValues])

  const theme = useContext(ThemeContext)

  return (
    <ConfirmationTransactionStep onConfirm={next} attemptingTxn={attemptingTxn} {...rest}>
      <Column width="100%" gap="4px" style={{ marginTop: '12px' }}>
        <LightCard padding="0.75rem 1rem">
          <AutoColumn gap="8px">
            <RowBetween align="center">
              <RowFixed gap="0px">
                <TruncatedText
                  fontSize={24}
                  fontWeight={500}
                  // color={showAcceptChanges && trade.tradeType === TradeType.EXACT_OUTPUT ? theme.deprecated_primary1 : ''}
                >
                  {amount ? amount?.toExact() : <Loader />}
                </TruncatedText>
              </RowFixed>
              <RowFixed gap="0px">
                <CurrencyLogo currency={amount?.currency} size="20px" style={{ marginRight: '12px' }} />
                <Text fontSize={20} fontWeight={500}>
                  {amount?.currency.symbol}
                </Text>
              </RowFixed>
            </RowBetween>
            <RowBetween>
              <FiatValue currencyAmount={amount} />
            </RowBetween>
          </AutoColumn>
        </LightCard>
        <LightCard>
          <AutoColumn gap="8px">
            <RowBetween>
              <RowFixed>
                <MouseoverTooltip
                  text={
                    <>
                      The amount you expect to receive at the current market price. You may receive less or more if the
                      market price changes while your transaction is pending.
                    </>
                  }
                  disableHover={false}
                >
                  <ThemedText.DeprecatedSubHeader color={theme.deprecated_text1}>
                    Lock period
                  </ThemedText.DeprecatedSubHeader>
                </MouseoverTooltip>
              </RowFixed>
              <ThemedText.DeprecatedBlack textAlign="right" fontSize={14}>
                {lockTime / ONE_DAY} days
              </ThemedText.DeprecatedBlack>
            </RowBetween>
            <RowBetween>
              <RowFixed>
                <MouseoverTooltip
                  text={
                    <>
                      The amount you expect to receive at the current market price. You may receive less or more if the
                      market price changes while your transaction is pending.
                    </>
                  }
                  disableHover={false}
                >
                  <ThemedText.DeprecatedSubHeader color={theme.deprecated_text1}>APR</ThemedText.DeprecatedSubHeader>
                </MouseoverTooltip>
              </RowFixed>
              <ThemedText.DeprecatedBlack textAlign="right" fontSize={14}>
                {apr?.toFixed(2)}%
              </ThemedText.DeprecatedBlack>
            </RowBetween>
            <RowBetween>
              <RowFixed>
                <MouseoverTooltip
                  text={<>The impact your trade has on the market price of this pool.</>}
                  disableHover={false}
                >
                  <ThemedText.DeprecatedSubHeader color={theme.deprecated_text1}>
                    Multiplier
                  </ThemedText.DeprecatedSubHeader>
                </MouseoverTooltip>
              </RowFixed>
              <ThemedText.DeprecatedBlack textAlign="right" fontSize={14}>
                x{multiplier && multiplier.toFixed(2)}
              </ThemedText.DeprecatedBlack>
            </RowBetween>
            <Separator redesignFlag={false} />
            <RowBetween>
              <RowFixed style={{ marginRight: '20px', alignItems: 'end' }}>
                <MouseoverTooltip
                  text={
                    <>
                      The minimum amount you are guaranteed to receive. If the price slips any further, your transaction
                      will revert.
                    </>
                  }
                  disableHover={false}
                >
                  <ThemedText.DeprecatedSubHeader color={theme.deprecated_text3}>
                    Estimated Rewards
                  </ThemedText.DeprecatedSubHeader>
                </MouseoverTooltip>
              </RowFixed>
              <Column>
                {earnings.map((earn, index) => {
                  return (
                    <RowFixed key={index}>
                      <ThemedText.DeprecatedBlack textAlign="right" fontSize={14} color={theme.deprecated_text3}>
                        {earn?.toSignificant()} {earn?.currency.symbol} (
                        <FiatValue value={earningsValues[index] || 0} />)
                      </ThemedText.DeprecatedBlack>
                    </RowFixed>
                  )
                })}
              </Column>
            </RowBetween>
            {earnings.length > 1 && (
              <RowBetween>
                <RowFixed style={{ marginRight: '20px' }}>
                  <MouseoverTooltip text={<>Summary reward</>} disableHover={false}>
                    <ThemedText.DeprecatedSubHeader color={theme.deprecated_text3}>
                      Summary reward
                    </ThemedText.DeprecatedSubHeader>
                  </MouseoverTooltip>
                </RowFixed>
                <ThemedText.DeprecatedBlack textAlign="right" fontSize={14} color={theme.deprecated_text3}>
                  <FiatValue value={earningTotalValue || 0} />
                </ThemedText.DeprecatedBlack>
              </RowBetween>
            )}
          </AutoColumn>
        </LightCard>
        <ButtonPrimary
          style={{ margin: '20px 0 0 0' }}
          onClick={onStake}
          disabled={!amount || attemptingTxn || amount.equalTo('0')}
        >
          <Text fontWeight={500} fontSize={20}>
            <>Confirm Stake</>
          </Text>
        </ButtonPrimary>
      </Column>
    </ConfirmationTransactionStep>
  )
}

export default StakeStep
