import { Percent } from '@kodiak-finance/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { DEFAULT_DEADLINE_FROM_NOW } from 'constants/misc'
import { RedesignVariant, useRedesignFlag } from 'featureFlags/flags/redesign'
import ms from 'ms.macro'
import { useContext, useState } from 'react'
import { useSetUserSlippageTolerance, useUserSlippageTolerance, useUserTransactionTTL } from 'state/user/hooks'
import styled, { ThemeContext } from 'styled-components/macro'

import { ThemedText } from '../../theme'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'

enum SlippageError {
  InvalidInput = 'InvalidInput',
}

enum DeadlineError {
  InvalidInput = 'InvalidInput',
}

const FancyButton = styled.button`
  color: ${({ theme }) => theme.deprecated_text1};
  align-items: center;
  height: 2rem;
  border-radius: 36px;
  font-size: 1rem;
  width: auto;
  min-width: 3.5rem;
  border: 1px solid ${({ theme }) => theme.deprecated_bg3};
  outline: none;
  background: ${({ theme }) => theme.deprecated_bg1};
  :hover {
    border: 1px solid ${({ theme }) => theme.deprecated_bg4};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.deprecated_primary1};
  }
`

const SlippageEmojiContainer = styled.span`
  position: absolute;
  left: 8px;
  color: #f3841e;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`

const Option = styled.div<{ active: boolean }>`
  display: flex;
  width: auto;
  cursor: pointer;
  padding: 4px 12px;
  text-align: center;
  gap: 4px;
  border-radius: 3px;
  background: ${({ active, theme }) => (active ? theme.cardPrimary : 'transparent')};
  /* border: 1px solid ${({ theme }) => theme.backgroundOutline}; */

  pointer-events: ${({ active }) => active && 'none'};
`

const Switch = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  padding: 4px;
  border-radius: 6px;
  height: 36px;
  background: ${({ theme }) => theme.cardSecondary};
`

export const Input = styled.input`
  width: 100%;
  height: 34px;
  border-radius: 6px;
  display: flex;
  flex: 1;
  font-size: 16px;
  outline: none;
  padding: 8px 24px 8px 8px;
  background: transparent;
  color: ${({ theme }) => theme.textPrimary};
  background: ${({ theme }) => theme.cardSecondary};
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  text-align: right;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  ::placeholder {
    color: ${({ theme }) => theme.textSecondary};
  }
`

export const TxnInput = styled(Input)`
  text-align: left;
`

export const InputContainer = styled(RowBetween)<{ error?: boolean }>`
  position: relative;
`

export const PercentAbsolute = styled.p`
  position: absolute;
  right: 8px;
`

export const OptionColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const SlippageRow = styled(RowBetween)`
  gap: 16px;
`

interface TransactionSettingsProps {
  placeholderSlippage: Percent // varies according to the context in which the settings dialog is placed
}

const THREE_DAYS_IN_SECONDS = ms`3 days` / 1000

export default function TransactionSettings({ placeholderSlippage }: TransactionSettingsProps) {
  const { chainId } = useWeb3React()
  const theme = useContext(ThemeContext)
  const redesignFlag = useRedesignFlag()
  const redesignFlagEnabled = redesignFlag === RedesignVariant.Enabled

  const userSlippageTolerance = useUserSlippageTolerance()
  const setUserSlippageTolerance = useSetUserSlippageTolerance()

  const [deadline, setDeadline] = useUserTransactionTTL()

  const [slippageInput, setSlippageInput] = useState('')
  const [slippageError, setSlippageError] = useState<SlippageError | false>(false)

  const [deadlineInput, setDeadlineInput] = useState('')
  const [deadlineError, setDeadlineError] = useState<DeadlineError | false>(false)

  function parseSlippageInput(value: string) {
    // populate what the user typed and clear the error
    setSlippageInput(value)
    setSlippageError(false)

    if (value.length === 0) {
      setUserSlippageTolerance('auto')
    } else {
      const parsed = Math.floor(Number.parseFloat(value) * 100)

      if (!Number.isInteger(parsed) || parsed < 0 || parsed > 5000) {
        setUserSlippageTolerance('auto')
        if (value !== '.') {
          setSlippageError(SlippageError.InvalidInput)
        }
      } else {
        setUserSlippageTolerance(new Percent(parsed, 10_000))
      }
    }
  }

  const [focusedUserSlippageInput, setFocusedUserSlippageInput] = useState(false)

  const tooLow = userSlippageTolerance !== 'auto' && userSlippageTolerance.lessThan(new Percent(5, 10_000))
  const tooHigh = userSlippageTolerance !== 'auto' && userSlippageTolerance.greaterThan(new Percent(1, 100))

  function parseCustomDeadline(value: string) {
    // populate what the user typed and clear the error
    setDeadlineInput(value)
    setDeadlineError(false)

    if (value.length === 0) {
      setDeadline(DEFAULT_DEADLINE_FROM_NOW)
    } else {
      try {
        const parsed: number = Math.floor(Number.parseFloat(value) * 60)
        if (!Number.isInteger(parsed) || parsed < 60 || parsed > THREE_DAYS_IN_SECONDS) {
          setDeadlineError(DeadlineError.InvalidInput)
        } else {
          setDeadline(parsed)
        }
      } catch (error) {
        console.error(error)
        setDeadlineError(DeadlineError.InvalidInput)
      }
    }
  }

  // const showCustomDeadlineRow = Boolean(chainId && !L2_CHAIN_IDS.includes(chainId)) OLD
  const showCustomDeadlineRow = false

  return (
    <AutoColumn gap="md">
      <AutoColumn gap="sm">
        <RowFixed>
          <ThemedText.DeprecatedBlack fontWeight={400} fontSize={14} color={theme.deprecated_text2}>
            <>Max Slippage</>
          </ThemedText.DeprecatedBlack>
          <QuestionHelper
            text={<>Your transaction will revert if the price changes unfavorably by more than this percentage.</>}
          />
        </RowFixed>
        <SlippageRow>
          <Switch>
            <Option
              onClick={() => {
                setUserSlippageTolerance('auto')
              }}
              active={userSlippageTolerance === 'auto' && !focusedUserSlippageInput}
            >
              <>Auto</>
            </Option>
            <Option
              onClick={() => {
                // When switching to custom slippage, use `auto` value as a default.
                parseSlippageInput('')
              }}
              active={userSlippageTolerance !== 'auto' || focusedUserSlippageInput}
              warning={!!slippageError}
              tabIndex={-1}
            >
              <>Custom</>
            </Option>
          </Switch>
          {/* 
          <OptionCustom
            redesignFlag={redesignFlagEnabled}
            active={userSlippageTolerance !== 'auto'}
            warning={!!slippageError}
            tabIndex={-1}
          > */}
          <InputContainer>
            {tooLow || tooHigh ? (
              <SlippageEmojiContainer>
                <span role="img" aria-label="warning">
                  ⚠️
                </span>
              </SlippageEmojiContainer>
            ) : null}
            <Input
              type="number"
              placeholder={placeholderSlippage.toFixed(2)}
              value={
                slippageInput.length > 0
                  ? slippageInput
                  : userSlippageTolerance === 'auto'
                  ? ''
                  : userSlippageTolerance.toFixed(2)
              }
              onChange={(e) => parseSlippageInput(e.target.value)}
              onBlur={() => {
                setSlippageInput('')
                setSlippageError(false)
                setFocusedUserSlippageInput(false)
              }}
              onFocus={() => {
                setFocusedUserSlippageInput(true)
              }}
              color={slippageError ? 'red' : ''}
            />
            <PercentAbsolute>%</PercentAbsolute>
          </InputContainer>
          {/* </OptionCustom> */}
        </SlippageRow>
        {slippageError || tooLow || tooHigh ? (
          <RowBetween
            style={{
              fontSize: '14px',
              paddingTop: '7px',
              color: slippageError ? 'red' : '#F3841E',
            }}
          >
            {slippageError ? (
              <>Enter a valid slippage percentage</>
            ) : tooLow ? (
              <>Your transaction may fail</>
            ) : (
              <>Your transaction may be frontrun</>
            )}
          </RowBetween>
        ) : null}
      </AutoColumn>

      {showCustomDeadlineRow && (
        <AutoColumn gap="sm">
          <RowFixed>
            <ThemedText.DeprecatedBlack fontSize={14} fontWeight={400} color={theme.deprecated_text2}>
              <>Transaction Deadline</>
            </ThemedText.DeprecatedBlack>
            <QuestionHelper
              text={<>Your transaction will revert if it is pending for more than this period of time.</>}
            />
          </RowFixed>
          <InputContainer>
            <TxnInput
              redesignFlag={redesignFlagEnabled}
              placeholder={(DEFAULT_DEADLINE_FROM_NOW / 60).toString()}
              value={
                deadlineInput.length > 0
                  ? deadlineInput
                  : deadline === DEFAULT_DEADLINE_FROM_NOW
                  ? ''
                  : (deadline / 60).toString()
              }
              onChange={(e) => parseCustomDeadline(e.target.value)}
              onBlur={() => {
                setDeadlineInput('')
                setDeadlineError(false)
              }}
              color={deadlineError ? 'red' : ''}
            />
            <PercentAbsolute>minutes</PercentAbsolute>
          </InputContainer>
        </AutoColumn>
      )}
    </AutoColumn>
  )
}
