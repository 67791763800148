import { BigintIsh, CurrencyAmount } from '@kodiak-finance/sdk-core'
import { useWeb3React } from '@web3-react/core'
import ERC20_ABI from 'abis/erc20.json'
import REWARDS_ABI from 'abis/KodiakRewards.json'
import { Interface } from 'ethers/lib/utils'
import { useSingleCallResult, useSingleContractMultipleData } from 'lib/hooks/multicall'
import { useMemo } from 'react'
import { isAddress } from 'utils'

import { useToken, useTokens } from './Tokens'
import { useKDK, useKodiakRewards, useXKDK } from './useContract'
export const IERC20 = new Interface(ERC20_ABI)
export const IRewards = new Interface(REWARDS_ABI)

export const useDividends = () => {
  const rewards = useKodiakRewards()
  const xKdk = useXKDK()
  const xKdkToken = useToken(xKdk?.address)
  const { account } = useWeb3React()

  const nextCycleStartTimeRaw = useSingleCallResult(rewards, 'nextCycleStartTime', [])
  const cycleDurationSecondsRaw = useSingleCallResult(rewards, 'cycleDurationSeconds', [])
  const distributedTokensLengthRaw = useSingleCallResult(rewards, 'distributedTokensLength', [])
  const totalAllocationRaw = useSingleCallResult(rewards, 'totalAllocation', [])
  const usagesDeallocationFeeRaw = useSingleCallResult(xKdk, 'usagesDeallocationFee', [rewards?.address])
  const usersAllocationRaw = useSingleCallResult(rewards, 'usersAllocation', [account])
  const userUsageAllocationRaw = useSingleCallResult(xKdk, 'getUsageAllocation', [account, rewards?.address])
  const usagesDeallocationFee = useMemo(() => {
    const fee = parseFloat(usagesDeallocationFeeRaw.result?.[0].toString()) / 100
    return isNaN(fee) ? undefined : fee
  }, [usagesDeallocationFeeRaw])

  const userUsageAllocation = useMemo(
    () =>
      xKdkToken && userUsageAllocationRaw?.result
        ? CurrencyAmount.fromRawAmount(xKdkToken, userUsageAllocationRaw?.result?.[0])
        : undefined,
    [userUsageAllocationRaw, xKdkToken]
  )

  const distributedTokensIds = useMemo(() => {
    const len = parseFloat(distributedTokensLengthRaw?.result?.[0].toString())
    const indexes = Array.from({ length: len }, (_, index) => index)
    return indexes.map((index) => [index])
  }, [distributedTokensLengthRaw])
  const distributedTokensRaw = useSingleContractMultipleData(rewards, 'distributedToken', distributedTokensIds)
  const distributedTokens = useMemo(() => {
    return distributedTokensRaw.map((distributedTokenRaw) => {
      return distributedTokenRaw?.result?.[0]
    })
  }, [distributedTokensRaw])

  const totalAllocation = useMemo(
    () =>
      xKdkToken && totalAllocationRaw?.result
        ? CurrencyAmount.fromRawAmount(xKdkToken, totalAllocationRaw?.result?.[0])
        : undefined,
    [totalAllocationRaw, xKdkToken]
  )

  const formattedTokens = useMemo(
    () =>
      distributedTokens
        .map((addr) => (isAddress(addr) ? addr : undefined))
        .filter((token) => typeof token === 'string'),
    [distributedTokens]
  )

  const tokens = useTokens(formattedTokens)

  const rewardsInfoRaw = useSingleContractMultipleData(
    rewards,
    'rewardsInfo',
    formattedTokens.map((token) => [token])
  )
  const pendingRewardsAmountRaw = useSingleContractMultipleData(
    rewards,
    'pendingRewardsAmount',
    formattedTokens.map((token) => [token, account])
  )

  const rewardsInfo = useMemo(() => {
    return rewardsInfoRaw.map((item, index) => {
      if (!item?.result) return undefined
      if (!tokens || tokens[index] === null) return undefined

      return {
        distributedAmount: CurrencyAmount.fromRawAmount(tokens[index]!, item.result.distributedAmount),
        currentDistributionAmount: CurrencyAmount.fromRawAmount(tokens[index]!, item.result.currentDistributionAmount),
        currentCycleDistributedAmount: CurrencyAmount.fromRawAmount(tokens[index]!, item.result.distributedAmount),
        pendingAmount: CurrencyAmount.fromRawAmount(tokens[index]!, item.result.pendingAmount),
      }
    })
  }, [rewardsInfoRaw, tokens])

  const pendingRewardsAmount = useMemo(() => {
    return pendingRewardsAmountRaw.map((item, index) => {
      if (!item?.result) return undefined
      if (!tokens || tokens[index] === null) return undefined

      return CurrencyAmount.fromRawAmount(tokens[index]!, item.result[0])
    })
  }, [tokens, pendingRewardsAmountRaw])

  const userAllocation = useMemo(
    () =>
      xKdkToken && usersAllocationRaw?.result
        ? CurrencyAmount.fromRawAmount(xKdkToken, usersAllocationRaw?.result?.[0].toString())
        : undefined,
    [usersAllocationRaw, xKdkToken]
  )

  const nextCycleStartTime = useMemo(
    () => parseFloat(nextCycleStartTimeRaw.result?.[0].toString()),
    [nextCycleStartTimeRaw]
  )
  const cycleDurationSeconds = useMemo(
    () => parseFloat(cycleDurationSecondsRaw.result?.[0].toString()),
    [cycleDurationSecondsRaw]
  )

  return {
    deallocationFee: usagesDeallocationFee,
    totalAllocation,
    nextCycleStartTime,
    userAllocation,
    userUsageAllocation,
    distributedTokens: tokens,
    rewardsInfo,
    pendingRewardsAmount,
    cycleDurationSeconds,
  }
}

export const useStaking = () => {
  const xkdk = useXKDK()
  const xKdkToken = useToken(xkdk?.address)
  const kdk = useKDK()
  const kdkToken = useToken(kdk?.address)

  const { account } = useWeb3React()

  const balance = useSingleCallResult(xkdk, 'balanceOf', [account])
  const minRedeemDuration = useSingleCallResult(xkdk, 'minRedeemDuration', [])
  const maxRedeemDuration = useSingleCallResult(xkdk, 'maxRedeemDuration', [])
  const getUserRedeemsLength = useSingleCallResult(xkdk, 'getUserRedeemsLength', [account])
  const getXKodiakBalance = useSingleCallResult(xkdk, 'getXKodiakBalance', [account])

  const redeems = useMemo(() => {
    const redeemsLen = parseFloat(getUserRedeemsLength?.result?.[0].toString())
    const redeemsIndexes = Array.from({ length: redeemsLen }, (_, index) => index)
    return redeemsIndexes.map((index) => [account, index])
  }, [getUserRedeemsLength, account])

  const userRedeemsRaw = useSingleContractMultipleData(xkdk, 'getUserRedeem', redeems)

  const userRedeems = useMemo(
    () =>
      userRedeemsRaw
        .map((userRedeem, index) => {
          if (userRedeem.error || userRedeem.loading || !userRedeem.valid || !xKdkToken || !kdkToken) return undefined
          return {
            kdkAmount: CurrencyAmount.fromRawAmount(kdkToken, userRedeem.result?.[0]),
            xKdkAmount: CurrencyAmount.fromRawAmount(xKdkToken, userRedeem.result?.[1]),
            endTime: parseFloat(userRedeem.result?.[2].toString() || 0),
            rewardsContract: userRedeem.result?.[3] as string,
            rewardsAllocation: userRedeem.result?.[4] as BigintIsh,
            index,
          }
        })
        .filter((item) => item !== undefined),
    [userRedeemsRaw]
  )

  if (
    !xKdkToken ||
    !balance?.result ||
    !minRedeemDuration?.result ||
    !maxRedeemDuration?.result ||
    !getXKodiakBalance?.result
  )
    return {}
  return {
    available: CurrencyAmount.fromRawAmount(xKdkToken, balance?.result?.[0]),
    allocated: CurrencyAmount.fromRawAmount(xKdkToken, getXKodiakBalance.result?.[0]),
    redeeming: CurrencyAmount.fromRawAmount(xKdkToken, getXKodiakBalance.result?.[1]),
    minRedeemDuration: parseFloat(minRedeemDuration?.result?.[0].toString()),
    maxRedeemDuration: parseFloat(maxRedeemDuration?.result?.[0].toString()),
    redeems: userRedeems,
  }
}

export default useStaking
