import Switch, { OptionType } from 'components/Switch'
import { useSubgraphSnapshots } from 'hooks/useSubgraphSnapshots' // make sure the path is correct
import { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components/macro'

import { ThemedText } from '../../../../theme'
import { useIslandContext } from '../hooks/useIslandContext'
import { FlexRowCenter } from '../styleds'
import AprView from './AprView'
import * as S from './styleds'
import TvlView from './TvlView'
import VolumeView from './VolumeView'

const IslandAnalyticsCard = () => {
  const [direction, setDirection] = useState<'tvl' | 'apr' | 'volume'>('tvl')
  const [unit, setUnit] = useState<'both' | 'token0' | 'token1'>('both')
  const [timeframe, setTimeframe] = useState<'7' | '30' | '90' | 'max'>('7')
  const [period, setPeriod] = useState<'1' | '2'>('1')

  const theme = useContext(ThemeContext)

  const context = useIslandContext()

  const { snapshots } = useSubgraphSnapshots(context.islandId)

  const directionOptions: OptionType<'tvl' | 'apr' | 'volume'>[] = [
    { value: 'tvl', label: 'TVL' },
    { value: 'apr', label: 'Island APR' },
    { value: 'volume', label: 'Volume' },
  ]

  const unitOptions: OptionType<'both' | 'token0' | 'token1'>[] = [
    { value: 'both', label: 'Both' },
    { value: 'token0', label: context.currencyA.symbol },
    { value: 'token1', label: context.currencyB.symbol },
  ]

  const timeframeOptions: OptionType<'7' | '30' | '90' | 'max'>[] = [
    { value: '7', label: '7d' },
    { value: '30', label: '30d' },
    { value: '90', label: '90d' },
    { value: 'max', label: 'All time' },
  ]

  const periodOptions: OptionType<'1' | '2'>[] = [
    { value: '1', label: 'Since period started' },
    { value: '2', label: 'Since vault inception' },
  ]

  const renderView = () => {
    switch (direction) {
      case 'tvl':
        return <TvlView {...context} unit={unit} timeframe={timeframe} period={period} snapshots={snapshots} />
      case 'apr':
        return <AprView {...context} timeframe={timeframe} snapshots={snapshots} />
      case 'volume':
        return <VolumeView {...context} unit={unit} timeframe={timeframe} snapshots={snapshots} />
      default:
        return null
    }
  }

  return (
    <S.IslandAnalyticsCard>
      <S.IslandAnalyticsCardHeader>
        <ThemedText.DeprecatedBody fontSize="14px" fontWeight="400" lineHeight="14px" color={theme.textPrimary}>
          Analytics
        </ThemedText.DeprecatedBody>

        <S.GraphTypeSwitchWrapper>
          <Switch<typeof direction> options={directionOptions} activeValue={direction} setActiveValue={setDirection} />
        </S.GraphTypeSwitchWrapper>
      </S.IslandAnalyticsCardHeader>

      {renderView()}
      <S.IslandAnalyticsCardFooter>
        <FlexRowCenter>
          <S.GraphModifierFlex>
            <ThemedText.DeprecatedBody fontSize="12px" fontWeight="400" lineHeight="20px" color={theme.textPrimary}>
              Asset
            </ThemedText.DeprecatedBody>
            <Switch<typeof unit>
              options={unitOptions}
              activeValue={unit}
              setActiveValue={setUnit}
              disabled={direction !== 'tvl'}
            />
          </S.GraphModifierFlex>

          <S.GraphModifierFlex>
            <ThemedText.DeprecatedBody fontSize="12px" fontWeight="400" lineHeight="20px" color={theme.textPrimary}>
              Timeframe
            </ThemedText.DeprecatedBody>
            <Switch<typeof timeframe>
              options={timeframeOptions}
              activeValue={timeframe}
              setActiveValue={setTimeframe}
            />
          </S.GraphModifierFlex>
        </FlexRowCenter>

        {/* <FlexRowCenter>
          <Switch<typeof period> options={periodOptions} activeValue={period} setActiveValue={setPeriod} />
        </FlexRowCenter> */}
      </S.IslandAnalyticsCardFooter>
    </S.IslandAnalyticsCard>
  )
}

export default IslandAnalyticsCard
