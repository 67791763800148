import { CommunalFarm } from 'abis/types'
import { CommunalFarmStake } from 'hooks/useCommunalFarms'
import sumCurrencyAmount from 'lib/utils/sumCurrencyAmount'
import { useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { calculateGasMargin } from 'utils/calculateGasMargin'

const useOnUnstakeCallback = (
  stakesToWithdraw: CommunalFarmStake[],
  farmContract: CommunalFarm | undefined | null,
  clear?: () => void
) => {
  const [attemptingTxnStake, setAttemptingTxnStake] = useState(false)
  const [hashStake, setHashStake] = useState('')
  const addTransaction = useTransactionAdder()

  const onConfirmWithdrawStakes = async () => {
    if (!stakesToWithdraw || !farmContract) return

    setAttemptingTxnStake(true)
    const args = stakesToWithdraw.map((stake) => stake.kekId)
    try {
      const gas = await farmContract.estimateGas.withdrawLockedMultiple(args)
      const response = await farmContract.withdrawLockedMultiple(args, {
        gasLimit: calculateGasMargin(gas),
      })
      setHashStake(response.hash)
      const sum = sumCurrencyAmount(stakesToWithdraw.map((item) => item.liquidity))

      addTransaction(response, {
        type: TransactionType.UNSTAKE_LIQUIDITY_ISLAND,
        amount: `${sum?.toSignificant(6) || 0} ${sum?.currency?.symbol}`,
      })
    } catch (e) {
      console.error(e)
    } finally {
      setAttemptingTxnStake(false)
    }
  }

  return {
    onConfirmWithdrawStakes,
    attemptingTxn: attemptingTxnStake,
    hash: hashStake,
    clear: () => {
      setAttemptingTxnStake(false)
      setHashStake('')
      clear && clear()
    },
  }
}

export default useOnUnstakeCallback
