import { ReactNode, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { ApplicationModal } from 'state/application/reducer'

interface SchemaItemBase {
  id: string
  label: ReactNode
}

export interface SchemaItemSingle extends SchemaItemBase {
  to: string
  sublinks?: never
  name?: never
  external?: boolean
}

export interface SchemaItemMultiple extends SchemaItemBase {
  to?: never
  external?: false

  sublinks: {
    id: string
    href: string
    label: string
  }[]
  name: ApplicationModal
}

export type SchemaTypeIsActiveExtend = { isActive: boolean }
export type SchemaTypeDefaultExtend = object

export type SchemaType<T extends SchemaTypeDefaultExtend = SchemaTypeDefaultExtend> = ((
  | SchemaItemMultiple
  | SchemaItemSingle
) &
  T)[]

export const Schema: SchemaType = [
  {
    id: 'swap-nav-link',
    to: '/swap',
    label: <>Swap</>,
  },
  {
    id: `liquidity-nav-link`,
    to: '/liquidity/islands',
    label: <>Liquidity</>,
  },
  // {
  //   id: 'pool-nav-link',
  //   to: '/pool',
  //   label: <>Pool</>,
  // },
  {
    id: 'staking-nav-link',
    to: '/staking',
    label: <>xKDK</>,
  },
  {
    id: 'analytics-nav-link',
    external: true,
    to: process.env.REACT_APP_V3_ANALYTICS!,
    label: <>Analytics</>,
  },
  {
    id: `beraden-nav-link`,
    external: true,
    to: 'https://www.beraden.com/',
    label: <>Faucet</>,
  },
  // {
  //   id: 'bridge-nav-link',
  //   label: <>Bridge</>,
  //   sublinks: [
  //     {
  //       id: 'bridge-nav-link-polygon',
  //       href: '/bridge/polygon',
  //       label: `Polygon`
  //     }
  //   ],
  //   name: ApplicationModal.VOTE
  // }
]

export const useSchema = (): SchemaType<SchemaTypeIsActiveExtend> => {
  const { pathname } = useLocation()

  const isLiquidityLink = pathname.startsWith('/liquidity')

  return useMemo(
    () =>
      Schema.map((item) => {
        let isActive = false

        if (item.id === 'liquidity-nav-link') {
          isActive = isLiquidityLink
        } else if (item.to) {
          // Logic for single link items
          isActive = pathname.startsWith(item.to)
        } else if (item.sublinks) {
          // Logic for items with sublinks
          isActive = item.sublinks.some((link) => pathname.startsWith(link.href))
        }
        return {
          ...item,
          isActive,
        }
      }),
    [pathname]
  )
}

export default Schema
