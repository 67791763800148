import React, { Dispatch, SetStateAction, useContext } from 'react'
import { ThemeContext } from 'styled-components/macro'

import { ThemedText } from '../../theme'
import * as S from './styleds'

export type OptionType<T> = {
  value: T
  label?: string
}

type SwitchProps<T> = {
  options: OptionType<T>[]
  activeValue: T
  setActiveValue: Dispatch<SetStateAction<T>>
}

export const Switch = <T extends string | number>({
  options,
  activeValue,
  setActiveValue,
  disabled,
}: SwitchProps<T> & { disabled?: boolean }) => {
  const theme = useContext(ThemeContext)

  return (
    <S.Switch>
      {options.map(({ value, label }) => (
        <S.Option
          key={String(value)}
          onClick={() => !disabled && setActiveValue(value)}
          active={activeValue === value}
          tabIndex={disabled ? -1 : 0}
          className="option"
          style={{ opacity: disabled ? 0.5 : 1, pointerEvents: disabled ? 'none' : 'auto' }}
        >
          <ThemedText.DeprecatedBody
            fontSize="12px"
            fontWeight="500"
            lineHeight="18px"
            color={activeValue === value ? theme.textPrimary : theme.textSecondary}
          >
            {label}
          </ThemedText.DeprecatedBody>
        </S.Option>
      ))}
    </S.Switch>
  )
}

export default Switch
