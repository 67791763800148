import { ColumnCenter } from 'components/Column'
import { RowBetween } from 'components/Row'
import { ReactNode, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { ThemedText } from 'theme'

import Step from './common/Step'
import { useStepLineStateUpdate } from './context/StepLineContext'

const StepLineWrapper = styled.div`
  width: 100%;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 80%;
    height: 2px;
    background-color: ${({ theme }) => theme.borderSecondary};
    // z-index: 5;
  }

  &:last-child::after {
    display: none;
  }
`

enum StepCircleStyle {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  CURRENT = 'current',
}

const StepCircle = styled.div<{ status: StepCircleStyle }>`
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: ${({ theme, status }) =>
    status === StepCircleStyle.ACTIVE
      ? theme.success500
      : status === StepCircleStyle.CURRENT
      ? theme.accentActive
      : theme.cardSecondary};
  align-items: center;
  justify-content: center;

  z-index: 1;
  border: 4px solid ${({ theme }) => theme.cardPrimary};
`

export type StepLineProps = {
  steps: (Step | undefined)[]
  onSwitchStep?: () => void
} & (
  | {
      finish?: ReactNode
    }
  | {
      onFinish?: () => void
    }
)

const StepLine = ({ steps: rawSteps, onSwitchStep, ...rest }: StepLineProps) => {
  const steps = useMemo(() => rawSteps.filter((step) => step !== undefined), [rawSteps]) as Step[]
  const update = useStepLineStateUpdate<{ __currentStep: Step; __prevStep: Step }>()

  const activeIndex = useMemo(() => {
    return steps.findIndex((step) => {
      if (typeof step.condition === 'function') {
        return !step.condition()
      }
      return !step.condition
    })
  }, [steps])

  useEffect(() => {
    if (activeIndex > -1) {
      update({ __currentStep: steps[activeIndex] })

      if (activeIndex > 0) {
        update({ __prevStep: steps[activeIndex - 1] })
      }
    }
  }, [activeIndex])

  const finish = 'finish' in rest ? rest.finish : undefined
  const onFinish = 'onFinish' in rest ? rest.onFinish : undefined

  useEffect(() => {
    if (activeIndex === -1) {
      onFinish?.()
    } else {
      onSwitchStep && onSwitchStep()
    }
  }, [activeIndex])

  const lastComponent = useMemo(() => steps[steps.length - 1]?.component, [steps])

  return (
    <ColumnCenter gap="8px">
      {steps && steps.length > 1 && (
        <StepLineWrapper>
          <RowBetween>
            {steps.map((step, index) => {
              return (
                <StepCircle
                  key={index}
                  status={
                    activeIndex === -1
                      ? StepCircleStyle.ACTIVE
                      : activeIndex === index
                      ? StepCircleStyle.CURRENT
                      : index > activeIndex
                      ? StepCircleStyle.INACTIVE
                      : step.condition
                      ? StepCircleStyle.ACTIVE
                      : StepCircleStyle.INACTIVE
                  }
                >
                  <ThemedText.DeprecatedSubHeader>{index + 1}</ThemedText.DeprecatedSubHeader>
                </StepCircle>
              )
            })}
          </RowBetween>
        </StepLineWrapper>
      )}
      <ColumnCenter>
        {activeIndex === -1 && (finish ? finish : lastComponent)}
        {activeIndex !== -1 && steps[activeIndex]?.component}
      </ColumnCenter>
    </ColumnCenter>
  )
}

export default StepLine
