import Polling from 'components/HeaderKodiak/Polling'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { ReactComponent as DiscordLogo } from '../../assets/svg/discord_logo.svg'
import { ReactComponent as MediumLogo } from '../../assets/svg/medium_logo.svg'
import { ReactComponent as TwitterLogo } from '../../assets/svg/twitter_logo.svg'

const FooterWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;

  padding: 28px 80px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  justify-self: flex-end;
  bottom: 0;
  background: ${({ theme }) => theme.backgroundBackdrop};
`

const FooterInnerFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  p {
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    color: ${({ theme }) => theme.textSecondary};
    &:hover {
      color: ${({ theme }) => theme.textPrimary};
    }
  }
`

const IconWrapper = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg > g > * {
    transition: fill 0.3s ease-in-out;
  }
  svg > g > g > * {
    transition: fill 0.3s ease-in-out;
  }

  &:hover {
    svg > g > * {
      fill: ${({ theme }) => theme.textPrimary};
    }
    svg > g > g > * {
      fill: ${({ theme }) => theme.textPrimary};
    }
  }
`

const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <FooterInnerFlex>
        {/* <T.BodyM color="textSecondary">FAQs</T.BodyM> */}

        {/* <Link to="https://docs.kodiak.finance/resources/terms" target="_blank">
          <T.BodyM color="textSecondary">Terms</T.BodyM>
        </Link>
        <Link to="https://docs.kodiak.finance/resources/privacy" target="_blank">
          <T.BodyM color="textSecondary">Privacy</T.BodyM>
        </Link> */}
      </FooterInnerFlex>
      <Polling />
      <FooterInnerFlex>
        {/* <Link to="https://docs.kodiak.finance" target="_blank">
          <T.BodyM color="textSecondary">Docs</T.BodyM>
        </Link> */}

        <Link to="https://twitter.com/KodiakFi" target="_blank">
          <IconWrapper>
            <TwitterLogo height="20px" width="20px" title="Twitter" />
          </IconWrapper>
        </Link>

        <Link to="https://discord.gg/kodiak" target="_blank">
          <IconWrapper>
            <DiscordLogo height="20px" width="18.667px" title="Discord" />
          </IconWrapper>
        </Link>

        <Link to="https://medium.com/@KodiakFi" target="_blank">
          <IconWrapper>
            <MediumLogo height="11.25px" width="20px" title="Medium" />
          </IconWrapper>
        </Link>
      </FooterInnerFlex>
    </FooterWrapper>
  )
}

export default Footer
