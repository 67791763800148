import { gql } from '@apollo/client'
import { useSubgraphClient } from 'index'
import { useEffect } from 'react'
import { useSetSnapshotData, useSnapshotState } from 'state/islandSnapshots/hooks/useSetSnapshotData'

const SNAPSHOT_QUERY = gql`
  query GetVaultSnapshots($islandId: ID!) {
    kodiakVaultDailySnapshots(where: { vault_: { id: $islandId } }) {
      id
      timestamp
      totalValueLockedUSD
      _token0Amount
      _token1Amount
      _token0AmountUSD
      _token1AmountUSD
      upperTick
      lowerTick
      volumeUSD # 24 hour possible?
      apr
      outputTokenPriceUSD
      volumeUSD
      vault {
        apr {
          averageApr
        }
        pool {
          volumeUSD
          volumeToken0
          volumeToken1
          tick
        }
        _token0 {
          id
          symbol
          volume
          volumeUSD
          totalValueLocked
          totalValueLockedUSD
        }
        _token1 {
          id
          symbol
          volume
          volumeUSD
          totalValueLocked
          totalValueLockedUSD
        }
      }
    }
  }
`

export const useSubgraphSnapshots = (islandId: string) => {
  const apolloClient = useSubgraphClient('v3')

  const { setSnapshots, setLoading } = useSetSnapshotData()

  useEffect(() => {
    setLoading(islandId, true)

    apolloClient
      .query({
        query: SNAPSHOT_QUERY,
        variables: { islandId },
      })
      .then((response) => {
        if (response.data && response.data.kodiakVaultDailySnapshots) {
          setSnapshots(islandId, response.data.kodiakVaultDailySnapshots)
        }
        setLoading(islandId, false)
      })
      .catch((error) => {
        console.error('Error fetching snapshots:', error)
        setLoading(islandId, false)
      })
  }, [apolloClient, islandId])

  return useSnapshotState(islandId)
}
