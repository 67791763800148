import { createAction } from '@reduxjs/toolkit'

export type DayDataV2Type = {
  id: string
  date: number
  dailyVolumeETH: string
  dailyVolumeUSD: string
  totalLiquidityUSD: string
}

export type ProtocolDataV3Type = {
  date: number
  volumeUSD: string
  feesUSD: string
  tvlUSD: string
}

export const setDayDataV2 = createAction<DayDataV2Type[]>('protocolData/setDayDataV2')
export const setDayDataV2Loading = createAction<boolean>('protocolData/setDayDataV2Loading')

export const setProtocolDataV3 = createAction<ProtocolDataV3Type[]>('protocolData/setProtocolDataV3')
export const setProtocolDataV3Loading = createAction<boolean>('protocolData/setProtocolDataV3Loading')
