export const farmsAddresses = [
  '0x0aDCf291a4e2461b448508c09467DF57E6dd4291',
  '0xDAAaF87d3d861ECA51b277AabB89c36221E0ed4e',
  '0x1B6499B0f481133d721eE052425C07Aa0515e46D',
  '0xB48D5AF04Dd15Badb9373684f46cEf65fd34f68e',
  '0xe4f77542Fca9a43B511e8B9aBEe8f12bA62d3150',
  '0x6012F322a63b48F51E96fBa48e0fA0840Ba1A0A2',
  '0xa42Be3AA6a2907D1fc402A5CB477fE285CDC8062',
  '0x01B2e15201b775B33088Ae5E258b92c77579Dc23',
]
