import React, { useContext, useEffect, useState } from 'react'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { ThemeContext, useTheme } from 'styled-components'
import { ThemedText } from 'theme'
import { formatDollarAmount } from 'utils/formatDollarAmt'

import { FlexRowCenter } from '../styleds'
import * as S from './styleds'
import { SnapshotType } from './types' // Ensure this type is correctly imported
import { filterDataByTimeframe, selectTimeFormat } from './utils/chartHelpers'

type VolumeViewProps = {
  unit: 'both' | 'token0' | 'token1'
  timeframe: '7' | '30' | '90' | 'max'
  snapshots: SnapshotType[]
}

const VolumeView: React.FC<VolumeViewProps> = ({ unit, timeframe, snapshots }) => {
  const theme = useContext(ThemeContext)
  const [filteredData, setFilteredData] = useState<SnapshotType[]>([])

  useEffect(() => {
    const newFilteredData = filterDataByTimeframe(snapshots, timeframe)
    setFilteredData(newFilteredData)
  }, [timeframe, snapshots])

  const maxVolumeUSD = Math.max(...filteredData.map((snap) => snap.volumeUSD), 0)

  const xAxisFormatter = selectTimeFormat(timeframe)

  const CustomTooltip: React.FC<CustomTooltipProps> = ({
    active,
    payload,
    label,
    theme,
    xAxisFormatter,
    activeToken,
  }) => {
    if (active && payload && payload.length) {
      return (
        <S.StrategyTooltipFlex>
          <ThemedText.DeprecatedBody fontSize="12px" fontWeight="500" lineHeight="20px" color={theme.textSecondary}>
            {xAxisFormatter(payload[0].payload.timestamp)}
          </ThemedText.DeprecatedBody>
          <FlexRowCenter>
            <ThemedText.DeprecatedBody fontSize="14px" fontWeight="700" lineHeight="20px" color={theme.textPrimary}>
              {formatDollarAmount(payload[0].value)}
            </ThemedText.DeprecatedBody>
          </FlexRowCenter>
        </S.StrategyTooltipFlex>
      )
    }
    return null
  }

  const CustomTickLabel = ({
    x,
    y,
    payload,
    axisType,
  }: {
    x?: number
    y?: number
    payload?: any
    axisType?: 'xAxis' | 'yAxis'
  }) => {
    const value = axisType === 'xAxis' ? xAxisFormatter(payload.value) : formatDollarAmount(payload.value)
    const { textSecondary } = useTheme()
    return (
      <text
        x={x}
        y={y}
        dy={axisType === 'xAxis' ? 10 : 0}
        dx={axisType === 'xAxis' ? 0 : -14}
        textAnchor="middle"
        fill={textSecondary}
        fontSize={12}
      >
        {value}
      </text>
    )
  }

  console.log('volume:filtered:', filteredData)

  return (
    <S.GraphFlex style={{ overflow: 'hidden' }}>
      <ResponsiveContainer width="100%" height={182} minWidth="100%">
        <BarChart data={filteredData} margin={{ top: 24, right: 8, left: -8, bottom: 0 }}>
          <XAxis
            dataKey="timestamp"
            stroke="white"
            tickLine={false}
            axisLine={false}
            tick={<CustomTickLabel axisType="xAxis" />}
            minTickGap={5}
          />
          <YAxis
            dataKey="volumeUSD"
            stroke="white"
            tickLine={false}
            axisLine={false}
            tick={<CustomTickLabel axisType="yAxis" />}
            minTickGap={2}
            domain={[0, maxVolumeUSD]}
          />
          <Tooltip content={<CustomTooltip theme={theme} xAxisFormatter={xAxisFormatter} />} />
          <Bar dataKey="volumeUSD" fill="#3083DC" />
        </BarChart>
      </ResponsiveContainer>
    </S.GraphFlex>
  )
}

export default VolumeView
