export interface TypographyType {
  body: {
    XS: { fontSize: string; lineHeight: string; fontWeight: { bold: number; regular: number } }
    S: {
      fontSize: string
      lineHeight: string
      fontWeight: { bold: number; semibold: number; regular: number }
    }
    M: { fontSize: string; lineHeight: string; fontWeight: { bold: number; regular: number } }
    L: {
      mobile: {
        fontSize: string
        lineHeight: string
        fontWeight: { bold: number; regular: number }
      }
      desktop: {
        fontSize: string
        lineHeight: string
        fontWeight: { bold: number; regular: number }
      }
    }
    XL: {
      mobile: {
        fontSize: string
        lineHeight: string
        fontWeight: { bold: number; semibold: number; regular: number }
      }
      desktop: {
        fontSize: string
        lineHeight: string
        fontWeight: { bold: number; semibold: number; regular: number }
      }
    }
  }
  heading: {
    mobile: {
      S: {
        fontSize: string
        lineHeight: string
        fontWeight: { light: number; regular: number; bold: number }
      }
      M: {
        fontSize: string
        lineHeight: string
        fontWeight: { light: number; regular: number; bold: number }
      }
      L: {
        fontSize: string
        lineHeight: string
        fontWeight: { light: number; regular: number; bold: number }
      }
      XL: {
        fontSize: string
        lineHeight: string
        fontWeight: { light: number; regular: number; bold: number }
      }
    }
    desktop: {
      S: {
        fontSize: string
        lineHeight: string
        fontWeight: { light: number; regular: number; bold: number }
      }
      M: {
        fontSize: string
        lineHeight: string
        fontWeight: { light: number; regular: number; bold: number }
      }
      L: {
        fontSize: string
        lineHeight: string
        fontWeight: { light: number; regular: number; bold: number }
      }
      XL: {
        fontSize: string
        lineHeight: string
        fontWeight: { light: number; regular: number; bold: number }
      }
    }
  }
  link: {
    fontSize: string
    lineHeight: string
    fontWeight: number
  }
  supportive: {
    fontSize: string
    lineHeight: string
    fontWeight: number
  }
}

const typography: TypographyType = {
  heading: {
    mobile: {
      S: {
        fontSize: '20px',
        lineHeight: '28px',
        fontWeight: { light: 300, regular: 400, bold: 700 },
      },
      M: {
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: { light: 300, regular: 400, bold: 700 },
      },
      L: {
        fontSize: '32px',
        lineHeight: '44px',
        fontWeight: { light: 300, regular: 400, bold: 700 },
      },
      XL: {
        fontSize: '40px',
        lineHeight: '48px',
        fontWeight: { light: 300, regular: 400, bold: 700 },
      },
    },
    desktop: {
      S: {
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: { light: 300, regular: 400, bold: 700 },
      },
      M: {
        fontSize: '32px',
        lineHeight: '44px',
        fontWeight: { light: 300, regular: 400, bold: 700 },
      },
      L: {
        fontSize: '40px',
        lineHeight: '48px',
        fontWeight: { light: 300, regular: 400, bold: 700 },
      },
      XL: {
        fontSize: '48px',
        lineHeight: '56px',
        fontWeight: { light: 300, regular: 400, bold: 700 },
      },
    },
  },
  body: {
    XS: { fontSize: '12px', lineHeight: '20px', fontWeight: { bold: 700, regular: 400 } },
    S: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: { bold: 700, semibold: 600, regular: 400 },
    },
    M: { fontSize: '16px', lineHeight: '24px', fontWeight: { bold: 600, regular: 400 } },
    L: {
      mobile: {
        fontSize: '18px',
        lineHeight: '24px',
        fontWeight: { bold: 700, regular: 400 },
      },
      desktop: {
        fontSize: '20px',
        lineHeight: '28px',
        fontWeight: { bold: 700, regular: 400 },
      },
    },
    XL: {
      mobile: {
        fontSize: '20px',
        lineHeight: '28px',
        fontWeight: { bold: 600, semibold: 500, regular: 400 },
      },
      desktop: {
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: { bold: 700, semibold: 600, regular: 400 },
      },
    },
  },
  supportive: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 600,
  },
  link: { fontSize: '16px', lineHeight: '24px', fontWeight: 500 },
}

export default typography
