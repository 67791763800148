const formatMoney = (amount: number | string): string => {
  const numericAmount = Number(amount)

  // Convert absolute amount for processing
  const absAmount = Math.abs(numericAmount)

  if (absAmount === 0) return `$0`
  else if (absAmount < 0.01) return `<$0.01`
  else if (absAmount < 1_000) return `$${parseFloat(numericAmount.toFixed(2)).toString()}` // Less than a thousand
  else if (absAmount < 1_000_000) return `$${parseFloat((numericAmount / 1_000).toFixed(1)).toString()}k` // Thousands
  else if (absAmount < 1_000_000_000)
    return `$${parseFloat((numericAmount / 1_000_000).toFixed(2)).toString()}M` // Millions
  else if (absAmount < 1_000_000_000_000)
    return `$${parseFloat((numericAmount / 1_000_000_000).toFixed(2)).toString()}B` // Billions
  else if (absAmount < 1_000_000_000_000_000)
    return `$${parseFloat((numericAmount / 1_000_000_000_000).toFixed(2)).toString()}T` // Trillions
  else return `$${parseFloat((numericAmount / 1_000_000_000_000_000).toFixed(2)).toString()}Q` // Quadrillions
}

export default formatMoney
