import { createReducer } from '@reduxjs/toolkit'

import { setLoading, setPrice } from './actions'

export type TokenPrice = {
  value: number
  lastUpdate: number
  loading: boolean
}
export type TokenPriceState = {
  [key: string]: TokenPrice
}

export const initialState: TokenPriceState = {}

export default createReducer<TokenPriceState>(initialState, (builder) => {
  builder.addCase(setLoading, (state, { payload }) => {
    return {
      ...state,
      [payload.tokenId]: {
        value: state[payload.tokenId]?.value || 0,
        lastUpdate: state[payload.tokenId]?.lastUpdate || 0,
        loading: payload.loading,
      },
    }
  })
  builder.addCase(setPrice, (state, { payload }) => {
    const dateNow = Date.now()
    return {
      ...state,
      [payload.tokenId]: {
        value: payload.price,
        lastUpdate: dateNow,
        loading: false,
      },
    }
  })
})
