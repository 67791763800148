import { CurrencyAmount, Token } from '@kodiak-finance/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useTokenBalance } from 'lib/hooks/useCurrencyBalance'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { useEffect, useState } from 'react'

export const useInputToken = (token: Token | undefined): [CurrencyAmount<Token> | undefined, () => void] => {
  const { account } = useWeb3React()
  const balance = useTokenBalance(account, token)
  const [balanceState, setBalanceState] = useState<typeof balance | null>(undefined)
  const [diffBalance, setDiffBalance] = useState<typeof balance>(undefined)
  useEffect(() => {
    if (!balance) {
      return
    }
    if (balanceState === undefined) {
      if (balance.equalTo('0')) {
        setBalanceState(null)
      } else {
        setBalanceState(tryParseCurrencyAmount(balance.toExact(), token))
      }
      return
    }

    if (balanceState !== null && !balanceState.equalTo(balance)) {
      setDiffBalance(balance.subtract(balanceState))
    } else if (balanceState === null) {
      setDiffBalance(balance)
    }
  }, [balance, balanceState])

  const reset = () => {
    setBalanceState(undefined)
    setDiffBalance(undefined)
  }

  const zero = token !== undefined ? CurrencyAmount.fromRawAmount(token, '0') : undefined
  const ret = diffBalance || zero

  return [ret, reset]
}
