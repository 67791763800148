import CurrencyLogo from 'components/CurrencyLogo'
import { useToken } from 'hooks/Tokens'
import { useCommunalFarm } from 'hooks/useCommunalFarms'
import { useContext, useEffect } from 'react'
import { ArrowLeft } from 'react-feather'
import { Link, useParams } from 'react-router-dom'
import { resetFarmInputs } from 'state/farms/actions'
import { useAppDispatch } from 'state/hooks'
import { ThemeContext } from 'styled-components'

import { FarmProvider } from './context/FarmContext'
import EarnedCard from './EarnedCard'
import InfoCard from './InfoCard'
import ManagementCard from './ManagementCard'
import PositionCard from './PositionCard'
import {
  AdaptiveCardFlex,
  BackButton,
  CardRowFlex,
  FarmTitle,
  FarmTitleWrapper,
  FlexCol,
  SecondCardRowFlex,
  StakeInfoCard,
  Wrapper,
} from './styleds'

export default function FarmPage() {
  const { farmId } = useParams<{ farmId: string }>()
  const farm = useCommunalFarm(farmId!)
  const stakingToken = useToken(farm?.stakingToken)
  const theme = useContext(ThemeContext)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(resetFarmInputs())
  }, [])

  if (!farm) return null
  return (
    <FarmProvider farm={farm}>
      <Wrapper>
        <Link
          data-cy="visit-farms"
          style={{ textDecoration: 'none', width: 'fit-content', marginBottom: '0.5rem' }}
          to="/farms"
        >
          <BackButton>
            <ArrowLeft color={theme.textSecondary} size={20} />
            Back to farms
          </BackButton>
        </Link>
        <CardRowFlex>
          <StakeInfoCard>
            <FarmTitleWrapper>
              <div>Stake</div>
              <FarmTitle>{stakingToken?.name}</FarmTitle>
              <CurrencyLogo currency={stakingToken} size="24px" />
            </FarmTitleWrapper>
            <InfoCard />
          </StakeInfoCard>
          <PositionCard />
        </CardRowFlex>

        <SecondCardRowFlex>
          <FlexCol>
            <AdaptiveCardFlex></AdaptiveCardFlex>
          </FlexCol>
          <FlexCol>
            <ManagementCard />
            <AdaptiveCardFlex size="md">
              <EarnedCard />
            </AdaptiveCardFlex>
          </FlexCol>
        </SecondCardRowFlex>
      </Wrapper>
    </FarmProvider>
  )
}
