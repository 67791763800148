import styled from 'styled-components'
import { TypographyType } from 'theme/typography'
// import { ColorOptionName } from 'types/ThemeTypes/ColorType'
// import getColorFromTheme from 'utils/getColorFromTheme'

type FontProps = {
  fontWeight?: 'light' | 'regular' | 'semibold' | 'bold'
  //   color?: ColorOptionName
}

type HeaderProps<T extends FontProps> = {
  size: keyof TypographyType['heading']['desktop']
} & T

type BodyProps<T extends FontProps> = {
  size: keyof TypographyType['body']
  semibold?: boolean
} & T

const Heading = styled.div<HeaderProps<FontProps>>`
  transition: all 0.3s ease-in-out;
  font-style: normal;
  letter-spacing: 0px;

  font-weight: ${({ size, theme, fontWeight }) =>
    !fontWeight
      ? theme.typography.heading.mobile[size].fontWeight['bold']
      : theme.typography.heading.mobile[size].fontWeight[fontWeight] ?? 700};
  font-size: ${({ size, theme }) => theme.typography.heading.mobile[size].fontSize};
  line-height: ${({ size, theme }) => theme.typography.heading.mobile[size].lineHeight};

  @media (min-width: ${({ theme }) => theme.devices.laptop}) {
    font-weight: ${({ size, theme, fontWeight }) =>
      !fontWeight
        ? theme.typography.heading.desktop[size].fontWeight['bold']
        : theme.typography.heading.desktop[size].fontWeight[fontWeight] ?? 700};
    font-size: ${({ size, theme }) => theme.typography.heading.desktop[size].fontSize};
    line-height: ${({ size, theme }) => theme.typography.heading.desktop[size].lineHeight};
  }
`

const Body = styled.p<BodyProps<FontProps>>`
  transition: all 0.3s ease-in-out;
  letter-spacing: 0px;

  font-weight: ${({ fontWeight, theme, size }) =>
    !fontWeight
      ? theme.typography.body[size].fontWeight['regular']
      : theme.typography.body[size].fontWeight[fontWeight] ?? 400};

  font-size: ${({ size, theme }) => theme.typography.body[size].fontSize};
  line-height: ${({ size, theme }) => theme.typography.body[size].lineHeight};
`

const SBodyL = styled.p<BodyProps<FontProps>>`
  transition: all 0.3s ease-in-out;
  letter-spacing: 0px;

  font-weight: ${({ fontWeight, theme }) =>
    !fontWeight
      ? theme.typography.body.L.mobile.fontWeight['regular']
      : theme.typography.body.L.mobile.fontWeight[fontWeight] ?? 400};

  font-size: ${({ theme }) => theme.typography.body.L.mobile.fontSize};
  line-height: ${({ theme }) => theme.typography.body.L.mobile.lineHeight};

  @media (min-width: ${({ theme }) => theme.devices.laptop}) {
    font-size: ${({ theme }) => theme.typography.body.L.desktop.fontSize};
    line-height: ${({ theme }) => theme.typography.body.L.desktop.lineHeight};
  }
`

const SBodyXL = styled.p<BodyProps<FontProps>>`
  transition: all 0.3s ease-in-out;
  letter-spacing: 0px;

  font-weight: ${({ fontWeight, theme }) =>
    !fontWeight
      ? theme.typography.body.XL.mobile.fontWeight['regular']
      : theme.typography.body.XL.mobile.fontWeight[fontWeight] ?? 400};

  font-size: ${({ theme }) => theme.typography.body.XL.mobile.fontSize};
  line-height: ${({ theme }) => theme.typography.body.XL.mobile.lineHeight};

  @media (min-width: ${({ theme }) => theme.devices.laptop}) {
    font-size: ${({ theme }) => theme.typography.body.XL.desktop.fontSize};
    line-height: ${({ theme }) => theme.typography.body.XL.desktop.lineHeight};
  }
`

export const Supportive = styled.p`
  font-style: normal;
  font-weight: ${({ theme }) => theme.typography.supportive.fontWeight};
  font-size: ${({ theme }) => theme.typography.supportive.fontSize};
  line-height: ${({ theme }) => theme.typography.supportive.lineHeight};
  text-transform: uppercase;
`

export const TextLink = styled.a`
  letter-spacing: 0.01em;
  text-decoration-line: underline;

  font-style: normal;
  font-weight: ${({ theme }) => theme.typography.link.fontWeight};
  font-size: ${({ theme }) => theme.typography.link.fontSize};
  line-height: ${({ theme }) => theme.typography.link.lineHeight};
  transition: all 0.3s ease-out;

  &:hover {
  }
`

export const HeadingS = styled(Heading).attrs({ size: 'S', as: 'h3' })``
export const HeadingM = styled(Heading).attrs({ size: 'M', as: 'h2' })``
export const HeadingL = styled(Heading).attrs({ size: 'L', as: 'h1' })``
export const HeadingXL = styled(Heading).attrs({ size: 'XL', as: 'h1' })``

export const BodyXS = styled(Body).attrs({ size: 'XS' })``
export const BodyS = styled(Body).attrs({ size: 'S' })``
export const BodyM = styled(Body).attrs({ size: 'M' })``
export const BodyL = styled(SBodyL).attrs({ size: 'L' })``
export const BodyXL = styled(SBodyXL).attrs({ size: 'XL' })``
