import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components/macro'

import { ReactComponent as KodiakLogoDark } from '../../assets/svg/kodiak-text-logo-default.svg'
import { ReactComponent as KodiakLogoLight } from '../../assets/svg/kodiak-text-logo-invert.svg'

const PoweredByFlex = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 8px;
  margin: 50px auto;
`

const PoweredByText = styled.p`
  color: ${({ theme }) => theme.textSecondary};
  /* small */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
`

export default function PoweredByKodiak() {
  const [darkMode, toggleDarkMode] = useDarkModeManager()

  return (
    <PoweredByFlex>
      <PoweredByText>Powered By</PoweredByText>
      {darkMode ? <KodiakLogoDark /> : <KodiakLogoLight />}
    </PoweredByFlex>
  )
}
