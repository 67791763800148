import { useWeb3React } from '@web3-react/core'
import { ButtonPrimary } from 'components/Button'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { useApproveStep } from 'components/StepLine/common/ApproveStep'
import useStepWithNext from 'components/StepLine/context/hooks/useStepWithNext'
import StepLineModal from 'components/StepLine/StepLineModal'
import { useToken } from 'hooks/Tokens'
import { useKDK, useXKDK } from 'hooks/useContract'
import { useTokenBalance } from 'lib/hooks/useCurrencyBalance'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { useMemo, useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { calculateGasMargin } from 'utils/calculateGasMargin'

import ConvertStep from './ConvertStep'

const ConvertView = () => {
  const [inputKdk, setInputKdk] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const kdk = useKDK()
  const xKdk = useXKDK()
  const kdkToken = useToken(kdk?.address)
  const amount = useMemo(() => {
    return tryParseCurrencyAmount(inputKdk, kdkToken || undefined)
  }, [inputKdk, kdkToken])
  const { account } = useWeb3React()
  const balance = useTokenBalance(account, kdkToken || undefined)

  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [hash, setHash] = useState('')
  const addTransaction = useTransactionAdder()

  const onConvert = async () => {
    if (!xKdk || !amount) return
    const quotient = amount.quotient.toString()

    setAttemptingTxn(true)
    try {
      const gas = await xKdk.estimateGas.convert(quotient)
      const res = await xKdk.convert(quotient, {
        gasLimit: calculateGasMargin(gas),
      })
      if (res.hash) {
        setHash(res.hash)
        addTransaction(res, {
          type: TransactionType.STAKE_CONVERT,
          amount: `${amount?.toSignificant(6)} ${amount?.currency?.symbol}`,
        })
      }
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      setAttemptingTxn(false)
    }
  }

  const approveStep = useApproveStep(amount, xKdk?.address)
  const convertStep = useStepWithNext('convert-step', ConvertStep, {
    amount,
    attemptingTxn,
    pendingText: (
      <>
        Converting {amount?.toSignificant(6)} {amount?.currency.symbol}
      </>
    ),
    hash,
    autoConfirm: true,
    transactionWaiting: true,
    onConvert,
  })

  const handleConvert = () => {
    if (amount && amount.greaterThan('0')) {
      setIsModalOpen(true)
    }
  }
  return (
    <>
      <StepLineModal
        steps={[approveStep, convertStep]}
        isOpen={isModalOpen}
        onFinish={() => setIsModalOpen(false)}
        onDismiss={() => setIsModalOpen(false)}
      />
      <CurrencyInputPanel
        // hideBalance={true}
        value={inputKdk}
        onUserInput={setInputKdk}
        onMax={() => setInputKdk(balance?.toExact() ?? '0')}
        showMaxButton={true}
        currency={kdkToken}
        label="kdk"
        // onCurrencySelect={handleSelectCurrencyA}
        id="convert-kdk"
      />
      <ButtonPrimary disabled={!amount || !amount.greaterThan('0')} onClick={handleConvert}>
        Convert
      </ButtonPrimary>
    </>
  )
}

export default ConvertView
