import { Currency } from '@kodiak-finance/sdk-core'
import { createContext } from 'react'

import { IslandContextType } from './IslandContextType'

const initialContext: IslandContextType = {
  currencyA: null as unknown as Currency,
  currencyB: null as unknown as Currency,
  wrappedNativeCurrency: null,
  feeAmount: 0,
  islandId: '',
  router: null,
}

export const IslandContext = createContext<IslandContextType>(initialContext)
