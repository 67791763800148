import { Currency, CurrencyAmount, Fraction } from '@kodiak-finance/sdk-core'
import { LightCard } from 'components/Card'
import Column, { AutoColumn } from 'components/Column'
import { useContext } from 'react'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'

import { ButtonPrimary } from '../../../components/Button'
import CurrencyLogo from '../../../components/CurrencyLogo'
import { AutoRow, RowTitled } from '../../../components/Row'
import { Field } from '../../../state/mint/actions'
import { Separator, ThemedText } from '../../../theme'

export function ConfirmAddModalBottom({
  noLiquidity,
  price,
  currencies,
  parsedAmounts,
  amountSharesMin,
  onAdd,
}: {
  noLiquidity?: boolean
  price?: Fraction
  currencies: { [field in Field]?: Currency }
  parsedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  amountSharesMin?: string
  onAdd: () => void
}) {
  const theme = useContext(ThemeContext)
  return (
    <>
      <LightCard>
        <AutoColumn gap="sm">
          <RowTitled
            header={
              <Text fontSize={14} color={theme.textSecondary}>
                {currencies[Field.CURRENCY_A]?.symbol} deposited
              </Text>
            }
          >
            <CurrencyLogo size="18px" currency={currencies[Field.CURRENCY_A]} />
            <ThemedText.DeprecatedBody marginLeft="4px">
              {parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}
            </ThemedText.DeprecatedBody>
          </RowTitled>
          <RowTitled
            header={
              <Text fontSize={14} color={theme.textSecondary}>
                {currencies[Field.CURRENCY_B]?.symbol} deposited
              </Text>
            }
          >
            <CurrencyLogo size="18px" currency={currencies[Field.CURRENCY_B]} />
            <ThemedText.DeprecatedBody marginLeft="4px">
              {parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}
            </ThemedText.DeprecatedBody>
          </RowTitled>
          <Separator />
          <RowTitled
            align="flex-start"
            header={
              <Text fontSize={14} color={theme.textSecondary}>
                Rates
              </Text>
            }
          >
            <Column width="100%" gap="4px">
              <AutoRow justify="flex-end" gap="2px">
                <CurrencyLogo size="18px" currency={currencies[Field.CURRENCY_A]} />
                <Text fontSize={14}>1</Text>
                <Text fontSize={14}>=</Text>
                <CurrencyLogo size="18px" currency={currencies[Field.CURRENCY_B]} />
                <Text fontSize={14}>{price?.toSignificant(4)}</Text>
              </AutoRow>
              <AutoRow justify="flex-end" gap="2px">
                <CurrencyLogo size="18px" currency={currencies[Field.CURRENCY_B]} />
                <Text fontSize={14}>1</Text>
                <Text fontSize={14}>=</Text>
                <CurrencyLogo size="18px" currency={currencies[Field.CURRENCY_A]} />
                <Text fontSize={14}>{price?.invert().toSignificant(4)}</Text>
              </AutoRow>
            </Column>
          </RowTitled>

          <Separator />

          <RowTitled
            header={
              <Text fontSize={14} color={theme.textSecondary}>
                Est. Received
              </Text>
            }
          >
            <Text fontSize={14}>{amountSharesMin}</Text>
            <Text fontSize={14} marginLeft="2px" color={theme.textSecondary}>
              Island tokens
            </Text>
          </RowTitled>
        </AutoColumn>
      </LightCard>
      <ButtonPrimary style={{ margin: '20px 0 0 0' }} onClick={onAdd}>
        <Text fontWeight={500} fontSize={20}>
          {noLiquidity ? <>Create Pool & Supply</> : <>Confirm Supply</>}
        </Text>
      </ButtonPrimary>
    </>
  )
}
