import { Currency, CurrencyAmount } from '@kodiak-finance/sdk-core'

function sumCurrencyAmount<T extends Currency>(
  amounts: (CurrencyAmount<T> | undefined)[],
  baseAmount?: CurrencyAmount<T>
) {
  const currency = amounts.find((amount) => !!amount?.currency)?.currency
  if (currency === undefined) return baseAmount || undefined
  const base = baseAmount || CurrencyAmount.fromRawAmount(currency, '0')
  return amounts
    .filter((item) => item !== undefined)
    .reduce((prev, curr) => {
      return prev!.add(curr!)
    }, base)
}
export default sumCurrencyAmount
