import { CurrencyAmount, Token } from '@kodiak-finance/sdk-core'
import Badge, { BadgeVariant } from 'components/Badge'
import { ButtonPrimary } from 'components/Button'
import { LightCard } from 'components/Card'
import Column, { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import Loader from 'components/Loader'
import { AutoRow, RowBetween } from 'components/Row'
import Slider from 'components/Slider'
import { StepRequired } from 'components/StepLine/common/Step'
import { useStepLineNext } from 'components/StepLine/context/hooks/useStepLineNext'
import { useStepLineStateUpdate } from 'components/StepLine/context/StepLineContext'
import { useToken } from 'hooks/Tokens'
import { useCalculationEarnings, useCommunalFarmApr, useCommunalFarmByIsland } from 'hooks/useCommunalFarms'
import { useSubgraphTokenValue } from 'hooks/useSubgraphTokenPrice'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { useContext, useEffect, useMemo } from 'react'
import useLockTimeValue, { useLockTimeMultiplier } from 'state/farms/hooks/useLockTimeValue'
import { Farm } from 'state/farms/reducer'
import { ThemeContext } from 'styled-components'
import { Separator, ThemedText } from 'theme'

export const useSelectLockTimeStep = (islandId?: string, value?: string): StepRequired => {
  const farm = useCommunalFarmByIsland(islandId)

  const { lockTime, setLockTime } = useLockTimeValue(farm?.lockTimeMin, farm?.lockTimeMax)
  const { condition, next, back } = useStepLineNext('selectLockTimeStepDone')

  return {
    title: 'Select Lock Time',
    condition,
    onBack: back,
    component: (
      <SelectLockTimeStep
        setLockTime={setLockTime}
        typedValue={value}
        lockTime={lockTime}
        farm={farm || undefined}
        next={next}
      />
    ),
  }
}

export type SelectLockTimeStepProps = {
  lockTime: number
  farm?: Farm
  setLockTime: (value: number) => void
  next: () => void
  typedValue: string | undefined
}

export type SelectLockTimeState = {
  lockTime: number
  apr?: number | null
  multiplier?: number | null
  earnings: (CurrencyAmount<Token> | undefined)[]
  rewardTokens: (Token | null | undefined)[]
}

const SelectLockTimeStep = ({ lockTime, setLockTime, typedValue, farm, next }: SelectLockTimeStepProps) => {
  const multiplier = useLockTimeMultiplier(lockTime, farm)
  const stakingToken = useToken(farm?.stakingToken)
  const amount = useMemo(
    () => tryParseCurrencyAmount<Token>(typedValue, stakingToken || undefined),
    [typedValue, stakingToken]
  )
  const value = useSubgraphTokenValue(amount)

  const apr = useCommunalFarmApr(farm, multiplier, amount)
  const theme = useContext(ThemeContext)
  const earnings = useCalculationEarnings({
    lockTimeValue: lockTime,
    multiplier,
    farm,
    inputValue: typedValue || '1',
  })

  const rewardTokens = farm?.rewardTokens.map(useToken) || []

  const update = useStepLineStateUpdate<SelectLockTimeState>()
  useEffect(() => {
    update({ lockTime, apr, multiplier, rewardTokens, earnings })
  }, [lockTime])

  return (
    <Column gap="8px" width="100%">
      <ThemedText.DeprecatedSubHeader color={theme.textSecondary}>Select lock-up period</ThemedText.DeprecatedSubHeader>
      {lockTime / (3600 * 24)} days
      <Slider
        step={3600 * 24}
        min={farm?.lockTimeMin}
        max={farm?.lockTimeMax}
        value={lockTime}
        onChange={setLockTime}
      ></Slider>
      <ThemedText.DeprecatedSubHeader color={theme.textSecondary}>
        Estimated award by the end of the lock-up period
      </ThemedText.DeprecatedSubHeader>
      <LightCard>
        <AutoColumn gap="sm">
          {rewardTokens.map((rewardToken, index) => {
            return (
              <RowBetween key={index}>
                <AutoRow gap="2px">
                  <CurrencyLogo currency={rewardToken} size="20px" />
                  <ThemedText.DeprecatedSubHeader color={theme.textSecondary}>
                    {rewardToken?.symbol}
                  </ThemedText.DeprecatedSubHeader>
                </AutoRow>
                <AutoRow justify="flex-end">
                  <ThemedText.DeprecatedSubHeader color={theme.textPrimary}>
                    {earnings?.[index]?.toSignificant(2)}{' '}
                    {lockTime === 0 ? (
                      <Badge size="sm" variant={BadgeVariant.WARNING}>
                        per year
                      </Badge>
                    ) : (
                      <Badge size="sm" variant={BadgeVariant.PRIMARY}>
                        per {lockTime / (3600 * 24)} days
                      </Badge>
                    )}{' '}
                  </ThemedText.DeprecatedSubHeader>
                </AutoRow>
              </RowBetween>
            )
          })}

          <Separator />
          <RowBetween>
            <ThemedText.DeprecatedSubHeader color={theme.textSecondary}>Stake amount</ThemedText.DeprecatedSubHeader>
            <ThemedText.DeprecatedSubHeader color={theme.textSecondary}>
              {amount?.toSignificant(6)} {stakingToken?.symbol}
            </ThemedText.DeprecatedSubHeader>
          </RowBetween>
          <RowBetween>
            <ThemedText.DeprecatedSubHeader color={theme.textSecondary}>Multiplier</ThemedText.DeprecatedSubHeader>
            <ThemedText.DeprecatedSubHeader color={theme.textSecondary}>
              x{multiplier.toFixed(2)}
            </ThemedText.DeprecatedSubHeader>
          </RowBetween>
          <RowBetween>
            <ThemedText.DeprecatedSubHeader color={theme.textSecondary}>APR</ThemedText.DeprecatedSubHeader>
            <ThemedText.DeprecatedSubHeader color={theme.textSecondary}>
              {typeof apr === 'number' ? (
                apr > 1000000 ? (
                  '>1,000,000%'
                ) : apr < 0.01 ? (
                  '<0.01%'
                ) : (
                  `${apr.toFixed(2)}%`
                )
              ) : (
                <Loader />
              )}
            </ThemedText.DeprecatedSubHeader>
          </RowBetween>
        </AutoColumn>
      </LightCard>
      <ButtonPrimary onClick={next}>Confirm</ButtonPrimary>
    </Column>
  )
}

export default SelectLockTimeStep
