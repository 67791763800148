import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { opacify } from 'theme/utils'

const ScrollableSection = styled.div`
  height: 300px;
  overflow-y: scroll;
  background: ${({ theme }) => theme.cardSecondary};
  padding: 1rem;
  border-radius: 4px;

  background: ${({ theme }) => opacify(50, theme.cardSecondary)};
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);

  p {
    margin: 0pt 0pt 12pt;
  }
  li {
    margin-top: 0pt;
    margin-bottom: 12pt;
  }
  ul {
    list-style: none;
    padding-inline-start: 8px;
  }

  a {
    color: ${({ theme }) => theme.buttonPrimary};
    transition: color 0.3s linear;

    &:hover {
      color: ${({ theme }) => theme.hoverState};
    }
  }
`

const Terms = () => (
  <ScrollableSection>
    <p autoFocus>
      <strong>TERMS OF USE</strong>
    </p>
    <p>Last updated on February 27, 2024</p>
    <p>
      OUR SERVICES ARE NOT OFFERED TO PERSONS OR ENTITIES WHO RESIDE IN, ARE CITIZENS OF, ARE LOCATED IN, ARE
      INCORPORATED IN, OR HAVE A REGISTERED OFFICE IN THE UNITED STATES OR ANY RESTRICTED TERRITORY (AS DEFINED BELOW)
      (ANY SUCH PERSON OR ENTITY FROM A RESTRICTED TERRITORY BEING A “<strong>RESTRICTED PERSON</strong>”). USE OF A
      VIRTUAL PRIVATE NETWORK (“<strong>VPN</strong>”) TO CIRCUMVENT THESE RESTRICTIONS IS PROHIBITED.
    </p>
    <p>
      These terms of use, together with any additional agreements, documents and terms incorporated by reference, which
      includes any other terms and conditions or other agreement that KDK Protocol Labs S.A. (“
      <strong>Kodiak</strong>”, “<strong>we</strong>”, “<strong>us</strong>” and “<strong>our</strong>”) posts publicly
      or makes available to you or the person or entity you represent (“<strong>you</strong>” or “<strong>your</strong>
      ”) (collectively, these “<strong>Terms</strong>”), are entered into between Kodiak and you concerning your use of,
      and access to, our (a) websites, including kodiak.finance; (b) web applications; mobile applications and (c) all
      associated sites linked thereto by Kodiak or its affiliates (collectively with any materials and services
      available therein, and successor website(s) or application(s) thereto, the “<strong>Site</strong>”).
    </p>
    <p>
      As part of the Site, we provide a user interface (the “<strong>Platform</strong>”) to access (a) a decentralized
      exchange platform for trading cryptocurrencies and other blockchain-based assets (“<strong>Digital Assets</strong>
      ”) in a decentralized, peer-to-peer manner, (b) an automated liquidity manager, (c) an integrated incentive layer
      and (d) a no-code token deployer factory (collectively, the “<strong>Protocol</strong>”). These Terms expressly
      cover your rights and obligations, and our disclaimers and limitations of legal liability, relating to your use
      of, and access to, the Site, the Platform, the Protocol and all related tools, applications, data, software and
      other services provided by us (collectively, the “<strong>Services</strong>”). By accessing or using the Site or
      the Services, you accept and agree to be bound by and to comply with these Terms. If you do not agree to these
      Terms, then you must not access or use the Site or the Services.
    </p>
    <p>
      By accessing or using the Site or the Services, you agree that Kodiak does not provide execution, settlement, or
      clearing services of any kind and is not responsible for the execution, settlement, or clearing of transactions
      automated through the Services.
    </p>
    <h2>Use of the Services</h2>
    <ul>
      <li>
        <ul>
          <li>
            <span> </span>
            <strong>Eligibility. </strong>
            <br />
            <br />
            As a condition to accessing or using the Services or the Site, you represent and warrant that:
            <ul>
              <li>
                <span> </span>
                if you are an individual, you are of legal age in the jurisdiction in which you reside and you have the
                legal capacity to enter into these Terms and be bound by them;
              </li>
              <li>
                <span> </span>
                if you are an entity, then you have the legal authority to accept these Terms;
              </li>
              <li>
                <span> </span>you are not a resident, national, or agent of, located in, incorporated or otherwise
                formed in, or have a registered office in, the United States;
              </li>
              <li>
                <span> </span>you are not a resident, national, or agent of, located in, incorporated or otherwise
                formed in, or have a registered office in, Antigua and Barbuda, Algeria, Bangladesh, Bolivia, Belarus,
                Burundi, Burma (Myanmar), Cote D'Ivoire (Ivory Coast), the regions of Crimea, Donetsk or Luhansk, Cuba,
                Democratic Republic of Congo, Ecuador, Iran, Iraq, Liberia, Libya, Magnitsky, Mali, Morocco, Nepal,
                North Korea, Somalia, Sudan, Syria, Venezuela, Yemen, Zimbabwe or any other country to which the United
                States, the United Kingdom or the European Union embargoes goods or imposes similar sanctions
                (collectively, “<strong>Restricted Territories</strong>”);
              </li>
              <li>
                <span> </span>
                you are not a member of any sanctions list or equivalent maintained by the United States government, the
                United Kingdom government, the European Union or the United Nations (a “
                <strong>Sanctioned Person</strong>”);
              </li>
              <li>
                <span> </span>you do not transact with or intend to transact with any Restricted Person or Sanctioned
                Person;
              </li>
              <li>
                <span> </span>you do not, and will not, use VPN software or any other privacy or anonymization tools or
                techniques to circumvent, or attempt to circumvent, any restrictions that apply to the Services; and
              </li>
              <li>
                <span> </span>your access to the Services (A) is not prohibited by and does not otherwise violate or
                assists you to violate any (1) laws, constitutions, treaties, statutes, codes, ordinances, principles of
                common and civil law and equity, orders, decrees, rules, regulations and municipal by-laws, whether
                domestic, foreign or international; (2) judicial, arbitral, administrative, ministerial, departmental
                and regulatory judgments, orders, writs, injunctions, decisions, rulings, decrees and awards of any (a)
                multinational or supranational body or organization, nation, government, state, province, country,
                territory, municipality, quasi-government, administrative, judicial or regulatory authority, agency,
                board, body, bureau, commission, instrumentality, court or tribunal or any political subdivision
                thereof, or any central bank (or similar monetary or regulatory authority) thereof, any taxing
                authority, any ministry or department or agency of any of the foregoing; (b) self-regulatory
                organization or stock exchange; (c) entity exercising executive, legislative, judicial, regulatory or
                administrative functions of or pertaining to government; or (d) any corporation or other entity owned or
                controlled, through stock or capital ownership or otherwise, by any of such entities or other bodies
                pursuant to the foregoing (each, a “<strong>Governmental Authority</strong>”); or (3) policies,
                practices and guidelines of, or contracts with, any Governmental Authority, which, although not actually
                having the force of law, are considered by such Governmental Authority as requiring compliance as if
                having the force of law, as the same may be amended from time to time and any successor thereto and in
                each case binding on, affecting or having jurisdiction over Kodiak, you, the Site or the Services
                (collectively, “<strong>Applicable Laws</strong>
                ”); and (B) does not contributes to or facilitates any illegal activity.
              </li>
            </ul>
          </li>
          <li>
            <span> </span>
            <strong>Acknowledgements. </strong>
            <br />
            <br />
            As a condition to accessing or using the Services or the Site, you acknowledge and agree that:
            <ul>
              <li>
                <span> </span>
                from time to time the Site and the Services may be inaccessible or inoperable for any reason, including
                without limitation: (A) equipment malfunctions; (B) periodic maintenance procedures or repairs that we
                or any of its suppliers or contractors may undertake from time to time; (C) causes beyond our control or
                that we could not reasonably foresee; (D) disruptions and temporary or permanent unavailability of
                underlying software, including without limitation blockchain infrastructure; or (E) unavailability of
                third-party service providers or external partners for any reason;
              </li>
              <li>
                <span> </span>
                we reserve the right to suspend, restrict or modify access to the Site and the Services at any time in
                the event of any breach of these Terms, including, without limitation, if we reasonably believe any of
                your representations and warranties may be untrue or inaccurate, and we will not be liable to you, and
                you will hold us harmless from, any losses or damages you may suffer as a result of or in connection
                with the Site or the Services being inaccessible to you at any time or for any reason;
              </li>
              <li>
                <span> </span>we may change, replace, or discontinue (temporarily or permanently) some or all of the
                Services at any time in our sole discretion;
              </li>
              <li>
                <span> </span>the pricing information provided on the Site does not represent an offer, a solicitation
                of an offer, or any advice regarding, or recommendation to enter into, a transaction with Kodiak;
              </li>
              <li>
                <span> </span>
                Kodiak does not act as an agent for you or any other user of the Site or the Services;
              </li>
              <li>
                <span> </span>you are solely responsible for your use of the Services, including all of your transfers
                of Digital Assets;
              </li>
              <li>
                <span> </span>to the fullest extent not prohibited by Applicable Laws, we owe no fiduciary duties or
                liabilities to you or any other party, and that to the extent any such duties or liabilities may exist
                under Applicable Laws, you hereby irrevocably disclaim and waive all of such duties and liabilities and
                hold us harmless from any of the foregoing;
              </li>
              <li>
                <span> </span>you are solely responsible for reporting and paying any taxes applicable to your use of
                the Services;
              </li>
              <li>
                <span> </span>we have no control over, or liability for, the delivery, quality, safety, legality, or any
                other aspect of any Digital Assets that you may transfer to or from a third party and we are not
                responsible for ensuring that an entity with whom you transact with completes the transaction or is
                authorized to do so; and
              </li>
              <li>
                <span> </span>
                you bear the entire risk with any transactions in Digital Assets and in using the Services.
              </li>
            </ul>
          </li>
          <li>
            <span> </span>
            <strong>User Responsibilities. </strong>
            <br />
            <br />
            As a condition to accessing or using the Services or the Site, you covenant to Kodiak the following:
            <ul>
              <li>
                <span> </span>
                you (A) must provide all equipment, connectivity, and software necessary to connect to the Services and
                (B) are solely responsible for any costs and expenses, including Internet connection or mobile fees,
                which you incur when accessing the Services;
              </li>
              <li>
                <span> </span>
                in connection with using the Services, you will only transfer legally-obtained Digital Assets;
              </li>
              <li>
                <span> </span>you will comply with all Applicable Laws and obtain and maintain all applicable
                registrations or licenses under Applicable Laws in connection with using the Services, and you will not
                use the Site or the Services if the laws of your country, or any other Applicable Laws, prohibit you
                from doing so;
              </li>
              <li>
                <span> </span>any Digital Assets you use in connection with the Services are either (A) owned by you or
                (B) you are validly authorized to carry out actions using such Digital Assets; and
              </li>
              <li>
                <span> </span>
                in addition to complying with all restrictions, prohibitions, and other provisions of these Terms, you
                will (A) ensure that, at all times, all information that you provide on the Site and during your use of
                the Services is current, complete, and accurate and (B) maintain the security and confidentiality of
                your private keys associated with your Wallet (as defined below), passwords, API keys and other related
                credentials.
              </li>
            </ul>
          </li>
          <li>
            <span> </span>
            <strong>Digital Wallet; Non-Custodial Services</strong>
            <br /> <br />
            <ul>
              <li>
                <span> </span>
                In order to use certain features of the Site and Services, you may be required to connect your digital
                asset wallet(s) or address(es) (collectively, “<strong>Wallet</strong>”) to the Platform. You
                acknowledge that we are not responsible for transferring, safeguarding, or maintaining your private keys
                or any assets associated with your Wallet. If you lose, mishandle or have stolen your Wallet private
                keys, you acknowledge that you may not be able to recover associated assets and that we are
                notresponsible for such loss.
              </li>
              <li>
                <span> </span>
                You acknowledge that you may disconnect your Wallet from the Platform at any time.
              </li>
              <li>
                <span> </span>You agree to notify us immediately if you suspect your linked Wallet has been compromised
                or otherwise suspect any security issues related to your use of the Services.
              </li>
              <li>
                <span> </span>You agree that you will not use the Services to transact with any digital currency that
                may be considered a security under Applicable Laws.
              </li>
              <li>
                <span> </span>
                You acknowledge and agree that we may restrict, suspend or close your Wallet and access to the Platform
                for any reason or no reason, including if we reasonably believe that you have breached any of the terms
                of this Agreement.
              </li>
              <li>
                <span> </span>
                Digital Assets that you purchase or use in relation to the Services may be held in one or more Wallets
                of yours. We do not operate, maintain, control or have custody over any contents of your Wallet. We
                accept no responsibility for, or liability to, you in connection with your Wallet and make no
                representations or warranties regarding how the Platform or the Services will operate with any specific
                Wallet. Any issues relating to your Wallet should be addressed to your Wallet provider.
              </li>
              <li>
                <span> </span>You acknowledge that we are not responsible for, and you agree to indemnify us for, any
                loss or damage arising from your failure to comply with the requirements hereunder.
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <ul>
      <strong>No Professional Advice or Fiduciary Duties</strong>
      <br /> <br />
    </ul>
    <ul>
      <p>
        All information provided in connection with your access and use of the Site and the Services is for
        informational purposes only and should not be construed as professional advice. You should not take, or refrain
        from taking, any action based on any information contained on the Site or any other information that we make
        available at any time, including, without limitation, blog posts, articles, links to third-party content,
        Discord content, Telegram content, news feeds, tutorials, tweets, and videos. Before you make any financial,
        legal, or other decisions involving the Services, you should seek independent professional advice from an
        individual who is licensed and qualified in the area for which such advice would be appropriate. The Terms are
        not intended to, and do not, create or impose any fiduciary duties on us. You further agree that the only duties
        and obligations that we owe you are those set out expressly in these Terms.
      </p>
    </ul>

    <ul>
      <li>
        <span> </span>
        <strong>Prohibited Activities</strong>
        <br />
      </li>
    </ul>
    <ul>
      <li>
        By using the Site or the Services, you confirm that you will not use the Site or the Services to do any of the
        following (collectively, “<strong>Prohibited Uses</strong>”):
      </li>
      <ul>
        <li>
          <span> </span>
          violate any Applicable Laws including, without limitation, any applicable anti-money laundering and
          anti-terrorist financing laws and sanctions programs;
        </li>
        <li>
          <span> </span>engage in transactions involving items that infringe or violate any copyright, trademark, right
          of publicity or privacy or any other proprietary right under Applicable Laws, including but not limited to (i)
          sales, distribution, or access to counterfeit music, movies, software, or other licensed materials without the
          appropriate authorization from the rights holder (ii) use of Kodiak’s intellectual property, name, or logo,
          including use of Kodiak’s trade marks or service marks, without express consent from Kodiak or in a manner
          that otherwise harms Kodiak and (iii) any action that implies an untrue endorsement by or affiliation with
          Kodiak;
        </li>
        <li>
          <span> </span>
          use the Site and/or the Services in any manner that could interfere with, disrupt, negatively affect, or
          inhibit other users from fully enjoying the Site and/or the Services, or that could damage, disable,
          overburden, or impair the functioning of the Site or the Services in any manner;
        </li>
        <li>
          <span> </span>
          circumvent any content-filtering techniques, security measures or access controls that Kodiak employs on the
          Site, including without limitation through the use of a VPN;
        </li>
        <li>
          <span> </span>use any robot, spider, crawler, scraper, or other automated means or interface not provided by
          us to access the Site or the Services or to extract data, or introduce any malware, virus, Trojan horse, worm,
          logic bomb, drop-dead device, backdoor, shutdown mechanism or other harmful material into the Site or the
          Services;
        </li>
        <li>
          <span> </span>
          provide false, inaccurate, or misleading information while using the Site or the Services or engage in
          activity that operates to defraud Kodiak, other users of the Services, or any other person;
        </li>
        <li>
          <span> </span>
          engage in improper or abusive trading practices, including (i) any fraudulent act or scheme to defraud,
          deceive, trick or mislead; (ii) trading ahead of another user of the Services or front-running; (iii)
          fraudulent trading; (iv) accommodation trading; (v) fictitious transactions; (vi) pre-arranged or
          non-competitive transactions; (vii) violations of bids or offers; (viii) cornering, or attempted cornering, of
          any Digital Assets; (ix) wash trading (i.e. entering buy and sell orders at the same or similar prices,
          volumes, and times for the purpose of generating trading volume); (x) spoofing (i.e. entering buy or sell
          orders without a bona fide intent to execute such orders and with the intent to cancel such orders before
          execution); (xi) manipulation (i.e. trading for the purpose of affecting the prices of Digital Assets and
          generating artificial prices); (xii) knowingly making any bid or offer for the purpose of making a market
          price that does not reflect the true state of the market; (xiii) entering orders for the purpose of entering
          into transactions without a net change in either party’s open positions but a resulting profit to one party
          and a loss to the other party, commonly known as a “money pass”; or (xiv) any other trading activity that, we
          have, in our sole discretion, determined to be abusive, improper or disruptive to the operation of the
          Services.
        </li>
        <li>
          <span> </span>use or access the Site or Services to transmit, stake, wrap, exchange or otherwise interact with
          Digital Assets that are the direct or indirect proceeds of any criminal or fraudulent activity, including
          without limitation terrorism or tax evasion;
        </li>
        <li>
          <span> </span>
          use the Site or the Services in any way that is, in our sole discretion, libelous, defamatory, profane,
          obscene, pornographic, sexually explicit, indecent, lewd, vulgar, suggestive, harassing, stalking, hateful,
          threatening, offensive, discriminatory, bigoted, abusive, inflammatory, fraudulent, deceptive, or otherwise
          objectionable or likely or intended to incite, threaten, facilitate, promote, or encourage hate, racial
          intolerance, or violent acts against others;
        </li>
        <li>
          <span> </span>
          use the Site or the Services from a jurisdiction that we have, in our sole discretion, determined is a
          jurisdiction where the use of the Site or the Services is prohibited;
        </li>
        <li>
          <span> </span>
          harass, abuse, or harm of another person or entity, including Kodiak’s employees and service providers;
        </li>
        <li>
          <span> </span>
          impersonate another user of the Site or the Services or otherwise misrepresent yourself;
        </li>
        <li>
          <span> </span>use the Site or the Platform for any purposes other than using the Services; or
        </li>
        <li>
          <span> </span>
          encourage, induce or assist any third party to engage in any of the activities prohibited under this Section 3
          or any other provision of these Terms.
        </li>
        <p>
          The foregoing activities are representative, but not exhaustive, of Prohibited Uses. If you are uncertain as
          to whether or not your use of the Site or the Services involves a Prohibited Use or have other questions about
          how these requirements apply to you, then please contact us at{' '}
          <Link to="mailto:admin@kodiak.finance" target="_blank">
            <strong>admin@kodiak.finance</strong>
          </Link>
        </p>
      </ul>
    </ul>
    <ul>
      <li>
        <span> </span>
        <strong>Your Content</strong>
        <br />
      </li>
    </ul>
    <ul>
      <p>
        You hereby grant to us a royalty-free, fully paid-up, sublicensable, transferable, perpetual, irrevocable,
        non-exclusive, worldwide license to use, copy, modify, create derivative works of, display, perform, publish and
        distribute, in any form, medium, or manner, any content that is available to other users as a result of your use
        of the Site or the Services (collectively, “<strong>Your Content</strong>”), including, without limitation, for
        promoting Kodiak, its affiliates, the Services or the Site. You represent and warrant that (a) you own Your
        Content or have the right to grant the rights and licenses in these Terms and (b) Your Content and our use of
        Your Content, as licensed herein, does not and will not violate, misappropriate or infringe on any third party’s
        rights.
      </p>
    </ul>
    <ul>
      <li>
        <span> </span>
        <strong>Proprietary Rights</strong>
        <br />
        <br />
        <ul>
          <li>
            <span> </span>
            {/* <Link> */}
            You acknowledge that the Site or the Services may use, incorporate or link to certain open-source components
            and that your use of the Site or Services is subject to, and you will comply with, any applicable
            open-source licenses that govern any such open-source components (collectively, “
            <strong>Open-Source Licenses</strong>”). Without limiting the generality of the foregoing, you may not (i)
            resell, lease, lend, share, distribute, or otherwise permit any third party to use the Site or the Services;
            (ii) use the Site or the Services for time-sharing or service bureau purposes; or (iii) otherwise use the
            Site or the Services in a manner that violates any Open-Source Licenses.
            {/* </Link> */}
          </li>
          <li>
            <span> </span>
            Excluding the open-source software described in Section 5(a), Your Content or third-party software that the
            Site or the Services incorporates you acknowledge and agree that Kodiak owns the Site and the Services,
            including all technology, content, software, images, text, graphics, illustrations, logos, patents,
            trademarks, service marks, copyrights, photographs, audio, videos and music and all intellectual property
            rights related thereto and other materials used, displayed, or provided on the Site or in connection with
            the Services but excluding Your Content (the “<strong>Company Content</strong>”) including all intellectual
            property rights subsisting therein. Kodiak hereby grants you a limited, revocable, transferable, license to
            access and use those portions of the Site and the Services that are proprietary to Kodiak solely in
            accordance with these Terms. Except as explicitly provided herein, nothing in these Terms shall be deemed to
            create a license in or under any Company Content or intellectual property rights, and you agree not to sell,
            license, rent, modify, distribute, copy, reproduce, transmit, publicly display, publicly perform, publish,
            adapt, edit or create derivative works from any Company Content. Use of the Company Content for any purpose
            not expressly permitted by these Terms is strictly prohibited. Company Content is made available solely for
            your personal, non-commercial use and may not be copied, reproduced, published, republished, modified,
            mirrored, uploaded, posted, transmitted, displayed, encoded, translated or distributed in any form or in
            way, including by e-mail or other electronic means, or stored in any retrieval system of any nature in any
            way, without the express prior written consent of us or such third party that may own such Company Content
            in each instance. You agree to abide by all copyright and other proprietary notices, information and
            restrictions contained in the Company Content and any other material accessed through the Site.
          </li>
          <li>
            <span> </span>
            Any of Kodiak’s product or service names, logos, and other marks used on the Site or as a part of the
            Services, including Kodiak's name and logo are trademarks owned by Kodiak, its affiliates, or its applicable
            licensors (collectively, the “<strong>Kodiak Trademarks</strong>”). You may not copy, imitate, or use them
            without the prior written consent of Kodiak or the applicable licensors, and notwithstanding to the contrary
            these Terms do not grant you any rights in the Kodiak Trademarks. You may not remove, obscure, or alter any
            legal notices displayed in or along with the Services.
          </li>
          <li>
            <span> </span>
            You may choose to, or we may invite you to submit comments, feedback, or ideas about the Site and the
            Services, including without limitation about how to improve the Site or our Services (“
            <strong>Feedback</strong>”). By submitting any Feedback, you agree that (i) your disclosure is
            non-confidential, gratuitous, unsolicited and without restriction and will not place us under any fiduciary
            or other obligation, (ii) you grant to us a perpetual, worldwide, royalty-free, irrevocable, transferable,
            sublicensable, non-exclusive and fully paid-up right to copy, use, reproduce, modify, adapt, publish, create
            derivative works from, translate, transmit, display, distribute, market, promote, sell or offer for sale,
            rent or lease such information or materials or any portions thereof (including any ideas for new products or
            Services or modifications to existing products or Services) and/or products or Services which practice or
            embody, or are configured for use in practicing, such information or materials or any portion thereof, in
            any form or medium known or later developed, in furtherance of these Terms and the actions and transactions
            contemplated hereby, including the right to bring an action for infringement of these rights, (iii) we are
            free to use the Feedback without any additional compensation to you, and/or to disclose the Feedback on a
            non-confidential basis or otherwise to anyone and (iv) you will have no claim against for any actual or
            alleged infringement of any proprietary rights, rights of privacy or publicity, moral rights or rights of
            attribution in connection with our use of any Feedback you provide. You further acknowledge that, by
            acceptance of your submission, we do not waive any rights to use similar or related comments, feedback and
            ideas previously known to us, or developed by our employees, or obtained from sources other than you.
          </li>
          <li>
            <span> </span>
            You acknowledge and understand that the Services are non-custodial. When you deposit Digital Assets into any
            Kodiak-developed smart contract, you retain control over those Digital Assets at all times. The private key
            associated with the Wallet from which you transfer Digital Assets is the only private key that can control
            the Digital Assets you transfer into Kodiak-developed smart contracts. In some cases, you may withdraw
            Digital Assets from any Kodiak-developed smart contract only to the digital address from which you deposited
            the Digital Assets.
          </li>
        </ul>
      </li>
    </ul>
    <ul>
      <span> </span>
      <strong>Third Party Links</strong>
    </ul>
    <ul>
      <p>
        The Site or the Services may provide, or third parties may provide, links to other external sites, applications
        or resources. You acknowledge and agree that we are not responsible for the availability of such external sites,
        applications or resources, does not endorse and is not responsible or liable for any content, advertising,
        products, or other materials on or available from such sites or resources. You further acknowledge and agree
        that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to
        be caused by or in connection with use of or reliance on any such content, goods, or services available on or
        through any such site or resource.
      </p>
    </ul>
    <ul>
      <li>
        <span> </span>
        <strong>Modification, Suspension and Termination</strong>
      </li>
    </ul>
    <ul>
      <p>
        We may, at our sole discretion, from time to time and with or without prior notice to you, modify, suspend or
        disable (temporarily or permanently) the Site or the Services, in whole or in part, for any reason whatsoever.
        Upon termination of your access, your right to use the Services will immediately cease. We will not be liable
        for, and you agree to indemnify us for, any losses suffered by you resulting from any modification to any
        Services or from any modification, suspension, or termination, for any reason, of your access to all or any
        portion of the Site or the Services.
      </p>
    </ul>
    <ul>
      <li>
        <span> </span>
        <strong>Risks</strong>
        <br />
        <br />
        <ul>
          <li>
            <span> </span>By using the Services or interacting with the Site in any way, you represent and warrant that
            you understand the inherent risks associated with cryptographic systems and blockchain-based networks;
            Digital Assets, including the usage and intricacies of native Digital Assets, smart contract-based tokens,
            and systems that interact with blockchain-based networks. Kodiak does not own or control any of the
            underlying software through which blockchain networks are formed. In general, the software underlying
            blockchain networks is open source, such that anyone can use, copy, modify, and distribute it. By using the
            Services, you acknowledge and agree that (i) Kodiak is not responsible for the operation of the software and
            networks underlying the Services, (ii) there exists no guarantee of the functionality, security, or
            availability of such software and such networks, and (iii) the underlying networks are subject to sudden
            changes in operating rules, such as those commonly referred to as “forks,” which may materially affect the
            Services.
          </li>
          <li>
            <span> </span>
            You acknowledge and agree that (i) blockchain networks use public/private key cryptography, (ii) you alone
            are responsible for securing your private keys, (iii) we do not have access to your private keys, (iv)
            losing control of your private keys will permanently and irreversibly deny you access to your Digital
            Assets, (v) neither Kodiak nor any other person or entity will be able to retrieve or protect your Digital
            Assets and (vi) if your private keys are lost, then you will not be able to transfer your Digital Assets to
            any other blockchain address or wallet and if this occurs, then you will not be able to realize any value or
            utility from the Digital Assets that you may hold.
          </li>
          <li>
            <span> </span>
            The Services and your Digital Assets could be impacted by one or more regulatory inquiries or regulatory
            actions, which could impede or limit the ability of Kodiak to continue to make available its proprietary
            software and, thus, could impede or limit your ability to access or use the Services.
          </li>
          <li>
            <span> </span>
            You acknowledge and understand that cryptography is a progressing field with advances in code cracking or
            other technical advancements, such as the development of quantum computers, which may present risks to
            Digital Assets and the Services, and could result in the theft or loss of your Digital Assets. To the extent
            possible, we intend to use commercially reasonable efforts to update or cause to be updated Kodiak-developed
            smart contracts related to the Services to account for any advances in cryptography and to incorporate
            additional security measures necessary to address risks presented from technological advancements, but you
            agree that such intention does not guarantee or otherwise ensure full security of the Services.
          </li>
          <li>
            <span> </span>
            You acknowledge that the Services are subject to flaws and that you are solely responsible for evaluating
            any code provided by the Services or Site. This warning and others we provide in these Terms in no way
            evidence or represent an ongoing duty to alert you to all of the potential risks of utilizing the Services
            or accessing the Site.
          </li>
          <li>
            <span> </span>
            Although we intend to provide accurate and timely information on the Site and during your use of the
            Services, the Site and other information available when using the Services may not always be entirely
            accurate, complete, or current and may also include technical inaccuracies or typographical errors. To
            continue to provide you with as complete and accurate information as possible, information may be changed or
            updated from time to time without notice, including, without limitation, information regarding our policies.
            Accordingly, you should verify all information before relying on it, and all decisions based on information
            contained on the Site or as part of the Services are your sole responsibility. No representation is made as
            to the accuracy, completeness, or appropriateness for any particular purpose of any information distributed
            via the Site or otherwise when using the Services. Prices and pricing information may be higher or lower
            than prices available on platforms providing similar services.
          </li>
          <li>
            <span> </span>
            Any use or interaction with the Services requires a comprehensive understanding of applied cryptography and
            computer science to appreciate the inherent risks, including those listed above. You represent and warrant
            that you possess relevant knowledge and skills to appreciate and understand such risks. Any reference to a
            type of Digital Asset on the Site or otherwise during the use of the Services does not indicate our approval
            or disapproval of the technology on which the Digital Asset relies and should not be used as a substitute
            for your understanding of the risks specific to each type of Digital Asset.
          </li>
          <li>
            <span> </span>
            Use of the Services may carry financial risk. Digital Assets are, by their nature, highly experimental,
            risky, and volatile. Transactions entered in connection with the Services are irreversible, final and there
            are no refunds. You acknowledge and agree that you will access and use the Site and the Services at your own
            risk. The risk of loss in trading, staking, locking or otherwise interacting with Digital Assets can be
            substantial. You should, therefore, carefully consider whether such activities are suitable for you in light
            of your circumstances and financial resources. By using the Services, you represent and warrant that you
            have been, are, and will be solely responsible for making your independent appraisal and investigations into
            the risks of a given transaction and the underlying Digital Assets. You represent that you have sufficient
            knowledge, market sophistication, professional advice, and experience to make your evaluation of the merits
            and risks of any transaction conducted in connection with the Services or any Digital Asset. You accept all
            consequences of using the Services, including the risk that you may lose access to your Digital Assets
            indefinitely. All transaction decisions are made solely by you. Notwithstanding anything in these Terms, we
            accept no responsibility whatsoever for, and will in no circumstances be liable to you in connection with,
            your use of the Services.
          </li>
          <li>
            <span> </span>
            You and we agree to comply with all Applicable Laws and acknowledge that such compliance may require us to,
            upon request by government agencies, take certain actions or provide information, including without
            limitation information about you, which may not be in your best interests.
          </li>
          <li>
            <span> </span>
            You are responsible for all trades and transfer you place, including any erroneous orders that may be
            filled. We do not take any action to resolve erroneous trades or transfers that result from your errors.
          </li>
          <li>
            <span> </span>
            Any Services you interact with are entirely your own responsibility and liability, and we are not a party to
            the Protocol.
          </li>
          <li>
            <span> </span>
            At any time, your access to your Digital Assets may be suspended or terminated or there may be a delay in
            your access or use of your Digital Assets which may result in the Digital Assets diminishing in value.
          </li>
          <li>
            <span> </span>The Services may be suspended or terminated for any reason or no reason, which may limit your
            access to your Digital Assets.
          </li>
          <li>
            <span> </span>
            You hereby assume, and agree that Kodiak will have no responsibility or liability for, the risks set forth
            in this Section 8. You hereby irrevocably waive, release and discharge all claims, whether known or unknown
            to you, against Kodiak, its affiliates, and each of their respective shareholders, members, directors,
            officers, managers, employees, lawyers, accountants, advisors, agents, representatives, suppliers and
            contractors related to any of the risks set forth in this Section 8.
          </li>
        </ul>
      </li>
    </ul>
    <ul>
      <li>
        <span> </span>
        <strong>Indemnification</strong>
        <br />
      </li>
    </ul>
    <ul>
      <p>
        You agree you will defend, indemnify, and hold harmless Kodiak, its affiliates, and each of their respective
        shareholders, members, directors, officers, managers, employees, lawyers, agents, accountants, advisors,
        representatives, suppliers, and contractors (collectively, “<strong>Indemnified Parties</strong>”) from any
        claim, demand, lawsuit, action, proceeding, investigation, liability, damage, loss, cost or expense, including
        without limitation legal fees and expenses, arising out of or relating to: (a) your use of, access to or conduct
        in connection with, the Site, the Platform, Company Content, the Services or Digital Assets; (b) Digital Assets
        associated with your Wallets; (c) any Feedback, Your Content or user content you provide to Kodiak including
        without limitation misleading, false, or inaccurate information; (d) your violation of these Terms; (e) your
        infringement or misappropriation of the rights of any other person or entity; (f) your wilful misconduct; (g)
        your violation of any Applicable Laws; or (h) any other party’s access and use of the Site, the Platform,
        Company Content or the Services with your Wallet, unique username, password or other appropriate security code.
        If you are obligated to indemnify any Indemnified Party, we (or, at its discretion, the applicable Indemnified
        Party) will have the right, in our sole discretion, to control any action or proceeding and to determine whether
        we wish to settle, and if so, on what terms, and you agree to corporate with us in connection with the
        foregoing.
      </p>
    </ul>
    <ul>
      <strong>Disclaimers </strong>
      <br />
      <br />
      <ul>
        <li>
          <span> </span>We are a developer of open-source software. Kodiak does not operate a Digital Asset exchange
          platform or offer trade execution or clearing services and, therefore, has no oversight, involvement, or
          control concerning your transactions using the Services. All transactions between users of Kodiak-developed
          open-source software are executed peer-to-peer directly between the users’ digital addresses through a smart
          contract. You are responsible for complying with all Applicable Laws.
        </li>
        <li>
          <span> </span>
          You understand that Kodiak is not registered or licensed by the U.S. Commodity Futures Trading Commission, the
          U.S. Securities and Exchange Commission or any other financial regulatory authority. No financial regulatory
          authority has reviewed or approved the use of the Kodiak-developed open-source software. The Site and the
          Kodiak-developed open-source software do not constitute advice or a recommendation concerning any commodity,
          security, or other Digital Asset or instrument. Kodiak is not acting as an investment adviser or commodity
          trading adviser to any person or entity.
        </li>
        <li>
          <span> </span>
          Kodiak does not own or control the underlying software protocols that are used in connection with the
          Services. In general, the underlying protocols are open source and anyone can use, copy, modify, and
          distribute them. Kodiak is not responsible for the operation of the underlying protocols, and Kodiak makes no
          guarantee of their functionality, security, or availability.
        </li>
        <li>
          <span> </span>TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAWS, YOU UNDERSTAND AND AGREE THAT THE SITE,
          THE PLATFORM AND THE SERVICES (AND ANY OF THEIR CONTENT OR FUNCTIONALITY) PROVIDED BY OR ON BEHALF OF US ARE
          PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, AND WE EXPRESSLY DISCLAIM, AND YOU HEREBY WAIVE, ANY
          REPRESENTATIONS, CONDITIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, LEGAL, STATUTORY OR
          OTHERWISE, OR ARISING FROM STATUTE, OTHERWISE IN LAW, COURSE OF DEALING, OR USAGE OF TRADE, INCLUDING, WITHOUT
          LIMITATION, IMPLIED OR LEGAL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, MERCHANTABLE QUALITY, QUALITY OR
          FITNESS FOR A PARTICULAR PURPOSE, TITLE, SECURITY, AVAILABILITY, RELIABILITY, ACCURACY, QUIET ENJOYMENT AND
          NON-INFRINGEMENT OF THIRD PARTY RIGHTS. WITHOUT LIMITING THE FOREGOING, WE DO NOT REPRESENT OR WARRANT THAT
          THE SITE, THE PLATFORM OR THE SERVICES (INCLUDING ANY DATA RELATING THERETO) WILL BE UNINTERRUPTED, AVAILABLE
          AT ANY PARTICULAR TIME, OR ERROR-FREE. FURTHER, WE DO NOT WARRANT THAT ERRORS IN THE SITE OR THE SERVICE ARE
          CORRECTABLE OR WILL BE CORRECTABLE.
        </li>
        <li>
          <span> </span>
          You acknowledge that your data on the Site or the Services may become irretrievably lost or corrupted or
          temporarily unavailable due to a variety of causes, and agree that, to the maximum extent permitted under
          Applicable Laws, we will not be liable for any loss or damage caused by denial-of-service attacks, software
          failures, viruses or other technologically harmful materials (including those which may infect your computer
          equipment), protocol changes by third-party providers, Internet outages, force majeure events or other
          disasters, scheduled or unscheduled maintenance, or other causes either within or outside our control.
        </li>
        <li>
          <span> </span>
          The disclaimer of implied warranties contained herein may not apply if and to the extent such warranties
          cannot be excluded or limited under the Applicable Law of the jurisdiction in which you reside.
        </li>
      </ul>
    </ul>
    <ul>
      <li>
        <span> </span>
        <strong>Exclusion of Consequential Damages</strong>
        <br />
      </li>

      <ul>
        <li>
          You acknowledge and agree that in no event shall the Indemnified Parties be liable for any incidental,
          indirect, special, punitive, consequential or similar damages or liabilities whatsoever (including, without
          limitation, damages for loss of fiat, assets, data, information, revenue, opportunities, use, goodwill,
          profits or other business or financial benefit) arising out of or in connection with the Site, the Platform,
          Company Content or the Services and any of their content and functionality, any execution or settlement of a
          transaction, any performance or non-performance of the Site, the Services, the Platform, your Digital Assets
          or any other product, service or other item provided by or on behalf of Kodiak, whether under contract, tort
          (including negligence), civil liability, statute, strict liability, breach of warranties, or under any other
          theory of liability, and whether or not the Indemnified Parties have been advised of, knew of or should have
          known of the possibility of such damages and notwithstanding any failure of the essential purpose of these
          Terms or any limited remedy hereunder. In addition, you acknowledge and agree that Kodiak shall not be in any
          way responsible for the execution or settlement of transactions between users of Kodiak-developed open-source
          software.
        </li>
      </ul>
    </ul>

    <ul>
      <li>
        <span> </span>
        <strong>Limitation of Liability</strong>
        <br />
      </li>
    </ul>

    <ul>
      <li>
        <ul>
          <li>
            <span> </span>
            Under no circumstances will any Indemnified Party be responsible for any damage, loss or injury resulting
            from hacking, tampering or other unauthorized access or use of the Site, the Platform, the Services or the
            Company Content and other information contained therein. To the maximum extent permitted by Applicable Laws,
            we assume no liability or responsibility for any (i) errors, mistakes, or inaccuracies of content; (ii)
            personal injury or property damage, of any nature whatsoever, resulting from your access to or use of our
            Site, the Platform, Company Content or the Services; (iii) any unauthorized access to or use of our secure
            servers and/or any and all personal information stored therein; (iv) any interruption or cessation of
            transmission to or from the Site, the Platform or the Services; (v) any bugs, viruses, trojan horses, or the
            like that may be transmitted to or through our Site, the Platform, Company Content or the Services by any
            third party; (vi) any errors or omissions in any content or for any loss or damage incurred as a result of
            the use of any content posted, emailed, transmitted, or otherwise made available through the Site, the
            Platform or the Services; and/or (vii) Your Content or the defamatory, offensive, or illegal conduct of any
            third party You agree that if, notwithstanding the other provisions of these Terms, an Indemnified Party is
            found to be liable for any claim, demand, lawsuit, action, proceeding, investigation, liability, damage,
            loss, cost or expense, such Indemnified Party’s liability shall in no event exceed the amount of the fees
            paid by you to Kodiak under these Terms, if any, in the one-month period immediately preceding the event
            giving rise to the claim for liability, if any.
          </li>
          <li>
            <span> </span>
            This limitation of liability section applies whether the alleged liability is based on contract, tort,
            negligence, strict liability, or any other basis, even if we have been advised of the possibility of such
            damage. The foregoing limitation of liability shall apply to the fullest extent permitted by Applicable
            Laws.
          </li>
        </ul>
      </li>
    </ul>
    <ul>
      <li>
        <strong>Force Majeure</strong>
      </li>
      <ul>
        <p>
          We will have no responsibility or liability for any failure or delay in performance of the Site, the Platform
          or any of the Services, or any loss or damage that you may incur, due to any circumstance or event beyond our
          control, including any (a) flood, extraordinary weather conditions, earthquake, or other act of God, (b) fire,
          (c) war, (d) insurrection, (e) riot, (f) labour dispute, (g) accident, (h) epidemic or pandemic, (i) action of
          government, (j) new laws or regulations or change in existing laws or regulations or the interpretation or
          enforcement of any of the foregoing, (k) communications, (l) power failure, (m) equipment or software
          unavailability, disruption or malfunction, (n) hacking or other attack on the Site, the Platform or the
          Services, (o) the unavailability, disruption or malfunction of any network or blockchains or (p) the
          unavailability, disruption or malfunction of the Internet.
        </p>
      </ul>
    </ul>

    <ul>
      <li>
        <strong>Survival</strong>
      </li>
      <ul>
        <p>
          The following sections of these Terms will survive any termination of your access to the Site or the Services,
          regardless of the reasons for its expiration or termination, in addition to any other provision which by law
          or by its nature should survive: Sections 4 through 18.
        </p>
      </ul>
    </ul>

    <ul>
      <li>
        <strong>Governing Law </strong>
      </li>
      <ul>
        <p>
          The interpretation and enforcement of these Terms, and any dispute related to these Terms, the Site or the
          Services, will be governed by and construed and enforced under the laws of Panama without regard to conflict
          of law rules or principles (whether of Panama or any other jurisdiction) that would cause the application of
          the laws of any other jurisdiction. You agree that we may initiate a proceeding related to the enforcement or
          validity of our intellectual property rights in any court having jurisdiction. For any other proceeding that
          is not subject to arbitration under these Terms, the courts located in Panama will have exclusive
          jurisdiction. You waive any objection to venue in any such courts.
        </p>
      </ul>
    </ul>

    <ul>
      <li>
        <span> </span>
        <strong>Dispute Resolution and Arbitration</strong>
      </li>
      <ul>
        <p>
          PLEASE READ THE FOLLOWING SECTION CAREFULLY BECAUSE IT REQUIRES YOU TO ARBITRATE CERTAIN DISPUTES AND CLAIMS
          WITH KODIAK AND LIMITS HOW YOU CAN SEEK RELIEF FROM KODIAK. ALSO, ARBITRATION PRECLUDES YOU FROM SUING IN
          COURT OR HAVING A JURY TRIAL.
        </p>
      </ul>
      <ul>
        <li>
          <span> </span>You and we agree that any dispute arising out of or related to these Terms, the Site or the
          Services is personal to you and us and that any dispute will be resolved solely through individual action, and
          will not be brought as a class arbitration, class action, or any other type of representative proceeding.
        </li>
        <li>
          <span> </span>Except for disputes in which you or we seek injunctive or other equitable relief for the alleged
          unlawful use of intellectual property, you and we waive all rights to a jury trial and to have any dispute
          arising out of or related to these Terms or the Services resolved in court. Instead, for any dispute or claim
          that you have against us or relating in any way to the Site or the Services, you agree to first contact us and
          attempt to resolve the claim informally by sending a written notice of your claim (“<strong>Notice</strong>”)
          to us by email at
          <Link to="mailto:admin@kodiak.finance">
            <strong>
              <span> admin@kodiak.finance</span>
            </strong>
          </Link>
          . <br />
          <br />
          The Notice must: (i) include your name, residence address, email address, and telephone number; (ii) describe
          the nature and basis of the claim; and (iii) set forth the specific relief sought. Our notice to you will be
          similar in form to that described above. If you and Kodiak cannot reach an agreement to resolve the claim
          within thirty (30) days after such Notice is received, then either party may submit the dispute to binding
          arbitration administered by the Centro de Conciliación y Arbitraje de PanamáCentre (“<strong>CeCAP</strong>”)
          or, under the limited circumstances set forth above, in court. All disputes submitted to the CeCAP will be
          resolved through confidential, binding arbitration before one arbitrator (the “<strong>Arbitrator</strong>”).
          The place of arbitration shall be Panama unless the parties agree otherwise and shall be conducted under
          CeCAP’s Arbitration Regulation (the “<strong>CeCAP Rules</strong>”). The language to be used in the arbitral
          proceedings shall be English. The most recent version of the CeCAP Rules are available on the CeCAP website
          and are hereby incorporated by reference. You either acknowledge and agree that you have read and understand
          the CeCAP Rules or waive your opportunity to read the CeCAP Rules and waive any claim that the CeCAP Rules are
          unfair or should not apply for any reason.
        </li>
        <li>
          <span> </span>
          The Arbitrator will have exclusive authority to make all procedural and substantive decisions regarding any
          dispute and to grant any remedy that would otherwise be available in court, including the power to determine
          the question of arbitrability. The Arbitrator may conduct only an individual arbitration and may not
          consolidate more than one individual’s claims, preside over any type of class or representative proceeding or
          preside over any proceeding involving more than one individual.
        </li>
        <li>
          <span> </span>The Arbitrator, Kodiak, and you will maintain the confidentiality of any arbitration
          proceedings, judgments and awards, including, but not limited to, all information gathered, prepared, and
          presented for purposes of the arbitration or related to the dispute(s) therein. The Arbitrator will have the
          authority to make appropriate rulings to safeguard confidentiality unless the law provides to the contrary.
          The duty of confidentiality does not apply to the extent that disclosure is necessary to prepare for or
          conduct the arbitration hearing on the merits, in connection with a court application for a preliminary remedy
          or in connection with a judicial challenge to an arbitration award or its enforcement, or to the extent that
          disclosure is otherwise required by law or judicial decision.
        </li>
        <li>
          <span> </span>You and Kodiak agree that for any arbitration you initiate, you will pay the filing fee and all
          other CeCAP fees and costs. For any arbitration initiated by Kodiak, Kodiak will pay all CeCAP fees and costs.
          You and Kodiak agree that the courts of Panama have exclusive jurisdiction over the enforcement of an
          arbitration award.
        </li>
        <li>
          <span> </span>
          Any claim arising out of or related to these Terms or the Services must be filed within one year after such
          claim arose; otherwise, the claim is permanently barred, which means that you and Kodiak will not have the
          right to assert the claim.
        </li>
        <li>
          <span> </span>
          If any portion of this Section 16 is found to be unenforceable or unlawful for any reason: (i) the
          unenforceable or unlawful provision shall be severed from these Terms; (ii) severance of the unenforceable or
          unlawful provision shall have no impact whatsoever on the remainder of this Section 16 or the parties’ ability
          to compel arbitration of any remaining claims on an individual basis under this Section 16; and (iii) to the
          extent that any claims must therefore proceed on a class, collective, consolidated, or representative basis,
          such claims must be litigated in a civil court of competent jurisdiction and not in arbitration, and the
          parties agree that litigation of those claims shall be stayed pending the outcome of any individual claims in
          arbitration. Further, if any part of this Section 16 is found to prohibit an individual claim seeking
          injunctive relief, then that provision will have no effect to the extent such relief is allowed to be sought
          out of arbitration, and the remainder of this Section 16 will be enforceable.
        </li>
      </ul>
    </ul>

    <ul>
      <li>
        <strong>Amendments</strong>
      </li>
      <ul>
        <p>
          We reserve the right, at our sole discretion, to amend these Terms from time to time. If we make changes, we
          will provide you with notice of such changes, which may include providing notice through the Services or
          updating the date at the top of these Terms. Unless we state otherwise in our notice, all such modifications
          are effective immediately, and your continued use of the Site and the Services after we provide that notice
          will confirm your acceptance of the changes. If you do not agree to the amended Terms, then you must stop
          using the Site and the Services.
        </p>
      </ul>
    </ul>

    <ul>
      <span> </span>
      <strong>General</strong>
      <br />
      <br />
      <ul>
        <li>
          <span> </span>
          You acknowledge and agree that our privacy policy, which is available at{' '}
          {/* <Link to="https://docs.kodiak.finance/resources/privacy" target="_blank"> */}
          {/* <strong>https://docs.kodiak.finance/resources/privacy</strong> */}
          {/* </Link> */}
          <Link to="/privacy" target="_blank">
            <strong>Privacy Policy</strong>
          </Link>
          , is incorporated herein by reference and forms part of these Terms.
        </li>
        <li>
          <span> </span>
          You consent to receive all communications, agreements, documents, receipts, notices, and disclosures
          electronically (collectively, our “<strong>Communications</strong>”) that we provide in connection with these
          Terms, the Site or any Services. You agree that we may provide our Communications to you by posting them on
          the Site or by emailing them to you at the email address you provide in connection with using the Services, if
          any.
        </li>
        <li>
          <span> </span>
          Any right or remedy of any Indemnified Party set forth in these Terms is in addition to, and not in lieu of,
          any other right or remedy whether described in these Terms or under Applicable Laws, whether at law or in
          equity. The failure or delay of such Indemnified Party in exercising any right, power, or privilege under
          these Terms shall not operate as a waiver thereof.
        </li>
        <li>
          <span> </span>
          The invalidity or unenforceability of any provision of these Terms shall not affect the validity or
          enforceability of any other provision of these Terms, all of which shall remain in full force and effect.
        </li>
        <li>
          <span> </span>
          You acknowledge and agree that we will have no responsibility or liability for any failure or delay in
          performance of the Site or any of the Services, or any loss or damage that you may incur, due to any
          circumstance or event beyond our control, including without limitation any flood, extraordinary weather
          conditions, earthquake, or other act of God, fire, war, insurrection, riot, labor dispute, accident, action of
          government, communications, power failure, or equipment or software malfunction.
        </li>
        <li>
          <span> </span>
          You agree that you may not assign or transfer any right to use the Site or the Services, or any of your rights
          or obligations under these Terms, without our express prior written consent, including by operation of law or
          in connection with any change of control, which may be withheld at our sole discretion. We may assign or
          transfer any or all of our rights or obligations under these Terms, in whole or in part, without notice or
          obtaining your consent or approval.
        </li>
        <li>
          <span> </span>
          Headings of sections are for convenience only and shall not be used to limit or construe such sections.
        </li>
        <li>
          <span> </span>
          These Terms contain the entire agreement between you and Kodiak and supersede all prior and contemporaneous
          understandings between the parties regarding the Site and the Services.
        </li>
        <li>
          <span> </span>
          In the event of any conflict between these Terms and any other agreement you may have with us, these Terms
          will control unless the other agreement specifically identifies these Terms and declares that the other
          agreement supersedes these Terms.
        </li>
        <li>
          <span> </span>
          You agree that, except as otherwise expressly provided in this Agreement, there shall be no third-party
          beneficiaries to the Agreement other than the Indemnified Parties.
        </li>
      </ul>
    </ul>
  </ScrollableSection>
)

export default Terms
