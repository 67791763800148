import { BaseProvider, JsonRpcProvider } from '@ethersproject/providers'
import { Protocol } from '@kodiak-finance/router-sdk'
import {
  AlphaRouterParams,
  CachingV3PoolProvider,
  ChainId,
  ID_TO_CHAIN_ID,
  NodeJSCache,
  StaticV2SubgraphProvider,
  StaticV3SubgraphProvider,
  UniswapMulticallProvider,
  V2SubgraphProviderWithFallBacks,
  V3PoolProvider,
  V3SubgraphProviderWithFallBacks,
} from '@kodiak-finance/smart-order-router'
import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { RPC_URLS } from 'constants/networks'
import { getClientSideQuote, toSupportedChainId } from 'lib/hooks/routing/clientSideSmartOrderRouter'
import ms from 'ms.macro'
import NodeCache from 'node-cache'
import qs from 'qs'

import { GetQuoteResult } from './types'

const routerProviders = new Map<ChainId, BaseProvider>()
function getRouterProvider(chainId: ChainId): BaseProvider {
  const provider = routerProviders.get(chainId)
  if (provider) return provider

  const supportedChainId = toSupportedChainId(chainId)
  if (supportedChainId) {
    const provider = new JsonRpcProvider(RPC_URLS[supportedChainId])
    routerProviders.set(chainId, provider)
    return provider
  }

  throw new Error(`Router does not support this chain (chainId: ${chainId}).`)
}

const protocols: Protocol[] = [Protocol.V2, Protocol.V3, Protocol.MIXED]

const DEFAULT_QUERY_PARAMS = {
  protocols: protocols.map((p) => p.toLowerCase()).join(','),
  // example other params
  // forceCrossProtocol: 'true',
  // minSplits: '5',
}

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// BERA MIGRATION: Add Berachain cases for the subgraph fetchers.
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export const getV3Subgraph = (chainId: ChainId, provider: BaseProvider) => {
  switch (chainId) {
    case ChainId.POLYGON_MUMBAI || ChainId.BERACHAIN_PRIVATE_TESTNET:
      return new V3SubgraphProviderWithFallBacks([
        new StaticV3SubgraphProvider(
          chainId,
          new CachingV3PoolProvider(
            chainId,
            new V3PoolProvider(ID_TO_CHAIN_ID(chainId), new UniswapMulticallProvider(chainId, provider, 375_000)),
            new NodeJSCache(new NodeCache({ stdTTL: 360, useClones: false }))
          )
        ),
      ])
    default:
      return undefined
  }
}

export const getV2Subgraph = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.POLYGON_MUMBAI || ChainId.BERACHAIN_PRIVATE_TESTNET:
      return new V2SubgraphProviderWithFallBacks([new StaticV2SubgraphProvider(chainId)])
    default:
      return undefined
  }
}

export const routingApi = createApi({
  reducerPath: 'routingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://p98xpjn4m4.execute-api.us-east-1.amazonaws.com/prod/',
  }),
  endpoints: (build) => ({
    getQuote: build.query<
      GetQuoteResult,
      {
        tokenInAddress: string
        tokenInChainId: ChainId
        tokenInDecimals: number
        tokenInSymbol?: string
        tokenOutAddress: string
        tokenOutChainId: ChainId
        tokenOutDecimals: number
        tokenOutSymbol?: string
        amount: string
        useClientSideRouter: boolean // included in key to invalidate on change
        type: 'exactIn' | 'exactOut'
      }
    >({
      async queryFn(args, _api, _extraOptions, fetch) {
        const { tokenInAddress, tokenInChainId, tokenOutAddress, tokenOutChainId, amount, useClientSideRouter, type } =
          args

        let result

        try {
          if (useClientSideRouter) {
            const chainId = args.tokenInChainId
            const provider = getRouterProvider(chainId)
            const params: AlphaRouterParams = {
              chainId,
              provider,
              v3SubgraphProvider: getV3Subgraph(chainId, provider), //getV3Subgraph(chainId, provider),
              v2SubgraphProvider: getV2Subgraph(chainId), //getV2Subgraph(chainId),
            }
            result = await getClientSideQuote(args, params, { protocols })
          } else {
            const query = qs.stringify({
              ...DEFAULT_QUERY_PARAMS,
              tokenInAddress,
              tokenInChainId,
              tokenOutAddress,
              tokenOutChainId,
              amount,
              type,
            })
            result = await fetch(`quote?${query}`)
          }

          return { data: result.data as GetQuoteResult }
        } catch (e) {
          // TODO: fall back to client-side quoter when auto router fails.
          // deprecate 'legacy' v2/v3 routers first.
          return {
            error: {
              status: e.status,
              data: e.data,
              error: e.error,
              stack: e.stack,
            } as FetchBaseQueryError,
          }
        }
      },
      keepUnusedDataFor: ms`10s`,
      extraOptions: {
        maxRetries: 0,
      },
    }),
  }),
})

export const { useGetQuoteQuery } = routingApi
