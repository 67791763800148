import { LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { LoadingRows } from 'components/Loader/styled'
import { RowBetween } from 'components/Row'
import { useDividends } from 'hooks/useStaking'
import { useMemo } from 'react'
import { Text } from 'rebass'
import { useTheme } from 'styled-components'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'

const UserAllocationInfo = () => {
  const theme = useTheme()
  const { totalAllocation, userAllocation, userUsageAllocation: manualAllocation } = useDividends()

  const percentAllocation = useMemo(() => {
    if (!userAllocation || !totalAllocation) return null
    if (totalAllocation.equalTo('0')) return 0
    return parseFloat(userAllocation.multiply(1e4).divide(totalAllocation).multiply(100).quotient.toString()) / 1e4
  }, [userAllocation, totalAllocation])

  const redeemAllocation = useMemo(() => {
    if (!manualAllocation || !userAllocation) return undefined
    return userAllocation.subtract(manualAllocation)
  }, [manualAllocation, userAllocation])
  return (
    <LightCard>
      <AutoColumn gap="20px">
        {!userAllocation || !manualAllocation || !userAllocation || !redeemAllocation ? (
          <>
            <LoadingRows>
              <div></div>
            </LoadingRows>
            <LoadingRows>
              <div></div>
            </LoadingRows>
            <LoadingRows>
              <div></div>
            </LoadingRows>
            <LoadingRows>
              <div></div>
            </LoadingRows>
          </>
        ) : (
          <>
            <RowBetween>
              <Text color={theme.textSecondary} fontSize={14}>
                Total allocation
              </Text>
              <Text color={theme.textPrimary} fontSize={14}>
                {formatCurrencyAmount(userAllocation, 4)} {userAllocation?.currency.symbol}
              </Text>
            </RowBetween>
            <RowBetween>
              <Text color={theme.textSecondary} fontSize={14}>
                Total share
              </Text>
              <Text color={theme.textPrimary} fontSize={14}>
                {percentAllocation || 0}%
              </Text>
            </RowBetween>
            <RowBetween>
              <Text color={theme.textSecondary} fontSize={14}>
                Manual allocation
              </Text>
              <Text color={theme.textPrimary} fontSize={14}>
                {formatCurrencyAmount(manualAllocation, 4)} {userAllocation?.currency.symbol}
              </Text>
            </RowBetween>
            <RowBetween>
              <Text color={theme.textSecondary} fontSize={14}>
                Redeem allocation
              </Text>
              <Text color={theme.textPrimary} fontSize={14}>
                {formatCurrencyAmount(redeemAllocation, 4)} {userAllocation?.currency.symbol}
              </Text>
            </RowBetween>
          </>
        )}
      </AutoColumn>
    </LightCard>
  )
}

export default UserAllocationInfo
