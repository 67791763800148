import numbro from 'numbro'

import formatSmallAmount from './formatSmallAmount'

export const formatDollarAmount = (num, digits = 2, round = true) => {
  if (typeof num === 'string') num = Number(num)
  if (num === 0) return '$0'
  if (!num) return '-'
  if (num < 0.001 && digits <= 3) {
    return '<0.001'
  }

  return numbro(num)
    .formatCurrency({
      average: round,
      mantissa: num > 1000 ? 2 : digits,
      totalLength: 3,
      thousandSeparated: true,
      abbreviations: {
        million: 'M',
        billion: 'B',
        trillion: 'T',
      },
    })
    .replace('m', 'M')
    .replace('b', 'B')
    .replace('t', 'T')
}

export const formatAmount = (num, digits = 2) => {
  if (typeof num === 'string') num = Number(num)
  if (num === 0) return '0'
  if (!num) return '-'
  if (typeof num === 'number' && num > 0 && num < 0.001) {
    return formatSmallAmount(num.toString(), digits) || '<0.001'
  }

  // console.log('numdebug', num, digits)

  // For numbers less than 1000, format without abbreviations
  if (num < 1000) {
    return numbro(num).format({
      thousandSeparated: true,
      mantissa: digits,
    })
  }

  return numbro(num)
    .format({
      average: true,
      mantissa: num >= 1000 ? 2 : digits,
      thousandSeparated: true,
      totalLength: 3,
      abbreviations: {
        thousand: 'K',
        million: 'M',
        billion: 'B',
        trillion: 'T',
      },
    })
    .replace('m', 'M')
    .replace('b', 'B')
    .replace('t', 'T')
}
