import { Currency, CurrencyAmount, Fraction, Price } from '@kodiak-finance/sdk-core'
import { DEFAULT_LOCALE, SupportedLocale } from 'constants/locales'
import JSBI from 'jsbi'
import formatLocaleNumber from 'lib/utils/formatLocaleNumber'

import { formatAmount } from './formatDollarAmt'
import formatSmallAmount from './formatSmallAmount'

export function formatCurrencyAmount(
  amount: CurrencyAmount<Currency> | undefined,
  sigFigs: number,
  locale: SupportedLocale = DEFAULT_LOCALE,
  fixedDecimals?: number
): string {
  if (!amount) {
    return '-'
  }

  if (JSBI.equal(amount.quotient, JSBI.BigInt(0))) {
    return '0'
  }

  if (amount.divide(amount.decimalScale).lessThan(new Fraction(1, 100000))) {
    return `<${formatLocaleNumber({ number: 0.00001, locale })}`
  }

  const baseString = parseFloat(amount.toSignificant(sigFigs))
  const numberString = fixedDecimals ? parseFloat(baseString.toFixed(fixedDecimals)) : baseString

  console.log('formatted:Locale', {
    number: amount,
    baseString,
    numberString,
  })

  if (numberString > 10000000) {
    // Use formatAmount for numbers greater than 1 billion
    return formatAmount(numberString)
  } else {
    // Use formatLocaleNumber for other numbers
    return formatLocaleNumber({ number: amount, locale, sigFigs, fixedDecimals })
  }
}

export function formatPrice(
  price: Price<Currency, Currency> | undefined,
  sigFigs: number,
  locale: SupportedLocale = DEFAULT_LOCALE
): string {
  if (!price) {
    return '-'
  }

  // Convert the price to a string with significant figures without rounding
  const priceStr = price.toSignificant(sigFigs)

  console.log('num:priceString', priceStr)

  // Check if the string represents a number in scientific notation
  if (priceStr.includes('e') || priceStr.includes('E') || priceStr.startsWith('0.0000')) {
    return formatSmallAmount(priceStr, sigFigs)
  }

  // Use formatLocaleNumber for other numbers
  // const priceNumber = Number(priceStr)
  const priceNumber = Number(parseFloat(priceStr).toFixed(5))

  if (priceNumber > 10000000) {
    // Use formatAmount for numbers greater than 1 billion
    return formatAmount(priceNumber)
  } else {
    // Use formatLocaleNumber for other numbers
    return formatLocaleNumber({ number: priceNumber, locale, sigFigs })
  }
}
