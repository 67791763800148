import { useWeb3React } from '@web3-react/core'
import Badge, { BadgeVariant } from 'components/Badge'
import { ButtonPrimary } from 'components/Button'
import { LightCard, OutlineCard } from 'components/Card'
import Column, { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import FormattedCurrencyAmount from 'components/FormattedCurrencyAmount'
import Loader from 'components/Loader'
import Row, { RowBetween, RowFixed } from 'components/Row'
import useStepWithNext from 'components/StepLine/context/hooks/useStepWithNext'
import StepLineModal from 'components/StepLine/StepLineModal'
import TimeBetweenText from 'components/TimeBetweenText'
import { useToken } from 'hooks/Tokens'
import { CommunalFarmStake, useCommunalFarmByIsland, useCommunalFarmLockedStakesOf } from 'hooks/useCommunalFarms'
import { useFarmContract } from 'hooks/useContract'
import { useIslandUnderlyingBalances } from 'hooks/useIslandPositionDetails'
import { useIsland } from 'hooks/useIslands'
import sumCurrencyAmount from 'lib/utils/sumCurrencyAmount'
import { useContext, useEffect, useMemo, useState } from 'react'
import { AlertTriangle, Lock, Unlock } from 'react-feather'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { Separator } from 'theme'

import { IslandContextType } from '../context/IslandContextType'
import useOnUnstakeCallback from './hooks/useOnUnstakeCallback'
import WithdrawBalanceConstructorStep from './steps/WithdrawBalanceConstructorStep'
import WithdrawFromStakeStep from './steps/WithdrawFromStakeStep'
import { UnstakeManualUnlockButton } from './styleds'

const StyledController = styled(OutlineCard)<{ active?: boolean; pulsing?: boolean; disabled?: boolean }>`
  border-color: ${({ active, theme }) => (active ? theme.deprecated_blue1 : theme.cardBorderPrimary)};
  border-radius: 8px;
  height: 32px;
  width: 32px;

  background: ${({ theme }) => theme.cardPrimary};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ disabled, theme }) =>
    !disabled
      ? `
    :hover {
      border-color: ${theme.deprecated_blue1};
      background: ${theme.deprecated_blue1};
    }
    cursor: pointer;
  `
      : `
    opacity: 0.5;
  `}

  overflow: hidden;

  user-select: none;
`
type PropsOf<T> = T extends React.ComponentType<infer P> ? P : never

const Controller = ({
  children,
  onMouseDown,
  onMouseUp,
  onPress,
  onClick: _,
  ...props
}: { children: any; onPress: () => void } & PropsOf<typeof StyledController>) => {
  const [pressing, setPressing] = useState(false)

  const handleMouseDown = (e: any) => {
    setPressing(true)
    return onMouseDown && onMouseDown(e)
  }
  const handleMouseUp = (e: any) => {
    setPressing(false)
    return onMouseUp && onMouseUp(e)
  }

  useEffect(() => {
    if (pressing) {
      let throttling = 7
      let tick = -1
      let skips = 5

      const int = setInterval(() => {
        if (skips > 0) {
          skips--
          return
        }

        tick++
        if (throttling !== 0 && tick % throttling !== 0) {
          return
        }
        onPress()
        if (throttling > 0) {
          throttling = throttling - 1
        }
      }, 75)

      return () => {
        clearInterval(int)
      }
    }
    return undefined
  }, [pressing])

  return (
    <StyledController {...props} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onClick={onPress}>
      {children}
    </StyledController>
  )
}

const UnstakeView = ({ islandId }: IslandContextType) => {
  const [value, setValue] = useState('')
  const token = useToken(islandId)
  const theme = useContext(ThemeContext)
  const { island } = useIsland(islandId)
  const farm = useCommunalFarmByIsland(islandId)
  const farmContract = useFarmContract(farm?.address)
  const token0 = useToken(island?.token0.id)
  const token1 = useToken(island?.token1.id)
  const { account } = useWeb3React()

  const { avaliableStakes, stakes } = useCommunalFarmLockedStakesOf(farm, account)

  const unavaliableStakes = useMemo(() => {
    if (!stakes) return []
    return stakes.filter((item) => item.endingTimestamp.getTime() > Date.now())
  }, [stakes])

  const sortedAvailableStakes = useMemo(
    () =>
      avaliableStakes
        ?.filter((item) => !!item.liquidity)
        .sort((a, b) => (b.liquidity!.greaterThan(a.liquidity!) ? -1 : 1)),
    [avaliableStakes]
  )

  const [index, setIndex] = useState(-1)
  const [manualIndexes, setManualIndexes] = useState<number[]>([])
  console.log(manualIndexes)

  const plus = () => {
    if (!sortedAvailableStakes) return

    if (index < sortedAvailableStakes.length - 1) {
      setIndex((prev) => {
        if (prev < sortedAvailableStakes.length - 1) {
          const newIndex = prev + 1
          return newIndex
        }
        return prev
      })
    }
  }

  const Stake = ({
    disabled,
    stake: { liquidity, endingTimestamp, lockMultiplier },
    onSelect,
  }: {
    stake: CommunalFarmStake
    disabled?: boolean
    onSelect?: () => void
  }) => {
    const underlyingAmounts = useIslandUnderlyingBalances(island, liquidity, token0, token1)
    const isLocked = endingTimestamp.getTime() > Date.now()

    return (
      <RowBetween opacity={disabled ? 0.5 : 1}>
        <RowFixed marginLeft="4px">
          <DoubleCurrencyLogo currency0={token0 || undefined} currency1={token1 || undefined} size={16} />
          <Text marginLeft="4px" marginRight="4px" fontSize={12}>
            {underlyingAmounts?.amount0 && (
              <FormattedCurrencyAmount currencyAmount={underlyingAmounts.amount0} significantDigits={6} />
            )}
            /
            {underlyingAmounts?.amount1 && (
              <FormattedCurrencyAmount currencyAmount={underlyingAmounts.amount1} significantDigits={6} />
            )}
          </Text>
          <Badge variant={BadgeVariant.PRIMARY} size="sm">
            <Text fontSize={10} opacity={0.5}>
              x{lockMultiplier.toFixed(2)}
            </Text>
          </Badge>
        </RowFixed>

        <RowFixed>
          {isLocked ? (
            <Lock color={theme.accentFailure} size={14} opacity={0.6} />
          ) : (
            <UnstakeManualUnlockButton clickable={onSelect !== undefined} onClick={() => onSelect?.()}>
              <Unlock color={disabled ? theme.textSecondary : theme.accentSuccess} size={14} opacity={0.6} />
            </UnstakeManualUnlockButton>
          )}
          {isLocked && (
            <Text fontSize={12} marginLeft="4px">
              <TimeBetweenText date={endingTimestamp}>
                <Loader />
              </TimeBetweenText>
            </Text>
          )}
        </RowFixed>
      </RowBetween>
    )
  }

  const minus = () => {
    if (!sortedAvailableStakes) return

    if (index - 1 >= -1) {
      setIndex((prev) => {
        const newIndex = prev - 1
        if (newIndex >= -1) {
          return newIndex
        }
        return prev
      })
    }
  }

  const selectedAvailableStakes = useMemo(() => {
    if (!sortedAvailableStakes) return []
    const combined = [
      ...sortedAvailableStakes.slice(0, index + 1),
      ...manualIndexes.map((index) => sortedAvailableStakes[index]),
    ]
    const uniqueArray = combined.filter((item, index) => {
      const _index = combined.findIndex((i) => i.kekId === item.kekId)
      return _index === index
    })

    return uniqueArray
  }, [index, sortedAvailableStakes, manualIndexes])
  console.log(`selectedAvailableStakes`, selectedAvailableStakes)

  const selectedShares = useMemo(() => {
    return sumCurrencyAmount(selectedAvailableStakes.map((item) => item.liquidity))
  }, [selectedAvailableStakes])
  const totalAvailableStakes = useMemo(() => {
    if (!sortedAvailableStakes) {
      return undefined
    }
    return sumCurrencyAmount(sortedAvailableStakes.map((item) => item!.liquidity!))
  }, [selectedAvailableStakes])

  useEffect(() => {
    if (!selectedShares) {
      setValue('0')
      return
    }
    setValue(selectedShares.toExact() || '0')
  }, [selectedShares])

  const selectedUnderlyingBalances = useIslandUnderlyingBalances(island, selectedShares, token0, token1)

  const [selectedStakes, setSelectedStakes] = useState<CommunalFarmStake[] | undefined>(undefined)
  const constructorStep = useStepWithNext('WithdrawBalanceConstructorStep', WithdrawBalanceConstructorStep, {
    selectedShares,
    userBalanceShares: undefined,
    stakes: sortedAvailableStakes,
    island,
    onSelect: ({ stakes }) => setSelectedStakes(stakes),
    defaultActiveStakes: selectedAvailableStakes,
  })

  const { onConfirmWithdrawStakes, attemptingTxn, hash, clear } = useOnUnstakeCallback(
    selectedStakes || [],
    farmContract,
    () => {
      setIndex(-1)
      setManualIndexes([])
      setIsOpen(false)
    }
  )

  const withdrawStep = useStepWithNext('WithdrawFromStakeStep', WithdrawFromStakeStep, {
    stakes: selectedStakes || [],
    onWithdraw: onConfirmWithdrawStakes,
    hash,
    attemptingTxn,
    pendingText: (
      <>
        Unstaking {selectedShares?.toSignificant(6)} {token?.symbol}
      </>
    ),
    autoConfirm: true,
    transactionWaiting: true,
  })

  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <StepLineModal
        steps={[constructorStep, withdrawStep]}
        isOpen={isOpen}
        header={<>Unstake</>}
        onDismiss={clear}
        onFinish={clear}
      />
      {!!(unavaliableStakes.length || sortedAvailableStakes?.length) && (
        <>
          <LightCard>
            <AutoColumn gap="md">
              {unavaliableStakes.map((stake) => (
                <Stake key={stake.kekId} stake={stake} />
              ))}
              {!!(unavaliableStakes.length && sortedAvailableStakes?.length) && <Separator />}
              {sortedAvailableStakes?.map((stake, currentIndex) => (
                <Stake
                  key={stake.kekId}
                  stake={stake}
                  disabled={!selectedAvailableStakes.some((item) => item.kekId === stake.kekId)}
                  onSelect={() => {
                    if (manualIndexes.includes(currentIndex)) {
                      setManualIndexes((prev) => prev.filter((item) => item !== currentIndex))
                    } else {
                      setManualIndexes((prev) => [...prev, currentIndex])
                    }
                  }}
                />
              ))}
            </AutoColumn>
          </LightCard>
          <Separator />
        </>
      )}
      {/* {avaliableStakes?.length && <>
      <LightCard>
        <AutoColumn gap="md">
          {avaliableStakes.map((stake, currentIndex) =>
            <Stake key={stake.kekId} stake={stake} disabled={index < currentIndex} />)
          }
        </AutoColumn>
      </LightCard>
    </>} */}

      <AutoColumn gap="md" style={{ position: 'relative' }}>
        <LightCard opacity={avaliableStakes?.length ? 1 : 0.5}>
          <RowBetween>
            <RowFixed grow="1" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
              <Column>
                <Row align="end">
                  <Text fontSize={16} color={theme.textPrimary}>
                    {value}
                  </Text>
                  <Text
                    fontSize={16}
                    color={theme.textSecondary}
                    opacity={0.5}
                    hidden={selectedShares ? totalAvailableStakes?.equalTo(selectedShares) : false}
                  >
                    {totalAvailableStakes && <>/{totalAvailableStakes?.toSignificant(6)}</>}
                  </Text>
                </Row>
                <Text fontSize={12} color={theme.textSecondary}>
                  {token?.symbol}
                </Text>
              </Column>
            </RowFixed>

            <RowFixed grow="0" shrink="0">
              <Controller disabled={index === -1} marginRight="4px" onPress={minus}>
                -
              </Controller>
              <Controller disabled={index === (sortedAvailableStakes?.length || 0) - 1} onPress={plus}>
                +
              </Controller>
            </RowFixed>
          </RowBetween>
        </LightCard>

        {(avaliableStakes?.length || 0) !== 0 && (
          <LightCard>
            <AutoColumn gap="md">
              <RowBetween>
                <Text fontSize={16} fontWeight={500}>
                  <>Pooled {token0?.symbol}:</>
                </Text>
                <RowFixed>
                  <Text fontSize={16} fontWeight={500} marginLeft="6px">
                    {selectedUnderlyingBalances?.amount0 ? (
                      <FormattedCurrencyAmount
                        significantDigits={6}
                        currencyAmount={selectedUnderlyingBalances?.amount0}
                      />
                    ) : (
                      <>0</>
                    )}
                  </Text>
                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={token0} />
                </RowFixed>
              </RowBetween>
              <RowBetween>
                <Text fontSize={16} fontWeight={500}>
                  <>Pooled {token1?.symbol}:</>
                </Text>
                <RowFixed>
                  <Text fontSize={16} fontWeight={500} marginLeft="6px">
                    {selectedUnderlyingBalances?.amount1 ? (
                      <FormattedCurrencyAmount
                        significantDigits={6}
                        currencyAmount={selectedUnderlyingBalances?.amount1}
                      />
                    ) : (
                      <>0</>
                    )}
                  </Text>
                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={token1} />
                </RowFixed>
              </RowBetween>
            </AutoColumn>
          </LightCard>
        )}

        <ButtonPrimary disabled={selectedAvailableStakes.length === 0} onClick={() => setIsOpen(true)}>
          {(avaliableStakes?.length || 0) === 0 ? (
            <>
              <AlertTriangle size={16} color={theme.accentWarning}></AlertTriangle>
              <Text fontSize={14} marginLeft="4px" color={theme.accentWarning}>
                There are no avaliable stakes
              </Text>
            </>
          ) : (
            <>Unstake {selectedAvailableStakes.length >= 0 && <>{selectedAvailableStakes.length} positions</>}</>
          )}
        </ButtonPrimary>
      </AutoColumn>
    </>
  )
}

export default UnstakeView
