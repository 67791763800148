import { useWeb3React } from '@web3-react/core'
import { PageName } from 'components/AmplitudeAnalytics/constants'
import { Trace } from 'components/AmplitudeAnalytics/Trace'
import IslandList from 'components/modules/IslandList'
import { RowBetween } from 'components/Row'
import { Island, useIslands } from 'hooks/useIslands'
import { useContext, useState } from 'react'
import { useMemo } from 'react'
import { AlertTriangle } from 'react-feather'
import { useToggleWalletModal } from 'state/application/hooks'
import styled, { css, ThemeContext } from 'styled-components/macro'

import * as S from '../../Liquidity.styles'

export const TitleRow = styled(RowBetween)`
  color: ${({ theme }) => theme.deprecated_text2};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  `};
`
const Wrapper = styled.div`
  width: 100%;
`

export const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 24px 0;
  max-width: 300px;
  /* min-height: 25vh; */
`

const IconStyle = css`
  width: 40px;
  height: 40px;
  margin-bottom: 0.5rem;
`

export const NetworkIcon = styled(AlertTriangle)`
  ${IconStyle}
`

function PositionsLoadingPlaceholder() {
  return (
    <S.LoadingRows>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </S.LoadingRows>
  )
}

export default function Islands({ searchParam }: { searchParam: string }) {
  const { account, chainId } = useWeb3React()
  const toggleWalletModal = useToggleWalletModal()

  const theme = useContext(ThemeContext)
  // const [userHideClosedPositions, setUserHideClosedPositions] = useUserHideClosedPositions()
  const [viewAllIslands, setViewAllIslands] = useState(true)
  const [gridMode, setGridMode] = useState(false)

  const { islands, isLoading, isFetching, isUninitialized, isError } = useIslands()

  // Success state
  const allIslands = islands ?? []
  const userIslands = islands?.filter((island) => island.userPosition) ?? []

  // Filter function
  const filterIslands = (island: Island, searchTerm: string) => {
    const searchFields = [
      island.token0.symbol,
      island.token0.name,
      island.token0.id,
      island.token1.symbol,
      island.token1.name,
      island.token1.id,
      island.id,
    ]
    return searchFields.some((field) => field.toLowerCase().includes(searchTerm.toLowerCase()))
  }

  // Determine which islands to show based on viewAllIslands
  const islandsToFilter = viewAllIslands ? allIslands : userIslands

  // Use searchedIslands for display
  const filteredIslands = useMemo(() => {
    return searchParam ? islandsToFilter.filter((island) => filterIslands(island, searchParam)) : islandsToFilter
  }, [islandsToFilter, viewAllIslands, searchParam])
  // const showV2Features = Boolean(V2_FACTORY_ADDRESSES[chainId])

  // Loading or uninitialized state
  if (isLoading || isFetching || isUninitialized) {
    return <Wrapper>{/* <PositionsLoadingPlaceholder /> */}</Wrapper>
  }

  const userTotalDeposits = userIslands?.reduce((total, island) => {
    const balanceShareUSD = island?.userPosition?.balanceSharesUSD

    return (
      total +
      (balanceShareUSD === undefined
        ? 0
        : typeof balanceShareUSD === 'string'
        ? parseFloat(balanceShareUSD)
        : balanceShareUSD)
    )
  }, 0)

  const kodiakTotalValueLocked = allIslands?.reduce((total, island) => {
    return total + (Number(island.totalValueLockedUSD) || 0)
  }, 0)

  const showConnectAWallet = Boolean(!account)

  return (
    <Trace page={PageName.ISLAND_PAGE} shouldLogImpression>
      <Wrapper>
        {isLoading ? (
          <PositionsLoadingPlaceholder />
        ) : (
          // filteredIslands && filteredIslands.length > 0 ? (
          <IslandList islands={filteredIslands} /*isError={isError}*/ />
          // )
        )}
      </Wrapper>
    </Trace>
  )
}
// ) : filteredPositions && closedPositions && tempIslandDetails.length > 0 ? (
