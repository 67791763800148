// eslint-disable-next-line no-restricted-imports
import { t } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { ElementName, Event, EventName } from 'components/AmplitudeAnalytics/constants'
import { TraceEvent } from 'components/AmplitudeAnalytics/TraceEvent'
import { getConnection } from 'connection/utils'
import { ALL_SUPPORTED_CHAIN_IDS } from 'constants/chains'
import useSelectChain from 'hooks/useSelectChain'
import useSyncChainQuery from 'hooks/useSyncChainQuery'
import { getIsValidSwapQuote } from 'pages/Swap'
import { darken } from 'polished'
import { useMemo } from 'react'
import { AlertTriangle } from 'react-feather'
import { useAppSelector } from 'state/hooks'
import { useDerivedSwapInfo } from 'state/swap/hooks'
import styled, { css } from 'styled-components/macro'

import { useToggleWalletModal } from '../../state/application/hooks'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/types'
import { shortenAddress } from '../../utils'
import { ButtonSecondary } from '../Button'
import StatusIcon from '../Identicon/StatusIcon'
import Loader from '../Loader'
import { RowBetween } from '../Row'
import WalletModal from '../WalletModal'
const Web3StatusGeneric = styled(ButtonSecondary)`
  display: flex;
  height: 40px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  background: ${({ theme }) => theme.cardPrimary};

  font-weight: 500;

  color: ${({ theme }) => theme.textPrimary};
  text-align: center;
  /* link/link-medium */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  cursor: pointer;
  user-select: none;
  :focus {
    outline: none;
  }
`
const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.deprecated_red1};
  border: 1px solid ${({ theme }) => theme.deprecated_red1};
  color: ${({ theme }) => theme.deprecated_white};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.1, theme.deprecated_red1)};
  }
`

const Web3StatusConnect = styled(Web3StatusGeneric)<{ faded?: boolean }>`
  :hover,
  :focus {
    border: 1px solid ${({ theme }) => darken(0.05, theme.cardBorderPrimary)};
    color: ${({ theme }) => theme.textPrimary};
  }

  ${({ faded }) =>
    faded &&
    css`
      background-color: ${({ theme }) => theme.cardPrimary};
      border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
      color: ${({ theme }) => theme.textPrimary};

      :hover,
      :focus {
        border: 1px solid ${({ theme }) => darken(0.05, theme.cardBorderPrimary)};
        color: ${({ theme }) => darken(0.05, theme.textPrimary)};
      }
    `}
`

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean }>`
  background-color: transparent;
  border: 1px solid transparent;

  color: ${({ pending, theme }) => (pending ? theme.textSecondary : theme.textPrimary)};

  :hover,
  :focus {
    border: 1px solid ${({ theme }) => darken(0.05, theme.cardBorderPrimary)};
    background: ${({ theme }) => darken(0.05, theme.backgroundSurface)};

    :focus {
      border: 1px solid
        ${({ pending, theme }) =>
          pending ? darken(0.1, theme.deprecated_primary1) : darken(0.1, theme.deprecated_bg2)};
    }
  }
`

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
  font-weight: 500;

  color: ${({ theme }) => theme.textPrimary};
  text-align: center;
  /* link/link-medium */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`

const NetworkIcon = styled(AlertTriangle)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

function Sock() {
  return (
    <span role="img" aria-label={t`has socks emoji`} style={{ marginTop: -4, marginBottom: -4 }}>
      🧦
    </span>
  )
}

function Web3StatusInner() {
  const { account, connector, chainId, ENSName } = useWeb3React()
  const connectionType = getConnection(connector).type
  const {
    trade: { state: tradeState, trade },
    inputError: swapInputError,
  } = useDerivedSwapInfo()
  const validSwapQuote = getIsValidSwapQuote(trade, tradeState, swapInputError)

  const error = useAppSelector((state) => state.connection.errorByConnectionType[getConnection(connector).type])

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)

  const hasPendingTransactions = !!pending.length
  const toggleWalletModal = useToggleWalletModal()

  const selectChain = useSelectChain()
  useSyncChainQuery()

  const isAllowedChain = ALL_SUPPORTED_CHAIN_IDS.includes(chainId)

  async function handleWrongNetwork() {
    // await selectChain(2061)
    await selectChain(80085)
    // BERA MIGRATION
  }

  if (error) {
    return (
      <Web3StatusError onClick={toggleWalletModal}>
        <NetworkIcon />
        <Text>
          <>Error</>
        </Text>
      </Web3StatusError>
    )
  }
  if (account && isAllowedChain) {
    return (
      // <WalletDropdown name={ApplicationModal.ACCOUNT_DROPDOWN}>
      <Web3StatusConnected
        data-testid="web3-status-connected"
        onClick={toggleWalletModal}
        pending={hasPendingTransactions}
      >
        {hasPendingTransactions ? (
          <RowBetween style={{ gap: '8px' }}>
            <Text>{pending?.length} Pending</Text> <Loader stroke="white" />
          </RowBetween>
        ) : (
          <>
            <Text>{ENSName || shortenAddress(account)}</Text>
          </>
        )}
        {!hasPendingTransactions && <StatusIcon connectionType={connectionType} />}
      </Web3StatusConnected>
      // </WalletDropdown>
    )
  }
  if (!account) {
    return (
      <TraceEvent
        events={[Event.onClick]}
        name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
        properties={{ received_swap_quote: validSwapQuote }}
        element={ElementName.CONNECT_WALLET_BUTTON}
      >
        <Web3StatusConnect onClick={toggleWalletModal} faded={!account}>
          <Text>
            <>Connect Wallet</>
          </Text>
        </Web3StatusConnect>
      </TraceEvent>
    )
  }
  if (account && !isAllowedChain) {
    return (
      <Web3StatusConnect onClick={handleWrongNetwork}>
        <Text>
          <>Wrong Network</>
        </Text>
      </Web3StatusConnect>
    )
  } else {
    return null
  }
}

export default function Web3Status() {
  const { ENSName } = useWeb3React()

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  console.log(`sortedRecentTransactions`, sortedRecentTransactions)
  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)
  const confirmed = sortedRecentTransactions.filter((tx) => tx.receipt).map((tx) => tx.hash)

  return (
    <>
      <Web3StatusInner />
      <WalletModal ENSName={ENSName ?? undefined} pendingTransactions={pending} confirmedTransactions={confirmed} />
    </>
  )
}
