import beraLogo from 'assets/bera.png'
import polygonMaticLogo from 'assets/svg/polygon-matic-logo.svg'
import ms from 'ms.macro'

import { SupportedChainId, SupportedL1ChainId, SupportedL2ChainId } from './chains'

export enum NetworkType {
  L1,
  L2,
}

interface BaseChainInfo {
  readonly networkType: NetworkType
  readonly blockWaitMsBeforeWarning?: number
  readonly docs: string
  readonly bridge?: string
  readonly explorer: string
  readonly infoLink: string
  readonly logoUrl: string
  readonly label: string
  readonly helpCenterUrl?: string
  readonly nativeCurrency: {
    name: string // e.g. 'Goerli ETH',
    symbol: string // e.g. 'gorETH',
    decimals: number // e.g. 18,
  }
  readonly color?: string
  readonly backgroundColor?: string
}

export interface L1ChainInfo extends BaseChainInfo {
  readonly networkType: NetworkType.L1
  readonly defaultListUrl?: string
}

export interface L2ChainInfo extends BaseChainInfo {
  readonly networkType: NetworkType.L2
  readonly bridge: string
  readonly statusPage?: string
  readonly defaultListUrl: string
}

export type ChainInfoMap = { readonly [chainId: number]: L1ChainInfo | L2ChainInfo } & {
  readonly [chainId in SupportedL2ChainId]: L2ChainInfo
} & { readonly [chainId in SupportedL1ChainId]: L1ChainInfo }

const CHAIN_INFO: ChainInfoMap = {
  // [SupportedChainId.MAINNET]: {
  //   networkType: NetworkType.L1,
  //   docs: 'https://docs.uniswap.org/',
  //   explorer: 'https://etherscan.io/',
  //   infoLink: 'https://info.uniswap.org/#/',
  //   label: 'Ethereum',
  //   logoUrl: ethereumLogoUrl,
  //   nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  //   color: colorsDark.chain_1,
  // },
  // [SupportedChainId.RINKEBY]: {
  //   networkType: NetworkType.L1,
  //   docs: 'https://docs.uniswap.org/',
  //   explorer: 'https://rinkeby.etherscan.io/',
  //   infoLink: 'https://info.uniswap.org/#/',
  //   label: 'Rinkeby',
  //   logoUrl: ethereumLogoUrl,
  //   nativeCurrency: { name: 'Rinkeby Ether', symbol: 'rETH', decimals: 18 },
  //   color: colorsDark.chain_4,
  // },
  // [SupportedChainId.ROPSTEN]: {
  //   networkType: NetworkType.L1,
  //   docs: 'https://docs.uniswap.org/',
  //   explorer: 'https://ropsten.etherscan.io/',
  //   infoLink: 'https://info.uniswap.org/#/',
  //   label: 'Ropsten',
  //   logoUrl: ethereumLogoUrl,
  //   nativeCurrency: { name: 'Ropsten Ether', symbol: 'ropETH', decimals: 18 },
  //   color: colorsDark.chain_3,
  // },
  // [SupportedChainId.KOVAN]: {
  //   networkType: NetworkType.L1,
  //   docs: 'https://docs.uniswap.org/',
  //   explorer: 'https://kovan.etherscan.io/',
  //   infoLink: 'https://info.uniswap.org/#/',
  //   label: 'Kovan',
  //   logoUrl: ethereumLogoUrl,
  //   nativeCurrency: { name: 'Kovan Ether', symbol: 'kovETH', decimals: 18 },
  //   color: colorsDark.chain_69,
  // },
  // [SupportedChainId.GOERLI]: {
  //   networkType: NetworkType.L1,
  //   docs: 'https://docs.uniswap.org/',
  //   explorer: 'https://goerli.etherscan.io/',
  //   infoLink: 'https://info.uniswap.org/#/',
  //   label: 'Görli',
  //   logoUrl: ethereumLogoUrl,
  //   nativeCurrency: { name: 'Görli Ether', symbol: 'görETH', decimals: 18 },
  //   color: colorsDark.chain_5,
  // },
  // [SupportedChainId.OPTIMISM]: {
  //   networkType: NetworkType.L2,
  //   blockWaitMsBeforeWarning: ms`25m`,
  //   bridge: 'https://app.optimism.io/bridge',
  //   defaultListUrl: OPTIMISM_LIST,
  //   docs: 'https://optimism.io/',
  //   explorer: 'https://optimistic.etherscan.io/',
  //   infoLink: 'https://info.uniswap.org/#/optimism/',
  //   label: 'Optimism',
  //   logoUrl: optimismLogoUrl,
  //   statusPage: 'https://optimism.io/status',
  //   helpCenterUrl: 'https://help.uniswap.org/en/collections/3137778-uniswap-on-optimistic-ethereum-oξ',
  //   nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  //   color: colorsDark.chain_10,
  //   backgroundColor: colorsDark.chain_10_background,
  // },
  // [SupportedChainId.OPTIMISTIC_KOVAN]: {
  //   networkType: NetworkType.L2,
  //   blockWaitMsBeforeWarning: ms`25m`,
  //   bridge: 'https://app.optimism.io/bridge',
  //   defaultListUrl: OPTIMISM_LIST,
  //   docs: 'https://optimism.io/',
  //   explorer: 'https://optimistic.etherscan.io/',
  //   infoLink: 'https://info.uniswap.org/#/optimism/',
  //   label: 'Optimistic Kovan',
  //   logoUrl: optimismLogoUrl,
  //   statusPage: 'https://optimism.io/status',
  //   helpCenterUrl: 'https://help.uniswap.org/en/collections/3137778-uniswap-on-optimistic-ethereum-oξ',
  //   nativeCurrency: { name: 'Optimistic Kovan Ether', symbol: 'kovOpETH', decimals: 18 },
  //   color: colorsDark.chain_69,
  // },
  // [SupportedChainId.ARBITRUM_ONE]: {
  //   networkType: NetworkType.L2,
  //   blockWaitMsBeforeWarning: ms`10m`,
  //   bridge: 'https://bridge.arbitrum.io/',
  //   docs: 'https://offchainlabs.com/',
  //   explorer: 'https://arbiscan.io/',
  //   infoLink: 'https://info.uniswap.org/#/arbitrum',
  //   label: 'Arbitrum',
  //   logoUrl: arbitrumLogoUrl,
  //   defaultListUrl: ARBITRUM_LIST,
  //   helpCenterUrl: 'https://help.uniswap.org/en/collections/3137787-uniswap-on-arbitrum',
  //   nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  //   color: colorsDark.chain_42,
  //   backgroundColor: colorsDark.chain_42161_background,
  // },
  // [SupportedChainId.ARBITRUM_RINKEBY]: {
  //   networkType: NetworkType.L2,
  //   blockWaitMsBeforeWarning: ms`10m`,
  //   bridge: 'https://bridge.arbitrum.io/',
  //   docs: 'https://offchainlabs.com/',
  //   explorer: 'https://rinkeby-explorer.arbitrum.io/',
  //   infoLink: 'https://info.uniswap.org/#/arbitrum/',
  //   label: 'Arbitrum Rinkeby',
  //   logoUrl: arbitrumLogoUrl,
  //   defaultListUrl: ARBITRUM_LIST,
  //   helpCenterUrl: 'https://help.uniswap.org/en/collections/3137787-uniswap-on-arbitrum',
  //   nativeCurrency: { name: 'Rinkeby Arbitrum Ether', symbol: 'rinkArbETH', decimals: 18 },
  //   color: colorsDark.chain_421611,
  // },
  // [SupportedChainId.POLYGON]: {
  //   networkType: NetworkType.L1,
  //   blockWaitMsBeforeWarning: ms`10m`,
  //   bridge: 'https://wallet.polygon.technology/bridge',
  //   docs: 'https://polygon.io/',
  //   explorer: 'https://polygonscan.com/',
  //   infoLink: 'https://info.uniswap.org/#/polygon/',
  //   label: 'Polygon',
  //   logoUrl: polygonMaticLogo,
  //   nativeCurrency: { name: 'Polygon Matic', symbol: 'MATIC', decimals: 18 },
  //   color: colorsDark.chain_137,
  //   backgroundColor: colorsDark.chain_137_background,
  // },
  [SupportedChainId.POLYGON_MUMBAI]: {
    networkType: NetworkType.L1,
    blockWaitMsBeforeWarning: ms`10m`,
    bridge: 'https://wallet.polygon.technology/bridge',
    docs: 'https://polygon.io/',
    explorer: 'https://mumbai.polygonscan.com/',
    infoLink: 'https://info.uniswap.org/#/polygon/',
    label: 'Polygon Mumbai',
    logoUrl: polygonMaticLogo,
    nativeCurrency: { name: 'Polygon Mumbai Matic', symbol: 'MATIC', decimals: 18 },
  },

  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // BERA MIGRATION: Add berachain info's here following the templates above
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  [SupportedChainId.BERACHAIN_PRIVATE_TESTNET]: {
    networkType: NetworkType.L1,
    blockWaitMsBeforeWarning: ms`25m`,
    // defaultListUrl: BERACHAIN_PRIVATE_TESTNET_LIST,
    bridge: 'https://bridge.berachain-internal.com', // placeholder, no link rn
    docs: 'https://docs.berachain-internal.com',
    explorer: 'https://scan.berachain-internal.com/',
    infoLink: process.env.REACT_APP_V3_ANALYTICS,
    label: 'Berachain Puer',
    logoUrl: beraLogo,
    nativeCurrency: { name: 'Berachain Token', symbol: 'BERA', decimals: 18 },
  },
  [SupportedChainId.BERACHAIN_ARTIO_TESTNET]: {
    networkType: NetworkType.L1,
    blockWaitMsBeforeWarning: ms`25m`,
    // defaultListUrl: BERACHAIN_PRIVATE_TESTNET_LIST,
    // bridge: 'https://artio.bridge.berachain.com', // placeholder, no link rn
    docs: 'https://artio.docs.berachain.com',
    explorer: 'https://artio.beratrail.io/',
    infoLink: process.env.REACT_APP_V3_ANALYTICS,
    label: 'Berachain Artio',
    logoUrl: 'https://raw.githubusercontent.com/berachain/default-token-list/main/src/assets/bera.png',
    nativeCurrency: { name: 'Berachain Token', symbol: 'BERA', decimals: 18 },
  },
}

export function getChainInfo(chainId: SupportedL1ChainId): L1ChainInfo
export function getChainInfo(chainId: SupportedL2ChainId): L2ChainInfo
export function getChainInfo(chainId: SupportedChainId): L1ChainInfo | L2ChainInfo
export function getChainInfo(
  chainId: SupportedChainId | SupportedL1ChainId | SupportedL2ChainId | number | undefined
): L1ChainInfo | L2ChainInfo | undefined

/**
 * Overloaded method for returning ChainInfo given a chainID
 * Return type varies depending on input type:
 * number | undefined -> returns chaininfo | undefined
 * SupportedChainId -> returns L1ChainInfo | L2ChainInfo
 * SupportedL1ChainId -> returns L1ChainInfo
 * SupportedL2ChainId -> returns L2ChainInfo
 */
export function getChainInfo(chainId: any): any {
  if (chainId) {
    return CHAIN_INFO[chainId] ?? undefined
  }
  return undefined
}

export const MAINNET_INFO = CHAIN_INFO[SupportedChainId.BERACHAIN_ARTIO_TESTNET]
export function getChainInfoOrDefault(chainId: number | undefined) {
  return getChainInfo(chainId) ?? CHAIN_INFO[SupportedChainId.BERACHAIN_ARTIO_TESTNET]
}
