import { ButtonPrimary } from 'components/Button'
import styled from 'styled-components'

export const MainContentWrapper = styled.main`
  background-color: ${({ theme }) => theme.cardPrimary};
  /* padding: 16px; */
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09);

  width: 100%;
`

export const HeaderCol = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;

  div {
    /* width: 100%; */
    white-space: wrap;
    text-align: center;
  }

  .title {
    cursor: help;
  }
`

// Subgrid for header
export const HeaderRow = styled.div`
  grid-area: header;
  display: grid;
  grid-template-columns: 260px 230px 20px repeat(2, 1fr);
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr repeat(3, 0px) repeat(1, 1fr)
  `}

  /* height: 60px; */

  padding: 16px;

  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.cardBorderSecondary};
`

// Subgrid for header
export const V2HeaderRowFlex = styled.div`
  grid-area: header;
  display: grid;
  grid-template-columns: 260px 230px repeat(2, 1fr);
  ${({ theme }) => theme.mediaWidth.upToSmall`
  grid-template-columns: 1fr repeat(2, 0px) repeat(1, 1fr)
`}

  /* height: 60px; */

  padding: 16px;

  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.cardBorderSecondary};
`

export const ToggleWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ToggleLabel = styled.div`
  opacity: 0.6;
  margin-right: 10px;
`
export const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  padding: 6px 16px;
  border: none;
  width: fit-content;

  background-color: transparent;

  border-radius: 4px;
  border: 1px solid #64748b;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex: 1 1 auto;
    width: 100%;
  `};

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  color: ${({ theme }) => theme.textPrimary};

  &:hover {
    background: ${({ theme }) => theme.stateOverlayHover};
  }
`

export const PositionListFooter = styled.div`
  display: flex;
  padding: 16px;
  justify-content: flex-end;
  gap: 24px;
  /* border-bottom: 1px solid ${({ theme }) => theme.cardBorderPrimary}; */
`
