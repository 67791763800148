import { useWeb3React } from '@web3-react/core'
import { ElementName, Event, EventName } from 'components/AmplitudeAnalytics/constants'
import { TraceEvent } from 'components/AmplitudeAnalytics/TraceEvent'
import { ButtonLight, ButtonPrimary } from 'components/Button'
import TimeBetweenText from 'components/TimeBetweenText'
import { useLockedPositions } from 'hooks/useCommunalFarms'
import { Text } from 'rebass'
import { useToggleWalletModal } from 'state/application/hooks'
import styled from 'styled-components'
import { ClockIcon } from 'theme'

import { useFarm } from './context/FarmContext'

const PositionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Position = styled.div`
  display: flex;
  background: ${({ theme }) => theme.cardSecondary};
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  border-radius: 8px;
  justify-content: space-between;
`

const PositionInner = styled.div<{ fontSize?: string }>`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`

const MultipliyerText = styled.div<{ fontSize?: string; opacity?: string }>`
  font-size: ${({ fontSize }) => fontSize || '16px'};
  opacity: ${({ opacity }) => opacity || '1'};
`

const StyledClockIcon = styled(ClockIcon)`
  margin-right: 4px;
`

const WithdrawView = () => {
  const farm = useFarm()
  const { account } = useWeb3React()
  const toggleWalletModal = useToggleWalletModal()
  const lockedPositions = useLockedPositions(farm)
  const WithdrawButton = () => (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <ButtonPrimary onClick={() => {}} disabled={false}>
      <Text fontSize={20} fontWeight={500}>
        <>Withdraw </>
      </Text>
    </ButtonPrimary>
  )

  return (
    <>
      <PositionsWrapper>
        {lockedPositions.map((lockedPosition) => {
          return (
            <Position key={lockedPosition.kekId}>
              <PositionInner>
                <div>
                  {lockedPosition.liquidity.toSignificant()} {lockedPosition.liquidity.currency.symbol}
                </div>
              </PositionInner>
              <PositionInner>
                <MultipliyerText fontSize="90%" opacity="0.8">
                  <TimeBetweenText date={lockedPosition.endingTimestamp}>
                    <StyledClockIcon size="12px" />
                  </TimeBetweenText>
                </MultipliyerText>
                <MultipliyerText fontSize="80%" opacity="0.6">
                  Multipliyer: x{lockedPosition.lockMultiplier.toFixed(2)}
                </MultipliyerText>
              </PositionInner>
            </Position>
          )
        })}
      </PositionsWrapper>

      {!account && (
        <TraceEvent
          events={[Event.onClick]}
          name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
          properties={{ received_swap_quote: false }}
          element={ElementName.CONNECT_WALLET_BUTTON}
        >
          <ButtonLight onClick={toggleWalletModal}>
            <>Connect Wallet</>
          </ButtonLight>
        </TraceEvent>
      )}
    </>
  )
}

export default WithdrawView
