import { useWeb3React } from '@web3-react/core'
import * as T from 'components/Typography'
import { getChainInfoOrDefault } from 'constants/chainInfo'
import React, { useEffect, useRef, useState } from 'react'
import { ChevronDown } from 'react-feather'
import { useCloseModal, useModalIsOpen, useOpenModal, useToggleModal } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { useNativeCurrencyBalances } from 'state/connection/hooks'
import styled from 'styled-components/macro'
import { MEDIA_WIDTHS } from 'theme'

import { StyledExternalLink, StyledNavLink } from '.'

export enum FlyoutAlignment {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

const FlyoutHeader = styled.div`
  color: ${({ theme }) => theme.deprecated_text2};
  cursor: default;
  font-weight: 400;
`
const FlyoutMenu = styled.div`
  position: absolute;
  display: flex;
  width: 180px;
  flex-direction: column;
  align-items: flex-start;
  top: 40px;
  z-index: 99;
  /* @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    top: 40px;
  } */

  border-radius: 6px;
  border: 1px solid #475569;
  background: #1e293b;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09);
`

const FlyoutRow = styled.div<{ active: boolean }>`
  display: flex;
  padding: 11px 16px;
  border-radius: 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background: #1e293b;
`
const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  svg {
    stroke: ${({ theme }) => theme.textSecondary};
  }

  &:hover {
    a {
      color: ${({ theme }) => theme.textPrimary};
    }
    svg {
      stroke: ${({ theme }) => theme.textPrimary};
    }
  }

  &.active {
    a {
      color: ${({ theme }) => theme.textPrimary};
    }
    svg {
      stroke: ${({ theme }) => theme.textPrimary};
    }
  }
`
const SelectorWrapper = styled.div`
  position: relative;
  top: 0;
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
  }
`

export default function NavDropdown({
  name,
  children,
  dropdownLinks,
}: {
  name: ApplicationModal
  children: React.ReactNode
  dropdownLinks: {
    label: string
    href: string
    external?: boolean
  }[]
}) {
  const node = useRef<HTMLDivElement>(null)
  const isOpen = useModalIsOpen(name)
  const openModal = useOpenModal(name)
  const closeModal = useCloseModal(name)
  const toggleModal = useToggleModal(name)

  const [closeTimeout, setCloseTimeout] = useState<number | null>(null)
  const handleMouseEnter = () => {
    if (closeTimeout) {
      clearTimeout(closeTimeout)
      setCloseTimeout(null)
    }
    openModal()
  }

  const handleMouseLeave = () => {
    const timeout = setTimeout(() => {
      closeModal()
    }, 300) // 300ms delay before closing
    setCloseTimeout(timeout)
  }

  useEffect(() => {
    return () => {
      if (closeTimeout) {
        clearTimeout(closeTimeout)
      }
    }
  }, [closeTimeout])

  return (
    <SelectorWrapper ref={node} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Row>
        {children}
        <ChevronDown size={16} />
      </Row>

      {isOpen && (
        <FlyoutMenu>
          {dropdownLinks.map((link, index) => (
            <FlyoutRow key={index}>
              {link.external ? (
                <StyledExternalLink href={link.href}>
                  {link.label}
                  <sup>↗</sup>
                </StyledExternalLink>
              ) : (
                <StyledNavLink to={link.href}>{link.label}</StyledNavLink>
              )}
            </FlyoutRow>
          ))}
        </FlyoutMenu>
      )}
    </SelectorWrapper>
  )
}

export function WalletDropdown({ name, children }: { name: ApplicationModal; children: React.ReactNode }) {
  const node = useRef<HTMLDivElement>(null)
  const isOpen = useModalIsOpen(name)
  const openModal = useOpenModal(name)
  const closeModal = useCloseModal(name)
  const toggleModal = useToggleModal(name)

  const [closeTimeout, setCloseTimeout] = useState<number | null>(null)

  const { account, chainId } = useWeb3React()

  const userEthBalance = useNativeCurrencyBalances(account ? [account] : [])?.[account ?? '']
  const {
    infoLink,
    nativeCurrency: { symbol: nativeCurrencySymbol },
  } = getChainInfoOrDefault(chainId)

  const handleMouseEnter = () => {
    if (closeTimeout) {
      clearTimeout(closeTimeout)
      setCloseTimeout(null)
    }
    openModal()
  }

  const handleMouseLeave = () => {
    const timeout = setTimeout(() => {
      closeModal()
    }, 300) // 300ms delay before closing
    setCloseTimeout(timeout)
  }

  useEffect(() => {
    return () => {
      if (closeTimeout) {
        clearTimeout(closeTimeout)
      }
    }
  }, [closeTimeout])

  return (
    <SelectorWrapper ref={node} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Row>
        {children}
        <ChevronDown size={16} />
      </Row>

      {isOpen && (
        <FlyoutMenu>
          <FlyoutRow>
            {account && userEthBalance ? (
              <T.BodyXL>
                {userEthBalance?.toSignificant(3)} {nativeCurrencySymbol}
              </T.BodyXL>
            ) : null}
          </FlyoutRow>
        </FlyoutMenu>
      )}
    </SelectorWrapper>
  )
}
