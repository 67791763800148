import { arrayify } from '@ethersproject/bytes'
import { parseBytes32String } from '@ethersproject/strings'
import { Currency, Token } from '@kodiak-finance/sdk-core'
import { useWeb3React } from '@web3-react/core'
import ERC20_ABI from 'abis/erc20.json'
import { Interface } from 'ethers/lib/utils'
import { useBytes32TokenContract, useTokenContract } from 'hooks/useContract'
import { NEVER_RELOAD, useMultipleContractSingleData, useSingleCallResult } from 'lib/hooks/multicall'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import { useMemo } from 'react'
import { isChainAllowed } from 'utils/switchChain'

import { TOKEN_SHORTHANDS } from '../../constants/tokens'
import { isAddress } from '../../utils'
import { supportedChainId } from '../../utils/supportedChainId'

export const IERC20 = new Interface(ERC20_ABI)

// parse a name or symbol from a token response
const BYTES32_REGEX = /^0x[a-fA-F0-9]{64}$/

function parseStringOrBytes32(str: string | undefined, bytes32: string | undefined, defaultValue: string): string {
  return str && str.length > 0
    ? str
    : // need to check for proper bytes string and valid terminator
    bytes32 && BYTES32_REGEX.test(bytes32) && arrayify(bytes32)[31] === 0
    ? parseBytes32String(bytes32)
    : defaultValue
}

/**
 * Returns a Token from the tokenAddress.
 * Returns null if token is loading or null was passed.
 * Returns undefined if tokenAddress is invalid or token does not exist.
 */
export function useTokenFromNetwork(tokenAddress: string | null | undefined): Token | null | undefined {
  const { chainId, connector } = useWeb3React()
  const chainAllowed = chainId && isChainAllowed(connector, chainId)

  const formattedAddress = isAddress(tokenAddress)

  const tokenContract = useTokenContract(formattedAddress ? formattedAddress : undefined, false)
  const tokenContractBytes32 = useBytes32TokenContract(formattedAddress ? formattedAddress : undefined, false)

  const tokenName = useSingleCallResult(tokenContract, 'name', undefined, NEVER_RELOAD)
  const tokenNameBytes32 = useSingleCallResult(tokenContractBytes32, 'name', undefined, NEVER_RELOAD)
  const symbol = useSingleCallResult(tokenContract, 'symbol', undefined, NEVER_RELOAD)
  const symbolBytes32 = useSingleCallResult(tokenContractBytes32, 'symbol', undefined, NEVER_RELOAD)
  const decimals = useSingleCallResult(tokenContract, 'decimals', undefined, NEVER_RELOAD)

  return useMemo(() => {
    if (typeof tokenAddress !== 'string' || !chainAllowed || !formattedAddress) return undefined
    if (decimals.loading || symbol.loading || tokenName.loading) return null
    if (decimals.result) {
      let symbolTmp = parseStringOrBytes32(symbol.result?.[0], symbolBytes32.result?.[0], 'UNKNOWN') // TODO: Remove
      const nameTmp = parseStringOrBytes32(tokenName.result?.[0], symbolBytes32.result?.[0], 'UNKNOWN') // TODO: Remove
      if (symbolTmp.startsWith('RAKIS')) {
        symbolTmp = `KODIAK-${symbolTmp.split('-')[1]}`
      }
      return new Token(chainId, formattedAddress, decimals.result[0], symbolTmp, nameTmp)
    }
    return undefined
  }, [
    formattedAddress,
    chainId,
    chainAllowed,
    decimals.loading,
    decimals.result,
    symbol.loading,
    symbol.result,
    symbolBytes32.result,
    tokenAddress,
    tokenName.loading,
    tokenName.result,
    tokenNameBytes32.result,
  ])
}

export function useTokensFromNetwork(tokenAddresses: (string | null | undefined)[]): (Token | null | undefined)[] {
  const { chainId, connector } = useWeb3React()
  const chainAllowed = chainId && isChainAllowed(connector, chainId)
  const formattedAddresses = tokenAddresses.map(isAddress).map((item) => (item === false ? undefined : item))

  const tokenName = useMultipleContractSingleData(formattedAddresses, IERC20, 'name', undefined, NEVER_RELOAD)
  const symbol = useMultipleContractSingleData(formattedAddresses, IERC20, 'symbol', undefined, NEVER_RELOAD)
  const decimals = useMultipleContractSingleData(formattedAddresses, IERC20, 'decimals', undefined, NEVER_RELOAD)

  return useMemo(() => {
    if (
      !chainAllowed ||
      !formattedAddresses ||
      decimals === undefined ||
      symbol === undefined ||
      tokenName === undefined
    )
      return []

    return formattedAddresses.map((tokenAddress, index) => {
      if (typeof tokenAddress !== 'string') return undefined
      if (decimals[index].loading || symbol[index].loading || tokenName[index].loading) return null
      if (decimals[index].result) {
        return new Token(
          chainId,
          tokenAddress,
          decimals[index].result?.[0],
          symbol[index].result?.[0],
          tokenName[index].result?.[0]
        )
      }
      return undefined
    })
  }, [formattedAddresses, chainAllowed, decimals, symbol, tokenName, chainId])
}

type TokenMap = { [address: string]: Token }

/**
 * Returns a Token from the tokenAddress.
 * Returns null if token is loading or null was passed.
 * Returns undefined if tokenAddress is invalid or token does not exist.
 */
export function useTokenFromMapOrNetwork(tokens: TokenMap, tokenAddress?: string | null): Token | null | undefined {
  const address = isAddress(tokenAddress)
  const token: Token | undefined = address ? tokens[address] : undefined

  const tokenFromNetwork = useTokenFromNetwork(token ? undefined : address ? address : undefined)

  return tokenFromNetwork ?? token
}

export function useTokensFromMapOrNetwork(
  tokens: TokenMap,
  tokenAddresses?: (string | null | undefined)[]
): (Token | null | undefined)[] {
  const { tokenMapping } = useMemo(() => {
    const addresses = tokenAddresses?.map(isAddress) || []
    const tokenMapping: { [address: string]: Token | 'fromNetwork' } = {}

    addresses.forEach((address) => {
      if (address) {
        if (tokens[address]) {
          tokenMapping[address] = tokens[address]
        } else {
          tokenMapping[address] = 'fromNetwork'
        }
      }
    })

    return { tokenMapping }
  }, [tokenAddresses, tokens])

  const fromNetworkAddresses = Object.keys(tokenMapping).filter((address) => tokenMapping[address] === 'fromNetwork')

  const fromNetwork = useTokensFromNetwork(fromNetworkAddresses)

  const networkTokensMap = fromNetwork.reduce((acc, token, index) => {
    if (token) {
      acc[fromNetworkAddresses[index]] = token
    }
    return acc
  }, {} as any)

  const list = (tokenAddresses || []).map((address) =>
    address ? (tokenMapping[address] === 'fromNetwork' ? networkTokensMap[address] : tokenMapping[address]) : undefined
  )

  return list
}

/**
 * Returns a Currency from the currencyId.
 * Returns null if currency is loading or null was passed.
 * Returns undefined if currencyId is invalid or token does not exist.
 */
export function useCurrencyFromMap(tokens: TokenMap, currencyId?: string | null): Currency | null | undefined {
  const nativeCurrency = useNativeCurrency()
  const { chainId, connector } = useWeb3React()
  const isNative = Boolean(nativeCurrency && currencyId?.toUpperCase() === 'ETH')
  const shorthandMatchAddress = useMemo(() => {
    const chain = supportedChainId(chainId)
    return chain && currencyId ? TOKEN_SHORTHANDS[currencyId.toUpperCase()]?.[chain] : undefined
  }, [chainId, currencyId])

  const token = useTokenFromMapOrNetwork(tokens, isNative ? undefined : shorthandMatchAddress ?? currencyId)

  const chainAllowed = chainId && isChainAllowed(connector, chainId)
  if (currencyId === null || currencyId === undefined || !chainAllowed) return null

  // this case so we use our builtin wrapped token instead of wrapped tokens on token lists
  const wrappedNative = nativeCurrency?.wrapped
  if (wrappedNative?.address?.toUpperCase() === currencyId?.toUpperCase()) return wrappedNative

  return isNative ? nativeCurrency : token
}
