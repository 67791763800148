import { api } from './slice';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
  /**
   * 8 bytes signed integer
   *
   */
  Int8: any;
  /**
   * A string representation of microseconds UNIX timestamp (16 digits)
   *
   */
  Timestamp: any;
};




export type Account = {
  __typename?: 'Account';
  /**  Address of the account  */
  id: Scalars['ID'];
};

export type Account_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Account_Filter>>>;
  or?: Maybe<Array<Maybe<Account_Filter>>>;
};

export enum Account_OrderBy {
  Id = 'id'
}

export type ActiveAccount = {
  __typename?: 'ActiveAccount';
  /**  { daily/hourly }-{ Address of the account }-{ Days/hours since Unix epoch }  */
  id: Scalars['ID'];
};

export type ActiveAccount_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<ActiveAccount_Filter>>>;
  or?: Maybe<Array<Maybe<ActiveAccount_Filter>>>;
};

export enum ActiveAccount_OrderBy {
  Id = 'id'
}

export enum Aggregation_Interval {
  Hour = 'hour',
  Day = 'day'
}



export type BlockChangedFilter = {
  number_gte: Scalars['Int'];
};

export type Block_Height = {
  hash?: Maybe<Scalars['Bytes']>;
  number?: Maybe<Scalars['Int']>;
  number_gte?: Maybe<Scalars['Int']>;
};

export type Bundle = {
  __typename?: 'Bundle';
  id: Scalars['ID'];
  ethPriceUSD: Scalars['BigDecimal'];
};

export type Bundle_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  ethPriceUSD?: Maybe<Scalars['BigDecimal']>;
  ethPriceUSD_not?: Maybe<Scalars['BigDecimal']>;
  ethPriceUSD_gt?: Maybe<Scalars['BigDecimal']>;
  ethPriceUSD_lt?: Maybe<Scalars['BigDecimal']>;
  ethPriceUSD_gte?: Maybe<Scalars['BigDecimal']>;
  ethPriceUSD_lte?: Maybe<Scalars['BigDecimal']>;
  ethPriceUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  ethPriceUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Bundle_Filter>>>;
  or?: Maybe<Array<Maybe<Bundle_Filter>>>;
};

export enum Bundle_OrderBy {
  Id = 'id',
  EthPriceUsd = 'ethPriceUSD'
}

export type Burn = {
  __typename?: 'Burn';
  id: Scalars['ID'];
  transaction: Transaction;
  pool: Pool;
  token0: Token;
  token1: Token;
  timestamp: Scalars['BigInt'];
  owner?: Maybe<Scalars['Bytes']>;
  origin: Scalars['Bytes'];
  amount: Scalars['BigInt'];
  amount0: Scalars['BigDecimal'];
  amount1: Scalars['BigDecimal'];
  amountUSD?: Maybe<Scalars['BigDecimal']>;
  tickLower: Scalars['BigInt'];
  tickUpper: Scalars['BigInt'];
  logIndex?: Maybe<Scalars['BigInt']>;
};

export type Burn_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  transaction?: Maybe<Scalars['String']>;
  transaction_not?: Maybe<Scalars['String']>;
  transaction_gt?: Maybe<Scalars['String']>;
  transaction_lt?: Maybe<Scalars['String']>;
  transaction_gte?: Maybe<Scalars['String']>;
  transaction_lte?: Maybe<Scalars['String']>;
  transaction_in?: Maybe<Array<Scalars['String']>>;
  transaction_not_in?: Maybe<Array<Scalars['String']>>;
  transaction_contains?: Maybe<Scalars['String']>;
  transaction_contains_nocase?: Maybe<Scalars['String']>;
  transaction_not_contains?: Maybe<Scalars['String']>;
  transaction_not_contains_nocase?: Maybe<Scalars['String']>;
  transaction_starts_with?: Maybe<Scalars['String']>;
  transaction_starts_with_nocase?: Maybe<Scalars['String']>;
  transaction_not_starts_with?: Maybe<Scalars['String']>;
  transaction_not_starts_with_nocase?: Maybe<Scalars['String']>;
  transaction_ends_with?: Maybe<Scalars['String']>;
  transaction_ends_with_nocase?: Maybe<Scalars['String']>;
  transaction_not_ends_with?: Maybe<Scalars['String']>;
  transaction_not_ends_with_nocase?: Maybe<Scalars['String']>;
  transaction_?: Maybe<Transaction_Filter>;
  pool?: Maybe<Scalars['String']>;
  pool_not?: Maybe<Scalars['String']>;
  pool_gt?: Maybe<Scalars['String']>;
  pool_lt?: Maybe<Scalars['String']>;
  pool_gte?: Maybe<Scalars['String']>;
  pool_lte?: Maybe<Scalars['String']>;
  pool_in?: Maybe<Array<Scalars['String']>>;
  pool_not_in?: Maybe<Array<Scalars['String']>>;
  pool_contains?: Maybe<Scalars['String']>;
  pool_contains_nocase?: Maybe<Scalars['String']>;
  pool_not_contains?: Maybe<Scalars['String']>;
  pool_not_contains_nocase?: Maybe<Scalars['String']>;
  pool_starts_with?: Maybe<Scalars['String']>;
  pool_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_not_starts_with?: Maybe<Scalars['String']>;
  pool_not_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_ends_with?: Maybe<Scalars['String']>;
  pool_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_not_ends_with?: Maybe<Scalars['String']>;
  pool_not_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_?: Maybe<Pool_Filter>;
  token0?: Maybe<Scalars['String']>;
  token0_not?: Maybe<Scalars['String']>;
  token0_gt?: Maybe<Scalars['String']>;
  token0_lt?: Maybe<Scalars['String']>;
  token0_gte?: Maybe<Scalars['String']>;
  token0_lte?: Maybe<Scalars['String']>;
  token0_in?: Maybe<Array<Scalars['String']>>;
  token0_not_in?: Maybe<Array<Scalars['String']>>;
  token0_contains?: Maybe<Scalars['String']>;
  token0_contains_nocase?: Maybe<Scalars['String']>;
  token0_not_contains?: Maybe<Scalars['String']>;
  token0_not_contains_nocase?: Maybe<Scalars['String']>;
  token0_starts_with?: Maybe<Scalars['String']>;
  token0_starts_with_nocase?: Maybe<Scalars['String']>;
  token0_not_starts_with?: Maybe<Scalars['String']>;
  token0_not_starts_with_nocase?: Maybe<Scalars['String']>;
  token0_ends_with?: Maybe<Scalars['String']>;
  token0_ends_with_nocase?: Maybe<Scalars['String']>;
  token0_not_ends_with?: Maybe<Scalars['String']>;
  token0_not_ends_with_nocase?: Maybe<Scalars['String']>;
  token0_?: Maybe<Token_Filter>;
  token1?: Maybe<Scalars['String']>;
  token1_not?: Maybe<Scalars['String']>;
  token1_gt?: Maybe<Scalars['String']>;
  token1_lt?: Maybe<Scalars['String']>;
  token1_gte?: Maybe<Scalars['String']>;
  token1_lte?: Maybe<Scalars['String']>;
  token1_in?: Maybe<Array<Scalars['String']>>;
  token1_not_in?: Maybe<Array<Scalars['String']>>;
  token1_contains?: Maybe<Scalars['String']>;
  token1_contains_nocase?: Maybe<Scalars['String']>;
  token1_not_contains?: Maybe<Scalars['String']>;
  token1_not_contains_nocase?: Maybe<Scalars['String']>;
  token1_starts_with?: Maybe<Scalars['String']>;
  token1_starts_with_nocase?: Maybe<Scalars['String']>;
  token1_not_starts_with?: Maybe<Scalars['String']>;
  token1_not_starts_with_nocase?: Maybe<Scalars['String']>;
  token1_ends_with?: Maybe<Scalars['String']>;
  token1_ends_with_nocase?: Maybe<Scalars['String']>;
  token1_not_ends_with?: Maybe<Scalars['String']>;
  token1_not_ends_with_nocase?: Maybe<Scalars['String']>;
  token1_?: Maybe<Token_Filter>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  owner?: Maybe<Scalars['Bytes']>;
  owner_not?: Maybe<Scalars['Bytes']>;
  owner_gt?: Maybe<Scalars['Bytes']>;
  owner_lt?: Maybe<Scalars['Bytes']>;
  owner_gte?: Maybe<Scalars['Bytes']>;
  owner_lte?: Maybe<Scalars['Bytes']>;
  owner_in?: Maybe<Array<Scalars['Bytes']>>;
  owner_not_in?: Maybe<Array<Scalars['Bytes']>>;
  owner_contains?: Maybe<Scalars['Bytes']>;
  owner_not_contains?: Maybe<Scalars['Bytes']>;
  origin?: Maybe<Scalars['Bytes']>;
  origin_not?: Maybe<Scalars['Bytes']>;
  origin_gt?: Maybe<Scalars['Bytes']>;
  origin_lt?: Maybe<Scalars['Bytes']>;
  origin_gte?: Maybe<Scalars['Bytes']>;
  origin_lte?: Maybe<Scalars['Bytes']>;
  origin_in?: Maybe<Array<Scalars['Bytes']>>;
  origin_not_in?: Maybe<Array<Scalars['Bytes']>>;
  origin_contains?: Maybe<Scalars['Bytes']>;
  origin_not_contains?: Maybe<Scalars['Bytes']>;
  amount?: Maybe<Scalars['BigInt']>;
  amount_not?: Maybe<Scalars['BigInt']>;
  amount_gt?: Maybe<Scalars['BigInt']>;
  amount_lt?: Maybe<Scalars['BigInt']>;
  amount_gte?: Maybe<Scalars['BigInt']>;
  amount_lte?: Maybe<Scalars['BigInt']>;
  amount_in?: Maybe<Array<Scalars['BigInt']>>;
  amount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  amount0?: Maybe<Scalars['BigDecimal']>;
  amount0_not?: Maybe<Scalars['BigDecimal']>;
  amount0_gt?: Maybe<Scalars['BigDecimal']>;
  amount0_lt?: Maybe<Scalars['BigDecimal']>;
  amount0_gte?: Maybe<Scalars['BigDecimal']>;
  amount0_lte?: Maybe<Scalars['BigDecimal']>;
  amount0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amount0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amount1?: Maybe<Scalars['BigDecimal']>;
  amount1_not?: Maybe<Scalars['BigDecimal']>;
  amount1_gt?: Maybe<Scalars['BigDecimal']>;
  amount1_lt?: Maybe<Scalars['BigDecimal']>;
  amount1_gte?: Maybe<Scalars['BigDecimal']>;
  amount1_lte?: Maybe<Scalars['BigDecimal']>;
  amount1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amount1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amountUSD?: Maybe<Scalars['BigDecimal']>;
  amountUSD_not?: Maybe<Scalars['BigDecimal']>;
  amountUSD_gt?: Maybe<Scalars['BigDecimal']>;
  amountUSD_lt?: Maybe<Scalars['BigDecimal']>;
  amountUSD_gte?: Maybe<Scalars['BigDecimal']>;
  amountUSD_lte?: Maybe<Scalars['BigDecimal']>;
  amountUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amountUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  tickLower?: Maybe<Scalars['BigInt']>;
  tickLower_not?: Maybe<Scalars['BigInt']>;
  tickLower_gt?: Maybe<Scalars['BigInt']>;
  tickLower_lt?: Maybe<Scalars['BigInt']>;
  tickLower_gte?: Maybe<Scalars['BigInt']>;
  tickLower_lte?: Maybe<Scalars['BigInt']>;
  tickLower_in?: Maybe<Array<Scalars['BigInt']>>;
  tickLower_not_in?: Maybe<Array<Scalars['BigInt']>>;
  tickUpper?: Maybe<Scalars['BigInt']>;
  tickUpper_not?: Maybe<Scalars['BigInt']>;
  tickUpper_gt?: Maybe<Scalars['BigInt']>;
  tickUpper_lt?: Maybe<Scalars['BigInt']>;
  tickUpper_gte?: Maybe<Scalars['BigInt']>;
  tickUpper_lte?: Maybe<Scalars['BigInt']>;
  tickUpper_in?: Maybe<Array<Scalars['BigInt']>>;
  tickUpper_not_in?: Maybe<Array<Scalars['BigInt']>>;
  logIndex?: Maybe<Scalars['BigInt']>;
  logIndex_not?: Maybe<Scalars['BigInt']>;
  logIndex_gt?: Maybe<Scalars['BigInt']>;
  logIndex_lt?: Maybe<Scalars['BigInt']>;
  logIndex_gte?: Maybe<Scalars['BigInt']>;
  logIndex_lte?: Maybe<Scalars['BigInt']>;
  logIndex_in?: Maybe<Array<Scalars['BigInt']>>;
  logIndex_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Burn_Filter>>>;
  or?: Maybe<Array<Maybe<Burn_Filter>>>;
};

export enum Burn_OrderBy {
  Id = 'id',
  Transaction = 'transaction',
  TransactionId = 'transaction__id',
  TransactionBlockNumber = 'transaction__blockNumber',
  TransactionTimestamp = 'transaction__timestamp',
  TransactionGasUsed = 'transaction__gasUsed',
  TransactionGasPrice = 'transaction__gasPrice',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolCreatedAtTimestamp = 'pool__createdAtTimestamp',
  PoolCreatedAtBlockNumber = 'pool__createdAtBlockNumber',
  PoolFeeTier = 'pool__feeTier',
  PoolLiquidity = 'pool__liquidity',
  PoolSqrtPrice = 'pool__sqrtPrice',
  PoolFeeGrowthGlobal0X128 = 'pool__feeGrowthGlobal0X128',
  PoolFeeGrowthGlobal1X128 = 'pool__feeGrowthGlobal1X128',
  PoolToken0Price = 'pool__token0Price',
  PoolToken1Price = 'pool__token1Price',
  PoolTick = 'pool__tick',
  PoolObservationIndex = 'pool__observationIndex',
  PoolVolumeToken0 = 'pool__volumeToken0',
  PoolVolumeToken1 = 'pool__volumeToken1',
  PoolVolumeUsd = 'pool__volumeUSD',
  PoolUntrackedVolumeUsd = 'pool__untrackedVolumeUSD',
  PoolFeesUsd = 'pool__feesUSD',
  PoolTxCount = 'pool__txCount',
  PoolCollectedFeesToken0 = 'pool__collectedFeesToken0',
  PoolCollectedFeesToken1 = 'pool__collectedFeesToken1',
  PoolCollectedFeesUsd = 'pool__collectedFeesUSD',
  PoolTotalValueLockedToken0 = 'pool__totalValueLockedToken0',
  PoolTotalValueLockedToken1 = 'pool__totalValueLockedToken1',
  PoolTotalValueLockedEth = 'pool__totalValueLockedETH',
  PoolTotalValueLockedUsd = 'pool__totalValueLockedUSD',
  PoolTotalValueLockedUsdUntracked = 'pool__totalValueLockedUSDUntracked',
  PoolLiquidityProviderCount = 'pool__liquidityProviderCount',
  Token0 = 'token0',
  Token0Id = 'token0__id',
  Token0Symbol = 'token0__symbol',
  Token0Name = 'token0__name',
  Token0Decimals = 'token0__decimals',
  Token0TotalSupply = 'token0__totalSupply',
  Token0Volume = 'token0__volume',
  Token0VolumeUsd = 'token0__volumeUSD',
  Token0UntrackedVolumeUsd = 'token0__untrackedVolumeUSD',
  Token0FeesUsd = 'token0__feesUSD',
  Token0TxCount = 'token0__txCount',
  Token0PoolCount = 'token0__poolCount',
  Token0TotalValueLocked = 'token0__totalValueLocked',
  Token0TotalValueLockedUsd = 'token0__totalValueLockedUSD',
  Token0TotalValueLockedUsdUntracked = 'token0__totalValueLockedUSDUntracked',
  Token0DerivedEth = 'token0__derivedETH',
  Token1 = 'token1',
  Token1Id = 'token1__id',
  Token1Symbol = 'token1__symbol',
  Token1Name = 'token1__name',
  Token1Decimals = 'token1__decimals',
  Token1TotalSupply = 'token1__totalSupply',
  Token1Volume = 'token1__volume',
  Token1VolumeUsd = 'token1__volumeUSD',
  Token1UntrackedVolumeUsd = 'token1__untrackedVolumeUSD',
  Token1FeesUsd = 'token1__feesUSD',
  Token1TxCount = 'token1__txCount',
  Token1PoolCount = 'token1__poolCount',
  Token1TotalValueLocked = 'token1__totalValueLocked',
  Token1TotalValueLockedUsd = 'token1__totalValueLockedUSD',
  Token1TotalValueLockedUsdUntracked = 'token1__totalValueLockedUSDUntracked',
  Token1DerivedEth = 'token1__derivedETH',
  Timestamp = 'timestamp',
  Owner = 'owner',
  Origin = 'origin',
  Amount = 'amount',
  Amount0 = 'amount0',
  Amount1 = 'amount1',
  AmountUsd = 'amountUSD',
  TickLower = 'tickLower',
  TickUpper = 'tickUpper',
  LogIndex = 'logIndex'
}


export type Collect = {
  __typename?: 'Collect';
  id: Scalars['ID'];
  transaction: Transaction;
  timestamp: Scalars['BigInt'];
  pool: Pool;
  owner?: Maybe<Scalars['Bytes']>;
  amount0: Scalars['BigDecimal'];
  amount1: Scalars['BigDecimal'];
  amountUSD?: Maybe<Scalars['BigDecimal']>;
  tickLower: Scalars['BigInt'];
  tickUpper: Scalars['BigInt'];
  logIndex?: Maybe<Scalars['BigInt']>;
};

export type Collect_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  transaction?: Maybe<Scalars['String']>;
  transaction_not?: Maybe<Scalars['String']>;
  transaction_gt?: Maybe<Scalars['String']>;
  transaction_lt?: Maybe<Scalars['String']>;
  transaction_gte?: Maybe<Scalars['String']>;
  transaction_lte?: Maybe<Scalars['String']>;
  transaction_in?: Maybe<Array<Scalars['String']>>;
  transaction_not_in?: Maybe<Array<Scalars['String']>>;
  transaction_contains?: Maybe<Scalars['String']>;
  transaction_contains_nocase?: Maybe<Scalars['String']>;
  transaction_not_contains?: Maybe<Scalars['String']>;
  transaction_not_contains_nocase?: Maybe<Scalars['String']>;
  transaction_starts_with?: Maybe<Scalars['String']>;
  transaction_starts_with_nocase?: Maybe<Scalars['String']>;
  transaction_not_starts_with?: Maybe<Scalars['String']>;
  transaction_not_starts_with_nocase?: Maybe<Scalars['String']>;
  transaction_ends_with?: Maybe<Scalars['String']>;
  transaction_ends_with_nocase?: Maybe<Scalars['String']>;
  transaction_not_ends_with?: Maybe<Scalars['String']>;
  transaction_not_ends_with_nocase?: Maybe<Scalars['String']>;
  transaction_?: Maybe<Transaction_Filter>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  pool?: Maybe<Scalars['String']>;
  pool_not?: Maybe<Scalars['String']>;
  pool_gt?: Maybe<Scalars['String']>;
  pool_lt?: Maybe<Scalars['String']>;
  pool_gte?: Maybe<Scalars['String']>;
  pool_lte?: Maybe<Scalars['String']>;
  pool_in?: Maybe<Array<Scalars['String']>>;
  pool_not_in?: Maybe<Array<Scalars['String']>>;
  pool_contains?: Maybe<Scalars['String']>;
  pool_contains_nocase?: Maybe<Scalars['String']>;
  pool_not_contains?: Maybe<Scalars['String']>;
  pool_not_contains_nocase?: Maybe<Scalars['String']>;
  pool_starts_with?: Maybe<Scalars['String']>;
  pool_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_not_starts_with?: Maybe<Scalars['String']>;
  pool_not_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_ends_with?: Maybe<Scalars['String']>;
  pool_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_not_ends_with?: Maybe<Scalars['String']>;
  pool_not_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_?: Maybe<Pool_Filter>;
  owner?: Maybe<Scalars['Bytes']>;
  owner_not?: Maybe<Scalars['Bytes']>;
  owner_gt?: Maybe<Scalars['Bytes']>;
  owner_lt?: Maybe<Scalars['Bytes']>;
  owner_gte?: Maybe<Scalars['Bytes']>;
  owner_lte?: Maybe<Scalars['Bytes']>;
  owner_in?: Maybe<Array<Scalars['Bytes']>>;
  owner_not_in?: Maybe<Array<Scalars['Bytes']>>;
  owner_contains?: Maybe<Scalars['Bytes']>;
  owner_not_contains?: Maybe<Scalars['Bytes']>;
  amount0?: Maybe<Scalars['BigDecimal']>;
  amount0_not?: Maybe<Scalars['BigDecimal']>;
  amount0_gt?: Maybe<Scalars['BigDecimal']>;
  amount0_lt?: Maybe<Scalars['BigDecimal']>;
  amount0_gte?: Maybe<Scalars['BigDecimal']>;
  amount0_lte?: Maybe<Scalars['BigDecimal']>;
  amount0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amount0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amount1?: Maybe<Scalars['BigDecimal']>;
  amount1_not?: Maybe<Scalars['BigDecimal']>;
  amount1_gt?: Maybe<Scalars['BigDecimal']>;
  amount1_lt?: Maybe<Scalars['BigDecimal']>;
  amount1_gte?: Maybe<Scalars['BigDecimal']>;
  amount1_lte?: Maybe<Scalars['BigDecimal']>;
  amount1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amount1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amountUSD?: Maybe<Scalars['BigDecimal']>;
  amountUSD_not?: Maybe<Scalars['BigDecimal']>;
  amountUSD_gt?: Maybe<Scalars['BigDecimal']>;
  amountUSD_lt?: Maybe<Scalars['BigDecimal']>;
  amountUSD_gte?: Maybe<Scalars['BigDecimal']>;
  amountUSD_lte?: Maybe<Scalars['BigDecimal']>;
  amountUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amountUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  tickLower?: Maybe<Scalars['BigInt']>;
  tickLower_not?: Maybe<Scalars['BigInt']>;
  tickLower_gt?: Maybe<Scalars['BigInt']>;
  tickLower_lt?: Maybe<Scalars['BigInt']>;
  tickLower_gte?: Maybe<Scalars['BigInt']>;
  tickLower_lte?: Maybe<Scalars['BigInt']>;
  tickLower_in?: Maybe<Array<Scalars['BigInt']>>;
  tickLower_not_in?: Maybe<Array<Scalars['BigInt']>>;
  tickUpper?: Maybe<Scalars['BigInt']>;
  tickUpper_not?: Maybe<Scalars['BigInt']>;
  tickUpper_gt?: Maybe<Scalars['BigInt']>;
  tickUpper_lt?: Maybe<Scalars['BigInt']>;
  tickUpper_gte?: Maybe<Scalars['BigInt']>;
  tickUpper_lte?: Maybe<Scalars['BigInt']>;
  tickUpper_in?: Maybe<Array<Scalars['BigInt']>>;
  tickUpper_not_in?: Maybe<Array<Scalars['BigInt']>>;
  logIndex?: Maybe<Scalars['BigInt']>;
  logIndex_not?: Maybe<Scalars['BigInt']>;
  logIndex_gt?: Maybe<Scalars['BigInt']>;
  logIndex_lt?: Maybe<Scalars['BigInt']>;
  logIndex_gte?: Maybe<Scalars['BigInt']>;
  logIndex_lte?: Maybe<Scalars['BigInt']>;
  logIndex_in?: Maybe<Array<Scalars['BigInt']>>;
  logIndex_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Collect_Filter>>>;
  or?: Maybe<Array<Maybe<Collect_Filter>>>;
};

export enum Collect_OrderBy {
  Id = 'id',
  Transaction = 'transaction',
  TransactionId = 'transaction__id',
  TransactionBlockNumber = 'transaction__blockNumber',
  TransactionTimestamp = 'transaction__timestamp',
  TransactionGasUsed = 'transaction__gasUsed',
  TransactionGasPrice = 'transaction__gasPrice',
  Timestamp = 'timestamp',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolCreatedAtTimestamp = 'pool__createdAtTimestamp',
  PoolCreatedAtBlockNumber = 'pool__createdAtBlockNumber',
  PoolFeeTier = 'pool__feeTier',
  PoolLiquidity = 'pool__liquidity',
  PoolSqrtPrice = 'pool__sqrtPrice',
  PoolFeeGrowthGlobal0X128 = 'pool__feeGrowthGlobal0X128',
  PoolFeeGrowthGlobal1X128 = 'pool__feeGrowthGlobal1X128',
  PoolToken0Price = 'pool__token0Price',
  PoolToken1Price = 'pool__token1Price',
  PoolTick = 'pool__tick',
  PoolObservationIndex = 'pool__observationIndex',
  PoolVolumeToken0 = 'pool__volumeToken0',
  PoolVolumeToken1 = 'pool__volumeToken1',
  PoolVolumeUsd = 'pool__volumeUSD',
  PoolUntrackedVolumeUsd = 'pool__untrackedVolumeUSD',
  PoolFeesUsd = 'pool__feesUSD',
  PoolTxCount = 'pool__txCount',
  PoolCollectedFeesToken0 = 'pool__collectedFeesToken0',
  PoolCollectedFeesToken1 = 'pool__collectedFeesToken1',
  PoolCollectedFeesUsd = 'pool__collectedFeesUSD',
  PoolTotalValueLockedToken0 = 'pool__totalValueLockedToken0',
  PoolTotalValueLockedToken1 = 'pool__totalValueLockedToken1',
  PoolTotalValueLockedEth = 'pool__totalValueLockedETH',
  PoolTotalValueLockedUsd = 'pool__totalValueLockedUSD',
  PoolTotalValueLockedUsdUntracked = 'pool__totalValueLockedUSDUntracked',
  PoolLiquidityProviderCount = 'pool__liquidityProviderCount',
  Owner = 'owner',
  Amount0 = 'amount0',
  Amount1 = 'amount1',
  AmountUsd = 'amountUSD',
  TickLower = 'tickLower',
  TickUpper = 'tickUpper',
  LogIndex = 'logIndex'
}

/**
 * An event is any user action that occurs in a protocol. Generally, they are Ethereum events
 * emitted by a function in the smart contracts, stored in transaction receipts as event logs.
 * However, some user actions of interest are function calls that don't emit events. For example,
 * the deposit and withdraw functions in Yearn do not emit any events. In our subgraphs, we still
 * store them as events, although they are not technically Ethereum events emitted by smart
 * contracts.
 */
export type Event = {
  /**  { Transaction hash }-{ Log index }  */
  id: Scalars['ID'];
  /**  Transaction hash of the transaction that emitted this event  */
  hash: Scalars['String'];
  /**  Event log index. For transactions that don't emit event, create arbitrary index starting from 0  */
  logIndex: Scalars['Int'];
  /**  The protocol this transaction belongs to  */
  protocol: YieldAggregator;
  /**  Address that received tokens  */
  to: Scalars['String'];
  /**  Address that sent tokens  */
  from: Scalars['String'];
  /**  Block number of this event  */
  blockNumber: Scalars['BigInt'];
  /**  Timestamp of this event  */
  timestamp: Scalars['BigInt'];
};

export type Event_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  hash?: Maybe<Scalars['String']>;
  hash_not?: Maybe<Scalars['String']>;
  hash_gt?: Maybe<Scalars['String']>;
  hash_lt?: Maybe<Scalars['String']>;
  hash_gte?: Maybe<Scalars['String']>;
  hash_lte?: Maybe<Scalars['String']>;
  hash_in?: Maybe<Array<Scalars['String']>>;
  hash_not_in?: Maybe<Array<Scalars['String']>>;
  hash_contains?: Maybe<Scalars['String']>;
  hash_contains_nocase?: Maybe<Scalars['String']>;
  hash_not_contains?: Maybe<Scalars['String']>;
  hash_not_contains_nocase?: Maybe<Scalars['String']>;
  hash_starts_with?: Maybe<Scalars['String']>;
  hash_starts_with_nocase?: Maybe<Scalars['String']>;
  hash_not_starts_with?: Maybe<Scalars['String']>;
  hash_not_starts_with_nocase?: Maybe<Scalars['String']>;
  hash_ends_with?: Maybe<Scalars['String']>;
  hash_ends_with_nocase?: Maybe<Scalars['String']>;
  hash_not_ends_with?: Maybe<Scalars['String']>;
  hash_not_ends_with_nocase?: Maybe<Scalars['String']>;
  logIndex?: Maybe<Scalars['Int']>;
  logIndex_not?: Maybe<Scalars['Int']>;
  logIndex_gt?: Maybe<Scalars['Int']>;
  logIndex_lt?: Maybe<Scalars['Int']>;
  logIndex_gte?: Maybe<Scalars['Int']>;
  logIndex_lte?: Maybe<Scalars['Int']>;
  logIndex_in?: Maybe<Array<Scalars['Int']>>;
  logIndex_not_in?: Maybe<Array<Scalars['Int']>>;
  protocol?: Maybe<Scalars['String']>;
  protocol_not?: Maybe<Scalars['String']>;
  protocol_gt?: Maybe<Scalars['String']>;
  protocol_lt?: Maybe<Scalars['String']>;
  protocol_gte?: Maybe<Scalars['String']>;
  protocol_lte?: Maybe<Scalars['String']>;
  protocol_in?: Maybe<Array<Scalars['String']>>;
  protocol_not_in?: Maybe<Array<Scalars['String']>>;
  protocol_contains?: Maybe<Scalars['String']>;
  protocol_contains_nocase?: Maybe<Scalars['String']>;
  protocol_not_contains?: Maybe<Scalars['String']>;
  protocol_not_contains_nocase?: Maybe<Scalars['String']>;
  protocol_starts_with?: Maybe<Scalars['String']>;
  protocol_starts_with_nocase?: Maybe<Scalars['String']>;
  protocol_not_starts_with?: Maybe<Scalars['String']>;
  protocol_not_starts_with_nocase?: Maybe<Scalars['String']>;
  protocol_ends_with?: Maybe<Scalars['String']>;
  protocol_ends_with_nocase?: Maybe<Scalars['String']>;
  protocol_not_ends_with?: Maybe<Scalars['String']>;
  protocol_not_ends_with_nocase?: Maybe<Scalars['String']>;
  protocol_?: Maybe<YieldAggregator_Filter>;
  to?: Maybe<Scalars['String']>;
  to_not?: Maybe<Scalars['String']>;
  to_gt?: Maybe<Scalars['String']>;
  to_lt?: Maybe<Scalars['String']>;
  to_gte?: Maybe<Scalars['String']>;
  to_lte?: Maybe<Scalars['String']>;
  to_in?: Maybe<Array<Scalars['String']>>;
  to_not_in?: Maybe<Array<Scalars['String']>>;
  to_contains?: Maybe<Scalars['String']>;
  to_contains_nocase?: Maybe<Scalars['String']>;
  to_not_contains?: Maybe<Scalars['String']>;
  to_not_contains_nocase?: Maybe<Scalars['String']>;
  to_starts_with?: Maybe<Scalars['String']>;
  to_starts_with_nocase?: Maybe<Scalars['String']>;
  to_not_starts_with?: Maybe<Scalars['String']>;
  to_not_starts_with_nocase?: Maybe<Scalars['String']>;
  to_ends_with?: Maybe<Scalars['String']>;
  to_ends_with_nocase?: Maybe<Scalars['String']>;
  to_not_ends_with?: Maybe<Scalars['String']>;
  to_not_ends_with_nocase?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  from_not?: Maybe<Scalars['String']>;
  from_gt?: Maybe<Scalars['String']>;
  from_lt?: Maybe<Scalars['String']>;
  from_gte?: Maybe<Scalars['String']>;
  from_lte?: Maybe<Scalars['String']>;
  from_in?: Maybe<Array<Scalars['String']>>;
  from_not_in?: Maybe<Array<Scalars['String']>>;
  from_contains?: Maybe<Scalars['String']>;
  from_contains_nocase?: Maybe<Scalars['String']>;
  from_not_contains?: Maybe<Scalars['String']>;
  from_not_contains_nocase?: Maybe<Scalars['String']>;
  from_starts_with?: Maybe<Scalars['String']>;
  from_starts_with_nocase?: Maybe<Scalars['String']>;
  from_not_starts_with?: Maybe<Scalars['String']>;
  from_not_starts_with_nocase?: Maybe<Scalars['String']>;
  from_ends_with?: Maybe<Scalars['String']>;
  from_ends_with_nocase?: Maybe<Scalars['String']>;
  from_not_ends_with?: Maybe<Scalars['String']>;
  from_not_ends_with_nocase?: Maybe<Scalars['String']>;
  blockNumber?: Maybe<Scalars['BigInt']>;
  blockNumber_not?: Maybe<Scalars['BigInt']>;
  blockNumber_gt?: Maybe<Scalars['BigInt']>;
  blockNumber_lt?: Maybe<Scalars['BigInt']>;
  blockNumber_gte?: Maybe<Scalars['BigInt']>;
  blockNumber_lte?: Maybe<Scalars['BigInt']>;
  blockNumber_in?: Maybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Event_Filter>>>;
  or?: Maybe<Array<Maybe<Event_Filter>>>;
};

export enum Event_OrderBy {
  Id = 'id',
  Hash = 'hash',
  LogIndex = 'logIndex',
  Protocol = 'protocol',
  ProtocolId = 'protocol__id',
  ProtocolName = 'protocol__name',
  ProtocolSlug = 'protocol__slug',
  ProtocolSchemaVersion = 'protocol__schemaVersion',
  ProtocolSubgraphVersion = 'protocol__subgraphVersion',
  ProtocolMethodologyVersion = 'protocol__methodologyVersion',
  ProtocolNetwork = 'protocol__network',
  ProtocolType = 'protocol__type',
  ProtocolTotalValueLockedUsd = 'protocol__totalValueLockedUSD',
  ProtocolProtocolControlledValueUsd = 'protocol__protocolControlledValueUSD',
  ProtocolCumulativeSupplySideRevenueUsd = 'protocol__cumulativeSupplySideRevenueUSD',
  ProtocolCumulativeProtocolSideRevenueUsd = 'protocol__cumulativeProtocolSideRevenueUSD',
  ProtocolCumulativeTotalRevenueUsd = 'protocol__cumulativeTotalRevenueUSD',
  ProtocolCumulativeUniqueUsers = 'protocol__cumulativeUniqueUsers',
  ProtocolTotalPoolCount = 'protocol__totalPoolCount',
  To = 'to',
  From = 'from',
  BlockNumber = 'blockNumber',
  Timestamp = 'timestamp'
}

export type Factory = {
  __typename?: 'Factory';
  id: Scalars['ID'];
  poolCount: Scalars['BigInt'];
  txCount: Scalars['BigInt'];
  totalVolumeUSD: Scalars['BigDecimal'];
  totalVolumeETH: Scalars['BigDecimal'];
  totalFeesUSD: Scalars['BigDecimal'];
  totalFeesETH: Scalars['BigDecimal'];
  untrackedVolumeUSD: Scalars['BigDecimal'];
  totalValueLockedUSD: Scalars['BigDecimal'];
  totalValueLockedETH: Scalars['BigDecimal'];
  totalValueLockedUSDUntracked: Scalars['BigDecimal'];
  totalValueLockedETHUntracked: Scalars['BigDecimal'];
  owner: Scalars['ID'];
};

export type Factory_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  poolCount?: Maybe<Scalars['BigInt']>;
  poolCount_not?: Maybe<Scalars['BigInt']>;
  poolCount_gt?: Maybe<Scalars['BigInt']>;
  poolCount_lt?: Maybe<Scalars['BigInt']>;
  poolCount_gte?: Maybe<Scalars['BigInt']>;
  poolCount_lte?: Maybe<Scalars['BigInt']>;
  poolCount_in?: Maybe<Array<Scalars['BigInt']>>;
  poolCount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  txCount?: Maybe<Scalars['BigInt']>;
  txCount_not?: Maybe<Scalars['BigInt']>;
  txCount_gt?: Maybe<Scalars['BigInt']>;
  txCount_lt?: Maybe<Scalars['BigInt']>;
  txCount_gte?: Maybe<Scalars['BigInt']>;
  txCount_lte?: Maybe<Scalars['BigInt']>;
  txCount_in?: Maybe<Array<Scalars['BigInt']>>;
  txCount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  totalVolumeUSD?: Maybe<Scalars['BigDecimal']>;
  totalVolumeUSD_not?: Maybe<Scalars['BigDecimal']>;
  totalVolumeUSD_gt?: Maybe<Scalars['BigDecimal']>;
  totalVolumeUSD_lt?: Maybe<Scalars['BigDecimal']>;
  totalVolumeUSD_gte?: Maybe<Scalars['BigDecimal']>;
  totalVolumeUSD_lte?: Maybe<Scalars['BigDecimal']>;
  totalVolumeUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalVolumeUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalVolumeETH?: Maybe<Scalars['BigDecimal']>;
  totalVolumeETH_not?: Maybe<Scalars['BigDecimal']>;
  totalVolumeETH_gt?: Maybe<Scalars['BigDecimal']>;
  totalVolumeETH_lt?: Maybe<Scalars['BigDecimal']>;
  totalVolumeETH_gte?: Maybe<Scalars['BigDecimal']>;
  totalVolumeETH_lte?: Maybe<Scalars['BigDecimal']>;
  totalVolumeETH_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalVolumeETH_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalFeesUSD?: Maybe<Scalars['BigDecimal']>;
  totalFeesUSD_not?: Maybe<Scalars['BigDecimal']>;
  totalFeesUSD_gt?: Maybe<Scalars['BigDecimal']>;
  totalFeesUSD_lt?: Maybe<Scalars['BigDecimal']>;
  totalFeesUSD_gte?: Maybe<Scalars['BigDecimal']>;
  totalFeesUSD_lte?: Maybe<Scalars['BigDecimal']>;
  totalFeesUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalFeesUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalFeesETH?: Maybe<Scalars['BigDecimal']>;
  totalFeesETH_not?: Maybe<Scalars['BigDecimal']>;
  totalFeesETH_gt?: Maybe<Scalars['BigDecimal']>;
  totalFeesETH_lt?: Maybe<Scalars['BigDecimal']>;
  totalFeesETH_gte?: Maybe<Scalars['BigDecimal']>;
  totalFeesETH_lte?: Maybe<Scalars['BigDecimal']>;
  totalFeesETH_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalFeesETH_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  untrackedVolumeUSD?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_not?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_gt?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_lt?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_gte?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_lte?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  untrackedVolumeUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedUSD?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_not?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_gt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_lt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_gte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_lte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedETH?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedETH_not?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedETH_gt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedETH_lt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedETH_gte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedETH_lte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedETH_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedETH_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedUSDUntracked?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSDUntracked_not?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSDUntracked_gt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSDUntracked_lt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSDUntracked_gte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSDUntracked_lte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSDUntracked_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedUSDUntracked_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedETHUntracked?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedETHUntracked_not?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedETHUntracked_gt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedETHUntracked_lt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedETHUntracked_gte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedETHUntracked_lte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedETHUntracked_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedETHUntracked_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  owner?: Maybe<Scalars['ID']>;
  owner_not?: Maybe<Scalars['ID']>;
  owner_gt?: Maybe<Scalars['ID']>;
  owner_lt?: Maybe<Scalars['ID']>;
  owner_gte?: Maybe<Scalars['ID']>;
  owner_lte?: Maybe<Scalars['ID']>;
  owner_in?: Maybe<Array<Scalars['ID']>>;
  owner_not_in?: Maybe<Array<Scalars['ID']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Factory_Filter>>>;
  or?: Maybe<Array<Maybe<Factory_Filter>>>;
};

export enum Factory_OrderBy {
  Id = 'id',
  PoolCount = 'poolCount',
  TxCount = 'txCount',
  TotalVolumeUsd = 'totalVolumeUSD',
  TotalVolumeEth = 'totalVolumeETH',
  TotalFeesUsd = 'totalFeesUSD',
  TotalFeesEth = 'totalFeesETH',
  UntrackedVolumeUsd = 'untrackedVolumeUSD',
  TotalValueLockedUsd = 'totalValueLockedUSD',
  TotalValueLockedEth = 'totalValueLockedETH',
  TotalValueLockedUsdUntracked = 'totalValueLockedUSDUntracked',
  TotalValueLockedEthUntracked = 'totalValueLockedETHUntracked',
  Owner = 'owner'
}

export type Flash = {
  __typename?: 'Flash';
  id: Scalars['ID'];
  transaction: Transaction;
  timestamp: Scalars['BigInt'];
  pool: Pool;
  sender: Scalars['Bytes'];
  recipient: Scalars['Bytes'];
  amount0: Scalars['BigDecimal'];
  amount1: Scalars['BigDecimal'];
  amountUSD: Scalars['BigDecimal'];
  amount0Paid: Scalars['BigDecimal'];
  amount1Paid: Scalars['BigDecimal'];
  logIndex?: Maybe<Scalars['BigInt']>;
};

export type Flash_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  transaction?: Maybe<Scalars['String']>;
  transaction_not?: Maybe<Scalars['String']>;
  transaction_gt?: Maybe<Scalars['String']>;
  transaction_lt?: Maybe<Scalars['String']>;
  transaction_gte?: Maybe<Scalars['String']>;
  transaction_lte?: Maybe<Scalars['String']>;
  transaction_in?: Maybe<Array<Scalars['String']>>;
  transaction_not_in?: Maybe<Array<Scalars['String']>>;
  transaction_contains?: Maybe<Scalars['String']>;
  transaction_contains_nocase?: Maybe<Scalars['String']>;
  transaction_not_contains?: Maybe<Scalars['String']>;
  transaction_not_contains_nocase?: Maybe<Scalars['String']>;
  transaction_starts_with?: Maybe<Scalars['String']>;
  transaction_starts_with_nocase?: Maybe<Scalars['String']>;
  transaction_not_starts_with?: Maybe<Scalars['String']>;
  transaction_not_starts_with_nocase?: Maybe<Scalars['String']>;
  transaction_ends_with?: Maybe<Scalars['String']>;
  transaction_ends_with_nocase?: Maybe<Scalars['String']>;
  transaction_not_ends_with?: Maybe<Scalars['String']>;
  transaction_not_ends_with_nocase?: Maybe<Scalars['String']>;
  transaction_?: Maybe<Transaction_Filter>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  pool?: Maybe<Scalars['String']>;
  pool_not?: Maybe<Scalars['String']>;
  pool_gt?: Maybe<Scalars['String']>;
  pool_lt?: Maybe<Scalars['String']>;
  pool_gte?: Maybe<Scalars['String']>;
  pool_lte?: Maybe<Scalars['String']>;
  pool_in?: Maybe<Array<Scalars['String']>>;
  pool_not_in?: Maybe<Array<Scalars['String']>>;
  pool_contains?: Maybe<Scalars['String']>;
  pool_contains_nocase?: Maybe<Scalars['String']>;
  pool_not_contains?: Maybe<Scalars['String']>;
  pool_not_contains_nocase?: Maybe<Scalars['String']>;
  pool_starts_with?: Maybe<Scalars['String']>;
  pool_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_not_starts_with?: Maybe<Scalars['String']>;
  pool_not_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_ends_with?: Maybe<Scalars['String']>;
  pool_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_not_ends_with?: Maybe<Scalars['String']>;
  pool_not_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_?: Maybe<Pool_Filter>;
  sender?: Maybe<Scalars['Bytes']>;
  sender_not?: Maybe<Scalars['Bytes']>;
  sender_gt?: Maybe<Scalars['Bytes']>;
  sender_lt?: Maybe<Scalars['Bytes']>;
  sender_gte?: Maybe<Scalars['Bytes']>;
  sender_lte?: Maybe<Scalars['Bytes']>;
  sender_in?: Maybe<Array<Scalars['Bytes']>>;
  sender_not_in?: Maybe<Array<Scalars['Bytes']>>;
  sender_contains?: Maybe<Scalars['Bytes']>;
  sender_not_contains?: Maybe<Scalars['Bytes']>;
  recipient?: Maybe<Scalars['Bytes']>;
  recipient_not?: Maybe<Scalars['Bytes']>;
  recipient_gt?: Maybe<Scalars['Bytes']>;
  recipient_lt?: Maybe<Scalars['Bytes']>;
  recipient_gte?: Maybe<Scalars['Bytes']>;
  recipient_lte?: Maybe<Scalars['Bytes']>;
  recipient_in?: Maybe<Array<Scalars['Bytes']>>;
  recipient_not_in?: Maybe<Array<Scalars['Bytes']>>;
  recipient_contains?: Maybe<Scalars['Bytes']>;
  recipient_not_contains?: Maybe<Scalars['Bytes']>;
  amount0?: Maybe<Scalars['BigDecimal']>;
  amount0_not?: Maybe<Scalars['BigDecimal']>;
  amount0_gt?: Maybe<Scalars['BigDecimal']>;
  amount0_lt?: Maybe<Scalars['BigDecimal']>;
  amount0_gte?: Maybe<Scalars['BigDecimal']>;
  amount0_lte?: Maybe<Scalars['BigDecimal']>;
  amount0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amount0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amount1?: Maybe<Scalars['BigDecimal']>;
  amount1_not?: Maybe<Scalars['BigDecimal']>;
  amount1_gt?: Maybe<Scalars['BigDecimal']>;
  amount1_lt?: Maybe<Scalars['BigDecimal']>;
  amount1_gte?: Maybe<Scalars['BigDecimal']>;
  amount1_lte?: Maybe<Scalars['BigDecimal']>;
  amount1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amount1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amountUSD?: Maybe<Scalars['BigDecimal']>;
  amountUSD_not?: Maybe<Scalars['BigDecimal']>;
  amountUSD_gt?: Maybe<Scalars['BigDecimal']>;
  amountUSD_lt?: Maybe<Scalars['BigDecimal']>;
  amountUSD_gte?: Maybe<Scalars['BigDecimal']>;
  amountUSD_lte?: Maybe<Scalars['BigDecimal']>;
  amountUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amountUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amount0Paid?: Maybe<Scalars['BigDecimal']>;
  amount0Paid_not?: Maybe<Scalars['BigDecimal']>;
  amount0Paid_gt?: Maybe<Scalars['BigDecimal']>;
  amount0Paid_lt?: Maybe<Scalars['BigDecimal']>;
  amount0Paid_gte?: Maybe<Scalars['BigDecimal']>;
  amount0Paid_lte?: Maybe<Scalars['BigDecimal']>;
  amount0Paid_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amount0Paid_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amount1Paid?: Maybe<Scalars['BigDecimal']>;
  amount1Paid_not?: Maybe<Scalars['BigDecimal']>;
  amount1Paid_gt?: Maybe<Scalars['BigDecimal']>;
  amount1Paid_lt?: Maybe<Scalars['BigDecimal']>;
  amount1Paid_gte?: Maybe<Scalars['BigDecimal']>;
  amount1Paid_lte?: Maybe<Scalars['BigDecimal']>;
  amount1Paid_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amount1Paid_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  logIndex?: Maybe<Scalars['BigInt']>;
  logIndex_not?: Maybe<Scalars['BigInt']>;
  logIndex_gt?: Maybe<Scalars['BigInt']>;
  logIndex_lt?: Maybe<Scalars['BigInt']>;
  logIndex_gte?: Maybe<Scalars['BigInt']>;
  logIndex_lte?: Maybe<Scalars['BigInt']>;
  logIndex_in?: Maybe<Array<Scalars['BigInt']>>;
  logIndex_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Flash_Filter>>>;
  or?: Maybe<Array<Maybe<Flash_Filter>>>;
};

export enum Flash_OrderBy {
  Id = 'id',
  Transaction = 'transaction',
  TransactionId = 'transaction__id',
  TransactionBlockNumber = 'transaction__blockNumber',
  TransactionTimestamp = 'transaction__timestamp',
  TransactionGasUsed = 'transaction__gasUsed',
  TransactionGasPrice = 'transaction__gasPrice',
  Timestamp = 'timestamp',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolCreatedAtTimestamp = 'pool__createdAtTimestamp',
  PoolCreatedAtBlockNumber = 'pool__createdAtBlockNumber',
  PoolFeeTier = 'pool__feeTier',
  PoolLiquidity = 'pool__liquidity',
  PoolSqrtPrice = 'pool__sqrtPrice',
  PoolFeeGrowthGlobal0X128 = 'pool__feeGrowthGlobal0X128',
  PoolFeeGrowthGlobal1X128 = 'pool__feeGrowthGlobal1X128',
  PoolToken0Price = 'pool__token0Price',
  PoolToken1Price = 'pool__token1Price',
  PoolTick = 'pool__tick',
  PoolObservationIndex = 'pool__observationIndex',
  PoolVolumeToken0 = 'pool__volumeToken0',
  PoolVolumeToken1 = 'pool__volumeToken1',
  PoolVolumeUsd = 'pool__volumeUSD',
  PoolUntrackedVolumeUsd = 'pool__untrackedVolumeUSD',
  PoolFeesUsd = 'pool__feesUSD',
  PoolTxCount = 'pool__txCount',
  PoolCollectedFeesToken0 = 'pool__collectedFeesToken0',
  PoolCollectedFeesToken1 = 'pool__collectedFeesToken1',
  PoolCollectedFeesUsd = 'pool__collectedFeesUSD',
  PoolTotalValueLockedToken0 = 'pool__totalValueLockedToken0',
  PoolTotalValueLockedToken1 = 'pool__totalValueLockedToken1',
  PoolTotalValueLockedEth = 'pool__totalValueLockedETH',
  PoolTotalValueLockedUsd = 'pool__totalValueLockedUSD',
  PoolTotalValueLockedUsdUntracked = 'pool__totalValueLockedUSDUntracked',
  PoolLiquidityProviderCount = 'pool__liquidityProviderCount',
  Sender = 'sender',
  Recipient = 'recipient',
  Amount0 = 'amount0',
  Amount1 = 'amount1',
  AmountUsd = 'amountUSD',
  Amount0Paid = 'amount0Paid',
  Amount1Paid = 'amount1Paid',
  LogIndex = 'logIndex'
}


export type KodiakApr = {
  __typename?: 'KodiakApr';
  id: Scalars['ID'];
  averageApr: Scalars['BigDecimal'];
  timestamp: Scalars['BigInt'];
};

export type KodiakAprAccumulated = {
  __typename?: 'KodiakAprAccumulated';
  id: Scalars['ID'];
  vault: KodiakVault;
  accumulated: Scalars['BigDecimal'];
  count: Scalars['BigInt'];
  lastSnapshot?: Maybe<KodiakVaultDailySnapshot>;
};

export type KodiakAprAccumulated_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  vault?: Maybe<Scalars['String']>;
  vault_not?: Maybe<Scalars['String']>;
  vault_gt?: Maybe<Scalars['String']>;
  vault_lt?: Maybe<Scalars['String']>;
  vault_gte?: Maybe<Scalars['String']>;
  vault_lte?: Maybe<Scalars['String']>;
  vault_in?: Maybe<Array<Scalars['String']>>;
  vault_not_in?: Maybe<Array<Scalars['String']>>;
  vault_contains?: Maybe<Scalars['String']>;
  vault_contains_nocase?: Maybe<Scalars['String']>;
  vault_not_contains?: Maybe<Scalars['String']>;
  vault_not_contains_nocase?: Maybe<Scalars['String']>;
  vault_starts_with?: Maybe<Scalars['String']>;
  vault_starts_with_nocase?: Maybe<Scalars['String']>;
  vault_not_starts_with?: Maybe<Scalars['String']>;
  vault_not_starts_with_nocase?: Maybe<Scalars['String']>;
  vault_ends_with?: Maybe<Scalars['String']>;
  vault_ends_with_nocase?: Maybe<Scalars['String']>;
  vault_not_ends_with?: Maybe<Scalars['String']>;
  vault_not_ends_with_nocase?: Maybe<Scalars['String']>;
  vault_?: Maybe<KodiakVault_Filter>;
  accumulated?: Maybe<Scalars['BigDecimal']>;
  accumulated_not?: Maybe<Scalars['BigDecimal']>;
  accumulated_gt?: Maybe<Scalars['BigDecimal']>;
  accumulated_lt?: Maybe<Scalars['BigDecimal']>;
  accumulated_gte?: Maybe<Scalars['BigDecimal']>;
  accumulated_lte?: Maybe<Scalars['BigDecimal']>;
  accumulated_in?: Maybe<Array<Scalars['BigDecimal']>>;
  accumulated_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  count?: Maybe<Scalars['BigInt']>;
  count_not?: Maybe<Scalars['BigInt']>;
  count_gt?: Maybe<Scalars['BigInt']>;
  count_lt?: Maybe<Scalars['BigInt']>;
  count_gte?: Maybe<Scalars['BigInt']>;
  count_lte?: Maybe<Scalars['BigInt']>;
  count_in?: Maybe<Array<Scalars['BigInt']>>;
  count_not_in?: Maybe<Array<Scalars['BigInt']>>;
  lastSnapshot?: Maybe<Scalars['String']>;
  lastSnapshot_not?: Maybe<Scalars['String']>;
  lastSnapshot_gt?: Maybe<Scalars['String']>;
  lastSnapshot_lt?: Maybe<Scalars['String']>;
  lastSnapshot_gte?: Maybe<Scalars['String']>;
  lastSnapshot_lte?: Maybe<Scalars['String']>;
  lastSnapshot_in?: Maybe<Array<Scalars['String']>>;
  lastSnapshot_not_in?: Maybe<Array<Scalars['String']>>;
  lastSnapshot_contains?: Maybe<Scalars['String']>;
  lastSnapshot_contains_nocase?: Maybe<Scalars['String']>;
  lastSnapshot_not_contains?: Maybe<Scalars['String']>;
  lastSnapshot_not_contains_nocase?: Maybe<Scalars['String']>;
  lastSnapshot_starts_with?: Maybe<Scalars['String']>;
  lastSnapshot_starts_with_nocase?: Maybe<Scalars['String']>;
  lastSnapshot_not_starts_with?: Maybe<Scalars['String']>;
  lastSnapshot_not_starts_with_nocase?: Maybe<Scalars['String']>;
  lastSnapshot_ends_with?: Maybe<Scalars['String']>;
  lastSnapshot_ends_with_nocase?: Maybe<Scalars['String']>;
  lastSnapshot_not_ends_with?: Maybe<Scalars['String']>;
  lastSnapshot_not_ends_with_nocase?: Maybe<Scalars['String']>;
  lastSnapshot_?: Maybe<KodiakVaultDailySnapshot_Filter>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<KodiakAprAccumulated_Filter>>>;
  or?: Maybe<Array<Maybe<KodiakAprAccumulated_Filter>>>;
};

export enum KodiakAprAccumulated_OrderBy {
  Id = 'id',
  Vault = 'vault',
  VaultId = 'vault__id',
  VaultName = 'vault__name',
  VaultSymbol = 'vault__symbol',
  VaultDepositLimit = 'vault__depositLimit',
  VaultCreatedTimestamp = 'vault__createdTimestamp',
  VaultCreatedBlockNumber = 'vault__createdBlockNumber',
  VaultTotalValueLockedUsd = 'vault__totalValueLockedUSD',
  VaultCumulativeSupplySideRevenueUsd = 'vault__cumulativeSupplySideRevenueUSD',
  VaultCumulativeProtocolSideRevenueUsd = 'vault__cumulativeProtocolSideRevenueUSD',
  VaultCumulativeTotalRevenueUsd = 'vault__cumulativeTotalRevenueUSD',
  VaultInputTokenBalance = 'vault__inputTokenBalance',
  VaultOutputTokenSupply = 'vault__outputTokenSupply',
  VaultOutputTokenPriceUsd = 'vault__outputTokenPriceUSD',
  VaultPricePerShare = 'vault__pricePerShare',
  VaultStakedOutputTokenAmount = 'vault__stakedOutputTokenAmount',
  VaultVolumeToken0 = 'vault__volumeToken0',
  VaultVolumeToken1 = 'vault__volumeToken1',
  VaultVolumeUsd = 'vault__volumeUSD',
  VaultLowerTick = 'vault__lowerTick',
  VaultUpperTick = 'vault__upperTick',
  VaultToken0Amount = 'vault___token0Amount',
  VaultToken1Amount = 'vault___token1Amount',
  VaultToken0AmountUsd = 'vault___token0AmountUSD',
  VaultToken1AmountUsd = 'vault___token1AmountUSD',
  Accumulated = 'accumulated',
  Count = 'count',
  LastSnapshot = 'lastSnapshot',
  LastSnapshotId = 'lastSnapshot__id',
  LastSnapshotTotalValueLockedUsd = 'lastSnapshot__totalValueLockedUSD',
  LastSnapshotCumulativeSupplySideRevenueUsd = 'lastSnapshot__cumulativeSupplySideRevenueUSD',
  LastSnapshotDailySupplySideRevenueUsd = 'lastSnapshot__dailySupplySideRevenueUSD',
  LastSnapshotCumulativeProtocolSideRevenueUsd = 'lastSnapshot__cumulativeProtocolSideRevenueUSD',
  LastSnapshotDailyProtocolSideRevenueUsd = 'lastSnapshot__dailyProtocolSideRevenueUSD',
  LastSnapshotCumulativeTotalRevenueUsd = 'lastSnapshot__cumulativeTotalRevenueUSD',
  LastSnapshotDailyTotalRevenueUsd = 'lastSnapshot__dailyTotalRevenueUSD',
  LastSnapshotInputTokenBalance = 'lastSnapshot__inputTokenBalance',
  LastSnapshotOutputTokenSupply = 'lastSnapshot__outputTokenSupply',
  LastSnapshotOutputTokenPriceUsd = 'lastSnapshot__outputTokenPriceUSD',
  LastSnapshotPricePerShare = 'lastSnapshot__pricePerShare',
  LastSnapshotStakedOutputTokenAmount = 'lastSnapshot__stakedOutputTokenAmount',
  LastSnapshotVolumeUsd = 'lastSnapshot__volumeUSD',
  LastSnapshotVolumeToken0 = 'lastSnapshot__volumeToken0',
  LastSnapshotVolumeToken1 = 'lastSnapshot__volumeToken1',
  LastSnapshotApr = 'lastSnapshot__apr',
  LastSnapshotBlockNumber = 'lastSnapshot__blockNumber',
  LastSnapshotTimestamp = 'lastSnapshot__timestamp',
  LastSnapshotLowerTick = 'lastSnapshot__lowerTick',
  LastSnapshotUpperTick = 'lastSnapshot__upperTick',
  LastSnapshotBaseLowerTick = 'lastSnapshot___baseLowerTick',
  LastSnapshotBaseUpperTick = 'lastSnapshot___baseUpperTick',
  LastSnapshotToken0Amount = 'lastSnapshot___token0Amount',
  LastSnapshotToken1Amount = 'lastSnapshot___token1Amount',
  LastSnapshotToken0AmountUsd = 'lastSnapshot___token0AmountUSD',
  LastSnapshotToken1AmountUsd = 'lastSnapshot___token1AmountUSD'
}

export type KodiakApr_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  averageApr?: Maybe<Scalars['BigDecimal']>;
  averageApr_not?: Maybe<Scalars['BigDecimal']>;
  averageApr_gt?: Maybe<Scalars['BigDecimal']>;
  averageApr_lt?: Maybe<Scalars['BigDecimal']>;
  averageApr_gte?: Maybe<Scalars['BigDecimal']>;
  averageApr_lte?: Maybe<Scalars['BigDecimal']>;
  averageApr_in?: Maybe<Array<Scalars['BigDecimal']>>;
  averageApr_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<KodiakApr_Filter>>>;
  or?: Maybe<Array<Maybe<KodiakApr_Filter>>>;
};

export enum KodiakApr_OrderBy {
  Id = 'id',
  AverageApr = 'averageApr',
  Timestamp = 'timestamp'
}

export type KodiakDeposit = Event & {
  __typename?: 'KodiakDeposit';
  /**  { Transaction hash }-{ Log index }  */
  id: Scalars['ID'];
  /**  Transaction hash of the transaction that emitted this event  */
  hash: Scalars['String'];
  /**  Event log index. For transactions that don't emit event, create arbitrary index starting from 0  */
  logIndex: Scalars['Int'];
  /**  The protocol this transaction belongs to  */
  protocol: YieldAggregator;
  /**  Market that tokens are deposited into  */
  to: Scalars['String'];
  /**  Address that deposited tokens  */
  from: Scalars['String'];
  /**  Block number of this event  */
  blockNumber: Scalars['BigInt'];
  /**  Timestamp of this event  */
  timestamp: Scalars['BigInt'];
  /**  Token deposited  */
  asset: Token;
  /**  Amount of token deposited in native units  */
  amount: Scalars['BigInt'];
  /**  Amount of token deposited in USD  */
  amountUSD: Scalars['BigDecimal'];
  /**  The vault involving this transaction  */
  vault: KodiakVault;
};

export type KodiakDeposit_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  hash?: Maybe<Scalars['String']>;
  hash_not?: Maybe<Scalars['String']>;
  hash_gt?: Maybe<Scalars['String']>;
  hash_lt?: Maybe<Scalars['String']>;
  hash_gte?: Maybe<Scalars['String']>;
  hash_lte?: Maybe<Scalars['String']>;
  hash_in?: Maybe<Array<Scalars['String']>>;
  hash_not_in?: Maybe<Array<Scalars['String']>>;
  hash_contains?: Maybe<Scalars['String']>;
  hash_contains_nocase?: Maybe<Scalars['String']>;
  hash_not_contains?: Maybe<Scalars['String']>;
  hash_not_contains_nocase?: Maybe<Scalars['String']>;
  hash_starts_with?: Maybe<Scalars['String']>;
  hash_starts_with_nocase?: Maybe<Scalars['String']>;
  hash_not_starts_with?: Maybe<Scalars['String']>;
  hash_not_starts_with_nocase?: Maybe<Scalars['String']>;
  hash_ends_with?: Maybe<Scalars['String']>;
  hash_ends_with_nocase?: Maybe<Scalars['String']>;
  hash_not_ends_with?: Maybe<Scalars['String']>;
  hash_not_ends_with_nocase?: Maybe<Scalars['String']>;
  logIndex?: Maybe<Scalars['Int']>;
  logIndex_not?: Maybe<Scalars['Int']>;
  logIndex_gt?: Maybe<Scalars['Int']>;
  logIndex_lt?: Maybe<Scalars['Int']>;
  logIndex_gte?: Maybe<Scalars['Int']>;
  logIndex_lte?: Maybe<Scalars['Int']>;
  logIndex_in?: Maybe<Array<Scalars['Int']>>;
  logIndex_not_in?: Maybe<Array<Scalars['Int']>>;
  protocol?: Maybe<Scalars['String']>;
  protocol_not?: Maybe<Scalars['String']>;
  protocol_gt?: Maybe<Scalars['String']>;
  protocol_lt?: Maybe<Scalars['String']>;
  protocol_gte?: Maybe<Scalars['String']>;
  protocol_lte?: Maybe<Scalars['String']>;
  protocol_in?: Maybe<Array<Scalars['String']>>;
  protocol_not_in?: Maybe<Array<Scalars['String']>>;
  protocol_contains?: Maybe<Scalars['String']>;
  protocol_contains_nocase?: Maybe<Scalars['String']>;
  protocol_not_contains?: Maybe<Scalars['String']>;
  protocol_not_contains_nocase?: Maybe<Scalars['String']>;
  protocol_starts_with?: Maybe<Scalars['String']>;
  protocol_starts_with_nocase?: Maybe<Scalars['String']>;
  protocol_not_starts_with?: Maybe<Scalars['String']>;
  protocol_not_starts_with_nocase?: Maybe<Scalars['String']>;
  protocol_ends_with?: Maybe<Scalars['String']>;
  protocol_ends_with_nocase?: Maybe<Scalars['String']>;
  protocol_not_ends_with?: Maybe<Scalars['String']>;
  protocol_not_ends_with_nocase?: Maybe<Scalars['String']>;
  protocol_?: Maybe<YieldAggregator_Filter>;
  to?: Maybe<Scalars['String']>;
  to_not?: Maybe<Scalars['String']>;
  to_gt?: Maybe<Scalars['String']>;
  to_lt?: Maybe<Scalars['String']>;
  to_gte?: Maybe<Scalars['String']>;
  to_lte?: Maybe<Scalars['String']>;
  to_in?: Maybe<Array<Scalars['String']>>;
  to_not_in?: Maybe<Array<Scalars['String']>>;
  to_contains?: Maybe<Scalars['String']>;
  to_contains_nocase?: Maybe<Scalars['String']>;
  to_not_contains?: Maybe<Scalars['String']>;
  to_not_contains_nocase?: Maybe<Scalars['String']>;
  to_starts_with?: Maybe<Scalars['String']>;
  to_starts_with_nocase?: Maybe<Scalars['String']>;
  to_not_starts_with?: Maybe<Scalars['String']>;
  to_not_starts_with_nocase?: Maybe<Scalars['String']>;
  to_ends_with?: Maybe<Scalars['String']>;
  to_ends_with_nocase?: Maybe<Scalars['String']>;
  to_not_ends_with?: Maybe<Scalars['String']>;
  to_not_ends_with_nocase?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  from_not?: Maybe<Scalars['String']>;
  from_gt?: Maybe<Scalars['String']>;
  from_lt?: Maybe<Scalars['String']>;
  from_gte?: Maybe<Scalars['String']>;
  from_lte?: Maybe<Scalars['String']>;
  from_in?: Maybe<Array<Scalars['String']>>;
  from_not_in?: Maybe<Array<Scalars['String']>>;
  from_contains?: Maybe<Scalars['String']>;
  from_contains_nocase?: Maybe<Scalars['String']>;
  from_not_contains?: Maybe<Scalars['String']>;
  from_not_contains_nocase?: Maybe<Scalars['String']>;
  from_starts_with?: Maybe<Scalars['String']>;
  from_starts_with_nocase?: Maybe<Scalars['String']>;
  from_not_starts_with?: Maybe<Scalars['String']>;
  from_not_starts_with_nocase?: Maybe<Scalars['String']>;
  from_ends_with?: Maybe<Scalars['String']>;
  from_ends_with_nocase?: Maybe<Scalars['String']>;
  from_not_ends_with?: Maybe<Scalars['String']>;
  from_not_ends_with_nocase?: Maybe<Scalars['String']>;
  blockNumber?: Maybe<Scalars['BigInt']>;
  blockNumber_not?: Maybe<Scalars['BigInt']>;
  blockNumber_gt?: Maybe<Scalars['BigInt']>;
  blockNumber_lt?: Maybe<Scalars['BigInt']>;
  blockNumber_gte?: Maybe<Scalars['BigInt']>;
  blockNumber_lte?: Maybe<Scalars['BigInt']>;
  blockNumber_in?: Maybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  asset?: Maybe<Scalars['String']>;
  asset_not?: Maybe<Scalars['String']>;
  asset_gt?: Maybe<Scalars['String']>;
  asset_lt?: Maybe<Scalars['String']>;
  asset_gte?: Maybe<Scalars['String']>;
  asset_lte?: Maybe<Scalars['String']>;
  asset_in?: Maybe<Array<Scalars['String']>>;
  asset_not_in?: Maybe<Array<Scalars['String']>>;
  asset_contains?: Maybe<Scalars['String']>;
  asset_contains_nocase?: Maybe<Scalars['String']>;
  asset_not_contains?: Maybe<Scalars['String']>;
  asset_not_contains_nocase?: Maybe<Scalars['String']>;
  asset_starts_with?: Maybe<Scalars['String']>;
  asset_starts_with_nocase?: Maybe<Scalars['String']>;
  asset_not_starts_with?: Maybe<Scalars['String']>;
  asset_not_starts_with_nocase?: Maybe<Scalars['String']>;
  asset_ends_with?: Maybe<Scalars['String']>;
  asset_ends_with_nocase?: Maybe<Scalars['String']>;
  asset_not_ends_with?: Maybe<Scalars['String']>;
  asset_not_ends_with_nocase?: Maybe<Scalars['String']>;
  asset_?: Maybe<Token_Filter>;
  amount?: Maybe<Scalars['BigInt']>;
  amount_not?: Maybe<Scalars['BigInt']>;
  amount_gt?: Maybe<Scalars['BigInt']>;
  amount_lt?: Maybe<Scalars['BigInt']>;
  amount_gte?: Maybe<Scalars['BigInt']>;
  amount_lte?: Maybe<Scalars['BigInt']>;
  amount_in?: Maybe<Array<Scalars['BigInt']>>;
  amount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  amountUSD?: Maybe<Scalars['BigDecimal']>;
  amountUSD_not?: Maybe<Scalars['BigDecimal']>;
  amountUSD_gt?: Maybe<Scalars['BigDecimal']>;
  amountUSD_lt?: Maybe<Scalars['BigDecimal']>;
  amountUSD_gte?: Maybe<Scalars['BigDecimal']>;
  amountUSD_lte?: Maybe<Scalars['BigDecimal']>;
  amountUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amountUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  vault?: Maybe<Scalars['String']>;
  vault_not?: Maybe<Scalars['String']>;
  vault_gt?: Maybe<Scalars['String']>;
  vault_lt?: Maybe<Scalars['String']>;
  vault_gte?: Maybe<Scalars['String']>;
  vault_lte?: Maybe<Scalars['String']>;
  vault_in?: Maybe<Array<Scalars['String']>>;
  vault_not_in?: Maybe<Array<Scalars['String']>>;
  vault_contains?: Maybe<Scalars['String']>;
  vault_contains_nocase?: Maybe<Scalars['String']>;
  vault_not_contains?: Maybe<Scalars['String']>;
  vault_not_contains_nocase?: Maybe<Scalars['String']>;
  vault_starts_with?: Maybe<Scalars['String']>;
  vault_starts_with_nocase?: Maybe<Scalars['String']>;
  vault_not_starts_with?: Maybe<Scalars['String']>;
  vault_not_starts_with_nocase?: Maybe<Scalars['String']>;
  vault_ends_with?: Maybe<Scalars['String']>;
  vault_ends_with_nocase?: Maybe<Scalars['String']>;
  vault_not_ends_with?: Maybe<Scalars['String']>;
  vault_not_ends_with_nocase?: Maybe<Scalars['String']>;
  vault_?: Maybe<KodiakVault_Filter>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<KodiakDeposit_Filter>>>;
  or?: Maybe<Array<Maybe<KodiakDeposit_Filter>>>;
};

export enum KodiakDeposit_OrderBy {
  Id = 'id',
  Hash = 'hash',
  LogIndex = 'logIndex',
  Protocol = 'protocol',
  ProtocolId = 'protocol__id',
  ProtocolName = 'protocol__name',
  ProtocolSlug = 'protocol__slug',
  ProtocolSchemaVersion = 'protocol__schemaVersion',
  ProtocolSubgraphVersion = 'protocol__subgraphVersion',
  ProtocolMethodologyVersion = 'protocol__methodologyVersion',
  ProtocolNetwork = 'protocol__network',
  ProtocolType = 'protocol__type',
  ProtocolTotalValueLockedUsd = 'protocol__totalValueLockedUSD',
  ProtocolProtocolControlledValueUsd = 'protocol__protocolControlledValueUSD',
  ProtocolCumulativeSupplySideRevenueUsd = 'protocol__cumulativeSupplySideRevenueUSD',
  ProtocolCumulativeProtocolSideRevenueUsd = 'protocol__cumulativeProtocolSideRevenueUSD',
  ProtocolCumulativeTotalRevenueUsd = 'protocol__cumulativeTotalRevenueUSD',
  ProtocolCumulativeUniqueUsers = 'protocol__cumulativeUniqueUsers',
  ProtocolTotalPoolCount = 'protocol__totalPoolCount',
  To = 'to',
  From = 'from',
  BlockNumber = 'blockNumber',
  Timestamp = 'timestamp',
  Asset = 'asset',
  AssetId = 'asset__id',
  AssetSymbol = 'asset__symbol',
  AssetName = 'asset__name',
  AssetDecimals = 'asset__decimals',
  AssetTotalSupply = 'asset__totalSupply',
  AssetVolume = 'asset__volume',
  AssetVolumeUsd = 'asset__volumeUSD',
  AssetUntrackedVolumeUsd = 'asset__untrackedVolumeUSD',
  AssetFeesUsd = 'asset__feesUSD',
  AssetTxCount = 'asset__txCount',
  AssetPoolCount = 'asset__poolCount',
  AssetTotalValueLocked = 'asset__totalValueLocked',
  AssetTotalValueLockedUsd = 'asset__totalValueLockedUSD',
  AssetTotalValueLockedUsdUntracked = 'asset__totalValueLockedUSDUntracked',
  AssetDerivedEth = 'asset__derivedETH',
  Amount = 'amount',
  AmountUsd = 'amountUSD',
  Vault = 'vault',
  VaultId = 'vault__id',
  VaultName = 'vault__name',
  VaultSymbol = 'vault__symbol',
  VaultDepositLimit = 'vault__depositLimit',
  VaultCreatedTimestamp = 'vault__createdTimestamp',
  VaultCreatedBlockNumber = 'vault__createdBlockNumber',
  VaultTotalValueLockedUsd = 'vault__totalValueLockedUSD',
  VaultCumulativeSupplySideRevenueUsd = 'vault__cumulativeSupplySideRevenueUSD',
  VaultCumulativeProtocolSideRevenueUsd = 'vault__cumulativeProtocolSideRevenueUSD',
  VaultCumulativeTotalRevenueUsd = 'vault__cumulativeTotalRevenueUSD',
  VaultInputTokenBalance = 'vault__inputTokenBalance',
  VaultOutputTokenSupply = 'vault__outputTokenSupply',
  VaultOutputTokenPriceUsd = 'vault__outputTokenPriceUSD',
  VaultPricePerShare = 'vault__pricePerShare',
  VaultStakedOutputTokenAmount = 'vault__stakedOutputTokenAmount',
  VaultVolumeToken0 = 'vault__volumeToken0',
  VaultVolumeToken1 = 'vault__volumeToken1',
  VaultVolumeUsd = 'vault__volumeUSD',
  VaultLowerTick = 'vault__lowerTick',
  VaultUpperTick = 'vault__upperTick',
  VaultToken0Amount = 'vault___token0Amount',
  VaultToken1Amount = 'vault___token1Amount',
  VaultToken0AmountUsd = 'vault___token0AmountUSD',
  VaultToken1AmountUsd = 'vault___token1AmountUSD'
}

export type KodiakFinancialsDailySnapshot = {
  __typename?: 'KodiakFinancialsDailySnapshot';
  /**  ID is # of days since Unix epoch time  */
  id: Scalars['ID'];
  /**  Protocol this snapshot is associated with  */
  protocol: YieldAggregator;
  /**  Current TVL (Total Value Locked) of the entire protocol  */
  totalValueLockedUSD: Scalars['BigDecimal'];
  /**  Current PCV (Protocol Controlled Value). Only relevant for protocols with PCV.  */
  protocolControlledValueUSD?: Maybe<Scalars['BigDecimal']>;
  /**  Revenue claimed by suppliers to the protocol. LPs on DEXs (e.g. 0.25% of the swap fee in Sushiswap). Depositors on Lending Protocols. NFT sellers on OpenSea.  */
  dailySupplySideRevenueUSD: Scalars['BigDecimal'];
  /**  Revenue claimed by suppliers to the protocol. LPs on DEXs (e.g. 0.25% of the swap fee in Sushiswap). Depositors on Lending Protocols. NFT sellers on OpenSea.  */
  cumulativeSupplySideRevenueUSD: Scalars['BigDecimal'];
  /**  Gross revenue for the protocol (revenue claimed by protocol). Examples: AMM protocol fee (Sushi’s 0.05%). OpenSea 10% sell fee.  */
  dailyProtocolSideRevenueUSD: Scalars['BigDecimal'];
  /**  Gross revenue for the protocol (revenue claimed by protocol). Examples: AMM protocol fee (Sushi’s 0.05%). OpenSea 10% sell fee.  */
  cumulativeProtocolSideRevenueUSD: Scalars['BigDecimal'];
  /**  All revenue generated by the protocol. e.g. 0.30% of swap fee in Sushiswap, all yield generated by Yearn.  */
  dailyTotalRevenueUSD: Scalars['BigDecimal'];
  /**  All revenue generated by the protocol. e.g. 0.30% of swap fee in Sushiswap, all yield generated by Yearn.  */
  cumulativeTotalRevenueUSD: Scalars['BigDecimal'];
  /**  Block number of this snapshot  */
  blockNumber: Scalars['BigInt'];
  /**  Timestamp of this snapshot  */
  timestamp: Scalars['BigInt'];
};

export type KodiakFinancialsDailySnapshot_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  protocol?: Maybe<Scalars['String']>;
  protocol_not?: Maybe<Scalars['String']>;
  protocol_gt?: Maybe<Scalars['String']>;
  protocol_lt?: Maybe<Scalars['String']>;
  protocol_gte?: Maybe<Scalars['String']>;
  protocol_lte?: Maybe<Scalars['String']>;
  protocol_in?: Maybe<Array<Scalars['String']>>;
  protocol_not_in?: Maybe<Array<Scalars['String']>>;
  protocol_contains?: Maybe<Scalars['String']>;
  protocol_contains_nocase?: Maybe<Scalars['String']>;
  protocol_not_contains?: Maybe<Scalars['String']>;
  protocol_not_contains_nocase?: Maybe<Scalars['String']>;
  protocol_starts_with?: Maybe<Scalars['String']>;
  protocol_starts_with_nocase?: Maybe<Scalars['String']>;
  protocol_not_starts_with?: Maybe<Scalars['String']>;
  protocol_not_starts_with_nocase?: Maybe<Scalars['String']>;
  protocol_ends_with?: Maybe<Scalars['String']>;
  protocol_ends_with_nocase?: Maybe<Scalars['String']>;
  protocol_not_ends_with?: Maybe<Scalars['String']>;
  protocol_not_ends_with_nocase?: Maybe<Scalars['String']>;
  protocol_?: Maybe<YieldAggregator_Filter>;
  totalValueLockedUSD?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_not?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_gt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_lt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_gte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_lte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  protocolControlledValueUSD?: Maybe<Scalars['BigDecimal']>;
  protocolControlledValueUSD_not?: Maybe<Scalars['BigDecimal']>;
  protocolControlledValueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  protocolControlledValueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  protocolControlledValueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  protocolControlledValueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  protocolControlledValueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  protocolControlledValueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  dailySupplySideRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  dailySupplySideRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  dailySupplySideRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  dailySupplySideRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  dailySupplySideRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  dailySupplySideRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  dailySupplySideRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  dailySupplySideRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeSupplySideRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeSupplySideRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  dailyProtocolSideRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  dailyProtocolSideRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  dailyProtocolSideRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  dailyProtocolSideRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  dailyProtocolSideRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  dailyProtocolSideRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  dailyProtocolSideRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  dailyProtocolSideRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeProtocolSideRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeProtocolSideRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  dailyTotalRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  dailyTotalRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  dailyTotalRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  dailyTotalRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  dailyTotalRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  dailyTotalRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  dailyTotalRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  dailyTotalRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeTotalRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeTotalRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  blockNumber?: Maybe<Scalars['BigInt']>;
  blockNumber_not?: Maybe<Scalars['BigInt']>;
  blockNumber_gt?: Maybe<Scalars['BigInt']>;
  blockNumber_lt?: Maybe<Scalars['BigInt']>;
  blockNumber_gte?: Maybe<Scalars['BigInt']>;
  blockNumber_lte?: Maybe<Scalars['BigInt']>;
  blockNumber_in?: Maybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<KodiakFinancialsDailySnapshot_Filter>>>;
  or?: Maybe<Array<Maybe<KodiakFinancialsDailySnapshot_Filter>>>;
};

export enum KodiakFinancialsDailySnapshot_OrderBy {
  Id = 'id',
  Protocol = 'protocol',
  ProtocolId = 'protocol__id',
  ProtocolName = 'protocol__name',
  ProtocolSlug = 'protocol__slug',
  ProtocolSchemaVersion = 'protocol__schemaVersion',
  ProtocolSubgraphVersion = 'protocol__subgraphVersion',
  ProtocolMethodologyVersion = 'protocol__methodologyVersion',
  ProtocolNetwork = 'protocol__network',
  ProtocolType = 'protocol__type',
  ProtocolTotalValueLockedUsd = 'protocol__totalValueLockedUSD',
  ProtocolProtocolControlledValueUsd = 'protocol__protocolControlledValueUSD',
  ProtocolCumulativeSupplySideRevenueUsd = 'protocol__cumulativeSupplySideRevenueUSD',
  ProtocolCumulativeProtocolSideRevenueUsd = 'protocol__cumulativeProtocolSideRevenueUSD',
  ProtocolCumulativeTotalRevenueUsd = 'protocol__cumulativeTotalRevenueUSD',
  ProtocolCumulativeUniqueUsers = 'protocol__cumulativeUniqueUsers',
  ProtocolTotalPoolCount = 'protocol__totalPoolCount',
  TotalValueLockedUsd = 'totalValueLockedUSD',
  ProtocolControlledValueUsd = 'protocolControlledValueUSD',
  DailySupplySideRevenueUsd = 'dailySupplySideRevenueUSD',
  CumulativeSupplySideRevenueUsd = 'cumulativeSupplySideRevenueUSD',
  DailyProtocolSideRevenueUsd = 'dailyProtocolSideRevenueUSD',
  CumulativeProtocolSideRevenueUsd = 'cumulativeProtocolSideRevenueUSD',
  DailyTotalRevenueUsd = 'dailyTotalRevenueUSD',
  CumulativeTotalRevenueUsd = 'cumulativeTotalRevenueUSD',
  BlockNumber = 'blockNumber',
  Timestamp = 'timestamp'
}

export type KodiakProtocol = {
  /**  Smart contract address of the protocol's main contract (Factory, Registry, etc)  */
  id: Scalars['ID'];
  /**  Name of the protocol, including version. e.g. Uniswap v3  */
  name: Scalars['String'];
  /**  Slug of protocol, including version. e.g. uniswap-v3  */
  slug: Scalars['String'];
  /**  Version of the subgraph schema, in SemVer format (e.g. 1.0.0)  */
  schemaVersion: Scalars['String'];
  /**  Version of the subgraph implementation, in SemVer format (e.g. 1.0.0)  */
  subgraphVersion: Scalars['String'];
  /**  Version of the methodology used to compute metrics, loosely based on SemVer format (e.g. 1.0.0)  */
  methodologyVersion: Scalars['String'];
  /**  The blockchain network this subgraph is indexing on  */
  network: Network;
  /**  The type of protocol (e.g. DEX, Lending, Yield, etc)  */
  type: KodiakProtocolType;
  /**  Current TVL (Total Value Locked) of the entire protocol  */
  totalValueLockedUSD: Scalars['BigDecimal'];
  /**  Current PCV (Protocol Controlled Value). Only relevant for protocols with PCV.  */
  protocolControlledValueUSD?: Maybe<Scalars['BigDecimal']>;
  /**  Revenue claimed by suppliers to the protocol. LPs on DEXs (e.g. 0.25% of the swap fee in Sushiswap). Depositors on Lending Protocols. NFT sellers on OpenSea.  */
  cumulativeSupplySideRevenueUSD: Scalars['BigDecimal'];
  /**  Gross revenue for the protocol (revenue claimed by protocol). Examples: AMM protocol fee (Sushi’s 0.05%). OpenSea 10% sell fee.  */
  cumulativeProtocolSideRevenueUSD: Scalars['BigDecimal'];
  /**  All revenue generated by the protocol. e.g. 0.30% of swap fee in Sushiswap, all yield generated by Yearn.  */
  cumulativeTotalRevenueUSD: Scalars['BigDecimal'];
  /**  Number of cumulative unique users  */
  cumulativeUniqueUsers: Scalars['Int'];
  /**  Total number of pools  */
  totalPoolCount: Scalars['Int'];
  /**  Daily usage metrics for this protocol  */
  dailyUsageMetrics: Array<KodiakUsageMetricsDailySnapshot>;
  /**  Hourly usage metrics for this protocol  */
  hourlyUsageMetrics: Array<KodiakUsageMetricsHourlySnapshot>;
  /**  Daily financial metrics for this protocol  */
  financialMetrics: Array<KodiakFinancialsDailySnapshot>;
};


export type KodiakProtocolDailyUsageMetricsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakUsageMetricsDailySnapshot_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakUsageMetricsDailySnapshot_Filter>;
};


export type KodiakProtocolHourlyUsageMetricsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakUsageMetricsHourlySnapshot_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakUsageMetricsHourlySnapshot_Filter>;
};


export type KodiakProtocolFinancialMetricsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakFinancialsDailySnapshot_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakFinancialsDailySnapshot_Filter>;
};

export enum KodiakProtocolType {
  Exchange = 'EXCHANGE',
  Lending = 'LENDING',
  Yield = 'YIELD',
  Bridge = 'BRIDGE',
  Generic = 'GENERIC'
}

export type KodiakProtocol_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_lt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_contains?: Maybe<Scalars['String']>;
  name_contains_nocase?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_not_contains_nocase?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_starts_with_nocase?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with_nocase?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_ends_with_nocase?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with_nocase?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_contains_nocase?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_not_contains_nocase?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_starts_with_nocase?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with_nocase?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_ends_with_nocase?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with_nocase?: Maybe<Scalars['String']>;
  schemaVersion?: Maybe<Scalars['String']>;
  schemaVersion_not?: Maybe<Scalars['String']>;
  schemaVersion_gt?: Maybe<Scalars['String']>;
  schemaVersion_lt?: Maybe<Scalars['String']>;
  schemaVersion_gte?: Maybe<Scalars['String']>;
  schemaVersion_lte?: Maybe<Scalars['String']>;
  schemaVersion_in?: Maybe<Array<Scalars['String']>>;
  schemaVersion_not_in?: Maybe<Array<Scalars['String']>>;
  schemaVersion_contains?: Maybe<Scalars['String']>;
  schemaVersion_contains_nocase?: Maybe<Scalars['String']>;
  schemaVersion_not_contains?: Maybe<Scalars['String']>;
  schemaVersion_not_contains_nocase?: Maybe<Scalars['String']>;
  schemaVersion_starts_with?: Maybe<Scalars['String']>;
  schemaVersion_starts_with_nocase?: Maybe<Scalars['String']>;
  schemaVersion_not_starts_with?: Maybe<Scalars['String']>;
  schemaVersion_not_starts_with_nocase?: Maybe<Scalars['String']>;
  schemaVersion_ends_with?: Maybe<Scalars['String']>;
  schemaVersion_ends_with_nocase?: Maybe<Scalars['String']>;
  schemaVersion_not_ends_with?: Maybe<Scalars['String']>;
  schemaVersion_not_ends_with_nocase?: Maybe<Scalars['String']>;
  subgraphVersion?: Maybe<Scalars['String']>;
  subgraphVersion_not?: Maybe<Scalars['String']>;
  subgraphVersion_gt?: Maybe<Scalars['String']>;
  subgraphVersion_lt?: Maybe<Scalars['String']>;
  subgraphVersion_gte?: Maybe<Scalars['String']>;
  subgraphVersion_lte?: Maybe<Scalars['String']>;
  subgraphVersion_in?: Maybe<Array<Scalars['String']>>;
  subgraphVersion_not_in?: Maybe<Array<Scalars['String']>>;
  subgraphVersion_contains?: Maybe<Scalars['String']>;
  subgraphVersion_contains_nocase?: Maybe<Scalars['String']>;
  subgraphVersion_not_contains?: Maybe<Scalars['String']>;
  subgraphVersion_not_contains_nocase?: Maybe<Scalars['String']>;
  subgraphVersion_starts_with?: Maybe<Scalars['String']>;
  subgraphVersion_starts_with_nocase?: Maybe<Scalars['String']>;
  subgraphVersion_not_starts_with?: Maybe<Scalars['String']>;
  subgraphVersion_not_starts_with_nocase?: Maybe<Scalars['String']>;
  subgraphVersion_ends_with?: Maybe<Scalars['String']>;
  subgraphVersion_ends_with_nocase?: Maybe<Scalars['String']>;
  subgraphVersion_not_ends_with?: Maybe<Scalars['String']>;
  subgraphVersion_not_ends_with_nocase?: Maybe<Scalars['String']>;
  methodologyVersion?: Maybe<Scalars['String']>;
  methodologyVersion_not?: Maybe<Scalars['String']>;
  methodologyVersion_gt?: Maybe<Scalars['String']>;
  methodologyVersion_lt?: Maybe<Scalars['String']>;
  methodologyVersion_gte?: Maybe<Scalars['String']>;
  methodologyVersion_lte?: Maybe<Scalars['String']>;
  methodologyVersion_in?: Maybe<Array<Scalars['String']>>;
  methodologyVersion_not_in?: Maybe<Array<Scalars['String']>>;
  methodologyVersion_contains?: Maybe<Scalars['String']>;
  methodologyVersion_contains_nocase?: Maybe<Scalars['String']>;
  methodologyVersion_not_contains?: Maybe<Scalars['String']>;
  methodologyVersion_not_contains_nocase?: Maybe<Scalars['String']>;
  methodologyVersion_starts_with?: Maybe<Scalars['String']>;
  methodologyVersion_starts_with_nocase?: Maybe<Scalars['String']>;
  methodologyVersion_not_starts_with?: Maybe<Scalars['String']>;
  methodologyVersion_not_starts_with_nocase?: Maybe<Scalars['String']>;
  methodologyVersion_ends_with?: Maybe<Scalars['String']>;
  methodologyVersion_ends_with_nocase?: Maybe<Scalars['String']>;
  methodologyVersion_not_ends_with?: Maybe<Scalars['String']>;
  methodologyVersion_not_ends_with_nocase?: Maybe<Scalars['String']>;
  network?: Maybe<Network>;
  network_not?: Maybe<Network>;
  network_in?: Maybe<Array<Network>>;
  network_not_in?: Maybe<Array<Network>>;
  type?: Maybe<KodiakProtocolType>;
  type_not?: Maybe<KodiakProtocolType>;
  type_in?: Maybe<Array<KodiakProtocolType>>;
  type_not_in?: Maybe<Array<KodiakProtocolType>>;
  totalValueLockedUSD?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_not?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_gt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_lt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_gte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_lte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  protocolControlledValueUSD?: Maybe<Scalars['BigDecimal']>;
  protocolControlledValueUSD_not?: Maybe<Scalars['BigDecimal']>;
  protocolControlledValueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  protocolControlledValueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  protocolControlledValueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  protocolControlledValueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  protocolControlledValueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  protocolControlledValueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeSupplySideRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeSupplySideRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeProtocolSideRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeProtocolSideRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeTotalRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeTotalRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeUniqueUsers?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_not?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_gt?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_lt?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_gte?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_lte?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_in?: Maybe<Array<Scalars['Int']>>;
  cumulativeUniqueUsers_not_in?: Maybe<Array<Scalars['Int']>>;
  totalPoolCount?: Maybe<Scalars['Int']>;
  totalPoolCount_not?: Maybe<Scalars['Int']>;
  totalPoolCount_gt?: Maybe<Scalars['Int']>;
  totalPoolCount_lt?: Maybe<Scalars['Int']>;
  totalPoolCount_gte?: Maybe<Scalars['Int']>;
  totalPoolCount_lte?: Maybe<Scalars['Int']>;
  totalPoolCount_in?: Maybe<Array<Scalars['Int']>>;
  totalPoolCount_not_in?: Maybe<Array<Scalars['Int']>>;
  dailyUsageMetrics_?: Maybe<KodiakUsageMetricsDailySnapshot_Filter>;
  hourlyUsageMetrics_?: Maybe<KodiakUsageMetricsHourlySnapshot_Filter>;
  financialMetrics_?: Maybe<KodiakFinancialsDailySnapshot_Filter>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<KodiakProtocol_Filter>>>;
  or?: Maybe<Array<Maybe<KodiakProtocol_Filter>>>;
};

export enum KodiakProtocol_OrderBy {
  Id = 'id',
  Name = 'name',
  Slug = 'slug',
  SchemaVersion = 'schemaVersion',
  SubgraphVersion = 'subgraphVersion',
  MethodologyVersion = 'methodologyVersion',
  Network = 'network',
  Type = 'type',
  TotalValueLockedUsd = 'totalValueLockedUSD',
  ProtocolControlledValueUsd = 'protocolControlledValueUSD',
  CumulativeSupplySideRevenueUsd = 'cumulativeSupplySideRevenueUSD',
  CumulativeProtocolSideRevenueUsd = 'cumulativeProtocolSideRevenueUSD',
  CumulativeTotalRevenueUsd = 'cumulativeTotalRevenueUSD',
  CumulativeUniqueUsers = 'cumulativeUniqueUsers',
  TotalPoolCount = 'totalPoolCount',
  DailyUsageMetrics = 'dailyUsageMetrics',
  HourlyUsageMetrics = 'hourlyUsageMetrics',
  FinancialMetrics = 'financialMetrics'
}

export type KodiakRewardToken = {
  __typename?: 'KodiakRewardToken';
  /**  { Reward token type }-{ Smart contract address of the reward token }  */
  id: Scalars['ID'];
  /**  Reference to the actual token  */
  token: Token;
  /**  The type of the reward token  */
  type: KodiakRewardTokenType;
};

export enum KodiakRewardTokenType {
  /**  For reward tokens awarded to LPs/lenders  */
  Deposit = 'DEPOSIT',
  /**  For reward tokens awarded to borrowers  */
  Borrow = 'BORROW'
}

export type KodiakRewardToken_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  token?: Maybe<Scalars['String']>;
  token_not?: Maybe<Scalars['String']>;
  token_gt?: Maybe<Scalars['String']>;
  token_lt?: Maybe<Scalars['String']>;
  token_gte?: Maybe<Scalars['String']>;
  token_lte?: Maybe<Scalars['String']>;
  token_in?: Maybe<Array<Scalars['String']>>;
  token_not_in?: Maybe<Array<Scalars['String']>>;
  token_contains?: Maybe<Scalars['String']>;
  token_contains_nocase?: Maybe<Scalars['String']>;
  token_not_contains?: Maybe<Scalars['String']>;
  token_not_contains_nocase?: Maybe<Scalars['String']>;
  token_starts_with?: Maybe<Scalars['String']>;
  token_starts_with_nocase?: Maybe<Scalars['String']>;
  token_not_starts_with?: Maybe<Scalars['String']>;
  token_not_starts_with_nocase?: Maybe<Scalars['String']>;
  token_ends_with?: Maybe<Scalars['String']>;
  token_ends_with_nocase?: Maybe<Scalars['String']>;
  token_not_ends_with?: Maybe<Scalars['String']>;
  token_not_ends_with_nocase?: Maybe<Scalars['String']>;
  token_?: Maybe<Token_Filter>;
  type?: Maybe<KodiakRewardTokenType>;
  type_not?: Maybe<KodiakRewardTokenType>;
  type_in?: Maybe<Array<KodiakRewardTokenType>>;
  type_not_in?: Maybe<Array<KodiakRewardTokenType>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<KodiakRewardToken_Filter>>>;
  or?: Maybe<Array<Maybe<KodiakRewardToken_Filter>>>;
};

export enum KodiakRewardToken_OrderBy {
  Id = 'id',
  Token = 'token',
  TokenId = 'token__id',
  TokenSymbol = 'token__symbol',
  TokenName = 'token__name',
  TokenDecimals = 'token__decimals',
  TokenTotalSupply = 'token__totalSupply',
  TokenVolume = 'token__volume',
  TokenVolumeUsd = 'token__volumeUSD',
  TokenUntrackedVolumeUsd = 'token__untrackedVolumeUSD',
  TokenFeesUsd = 'token__feesUSD',
  TokenTxCount = 'token__txCount',
  TokenPoolCount = 'token__poolCount',
  TokenTotalValueLocked = 'token__totalValueLocked',
  TokenTotalValueLockedUsd = 'token__totalValueLockedUSD',
  TokenTotalValueLockedUsdUntracked = 'token__totalValueLockedUSDUntracked',
  TokenDerivedEth = 'token__derivedETH',
  Type = 'type'
}

export type KodiakStrategyChange = {
  __typename?: 'KodiakStrategyChange';
  id: Scalars['ID'];
  lowerTick: Scalars['BigInt'];
  upperTick: Scalars['BigInt'];
  timestamp: Scalars['BigInt'];
};

export type KodiakStrategyChange_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  lowerTick?: Maybe<Scalars['BigInt']>;
  lowerTick_not?: Maybe<Scalars['BigInt']>;
  lowerTick_gt?: Maybe<Scalars['BigInt']>;
  lowerTick_lt?: Maybe<Scalars['BigInt']>;
  lowerTick_gte?: Maybe<Scalars['BigInt']>;
  lowerTick_lte?: Maybe<Scalars['BigInt']>;
  lowerTick_in?: Maybe<Array<Scalars['BigInt']>>;
  lowerTick_not_in?: Maybe<Array<Scalars['BigInt']>>;
  upperTick?: Maybe<Scalars['BigInt']>;
  upperTick_not?: Maybe<Scalars['BigInt']>;
  upperTick_gt?: Maybe<Scalars['BigInt']>;
  upperTick_lt?: Maybe<Scalars['BigInt']>;
  upperTick_gte?: Maybe<Scalars['BigInt']>;
  upperTick_lte?: Maybe<Scalars['BigInt']>;
  upperTick_in?: Maybe<Array<Scalars['BigInt']>>;
  upperTick_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<KodiakStrategyChange_Filter>>>;
  or?: Maybe<Array<Maybe<KodiakStrategyChange_Filter>>>;
};

export enum KodiakStrategyChange_OrderBy {
  Id = 'id',
  LowerTick = 'lowerTick',
  UpperTick = 'upperTick',
  Timestamp = 'timestamp'
}

export type KodiakUsageMetricsDailySnapshot = {
  __typename?: 'KodiakUsageMetricsDailySnapshot';
  /**  ID is # of days since Unix epoch time  */
  id: Scalars['ID'];
  /**  Protocol this snapshot is associated with  */
  protocol: YieldAggregator;
  /**  Number of unique daily active users  */
  dailyActiveUsers: Scalars['Int'];
  /**  Number of cumulative unique users  */
  cumulativeUniqueUsers: Scalars['Int'];
  /**  Total number of transactions occurred in a day. Transactions include all entities that implement the Event interface.  */
  dailyTransactionCount: Scalars['Int'];
  /**  Total number of deposits in a day  */
  dailyDepositCount: Scalars['Int'];
  /**  Total number of withdrawals in a day  */
  dailyWithdrawCount: Scalars['Int'];
  /**  Total number of pools  */
  totalPoolCount: Scalars['Int'];
  /**  Block number of this snapshot  */
  blockNumber: Scalars['BigInt'];
  /**  Timestamp of this snapshot  */
  timestamp: Scalars['BigInt'];
};

export type KodiakUsageMetricsDailySnapshot_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  protocol?: Maybe<Scalars['String']>;
  protocol_not?: Maybe<Scalars['String']>;
  protocol_gt?: Maybe<Scalars['String']>;
  protocol_lt?: Maybe<Scalars['String']>;
  protocol_gte?: Maybe<Scalars['String']>;
  protocol_lte?: Maybe<Scalars['String']>;
  protocol_in?: Maybe<Array<Scalars['String']>>;
  protocol_not_in?: Maybe<Array<Scalars['String']>>;
  protocol_contains?: Maybe<Scalars['String']>;
  protocol_contains_nocase?: Maybe<Scalars['String']>;
  protocol_not_contains?: Maybe<Scalars['String']>;
  protocol_not_contains_nocase?: Maybe<Scalars['String']>;
  protocol_starts_with?: Maybe<Scalars['String']>;
  protocol_starts_with_nocase?: Maybe<Scalars['String']>;
  protocol_not_starts_with?: Maybe<Scalars['String']>;
  protocol_not_starts_with_nocase?: Maybe<Scalars['String']>;
  protocol_ends_with?: Maybe<Scalars['String']>;
  protocol_ends_with_nocase?: Maybe<Scalars['String']>;
  protocol_not_ends_with?: Maybe<Scalars['String']>;
  protocol_not_ends_with_nocase?: Maybe<Scalars['String']>;
  protocol_?: Maybe<YieldAggregator_Filter>;
  dailyActiveUsers?: Maybe<Scalars['Int']>;
  dailyActiveUsers_not?: Maybe<Scalars['Int']>;
  dailyActiveUsers_gt?: Maybe<Scalars['Int']>;
  dailyActiveUsers_lt?: Maybe<Scalars['Int']>;
  dailyActiveUsers_gte?: Maybe<Scalars['Int']>;
  dailyActiveUsers_lte?: Maybe<Scalars['Int']>;
  dailyActiveUsers_in?: Maybe<Array<Scalars['Int']>>;
  dailyActiveUsers_not_in?: Maybe<Array<Scalars['Int']>>;
  cumulativeUniqueUsers?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_not?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_gt?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_lt?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_gte?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_lte?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_in?: Maybe<Array<Scalars['Int']>>;
  cumulativeUniqueUsers_not_in?: Maybe<Array<Scalars['Int']>>;
  dailyTransactionCount?: Maybe<Scalars['Int']>;
  dailyTransactionCount_not?: Maybe<Scalars['Int']>;
  dailyTransactionCount_gt?: Maybe<Scalars['Int']>;
  dailyTransactionCount_lt?: Maybe<Scalars['Int']>;
  dailyTransactionCount_gte?: Maybe<Scalars['Int']>;
  dailyTransactionCount_lte?: Maybe<Scalars['Int']>;
  dailyTransactionCount_in?: Maybe<Array<Scalars['Int']>>;
  dailyTransactionCount_not_in?: Maybe<Array<Scalars['Int']>>;
  dailyDepositCount?: Maybe<Scalars['Int']>;
  dailyDepositCount_not?: Maybe<Scalars['Int']>;
  dailyDepositCount_gt?: Maybe<Scalars['Int']>;
  dailyDepositCount_lt?: Maybe<Scalars['Int']>;
  dailyDepositCount_gte?: Maybe<Scalars['Int']>;
  dailyDepositCount_lte?: Maybe<Scalars['Int']>;
  dailyDepositCount_in?: Maybe<Array<Scalars['Int']>>;
  dailyDepositCount_not_in?: Maybe<Array<Scalars['Int']>>;
  dailyWithdrawCount?: Maybe<Scalars['Int']>;
  dailyWithdrawCount_not?: Maybe<Scalars['Int']>;
  dailyWithdrawCount_gt?: Maybe<Scalars['Int']>;
  dailyWithdrawCount_lt?: Maybe<Scalars['Int']>;
  dailyWithdrawCount_gte?: Maybe<Scalars['Int']>;
  dailyWithdrawCount_lte?: Maybe<Scalars['Int']>;
  dailyWithdrawCount_in?: Maybe<Array<Scalars['Int']>>;
  dailyWithdrawCount_not_in?: Maybe<Array<Scalars['Int']>>;
  totalPoolCount?: Maybe<Scalars['Int']>;
  totalPoolCount_not?: Maybe<Scalars['Int']>;
  totalPoolCount_gt?: Maybe<Scalars['Int']>;
  totalPoolCount_lt?: Maybe<Scalars['Int']>;
  totalPoolCount_gte?: Maybe<Scalars['Int']>;
  totalPoolCount_lte?: Maybe<Scalars['Int']>;
  totalPoolCount_in?: Maybe<Array<Scalars['Int']>>;
  totalPoolCount_not_in?: Maybe<Array<Scalars['Int']>>;
  blockNumber?: Maybe<Scalars['BigInt']>;
  blockNumber_not?: Maybe<Scalars['BigInt']>;
  blockNumber_gt?: Maybe<Scalars['BigInt']>;
  blockNumber_lt?: Maybe<Scalars['BigInt']>;
  blockNumber_gte?: Maybe<Scalars['BigInt']>;
  blockNumber_lte?: Maybe<Scalars['BigInt']>;
  blockNumber_in?: Maybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<KodiakUsageMetricsDailySnapshot_Filter>>>;
  or?: Maybe<Array<Maybe<KodiakUsageMetricsDailySnapshot_Filter>>>;
};

export enum KodiakUsageMetricsDailySnapshot_OrderBy {
  Id = 'id',
  Protocol = 'protocol',
  ProtocolId = 'protocol__id',
  ProtocolName = 'protocol__name',
  ProtocolSlug = 'protocol__slug',
  ProtocolSchemaVersion = 'protocol__schemaVersion',
  ProtocolSubgraphVersion = 'protocol__subgraphVersion',
  ProtocolMethodologyVersion = 'protocol__methodologyVersion',
  ProtocolNetwork = 'protocol__network',
  ProtocolType = 'protocol__type',
  ProtocolTotalValueLockedUsd = 'protocol__totalValueLockedUSD',
  ProtocolProtocolControlledValueUsd = 'protocol__protocolControlledValueUSD',
  ProtocolCumulativeSupplySideRevenueUsd = 'protocol__cumulativeSupplySideRevenueUSD',
  ProtocolCumulativeProtocolSideRevenueUsd = 'protocol__cumulativeProtocolSideRevenueUSD',
  ProtocolCumulativeTotalRevenueUsd = 'protocol__cumulativeTotalRevenueUSD',
  ProtocolCumulativeUniqueUsers = 'protocol__cumulativeUniqueUsers',
  ProtocolTotalPoolCount = 'protocol__totalPoolCount',
  DailyActiveUsers = 'dailyActiveUsers',
  CumulativeUniqueUsers = 'cumulativeUniqueUsers',
  DailyTransactionCount = 'dailyTransactionCount',
  DailyDepositCount = 'dailyDepositCount',
  DailyWithdrawCount = 'dailyWithdrawCount',
  TotalPoolCount = 'totalPoolCount',
  BlockNumber = 'blockNumber',
  Timestamp = 'timestamp'
}

export type KodiakUsageMetricsHourlySnapshot = {
  __typename?: 'KodiakUsageMetricsHourlySnapshot';
  /**  { # of hours since Unix epoch time }  */
  id: Scalars['ID'];
  /**  Protocol this snapshot is associated with  */
  protocol: YieldAggregator;
  /**  Number of unique hourly active users  */
  hourlyActiveUsers: Scalars['Int'];
  /**  Number of cumulative unique users  */
  cumulativeUniqueUsers: Scalars['Int'];
  /**  Total number of transactions occurred in an hour. Transactions include all entities that implement the Event interface.  */
  hourlyTransactionCount: Scalars['Int'];
  /**  Total number of deposits in an hour  */
  hourlyDepositCount: Scalars['Int'];
  /**  Total number of withdrawals in an hour  */
  hourlyWithdrawCount: Scalars['Int'];
  /**  Block number of this snapshot  */
  blockNumber: Scalars['BigInt'];
  /**  Timestamp of this snapshot  */
  timestamp: Scalars['BigInt'];
};

export type KodiakUsageMetricsHourlySnapshot_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  protocol?: Maybe<Scalars['String']>;
  protocol_not?: Maybe<Scalars['String']>;
  protocol_gt?: Maybe<Scalars['String']>;
  protocol_lt?: Maybe<Scalars['String']>;
  protocol_gte?: Maybe<Scalars['String']>;
  protocol_lte?: Maybe<Scalars['String']>;
  protocol_in?: Maybe<Array<Scalars['String']>>;
  protocol_not_in?: Maybe<Array<Scalars['String']>>;
  protocol_contains?: Maybe<Scalars['String']>;
  protocol_contains_nocase?: Maybe<Scalars['String']>;
  protocol_not_contains?: Maybe<Scalars['String']>;
  protocol_not_contains_nocase?: Maybe<Scalars['String']>;
  protocol_starts_with?: Maybe<Scalars['String']>;
  protocol_starts_with_nocase?: Maybe<Scalars['String']>;
  protocol_not_starts_with?: Maybe<Scalars['String']>;
  protocol_not_starts_with_nocase?: Maybe<Scalars['String']>;
  protocol_ends_with?: Maybe<Scalars['String']>;
  protocol_ends_with_nocase?: Maybe<Scalars['String']>;
  protocol_not_ends_with?: Maybe<Scalars['String']>;
  protocol_not_ends_with_nocase?: Maybe<Scalars['String']>;
  protocol_?: Maybe<YieldAggregator_Filter>;
  hourlyActiveUsers?: Maybe<Scalars['Int']>;
  hourlyActiveUsers_not?: Maybe<Scalars['Int']>;
  hourlyActiveUsers_gt?: Maybe<Scalars['Int']>;
  hourlyActiveUsers_lt?: Maybe<Scalars['Int']>;
  hourlyActiveUsers_gte?: Maybe<Scalars['Int']>;
  hourlyActiveUsers_lte?: Maybe<Scalars['Int']>;
  hourlyActiveUsers_in?: Maybe<Array<Scalars['Int']>>;
  hourlyActiveUsers_not_in?: Maybe<Array<Scalars['Int']>>;
  cumulativeUniqueUsers?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_not?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_gt?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_lt?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_gte?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_lte?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_in?: Maybe<Array<Scalars['Int']>>;
  cumulativeUniqueUsers_not_in?: Maybe<Array<Scalars['Int']>>;
  hourlyTransactionCount?: Maybe<Scalars['Int']>;
  hourlyTransactionCount_not?: Maybe<Scalars['Int']>;
  hourlyTransactionCount_gt?: Maybe<Scalars['Int']>;
  hourlyTransactionCount_lt?: Maybe<Scalars['Int']>;
  hourlyTransactionCount_gte?: Maybe<Scalars['Int']>;
  hourlyTransactionCount_lte?: Maybe<Scalars['Int']>;
  hourlyTransactionCount_in?: Maybe<Array<Scalars['Int']>>;
  hourlyTransactionCount_not_in?: Maybe<Array<Scalars['Int']>>;
  hourlyDepositCount?: Maybe<Scalars['Int']>;
  hourlyDepositCount_not?: Maybe<Scalars['Int']>;
  hourlyDepositCount_gt?: Maybe<Scalars['Int']>;
  hourlyDepositCount_lt?: Maybe<Scalars['Int']>;
  hourlyDepositCount_gte?: Maybe<Scalars['Int']>;
  hourlyDepositCount_lte?: Maybe<Scalars['Int']>;
  hourlyDepositCount_in?: Maybe<Array<Scalars['Int']>>;
  hourlyDepositCount_not_in?: Maybe<Array<Scalars['Int']>>;
  hourlyWithdrawCount?: Maybe<Scalars['Int']>;
  hourlyWithdrawCount_not?: Maybe<Scalars['Int']>;
  hourlyWithdrawCount_gt?: Maybe<Scalars['Int']>;
  hourlyWithdrawCount_lt?: Maybe<Scalars['Int']>;
  hourlyWithdrawCount_gte?: Maybe<Scalars['Int']>;
  hourlyWithdrawCount_lte?: Maybe<Scalars['Int']>;
  hourlyWithdrawCount_in?: Maybe<Array<Scalars['Int']>>;
  hourlyWithdrawCount_not_in?: Maybe<Array<Scalars['Int']>>;
  blockNumber?: Maybe<Scalars['BigInt']>;
  blockNumber_not?: Maybe<Scalars['BigInt']>;
  blockNumber_gt?: Maybe<Scalars['BigInt']>;
  blockNumber_lt?: Maybe<Scalars['BigInt']>;
  blockNumber_gte?: Maybe<Scalars['BigInt']>;
  blockNumber_lte?: Maybe<Scalars['BigInt']>;
  blockNumber_in?: Maybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<KodiakUsageMetricsHourlySnapshot_Filter>>>;
  or?: Maybe<Array<Maybe<KodiakUsageMetricsHourlySnapshot_Filter>>>;
};

export enum KodiakUsageMetricsHourlySnapshot_OrderBy {
  Id = 'id',
  Protocol = 'protocol',
  ProtocolId = 'protocol__id',
  ProtocolName = 'protocol__name',
  ProtocolSlug = 'protocol__slug',
  ProtocolSchemaVersion = 'protocol__schemaVersion',
  ProtocolSubgraphVersion = 'protocol__subgraphVersion',
  ProtocolMethodologyVersion = 'protocol__methodologyVersion',
  ProtocolNetwork = 'protocol__network',
  ProtocolType = 'protocol__type',
  ProtocolTotalValueLockedUsd = 'protocol__totalValueLockedUSD',
  ProtocolProtocolControlledValueUsd = 'protocol__protocolControlledValueUSD',
  ProtocolCumulativeSupplySideRevenueUsd = 'protocol__cumulativeSupplySideRevenueUSD',
  ProtocolCumulativeProtocolSideRevenueUsd = 'protocol__cumulativeProtocolSideRevenueUSD',
  ProtocolCumulativeTotalRevenueUsd = 'protocol__cumulativeTotalRevenueUSD',
  ProtocolCumulativeUniqueUsers = 'protocol__cumulativeUniqueUsers',
  ProtocolTotalPoolCount = 'protocol__totalPoolCount',
  HourlyActiveUsers = 'hourlyActiveUsers',
  CumulativeUniqueUsers = 'cumulativeUniqueUsers',
  HourlyTransactionCount = 'hourlyTransactionCount',
  HourlyDepositCount = 'hourlyDepositCount',
  HourlyWithdrawCount = 'hourlyWithdrawCount',
  BlockNumber = 'blockNumber',
  Timestamp = 'timestamp'
}

export type KodiakVault = {
  __typename?: 'KodiakVault';
  /**  Smart contract address of the vault  */
  id: Scalars['ID'];
  /**  The protocol this vault belongs to  */
  protocol: YieldAggregator;
  /**  Name of liquidity pool (e.g. Curve.fi DAI/USDC/USDT)  */
  name?: Maybe<Scalars['String']>;
  /**  Symbol of liquidity pool (e.g. 3CRV)  */
  symbol?: Maybe<Scalars['String']>;
  /**  Token that need to be deposited to take a position in protocol  */
  inputToken: Token;
  /**  Token that is minted to track ownership of position in protocol  */
  outputToken?: Maybe<Token>;
  /**  Aditional tokens that are given as reward for position in a protocol, usually in liquidity mining programs. e.g. SUSHI in the Onsen program, MATIC for Aave Polygon  */
  rewardTokens?: Maybe<Array<KodiakRewardToken>>;
  /**  Some vaults have a deposit cap. This is in input token amount  */
  depositLimit: Scalars['BigInt'];
  /**  Type of fees incurred to the user. Should include all fees that apply to the vault  */
  fees: Array<KodiakVaultFee>;
  /**  Creation timestamp  */
  createdTimestamp: Scalars['BigInt'];
  /**  Creation block number  */
  createdBlockNumber: Scalars['BigInt'];
  /**  Current TVL (Total Value Locked) of this pool in USD  */
  totalValueLockedUSD: Scalars['BigDecimal'];
  /**  All revenue generated by the vault, accrued to the supply side.  */
  cumulativeSupplySideRevenueUSD: Scalars['BigDecimal'];
  /**  All revenue generated by the vault, accrued to the protocol.  */
  cumulativeProtocolSideRevenueUSD: Scalars['BigDecimal'];
  /**  All revenue generated by the vault.  */
  cumulativeTotalRevenueUSD: Scalars['BigDecimal'];
  /**  Amount of input token in the pool  */
  inputTokenBalance: Scalars['BigInt'];
  /**  Total supply of output token  */
  outputTokenSupply?: Maybe<Scalars['BigInt']>;
  /**  Price per share of output token in USD  */
  outputTokenPriceUSD?: Maybe<Scalars['BigDecimal']>;
  /**  Amount of input token per full share of output token. Usually corresponds to the value of `pricePerShare` or `pricePerFullShare` in the vault contract.  */
  pricePerShare?: Maybe<Scalars['BigDecimal']>;
  /**  Total supply of output tokens that are staked (usually in the MasterChef contract). Used to calculate reward APY.  */
  stakedOutputTokenAmount?: Maybe<Scalars['BigInt']>;
  /**  Per-block reward token emission as of the current block normalized to a day, in token's native amount. This should be ideally calculated as the theoretical rate instead of the realized amount.  */
  rewardTokenEmissionsAmount?: Maybe<Array<Scalars['BigInt']>>;
  /**  Per-block reward token emission as of the current block normalized to a day, in USD value. This should be ideally calculated as the theoretical rate instead of the realized amount.  */
  rewardTokenEmissionsUSD?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken0: Scalars['BigDecimal'];
  volumeToken1: Scalars['BigDecimal'];
  volumeUSD: Scalars['BigDecimal'];
  /**  Vault daily snapshots  */
  dailySnapshots: Array<KodiakVaultDailySnapshot>;
  /**  Vault hourly snapshots  */
  hourlySnapshots: Array<KodiakVaultHourlySnapshot>;
  /**  All deposits made to this vault  */
  deposits: Array<KodiakDeposit>;
  /**  All withdrawals made from this vault  */
  withdraws: Array<KodiakWithdraw>;
  pool?: Maybe<Pool>;
  apr: KodiakApr;
  lowerTick: Scalars['BigInt'];
  upperTick: Scalars['BigInt'];
  /**  token0 of vault  */
  _token0: Token;
  /**  token1 of vault  */
  _token1: Token;
  /**  token0 amount */
  _token0Amount: Scalars['BigInt'];
  /**  token1 amount  */
  _token1Amount: Scalars['BigInt'];
  /**  token0 amount USD  */
  _token0AmountUSD: Scalars['BigDecimal'];
  /**  token1 amount USD  */
  _token1AmountUSD: Scalars['BigDecimal'];
};


export type KodiakVaultRewardTokensArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakRewardToken_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakRewardToken_Filter>;
};


export type KodiakVaultFeesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakVaultFee_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakVaultFee_Filter>;
};


export type KodiakVaultDailySnapshotsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakVaultDailySnapshot_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakVaultDailySnapshot_Filter>;
};


export type KodiakVaultHourlySnapshotsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakVaultHourlySnapshot_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakVaultHourlySnapshot_Filter>;
};


export type KodiakVaultDepositsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakDeposit_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakDeposit_Filter>;
};


export type KodiakVaultWithdrawsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakWithdraw_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakWithdraw_Filter>;
};

export type KodiakVaultDailySnapshot = {
  __typename?: 'KodiakVaultDailySnapshot';
  /**  { Smart contract address of the vault }-{ # of days since Unix epoch time }  */
  id: Scalars['ID'];
  /**  The protocol this snapshot belongs to  */
  protocol: YieldAggregator;
  /**  The vault this snapshot belongs to  */
  vault: KodiakVault;
  /**  Current TVL (Total Value Locked) of this pool in USD  */
  totalValueLockedUSD: Scalars['BigDecimal'];
  /**  All revenue generated by the vault, accrued to the supply side.  */
  cumulativeSupplySideRevenueUSD: Scalars['BigDecimal'];
  /**  Daily revenue generated by the vault, accrued to the supply side.  */
  dailySupplySideRevenueUSD: Scalars['BigDecimal'];
  /**  All revenue generated by the vault, accrued to the protocol.  */
  cumulativeProtocolSideRevenueUSD: Scalars['BigDecimal'];
  /**  Daily revenue generated by the vault, accrued to the protocol.  */
  dailyProtocolSideRevenueUSD: Scalars['BigDecimal'];
  /**  All revenue generated by the vault.  */
  cumulativeTotalRevenueUSD: Scalars['BigDecimal'];
  /**  Daily revenue generated by the vault.  */
  dailyTotalRevenueUSD: Scalars['BigDecimal'];
  /**  Amount of input token in the pool  */
  inputTokenBalance: Scalars['BigInt'];
  /**  Total supply of output token  */
  outputTokenSupply: Scalars['BigInt'];
  /**  Price per share of output token in USD  */
  outputTokenPriceUSD?: Maybe<Scalars['BigDecimal']>;
  /**  Amount of input token per full share of output token. Usually corresponds to the value of `pricePerShare` or `pricePerFullShare` in the vault contract.  */
  pricePerShare?: Maybe<Scalars['BigDecimal']>;
  /**  Total supply of output tokens that are staked (usually in the MasterChef contract). Used to calculate reward APY.  */
  stakedOutputTokenAmount?: Maybe<Scalars['BigInt']>;
  /**  Per-block reward token emission as of the current block normalized to a day, in token's native amount. This should be ideally calculated as the theoretical rate instead of the realized amount.  */
  rewardTokenEmissionsAmount?: Maybe<Array<Scalars['BigInt']>>;
  /**  Per-block reward token emission as of the current block normalized to a day, in USD value. This should be ideally calculated as the theoretical rate instead of the realized amount.  */
  rewardTokenEmissionsUSD?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD: Scalars['BigDecimal'];
  volumeToken0: Scalars['BigDecimal'];
  volumeToken1: Scalars['BigDecimal'];
  /**  Annual Percentage Rate  */
  apr: Scalars['BigDecimal'];
  /**  Block number of this snapshot  */
  blockNumber: Scalars['BigInt'];
  /**  Timestamp of this snapshot  */
  timestamp: Scalars['BigInt'];
  lowerTick: Scalars['BigInt'];
  upperTick: Scalars['BigInt'];
  tickChanges?: Maybe<Array<KodiakStrategyChange>>;
  _baseLowerTick: Scalars['BigInt'];
  _baseUpperTick: Scalars['BigInt'];
  /**  token0 of vault  */
  _token0: Token;
  /**  token1 of vault  */
  _token1: Token;
  /**  token0 amount */
  _token0Amount: Scalars['BigInt'];
  /**  token1 amount  */
  _token1Amount: Scalars['BigInt'];
  /**  token0 amount USD  */
  _token0AmountUSD: Scalars['BigDecimal'];
  /**  token1 amount USD  */
  _token1AmountUSD: Scalars['BigDecimal'];
};


export type KodiakVaultDailySnapshotTickChangesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakStrategyChange_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakStrategyChange_Filter>;
};

export type KodiakVaultDailySnapshot_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  protocol?: Maybe<Scalars['String']>;
  protocol_not?: Maybe<Scalars['String']>;
  protocol_gt?: Maybe<Scalars['String']>;
  protocol_lt?: Maybe<Scalars['String']>;
  protocol_gte?: Maybe<Scalars['String']>;
  protocol_lte?: Maybe<Scalars['String']>;
  protocol_in?: Maybe<Array<Scalars['String']>>;
  protocol_not_in?: Maybe<Array<Scalars['String']>>;
  protocol_contains?: Maybe<Scalars['String']>;
  protocol_contains_nocase?: Maybe<Scalars['String']>;
  protocol_not_contains?: Maybe<Scalars['String']>;
  protocol_not_contains_nocase?: Maybe<Scalars['String']>;
  protocol_starts_with?: Maybe<Scalars['String']>;
  protocol_starts_with_nocase?: Maybe<Scalars['String']>;
  protocol_not_starts_with?: Maybe<Scalars['String']>;
  protocol_not_starts_with_nocase?: Maybe<Scalars['String']>;
  protocol_ends_with?: Maybe<Scalars['String']>;
  protocol_ends_with_nocase?: Maybe<Scalars['String']>;
  protocol_not_ends_with?: Maybe<Scalars['String']>;
  protocol_not_ends_with_nocase?: Maybe<Scalars['String']>;
  protocol_?: Maybe<YieldAggregator_Filter>;
  vault?: Maybe<Scalars['String']>;
  vault_not?: Maybe<Scalars['String']>;
  vault_gt?: Maybe<Scalars['String']>;
  vault_lt?: Maybe<Scalars['String']>;
  vault_gte?: Maybe<Scalars['String']>;
  vault_lte?: Maybe<Scalars['String']>;
  vault_in?: Maybe<Array<Scalars['String']>>;
  vault_not_in?: Maybe<Array<Scalars['String']>>;
  vault_contains?: Maybe<Scalars['String']>;
  vault_contains_nocase?: Maybe<Scalars['String']>;
  vault_not_contains?: Maybe<Scalars['String']>;
  vault_not_contains_nocase?: Maybe<Scalars['String']>;
  vault_starts_with?: Maybe<Scalars['String']>;
  vault_starts_with_nocase?: Maybe<Scalars['String']>;
  vault_not_starts_with?: Maybe<Scalars['String']>;
  vault_not_starts_with_nocase?: Maybe<Scalars['String']>;
  vault_ends_with?: Maybe<Scalars['String']>;
  vault_ends_with_nocase?: Maybe<Scalars['String']>;
  vault_not_ends_with?: Maybe<Scalars['String']>;
  vault_not_ends_with_nocase?: Maybe<Scalars['String']>;
  vault_?: Maybe<KodiakVault_Filter>;
  totalValueLockedUSD?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_not?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_gt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_lt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_gte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_lte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeSupplySideRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeSupplySideRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  dailySupplySideRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  dailySupplySideRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  dailySupplySideRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  dailySupplySideRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  dailySupplySideRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  dailySupplySideRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  dailySupplySideRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  dailySupplySideRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeProtocolSideRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeProtocolSideRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  dailyProtocolSideRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  dailyProtocolSideRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  dailyProtocolSideRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  dailyProtocolSideRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  dailyProtocolSideRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  dailyProtocolSideRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  dailyProtocolSideRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  dailyProtocolSideRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeTotalRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeTotalRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  dailyTotalRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  dailyTotalRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  dailyTotalRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  dailyTotalRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  dailyTotalRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  dailyTotalRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  dailyTotalRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  dailyTotalRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  inputTokenBalance?: Maybe<Scalars['BigInt']>;
  inputTokenBalance_not?: Maybe<Scalars['BigInt']>;
  inputTokenBalance_gt?: Maybe<Scalars['BigInt']>;
  inputTokenBalance_lt?: Maybe<Scalars['BigInt']>;
  inputTokenBalance_gte?: Maybe<Scalars['BigInt']>;
  inputTokenBalance_lte?: Maybe<Scalars['BigInt']>;
  inputTokenBalance_in?: Maybe<Array<Scalars['BigInt']>>;
  inputTokenBalance_not_in?: Maybe<Array<Scalars['BigInt']>>;
  outputTokenSupply?: Maybe<Scalars['BigInt']>;
  outputTokenSupply_not?: Maybe<Scalars['BigInt']>;
  outputTokenSupply_gt?: Maybe<Scalars['BigInt']>;
  outputTokenSupply_lt?: Maybe<Scalars['BigInt']>;
  outputTokenSupply_gte?: Maybe<Scalars['BigInt']>;
  outputTokenSupply_lte?: Maybe<Scalars['BigInt']>;
  outputTokenSupply_in?: Maybe<Array<Scalars['BigInt']>>;
  outputTokenSupply_not_in?: Maybe<Array<Scalars['BigInt']>>;
  outputTokenPriceUSD?: Maybe<Scalars['BigDecimal']>;
  outputTokenPriceUSD_not?: Maybe<Scalars['BigDecimal']>;
  outputTokenPriceUSD_gt?: Maybe<Scalars['BigDecimal']>;
  outputTokenPriceUSD_lt?: Maybe<Scalars['BigDecimal']>;
  outputTokenPriceUSD_gte?: Maybe<Scalars['BigDecimal']>;
  outputTokenPriceUSD_lte?: Maybe<Scalars['BigDecimal']>;
  outputTokenPriceUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  outputTokenPriceUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  pricePerShare?: Maybe<Scalars['BigDecimal']>;
  pricePerShare_not?: Maybe<Scalars['BigDecimal']>;
  pricePerShare_gt?: Maybe<Scalars['BigDecimal']>;
  pricePerShare_lt?: Maybe<Scalars['BigDecimal']>;
  pricePerShare_gte?: Maybe<Scalars['BigDecimal']>;
  pricePerShare_lte?: Maybe<Scalars['BigDecimal']>;
  pricePerShare_in?: Maybe<Array<Scalars['BigDecimal']>>;
  pricePerShare_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  stakedOutputTokenAmount?: Maybe<Scalars['BigInt']>;
  stakedOutputTokenAmount_not?: Maybe<Scalars['BigInt']>;
  stakedOutputTokenAmount_gt?: Maybe<Scalars['BigInt']>;
  stakedOutputTokenAmount_lt?: Maybe<Scalars['BigInt']>;
  stakedOutputTokenAmount_gte?: Maybe<Scalars['BigInt']>;
  stakedOutputTokenAmount_lte?: Maybe<Scalars['BigInt']>;
  stakedOutputTokenAmount_in?: Maybe<Array<Scalars['BigInt']>>;
  stakedOutputTokenAmount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  rewardTokenEmissionsAmount?: Maybe<Array<Scalars['BigInt']>>;
  rewardTokenEmissionsAmount_not?: Maybe<Array<Scalars['BigInt']>>;
  rewardTokenEmissionsAmount_contains?: Maybe<Array<Scalars['BigInt']>>;
  rewardTokenEmissionsAmount_contains_nocase?: Maybe<Array<Scalars['BigInt']>>;
  rewardTokenEmissionsAmount_not_contains?: Maybe<Array<Scalars['BigInt']>>;
  rewardTokenEmissionsAmount_not_contains_nocase?: Maybe<Array<Scalars['BigInt']>>;
  rewardTokenEmissionsUSD?: Maybe<Array<Scalars['BigDecimal']>>;
  rewardTokenEmissionsUSD_not?: Maybe<Array<Scalars['BigDecimal']>>;
  rewardTokenEmissionsUSD_contains?: Maybe<Array<Scalars['BigDecimal']>>;
  rewardTokenEmissionsUSD_contains_nocase?: Maybe<Array<Scalars['BigDecimal']>>;
  rewardTokenEmissionsUSD_not_contains?: Maybe<Array<Scalars['BigDecimal']>>;
  rewardTokenEmissionsUSD_not_contains_nocase?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_not?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken0?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_not?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_gt?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_lt?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_gte?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_lte?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken1?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_not?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_gt?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_lt?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_gte?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_lte?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  apr?: Maybe<Scalars['BigDecimal']>;
  apr_not?: Maybe<Scalars['BigDecimal']>;
  apr_gt?: Maybe<Scalars['BigDecimal']>;
  apr_lt?: Maybe<Scalars['BigDecimal']>;
  apr_gte?: Maybe<Scalars['BigDecimal']>;
  apr_lte?: Maybe<Scalars['BigDecimal']>;
  apr_in?: Maybe<Array<Scalars['BigDecimal']>>;
  apr_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  blockNumber?: Maybe<Scalars['BigInt']>;
  blockNumber_not?: Maybe<Scalars['BigInt']>;
  blockNumber_gt?: Maybe<Scalars['BigInt']>;
  blockNumber_lt?: Maybe<Scalars['BigInt']>;
  blockNumber_gte?: Maybe<Scalars['BigInt']>;
  blockNumber_lte?: Maybe<Scalars['BigInt']>;
  blockNumber_in?: Maybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  lowerTick?: Maybe<Scalars['BigInt']>;
  lowerTick_not?: Maybe<Scalars['BigInt']>;
  lowerTick_gt?: Maybe<Scalars['BigInt']>;
  lowerTick_lt?: Maybe<Scalars['BigInt']>;
  lowerTick_gte?: Maybe<Scalars['BigInt']>;
  lowerTick_lte?: Maybe<Scalars['BigInt']>;
  lowerTick_in?: Maybe<Array<Scalars['BigInt']>>;
  lowerTick_not_in?: Maybe<Array<Scalars['BigInt']>>;
  upperTick?: Maybe<Scalars['BigInt']>;
  upperTick_not?: Maybe<Scalars['BigInt']>;
  upperTick_gt?: Maybe<Scalars['BigInt']>;
  upperTick_lt?: Maybe<Scalars['BigInt']>;
  upperTick_gte?: Maybe<Scalars['BigInt']>;
  upperTick_lte?: Maybe<Scalars['BigInt']>;
  upperTick_in?: Maybe<Array<Scalars['BigInt']>>;
  upperTick_not_in?: Maybe<Array<Scalars['BigInt']>>;
  tickChanges?: Maybe<Array<Scalars['String']>>;
  tickChanges_not?: Maybe<Array<Scalars['String']>>;
  tickChanges_contains?: Maybe<Array<Scalars['String']>>;
  tickChanges_contains_nocase?: Maybe<Array<Scalars['String']>>;
  tickChanges_not_contains?: Maybe<Array<Scalars['String']>>;
  tickChanges_not_contains_nocase?: Maybe<Array<Scalars['String']>>;
  tickChanges_?: Maybe<KodiakStrategyChange_Filter>;
  _baseLowerTick?: Maybe<Scalars['BigInt']>;
  _baseLowerTick_not?: Maybe<Scalars['BigInt']>;
  _baseLowerTick_gt?: Maybe<Scalars['BigInt']>;
  _baseLowerTick_lt?: Maybe<Scalars['BigInt']>;
  _baseLowerTick_gte?: Maybe<Scalars['BigInt']>;
  _baseLowerTick_lte?: Maybe<Scalars['BigInt']>;
  _baseLowerTick_in?: Maybe<Array<Scalars['BigInt']>>;
  _baseLowerTick_not_in?: Maybe<Array<Scalars['BigInt']>>;
  _baseUpperTick?: Maybe<Scalars['BigInt']>;
  _baseUpperTick_not?: Maybe<Scalars['BigInt']>;
  _baseUpperTick_gt?: Maybe<Scalars['BigInt']>;
  _baseUpperTick_lt?: Maybe<Scalars['BigInt']>;
  _baseUpperTick_gte?: Maybe<Scalars['BigInt']>;
  _baseUpperTick_lte?: Maybe<Scalars['BigInt']>;
  _baseUpperTick_in?: Maybe<Array<Scalars['BigInt']>>;
  _baseUpperTick_not_in?: Maybe<Array<Scalars['BigInt']>>;
  _token0?: Maybe<Scalars['String']>;
  _token0_not?: Maybe<Scalars['String']>;
  _token0_gt?: Maybe<Scalars['String']>;
  _token0_lt?: Maybe<Scalars['String']>;
  _token0_gte?: Maybe<Scalars['String']>;
  _token0_lte?: Maybe<Scalars['String']>;
  _token0_in?: Maybe<Array<Scalars['String']>>;
  _token0_not_in?: Maybe<Array<Scalars['String']>>;
  _token0_contains?: Maybe<Scalars['String']>;
  _token0_contains_nocase?: Maybe<Scalars['String']>;
  _token0_not_contains?: Maybe<Scalars['String']>;
  _token0_not_contains_nocase?: Maybe<Scalars['String']>;
  _token0_starts_with?: Maybe<Scalars['String']>;
  _token0_starts_with_nocase?: Maybe<Scalars['String']>;
  _token0_not_starts_with?: Maybe<Scalars['String']>;
  _token0_not_starts_with_nocase?: Maybe<Scalars['String']>;
  _token0_ends_with?: Maybe<Scalars['String']>;
  _token0_ends_with_nocase?: Maybe<Scalars['String']>;
  _token0_not_ends_with?: Maybe<Scalars['String']>;
  _token0_not_ends_with_nocase?: Maybe<Scalars['String']>;
  _token0_?: Maybe<Token_Filter>;
  _token1?: Maybe<Scalars['String']>;
  _token1_not?: Maybe<Scalars['String']>;
  _token1_gt?: Maybe<Scalars['String']>;
  _token1_lt?: Maybe<Scalars['String']>;
  _token1_gte?: Maybe<Scalars['String']>;
  _token1_lte?: Maybe<Scalars['String']>;
  _token1_in?: Maybe<Array<Scalars['String']>>;
  _token1_not_in?: Maybe<Array<Scalars['String']>>;
  _token1_contains?: Maybe<Scalars['String']>;
  _token1_contains_nocase?: Maybe<Scalars['String']>;
  _token1_not_contains?: Maybe<Scalars['String']>;
  _token1_not_contains_nocase?: Maybe<Scalars['String']>;
  _token1_starts_with?: Maybe<Scalars['String']>;
  _token1_starts_with_nocase?: Maybe<Scalars['String']>;
  _token1_not_starts_with?: Maybe<Scalars['String']>;
  _token1_not_starts_with_nocase?: Maybe<Scalars['String']>;
  _token1_ends_with?: Maybe<Scalars['String']>;
  _token1_ends_with_nocase?: Maybe<Scalars['String']>;
  _token1_not_ends_with?: Maybe<Scalars['String']>;
  _token1_not_ends_with_nocase?: Maybe<Scalars['String']>;
  _token1_?: Maybe<Token_Filter>;
  _token0Amount?: Maybe<Scalars['BigInt']>;
  _token0Amount_not?: Maybe<Scalars['BigInt']>;
  _token0Amount_gt?: Maybe<Scalars['BigInt']>;
  _token0Amount_lt?: Maybe<Scalars['BigInt']>;
  _token0Amount_gte?: Maybe<Scalars['BigInt']>;
  _token0Amount_lte?: Maybe<Scalars['BigInt']>;
  _token0Amount_in?: Maybe<Array<Scalars['BigInt']>>;
  _token0Amount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  _token1Amount?: Maybe<Scalars['BigInt']>;
  _token1Amount_not?: Maybe<Scalars['BigInt']>;
  _token1Amount_gt?: Maybe<Scalars['BigInt']>;
  _token1Amount_lt?: Maybe<Scalars['BigInt']>;
  _token1Amount_gte?: Maybe<Scalars['BigInt']>;
  _token1Amount_lte?: Maybe<Scalars['BigInt']>;
  _token1Amount_in?: Maybe<Array<Scalars['BigInt']>>;
  _token1Amount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  _token0AmountUSD?: Maybe<Scalars['BigDecimal']>;
  _token0AmountUSD_not?: Maybe<Scalars['BigDecimal']>;
  _token0AmountUSD_gt?: Maybe<Scalars['BigDecimal']>;
  _token0AmountUSD_lt?: Maybe<Scalars['BigDecimal']>;
  _token0AmountUSD_gte?: Maybe<Scalars['BigDecimal']>;
  _token0AmountUSD_lte?: Maybe<Scalars['BigDecimal']>;
  _token0AmountUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  _token0AmountUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  _token1AmountUSD?: Maybe<Scalars['BigDecimal']>;
  _token1AmountUSD_not?: Maybe<Scalars['BigDecimal']>;
  _token1AmountUSD_gt?: Maybe<Scalars['BigDecimal']>;
  _token1AmountUSD_lt?: Maybe<Scalars['BigDecimal']>;
  _token1AmountUSD_gte?: Maybe<Scalars['BigDecimal']>;
  _token1AmountUSD_lte?: Maybe<Scalars['BigDecimal']>;
  _token1AmountUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  _token1AmountUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<KodiakVaultDailySnapshot_Filter>>>;
  or?: Maybe<Array<Maybe<KodiakVaultDailySnapshot_Filter>>>;
};

export enum KodiakVaultDailySnapshot_OrderBy {
  Id = 'id',
  Protocol = 'protocol',
  ProtocolId = 'protocol__id',
  ProtocolName = 'protocol__name',
  ProtocolSlug = 'protocol__slug',
  ProtocolSchemaVersion = 'protocol__schemaVersion',
  ProtocolSubgraphVersion = 'protocol__subgraphVersion',
  ProtocolMethodologyVersion = 'protocol__methodologyVersion',
  ProtocolNetwork = 'protocol__network',
  ProtocolType = 'protocol__type',
  ProtocolTotalValueLockedUsd = 'protocol__totalValueLockedUSD',
  ProtocolProtocolControlledValueUsd = 'protocol__protocolControlledValueUSD',
  ProtocolCumulativeSupplySideRevenueUsd = 'protocol__cumulativeSupplySideRevenueUSD',
  ProtocolCumulativeProtocolSideRevenueUsd = 'protocol__cumulativeProtocolSideRevenueUSD',
  ProtocolCumulativeTotalRevenueUsd = 'protocol__cumulativeTotalRevenueUSD',
  ProtocolCumulativeUniqueUsers = 'protocol__cumulativeUniqueUsers',
  ProtocolTotalPoolCount = 'protocol__totalPoolCount',
  Vault = 'vault',
  VaultId = 'vault__id',
  VaultName = 'vault__name',
  VaultSymbol = 'vault__symbol',
  VaultDepositLimit = 'vault__depositLimit',
  VaultCreatedTimestamp = 'vault__createdTimestamp',
  VaultCreatedBlockNumber = 'vault__createdBlockNumber',
  VaultTotalValueLockedUsd = 'vault__totalValueLockedUSD',
  VaultCumulativeSupplySideRevenueUsd = 'vault__cumulativeSupplySideRevenueUSD',
  VaultCumulativeProtocolSideRevenueUsd = 'vault__cumulativeProtocolSideRevenueUSD',
  VaultCumulativeTotalRevenueUsd = 'vault__cumulativeTotalRevenueUSD',
  VaultInputTokenBalance = 'vault__inputTokenBalance',
  VaultOutputTokenSupply = 'vault__outputTokenSupply',
  VaultOutputTokenPriceUsd = 'vault__outputTokenPriceUSD',
  VaultPricePerShare = 'vault__pricePerShare',
  VaultStakedOutputTokenAmount = 'vault__stakedOutputTokenAmount',
  VaultVolumeToken0 = 'vault__volumeToken0',
  VaultVolumeToken1 = 'vault__volumeToken1',
  VaultVolumeUsd = 'vault__volumeUSD',
  VaultLowerTick = 'vault__lowerTick',
  VaultUpperTick = 'vault__upperTick',
  VaultToken0Amount = 'vault___token0Amount',
  VaultToken1Amount = 'vault___token1Amount',
  VaultToken0AmountUsd = 'vault___token0AmountUSD',
  VaultToken1AmountUsd = 'vault___token1AmountUSD',
  TotalValueLockedUsd = 'totalValueLockedUSD',
  CumulativeSupplySideRevenueUsd = 'cumulativeSupplySideRevenueUSD',
  DailySupplySideRevenueUsd = 'dailySupplySideRevenueUSD',
  CumulativeProtocolSideRevenueUsd = 'cumulativeProtocolSideRevenueUSD',
  DailyProtocolSideRevenueUsd = 'dailyProtocolSideRevenueUSD',
  CumulativeTotalRevenueUsd = 'cumulativeTotalRevenueUSD',
  DailyTotalRevenueUsd = 'dailyTotalRevenueUSD',
  InputTokenBalance = 'inputTokenBalance',
  OutputTokenSupply = 'outputTokenSupply',
  OutputTokenPriceUsd = 'outputTokenPriceUSD',
  PricePerShare = 'pricePerShare',
  StakedOutputTokenAmount = 'stakedOutputTokenAmount',
  RewardTokenEmissionsAmount = 'rewardTokenEmissionsAmount',
  RewardTokenEmissionsUsd = 'rewardTokenEmissionsUSD',
  VolumeUsd = 'volumeUSD',
  VolumeToken0 = 'volumeToken0',
  VolumeToken1 = 'volumeToken1',
  Apr = 'apr',
  BlockNumber = 'blockNumber',
  Timestamp = 'timestamp',
  LowerTick = 'lowerTick',
  UpperTick = 'upperTick',
  TickChanges = 'tickChanges',
  BaseLowerTick = '_baseLowerTick',
  BaseUpperTick = '_baseUpperTick',
  Token0 = '_token0',
  Token0Id = '_token0__id',
  Token0Symbol = '_token0__symbol',
  Token0Name = '_token0__name',
  Token0Decimals = '_token0__decimals',
  Token0TotalSupply = '_token0__totalSupply',
  Token0Volume = '_token0__volume',
  Token0VolumeUsd = '_token0__volumeUSD',
  Token0UntrackedVolumeUsd = '_token0__untrackedVolumeUSD',
  Token0FeesUsd = '_token0__feesUSD',
  Token0TxCount = '_token0__txCount',
  Token0PoolCount = '_token0__poolCount',
  Token0TotalValueLocked = '_token0__totalValueLocked',
  Token0TotalValueLockedUsd = '_token0__totalValueLockedUSD',
  Token0TotalValueLockedUsdUntracked = '_token0__totalValueLockedUSDUntracked',
  Token0DerivedEth = '_token0__derivedETH',
  Token1 = '_token1',
  Token1Id = '_token1__id',
  Token1Symbol = '_token1__symbol',
  Token1Name = '_token1__name',
  Token1Decimals = '_token1__decimals',
  Token1TotalSupply = '_token1__totalSupply',
  Token1Volume = '_token1__volume',
  Token1VolumeUsd = '_token1__volumeUSD',
  Token1UntrackedVolumeUsd = '_token1__untrackedVolumeUSD',
  Token1FeesUsd = '_token1__feesUSD',
  Token1TxCount = '_token1__txCount',
  Token1PoolCount = '_token1__poolCount',
  Token1TotalValueLocked = '_token1__totalValueLocked',
  Token1TotalValueLockedUsd = '_token1__totalValueLockedUSD',
  Token1TotalValueLockedUsdUntracked = '_token1__totalValueLockedUSDUntracked',
  Token1DerivedEth = '_token1__derivedETH',
  Token0Amount = '_token0Amount',
  Token1Amount = '_token1Amount',
  Token0AmountUsd = '_token0AmountUSD',
  Token1AmountUsd = '_token1AmountUSD'
}

export type KodiakVaultFee = {
  __typename?: 'KodiakVaultFee';
  /**  { Vault fee type }-{ Vault address }  */
  id: Scalars['ID'];
  /**  Fee as a percentage of the trade (swap) amount. Does not always apply  */
  feePercentage?: Maybe<Scalars['BigDecimal']>;
  /**  Type of fee this vault uses  */
  feeType: KodiakVaultFeeType;
};

export enum KodiakVaultFeeType {
  /**  One-time fee charged by the protocol during deposit, in percentages of the deposit token  */
  DepositFee = 'DEPOSIT_FEE',
  /**  One-time fee charged by the protocol during withdrawal, in percentages of the withdrawal token  */
  WithdrawalFee = 'WITHDRAWAL_FEE',
  /**  Fees charged by the protocol during harvest, in percentages of the interest accrued  */
  PerformanceFee = 'PERFORMANCE_FEE',
  /**  Fees charged by the protocol on a periodic basis, in percentages of the total principal  */
  ManagementFee = 'MANAGEMENT_FEE'
}

export type KodiakVaultFee_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  feePercentage?: Maybe<Scalars['BigDecimal']>;
  feePercentage_not?: Maybe<Scalars['BigDecimal']>;
  feePercentage_gt?: Maybe<Scalars['BigDecimal']>;
  feePercentage_lt?: Maybe<Scalars['BigDecimal']>;
  feePercentage_gte?: Maybe<Scalars['BigDecimal']>;
  feePercentage_lte?: Maybe<Scalars['BigDecimal']>;
  feePercentage_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feePercentage_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feeType?: Maybe<KodiakVaultFeeType>;
  feeType_not?: Maybe<KodiakVaultFeeType>;
  feeType_in?: Maybe<Array<KodiakVaultFeeType>>;
  feeType_not_in?: Maybe<Array<KodiakVaultFeeType>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<KodiakVaultFee_Filter>>>;
  or?: Maybe<Array<Maybe<KodiakVaultFee_Filter>>>;
};

export enum KodiakVaultFee_OrderBy {
  Id = 'id',
  FeePercentage = 'feePercentage',
  FeeType = 'feeType'
}

export type KodiakVaultHourlySnapshot = {
  __typename?: 'KodiakVaultHourlySnapshot';
  /**  { Smart contract address of the vault }-{ # of hours since Unix epoch time }  */
  id: Scalars['ID'];
  /**  The protocol this snapshot belongs to  */
  protocol: YieldAggregator;
  /**  The vault this snapshot belongs to  */
  vault: KodiakVault;
  /**  Current TVL (Total Value Locked) of this pool in USD  */
  totalValueLockedUSD: Scalars['BigDecimal'];
  /**  All revenue generated by the vault, accrued to the supply side.  */
  cumulativeSupplySideRevenueUSD: Scalars['BigDecimal'];
  /**  Hourly revenue generated by the vault, accrued to the supply side.  */
  hourlySupplySideRevenueUSD: Scalars['BigDecimal'];
  /**  All revenue generated by the vault, accrued to the protocol.  */
  cumulativeProtocolSideRevenueUSD: Scalars['BigDecimal'];
  /**  Hourly revenue generated by the vault, accrued to the protocol.  */
  hourlyProtocolSideRevenueUSD: Scalars['BigDecimal'];
  /**  All revenue generated by the vault.  */
  cumulativeTotalRevenueUSD: Scalars['BigDecimal'];
  /**  Hourly revenue generated by the vault.  */
  hourlyTotalRevenueUSD: Scalars['BigDecimal'];
  /**  Amount of input token in the pool  */
  inputTokenBalance: Scalars['BigInt'];
  /**  Total supply of output token  */
  outputTokenSupply: Scalars['BigInt'];
  /**  Price per share of output token in USD  */
  outputTokenPriceUSD?: Maybe<Scalars['BigDecimal']>;
  volumeUSD: Scalars['BigDecimal'];
  volumeToken0: Scalars['BigDecimal'];
  volumeToken1: Scalars['BigDecimal'];
  lowerTick: Scalars['BigInt'];
  upperTick: Scalars['BigInt'];
  tickChanges?: Maybe<Array<KodiakStrategyChange>>;
  _baseLowerTick: Scalars['BigInt'];
  _baseUpperTick: Scalars['BigInt'];
  /**  Amount of input token per full share of output token. Usually corresponds to the value of `pricePerShare` or `pricePerFullShare` in the vault contract.  */
  pricePerShare?: Maybe<Scalars['BigDecimal']>;
  /**  Total supply of output tokens that are staked (usually in the MasterChef contract). Used to calculate reward APY.  */
  stakedOutputTokenAmount?: Maybe<Scalars['BigInt']>;
  /**  Per-block reward token emission as of the current block normalized to a day (not hour), in token's native amount. This should be ideally calculated as the theoretical rate instead of the realized amount.  */
  rewardTokenEmissionsAmount?: Maybe<Array<Scalars['BigInt']>>;
  /**  Per-block reward token emission as of the current block normalized to a day (not hour), in USD value. This should be ideally calculated as the theoretical rate instead of the realized amount.  */
  rewardTokenEmissionsUSD?: Maybe<Array<Scalars['BigDecimal']>>;
  /**  Block number of this snapshot  */
  blockNumber: Scalars['BigInt'];
  /**  Timestamp of this snapshot  */
  timestamp: Scalars['BigInt'];
};


export type KodiakVaultHourlySnapshotTickChangesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakStrategyChange_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakStrategyChange_Filter>;
};

export type KodiakVaultHourlySnapshot_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  protocol?: Maybe<Scalars['String']>;
  protocol_not?: Maybe<Scalars['String']>;
  protocol_gt?: Maybe<Scalars['String']>;
  protocol_lt?: Maybe<Scalars['String']>;
  protocol_gte?: Maybe<Scalars['String']>;
  protocol_lte?: Maybe<Scalars['String']>;
  protocol_in?: Maybe<Array<Scalars['String']>>;
  protocol_not_in?: Maybe<Array<Scalars['String']>>;
  protocol_contains?: Maybe<Scalars['String']>;
  protocol_contains_nocase?: Maybe<Scalars['String']>;
  protocol_not_contains?: Maybe<Scalars['String']>;
  protocol_not_contains_nocase?: Maybe<Scalars['String']>;
  protocol_starts_with?: Maybe<Scalars['String']>;
  protocol_starts_with_nocase?: Maybe<Scalars['String']>;
  protocol_not_starts_with?: Maybe<Scalars['String']>;
  protocol_not_starts_with_nocase?: Maybe<Scalars['String']>;
  protocol_ends_with?: Maybe<Scalars['String']>;
  protocol_ends_with_nocase?: Maybe<Scalars['String']>;
  protocol_not_ends_with?: Maybe<Scalars['String']>;
  protocol_not_ends_with_nocase?: Maybe<Scalars['String']>;
  protocol_?: Maybe<YieldAggregator_Filter>;
  vault?: Maybe<Scalars['String']>;
  vault_not?: Maybe<Scalars['String']>;
  vault_gt?: Maybe<Scalars['String']>;
  vault_lt?: Maybe<Scalars['String']>;
  vault_gte?: Maybe<Scalars['String']>;
  vault_lte?: Maybe<Scalars['String']>;
  vault_in?: Maybe<Array<Scalars['String']>>;
  vault_not_in?: Maybe<Array<Scalars['String']>>;
  vault_contains?: Maybe<Scalars['String']>;
  vault_contains_nocase?: Maybe<Scalars['String']>;
  vault_not_contains?: Maybe<Scalars['String']>;
  vault_not_contains_nocase?: Maybe<Scalars['String']>;
  vault_starts_with?: Maybe<Scalars['String']>;
  vault_starts_with_nocase?: Maybe<Scalars['String']>;
  vault_not_starts_with?: Maybe<Scalars['String']>;
  vault_not_starts_with_nocase?: Maybe<Scalars['String']>;
  vault_ends_with?: Maybe<Scalars['String']>;
  vault_ends_with_nocase?: Maybe<Scalars['String']>;
  vault_not_ends_with?: Maybe<Scalars['String']>;
  vault_not_ends_with_nocase?: Maybe<Scalars['String']>;
  vault_?: Maybe<KodiakVault_Filter>;
  totalValueLockedUSD?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_not?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_gt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_lt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_gte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_lte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeSupplySideRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeSupplySideRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  hourlySupplySideRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  hourlySupplySideRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  hourlySupplySideRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  hourlySupplySideRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  hourlySupplySideRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  hourlySupplySideRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  hourlySupplySideRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  hourlySupplySideRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeProtocolSideRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeProtocolSideRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  hourlyProtocolSideRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  hourlyProtocolSideRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  hourlyProtocolSideRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  hourlyProtocolSideRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  hourlyProtocolSideRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  hourlyProtocolSideRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  hourlyProtocolSideRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  hourlyProtocolSideRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeTotalRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeTotalRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  hourlyTotalRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  hourlyTotalRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  hourlyTotalRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  hourlyTotalRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  hourlyTotalRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  hourlyTotalRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  hourlyTotalRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  hourlyTotalRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  inputTokenBalance?: Maybe<Scalars['BigInt']>;
  inputTokenBalance_not?: Maybe<Scalars['BigInt']>;
  inputTokenBalance_gt?: Maybe<Scalars['BigInt']>;
  inputTokenBalance_lt?: Maybe<Scalars['BigInt']>;
  inputTokenBalance_gte?: Maybe<Scalars['BigInt']>;
  inputTokenBalance_lte?: Maybe<Scalars['BigInt']>;
  inputTokenBalance_in?: Maybe<Array<Scalars['BigInt']>>;
  inputTokenBalance_not_in?: Maybe<Array<Scalars['BigInt']>>;
  outputTokenSupply?: Maybe<Scalars['BigInt']>;
  outputTokenSupply_not?: Maybe<Scalars['BigInt']>;
  outputTokenSupply_gt?: Maybe<Scalars['BigInt']>;
  outputTokenSupply_lt?: Maybe<Scalars['BigInt']>;
  outputTokenSupply_gte?: Maybe<Scalars['BigInt']>;
  outputTokenSupply_lte?: Maybe<Scalars['BigInt']>;
  outputTokenSupply_in?: Maybe<Array<Scalars['BigInt']>>;
  outputTokenSupply_not_in?: Maybe<Array<Scalars['BigInt']>>;
  outputTokenPriceUSD?: Maybe<Scalars['BigDecimal']>;
  outputTokenPriceUSD_not?: Maybe<Scalars['BigDecimal']>;
  outputTokenPriceUSD_gt?: Maybe<Scalars['BigDecimal']>;
  outputTokenPriceUSD_lt?: Maybe<Scalars['BigDecimal']>;
  outputTokenPriceUSD_gte?: Maybe<Scalars['BigDecimal']>;
  outputTokenPriceUSD_lte?: Maybe<Scalars['BigDecimal']>;
  outputTokenPriceUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  outputTokenPriceUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_not?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken0?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_not?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_gt?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_lt?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_gte?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_lte?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken1?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_not?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_gt?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_lt?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_gte?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_lte?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  lowerTick?: Maybe<Scalars['BigInt']>;
  lowerTick_not?: Maybe<Scalars['BigInt']>;
  lowerTick_gt?: Maybe<Scalars['BigInt']>;
  lowerTick_lt?: Maybe<Scalars['BigInt']>;
  lowerTick_gte?: Maybe<Scalars['BigInt']>;
  lowerTick_lte?: Maybe<Scalars['BigInt']>;
  lowerTick_in?: Maybe<Array<Scalars['BigInt']>>;
  lowerTick_not_in?: Maybe<Array<Scalars['BigInt']>>;
  upperTick?: Maybe<Scalars['BigInt']>;
  upperTick_not?: Maybe<Scalars['BigInt']>;
  upperTick_gt?: Maybe<Scalars['BigInt']>;
  upperTick_lt?: Maybe<Scalars['BigInt']>;
  upperTick_gte?: Maybe<Scalars['BigInt']>;
  upperTick_lte?: Maybe<Scalars['BigInt']>;
  upperTick_in?: Maybe<Array<Scalars['BigInt']>>;
  upperTick_not_in?: Maybe<Array<Scalars['BigInt']>>;
  tickChanges?: Maybe<Array<Scalars['String']>>;
  tickChanges_not?: Maybe<Array<Scalars['String']>>;
  tickChanges_contains?: Maybe<Array<Scalars['String']>>;
  tickChanges_contains_nocase?: Maybe<Array<Scalars['String']>>;
  tickChanges_not_contains?: Maybe<Array<Scalars['String']>>;
  tickChanges_not_contains_nocase?: Maybe<Array<Scalars['String']>>;
  tickChanges_?: Maybe<KodiakStrategyChange_Filter>;
  _baseLowerTick?: Maybe<Scalars['BigInt']>;
  _baseLowerTick_not?: Maybe<Scalars['BigInt']>;
  _baseLowerTick_gt?: Maybe<Scalars['BigInt']>;
  _baseLowerTick_lt?: Maybe<Scalars['BigInt']>;
  _baseLowerTick_gte?: Maybe<Scalars['BigInt']>;
  _baseLowerTick_lte?: Maybe<Scalars['BigInt']>;
  _baseLowerTick_in?: Maybe<Array<Scalars['BigInt']>>;
  _baseLowerTick_not_in?: Maybe<Array<Scalars['BigInt']>>;
  _baseUpperTick?: Maybe<Scalars['BigInt']>;
  _baseUpperTick_not?: Maybe<Scalars['BigInt']>;
  _baseUpperTick_gt?: Maybe<Scalars['BigInt']>;
  _baseUpperTick_lt?: Maybe<Scalars['BigInt']>;
  _baseUpperTick_gte?: Maybe<Scalars['BigInt']>;
  _baseUpperTick_lte?: Maybe<Scalars['BigInt']>;
  _baseUpperTick_in?: Maybe<Array<Scalars['BigInt']>>;
  _baseUpperTick_not_in?: Maybe<Array<Scalars['BigInt']>>;
  pricePerShare?: Maybe<Scalars['BigDecimal']>;
  pricePerShare_not?: Maybe<Scalars['BigDecimal']>;
  pricePerShare_gt?: Maybe<Scalars['BigDecimal']>;
  pricePerShare_lt?: Maybe<Scalars['BigDecimal']>;
  pricePerShare_gte?: Maybe<Scalars['BigDecimal']>;
  pricePerShare_lte?: Maybe<Scalars['BigDecimal']>;
  pricePerShare_in?: Maybe<Array<Scalars['BigDecimal']>>;
  pricePerShare_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  stakedOutputTokenAmount?: Maybe<Scalars['BigInt']>;
  stakedOutputTokenAmount_not?: Maybe<Scalars['BigInt']>;
  stakedOutputTokenAmount_gt?: Maybe<Scalars['BigInt']>;
  stakedOutputTokenAmount_lt?: Maybe<Scalars['BigInt']>;
  stakedOutputTokenAmount_gte?: Maybe<Scalars['BigInt']>;
  stakedOutputTokenAmount_lte?: Maybe<Scalars['BigInt']>;
  stakedOutputTokenAmount_in?: Maybe<Array<Scalars['BigInt']>>;
  stakedOutputTokenAmount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  rewardTokenEmissionsAmount?: Maybe<Array<Scalars['BigInt']>>;
  rewardTokenEmissionsAmount_not?: Maybe<Array<Scalars['BigInt']>>;
  rewardTokenEmissionsAmount_contains?: Maybe<Array<Scalars['BigInt']>>;
  rewardTokenEmissionsAmount_contains_nocase?: Maybe<Array<Scalars['BigInt']>>;
  rewardTokenEmissionsAmount_not_contains?: Maybe<Array<Scalars['BigInt']>>;
  rewardTokenEmissionsAmount_not_contains_nocase?: Maybe<Array<Scalars['BigInt']>>;
  rewardTokenEmissionsUSD?: Maybe<Array<Scalars['BigDecimal']>>;
  rewardTokenEmissionsUSD_not?: Maybe<Array<Scalars['BigDecimal']>>;
  rewardTokenEmissionsUSD_contains?: Maybe<Array<Scalars['BigDecimal']>>;
  rewardTokenEmissionsUSD_contains_nocase?: Maybe<Array<Scalars['BigDecimal']>>;
  rewardTokenEmissionsUSD_not_contains?: Maybe<Array<Scalars['BigDecimal']>>;
  rewardTokenEmissionsUSD_not_contains_nocase?: Maybe<Array<Scalars['BigDecimal']>>;
  blockNumber?: Maybe<Scalars['BigInt']>;
  blockNumber_not?: Maybe<Scalars['BigInt']>;
  blockNumber_gt?: Maybe<Scalars['BigInt']>;
  blockNumber_lt?: Maybe<Scalars['BigInt']>;
  blockNumber_gte?: Maybe<Scalars['BigInt']>;
  blockNumber_lte?: Maybe<Scalars['BigInt']>;
  blockNumber_in?: Maybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<KodiakVaultHourlySnapshot_Filter>>>;
  or?: Maybe<Array<Maybe<KodiakVaultHourlySnapshot_Filter>>>;
};

export enum KodiakVaultHourlySnapshot_OrderBy {
  Id = 'id',
  Protocol = 'protocol',
  ProtocolId = 'protocol__id',
  ProtocolName = 'protocol__name',
  ProtocolSlug = 'protocol__slug',
  ProtocolSchemaVersion = 'protocol__schemaVersion',
  ProtocolSubgraphVersion = 'protocol__subgraphVersion',
  ProtocolMethodologyVersion = 'protocol__methodologyVersion',
  ProtocolNetwork = 'protocol__network',
  ProtocolType = 'protocol__type',
  ProtocolTotalValueLockedUsd = 'protocol__totalValueLockedUSD',
  ProtocolProtocolControlledValueUsd = 'protocol__protocolControlledValueUSD',
  ProtocolCumulativeSupplySideRevenueUsd = 'protocol__cumulativeSupplySideRevenueUSD',
  ProtocolCumulativeProtocolSideRevenueUsd = 'protocol__cumulativeProtocolSideRevenueUSD',
  ProtocolCumulativeTotalRevenueUsd = 'protocol__cumulativeTotalRevenueUSD',
  ProtocolCumulativeUniqueUsers = 'protocol__cumulativeUniqueUsers',
  ProtocolTotalPoolCount = 'protocol__totalPoolCount',
  Vault = 'vault',
  VaultId = 'vault__id',
  VaultName = 'vault__name',
  VaultSymbol = 'vault__symbol',
  VaultDepositLimit = 'vault__depositLimit',
  VaultCreatedTimestamp = 'vault__createdTimestamp',
  VaultCreatedBlockNumber = 'vault__createdBlockNumber',
  VaultTotalValueLockedUsd = 'vault__totalValueLockedUSD',
  VaultCumulativeSupplySideRevenueUsd = 'vault__cumulativeSupplySideRevenueUSD',
  VaultCumulativeProtocolSideRevenueUsd = 'vault__cumulativeProtocolSideRevenueUSD',
  VaultCumulativeTotalRevenueUsd = 'vault__cumulativeTotalRevenueUSD',
  VaultInputTokenBalance = 'vault__inputTokenBalance',
  VaultOutputTokenSupply = 'vault__outputTokenSupply',
  VaultOutputTokenPriceUsd = 'vault__outputTokenPriceUSD',
  VaultPricePerShare = 'vault__pricePerShare',
  VaultStakedOutputTokenAmount = 'vault__stakedOutputTokenAmount',
  VaultVolumeToken0 = 'vault__volumeToken0',
  VaultVolumeToken1 = 'vault__volumeToken1',
  VaultVolumeUsd = 'vault__volumeUSD',
  VaultLowerTick = 'vault__lowerTick',
  VaultUpperTick = 'vault__upperTick',
  VaultToken0Amount = 'vault___token0Amount',
  VaultToken1Amount = 'vault___token1Amount',
  VaultToken0AmountUsd = 'vault___token0AmountUSD',
  VaultToken1AmountUsd = 'vault___token1AmountUSD',
  TotalValueLockedUsd = 'totalValueLockedUSD',
  CumulativeSupplySideRevenueUsd = 'cumulativeSupplySideRevenueUSD',
  HourlySupplySideRevenueUsd = 'hourlySupplySideRevenueUSD',
  CumulativeProtocolSideRevenueUsd = 'cumulativeProtocolSideRevenueUSD',
  HourlyProtocolSideRevenueUsd = 'hourlyProtocolSideRevenueUSD',
  CumulativeTotalRevenueUsd = 'cumulativeTotalRevenueUSD',
  HourlyTotalRevenueUsd = 'hourlyTotalRevenueUSD',
  InputTokenBalance = 'inputTokenBalance',
  OutputTokenSupply = 'outputTokenSupply',
  OutputTokenPriceUsd = 'outputTokenPriceUSD',
  VolumeUsd = 'volumeUSD',
  VolumeToken0 = 'volumeToken0',
  VolumeToken1 = 'volumeToken1',
  LowerTick = 'lowerTick',
  UpperTick = 'upperTick',
  TickChanges = 'tickChanges',
  BaseLowerTick = '_baseLowerTick',
  BaseUpperTick = '_baseUpperTick',
  PricePerShare = 'pricePerShare',
  StakedOutputTokenAmount = 'stakedOutputTokenAmount',
  RewardTokenEmissionsAmount = 'rewardTokenEmissionsAmount',
  RewardTokenEmissionsUsd = 'rewardTokenEmissionsUSD',
  BlockNumber = 'blockNumber',
  Timestamp = 'timestamp'
}

export type KodiakVault_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  protocol?: Maybe<Scalars['String']>;
  protocol_not?: Maybe<Scalars['String']>;
  protocol_gt?: Maybe<Scalars['String']>;
  protocol_lt?: Maybe<Scalars['String']>;
  protocol_gte?: Maybe<Scalars['String']>;
  protocol_lte?: Maybe<Scalars['String']>;
  protocol_in?: Maybe<Array<Scalars['String']>>;
  protocol_not_in?: Maybe<Array<Scalars['String']>>;
  protocol_contains?: Maybe<Scalars['String']>;
  protocol_contains_nocase?: Maybe<Scalars['String']>;
  protocol_not_contains?: Maybe<Scalars['String']>;
  protocol_not_contains_nocase?: Maybe<Scalars['String']>;
  protocol_starts_with?: Maybe<Scalars['String']>;
  protocol_starts_with_nocase?: Maybe<Scalars['String']>;
  protocol_not_starts_with?: Maybe<Scalars['String']>;
  protocol_not_starts_with_nocase?: Maybe<Scalars['String']>;
  protocol_ends_with?: Maybe<Scalars['String']>;
  protocol_ends_with_nocase?: Maybe<Scalars['String']>;
  protocol_not_ends_with?: Maybe<Scalars['String']>;
  protocol_not_ends_with_nocase?: Maybe<Scalars['String']>;
  protocol_?: Maybe<YieldAggregator_Filter>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_lt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_contains?: Maybe<Scalars['String']>;
  name_contains_nocase?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_not_contains_nocase?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_starts_with_nocase?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with_nocase?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_ends_with_nocase?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with_nocase?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  symbol_not?: Maybe<Scalars['String']>;
  symbol_gt?: Maybe<Scalars['String']>;
  symbol_lt?: Maybe<Scalars['String']>;
  symbol_gte?: Maybe<Scalars['String']>;
  symbol_lte?: Maybe<Scalars['String']>;
  symbol_in?: Maybe<Array<Scalars['String']>>;
  symbol_not_in?: Maybe<Array<Scalars['String']>>;
  symbol_contains?: Maybe<Scalars['String']>;
  symbol_contains_nocase?: Maybe<Scalars['String']>;
  symbol_not_contains?: Maybe<Scalars['String']>;
  symbol_not_contains_nocase?: Maybe<Scalars['String']>;
  symbol_starts_with?: Maybe<Scalars['String']>;
  symbol_starts_with_nocase?: Maybe<Scalars['String']>;
  symbol_not_starts_with?: Maybe<Scalars['String']>;
  symbol_not_starts_with_nocase?: Maybe<Scalars['String']>;
  symbol_ends_with?: Maybe<Scalars['String']>;
  symbol_ends_with_nocase?: Maybe<Scalars['String']>;
  symbol_not_ends_with?: Maybe<Scalars['String']>;
  symbol_not_ends_with_nocase?: Maybe<Scalars['String']>;
  inputToken?: Maybe<Scalars['String']>;
  inputToken_not?: Maybe<Scalars['String']>;
  inputToken_gt?: Maybe<Scalars['String']>;
  inputToken_lt?: Maybe<Scalars['String']>;
  inputToken_gte?: Maybe<Scalars['String']>;
  inputToken_lte?: Maybe<Scalars['String']>;
  inputToken_in?: Maybe<Array<Scalars['String']>>;
  inputToken_not_in?: Maybe<Array<Scalars['String']>>;
  inputToken_contains?: Maybe<Scalars['String']>;
  inputToken_contains_nocase?: Maybe<Scalars['String']>;
  inputToken_not_contains?: Maybe<Scalars['String']>;
  inputToken_not_contains_nocase?: Maybe<Scalars['String']>;
  inputToken_starts_with?: Maybe<Scalars['String']>;
  inputToken_starts_with_nocase?: Maybe<Scalars['String']>;
  inputToken_not_starts_with?: Maybe<Scalars['String']>;
  inputToken_not_starts_with_nocase?: Maybe<Scalars['String']>;
  inputToken_ends_with?: Maybe<Scalars['String']>;
  inputToken_ends_with_nocase?: Maybe<Scalars['String']>;
  inputToken_not_ends_with?: Maybe<Scalars['String']>;
  inputToken_not_ends_with_nocase?: Maybe<Scalars['String']>;
  inputToken_?: Maybe<Token_Filter>;
  outputToken?: Maybe<Scalars['String']>;
  outputToken_not?: Maybe<Scalars['String']>;
  outputToken_gt?: Maybe<Scalars['String']>;
  outputToken_lt?: Maybe<Scalars['String']>;
  outputToken_gte?: Maybe<Scalars['String']>;
  outputToken_lte?: Maybe<Scalars['String']>;
  outputToken_in?: Maybe<Array<Scalars['String']>>;
  outputToken_not_in?: Maybe<Array<Scalars['String']>>;
  outputToken_contains?: Maybe<Scalars['String']>;
  outputToken_contains_nocase?: Maybe<Scalars['String']>;
  outputToken_not_contains?: Maybe<Scalars['String']>;
  outputToken_not_contains_nocase?: Maybe<Scalars['String']>;
  outputToken_starts_with?: Maybe<Scalars['String']>;
  outputToken_starts_with_nocase?: Maybe<Scalars['String']>;
  outputToken_not_starts_with?: Maybe<Scalars['String']>;
  outputToken_not_starts_with_nocase?: Maybe<Scalars['String']>;
  outputToken_ends_with?: Maybe<Scalars['String']>;
  outputToken_ends_with_nocase?: Maybe<Scalars['String']>;
  outputToken_not_ends_with?: Maybe<Scalars['String']>;
  outputToken_not_ends_with_nocase?: Maybe<Scalars['String']>;
  outputToken_?: Maybe<Token_Filter>;
  rewardTokens?: Maybe<Array<Scalars['String']>>;
  rewardTokens_not?: Maybe<Array<Scalars['String']>>;
  rewardTokens_contains?: Maybe<Array<Scalars['String']>>;
  rewardTokens_contains_nocase?: Maybe<Array<Scalars['String']>>;
  rewardTokens_not_contains?: Maybe<Array<Scalars['String']>>;
  rewardTokens_not_contains_nocase?: Maybe<Array<Scalars['String']>>;
  rewardTokens_?: Maybe<KodiakRewardToken_Filter>;
  depositLimit?: Maybe<Scalars['BigInt']>;
  depositLimit_not?: Maybe<Scalars['BigInt']>;
  depositLimit_gt?: Maybe<Scalars['BigInt']>;
  depositLimit_lt?: Maybe<Scalars['BigInt']>;
  depositLimit_gte?: Maybe<Scalars['BigInt']>;
  depositLimit_lte?: Maybe<Scalars['BigInt']>;
  depositLimit_in?: Maybe<Array<Scalars['BigInt']>>;
  depositLimit_not_in?: Maybe<Array<Scalars['BigInt']>>;
  fees?: Maybe<Array<Scalars['String']>>;
  fees_not?: Maybe<Array<Scalars['String']>>;
  fees_contains?: Maybe<Array<Scalars['String']>>;
  fees_contains_nocase?: Maybe<Array<Scalars['String']>>;
  fees_not_contains?: Maybe<Array<Scalars['String']>>;
  fees_not_contains_nocase?: Maybe<Array<Scalars['String']>>;
  fees_?: Maybe<KodiakVaultFee_Filter>;
  createdTimestamp?: Maybe<Scalars['BigInt']>;
  createdTimestamp_not?: Maybe<Scalars['BigInt']>;
  createdTimestamp_gt?: Maybe<Scalars['BigInt']>;
  createdTimestamp_lt?: Maybe<Scalars['BigInt']>;
  createdTimestamp_gte?: Maybe<Scalars['BigInt']>;
  createdTimestamp_lte?: Maybe<Scalars['BigInt']>;
  createdTimestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  createdTimestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  createdBlockNumber?: Maybe<Scalars['BigInt']>;
  createdBlockNumber_not?: Maybe<Scalars['BigInt']>;
  createdBlockNumber_gt?: Maybe<Scalars['BigInt']>;
  createdBlockNumber_lt?: Maybe<Scalars['BigInt']>;
  createdBlockNumber_gte?: Maybe<Scalars['BigInt']>;
  createdBlockNumber_lte?: Maybe<Scalars['BigInt']>;
  createdBlockNumber_in?: Maybe<Array<Scalars['BigInt']>>;
  createdBlockNumber_not_in?: Maybe<Array<Scalars['BigInt']>>;
  totalValueLockedUSD?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_not?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_gt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_lt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_gte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_lte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeSupplySideRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeSupplySideRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeProtocolSideRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeProtocolSideRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeTotalRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeTotalRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  inputTokenBalance?: Maybe<Scalars['BigInt']>;
  inputTokenBalance_not?: Maybe<Scalars['BigInt']>;
  inputTokenBalance_gt?: Maybe<Scalars['BigInt']>;
  inputTokenBalance_lt?: Maybe<Scalars['BigInt']>;
  inputTokenBalance_gte?: Maybe<Scalars['BigInt']>;
  inputTokenBalance_lte?: Maybe<Scalars['BigInt']>;
  inputTokenBalance_in?: Maybe<Array<Scalars['BigInt']>>;
  inputTokenBalance_not_in?: Maybe<Array<Scalars['BigInt']>>;
  outputTokenSupply?: Maybe<Scalars['BigInt']>;
  outputTokenSupply_not?: Maybe<Scalars['BigInt']>;
  outputTokenSupply_gt?: Maybe<Scalars['BigInt']>;
  outputTokenSupply_lt?: Maybe<Scalars['BigInt']>;
  outputTokenSupply_gte?: Maybe<Scalars['BigInt']>;
  outputTokenSupply_lte?: Maybe<Scalars['BigInt']>;
  outputTokenSupply_in?: Maybe<Array<Scalars['BigInt']>>;
  outputTokenSupply_not_in?: Maybe<Array<Scalars['BigInt']>>;
  outputTokenPriceUSD?: Maybe<Scalars['BigDecimal']>;
  outputTokenPriceUSD_not?: Maybe<Scalars['BigDecimal']>;
  outputTokenPriceUSD_gt?: Maybe<Scalars['BigDecimal']>;
  outputTokenPriceUSD_lt?: Maybe<Scalars['BigDecimal']>;
  outputTokenPriceUSD_gte?: Maybe<Scalars['BigDecimal']>;
  outputTokenPriceUSD_lte?: Maybe<Scalars['BigDecimal']>;
  outputTokenPriceUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  outputTokenPriceUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  pricePerShare?: Maybe<Scalars['BigDecimal']>;
  pricePerShare_not?: Maybe<Scalars['BigDecimal']>;
  pricePerShare_gt?: Maybe<Scalars['BigDecimal']>;
  pricePerShare_lt?: Maybe<Scalars['BigDecimal']>;
  pricePerShare_gte?: Maybe<Scalars['BigDecimal']>;
  pricePerShare_lte?: Maybe<Scalars['BigDecimal']>;
  pricePerShare_in?: Maybe<Array<Scalars['BigDecimal']>>;
  pricePerShare_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  stakedOutputTokenAmount?: Maybe<Scalars['BigInt']>;
  stakedOutputTokenAmount_not?: Maybe<Scalars['BigInt']>;
  stakedOutputTokenAmount_gt?: Maybe<Scalars['BigInt']>;
  stakedOutputTokenAmount_lt?: Maybe<Scalars['BigInt']>;
  stakedOutputTokenAmount_gte?: Maybe<Scalars['BigInt']>;
  stakedOutputTokenAmount_lte?: Maybe<Scalars['BigInt']>;
  stakedOutputTokenAmount_in?: Maybe<Array<Scalars['BigInt']>>;
  stakedOutputTokenAmount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  rewardTokenEmissionsAmount?: Maybe<Array<Scalars['BigInt']>>;
  rewardTokenEmissionsAmount_not?: Maybe<Array<Scalars['BigInt']>>;
  rewardTokenEmissionsAmount_contains?: Maybe<Array<Scalars['BigInt']>>;
  rewardTokenEmissionsAmount_contains_nocase?: Maybe<Array<Scalars['BigInt']>>;
  rewardTokenEmissionsAmount_not_contains?: Maybe<Array<Scalars['BigInt']>>;
  rewardTokenEmissionsAmount_not_contains_nocase?: Maybe<Array<Scalars['BigInt']>>;
  rewardTokenEmissionsUSD?: Maybe<Array<Scalars['BigDecimal']>>;
  rewardTokenEmissionsUSD_not?: Maybe<Array<Scalars['BigDecimal']>>;
  rewardTokenEmissionsUSD_contains?: Maybe<Array<Scalars['BigDecimal']>>;
  rewardTokenEmissionsUSD_contains_nocase?: Maybe<Array<Scalars['BigDecimal']>>;
  rewardTokenEmissionsUSD_not_contains?: Maybe<Array<Scalars['BigDecimal']>>;
  rewardTokenEmissionsUSD_not_contains_nocase?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken0?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_not?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_gt?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_lt?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_gte?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_lte?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken1?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_not?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_gt?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_lt?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_gte?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_lte?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_not?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  dailySnapshots_?: Maybe<KodiakVaultDailySnapshot_Filter>;
  hourlySnapshots_?: Maybe<KodiakVaultHourlySnapshot_Filter>;
  deposits_?: Maybe<KodiakDeposit_Filter>;
  withdraws_?: Maybe<KodiakWithdraw_Filter>;
  pool?: Maybe<Scalars['String']>;
  pool_not?: Maybe<Scalars['String']>;
  pool_gt?: Maybe<Scalars['String']>;
  pool_lt?: Maybe<Scalars['String']>;
  pool_gte?: Maybe<Scalars['String']>;
  pool_lte?: Maybe<Scalars['String']>;
  pool_in?: Maybe<Array<Scalars['String']>>;
  pool_not_in?: Maybe<Array<Scalars['String']>>;
  pool_contains?: Maybe<Scalars['String']>;
  pool_contains_nocase?: Maybe<Scalars['String']>;
  pool_not_contains?: Maybe<Scalars['String']>;
  pool_not_contains_nocase?: Maybe<Scalars['String']>;
  pool_starts_with?: Maybe<Scalars['String']>;
  pool_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_not_starts_with?: Maybe<Scalars['String']>;
  pool_not_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_ends_with?: Maybe<Scalars['String']>;
  pool_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_not_ends_with?: Maybe<Scalars['String']>;
  pool_not_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_?: Maybe<Pool_Filter>;
  apr?: Maybe<Scalars['String']>;
  apr_not?: Maybe<Scalars['String']>;
  apr_gt?: Maybe<Scalars['String']>;
  apr_lt?: Maybe<Scalars['String']>;
  apr_gte?: Maybe<Scalars['String']>;
  apr_lte?: Maybe<Scalars['String']>;
  apr_in?: Maybe<Array<Scalars['String']>>;
  apr_not_in?: Maybe<Array<Scalars['String']>>;
  apr_contains?: Maybe<Scalars['String']>;
  apr_contains_nocase?: Maybe<Scalars['String']>;
  apr_not_contains?: Maybe<Scalars['String']>;
  apr_not_contains_nocase?: Maybe<Scalars['String']>;
  apr_starts_with?: Maybe<Scalars['String']>;
  apr_starts_with_nocase?: Maybe<Scalars['String']>;
  apr_not_starts_with?: Maybe<Scalars['String']>;
  apr_not_starts_with_nocase?: Maybe<Scalars['String']>;
  apr_ends_with?: Maybe<Scalars['String']>;
  apr_ends_with_nocase?: Maybe<Scalars['String']>;
  apr_not_ends_with?: Maybe<Scalars['String']>;
  apr_not_ends_with_nocase?: Maybe<Scalars['String']>;
  apr_?: Maybe<KodiakApr_Filter>;
  lowerTick?: Maybe<Scalars['BigInt']>;
  lowerTick_not?: Maybe<Scalars['BigInt']>;
  lowerTick_gt?: Maybe<Scalars['BigInt']>;
  lowerTick_lt?: Maybe<Scalars['BigInt']>;
  lowerTick_gte?: Maybe<Scalars['BigInt']>;
  lowerTick_lte?: Maybe<Scalars['BigInt']>;
  lowerTick_in?: Maybe<Array<Scalars['BigInt']>>;
  lowerTick_not_in?: Maybe<Array<Scalars['BigInt']>>;
  upperTick?: Maybe<Scalars['BigInt']>;
  upperTick_not?: Maybe<Scalars['BigInt']>;
  upperTick_gt?: Maybe<Scalars['BigInt']>;
  upperTick_lt?: Maybe<Scalars['BigInt']>;
  upperTick_gte?: Maybe<Scalars['BigInt']>;
  upperTick_lte?: Maybe<Scalars['BigInt']>;
  upperTick_in?: Maybe<Array<Scalars['BigInt']>>;
  upperTick_not_in?: Maybe<Array<Scalars['BigInt']>>;
  _token0?: Maybe<Scalars['String']>;
  _token0_not?: Maybe<Scalars['String']>;
  _token0_gt?: Maybe<Scalars['String']>;
  _token0_lt?: Maybe<Scalars['String']>;
  _token0_gte?: Maybe<Scalars['String']>;
  _token0_lte?: Maybe<Scalars['String']>;
  _token0_in?: Maybe<Array<Scalars['String']>>;
  _token0_not_in?: Maybe<Array<Scalars['String']>>;
  _token0_contains?: Maybe<Scalars['String']>;
  _token0_contains_nocase?: Maybe<Scalars['String']>;
  _token0_not_contains?: Maybe<Scalars['String']>;
  _token0_not_contains_nocase?: Maybe<Scalars['String']>;
  _token0_starts_with?: Maybe<Scalars['String']>;
  _token0_starts_with_nocase?: Maybe<Scalars['String']>;
  _token0_not_starts_with?: Maybe<Scalars['String']>;
  _token0_not_starts_with_nocase?: Maybe<Scalars['String']>;
  _token0_ends_with?: Maybe<Scalars['String']>;
  _token0_ends_with_nocase?: Maybe<Scalars['String']>;
  _token0_not_ends_with?: Maybe<Scalars['String']>;
  _token0_not_ends_with_nocase?: Maybe<Scalars['String']>;
  _token0_?: Maybe<Token_Filter>;
  _token1?: Maybe<Scalars['String']>;
  _token1_not?: Maybe<Scalars['String']>;
  _token1_gt?: Maybe<Scalars['String']>;
  _token1_lt?: Maybe<Scalars['String']>;
  _token1_gte?: Maybe<Scalars['String']>;
  _token1_lte?: Maybe<Scalars['String']>;
  _token1_in?: Maybe<Array<Scalars['String']>>;
  _token1_not_in?: Maybe<Array<Scalars['String']>>;
  _token1_contains?: Maybe<Scalars['String']>;
  _token1_contains_nocase?: Maybe<Scalars['String']>;
  _token1_not_contains?: Maybe<Scalars['String']>;
  _token1_not_contains_nocase?: Maybe<Scalars['String']>;
  _token1_starts_with?: Maybe<Scalars['String']>;
  _token1_starts_with_nocase?: Maybe<Scalars['String']>;
  _token1_not_starts_with?: Maybe<Scalars['String']>;
  _token1_not_starts_with_nocase?: Maybe<Scalars['String']>;
  _token1_ends_with?: Maybe<Scalars['String']>;
  _token1_ends_with_nocase?: Maybe<Scalars['String']>;
  _token1_not_ends_with?: Maybe<Scalars['String']>;
  _token1_not_ends_with_nocase?: Maybe<Scalars['String']>;
  _token1_?: Maybe<Token_Filter>;
  _token0Amount?: Maybe<Scalars['BigInt']>;
  _token0Amount_not?: Maybe<Scalars['BigInt']>;
  _token0Amount_gt?: Maybe<Scalars['BigInt']>;
  _token0Amount_lt?: Maybe<Scalars['BigInt']>;
  _token0Amount_gte?: Maybe<Scalars['BigInt']>;
  _token0Amount_lte?: Maybe<Scalars['BigInt']>;
  _token0Amount_in?: Maybe<Array<Scalars['BigInt']>>;
  _token0Amount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  _token1Amount?: Maybe<Scalars['BigInt']>;
  _token1Amount_not?: Maybe<Scalars['BigInt']>;
  _token1Amount_gt?: Maybe<Scalars['BigInt']>;
  _token1Amount_lt?: Maybe<Scalars['BigInt']>;
  _token1Amount_gte?: Maybe<Scalars['BigInt']>;
  _token1Amount_lte?: Maybe<Scalars['BigInt']>;
  _token1Amount_in?: Maybe<Array<Scalars['BigInt']>>;
  _token1Amount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  _token0AmountUSD?: Maybe<Scalars['BigDecimal']>;
  _token0AmountUSD_not?: Maybe<Scalars['BigDecimal']>;
  _token0AmountUSD_gt?: Maybe<Scalars['BigDecimal']>;
  _token0AmountUSD_lt?: Maybe<Scalars['BigDecimal']>;
  _token0AmountUSD_gte?: Maybe<Scalars['BigDecimal']>;
  _token0AmountUSD_lte?: Maybe<Scalars['BigDecimal']>;
  _token0AmountUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  _token0AmountUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  _token1AmountUSD?: Maybe<Scalars['BigDecimal']>;
  _token1AmountUSD_not?: Maybe<Scalars['BigDecimal']>;
  _token1AmountUSD_gt?: Maybe<Scalars['BigDecimal']>;
  _token1AmountUSD_lt?: Maybe<Scalars['BigDecimal']>;
  _token1AmountUSD_gte?: Maybe<Scalars['BigDecimal']>;
  _token1AmountUSD_lte?: Maybe<Scalars['BigDecimal']>;
  _token1AmountUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  _token1AmountUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<KodiakVault_Filter>>>;
  or?: Maybe<Array<Maybe<KodiakVault_Filter>>>;
};

export enum KodiakVault_OrderBy {
  Id = 'id',
  Protocol = 'protocol',
  ProtocolId = 'protocol__id',
  ProtocolName = 'protocol__name',
  ProtocolSlug = 'protocol__slug',
  ProtocolSchemaVersion = 'protocol__schemaVersion',
  ProtocolSubgraphVersion = 'protocol__subgraphVersion',
  ProtocolMethodologyVersion = 'protocol__methodologyVersion',
  ProtocolNetwork = 'protocol__network',
  ProtocolType = 'protocol__type',
  ProtocolTotalValueLockedUsd = 'protocol__totalValueLockedUSD',
  ProtocolProtocolControlledValueUsd = 'protocol__protocolControlledValueUSD',
  ProtocolCumulativeSupplySideRevenueUsd = 'protocol__cumulativeSupplySideRevenueUSD',
  ProtocolCumulativeProtocolSideRevenueUsd = 'protocol__cumulativeProtocolSideRevenueUSD',
  ProtocolCumulativeTotalRevenueUsd = 'protocol__cumulativeTotalRevenueUSD',
  ProtocolCumulativeUniqueUsers = 'protocol__cumulativeUniqueUsers',
  ProtocolTotalPoolCount = 'protocol__totalPoolCount',
  Name = 'name',
  Symbol = 'symbol',
  InputToken = 'inputToken',
  InputTokenId = 'inputToken__id',
  InputTokenSymbol = 'inputToken__symbol',
  InputTokenName = 'inputToken__name',
  InputTokenDecimals = 'inputToken__decimals',
  InputTokenTotalSupply = 'inputToken__totalSupply',
  InputTokenVolume = 'inputToken__volume',
  InputTokenVolumeUsd = 'inputToken__volumeUSD',
  InputTokenUntrackedVolumeUsd = 'inputToken__untrackedVolumeUSD',
  InputTokenFeesUsd = 'inputToken__feesUSD',
  InputTokenTxCount = 'inputToken__txCount',
  InputTokenPoolCount = 'inputToken__poolCount',
  InputTokenTotalValueLocked = 'inputToken__totalValueLocked',
  InputTokenTotalValueLockedUsd = 'inputToken__totalValueLockedUSD',
  InputTokenTotalValueLockedUsdUntracked = 'inputToken__totalValueLockedUSDUntracked',
  InputTokenDerivedEth = 'inputToken__derivedETH',
  OutputToken = 'outputToken',
  OutputTokenId = 'outputToken__id',
  OutputTokenSymbol = 'outputToken__symbol',
  OutputTokenName = 'outputToken__name',
  OutputTokenDecimals = 'outputToken__decimals',
  OutputTokenTotalSupply = 'outputToken__totalSupply',
  OutputTokenVolume = 'outputToken__volume',
  OutputTokenVolumeUsd = 'outputToken__volumeUSD',
  OutputTokenUntrackedVolumeUsd = 'outputToken__untrackedVolumeUSD',
  OutputTokenFeesUsd = 'outputToken__feesUSD',
  OutputTokenTxCount = 'outputToken__txCount',
  OutputTokenPoolCount = 'outputToken__poolCount',
  OutputTokenTotalValueLocked = 'outputToken__totalValueLocked',
  OutputTokenTotalValueLockedUsd = 'outputToken__totalValueLockedUSD',
  OutputTokenTotalValueLockedUsdUntracked = 'outputToken__totalValueLockedUSDUntracked',
  OutputTokenDerivedEth = 'outputToken__derivedETH',
  RewardTokens = 'rewardTokens',
  DepositLimit = 'depositLimit',
  Fees = 'fees',
  CreatedTimestamp = 'createdTimestamp',
  CreatedBlockNumber = 'createdBlockNumber',
  TotalValueLockedUsd = 'totalValueLockedUSD',
  CumulativeSupplySideRevenueUsd = 'cumulativeSupplySideRevenueUSD',
  CumulativeProtocolSideRevenueUsd = 'cumulativeProtocolSideRevenueUSD',
  CumulativeTotalRevenueUsd = 'cumulativeTotalRevenueUSD',
  InputTokenBalance = 'inputTokenBalance',
  OutputTokenSupply = 'outputTokenSupply',
  OutputTokenPriceUsd = 'outputTokenPriceUSD',
  PricePerShare = 'pricePerShare',
  StakedOutputTokenAmount = 'stakedOutputTokenAmount',
  RewardTokenEmissionsAmount = 'rewardTokenEmissionsAmount',
  RewardTokenEmissionsUsd = 'rewardTokenEmissionsUSD',
  VolumeToken0 = 'volumeToken0',
  VolumeToken1 = 'volumeToken1',
  VolumeUsd = 'volumeUSD',
  DailySnapshots = 'dailySnapshots',
  HourlySnapshots = 'hourlySnapshots',
  Deposits = 'deposits',
  Withdraws = 'withdraws',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolCreatedAtTimestamp = 'pool__createdAtTimestamp',
  PoolCreatedAtBlockNumber = 'pool__createdAtBlockNumber',
  PoolFeeTier = 'pool__feeTier',
  PoolLiquidity = 'pool__liquidity',
  PoolSqrtPrice = 'pool__sqrtPrice',
  PoolFeeGrowthGlobal0X128 = 'pool__feeGrowthGlobal0X128',
  PoolFeeGrowthGlobal1X128 = 'pool__feeGrowthGlobal1X128',
  PoolToken0Price = 'pool__token0Price',
  PoolToken1Price = 'pool__token1Price',
  PoolTick = 'pool__tick',
  PoolObservationIndex = 'pool__observationIndex',
  PoolVolumeToken0 = 'pool__volumeToken0',
  PoolVolumeToken1 = 'pool__volumeToken1',
  PoolVolumeUsd = 'pool__volumeUSD',
  PoolUntrackedVolumeUsd = 'pool__untrackedVolumeUSD',
  PoolFeesUsd = 'pool__feesUSD',
  PoolTxCount = 'pool__txCount',
  PoolCollectedFeesToken0 = 'pool__collectedFeesToken0',
  PoolCollectedFeesToken1 = 'pool__collectedFeesToken1',
  PoolCollectedFeesUsd = 'pool__collectedFeesUSD',
  PoolTotalValueLockedToken0 = 'pool__totalValueLockedToken0',
  PoolTotalValueLockedToken1 = 'pool__totalValueLockedToken1',
  PoolTotalValueLockedEth = 'pool__totalValueLockedETH',
  PoolTotalValueLockedUsd = 'pool__totalValueLockedUSD',
  PoolTotalValueLockedUsdUntracked = 'pool__totalValueLockedUSDUntracked',
  PoolLiquidityProviderCount = 'pool__liquidityProviderCount',
  Apr = 'apr',
  AprId = 'apr__id',
  AprAverageApr = 'apr__averageApr',
  AprTimestamp = 'apr__timestamp',
  LowerTick = 'lowerTick',
  UpperTick = 'upperTick',
  Token0 = '_token0',
  Token0Id = '_token0__id',
  Token0Symbol = '_token0__symbol',
  Token0Name = '_token0__name',
  Token0Decimals = '_token0__decimals',
  Token0TotalSupply = '_token0__totalSupply',
  Token0Volume = '_token0__volume',
  Token0VolumeUsd = '_token0__volumeUSD',
  Token0UntrackedVolumeUsd = '_token0__untrackedVolumeUSD',
  Token0FeesUsd = '_token0__feesUSD',
  Token0TxCount = '_token0__txCount',
  Token0PoolCount = '_token0__poolCount',
  Token0TotalValueLocked = '_token0__totalValueLocked',
  Token0TotalValueLockedUsd = '_token0__totalValueLockedUSD',
  Token0TotalValueLockedUsdUntracked = '_token0__totalValueLockedUSDUntracked',
  Token0DerivedEth = '_token0__derivedETH',
  Token1 = '_token1',
  Token1Id = '_token1__id',
  Token1Symbol = '_token1__symbol',
  Token1Name = '_token1__name',
  Token1Decimals = '_token1__decimals',
  Token1TotalSupply = '_token1__totalSupply',
  Token1Volume = '_token1__volume',
  Token1VolumeUsd = '_token1__volumeUSD',
  Token1UntrackedVolumeUsd = '_token1__untrackedVolumeUSD',
  Token1FeesUsd = '_token1__feesUSD',
  Token1TxCount = '_token1__txCount',
  Token1PoolCount = '_token1__poolCount',
  Token1TotalValueLocked = '_token1__totalValueLocked',
  Token1TotalValueLockedUsd = '_token1__totalValueLockedUSD',
  Token1TotalValueLockedUsdUntracked = '_token1__totalValueLockedUSDUntracked',
  Token1DerivedEth = '_token1__derivedETH',
  Token0Amount = '_token0Amount',
  Token1Amount = '_token1Amount',
  Token0AmountUsd = '_token0AmountUSD',
  Token1AmountUsd = '_token1AmountUSD'
}

export type KodiakWithdraw = Event & {
  __typename?: 'KodiakWithdraw';
  /**  { Transaction hash }-{ Log index } */
  id: Scalars['ID'];
  /**  Transaction hash of the transaction that emitted this event  */
  hash: Scalars['String'];
  /**  Event log index. For transactions that don't emit event, create arbitrary index starting from 0  */
  logIndex: Scalars['Int'];
  /**  The protocol this transaction belongs to  */
  protocol: YieldAggregator;
  /**  Address that received tokens  */
  to: Scalars['String'];
  /**  Market that tokens are withdrawn from  */
  from: Scalars['String'];
  /**  Block number of this event  */
  blockNumber: Scalars['BigInt'];
  /**  Timestamp of this event  */
  timestamp: Scalars['BigInt'];
  /**  Token withdrawn  */
  asset: Token;
  /**  Amount of token withdrawn in native units  */
  amount: Scalars['BigInt'];
  /**  Amount of token withdrawn in USD  */
  amountUSD: Scalars['BigDecimal'];
  /**  The vault involving this transaction  */
  vault: KodiakVault;
};

export type KodiakWithdraw_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  hash?: Maybe<Scalars['String']>;
  hash_not?: Maybe<Scalars['String']>;
  hash_gt?: Maybe<Scalars['String']>;
  hash_lt?: Maybe<Scalars['String']>;
  hash_gte?: Maybe<Scalars['String']>;
  hash_lte?: Maybe<Scalars['String']>;
  hash_in?: Maybe<Array<Scalars['String']>>;
  hash_not_in?: Maybe<Array<Scalars['String']>>;
  hash_contains?: Maybe<Scalars['String']>;
  hash_contains_nocase?: Maybe<Scalars['String']>;
  hash_not_contains?: Maybe<Scalars['String']>;
  hash_not_contains_nocase?: Maybe<Scalars['String']>;
  hash_starts_with?: Maybe<Scalars['String']>;
  hash_starts_with_nocase?: Maybe<Scalars['String']>;
  hash_not_starts_with?: Maybe<Scalars['String']>;
  hash_not_starts_with_nocase?: Maybe<Scalars['String']>;
  hash_ends_with?: Maybe<Scalars['String']>;
  hash_ends_with_nocase?: Maybe<Scalars['String']>;
  hash_not_ends_with?: Maybe<Scalars['String']>;
  hash_not_ends_with_nocase?: Maybe<Scalars['String']>;
  logIndex?: Maybe<Scalars['Int']>;
  logIndex_not?: Maybe<Scalars['Int']>;
  logIndex_gt?: Maybe<Scalars['Int']>;
  logIndex_lt?: Maybe<Scalars['Int']>;
  logIndex_gte?: Maybe<Scalars['Int']>;
  logIndex_lte?: Maybe<Scalars['Int']>;
  logIndex_in?: Maybe<Array<Scalars['Int']>>;
  logIndex_not_in?: Maybe<Array<Scalars['Int']>>;
  protocol?: Maybe<Scalars['String']>;
  protocol_not?: Maybe<Scalars['String']>;
  protocol_gt?: Maybe<Scalars['String']>;
  protocol_lt?: Maybe<Scalars['String']>;
  protocol_gte?: Maybe<Scalars['String']>;
  protocol_lte?: Maybe<Scalars['String']>;
  protocol_in?: Maybe<Array<Scalars['String']>>;
  protocol_not_in?: Maybe<Array<Scalars['String']>>;
  protocol_contains?: Maybe<Scalars['String']>;
  protocol_contains_nocase?: Maybe<Scalars['String']>;
  protocol_not_contains?: Maybe<Scalars['String']>;
  protocol_not_contains_nocase?: Maybe<Scalars['String']>;
  protocol_starts_with?: Maybe<Scalars['String']>;
  protocol_starts_with_nocase?: Maybe<Scalars['String']>;
  protocol_not_starts_with?: Maybe<Scalars['String']>;
  protocol_not_starts_with_nocase?: Maybe<Scalars['String']>;
  protocol_ends_with?: Maybe<Scalars['String']>;
  protocol_ends_with_nocase?: Maybe<Scalars['String']>;
  protocol_not_ends_with?: Maybe<Scalars['String']>;
  protocol_not_ends_with_nocase?: Maybe<Scalars['String']>;
  protocol_?: Maybe<YieldAggregator_Filter>;
  to?: Maybe<Scalars['String']>;
  to_not?: Maybe<Scalars['String']>;
  to_gt?: Maybe<Scalars['String']>;
  to_lt?: Maybe<Scalars['String']>;
  to_gte?: Maybe<Scalars['String']>;
  to_lte?: Maybe<Scalars['String']>;
  to_in?: Maybe<Array<Scalars['String']>>;
  to_not_in?: Maybe<Array<Scalars['String']>>;
  to_contains?: Maybe<Scalars['String']>;
  to_contains_nocase?: Maybe<Scalars['String']>;
  to_not_contains?: Maybe<Scalars['String']>;
  to_not_contains_nocase?: Maybe<Scalars['String']>;
  to_starts_with?: Maybe<Scalars['String']>;
  to_starts_with_nocase?: Maybe<Scalars['String']>;
  to_not_starts_with?: Maybe<Scalars['String']>;
  to_not_starts_with_nocase?: Maybe<Scalars['String']>;
  to_ends_with?: Maybe<Scalars['String']>;
  to_ends_with_nocase?: Maybe<Scalars['String']>;
  to_not_ends_with?: Maybe<Scalars['String']>;
  to_not_ends_with_nocase?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  from_not?: Maybe<Scalars['String']>;
  from_gt?: Maybe<Scalars['String']>;
  from_lt?: Maybe<Scalars['String']>;
  from_gte?: Maybe<Scalars['String']>;
  from_lte?: Maybe<Scalars['String']>;
  from_in?: Maybe<Array<Scalars['String']>>;
  from_not_in?: Maybe<Array<Scalars['String']>>;
  from_contains?: Maybe<Scalars['String']>;
  from_contains_nocase?: Maybe<Scalars['String']>;
  from_not_contains?: Maybe<Scalars['String']>;
  from_not_contains_nocase?: Maybe<Scalars['String']>;
  from_starts_with?: Maybe<Scalars['String']>;
  from_starts_with_nocase?: Maybe<Scalars['String']>;
  from_not_starts_with?: Maybe<Scalars['String']>;
  from_not_starts_with_nocase?: Maybe<Scalars['String']>;
  from_ends_with?: Maybe<Scalars['String']>;
  from_ends_with_nocase?: Maybe<Scalars['String']>;
  from_not_ends_with?: Maybe<Scalars['String']>;
  from_not_ends_with_nocase?: Maybe<Scalars['String']>;
  blockNumber?: Maybe<Scalars['BigInt']>;
  blockNumber_not?: Maybe<Scalars['BigInt']>;
  blockNumber_gt?: Maybe<Scalars['BigInt']>;
  blockNumber_lt?: Maybe<Scalars['BigInt']>;
  blockNumber_gte?: Maybe<Scalars['BigInt']>;
  blockNumber_lte?: Maybe<Scalars['BigInt']>;
  blockNumber_in?: Maybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  asset?: Maybe<Scalars['String']>;
  asset_not?: Maybe<Scalars['String']>;
  asset_gt?: Maybe<Scalars['String']>;
  asset_lt?: Maybe<Scalars['String']>;
  asset_gte?: Maybe<Scalars['String']>;
  asset_lte?: Maybe<Scalars['String']>;
  asset_in?: Maybe<Array<Scalars['String']>>;
  asset_not_in?: Maybe<Array<Scalars['String']>>;
  asset_contains?: Maybe<Scalars['String']>;
  asset_contains_nocase?: Maybe<Scalars['String']>;
  asset_not_contains?: Maybe<Scalars['String']>;
  asset_not_contains_nocase?: Maybe<Scalars['String']>;
  asset_starts_with?: Maybe<Scalars['String']>;
  asset_starts_with_nocase?: Maybe<Scalars['String']>;
  asset_not_starts_with?: Maybe<Scalars['String']>;
  asset_not_starts_with_nocase?: Maybe<Scalars['String']>;
  asset_ends_with?: Maybe<Scalars['String']>;
  asset_ends_with_nocase?: Maybe<Scalars['String']>;
  asset_not_ends_with?: Maybe<Scalars['String']>;
  asset_not_ends_with_nocase?: Maybe<Scalars['String']>;
  asset_?: Maybe<Token_Filter>;
  amount?: Maybe<Scalars['BigInt']>;
  amount_not?: Maybe<Scalars['BigInt']>;
  amount_gt?: Maybe<Scalars['BigInt']>;
  amount_lt?: Maybe<Scalars['BigInt']>;
  amount_gte?: Maybe<Scalars['BigInt']>;
  amount_lte?: Maybe<Scalars['BigInt']>;
  amount_in?: Maybe<Array<Scalars['BigInt']>>;
  amount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  amountUSD?: Maybe<Scalars['BigDecimal']>;
  amountUSD_not?: Maybe<Scalars['BigDecimal']>;
  amountUSD_gt?: Maybe<Scalars['BigDecimal']>;
  amountUSD_lt?: Maybe<Scalars['BigDecimal']>;
  amountUSD_gte?: Maybe<Scalars['BigDecimal']>;
  amountUSD_lte?: Maybe<Scalars['BigDecimal']>;
  amountUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amountUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  vault?: Maybe<Scalars['String']>;
  vault_not?: Maybe<Scalars['String']>;
  vault_gt?: Maybe<Scalars['String']>;
  vault_lt?: Maybe<Scalars['String']>;
  vault_gte?: Maybe<Scalars['String']>;
  vault_lte?: Maybe<Scalars['String']>;
  vault_in?: Maybe<Array<Scalars['String']>>;
  vault_not_in?: Maybe<Array<Scalars['String']>>;
  vault_contains?: Maybe<Scalars['String']>;
  vault_contains_nocase?: Maybe<Scalars['String']>;
  vault_not_contains?: Maybe<Scalars['String']>;
  vault_not_contains_nocase?: Maybe<Scalars['String']>;
  vault_starts_with?: Maybe<Scalars['String']>;
  vault_starts_with_nocase?: Maybe<Scalars['String']>;
  vault_not_starts_with?: Maybe<Scalars['String']>;
  vault_not_starts_with_nocase?: Maybe<Scalars['String']>;
  vault_ends_with?: Maybe<Scalars['String']>;
  vault_ends_with_nocase?: Maybe<Scalars['String']>;
  vault_not_ends_with?: Maybe<Scalars['String']>;
  vault_not_ends_with_nocase?: Maybe<Scalars['String']>;
  vault_?: Maybe<KodiakVault_Filter>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<KodiakWithdraw_Filter>>>;
  or?: Maybe<Array<Maybe<KodiakWithdraw_Filter>>>;
};

export enum KodiakWithdraw_OrderBy {
  Id = 'id',
  Hash = 'hash',
  LogIndex = 'logIndex',
  Protocol = 'protocol',
  ProtocolId = 'protocol__id',
  ProtocolName = 'protocol__name',
  ProtocolSlug = 'protocol__slug',
  ProtocolSchemaVersion = 'protocol__schemaVersion',
  ProtocolSubgraphVersion = 'protocol__subgraphVersion',
  ProtocolMethodologyVersion = 'protocol__methodologyVersion',
  ProtocolNetwork = 'protocol__network',
  ProtocolType = 'protocol__type',
  ProtocolTotalValueLockedUsd = 'protocol__totalValueLockedUSD',
  ProtocolProtocolControlledValueUsd = 'protocol__protocolControlledValueUSD',
  ProtocolCumulativeSupplySideRevenueUsd = 'protocol__cumulativeSupplySideRevenueUSD',
  ProtocolCumulativeProtocolSideRevenueUsd = 'protocol__cumulativeProtocolSideRevenueUSD',
  ProtocolCumulativeTotalRevenueUsd = 'protocol__cumulativeTotalRevenueUSD',
  ProtocolCumulativeUniqueUsers = 'protocol__cumulativeUniqueUsers',
  ProtocolTotalPoolCount = 'protocol__totalPoolCount',
  To = 'to',
  From = 'from',
  BlockNumber = 'blockNumber',
  Timestamp = 'timestamp',
  Asset = 'asset',
  AssetId = 'asset__id',
  AssetSymbol = 'asset__symbol',
  AssetName = 'asset__name',
  AssetDecimals = 'asset__decimals',
  AssetTotalSupply = 'asset__totalSupply',
  AssetVolume = 'asset__volume',
  AssetVolumeUsd = 'asset__volumeUSD',
  AssetUntrackedVolumeUsd = 'asset__untrackedVolumeUSD',
  AssetFeesUsd = 'asset__feesUSD',
  AssetTxCount = 'asset__txCount',
  AssetPoolCount = 'asset__poolCount',
  AssetTotalValueLocked = 'asset__totalValueLocked',
  AssetTotalValueLockedUsd = 'asset__totalValueLockedUSD',
  AssetTotalValueLockedUsdUntracked = 'asset__totalValueLockedUSDUntracked',
  AssetDerivedEth = 'asset__derivedETH',
  Amount = 'amount',
  AmountUsd = 'amountUSD',
  Vault = 'vault',
  VaultId = 'vault__id',
  VaultName = 'vault__name',
  VaultSymbol = 'vault__symbol',
  VaultDepositLimit = 'vault__depositLimit',
  VaultCreatedTimestamp = 'vault__createdTimestamp',
  VaultCreatedBlockNumber = 'vault__createdBlockNumber',
  VaultTotalValueLockedUsd = 'vault__totalValueLockedUSD',
  VaultCumulativeSupplySideRevenueUsd = 'vault__cumulativeSupplySideRevenueUSD',
  VaultCumulativeProtocolSideRevenueUsd = 'vault__cumulativeProtocolSideRevenueUSD',
  VaultCumulativeTotalRevenueUsd = 'vault__cumulativeTotalRevenueUSD',
  VaultInputTokenBalance = 'vault__inputTokenBalance',
  VaultOutputTokenSupply = 'vault__outputTokenSupply',
  VaultOutputTokenPriceUsd = 'vault__outputTokenPriceUSD',
  VaultPricePerShare = 'vault__pricePerShare',
  VaultStakedOutputTokenAmount = 'vault__stakedOutputTokenAmount',
  VaultVolumeToken0 = 'vault__volumeToken0',
  VaultVolumeToken1 = 'vault__volumeToken1',
  VaultVolumeUsd = 'vault__volumeUSD',
  VaultLowerTick = 'vault__lowerTick',
  VaultUpperTick = 'vault__upperTick',
  VaultToken0Amount = 'vault___token0Amount',
  VaultToken1Amount = 'vault___token1Amount',
  VaultToken0AmountUsd = 'vault___token0AmountUSD',
  VaultToken1AmountUsd = 'vault___token1AmountUSD'
}

export type Mint = {
  __typename?: 'Mint';
  id: Scalars['ID'];
  transaction: Transaction;
  timestamp: Scalars['BigInt'];
  pool: Pool;
  token0: Token;
  token1: Token;
  owner: Scalars['Bytes'];
  sender?: Maybe<Scalars['Bytes']>;
  origin: Scalars['Bytes'];
  amount: Scalars['BigInt'];
  amount0: Scalars['BigDecimal'];
  amount1: Scalars['BigDecimal'];
  amountUSD?: Maybe<Scalars['BigDecimal']>;
  tickLower: Scalars['BigInt'];
  tickUpper: Scalars['BigInt'];
  logIndex?: Maybe<Scalars['BigInt']>;
};

export type Mint_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  transaction?: Maybe<Scalars['String']>;
  transaction_not?: Maybe<Scalars['String']>;
  transaction_gt?: Maybe<Scalars['String']>;
  transaction_lt?: Maybe<Scalars['String']>;
  transaction_gte?: Maybe<Scalars['String']>;
  transaction_lte?: Maybe<Scalars['String']>;
  transaction_in?: Maybe<Array<Scalars['String']>>;
  transaction_not_in?: Maybe<Array<Scalars['String']>>;
  transaction_contains?: Maybe<Scalars['String']>;
  transaction_contains_nocase?: Maybe<Scalars['String']>;
  transaction_not_contains?: Maybe<Scalars['String']>;
  transaction_not_contains_nocase?: Maybe<Scalars['String']>;
  transaction_starts_with?: Maybe<Scalars['String']>;
  transaction_starts_with_nocase?: Maybe<Scalars['String']>;
  transaction_not_starts_with?: Maybe<Scalars['String']>;
  transaction_not_starts_with_nocase?: Maybe<Scalars['String']>;
  transaction_ends_with?: Maybe<Scalars['String']>;
  transaction_ends_with_nocase?: Maybe<Scalars['String']>;
  transaction_not_ends_with?: Maybe<Scalars['String']>;
  transaction_not_ends_with_nocase?: Maybe<Scalars['String']>;
  transaction_?: Maybe<Transaction_Filter>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  pool?: Maybe<Scalars['String']>;
  pool_not?: Maybe<Scalars['String']>;
  pool_gt?: Maybe<Scalars['String']>;
  pool_lt?: Maybe<Scalars['String']>;
  pool_gte?: Maybe<Scalars['String']>;
  pool_lte?: Maybe<Scalars['String']>;
  pool_in?: Maybe<Array<Scalars['String']>>;
  pool_not_in?: Maybe<Array<Scalars['String']>>;
  pool_contains?: Maybe<Scalars['String']>;
  pool_contains_nocase?: Maybe<Scalars['String']>;
  pool_not_contains?: Maybe<Scalars['String']>;
  pool_not_contains_nocase?: Maybe<Scalars['String']>;
  pool_starts_with?: Maybe<Scalars['String']>;
  pool_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_not_starts_with?: Maybe<Scalars['String']>;
  pool_not_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_ends_with?: Maybe<Scalars['String']>;
  pool_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_not_ends_with?: Maybe<Scalars['String']>;
  pool_not_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_?: Maybe<Pool_Filter>;
  token0?: Maybe<Scalars['String']>;
  token0_not?: Maybe<Scalars['String']>;
  token0_gt?: Maybe<Scalars['String']>;
  token0_lt?: Maybe<Scalars['String']>;
  token0_gte?: Maybe<Scalars['String']>;
  token0_lte?: Maybe<Scalars['String']>;
  token0_in?: Maybe<Array<Scalars['String']>>;
  token0_not_in?: Maybe<Array<Scalars['String']>>;
  token0_contains?: Maybe<Scalars['String']>;
  token0_contains_nocase?: Maybe<Scalars['String']>;
  token0_not_contains?: Maybe<Scalars['String']>;
  token0_not_contains_nocase?: Maybe<Scalars['String']>;
  token0_starts_with?: Maybe<Scalars['String']>;
  token0_starts_with_nocase?: Maybe<Scalars['String']>;
  token0_not_starts_with?: Maybe<Scalars['String']>;
  token0_not_starts_with_nocase?: Maybe<Scalars['String']>;
  token0_ends_with?: Maybe<Scalars['String']>;
  token0_ends_with_nocase?: Maybe<Scalars['String']>;
  token0_not_ends_with?: Maybe<Scalars['String']>;
  token0_not_ends_with_nocase?: Maybe<Scalars['String']>;
  token0_?: Maybe<Token_Filter>;
  token1?: Maybe<Scalars['String']>;
  token1_not?: Maybe<Scalars['String']>;
  token1_gt?: Maybe<Scalars['String']>;
  token1_lt?: Maybe<Scalars['String']>;
  token1_gte?: Maybe<Scalars['String']>;
  token1_lte?: Maybe<Scalars['String']>;
  token1_in?: Maybe<Array<Scalars['String']>>;
  token1_not_in?: Maybe<Array<Scalars['String']>>;
  token1_contains?: Maybe<Scalars['String']>;
  token1_contains_nocase?: Maybe<Scalars['String']>;
  token1_not_contains?: Maybe<Scalars['String']>;
  token1_not_contains_nocase?: Maybe<Scalars['String']>;
  token1_starts_with?: Maybe<Scalars['String']>;
  token1_starts_with_nocase?: Maybe<Scalars['String']>;
  token1_not_starts_with?: Maybe<Scalars['String']>;
  token1_not_starts_with_nocase?: Maybe<Scalars['String']>;
  token1_ends_with?: Maybe<Scalars['String']>;
  token1_ends_with_nocase?: Maybe<Scalars['String']>;
  token1_not_ends_with?: Maybe<Scalars['String']>;
  token1_not_ends_with_nocase?: Maybe<Scalars['String']>;
  token1_?: Maybe<Token_Filter>;
  owner?: Maybe<Scalars['Bytes']>;
  owner_not?: Maybe<Scalars['Bytes']>;
  owner_gt?: Maybe<Scalars['Bytes']>;
  owner_lt?: Maybe<Scalars['Bytes']>;
  owner_gte?: Maybe<Scalars['Bytes']>;
  owner_lte?: Maybe<Scalars['Bytes']>;
  owner_in?: Maybe<Array<Scalars['Bytes']>>;
  owner_not_in?: Maybe<Array<Scalars['Bytes']>>;
  owner_contains?: Maybe<Scalars['Bytes']>;
  owner_not_contains?: Maybe<Scalars['Bytes']>;
  sender?: Maybe<Scalars['Bytes']>;
  sender_not?: Maybe<Scalars['Bytes']>;
  sender_gt?: Maybe<Scalars['Bytes']>;
  sender_lt?: Maybe<Scalars['Bytes']>;
  sender_gte?: Maybe<Scalars['Bytes']>;
  sender_lte?: Maybe<Scalars['Bytes']>;
  sender_in?: Maybe<Array<Scalars['Bytes']>>;
  sender_not_in?: Maybe<Array<Scalars['Bytes']>>;
  sender_contains?: Maybe<Scalars['Bytes']>;
  sender_not_contains?: Maybe<Scalars['Bytes']>;
  origin?: Maybe<Scalars['Bytes']>;
  origin_not?: Maybe<Scalars['Bytes']>;
  origin_gt?: Maybe<Scalars['Bytes']>;
  origin_lt?: Maybe<Scalars['Bytes']>;
  origin_gte?: Maybe<Scalars['Bytes']>;
  origin_lte?: Maybe<Scalars['Bytes']>;
  origin_in?: Maybe<Array<Scalars['Bytes']>>;
  origin_not_in?: Maybe<Array<Scalars['Bytes']>>;
  origin_contains?: Maybe<Scalars['Bytes']>;
  origin_not_contains?: Maybe<Scalars['Bytes']>;
  amount?: Maybe<Scalars['BigInt']>;
  amount_not?: Maybe<Scalars['BigInt']>;
  amount_gt?: Maybe<Scalars['BigInt']>;
  amount_lt?: Maybe<Scalars['BigInt']>;
  amount_gte?: Maybe<Scalars['BigInt']>;
  amount_lte?: Maybe<Scalars['BigInt']>;
  amount_in?: Maybe<Array<Scalars['BigInt']>>;
  amount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  amount0?: Maybe<Scalars['BigDecimal']>;
  amount0_not?: Maybe<Scalars['BigDecimal']>;
  amount0_gt?: Maybe<Scalars['BigDecimal']>;
  amount0_lt?: Maybe<Scalars['BigDecimal']>;
  amount0_gte?: Maybe<Scalars['BigDecimal']>;
  amount0_lte?: Maybe<Scalars['BigDecimal']>;
  amount0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amount0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amount1?: Maybe<Scalars['BigDecimal']>;
  amount1_not?: Maybe<Scalars['BigDecimal']>;
  amount1_gt?: Maybe<Scalars['BigDecimal']>;
  amount1_lt?: Maybe<Scalars['BigDecimal']>;
  amount1_gte?: Maybe<Scalars['BigDecimal']>;
  amount1_lte?: Maybe<Scalars['BigDecimal']>;
  amount1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amount1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amountUSD?: Maybe<Scalars['BigDecimal']>;
  amountUSD_not?: Maybe<Scalars['BigDecimal']>;
  amountUSD_gt?: Maybe<Scalars['BigDecimal']>;
  amountUSD_lt?: Maybe<Scalars['BigDecimal']>;
  amountUSD_gte?: Maybe<Scalars['BigDecimal']>;
  amountUSD_lte?: Maybe<Scalars['BigDecimal']>;
  amountUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amountUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  tickLower?: Maybe<Scalars['BigInt']>;
  tickLower_not?: Maybe<Scalars['BigInt']>;
  tickLower_gt?: Maybe<Scalars['BigInt']>;
  tickLower_lt?: Maybe<Scalars['BigInt']>;
  tickLower_gte?: Maybe<Scalars['BigInt']>;
  tickLower_lte?: Maybe<Scalars['BigInt']>;
  tickLower_in?: Maybe<Array<Scalars['BigInt']>>;
  tickLower_not_in?: Maybe<Array<Scalars['BigInt']>>;
  tickUpper?: Maybe<Scalars['BigInt']>;
  tickUpper_not?: Maybe<Scalars['BigInt']>;
  tickUpper_gt?: Maybe<Scalars['BigInt']>;
  tickUpper_lt?: Maybe<Scalars['BigInt']>;
  tickUpper_gte?: Maybe<Scalars['BigInt']>;
  tickUpper_lte?: Maybe<Scalars['BigInt']>;
  tickUpper_in?: Maybe<Array<Scalars['BigInt']>>;
  tickUpper_not_in?: Maybe<Array<Scalars['BigInt']>>;
  logIndex?: Maybe<Scalars['BigInt']>;
  logIndex_not?: Maybe<Scalars['BigInt']>;
  logIndex_gt?: Maybe<Scalars['BigInt']>;
  logIndex_lt?: Maybe<Scalars['BigInt']>;
  logIndex_gte?: Maybe<Scalars['BigInt']>;
  logIndex_lte?: Maybe<Scalars['BigInt']>;
  logIndex_in?: Maybe<Array<Scalars['BigInt']>>;
  logIndex_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Mint_Filter>>>;
  or?: Maybe<Array<Maybe<Mint_Filter>>>;
};

export enum Mint_OrderBy {
  Id = 'id',
  Transaction = 'transaction',
  TransactionId = 'transaction__id',
  TransactionBlockNumber = 'transaction__blockNumber',
  TransactionTimestamp = 'transaction__timestamp',
  TransactionGasUsed = 'transaction__gasUsed',
  TransactionGasPrice = 'transaction__gasPrice',
  Timestamp = 'timestamp',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolCreatedAtTimestamp = 'pool__createdAtTimestamp',
  PoolCreatedAtBlockNumber = 'pool__createdAtBlockNumber',
  PoolFeeTier = 'pool__feeTier',
  PoolLiquidity = 'pool__liquidity',
  PoolSqrtPrice = 'pool__sqrtPrice',
  PoolFeeGrowthGlobal0X128 = 'pool__feeGrowthGlobal0X128',
  PoolFeeGrowthGlobal1X128 = 'pool__feeGrowthGlobal1X128',
  PoolToken0Price = 'pool__token0Price',
  PoolToken1Price = 'pool__token1Price',
  PoolTick = 'pool__tick',
  PoolObservationIndex = 'pool__observationIndex',
  PoolVolumeToken0 = 'pool__volumeToken0',
  PoolVolumeToken1 = 'pool__volumeToken1',
  PoolVolumeUsd = 'pool__volumeUSD',
  PoolUntrackedVolumeUsd = 'pool__untrackedVolumeUSD',
  PoolFeesUsd = 'pool__feesUSD',
  PoolTxCount = 'pool__txCount',
  PoolCollectedFeesToken0 = 'pool__collectedFeesToken0',
  PoolCollectedFeesToken1 = 'pool__collectedFeesToken1',
  PoolCollectedFeesUsd = 'pool__collectedFeesUSD',
  PoolTotalValueLockedToken0 = 'pool__totalValueLockedToken0',
  PoolTotalValueLockedToken1 = 'pool__totalValueLockedToken1',
  PoolTotalValueLockedEth = 'pool__totalValueLockedETH',
  PoolTotalValueLockedUsd = 'pool__totalValueLockedUSD',
  PoolTotalValueLockedUsdUntracked = 'pool__totalValueLockedUSDUntracked',
  PoolLiquidityProviderCount = 'pool__liquidityProviderCount',
  Token0 = 'token0',
  Token0Id = 'token0__id',
  Token0Symbol = 'token0__symbol',
  Token0Name = 'token0__name',
  Token0Decimals = 'token0__decimals',
  Token0TotalSupply = 'token0__totalSupply',
  Token0Volume = 'token0__volume',
  Token0VolumeUsd = 'token0__volumeUSD',
  Token0UntrackedVolumeUsd = 'token0__untrackedVolumeUSD',
  Token0FeesUsd = 'token0__feesUSD',
  Token0TxCount = 'token0__txCount',
  Token0PoolCount = 'token0__poolCount',
  Token0TotalValueLocked = 'token0__totalValueLocked',
  Token0TotalValueLockedUsd = 'token0__totalValueLockedUSD',
  Token0TotalValueLockedUsdUntracked = 'token0__totalValueLockedUSDUntracked',
  Token0DerivedEth = 'token0__derivedETH',
  Token1 = 'token1',
  Token1Id = 'token1__id',
  Token1Symbol = 'token1__symbol',
  Token1Name = 'token1__name',
  Token1Decimals = 'token1__decimals',
  Token1TotalSupply = 'token1__totalSupply',
  Token1Volume = 'token1__volume',
  Token1VolumeUsd = 'token1__volumeUSD',
  Token1UntrackedVolumeUsd = 'token1__untrackedVolumeUSD',
  Token1FeesUsd = 'token1__feesUSD',
  Token1TxCount = 'token1__txCount',
  Token1PoolCount = 'token1__poolCount',
  Token1TotalValueLocked = 'token1__totalValueLocked',
  Token1TotalValueLockedUsd = 'token1__totalValueLockedUSD',
  Token1TotalValueLockedUsdUntracked = 'token1__totalValueLockedUSDUntracked',
  Token1DerivedEth = 'token1__derivedETH',
  Owner = 'owner',
  Sender = 'sender',
  Origin = 'origin',
  Amount = 'amount',
  Amount0 = 'amount0',
  Amount1 = 'amount1',
  AmountUsd = 'amountUSD',
  TickLower = 'tickLower',
  TickUpper = 'tickUpper',
  LogIndex = 'logIndex'
}

export enum Network {
  Mumbai = 'MUMBAI',
  BerachainTestnet = 'BERACHAIN_TESTNET',
  BerachainPublicTestnet = 'BERACHAIN_PUBLIC_TESTNET'
}

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Pool = {
  __typename?: 'Pool';
  id: Scalars['ID'];
  vault?: Maybe<KodiakVault>;
  createdAtTimestamp: Scalars['BigInt'];
  createdAtBlockNumber: Scalars['BigInt'];
  token0: Token;
  token1: Token;
  feeTier: Scalars['BigInt'];
  liquidity: Scalars['BigInt'];
  sqrtPrice: Scalars['BigInt'];
  feeGrowthGlobal0X128: Scalars['BigInt'];
  feeGrowthGlobal1X128: Scalars['BigInt'];
  token0Price: Scalars['BigDecimal'];
  token1Price: Scalars['BigDecimal'];
  tick?: Maybe<Scalars['BigInt']>;
  observationIndex: Scalars['BigInt'];
  volumeToken0: Scalars['BigDecimal'];
  volumeToken1: Scalars['BigDecimal'];
  volumeUSD: Scalars['BigDecimal'];
  untrackedVolumeUSD: Scalars['BigDecimal'];
  feesUSD: Scalars['BigDecimal'];
  txCount: Scalars['BigInt'];
  collectedFeesToken0: Scalars['BigDecimal'];
  collectedFeesToken1: Scalars['BigDecimal'];
  collectedFeesUSD: Scalars['BigDecimal'];
  totalValueLockedToken0: Scalars['BigDecimal'];
  totalValueLockedToken1: Scalars['BigDecimal'];
  totalValueLockedETH: Scalars['BigDecimal'];
  totalValueLockedUSD: Scalars['BigDecimal'];
  totalValueLockedUSDUntracked: Scalars['BigDecimal'];
  liquidityProviderCount: Scalars['BigInt'];
  poolHourData: Array<PoolHourData>;
  poolDayData: Array<PoolDayData>;
  mints: Array<Mint>;
  burns: Array<Burn>;
  swaps: Array<Swap>;
  collects: Array<Collect>;
  ticks: Array<Tick>;
};


export type PoolPoolHourDataArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PoolHourData_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<PoolHourData_Filter>;
};


export type PoolPoolDayDataArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PoolDayData_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<PoolDayData_Filter>;
};


export type PoolMintsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Mint_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Mint_Filter>;
};


export type PoolBurnsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Burn_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Burn_Filter>;
};


export type PoolSwapsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Swap_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Swap_Filter>;
};


export type PoolCollectsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Collect_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Collect_Filter>;
};


export type PoolTicksArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Tick_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Tick_Filter>;
};

export type PoolDayData = {
  __typename?: 'PoolDayData';
  id: Scalars['ID'];
  date: Scalars['Int'];
  pool: Pool;
  liquidity: Scalars['BigInt'];
  sqrtPrice: Scalars['BigInt'];
  token0Price: Scalars['BigDecimal'];
  token1Price: Scalars['BigDecimal'];
  tick?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal0X128: Scalars['BigInt'];
  feeGrowthGlobal1X128: Scalars['BigInt'];
  tvlUSD: Scalars['BigDecimal'];
  volumeToken0: Scalars['BigDecimal'];
  volumeToken1: Scalars['BigDecimal'];
  volumeUSD: Scalars['BigDecimal'];
  feesUSD: Scalars['BigDecimal'];
  txCount: Scalars['BigInt'];
  open: Scalars['BigDecimal'];
  high: Scalars['BigDecimal'];
  low: Scalars['BigDecimal'];
  close: Scalars['BigDecimal'];
};

export type PoolDayData_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  date?: Maybe<Scalars['Int']>;
  date_not?: Maybe<Scalars['Int']>;
  date_gt?: Maybe<Scalars['Int']>;
  date_lt?: Maybe<Scalars['Int']>;
  date_gte?: Maybe<Scalars['Int']>;
  date_lte?: Maybe<Scalars['Int']>;
  date_in?: Maybe<Array<Scalars['Int']>>;
  date_not_in?: Maybe<Array<Scalars['Int']>>;
  pool?: Maybe<Scalars['String']>;
  pool_not?: Maybe<Scalars['String']>;
  pool_gt?: Maybe<Scalars['String']>;
  pool_lt?: Maybe<Scalars['String']>;
  pool_gte?: Maybe<Scalars['String']>;
  pool_lte?: Maybe<Scalars['String']>;
  pool_in?: Maybe<Array<Scalars['String']>>;
  pool_not_in?: Maybe<Array<Scalars['String']>>;
  pool_contains?: Maybe<Scalars['String']>;
  pool_contains_nocase?: Maybe<Scalars['String']>;
  pool_not_contains?: Maybe<Scalars['String']>;
  pool_not_contains_nocase?: Maybe<Scalars['String']>;
  pool_starts_with?: Maybe<Scalars['String']>;
  pool_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_not_starts_with?: Maybe<Scalars['String']>;
  pool_not_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_ends_with?: Maybe<Scalars['String']>;
  pool_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_not_ends_with?: Maybe<Scalars['String']>;
  pool_not_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_?: Maybe<Pool_Filter>;
  liquidity?: Maybe<Scalars['BigInt']>;
  liquidity_not?: Maybe<Scalars['BigInt']>;
  liquidity_gt?: Maybe<Scalars['BigInt']>;
  liquidity_lt?: Maybe<Scalars['BigInt']>;
  liquidity_gte?: Maybe<Scalars['BigInt']>;
  liquidity_lte?: Maybe<Scalars['BigInt']>;
  liquidity_in?: Maybe<Array<Scalars['BigInt']>>;
  liquidity_not_in?: Maybe<Array<Scalars['BigInt']>>;
  sqrtPrice?: Maybe<Scalars['BigInt']>;
  sqrtPrice_not?: Maybe<Scalars['BigInt']>;
  sqrtPrice_gt?: Maybe<Scalars['BigInt']>;
  sqrtPrice_lt?: Maybe<Scalars['BigInt']>;
  sqrtPrice_gte?: Maybe<Scalars['BigInt']>;
  sqrtPrice_lte?: Maybe<Scalars['BigInt']>;
  sqrtPrice_in?: Maybe<Array<Scalars['BigInt']>>;
  sqrtPrice_not_in?: Maybe<Array<Scalars['BigInt']>>;
  token0Price?: Maybe<Scalars['BigDecimal']>;
  token0Price_not?: Maybe<Scalars['BigDecimal']>;
  token0Price_gt?: Maybe<Scalars['BigDecimal']>;
  token0Price_lt?: Maybe<Scalars['BigDecimal']>;
  token0Price_gte?: Maybe<Scalars['BigDecimal']>;
  token0Price_lte?: Maybe<Scalars['BigDecimal']>;
  token0Price_in?: Maybe<Array<Scalars['BigDecimal']>>;
  token0Price_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  token1Price?: Maybe<Scalars['BigDecimal']>;
  token1Price_not?: Maybe<Scalars['BigDecimal']>;
  token1Price_gt?: Maybe<Scalars['BigDecimal']>;
  token1Price_lt?: Maybe<Scalars['BigDecimal']>;
  token1Price_gte?: Maybe<Scalars['BigDecimal']>;
  token1Price_lte?: Maybe<Scalars['BigDecimal']>;
  token1Price_in?: Maybe<Array<Scalars['BigDecimal']>>;
  token1Price_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  tick?: Maybe<Scalars['BigInt']>;
  tick_not?: Maybe<Scalars['BigInt']>;
  tick_gt?: Maybe<Scalars['BigInt']>;
  tick_lt?: Maybe<Scalars['BigInt']>;
  tick_gte?: Maybe<Scalars['BigInt']>;
  tick_lte?: Maybe<Scalars['BigInt']>;
  tick_in?: Maybe<Array<Scalars['BigInt']>>;
  tick_not_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthGlobal0X128?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal0X128_not?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal0X128_gt?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal0X128_lt?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal0X128_gte?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal0X128_lte?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal0X128_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthGlobal0X128_not_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthGlobal1X128?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal1X128_not?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal1X128_gt?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal1X128_lt?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal1X128_gte?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal1X128_lte?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal1X128_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthGlobal1X128_not_in?: Maybe<Array<Scalars['BigInt']>>;
  tvlUSD?: Maybe<Scalars['BigDecimal']>;
  tvlUSD_not?: Maybe<Scalars['BigDecimal']>;
  tvlUSD_gt?: Maybe<Scalars['BigDecimal']>;
  tvlUSD_lt?: Maybe<Scalars['BigDecimal']>;
  tvlUSD_gte?: Maybe<Scalars['BigDecimal']>;
  tvlUSD_lte?: Maybe<Scalars['BigDecimal']>;
  tvlUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  tvlUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken0?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_not?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_gt?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_lt?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_gte?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_lte?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken1?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_not?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_gt?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_lt?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_gte?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_lte?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_not?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feesUSD?: Maybe<Scalars['BigDecimal']>;
  feesUSD_not?: Maybe<Scalars['BigDecimal']>;
  feesUSD_gt?: Maybe<Scalars['BigDecimal']>;
  feesUSD_lt?: Maybe<Scalars['BigDecimal']>;
  feesUSD_gte?: Maybe<Scalars['BigDecimal']>;
  feesUSD_lte?: Maybe<Scalars['BigDecimal']>;
  feesUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feesUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  txCount?: Maybe<Scalars['BigInt']>;
  txCount_not?: Maybe<Scalars['BigInt']>;
  txCount_gt?: Maybe<Scalars['BigInt']>;
  txCount_lt?: Maybe<Scalars['BigInt']>;
  txCount_gte?: Maybe<Scalars['BigInt']>;
  txCount_lte?: Maybe<Scalars['BigInt']>;
  txCount_in?: Maybe<Array<Scalars['BigInt']>>;
  txCount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  open?: Maybe<Scalars['BigDecimal']>;
  open_not?: Maybe<Scalars['BigDecimal']>;
  open_gt?: Maybe<Scalars['BigDecimal']>;
  open_lt?: Maybe<Scalars['BigDecimal']>;
  open_gte?: Maybe<Scalars['BigDecimal']>;
  open_lte?: Maybe<Scalars['BigDecimal']>;
  open_in?: Maybe<Array<Scalars['BigDecimal']>>;
  open_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  high?: Maybe<Scalars['BigDecimal']>;
  high_not?: Maybe<Scalars['BigDecimal']>;
  high_gt?: Maybe<Scalars['BigDecimal']>;
  high_lt?: Maybe<Scalars['BigDecimal']>;
  high_gte?: Maybe<Scalars['BigDecimal']>;
  high_lte?: Maybe<Scalars['BigDecimal']>;
  high_in?: Maybe<Array<Scalars['BigDecimal']>>;
  high_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  low?: Maybe<Scalars['BigDecimal']>;
  low_not?: Maybe<Scalars['BigDecimal']>;
  low_gt?: Maybe<Scalars['BigDecimal']>;
  low_lt?: Maybe<Scalars['BigDecimal']>;
  low_gte?: Maybe<Scalars['BigDecimal']>;
  low_lte?: Maybe<Scalars['BigDecimal']>;
  low_in?: Maybe<Array<Scalars['BigDecimal']>>;
  low_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  close?: Maybe<Scalars['BigDecimal']>;
  close_not?: Maybe<Scalars['BigDecimal']>;
  close_gt?: Maybe<Scalars['BigDecimal']>;
  close_lt?: Maybe<Scalars['BigDecimal']>;
  close_gte?: Maybe<Scalars['BigDecimal']>;
  close_lte?: Maybe<Scalars['BigDecimal']>;
  close_in?: Maybe<Array<Scalars['BigDecimal']>>;
  close_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<PoolDayData_Filter>>>;
  or?: Maybe<Array<Maybe<PoolDayData_Filter>>>;
};

export enum PoolDayData_OrderBy {
  Id = 'id',
  Date = 'date',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolCreatedAtTimestamp = 'pool__createdAtTimestamp',
  PoolCreatedAtBlockNumber = 'pool__createdAtBlockNumber',
  PoolFeeTier = 'pool__feeTier',
  PoolLiquidity = 'pool__liquidity',
  PoolSqrtPrice = 'pool__sqrtPrice',
  PoolFeeGrowthGlobal0X128 = 'pool__feeGrowthGlobal0X128',
  PoolFeeGrowthGlobal1X128 = 'pool__feeGrowthGlobal1X128',
  PoolToken0Price = 'pool__token0Price',
  PoolToken1Price = 'pool__token1Price',
  PoolTick = 'pool__tick',
  PoolObservationIndex = 'pool__observationIndex',
  PoolVolumeToken0 = 'pool__volumeToken0',
  PoolVolumeToken1 = 'pool__volumeToken1',
  PoolVolumeUsd = 'pool__volumeUSD',
  PoolUntrackedVolumeUsd = 'pool__untrackedVolumeUSD',
  PoolFeesUsd = 'pool__feesUSD',
  PoolTxCount = 'pool__txCount',
  PoolCollectedFeesToken0 = 'pool__collectedFeesToken0',
  PoolCollectedFeesToken1 = 'pool__collectedFeesToken1',
  PoolCollectedFeesUsd = 'pool__collectedFeesUSD',
  PoolTotalValueLockedToken0 = 'pool__totalValueLockedToken0',
  PoolTotalValueLockedToken1 = 'pool__totalValueLockedToken1',
  PoolTotalValueLockedEth = 'pool__totalValueLockedETH',
  PoolTotalValueLockedUsd = 'pool__totalValueLockedUSD',
  PoolTotalValueLockedUsdUntracked = 'pool__totalValueLockedUSDUntracked',
  PoolLiquidityProviderCount = 'pool__liquidityProviderCount',
  Liquidity = 'liquidity',
  SqrtPrice = 'sqrtPrice',
  Token0Price = 'token0Price',
  Token1Price = 'token1Price',
  Tick = 'tick',
  FeeGrowthGlobal0X128 = 'feeGrowthGlobal0X128',
  FeeGrowthGlobal1X128 = 'feeGrowthGlobal1X128',
  TvlUsd = 'tvlUSD',
  VolumeToken0 = 'volumeToken0',
  VolumeToken1 = 'volumeToken1',
  VolumeUsd = 'volumeUSD',
  FeesUsd = 'feesUSD',
  TxCount = 'txCount',
  Open = 'open',
  High = 'high',
  Low = 'low',
  Close = 'close'
}

export type PoolHourData = {
  __typename?: 'PoolHourData';
  id: Scalars['ID'];
  periodStartUnix: Scalars['Int'];
  pool: Pool;
  liquidity: Scalars['BigInt'];
  sqrtPrice: Scalars['BigInt'];
  token0Price: Scalars['BigDecimal'];
  token1Price: Scalars['BigDecimal'];
  tick?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal0X128: Scalars['BigInt'];
  feeGrowthGlobal1X128: Scalars['BigInt'];
  tvlUSD: Scalars['BigDecimal'];
  volumeToken0: Scalars['BigDecimal'];
  volumeToken1: Scalars['BigDecimal'];
  volumeUSD: Scalars['BigDecimal'];
  feesUSD: Scalars['BigDecimal'];
  txCount: Scalars['BigInt'];
  open: Scalars['BigDecimal'];
  high: Scalars['BigDecimal'];
  low: Scalars['BigDecimal'];
  close: Scalars['BigDecimal'];
};

export type PoolHourData_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  periodStartUnix?: Maybe<Scalars['Int']>;
  periodStartUnix_not?: Maybe<Scalars['Int']>;
  periodStartUnix_gt?: Maybe<Scalars['Int']>;
  periodStartUnix_lt?: Maybe<Scalars['Int']>;
  periodStartUnix_gte?: Maybe<Scalars['Int']>;
  periodStartUnix_lte?: Maybe<Scalars['Int']>;
  periodStartUnix_in?: Maybe<Array<Scalars['Int']>>;
  periodStartUnix_not_in?: Maybe<Array<Scalars['Int']>>;
  pool?: Maybe<Scalars['String']>;
  pool_not?: Maybe<Scalars['String']>;
  pool_gt?: Maybe<Scalars['String']>;
  pool_lt?: Maybe<Scalars['String']>;
  pool_gte?: Maybe<Scalars['String']>;
  pool_lte?: Maybe<Scalars['String']>;
  pool_in?: Maybe<Array<Scalars['String']>>;
  pool_not_in?: Maybe<Array<Scalars['String']>>;
  pool_contains?: Maybe<Scalars['String']>;
  pool_contains_nocase?: Maybe<Scalars['String']>;
  pool_not_contains?: Maybe<Scalars['String']>;
  pool_not_contains_nocase?: Maybe<Scalars['String']>;
  pool_starts_with?: Maybe<Scalars['String']>;
  pool_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_not_starts_with?: Maybe<Scalars['String']>;
  pool_not_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_ends_with?: Maybe<Scalars['String']>;
  pool_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_not_ends_with?: Maybe<Scalars['String']>;
  pool_not_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_?: Maybe<Pool_Filter>;
  liquidity?: Maybe<Scalars['BigInt']>;
  liquidity_not?: Maybe<Scalars['BigInt']>;
  liquidity_gt?: Maybe<Scalars['BigInt']>;
  liquidity_lt?: Maybe<Scalars['BigInt']>;
  liquidity_gte?: Maybe<Scalars['BigInt']>;
  liquidity_lte?: Maybe<Scalars['BigInt']>;
  liquidity_in?: Maybe<Array<Scalars['BigInt']>>;
  liquidity_not_in?: Maybe<Array<Scalars['BigInt']>>;
  sqrtPrice?: Maybe<Scalars['BigInt']>;
  sqrtPrice_not?: Maybe<Scalars['BigInt']>;
  sqrtPrice_gt?: Maybe<Scalars['BigInt']>;
  sqrtPrice_lt?: Maybe<Scalars['BigInt']>;
  sqrtPrice_gte?: Maybe<Scalars['BigInt']>;
  sqrtPrice_lte?: Maybe<Scalars['BigInt']>;
  sqrtPrice_in?: Maybe<Array<Scalars['BigInt']>>;
  sqrtPrice_not_in?: Maybe<Array<Scalars['BigInt']>>;
  token0Price?: Maybe<Scalars['BigDecimal']>;
  token0Price_not?: Maybe<Scalars['BigDecimal']>;
  token0Price_gt?: Maybe<Scalars['BigDecimal']>;
  token0Price_lt?: Maybe<Scalars['BigDecimal']>;
  token0Price_gte?: Maybe<Scalars['BigDecimal']>;
  token0Price_lte?: Maybe<Scalars['BigDecimal']>;
  token0Price_in?: Maybe<Array<Scalars['BigDecimal']>>;
  token0Price_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  token1Price?: Maybe<Scalars['BigDecimal']>;
  token1Price_not?: Maybe<Scalars['BigDecimal']>;
  token1Price_gt?: Maybe<Scalars['BigDecimal']>;
  token1Price_lt?: Maybe<Scalars['BigDecimal']>;
  token1Price_gte?: Maybe<Scalars['BigDecimal']>;
  token1Price_lte?: Maybe<Scalars['BigDecimal']>;
  token1Price_in?: Maybe<Array<Scalars['BigDecimal']>>;
  token1Price_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  tick?: Maybe<Scalars['BigInt']>;
  tick_not?: Maybe<Scalars['BigInt']>;
  tick_gt?: Maybe<Scalars['BigInt']>;
  tick_lt?: Maybe<Scalars['BigInt']>;
  tick_gte?: Maybe<Scalars['BigInt']>;
  tick_lte?: Maybe<Scalars['BigInt']>;
  tick_in?: Maybe<Array<Scalars['BigInt']>>;
  tick_not_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthGlobal0X128?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal0X128_not?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal0X128_gt?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal0X128_lt?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal0X128_gte?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal0X128_lte?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal0X128_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthGlobal0X128_not_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthGlobal1X128?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal1X128_not?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal1X128_gt?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal1X128_lt?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal1X128_gte?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal1X128_lte?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal1X128_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthGlobal1X128_not_in?: Maybe<Array<Scalars['BigInt']>>;
  tvlUSD?: Maybe<Scalars['BigDecimal']>;
  tvlUSD_not?: Maybe<Scalars['BigDecimal']>;
  tvlUSD_gt?: Maybe<Scalars['BigDecimal']>;
  tvlUSD_lt?: Maybe<Scalars['BigDecimal']>;
  tvlUSD_gte?: Maybe<Scalars['BigDecimal']>;
  tvlUSD_lte?: Maybe<Scalars['BigDecimal']>;
  tvlUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  tvlUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken0?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_not?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_gt?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_lt?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_gte?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_lte?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken1?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_not?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_gt?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_lt?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_gte?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_lte?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_not?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feesUSD?: Maybe<Scalars['BigDecimal']>;
  feesUSD_not?: Maybe<Scalars['BigDecimal']>;
  feesUSD_gt?: Maybe<Scalars['BigDecimal']>;
  feesUSD_lt?: Maybe<Scalars['BigDecimal']>;
  feesUSD_gte?: Maybe<Scalars['BigDecimal']>;
  feesUSD_lte?: Maybe<Scalars['BigDecimal']>;
  feesUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feesUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  txCount?: Maybe<Scalars['BigInt']>;
  txCount_not?: Maybe<Scalars['BigInt']>;
  txCount_gt?: Maybe<Scalars['BigInt']>;
  txCount_lt?: Maybe<Scalars['BigInt']>;
  txCount_gte?: Maybe<Scalars['BigInt']>;
  txCount_lte?: Maybe<Scalars['BigInt']>;
  txCount_in?: Maybe<Array<Scalars['BigInt']>>;
  txCount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  open?: Maybe<Scalars['BigDecimal']>;
  open_not?: Maybe<Scalars['BigDecimal']>;
  open_gt?: Maybe<Scalars['BigDecimal']>;
  open_lt?: Maybe<Scalars['BigDecimal']>;
  open_gte?: Maybe<Scalars['BigDecimal']>;
  open_lte?: Maybe<Scalars['BigDecimal']>;
  open_in?: Maybe<Array<Scalars['BigDecimal']>>;
  open_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  high?: Maybe<Scalars['BigDecimal']>;
  high_not?: Maybe<Scalars['BigDecimal']>;
  high_gt?: Maybe<Scalars['BigDecimal']>;
  high_lt?: Maybe<Scalars['BigDecimal']>;
  high_gte?: Maybe<Scalars['BigDecimal']>;
  high_lte?: Maybe<Scalars['BigDecimal']>;
  high_in?: Maybe<Array<Scalars['BigDecimal']>>;
  high_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  low?: Maybe<Scalars['BigDecimal']>;
  low_not?: Maybe<Scalars['BigDecimal']>;
  low_gt?: Maybe<Scalars['BigDecimal']>;
  low_lt?: Maybe<Scalars['BigDecimal']>;
  low_gte?: Maybe<Scalars['BigDecimal']>;
  low_lte?: Maybe<Scalars['BigDecimal']>;
  low_in?: Maybe<Array<Scalars['BigDecimal']>>;
  low_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  close?: Maybe<Scalars['BigDecimal']>;
  close_not?: Maybe<Scalars['BigDecimal']>;
  close_gt?: Maybe<Scalars['BigDecimal']>;
  close_lt?: Maybe<Scalars['BigDecimal']>;
  close_gte?: Maybe<Scalars['BigDecimal']>;
  close_lte?: Maybe<Scalars['BigDecimal']>;
  close_in?: Maybe<Array<Scalars['BigDecimal']>>;
  close_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<PoolHourData_Filter>>>;
  or?: Maybe<Array<Maybe<PoolHourData_Filter>>>;
};

export enum PoolHourData_OrderBy {
  Id = 'id',
  PeriodStartUnix = 'periodStartUnix',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolCreatedAtTimestamp = 'pool__createdAtTimestamp',
  PoolCreatedAtBlockNumber = 'pool__createdAtBlockNumber',
  PoolFeeTier = 'pool__feeTier',
  PoolLiquidity = 'pool__liquidity',
  PoolSqrtPrice = 'pool__sqrtPrice',
  PoolFeeGrowthGlobal0X128 = 'pool__feeGrowthGlobal0X128',
  PoolFeeGrowthGlobal1X128 = 'pool__feeGrowthGlobal1X128',
  PoolToken0Price = 'pool__token0Price',
  PoolToken1Price = 'pool__token1Price',
  PoolTick = 'pool__tick',
  PoolObservationIndex = 'pool__observationIndex',
  PoolVolumeToken0 = 'pool__volumeToken0',
  PoolVolumeToken1 = 'pool__volumeToken1',
  PoolVolumeUsd = 'pool__volumeUSD',
  PoolUntrackedVolumeUsd = 'pool__untrackedVolumeUSD',
  PoolFeesUsd = 'pool__feesUSD',
  PoolTxCount = 'pool__txCount',
  PoolCollectedFeesToken0 = 'pool__collectedFeesToken0',
  PoolCollectedFeesToken1 = 'pool__collectedFeesToken1',
  PoolCollectedFeesUsd = 'pool__collectedFeesUSD',
  PoolTotalValueLockedToken0 = 'pool__totalValueLockedToken0',
  PoolTotalValueLockedToken1 = 'pool__totalValueLockedToken1',
  PoolTotalValueLockedEth = 'pool__totalValueLockedETH',
  PoolTotalValueLockedUsd = 'pool__totalValueLockedUSD',
  PoolTotalValueLockedUsdUntracked = 'pool__totalValueLockedUSDUntracked',
  PoolLiquidityProviderCount = 'pool__liquidityProviderCount',
  Liquidity = 'liquidity',
  SqrtPrice = 'sqrtPrice',
  Token0Price = 'token0Price',
  Token1Price = 'token1Price',
  Tick = 'tick',
  FeeGrowthGlobal0X128 = 'feeGrowthGlobal0X128',
  FeeGrowthGlobal1X128 = 'feeGrowthGlobal1X128',
  TvlUsd = 'tvlUSD',
  VolumeToken0 = 'volumeToken0',
  VolumeToken1 = 'volumeToken1',
  VolumeUsd = 'volumeUSD',
  FeesUsd = 'feesUSD',
  TxCount = 'txCount',
  Open = 'open',
  High = 'high',
  Low = 'low',
  Close = 'close'
}

export type Pool_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  vault?: Maybe<Scalars['String']>;
  vault_not?: Maybe<Scalars['String']>;
  vault_gt?: Maybe<Scalars['String']>;
  vault_lt?: Maybe<Scalars['String']>;
  vault_gte?: Maybe<Scalars['String']>;
  vault_lte?: Maybe<Scalars['String']>;
  vault_in?: Maybe<Array<Scalars['String']>>;
  vault_not_in?: Maybe<Array<Scalars['String']>>;
  vault_contains?: Maybe<Scalars['String']>;
  vault_contains_nocase?: Maybe<Scalars['String']>;
  vault_not_contains?: Maybe<Scalars['String']>;
  vault_not_contains_nocase?: Maybe<Scalars['String']>;
  vault_starts_with?: Maybe<Scalars['String']>;
  vault_starts_with_nocase?: Maybe<Scalars['String']>;
  vault_not_starts_with?: Maybe<Scalars['String']>;
  vault_not_starts_with_nocase?: Maybe<Scalars['String']>;
  vault_ends_with?: Maybe<Scalars['String']>;
  vault_ends_with_nocase?: Maybe<Scalars['String']>;
  vault_not_ends_with?: Maybe<Scalars['String']>;
  vault_not_ends_with_nocase?: Maybe<Scalars['String']>;
  vault_?: Maybe<KodiakVault_Filter>;
  createdAtTimestamp?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_not?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_gt?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_lt?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_gte?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_lte?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  createdAtTimestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  createdAtBlockNumber?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_not?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_gt?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_lt?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_gte?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_lte?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_in?: Maybe<Array<Scalars['BigInt']>>;
  createdAtBlockNumber_not_in?: Maybe<Array<Scalars['BigInt']>>;
  token0?: Maybe<Scalars['String']>;
  token0_not?: Maybe<Scalars['String']>;
  token0_gt?: Maybe<Scalars['String']>;
  token0_lt?: Maybe<Scalars['String']>;
  token0_gte?: Maybe<Scalars['String']>;
  token0_lte?: Maybe<Scalars['String']>;
  token0_in?: Maybe<Array<Scalars['String']>>;
  token0_not_in?: Maybe<Array<Scalars['String']>>;
  token0_contains?: Maybe<Scalars['String']>;
  token0_contains_nocase?: Maybe<Scalars['String']>;
  token0_not_contains?: Maybe<Scalars['String']>;
  token0_not_contains_nocase?: Maybe<Scalars['String']>;
  token0_starts_with?: Maybe<Scalars['String']>;
  token0_starts_with_nocase?: Maybe<Scalars['String']>;
  token0_not_starts_with?: Maybe<Scalars['String']>;
  token0_not_starts_with_nocase?: Maybe<Scalars['String']>;
  token0_ends_with?: Maybe<Scalars['String']>;
  token0_ends_with_nocase?: Maybe<Scalars['String']>;
  token0_not_ends_with?: Maybe<Scalars['String']>;
  token0_not_ends_with_nocase?: Maybe<Scalars['String']>;
  token0_?: Maybe<Token_Filter>;
  token1?: Maybe<Scalars['String']>;
  token1_not?: Maybe<Scalars['String']>;
  token1_gt?: Maybe<Scalars['String']>;
  token1_lt?: Maybe<Scalars['String']>;
  token1_gte?: Maybe<Scalars['String']>;
  token1_lte?: Maybe<Scalars['String']>;
  token1_in?: Maybe<Array<Scalars['String']>>;
  token1_not_in?: Maybe<Array<Scalars['String']>>;
  token1_contains?: Maybe<Scalars['String']>;
  token1_contains_nocase?: Maybe<Scalars['String']>;
  token1_not_contains?: Maybe<Scalars['String']>;
  token1_not_contains_nocase?: Maybe<Scalars['String']>;
  token1_starts_with?: Maybe<Scalars['String']>;
  token1_starts_with_nocase?: Maybe<Scalars['String']>;
  token1_not_starts_with?: Maybe<Scalars['String']>;
  token1_not_starts_with_nocase?: Maybe<Scalars['String']>;
  token1_ends_with?: Maybe<Scalars['String']>;
  token1_ends_with_nocase?: Maybe<Scalars['String']>;
  token1_not_ends_with?: Maybe<Scalars['String']>;
  token1_not_ends_with_nocase?: Maybe<Scalars['String']>;
  token1_?: Maybe<Token_Filter>;
  feeTier?: Maybe<Scalars['BigInt']>;
  feeTier_not?: Maybe<Scalars['BigInt']>;
  feeTier_gt?: Maybe<Scalars['BigInt']>;
  feeTier_lt?: Maybe<Scalars['BigInt']>;
  feeTier_gte?: Maybe<Scalars['BigInt']>;
  feeTier_lte?: Maybe<Scalars['BigInt']>;
  feeTier_in?: Maybe<Array<Scalars['BigInt']>>;
  feeTier_not_in?: Maybe<Array<Scalars['BigInt']>>;
  liquidity?: Maybe<Scalars['BigInt']>;
  liquidity_not?: Maybe<Scalars['BigInt']>;
  liquidity_gt?: Maybe<Scalars['BigInt']>;
  liquidity_lt?: Maybe<Scalars['BigInt']>;
  liquidity_gte?: Maybe<Scalars['BigInt']>;
  liquidity_lte?: Maybe<Scalars['BigInt']>;
  liquidity_in?: Maybe<Array<Scalars['BigInt']>>;
  liquidity_not_in?: Maybe<Array<Scalars['BigInt']>>;
  sqrtPrice?: Maybe<Scalars['BigInt']>;
  sqrtPrice_not?: Maybe<Scalars['BigInt']>;
  sqrtPrice_gt?: Maybe<Scalars['BigInt']>;
  sqrtPrice_lt?: Maybe<Scalars['BigInt']>;
  sqrtPrice_gte?: Maybe<Scalars['BigInt']>;
  sqrtPrice_lte?: Maybe<Scalars['BigInt']>;
  sqrtPrice_in?: Maybe<Array<Scalars['BigInt']>>;
  sqrtPrice_not_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthGlobal0X128?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal0X128_not?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal0X128_gt?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal0X128_lt?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal0X128_gte?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal0X128_lte?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal0X128_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthGlobal0X128_not_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthGlobal1X128?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal1X128_not?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal1X128_gt?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal1X128_lt?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal1X128_gte?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal1X128_lte?: Maybe<Scalars['BigInt']>;
  feeGrowthGlobal1X128_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthGlobal1X128_not_in?: Maybe<Array<Scalars['BigInt']>>;
  token0Price?: Maybe<Scalars['BigDecimal']>;
  token0Price_not?: Maybe<Scalars['BigDecimal']>;
  token0Price_gt?: Maybe<Scalars['BigDecimal']>;
  token0Price_lt?: Maybe<Scalars['BigDecimal']>;
  token0Price_gte?: Maybe<Scalars['BigDecimal']>;
  token0Price_lte?: Maybe<Scalars['BigDecimal']>;
  token0Price_in?: Maybe<Array<Scalars['BigDecimal']>>;
  token0Price_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  token1Price?: Maybe<Scalars['BigDecimal']>;
  token1Price_not?: Maybe<Scalars['BigDecimal']>;
  token1Price_gt?: Maybe<Scalars['BigDecimal']>;
  token1Price_lt?: Maybe<Scalars['BigDecimal']>;
  token1Price_gte?: Maybe<Scalars['BigDecimal']>;
  token1Price_lte?: Maybe<Scalars['BigDecimal']>;
  token1Price_in?: Maybe<Array<Scalars['BigDecimal']>>;
  token1Price_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  tick?: Maybe<Scalars['BigInt']>;
  tick_not?: Maybe<Scalars['BigInt']>;
  tick_gt?: Maybe<Scalars['BigInt']>;
  tick_lt?: Maybe<Scalars['BigInt']>;
  tick_gte?: Maybe<Scalars['BigInt']>;
  tick_lte?: Maybe<Scalars['BigInt']>;
  tick_in?: Maybe<Array<Scalars['BigInt']>>;
  tick_not_in?: Maybe<Array<Scalars['BigInt']>>;
  observationIndex?: Maybe<Scalars['BigInt']>;
  observationIndex_not?: Maybe<Scalars['BigInt']>;
  observationIndex_gt?: Maybe<Scalars['BigInt']>;
  observationIndex_lt?: Maybe<Scalars['BigInt']>;
  observationIndex_gte?: Maybe<Scalars['BigInt']>;
  observationIndex_lte?: Maybe<Scalars['BigInt']>;
  observationIndex_in?: Maybe<Array<Scalars['BigInt']>>;
  observationIndex_not_in?: Maybe<Array<Scalars['BigInt']>>;
  volumeToken0?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_not?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_gt?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_lt?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_gte?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_lte?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken1?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_not?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_gt?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_lt?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_gte?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_lte?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_not?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  untrackedVolumeUSD?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_not?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_gt?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_lt?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_gte?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_lte?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  untrackedVolumeUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feesUSD?: Maybe<Scalars['BigDecimal']>;
  feesUSD_not?: Maybe<Scalars['BigDecimal']>;
  feesUSD_gt?: Maybe<Scalars['BigDecimal']>;
  feesUSD_lt?: Maybe<Scalars['BigDecimal']>;
  feesUSD_gte?: Maybe<Scalars['BigDecimal']>;
  feesUSD_lte?: Maybe<Scalars['BigDecimal']>;
  feesUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feesUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  txCount?: Maybe<Scalars['BigInt']>;
  txCount_not?: Maybe<Scalars['BigInt']>;
  txCount_gt?: Maybe<Scalars['BigInt']>;
  txCount_lt?: Maybe<Scalars['BigInt']>;
  txCount_gte?: Maybe<Scalars['BigInt']>;
  txCount_lte?: Maybe<Scalars['BigInt']>;
  txCount_in?: Maybe<Array<Scalars['BigInt']>>;
  txCount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  collectedFeesToken0?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_not?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_gt?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_lt?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_gte?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_lte?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collectedFeesToken0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collectedFeesToken1?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_not?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_gt?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_lt?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_gte?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_lte?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collectedFeesToken1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collectedFeesUSD?: Maybe<Scalars['BigDecimal']>;
  collectedFeesUSD_not?: Maybe<Scalars['BigDecimal']>;
  collectedFeesUSD_gt?: Maybe<Scalars['BigDecimal']>;
  collectedFeesUSD_lt?: Maybe<Scalars['BigDecimal']>;
  collectedFeesUSD_gte?: Maybe<Scalars['BigDecimal']>;
  collectedFeesUSD_lte?: Maybe<Scalars['BigDecimal']>;
  collectedFeesUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collectedFeesUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedToken0?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedToken0_not?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedToken0_gt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedToken0_lt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedToken0_gte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedToken0_lte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedToken0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedToken0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedToken1?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedToken1_not?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedToken1_gt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedToken1_lt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedToken1_gte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedToken1_lte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedToken1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedToken1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedETH?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedETH_not?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedETH_gt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedETH_lt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedETH_gte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedETH_lte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedETH_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedETH_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedUSD?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_not?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_gt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_lt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_gte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_lte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedUSDUntracked?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSDUntracked_not?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSDUntracked_gt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSDUntracked_lt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSDUntracked_gte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSDUntracked_lte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSDUntracked_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedUSDUntracked_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  liquidityProviderCount?: Maybe<Scalars['BigInt']>;
  liquidityProviderCount_not?: Maybe<Scalars['BigInt']>;
  liquidityProviderCount_gt?: Maybe<Scalars['BigInt']>;
  liquidityProviderCount_lt?: Maybe<Scalars['BigInt']>;
  liquidityProviderCount_gte?: Maybe<Scalars['BigInt']>;
  liquidityProviderCount_lte?: Maybe<Scalars['BigInt']>;
  liquidityProviderCount_in?: Maybe<Array<Scalars['BigInt']>>;
  liquidityProviderCount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  poolHourData_?: Maybe<PoolHourData_Filter>;
  poolDayData_?: Maybe<PoolDayData_Filter>;
  mints_?: Maybe<Mint_Filter>;
  burns_?: Maybe<Burn_Filter>;
  swaps_?: Maybe<Swap_Filter>;
  collects_?: Maybe<Collect_Filter>;
  ticks_?: Maybe<Tick_Filter>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Pool_Filter>>>;
  or?: Maybe<Array<Maybe<Pool_Filter>>>;
};

export enum Pool_OrderBy {
  Id = 'id',
  Vault = 'vault',
  VaultId = 'vault__id',
  VaultName = 'vault__name',
  VaultSymbol = 'vault__symbol',
  VaultDepositLimit = 'vault__depositLimit',
  VaultCreatedTimestamp = 'vault__createdTimestamp',
  VaultCreatedBlockNumber = 'vault__createdBlockNumber',
  VaultTotalValueLockedUsd = 'vault__totalValueLockedUSD',
  VaultCumulativeSupplySideRevenueUsd = 'vault__cumulativeSupplySideRevenueUSD',
  VaultCumulativeProtocolSideRevenueUsd = 'vault__cumulativeProtocolSideRevenueUSD',
  VaultCumulativeTotalRevenueUsd = 'vault__cumulativeTotalRevenueUSD',
  VaultInputTokenBalance = 'vault__inputTokenBalance',
  VaultOutputTokenSupply = 'vault__outputTokenSupply',
  VaultOutputTokenPriceUsd = 'vault__outputTokenPriceUSD',
  VaultPricePerShare = 'vault__pricePerShare',
  VaultStakedOutputTokenAmount = 'vault__stakedOutputTokenAmount',
  VaultVolumeToken0 = 'vault__volumeToken0',
  VaultVolumeToken1 = 'vault__volumeToken1',
  VaultVolumeUsd = 'vault__volumeUSD',
  VaultLowerTick = 'vault__lowerTick',
  VaultUpperTick = 'vault__upperTick',
  VaultToken0Amount = 'vault___token0Amount',
  VaultToken1Amount = 'vault___token1Amount',
  VaultToken0AmountUsd = 'vault___token0AmountUSD',
  VaultToken1AmountUsd = 'vault___token1AmountUSD',
  CreatedAtTimestamp = 'createdAtTimestamp',
  CreatedAtBlockNumber = 'createdAtBlockNumber',
  Token0 = 'token0',
  Token0Id = 'token0__id',
  Token0Symbol = 'token0__symbol',
  Token0Name = 'token0__name',
  Token0Decimals = 'token0__decimals',
  Token0TotalSupply = 'token0__totalSupply',
  Token0Volume = 'token0__volume',
  Token0VolumeUsd = 'token0__volumeUSD',
  Token0UntrackedVolumeUsd = 'token0__untrackedVolumeUSD',
  Token0FeesUsd = 'token0__feesUSD',
  Token0TxCount = 'token0__txCount',
  Token0PoolCount = 'token0__poolCount',
  Token0TotalValueLocked = 'token0__totalValueLocked',
  Token0TotalValueLockedUsd = 'token0__totalValueLockedUSD',
  Token0TotalValueLockedUsdUntracked = 'token0__totalValueLockedUSDUntracked',
  Token0DerivedEth = 'token0__derivedETH',
  Token1 = 'token1',
  Token1Id = 'token1__id',
  Token1Symbol = 'token1__symbol',
  Token1Name = 'token1__name',
  Token1Decimals = 'token1__decimals',
  Token1TotalSupply = 'token1__totalSupply',
  Token1Volume = 'token1__volume',
  Token1VolumeUsd = 'token1__volumeUSD',
  Token1UntrackedVolumeUsd = 'token1__untrackedVolumeUSD',
  Token1FeesUsd = 'token1__feesUSD',
  Token1TxCount = 'token1__txCount',
  Token1PoolCount = 'token1__poolCount',
  Token1TotalValueLocked = 'token1__totalValueLocked',
  Token1TotalValueLockedUsd = 'token1__totalValueLockedUSD',
  Token1TotalValueLockedUsdUntracked = 'token1__totalValueLockedUSDUntracked',
  Token1DerivedEth = 'token1__derivedETH',
  FeeTier = 'feeTier',
  Liquidity = 'liquidity',
  SqrtPrice = 'sqrtPrice',
  FeeGrowthGlobal0X128 = 'feeGrowthGlobal0X128',
  FeeGrowthGlobal1X128 = 'feeGrowthGlobal1X128',
  Token0Price = 'token0Price',
  Token1Price = 'token1Price',
  Tick = 'tick',
  ObservationIndex = 'observationIndex',
  VolumeToken0 = 'volumeToken0',
  VolumeToken1 = 'volumeToken1',
  VolumeUsd = 'volumeUSD',
  UntrackedVolumeUsd = 'untrackedVolumeUSD',
  FeesUsd = 'feesUSD',
  TxCount = 'txCount',
  CollectedFeesToken0 = 'collectedFeesToken0',
  CollectedFeesToken1 = 'collectedFeesToken1',
  CollectedFeesUsd = 'collectedFeesUSD',
  TotalValueLockedToken0 = 'totalValueLockedToken0',
  TotalValueLockedToken1 = 'totalValueLockedToken1',
  TotalValueLockedEth = 'totalValueLockedETH',
  TotalValueLockedUsd = 'totalValueLockedUSD',
  TotalValueLockedUsdUntracked = 'totalValueLockedUSDUntracked',
  LiquidityProviderCount = 'liquidityProviderCount',
  PoolHourData = 'poolHourData',
  PoolDayData = 'poolDayData',
  Mints = 'mints',
  Burns = 'burns',
  Swaps = 'swaps',
  Collects = 'collects',
  Ticks = 'ticks'
}

export type Position = {
  __typename?: 'Position';
  id: Scalars['ID'];
  owner: Scalars['Bytes'];
  pool: Pool;
  token0: Token;
  token1: Token;
  tickLower: Tick;
  tickUpper: Tick;
  liquidity: Scalars['BigInt'];
  depositedToken0: Scalars['BigDecimal'];
  depositedToken1: Scalars['BigDecimal'];
  withdrawnToken0: Scalars['BigDecimal'];
  withdrawnToken1: Scalars['BigDecimal'];
  collectedFeesToken0: Scalars['BigDecimal'];
  collectedFeesToken1: Scalars['BigDecimal'];
  transaction: Transaction;
  feeGrowthInside0LastX128: Scalars['BigInt'];
  feeGrowthInside1LastX128: Scalars['BigInt'];
};

export type PositionSnapshot = {
  __typename?: 'PositionSnapshot';
  id: Scalars['ID'];
  owner: Scalars['Bytes'];
  pool: Pool;
  position: Position;
  blockNumber: Scalars['BigInt'];
  timestamp: Scalars['BigInt'];
  liquidity: Scalars['BigInt'];
  depositedToken0: Scalars['BigDecimal'];
  depositedToken1: Scalars['BigDecimal'];
  withdrawnToken0: Scalars['BigDecimal'];
  withdrawnToken1: Scalars['BigDecimal'];
  collectedFeesToken0: Scalars['BigDecimal'];
  collectedFeesToken1: Scalars['BigDecimal'];
  transaction: Transaction;
  feeGrowthInside0LastX128: Scalars['BigInt'];
  feeGrowthInside1LastX128: Scalars['BigInt'];
};

export type PositionSnapshot_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  owner?: Maybe<Scalars['Bytes']>;
  owner_not?: Maybe<Scalars['Bytes']>;
  owner_gt?: Maybe<Scalars['Bytes']>;
  owner_lt?: Maybe<Scalars['Bytes']>;
  owner_gte?: Maybe<Scalars['Bytes']>;
  owner_lte?: Maybe<Scalars['Bytes']>;
  owner_in?: Maybe<Array<Scalars['Bytes']>>;
  owner_not_in?: Maybe<Array<Scalars['Bytes']>>;
  owner_contains?: Maybe<Scalars['Bytes']>;
  owner_not_contains?: Maybe<Scalars['Bytes']>;
  pool?: Maybe<Scalars['String']>;
  pool_not?: Maybe<Scalars['String']>;
  pool_gt?: Maybe<Scalars['String']>;
  pool_lt?: Maybe<Scalars['String']>;
  pool_gte?: Maybe<Scalars['String']>;
  pool_lte?: Maybe<Scalars['String']>;
  pool_in?: Maybe<Array<Scalars['String']>>;
  pool_not_in?: Maybe<Array<Scalars['String']>>;
  pool_contains?: Maybe<Scalars['String']>;
  pool_contains_nocase?: Maybe<Scalars['String']>;
  pool_not_contains?: Maybe<Scalars['String']>;
  pool_not_contains_nocase?: Maybe<Scalars['String']>;
  pool_starts_with?: Maybe<Scalars['String']>;
  pool_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_not_starts_with?: Maybe<Scalars['String']>;
  pool_not_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_ends_with?: Maybe<Scalars['String']>;
  pool_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_not_ends_with?: Maybe<Scalars['String']>;
  pool_not_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_?: Maybe<Pool_Filter>;
  position?: Maybe<Scalars['String']>;
  position_not?: Maybe<Scalars['String']>;
  position_gt?: Maybe<Scalars['String']>;
  position_lt?: Maybe<Scalars['String']>;
  position_gte?: Maybe<Scalars['String']>;
  position_lte?: Maybe<Scalars['String']>;
  position_in?: Maybe<Array<Scalars['String']>>;
  position_not_in?: Maybe<Array<Scalars['String']>>;
  position_contains?: Maybe<Scalars['String']>;
  position_contains_nocase?: Maybe<Scalars['String']>;
  position_not_contains?: Maybe<Scalars['String']>;
  position_not_contains_nocase?: Maybe<Scalars['String']>;
  position_starts_with?: Maybe<Scalars['String']>;
  position_starts_with_nocase?: Maybe<Scalars['String']>;
  position_not_starts_with?: Maybe<Scalars['String']>;
  position_not_starts_with_nocase?: Maybe<Scalars['String']>;
  position_ends_with?: Maybe<Scalars['String']>;
  position_ends_with_nocase?: Maybe<Scalars['String']>;
  position_not_ends_with?: Maybe<Scalars['String']>;
  position_not_ends_with_nocase?: Maybe<Scalars['String']>;
  position_?: Maybe<Position_Filter>;
  blockNumber?: Maybe<Scalars['BigInt']>;
  blockNumber_not?: Maybe<Scalars['BigInt']>;
  blockNumber_gt?: Maybe<Scalars['BigInt']>;
  blockNumber_lt?: Maybe<Scalars['BigInt']>;
  blockNumber_gte?: Maybe<Scalars['BigInt']>;
  blockNumber_lte?: Maybe<Scalars['BigInt']>;
  blockNumber_in?: Maybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  liquidity?: Maybe<Scalars['BigInt']>;
  liquidity_not?: Maybe<Scalars['BigInt']>;
  liquidity_gt?: Maybe<Scalars['BigInt']>;
  liquidity_lt?: Maybe<Scalars['BigInt']>;
  liquidity_gte?: Maybe<Scalars['BigInt']>;
  liquidity_lte?: Maybe<Scalars['BigInt']>;
  liquidity_in?: Maybe<Array<Scalars['BigInt']>>;
  liquidity_not_in?: Maybe<Array<Scalars['BigInt']>>;
  depositedToken0?: Maybe<Scalars['BigDecimal']>;
  depositedToken0_not?: Maybe<Scalars['BigDecimal']>;
  depositedToken0_gt?: Maybe<Scalars['BigDecimal']>;
  depositedToken0_lt?: Maybe<Scalars['BigDecimal']>;
  depositedToken0_gte?: Maybe<Scalars['BigDecimal']>;
  depositedToken0_lte?: Maybe<Scalars['BigDecimal']>;
  depositedToken0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  depositedToken0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  depositedToken1?: Maybe<Scalars['BigDecimal']>;
  depositedToken1_not?: Maybe<Scalars['BigDecimal']>;
  depositedToken1_gt?: Maybe<Scalars['BigDecimal']>;
  depositedToken1_lt?: Maybe<Scalars['BigDecimal']>;
  depositedToken1_gte?: Maybe<Scalars['BigDecimal']>;
  depositedToken1_lte?: Maybe<Scalars['BigDecimal']>;
  depositedToken1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  depositedToken1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  withdrawnToken0?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken0_not?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken0_gt?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken0_lt?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken0_gte?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken0_lte?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  withdrawnToken0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  withdrawnToken1?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken1_not?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken1_gt?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken1_lt?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken1_gte?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken1_lte?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  withdrawnToken1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collectedFeesToken0?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_not?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_gt?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_lt?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_gte?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_lte?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collectedFeesToken0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collectedFeesToken1?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_not?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_gt?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_lt?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_gte?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_lte?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collectedFeesToken1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  transaction?: Maybe<Scalars['String']>;
  transaction_not?: Maybe<Scalars['String']>;
  transaction_gt?: Maybe<Scalars['String']>;
  transaction_lt?: Maybe<Scalars['String']>;
  transaction_gte?: Maybe<Scalars['String']>;
  transaction_lte?: Maybe<Scalars['String']>;
  transaction_in?: Maybe<Array<Scalars['String']>>;
  transaction_not_in?: Maybe<Array<Scalars['String']>>;
  transaction_contains?: Maybe<Scalars['String']>;
  transaction_contains_nocase?: Maybe<Scalars['String']>;
  transaction_not_contains?: Maybe<Scalars['String']>;
  transaction_not_contains_nocase?: Maybe<Scalars['String']>;
  transaction_starts_with?: Maybe<Scalars['String']>;
  transaction_starts_with_nocase?: Maybe<Scalars['String']>;
  transaction_not_starts_with?: Maybe<Scalars['String']>;
  transaction_not_starts_with_nocase?: Maybe<Scalars['String']>;
  transaction_ends_with?: Maybe<Scalars['String']>;
  transaction_ends_with_nocase?: Maybe<Scalars['String']>;
  transaction_not_ends_with?: Maybe<Scalars['String']>;
  transaction_not_ends_with_nocase?: Maybe<Scalars['String']>;
  transaction_?: Maybe<Transaction_Filter>;
  feeGrowthInside0LastX128?: Maybe<Scalars['BigInt']>;
  feeGrowthInside0LastX128_not?: Maybe<Scalars['BigInt']>;
  feeGrowthInside0LastX128_gt?: Maybe<Scalars['BigInt']>;
  feeGrowthInside0LastX128_lt?: Maybe<Scalars['BigInt']>;
  feeGrowthInside0LastX128_gte?: Maybe<Scalars['BigInt']>;
  feeGrowthInside0LastX128_lte?: Maybe<Scalars['BigInt']>;
  feeGrowthInside0LastX128_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthInside0LastX128_not_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthInside1LastX128?: Maybe<Scalars['BigInt']>;
  feeGrowthInside1LastX128_not?: Maybe<Scalars['BigInt']>;
  feeGrowthInside1LastX128_gt?: Maybe<Scalars['BigInt']>;
  feeGrowthInside1LastX128_lt?: Maybe<Scalars['BigInt']>;
  feeGrowthInside1LastX128_gte?: Maybe<Scalars['BigInt']>;
  feeGrowthInside1LastX128_lte?: Maybe<Scalars['BigInt']>;
  feeGrowthInside1LastX128_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthInside1LastX128_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<PositionSnapshot_Filter>>>;
  or?: Maybe<Array<Maybe<PositionSnapshot_Filter>>>;
};

export enum PositionSnapshot_OrderBy {
  Id = 'id',
  Owner = 'owner',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolCreatedAtTimestamp = 'pool__createdAtTimestamp',
  PoolCreatedAtBlockNumber = 'pool__createdAtBlockNumber',
  PoolFeeTier = 'pool__feeTier',
  PoolLiquidity = 'pool__liquidity',
  PoolSqrtPrice = 'pool__sqrtPrice',
  PoolFeeGrowthGlobal0X128 = 'pool__feeGrowthGlobal0X128',
  PoolFeeGrowthGlobal1X128 = 'pool__feeGrowthGlobal1X128',
  PoolToken0Price = 'pool__token0Price',
  PoolToken1Price = 'pool__token1Price',
  PoolTick = 'pool__tick',
  PoolObservationIndex = 'pool__observationIndex',
  PoolVolumeToken0 = 'pool__volumeToken0',
  PoolVolumeToken1 = 'pool__volumeToken1',
  PoolVolumeUsd = 'pool__volumeUSD',
  PoolUntrackedVolumeUsd = 'pool__untrackedVolumeUSD',
  PoolFeesUsd = 'pool__feesUSD',
  PoolTxCount = 'pool__txCount',
  PoolCollectedFeesToken0 = 'pool__collectedFeesToken0',
  PoolCollectedFeesToken1 = 'pool__collectedFeesToken1',
  PoolCollectedFeesUsd = 'pool__collectedFeesUSD',
  PoolTotalValueLockedToken0 = 'pool__totalValueLockedToken0',
  PoolTotalValueLockedToken1 = 'pool__totalValueLockedToken1',
  PoolTotalValueLockedEth = 'pool__totalValueLockedETH',
  PoolTotalValueLockedUsd = 'pool__totalValueLockedUSD',
  PoolTotalValueLockedUsdUntracked = 'pool__totalValueLockedUSDUntracked',
  PoolLiquidityProviderCount = 'pool__liquidityProviderCount',
  Position = 'position',
  PositionId = 'position__id',
  PositionOwner = 'position__owner',
  PositionLiquidity = 'position__liquidity',
  PositionDepositedToken0 = 'position__depositedToken0',
  PositionDepositedToken1 = 'position__depositedToken1',
  PositionWithdrawnToken0 = 'position__withdrawnToken0',
  PositionWithdrawnToken1 = 'position__withdrawnToken1',
  PositionCollectedFeesToken0 = 'position__collectedFeesToken0',
  PositionCollectedFeesToken1 = 'position__collectedFeesToken1',
  PositionFeeGrowthInside0LastX128 = 'position__feeGrowthInside0LastX128',
  PositionFeeGrowthInside1LastX128 = 'position__feeGrowthInside1LastX128',
  BlockNumber = 'blockNumber',
  Timestamp = 'timestamp',
  Liquidity = 'liquidity',
  DepositedToken0 = 'depositedToken0',
  DepositedToken1 = 'depositedToken1',
  WithdrawnToken0 = 'withdrawnToken0',
  WithdrawnToken1 = 'withdrawnToken1',
  CollectedFeesToken0 = 'collectedFeesToken0',
  CollectedFeesToken1 = 'collectedFeesToken1',
  Transaction = 'transaction',
  TransactionId = 'transaction__id',
  TransactionBlockNumber = 'transaction__blockNumber',
  TransactionTimestamp = 'transaction__timestamp',
  TransactionGasUsed = 'transaction__gasUsed',
  TransactionGasPrice = 'transaction__gasPrice',
  FeeGrowthInside0LastX128 = 'feeGrowthInside0LastX128',
  FeeGrowthInside1LastX128 = 'feeGrowthInside1LastX128'
}

export type Position_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  owner?: Maybe<Scalars['Bytes']>;
  owner_not?: Maybe<Scalars['Bytes']>;
  owner_gt?: Maybe<Scalars['Bytes']>;
  owner_lt?: Maybe<Scalars['Bytes']>;
  owner_gte?: Maybe<Scalars['Bytes']>;
  owner_lte?: Maybe<Scalars['Bytes']>;
  owner_in?: Maybe<Array<Scalars['Bytes']>>;
  owner_not_in?: Maybe<Array<Scalars['Bytes']>>;
  owner_contains?: Maybe<Scalars['Bytes']>;
  owner_not_contains?: Maybe<Scalars['Bytes']>;
  pool?: Maybe<Scalars['String']>;
  pool_not?: Maybe<Scalars['String']>;
  pool_gt?: Maybe<Scalars['String']>;
  pool_lt?: Maybe<Scalars['String']>;
  pool_gte?: Maybe<Scalars['String']>;
  pool_lte?: Maybe<Scalars['String']>;
  pool_in?: Maybe<Array<Scalars['String']>>;
  pool_not_in?: Maybe<Array<Scalars['String']>>;
  pool_contains?: Maybe<Scalars['String']>;
  pool_contains_nocase?: Maybe<Scalars['String']>;
  pool_not_contains?: Maybe<Scalars['String']>;
  pool_not_contains_nocase?: Maybe<Scalars['String']>;
  pool_starts_with?: Maybe<Scalars['String']>;
  pool_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_not_starts_with?: Maybe<Scalars['String']>;
  pool_not_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_ends_with?: Maybe<Scalars['String']>;
  pool_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_not_ends_with?: Maybe<Scalars['String']>;
  pool_not_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_?: Maybe<Pool_Filter>;
  token0?: Maybe<Scalars['String']>;
  token0_not?: Maybe<Scalars['String']>;
  token0_gt?: Maybe<Scalars['String']>;
  token0_lt?: Maybe<Scalars['String']>;
  token0_gte?: Maybe<Scalars['String']>;
  token0_lte?: Maybe<Scalars['String']>;
  token0_in?: Maybe<Array<Scalars['String']>>;
  token0_not_in?: Maybe<Array<Scalars['String']>>;
  token0_contains?: Maybe<Scalars['String']>;
  token0_contains_nocase?: Maybe<Scalars['String']>;
  token0_not_contains?: Maybe<Scalars['String']>;
  token0_not_contains_nocase?: Maybe<Scalars['String']>;
  token0_starts_with?: Maybe<Scalars['String']>;
  token0_starts_with_nocase?: Maybe<Scalars['String']>;
  token0_not_starts_with?: Maybe<Scalars['String']>;
  token0_not_starts_with_nocase?: Maybe<Scalars['String']>;
  token0_ends_with?: Maybe<Scalars['String']>;
  token0_ends_with_nocase?: Maybe<Scalars['String']>;
  token0_not_ends_with?: Maybe<Scalars['String']>;
  token0_not_ends_with_nocase?: Maybe<Scalars['String']>;
  token0_?: Maybe<Token_Filter>;
  token1?: Maybe<Scalars['String']>;
  token1_not?: Maybe<Scalars['String']>;
  token1_gt?: Maybe<Scalars['String']>;
  token1_lt?: Maybe<Scalars['String']>;
  token1_gte?: Maybe<Scalars['String']>;
  token1_lte?: Maybe<Scalars['String']>;
  token1_in?: Maybe<Array<Scalars['String']>>;
  token1_not_in?: Maybe<Array<Scalars['String']>>;
  token1_contains?: Maybe<Scalars['String']>;
  token1_contains_nocase?: Maybe<Scalars['String']>;
  token1_not_contains?: Maybe<Scalars['String']>;
  token1_not_contains_nocase?: Maybe<Scalars['String']>;
  token1_starts_with?: Maybe<Scalars['String']>;
  token1_starts_with_nocase?: Maybe<Scalars['String']>;
  token1_not_starts_with?: Maybe<Scalars['String']>;
  token1_not_starts_with_nocase?: Maybe<Scalars['String']>;
  token1_ends_with?: Maybe<Scalars['String']>;
  token1_ends_with_nocase?: Maybe<Scalars['String']>;
  token1_not_ends_with?: Maybe<Scalars['String']>;
  token1_not_ends_with_nocase?: Maybe<Scalars['String']>;
  token1_?: Maybe<Token_Filter>;
  tickLower?: Maybe<Scalars['String']>;
  tickLower_not?: Maybe<Scalars['String']>;
  tickLower_gt?: Maybe<Scalars['String']>;
  tickLower_lt?: Maybe<Scalars['String']>;
  tickLower_gte?: Maybe<Scalars['String']>;
  tickLower_lte?: Maybe<Scalars['String']>;
  tickLower_in?: Maybe<Array<Scalars['String']>>;
  tickLower_not_in?: Maybe<Array<Scalars['String']>>;
  tickLower_contains?: Maybe<Scalars['String']>;
  tickLower_contains_nocase?: Maybe<Scalars['String']>;
  tickLower_not_contains?: Maybe<Scalars['String']>;
  tickLower_not_contains_nocase?: Maybe<Scalars['String']>;
  tickLower_starts_with?: Maybe<Scalars['String']>;
  tickLower_starts_with_nocase?: Maybe<Scalars['String']>;
  tickLower_not_starts_with?: Maybe<Scalars['String']>;
  tickLower_not_starts_with_nocase?: Maybe<Scalars['String']>;
  tickLower_ends_with?: Maybe<Scalars['String']>;
  tickLower_ends_with_nocase?: Maybe<Scalars['String']>;
  tickLower_not_ends_with?: Maybe<Scalars['String']>;
  tickLower_not_ends_with_nocase?: Maybe<Scalars['String']>;
  tickLower_?: Maybe<Tick_Filter>;
  tickUpper?: Maybe<Scalars['String']>;
  tickUpper_not?: Maybe<Scalars['String']>;
  tickUpper_gt?: Maybe<Scalars['String']>;
  tickUpper_lt?: Maybe<Scalars['String']>;
  tickUpper_gte?: Maybe<Scalars['String']>;
  tickUpper_lte?: Maybe<Scalars['String']>;
  tickUpper_in?: Maybe<Array<Scalars['String']>>;
  tickUpper_not_in?: Maybe<Array<Scalars['String']>>;
  tickUpper_contains?: Maybe<Scalars['String']>;
  tickUpper_contains_nocase?: Maybe<Scalars['String']>;
  tickUpper_not_contains?: Maybe<Scalars['String']>;
  tickUpper_not_contains_nocase?: Maybe<Scalars['String']>;
  tickUpper_starts_with?: Maybe<Scalars['String']>;
  tickUpper_starts_with_nocase?: Maybe<Scalars['String']>;
  tickUpper_not_starts_with?: Maybe<Scalars['String']>;
  tickUpper_not_starts_with_nocase?: Maybe<Scalars['String']>;
  tickUpper_ends_with?: Maybe<Scalars['String']>;
  tickUpper_ends_with_nocase?: Maybe<Scalars['String']>;
  tickUpper_not_ends_with?: Maybe<Scalars['String']>;
  tickUpper_not_ends_with_nocase?: Maybe<Scalars['String']>;
  tickUpper_?: Maybe<Tick_Filter>;
  liquidity?: Maybe<Scalars['BigInt']>;
  liquidity_not?: Maybe<Scalars['BigInt']>;
  liquidity_gt?: Maybe<Scalars['BigInt']>;
  liquidity_lt?: Maybe<Scalars['BigInt']>;
  liquidity_gte?: Maybe<Scalars['BigInt']>;
  liquidity_lte?: Maybe<Scalars['BigInt']>;
  liquidity_in?: Maybe<Array<Scalars['BigInt']>>;
  liquidity_not_in?: Maybe<Array<Scalars['BigInt']>>;
  depositedToken0?: Maybe<Scalars['BigDecimal']>;
  depositedToken0_not?: Maybe<Scalars['BigDecimal']>;
  depositedToken0_gt?: Maybe<Scalars['BigDecimal']>;
  depositedToken0_lt?: Maybe<Scalars['BigDecimal']>;
  depositedToken0_gte?: Maybe<Scalars['BigDecimal']>;
  depositedToken0_lte?: Maybe<Scalars['BigDecimal']>;
  depositedToken0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  depositedToken0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  depositedToken1?: Maybe<Scalars['BigDecimal']>;
  depositedToken1_not?: Maybe<Scalars['BigDecimal']>;
  depositedToken1_gt?: Maybe<Scalars['BigDecimal']>;
  depositedToken1_lt?: Maybe<Scalars['BigDecimal']>;
  depositedToken1_gte?: Maybe<Scalars['BigDecimal']>;
  depositedToken1_lte?: Maybe<Scalars['BigDecimal']>;
  depositedToken1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  depositedToken1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  withdrawnToken0?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken0_not?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken0_gt?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken0_lt?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken0_gte?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken0_lte?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  withdrawnToken0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  withdrawnToken1?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken1_not?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken1_gt?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken1_lt?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken1_gte?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken1_lte?: Maybe<Scalars['BigDecimal']>;
  withdrawnToken1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  withdrawnToken1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collectedFeesToken0?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_not?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_gt?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_lt?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_gte?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_lte?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collectedFeesToken0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collectedFeesToken1?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_not?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_gt?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_lt?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_gte?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_lte?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collectedFeesToken1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  transaction?: Maybe<Scalars['String']>;
  transaction_not?: Maybe<Scalars['String']>;
  transaction_gt?: Maybe<Scalars['String']>;
  transaction_lt?: Maybe<Scalars['String']>;
  transaction_gte?: Maybe<Scalars['String']>;
  transaction_lte?: Maybe<Scalars['String']>;
  transaction_in?: Maybe<Array<Scalars['String']>>;
  transaction_not_in?: Maybe<Array<Scalars['String']>>;
  transaction_contains?: Maybe<Scalars['String']>;
  transaction_contains_nocase?: Maybe<Scalars['String']>;
  transaction_not_contains?: Maybe<Scalars['String']>;
  transaction_not_contains_nocase?: Maybe<Scalars['String']>;
  transaction_starts_with?: Maybe<Scalars['String']>;
  transaction_starts_with_nocase?: Maybe<Scalars['String']>;
  transaction_not_starts_with?: Maybe<Scalars['String']>;
  transaction_not_starts_with_nocase?: Maybe<Scalars['String']>;
  transaction_ends_with?: Maybe<Scalars['String']>;
  transaction_ends_with_nocase?: Maybe<Scalars['String']>;
  transaction_not_ends_with?: Maybe<Scalars['String']>;
  transaction_not_ends_with_nocase?: Maybe<Scalars['String']>;
  transaction_?: Maybe<Transaction_Filter>;
  feeGrowthInside0LastX128?: Maybe<Scalars['BigInt']>;
  feeGrowthInside0LastX128_not?: Maybe<Scalars['BigInt']>;
  feeGrowthInside0LastX128_gt?: Maybe<Scalars['BigInt']>;
  feeGrowthInside0LastX128_lt?: Maybe<Scalars['BigInt']>;
  feeGrowthInside0LastX128_gte?: Maybe<Scalars['BigInt']>;
  feeGrowthInside0LastX128_lte?: Maybe<Scalars['BigInt']>;
  feeGrowthInside0LastX128_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthInside0LastX128_not_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthInside1LastX128?: Maybe<Scalars['BigInt']>;
  feeGrowthInside1LastX128_not?: Maybe<Scalars['BigInt']>;
  feeGrowthInside1LastX128_gt?: Maybe<Scalars['BigInt']>;
  feeGrowthInside1LastX128_lt?: Maybe<Scalars['BigInt']>;
  feeGrowthInside1LastX128_gte?: Maybe<Scalars['BigInt']>;
  feeGrowthInside1LastX128_lte?: Maybe<Scalars['BigInt']>;
  feeGrowthInside1LastX128_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthInside1LastX128_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Position_Filter>>>;
  or?: Maybe<Array<Maybe<Position_Filter>>>;
};

export enum Position_OrderBy {
  Id = 'id',
  Owner = 'owner',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolCreatedAtTimestamp = 'pool__createdAtTimestamp',
  PoolCreatedAtBlockNumber = 'pool__createdAtBlockNumber',
  PoolFeeTier = 'pool__feeTier',
  PoolLiquidity = 'pool__liquidity',
  PoolSqrtPrice = 'pool__sqrtPrice',
  PoolFeeGrowthGlobal0X128 = 'pool__feeGrowthGlobal0X128',
  PoolFeeGrowthGlobal1X128 = 'pool__feeGrowthGlobal1X128',
  PoolToken0Price = 'pool__token0Price',
  PoolToken1Price = 'pool__token1Price',
  PoolTick = 'pool__tick',
  PoolObservationIndex = 'pool__observationIndex',
  PoolVolumeToken0 = 'pool__volumeToken0',
  PoolVolumeToken1 = 'pool__volumeToken1',
  PoolVolumeUsd = 'pool__volumeUSD',
  PoolUntrackedVolumeUsd = 'pool__untrackedVolumeUSD',
  PoolFeesUsd = 'pool__feesUSD',
  PoolTxCount = 'pool__txCount',
  PoolCollectedFeesToken0 = 'pool__collectedFeesToken0',
  PoolCollectedFeesToken1 = 'pool__collectedFeesToken1',
  PoolCollectedFeesUsd = 'pool__collectedFeesUSD',
  PoolTotalValueLockedToken0 = 'pool__totalValueLockedToken0',
  PoolTotalValueLockedToken1 = 'pool__totalValueLockedToken1',
  PoolTotalValueLockedEth = 'pool__totalValueLockedETH',
  PoolTotalValueLockedUsd = 'pool__totalValueLockedUSD',
  PoolTotalValueLockedUsdUntracked = 'pool__totalValueLockedUSDUntracked',
  PoolLiquidityProviderCount = 'pool__liquidityProviderCount',
  Token0 = 'token0',
  Token0Id = 'token0__id',
  Token0Symbol = 'token0__symbol',
  Token0Name = 'token0__name',
  Token0Decimals = 'token0__decimals',
  Token0TotalSupply = 'token0__totalSupply',
  Token0Volume = 'token0__volume',
  Token0VolumeUsd = 'token0__volumeUSD',
  Token0UntrackedVolumeUsd = 'token0__untrackedVolumeUSD',
  Token0FeesUsd = 'token0__feesUSD',
  Token0TxCount = 'token0__txCount',
  Token0PoolCount = 'token0__poolCount',
  Token0TotalValueLocked = 'token0__totalValueLocked',
  Token0TotalValueLockedUsd = 'token0__totalValueLockedUSD',
  Token0TotalValueLockedUsdUntracked = 'token0__totalValueLockedUSDUntracked',
  Token0DerivedEth = 'token0__derivedETH',
  Token1 = 'token1',
  Token1Id = 'token1__id',
  Token1Symbol = 'token1__symbol',
  Token1Name = 'token1__name',
  Token1Decimals = 'token1__decimals',
  Token1TotalSupply = 'token1__totalSupply',
  Token1Volume = 'token1__volume',
  Token1VolumeUsd = 'token1__volumeUSD',
  Token1UntrackedVolumeUsd = 'token1__untrackedVolumeUSD',
  Token1FeesUsd = 'token1__feesUSD',
  Token1TxCount = 'token1__txCount',
  Token1PoolCount = 'token1__poolCount',
  Token1TotalValueLocked = 'token1__totalValueLocked',
  Token1TotalValueLockedUsd = 'token1__totalValueLockedUSD',
  Token1TotalValueLockedUsdUntracked = 'token1__totalValueLockedUSDUntracked',
  Token1DerivedEth = 'token1__derivedETH',
  TickLower = 'tickLower',
  TickLowerId = 'tickLower__id',
  TickLowerPoolAddress = 'tickLower__poolAddress',
  TickLowerTickIdx = 'tickLower__tickIdx',
  TickLowerLiquidityGross = 'tickLower__liquidityGross',
  TickLowerLiquidityNet = 'tickLower__liquidityNet',
  TickLowerPrice0 = 'tickLower__price0',
  TickLowerPrice1 = 'tickLower__price1',
  TickLowerVolumeToken0 = 'tickLower__volumeToken0',
  TickLowerVolumeToken1 = 'tickLower__volumeToken1',
  TickLowerVolumeUsd = 'tickLower__volumeUSD',
  TickLowerUntrackedVolumeUsd = 'tickLower__untrackedVolumeUSD',
  TickLowerFeesUsd = 'tickLower__feesUSD',
  TickLowerCollectedFeesToken0 = 'tickLower__collectedFeesToken0',
  TickLowerCollectedFeesToken1 = 'tickLower__collectedFeesToken1',
  TickLowerCollectedFeesUsd = 'tickLower__collectedFeesUSD',
  TickLowerCreatedAtTimestamp = 'tickLower__createdAtTimestamp',
  TickLowerCreatedAtBlockNumber = 'tickLower__createdAtBlockNumber',
  TickLowerLiquidityProviderCount = 'tickLower__liquidityProviderCount',
  TickLowerFeeGrowthOutside0X128 = 'tickLower__feeGrowthOutside0X128',
  TickLowerFeeGrowthOutside1X128 = 'tickLower__feeGrowthOutside1X128',
  TickUpper = 'tickUpper',
  TickUpperId = 'tickUpper__id',
  TickUpperPoolAddress = 'tickUpper__poolAddress',
  TickUpperTickIdx = 'tickUpper__tickIdx',
  TickUpperLiquidityGross = 'tickUpper__liquidityGross',
  TickUpperLiquidityNet = 'tickUpper__liquidityNet',
  TickUpperPrice0 = 'tickUpper__price0',
  TickUpperPrice1 = 'tickUpper__price1',
  TickUpperVolumeToken0 = 'tickUpper__volumeToken0',
  TickUpperVolumeToken1 = 'tickUpper__volumeToken1',
  TickUpperVolumeUsd = 'tickUpper__volumeUSD',
  TickUpperUntrackedVolumeUsd = 'tickUpper__untrackedVolumeUSD',
  TickUpperFeesUsd = 'tickUpper__feesUSD',
  TickUpperCollectedFeesToken0 = 'tickUpper__collectedFeesToken0',
  TickUpperCollectedFeesToken1 = 'tickUpper__collectedFeesToken1',
  TickUpperCollectedFeesUsd = 'tickUpper__collectedFeesUSD',
  TickUpperCreatedAtTimestamp = 'tickUpper__createdAtTimestamp',
  TickUpperCreatedAtBlockNumber = 'tickUpper__createdAtBlockNumber',
  TickUpperLiquidityProviderCount = 'tickUpper__liquidityProviderCount',
  TickUpperFeeGrowthOutside0X128 = 'tickUpper__feeGrowthOutside0X128',
  TickUpperFeeGrowthOutside1X128 = 'tickUpper__feeGrowthOutside1X128',
  Liquidity = 'liquidity',
  DepositedToken0 = 'depositedToken0',
  DepositedToken1 = 'depositedToken1',
  WithdrawnToken0 = 'withdrawnToken0',
  WithdrawnToken1 = 'withdrawnToken1',
  CollectedFeesToken0 = 'collectedFeesToken0',
  CollectedFeesToken1 = 'collectedFeesToken1',
  Transaction = 'transaction',
  TransactionId = 'transaction__id',
  TransactionBlockNumber = 'transaction__blockNumber',
  TransactionTimestamp = 'transaction__timestamp',
  TransactionGasUsed = 'transaction__gasUsed',
  TransactionGasPrice = 'transaction__gasPrice',
  FeeGrowthInside0LastX128 = 'feeGrowthInside0LastX128',
  FeeGrowthInside1LastX128 = 'feeGrowthInside1LastX128'
}

export type Query = {
  __typename?: 'Query';
  factory?: Maybe<Factory>;
  factories: Array<Factory>;
  bundle?: Maybe<Bundle>;
  bundles: Array<Bundle>;
  token?: Maybe<Token>;
  tokens: Array<Token>;
  pool?: Maybe<Pool>;
  pools: Array<Pool>;
  tick?: Maybe<Tick>;
  ticks: Array<Tick>;
  position?: Maybe<Position>;
  positions: Array<Position>;
  positionSnapshot?: Maybe<PositionSnapshot>;
  positionSnapshots: Array<PositionSnapshot>;
  transaction?: Maybe<Transaction>;
  transactions: Array<Transaction>;
  mint?: Maybe<Mint>;
  mints: Array<Mint>;
  burn?: Maybe<Burn>;
  burns: Array<Burn>;
  swap?: Maybe<Swap>;
  swaps: Array<Swap>;
  collect?: Maybe<Collect>;
  collects: Array<Collect>;
  flash?: Maybe<Flash>;
  flashes: Array<Flash>;
  uniswapDayData?: Maybe<UniswapDayData>;
  uniswapDayDatas: Array<UniswapDayData>;
  poolDayData?: Maybe<PoolDayData>;
  poolDayDatas: Array<PoolDayData>;
  poolHourData?: Maybe<PoolHourData>;
  poolHourDatas: Array<PoolHourData>;
  tickHourData?: Maybe<TickHourData>;
  tickHourDatas: Array<TickHourData>;
  tickDayData?: Maybe<TickDayData>;
  tickDayDatas: Array<TickDayData>;
  tokenDayData?: Maybe<TokenDayData>;
  tokenDayDatas: Array<TokenDayData>;
  tokenHourData?: Maybe<TokenHourData>;
  tokenHourDatas: Array<TokenHourData>;
  yieldAggregator?: Maybe<YieldAggregator>;
  yieldAggregators: Array<YieldAggregator>;
  kodiakUsageMetricsDailySnapshot?: Maybe<KodiakUsageMetricsDailySnapshot>;
  kodiakUsageMetricsDailySnapshots: Array<KodiakUsageMetricsDailySnapshot>;
  kodiakUsageMetricsHourlySnapshot?: Maybe<KodiakUsageMetricsHourlySnapshot>;
  kodiakUsageMetricsHourlySnapshots: Array<KodiakUsageMetricsHourlySnapshot>;
  kodiakFinancialsDailySnapshot?: Maybe<KodiakFinancialsDailySnapshot>;
  kodiakFinancialsDailySnapshots: Array<KodiakFinancialsDailySnapshot>;
  kodiakVault?: Maybe<KodiakVault>;
  kodiakVaults: Array<KodiakVault>;
  kodiakAprAccumulated?: Maybe<KodiakAprAccumulated>;
  kodiakAprAccumulateds: Array<KodiakAprAccumulated>;
  kodiakRewardToken?: Maybe<KodiakRewardToken>;
  kodiakRewardTokens: Array<KodiakRewardToken>;
  kodiakVaultFee?: Maybe<KodiakVaultFee>;
  kodiakVaultFees: Array<KodiakVaultFee>;
  kodiakStrategyChange?: Maybe<KodiakStrategyChange>;
  kodiakStrategyChanges: Array<KodiakStrategyChange>;
  kodiakVaultDailySnapshot?: Maybe<KodiakVaultDailySnapshot>;
  kodiakVaultDailySnapshots: Array<KodiakVaultDailySnapshot>;
  kodiakVaultHourlySnapshot?: Maybe<KodiakVaultHourlySnapshot>;
  kodiakVaultHourlySnapshots: Array<KodiakVaultHourlySnapshot>;
  kodiakDeposit?: Maybe<KodiakDeposit>;
  kodiakDeposits: Array<KodiakDeposit>;
  kodiakWithdraw?: Maybe<KodiakWithdraw>;
  kodiakWithdraws: Array<KodiakWithdraw>;
  kodiakApr?: Maybe<KodiakApr>;
  kodiakAprs: Array<KodiakApr>;
  account?: Maybe<Account>;
  accounts: Array<Account>;
  activeAccount?: Maybe<ActiveAccount>;
  activeAccounts: Array<ActiveAccount>;
  kodiakProtocol?: Maybe<KodiakProtocol>;
  kodiakProtocols: Array<KodiakProtocol>;
  event?: Maybe<Event>;
  events: Array<Event>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};


export type QueryFactoryArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFactoriesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Factory_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Factory_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryBundleArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryBundlesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Bundle_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Bundle_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokenArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokensArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Token_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Token_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Pool_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Pool_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTickArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTicksArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Tick_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Tick_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPositionArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPositionsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Position_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Position_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPositionSnapshotArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPositionSnapshotsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PositionSnapshot_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<PositionSnapshot_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTransactionArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTransactionsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Transaction_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Transaction_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryMintArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryMintsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Mint_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Mint_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryBurnArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryBurnsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Burn_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Burn_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySwapArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySwapsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Swap_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Swap_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCollectArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCollectsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Collect_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Collect_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFlashArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFlashesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Flash_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Flash_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryUniswapDayDataArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryUniswapDayDatasArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<UniswapDayData_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<UniswapDayData_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolDayDataArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolDayDatasArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PoolDayData_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<PoolDayData_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolHourDataArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolHourDatasArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PoolHourData_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<PoolHourData_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTickHourDataArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTickHourDatasArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TickHourData_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<TickHourData_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTickDayDataArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTickDayDatasArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TickDayData_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<TickDayData_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokenDayDataArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokenDayDatasArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TokenDayData_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<TokenDayData_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokenHourDataArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokenHourDatasArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TokenHourData_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<TokenHourData_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryYieldAggregatorArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryYieldAggregatorsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<YieldAggregator_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<YieldAggregator_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakUsageMetricsDailySnapshotArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakUsageMetricsDailySnapshotsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakUsageMetricsDailySnapshot_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakUsageMetricsDailySnapshot_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakUsageMetricsHourlySnapshotArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakUsageMetricsHourlySnapshotsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakUsageMetricsHourlySnapshot_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakUsageMetricsHourlySnapshot_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakFinancialsDailySnapshotArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakFinancialsDailySnapshotsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakFinancialsDailySnapshot_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakFinancialsDailySnapshot_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakVaultArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakVaultsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakVault_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakVault_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakAprAccumulatedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakAprAccumulatedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakAprAccumulated_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakAprAccumulated_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakRewardTokenArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakRewardTokensArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakRewardToken_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakRewardToken_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakVaultFeeArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakVaultFeesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakVaultFee_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakVaultFee_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakStrategyChangeArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakStrategyChangesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakStrategyChange_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakStrategyChange_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakVaultDailySnapshotArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakVaultDailySnapshotsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakVaultDailySnapshot_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakVaultDailySnapshot_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakVaultHourlySnapshotArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakVaultHourlySnapshotsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakVaultHourlySnapshot_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakVaultHourlySnapshot_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakDepositArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakDepositsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakDeposit_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakDeposit_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakWithdrawArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakWithdrawsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakWithdraw_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakWithdraw_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakAprArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakAprsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakApr_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakApr_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAccountArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAccountsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Account_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Account_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryActiveAccountArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryActiveAccountsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ActiveAccount_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ActiveAccount_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakProtocolArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryKodiakProtocolsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakProtocol_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakProtocol_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryEventArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryEventsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Event_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Event_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Query_MetaArgs = {
  block?: Maybe<Block_Height>;
};

export type Subscription = {
  __typename?: 'Subscription';
  factory?: Maybe<Factory>;
  factories: Array<Factory>;
  bundle?: Maybe<Bundle>;
  bundles: Array<Bundle>;
  token?: Maybe<Token>;
  tokens: Array<Token>;
  pool?: Maybe<Pool>;
  pools: Array<Pool>;
  tick?: Maybe<Tick>;
  ticks: Array<Tick>;
  position?: Maybe<Position>;
  positions: Array<Position>;
  positionSnapshot?: Maybe<PositionSnapshot>;
  positionSnapshots: Array<PositionSnapshot>;
  transaction?: Maybe<Transaction>;
  transactions: Array<Transaction>;
  mint?: Maybe<Mint>;
  mints: Array<Mint>;
  burn?: Maybe<Burn>;
  burns: Array<Burn>;
  swap?: Maybe<Swap>;
  swaps: Array<Swap>;
  collect?: Maybe<Collect>;
  collects: Array<Collect>;
  flash?: Maybe<Flash>;
  flashes: Array<Flash>;
  uniswapDayData?: Maybe<UniswapDayData>;
  uniswapDayDatas: Array<UniswapDayData>;
  poolDayData?: Maybe<PoolDayData>;
  poolDayDatas: Array<PoolDayData>;
  poolHourData?: Maybe<PoolHourData>;
  poolHourDatas: Array<PoolHourData>;
  tickHourData?: Maybe<TickHourData>;
  tickHourDatas: Array<TickHourData>;
  tickDayData?: Maybe<TickDayData>;
  tickDayDatas: Array<TickDayData>;
  tokenDayData?: Maybe<TokenDayData>;
  tokenDayDatas: Array<TokenDayData>;
  tokenHourData?: Maybe<TokenHourData>;
  tokenHourDatas: Array<TokenHourData>;
  yieldAggregator?: Maybe<YieldAggregator>;
  yieldAggregators: Array<YieldAggregator>;
  kodiakUsageMetricsDailySnapshot?: Maybe<KodiakUsageMetricsDailySnapshot>;
  kodiakUsageMetricsDailySnapshots: Array<KodiakUsageMetricsDailySnapshot>;
  kodiakUsageMetricsHourlySnapshot?: Maybe<KodiakUsageMetricsHourlySnapshot>;
  kodiakUsageMetricsHourlySnapshots: Array<KodiakUsageMetricsHourlySnapshot>;
  kodiakFinancialsDailySnapshot?: Maybe<KodiakFinancialsDailySnapshot>;
  kodiakFinancialsDailySnapshots: Array<KodiakFinancialsDailySnapshot>;
  kodiakVault?: Maybe<KodiakVault>;
  kodiakVaults: Array<KodiakVault>;
  kodiakAprAccumulated?: Maybe<KodiakAprAccumulated>;
  kodiakAprAccumulateds: Array<KodiakAprAccumulated>;
  kodiakRewardToken?: Maybe<KodiakRewardToken>;
  kodiakRewardTokens: Array<KodiakRewardToken>;
  kodiakVaultFee?: Maybe<KodiakVaultFee>;
  kodiakVaultFees: Array<KodiakVaultFee>;
  kodiakStrategyChange?: Maybe<KodiakStrategyChange>;
  kodiakStrategyChanges: Array<KodiakStrategyChange>;
  kodiakVaultDailySnapshot?: Maybe<KodiakVaultDailySnapshot>;
  kodiakVaultDailySnapshots: Array<KodiakVaultDailySnapshot>;
  kodiakVaultHourlySnapshot?: Maybe<KodiakVaultHourlySnapshot>;
  kodiakVaultHourlySnapshots: Array<KodiakVaultHourlySnapshot>;
  kodiakDeposit?: Maybe<KodiakDeposit>;
  kodiakDeposits: Array<KodiakDeposit>;
  kodiakWithdraw?: Maybe<KodiakWithdraw>;
  kodiakWithdraws: Array<KodiakWithdraw>;
  kodiakApr?: Maybe<KodiakApr>;
  kodiakAprs: Array<KodiakApr>;
  account?: Maybe<Account>;
  accounts: Array<Account>;
  activeAccount?: Maybe<ActiveAccount>;
  activeAccounts: Array<ActiveAccount>;
  kodiakProtocol?: Maybe<KodiakProtocol>;
  kodiakProtocols: Array<KodiakProtocol>;
  event?: Maybe<Event>;
  events: Array<Event>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};


export type SubscriptionFactoryArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFactoriesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Factory_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Factory_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionBundleArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionBundlesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Bundle_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Bundle_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokenArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokensArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Token_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Token_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Pool_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Pool_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTickArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTicksArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Tick_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Tick_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPositionArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPositionsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Position_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Position_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPositionSnapshotArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPositionSnapshotsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PositionSnapshot_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<PositionSnapshot_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTransactionArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTransactionsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Transaction_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Transaction_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionMintArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionMintsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Mint_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Mint_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionBurnArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionBurnsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Burn_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Burn_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSwapArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSwapsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Swap_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Swap_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCollectArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCollectsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Collect_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Collect_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFlashArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFlashesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Flash_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Flash_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionUniswapDayDataArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionUniswapDayDatasArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<UniswapDayData_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<UniswapDayData_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolDayDataArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolDayDatasArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PoolDayData_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<PoolDayData_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolHourDataArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolHourDatasArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PoolHourData_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<PoolHourData_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTickHourDataArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTickHourDatasArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TickHourData_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<TickHourData_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTickDayDataArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTickDayDatasArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TickDayData_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<TickDayData_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokenDayDataArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokenDayDatasArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TokenDayData_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<TokenDayData_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokenHourDataArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokenHourDatasArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TokenHourData_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<TokenHourData_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionYieldAggregatorArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionYieldAggregatorsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<YieldAggregator_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<YieldAggregator_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakUsageMetricsDailySnapshotArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakUsageMetricsDailySnapshotsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakUsageMetricsDailySnapshot_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakUsageMetricsDailySnapshot_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakUsageMetricsHourlySnapshotArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakUsageMetricsHourlySnapshotsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakUsageMetricsHourlySnapshot_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakUsageMetricsHourlySnapshot_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakFinancialsDailySnapshotArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakFinancialsDailySnapshotsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakFinancialsDailySnapshot_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakFinancialsDailySnapshot_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakVaultArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakVaultsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakVault_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakVault_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakAprAccumulatedArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakAprAccumulatedsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakAprAccumulated_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakAprAccumulated_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakRewardTokenArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakRewardTokensArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakRewardToken_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakRewardToken_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakVaultFeeArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakVaultFeesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakVaultFee_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakVaultFee_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakStrategyChangeArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakStrategyChangesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakStrategyChange_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakStrategyChange_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakVaultDailySnapshotArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakVaultDailySnapshotsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakVaultDailySnapshot_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakVaultDailySnapshot_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakVaultHourlySnapshotArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakVaultHourlySnapshotsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakVaultHourlySnapshot_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakVaultHourlySnapshot_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakDepositArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakDepositsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakDeposit_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakDeposit_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakWithdrawArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakWithdrawsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakWithdraw_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakWithdraw_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakAprArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakAprsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakApr_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakApr_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAccountArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAccountsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Account_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Account_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionActiveAccountArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionActiveAccountsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ActiveAccount_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ActiveAccount_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakProtocolArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionKodiakProtocolsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakProtocol_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakProtocol_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionEventArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionEventsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Event_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Event_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscription_MetaArgs = {
  block?: Maybe<Block_Height>;
};

export type Swap = {
  __typename?: 'Swap';
  id: Scalars['ID'];
  transaction: Transaction;
  timestamp: Scalars['BigInt'];
  pool: Pool;
  token0: Token;
  token1: Token;
  sender: Scalars['Bytes'];
  recipient: Scalars['Bytes'];
  origin: Scalars['Bytes'];
  amount0: Scalars['BigDecimal'];
  amount1: Scalars['BigDecimal'];
  amountUSD: Scalars['BigDecimal'];
  sqrtPriceX96: Scalars['BigInt'];
  tick: Scalars['BigInt'];
  logIndex?: Maybe<Scalars['BigInt']>;
};

export type Swap_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  transaction?: Maybe<Scalars['String']>;
  transaction_not?: Maybe<Scalars['String']>;
  transaction_gt?: Maybe<Scalars['String']>;
  transaction_lt?: Maybe<Scalars['String']>;
  transaction_gte?: Maybe<Scalars['String']>;
  transaction_lte?: Maybe<Scalars['String']>;
  transaction_in?: Maybe<Array<Scalars['String']>>;
  transaction_not_in?: Maybe<Array<Scalars['String']>>;
  transaction_contains?: Maybe<Scalars['String']>;
  transaction_contains_nocase?: Maybe<Scalars['String']>;
  transaction_not_contains?: Maybe<Scalars['String']>;
  transaction_not_contains_nocase?: Maybe<Scalars['String']>;
  transaction_starts_with?: Maybe<Scalars['String']>;
  transaction_starts_with_nocase?: Maybe<Scalars['String']>;
  transaction_not_starts_with?: Maybe<Scalars['String']>;
  transaction_not_starts_with_nocase?: Maybe<Scalars['String']>;
  transaction_ends_with?: Maybe<Scalars['String']>;
  transaction_ends_with_nocase?: Maybe<Scalars['String']>;
  transaction_not_ends_with?: Maybe<Scalars['String']>;
  transaction_not_ends_with_nocase?: Maybe<Scalars['String']>;
  transaction_?: Maybe<Transaction_Filter>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  pool?: Maybe<Scalars['String']>;
  pool_not?: Maybe<Scalars['String']>;
  pool_gt?: Maybe<Scalars['String']>;
  pool_lt?: Maybe<Scalars['String']>;
  pool_gte?: Maybe<Scalars['String']>;
  pool_lte?: Maybe<Scalars['String']>;
  pool_in?: Maybe<Array<Scalars['String']>>;
  pool_not_in?: Maybe<Array<Scalars['String']>>;
  pool_contains?: Maybe<Scalars['String']>;
  pool_contains_nocase?: Maybe<Scalars['String']>;
  pool_not_contains?: Maybe<Scalars['String']>;
  pool_not_contains_nocase?: Maybe<Scalars['String']>;
  pool_starts_with?: Maybe<Scalars['String']>;
  pool_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_not_starts_with?: Maybe<Scalars['String']>;
  pool_not_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_ends_with?: Maybe<Scalars['String']>;
  pool_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_not_ends_with?: Maybe<Scalars['String']>;
  pool_not_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_?: Maybe<Pool_Filter>;
  token0?: Maybe<Scalars['String']>;
  token0_not?: Maybe<Scalars['String']>;
  token0_gt?: Maybe<Scalars['String']>;
  token0_lt?: Maybe<Scalars['String']>;
  token0_gte?: Maybe<Scalars['String']>;
  token0_lte?: Maybe<Scalars['String']>;
  token0_in?: Maybe<Array<Scalars['String']>>;
  token0_not_in?: Maybe<Array<Scalars['String']>>;
  token0_contains?: Maybe<Scalars['String']>;
  token0_contains_nocase?: Maybe<Scalars['String']>;
  token0_not_contains?: Maybe<Scalars['String']>;
  token0_not_contains_nocase?: Maybe<Scalars['String']>;
  token0_starts_with?: Maybe<Scalars['String']>;
  token0_starts_with_nocase?: Maybe<Scalars['String']>;
  token0_not_starts_with?: Maybe<Scalars['String']>;
  token0_not_starts_with_nocase?: Maybe<Scalars['String']>;
  token0_ends_with?: Maybe<Scalars['String']>;
  token0_ends_with_nocase?: Maybe<Scalars['String']>;
  token0_not_ends_with?: Maybe<Scalars['String']>;
  token0_not_ends_with_nocase?: Maybe<Scalars['String']>;
  token0_?: Maybe<Token_Filter>;
  token1?: Maybe<Scalars['String']>;
  token1_not?: Maybe<Scalars['String']>;
  token1_gt?: Maybe<Scalars['String']>;
  token1_lt?: Maybe<Scalars['String']>;
  token1_gte?: Maybe<Scalars['String']>;
  token1_lte?: Maybe<Scalars['String']>;
  token1_in?: Maybe<Array<Scalars['String']>>;
  token1_not_in?: Maybe<Array<Scalars['String']>>;
  token1_contains?: Maybe<Scalars['String']>;
  token1_contains_nocase?: Maybe<Scalars['String']>;
  token1_not_contains?: Maybe<Scalars['String']>;
  token1_not_contains_nocase?: Maybe<Scalars['String']>;
  token1_starts_with?: Maybe<Scalars['String']>;
  token1_starts_with_nocase?: Maybe<Scalars['String']>;
  token1_not_starts_with?: Maybe<Scalars['String']>;
  token1_not_starts_with_nocase?: Maybe<Scalars['String']>;
  token1_ends_with?: Maybe<Scalars['String']>;
  token1_ends_with_nocase?: Maybe<Scalars['String']>;
  token1_not_ends_with?: Maybe<Scalars['String']>;
  token1_not_ends_with_nocase?: Maybe<Scalars['String']>;
  token1_?: Maybe<Token_Filter>;
  sender?: Maybe<Scalars['Bytes']>;
  sender_not?: Maybe<Scalars['Bytes']>;
  sender_gt?: Maybe<Scalars['Bytes']>;
  sender_lt?: Maybe<Scalars['Bytes']>;
  sender_gte?: Maybe<Scalars['Bytes']>;
  sender_lte?: Maybe<Scalars['Bytes']>;
  sender_in?: Maybe<Array<Scalars['Bytes']>>;
  sender_not_in?: Maybe<Array<Scalars['Bytes']>>;
  sender_contains?: Maybe<Scalars['Bytes']>;
  sender_not_contains?: Maybe<Scalars['Bytes']>;
  recipient?: Maybe<Scalars['Bytes']>;
  recipient_not?: Maybe<Scalars['Bytes']>;
  recipient_gt?: Maybe<Scalars['Bytes']>;
  recipient_lt?: Maybe<Scalars['Bytes']>;
  recipient_gte?: Maybe<Scalars['Bytes']>;
  recipient_lte?: Maybe<Scalars['Bytes']>;
  recipient_in?: Maybe<Array<Scalars['Bytes']>>;
  recipient_not_in?: Maybe<Array<Scalars['Bytes']>>;
  recipient_contains?: Maybe<Scalars['Bytes']>;
  recipient_not_contains?: Maybe<Scalars['Bytes']>;
  origin?: Maybe<Scalars['Bytes']>;
  origin_not?: Maybe<Scalars['Bytes']>;
  origin_gt?: Maybe<Scalars['Bytes']>;
  origin_lt?: Maybe<Scalars['Bytes']>;
  origin_gte?: Maybe<Scalars['Bytes']>;
  origin_lte?: Maybe<Scalars['Bytes']>;
  origin_in?: Maybe<Array<Scalars['Bytes']>>;
  origin_not_in?: Maybe<Array<Scalars['Bytes']>>;
  origin_contains?: Maybe<Scalars['Bytes']>;
  origin_not_contains?: Maybe<Scalars['Bytes']>;
  amount0?: Maybe<Scalars['BigDecimal']>;
  amount0_not?: Maybe<Scalars['BigDecimal']>;
  amount0_gt?: Maybe<Scalars['BigDecimal']>;
  amount0_lt?: Maybe<Scalars['BigDecimal']>;
  amount0_gte?: Maybe<Scalars['BigDecimal']>;
  amount0_lte?: Maybe<Scalars['BigDecimal']>;
  amount0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amount0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amount1?: Maybe<Scalars['BigDecimal']>;
  amount1_not?: Maybe<Scalars['BigDecimal']>;
  amount1_gt?: Maybe<Scalars['BigDecimal']>;
  amount1_lt?: Maybe<Scalars['BigDecimal']>;
  amount1_gte?: Maybe<Scalars['BigDecimal']>;
  amount1_lte?: Maybe<Scalars['BigDecimal']>;
  amount1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amount1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amountUSD?: Maybe<Scalars['BigDecimal']>;
  amountUSD_not?: Maybe<Scalars['BigDecimal']>;
  amountUSD_gt?: Maybe<Scalars['BigDecimal']>;
  amountUSD_lt?: Maybe<Scalars['BigDecimal']>;
  amountUSD_gte?: Maybe<Scalars['BigDecimal']>;
  amountUSD_lte?: Maybe<Scalars['BigDecimal']>;
  amountUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  amountUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  sqrtPriceX96?: Maybe<Scalars['BigInt']>;
  sqrtPriceX96_not?: Maybe<Scalars['BigInt']>;
  sqrtPriceX96_gt?: Maybe<Scalars['BigInt']>;
  sqrtPriceX96_lt?: Maybe<Scalars['BigInt']>;
  sqrtPriceX96_gte?: Maybe<Scalars['BigInt']>;
  sqrtPriceX96_lte?: Maybe<Scalars['BigInt']>;
  sqrtPriceX96_in?: Maybe<Array<Scalars['BigInt']>>;
  sqrtPriceX96_not_in?: Maybe<Array<Scalars['BigInt']>>;
  tick?: Maybe<Scalars['BigInt']>;
  tick_not?: Maybe<Scalars['BigInt']>;
  tick_gt?: Maybe<Scalars['BigInt']>;
  tick_lt?: Maybe<Scalars['BigInt']>;
  tick_gte?: Maybe<Scalars['BigInt']>;
  tick_lte?: Maybe<Scalars['BigInt']>;
  tick_in?: Maybe<Array<Scalars['BigInt']>>;
  tick_not_in?: Maybe<Array<Scalars['BigInt']>>;
  logIndex?: Maybe<Scalars['BigInt']>;
  logIndex_not?: Maybe<Scalars['BigInt']>;
  logIndex_gt?: Maybe<Scalars['BigInt']>;
  logIndex_lt?: Maybe<Scalars['BigInt']>;
  logIndex_gte?: Maybe<Scalars['BigInt']>;
  logIndex_lte?: Maybe<Scalars['BigInt']>;
  logIndex_in?: Maybe<Array<Scalars['BigInt']>>;
  logIndex_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Swap_Filter>>>;
  or?: Maybe<Array<Maybe<Swap_Filter>>>;
};

export enum Swap_OrderBy {
  Id = 'id',
  Transaction = 'transaction',
  TransactionId = 'transaction__id',
  TransactionBlockNumber = 'transaction__blockNumber',
  TransactionTimestamp = 'transaction__timestamp',
  TransactionGasUsed = 'transaction__gasUsed',
  TransactionGasPrice = 'transaction__gasPrice',
  Timestamp = 'timestamp',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolCreatedAtTimestamp = 'pool__createdAtTimestamp',
  PoolCreatedAtBlockNumber = 'pool__createdAtBlockNumber',
  PoolFeeTier = 'pool__feeTier',
  PoolLiquidity = 'pool__liquidity',
  PoolSqrtPrice = 'pool__sqrtPrice',
  PoolFeeGrowthGlobal0X128 = 'pool__feeGrowthGlobal0X128',
  PoolFeeGrowthGlobal1X128 = 'pool__feeGrowthGlobal1X128',
  PoolToken0Price = 'pool__token0Price',
  PoolToken1Price = 'pool__token1Price',
  PoolTick = 'pool__tick',
  PoolObservationIndex = 'pool__observationIndex',
  PoolVolumeToken0 = 'pool__volumeToken0',
  PoolVolumeToken1 = 'pool__volumeToken1',
  PoolVolumeUsd = 'pool__volumeUSD',
  PoolUntrackedVolumeUsd = 'pool__untrackedVolumeUSD',
  PoolFeesUsd = 'pool__feesUSD',
  PoolTxCount = 'pool__txCount',
  PoolCollectedFeesToken0 = 'pool__collectedFeesToken0',
  PoolCollectedFeesToken1 = 'pool__collectedFeesToken1',
  PoolCollectedFeesUsd = 'pool__collectedFeesUSD',
  PoolTotalValueLockedToken0 = 'pool__totalValueLockedToken0',
  PoolTotalValueLockedToken1 = 'pool__totalValueLockedToken1',
  PoolTotalValueLockedEth = 'pool__totalValueLockedETH',
  PoolTotalValueLockedUsd = 'pool__totalValueLockedUSD',
  PoolTotalValueLockedUsdUntracked = 'pool__totalValueLockedUSDUntracked',
  PoolLiquidityProviderCount = 'pool__liquidityProviderCount',
  Token0 = 'token0',
  Token0Id = 'token0__id',
  Token0Symbol = 'token0__symbol',
  Token0Name = 'token0__name',
  Token0Decimals = 'token0__decimals',
  Token0TotalSupply = 'token0__totalSupply',
  Token0Volume = 'token0__volume',
  Token0VolumeUsd = 'token0__volumeUSD',
  Token0UntrackedVolumeUsd = 'token0__untrackedVolumeUSD',
  Token0FeesUsd = 'token0__feesUSD',
  Token0TxCount = 'token0__txCount',
  Token0PoolCount = 'token0__poolCount',
  Token0TotalValueLocked = 'token0__totalValueLocked',
  Token0TotalValueLockedUsd = 'token0__totalValueLockedUSD',
  Token0TotalValueLockedUsdUntracked = 'token0__totalValueLockedUSDUntracked',
  Token0DerivedEth = 'token0__derivedETH',
  Token1 = 'token1',
  Token1Id = 'token1__id',
  Token1Symbol = 'token1__symbol',
  Token1Name = 'token1__name',
  Token1Decimals = 'token1__decimals',
  Token1TotalSupply = 'token1__totalSupply',
  Token1Volume = 'token1__volume',
  Token1VolumeUsd = 'token1__volumeUSD',
  Token1UntrackedVolumeUsd = 'token1__untrackedVolumeUSD',
  Token1FeesUsd = 'token1__feesUSD',
  Token1TxCount = 'token1__txCount',
  Token1PoolCount = 'token1__poolCount',
  Token1TotalValueLocked = 'token1__totalValueLocked',
  Token1TotalValueLockedUsd = 'token1__totalValueLockedUSD',
  Token1TotalValueLockedUsdUntracked = 'token1__totalValueLockedUSDUntracked',
  Token1DerivedEth = 'token1__derivedETH',
  Sender = 'sender',
  Recipient = 'recipient',
  Origin = 'origin',
  Amount0 = 'amount0',
  Amount1 = 'amount1',
  AmountUsd = 'amountUSD',
  SqrtPriceX96 = 'sqrtPriceX96',
  Tick = 'tick',
  LogIndex = 'logIndex'
}

export type Tick = {
  __typename?: 'Tick';
  id: Scalars['ID'];
  poolAddress?: Maybe<Scalars['String']>;
  tickIdx: Scalars['BigInt'];
  pool: Pool;
  liquidityGross: Scalars['BigInt'];
  liquidityNet: Scalars['BigInt'];
  price0: Scalars['BigDecimal'];
  price1: Scalars['BigDecimal'];
  volumeToken0: Scalars['BigDecimal'];
  volumeToken1: Scalars['BigDecimal'];
  volumeUSD: Scalars['BigDecimal'];
  untrackedVolumeUSD: Scalars['BigDecimal'];
  feesUSD: Scalars['BigDecimal'];
  collectedFeesToken0: Scalars['BigDecimal'];
  collectedFeesToken1: Scalars['BigDecimal'];
  collectedFeesUSD: Scalars['BigDecimal'];
  createdAtTimestamp: Scalars['BigInt'];
  createdAtBlockNumber: Scalars['BigInt'];
  liquidityProviderCount: Scalars['BigInt'];
  feeGrowthOutside0X128: Scalars['BigInt'];
  feeGrowthOutside1X128: Scalars['BigInt'];
};

export type TickDayData = {
  __typename?: 'TickDayData';
  id: Scalars['ID'];
  date: Scalars['Int'];
  pool: Pool;
  tick: Tick;
  liquidityGross: Scalars['BigInt'];
  liquidityNet: Scalars['BigInt'];
  volumeToken0: Scalars['BigDecimal'];
  volumeToken1: Scalars['BigDecimal'];
  volumeUSD: Scalars['BigDecimal'];
  feesUSD: Scalars['BigDecimal'];
  feeGrowthOutside0X128: Scalars['BigInt'];
  feeGrowthOutside1X128: Scalars['BigInt'];
};

export type TickDayData_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  date?: Maybe<Scalars['Int']>;
  date_not?: Maybe<Scalars['Int']>;
  date_gt?: Maybe<Scalars['Int']>;
  date_lt?: Maybe<Scalars['Int']>;
  date_gte?: Maybe<Scalars['Int']>;
  date_lte?: Maybe<Scalars['Int']>;
  date_in?: Maybe<Array<Scalars['Int']>>;
  date_not_in?: Maybe<Array<Scalars['Int']>>;
  pool?: Maybe<Scalars['String']>;
  pool_not?: Maybe<Scalars['String']>;
  pool_gt?: Maybe<Scalars['String']>;
  pool_lt?: Maybe<Scalars['String']>;
  pool_gte?: Maybe<Scalars['String']>;
  pool_lte?: Maybe<Scalars['String']>;
  pool_in?: Maybe<Array<Scalars['String']>>;
  pool_not_in?: Maybe<Array<Scalars['String']>>;
  pool_contains?: Maybe<Scalars['String']>;
  pool_contains_nocase?: Maybe<Scalars['String']>;
  pool_not_contains?: Maybe<Scalars['String']>;
  pool_not_contains_nocase?: Maybe<Scalars['String']>;
  pool_starts_with?: Maybe<Scalars['String']>;
  pool_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_not_starts_with?: Maybe<Scalars['String']>;
  pool_not_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_ends_with?: Maybe<Scalars['String']>;
  pool_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_not_ends_with?: Maybe<Scalars['String']>;
  pool_not_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_?: Maybe<Pool_Filter>;
  tick?: Maybe<Scalars['String']>;
  tick_not?: Maybe<Scalars['String']>;
  tick_gt?: Maybe<Scalars['String']>;
  tick_lt?: Maybe<Scalars['String']>;
  tick_gte?: Maybe<Scalars['String']>;
  tick_lte?: Maybe<Scalars['String']>;
  tick_in?: Maybe<Array<Scalars['String']>>;
  tick_not_in?: Maybe<Array<Scalars['String']>>;
  tick_contains?: Maybe<Scalars['String']>;
  tick_contains_nocase?: Maybe<Scalars['String']>;
  tick_not_contains?: Maybe<Scalars['String']>;
  tick_not_contains_nocase?: Maybe<Scalars['String']>;
  tick_starts_with?: Maybe<Scalars['String']>;
  tick_starts_with_nocase?: Maybe<Scalars['String']>;
  tick_not_starts_with?: Maybe<Scalars['String']>;
  tick_not_starts_with_nocase?: Maybe<Scalars['String']>;
  tick_ends_with?: Maybe<Scalars['String']>;
  tick_ends_with_nocase?: Maybe<Scalars['String']>;
  tick_not_ends_with?: Maybe<Scalars['String']>;
  tick_not_ends_with_nocase?: Maybe<Scalars['String']>;
  tick_?: Maybe<Tick_Filter>;
  liquidityGross?: Maybe<Scalars['BigInt']>;
  liquidityGross_not?: Maybe<Scalars['BigInt']>;
  liquidityGross_gt?: Maybe<Scalars['BigInt']>;
  liquidityGross_lt?: Maybe<Scalars['BigInt']>;
  liquidityGross_gte?: Maybe<Scalars['BigInt']>;
  liquidityGross_lte?: Maybe<Scalars['BigInt']>;
  liquidityGross_in?: Maybe<Array<Scalars['BigInt']>>;
  liquidityGross_not_in?: Maybe<Array<Scalars['BigInt']>>;
  liquidityNet?: Maybe<Scalars['BigInt']>;
  liquidityNet_not?: Maybe<Scalars['BigInt']>;
  liquidityNet_gt?: Maybe<Scalars['BigInt']>;
  liquidityNet_lt?: Maybe<Scalars['BigInt']>;
  liquidityNet_gte?: Maybe<Scalars['BigInt']>;
  liquidityNet_lte?: Maybe<Scalars['BigInt']>;
  liquidityNet_in?: Maybe<Array<Scalars['BigInt']>>;
  liquidityNet_not_in?: Maybe<Array<Scalars['BigInt']>>;
  volumeToken0?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_not?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_gt?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_lt?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_gte?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_lte?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken1?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_not?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_gt?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_lt?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_gte?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_lte?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_not?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feesUSD?: Maybe<Scalars['BigDecimal']>;
  feesUSD_not?: Maybe<Scalars['BigDecimal']>;
  feesUSD_gt?: Maybe<Scalars['BigDecimal']>;
  feesUSD_lt?: Maybe<Scalars['BigDecimal']>;
  feesUSD_gte?: Maybe<Scalars['BigDecimal']>;
  feesUSD_lte?: Maybe<Scalars['BigDecimal']>;
  feesUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feesUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feeGrowthOutside0X128?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside0X128_not?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside0X128_gt?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside0X128_lt?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside0X128_gte?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside0X128_lte?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside0X128_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthOutside0X128_not_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthOutside1X128?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside1X128_not?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside1X128_gt?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside1X128_lt?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside1X128_gte?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside1X128_lte?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside1X128_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthOutside1X128_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<TickDayData_Filter>>>;
  or?: Maybe<Array<Maybe<TickDayData_Filter>>>;
};

export enum TickDayData_OrderBy {
  Id = 'id',
  Date = 'date',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolCreatedAtTimestamp = 'pool__createdAtTimestamp',
  PoolCreatedAtBlockNumber = 'pool__createdAtBlockNumber',
  PoolFeeTier = 'pool__feeTier',
  PoolLiquidity = 'pool__liquidity',
  PoolSqrtPrice = 'pool__sqrtPrice',
  PoolFeeGrowthGlobal0X128 = 'pool__feeGrowthGlobal0X128',
  PoolFeeGrowthGlobal1X128 = 'pool__feeGrowthGlobal1X128',
  PoolToken0Price = 'pool__token0Price',
  PoolToken1Price = 'pool__token1Price',
  PoolTick = 'pool__tick',
  PoolObservationIndex = 'pool__observationIndex',
  PoolVolumeToken0 = 'pool__volumeToken0',
  PoolVolumeToken1 = 'pool__volumeToken1',
  PoolVolumeUsd = 'pool__volumeUSD',
  PoolUntrackedVolumeUsd = 'pool__untrackedVolumeUSD',
  PoolFeesUsd = 'pool__feesUSD',
  PoolTxCount = 'pool__txCount',
  PoolCollectedFeesToken0 = 'pool__collectedFeesToken0',
  PoolCollectedFeesToken1 = 'pool__collectedFeesToken1',
  PoolCollectedFeesUsd = 'pool__collectedFeesUSD',
  PoolTotalValueLockedToken0 = 'pool__totalValueLockedToken0',
  PoolTotalValueLockedToken1 = 'pool__totalValueLockedToken1',
  PoolTotalValueLockedEth = 'pool__totalValueLockedETH',
  PoolTotalValueLockedUsd = 'pool__totalValueLockedUSD',
  PoolTotalValueLockedUsdUntracked = 'pool__totalValueLockedUSDUntracked',
  PoolLiquidityProviderCount = 'pool__liquidityProviderCount',
  Tick = 'tick',
  TickId = 'tick__id',
  TickPoolAddress = 'tick__poolAddress',
  TickTickIdx = 'tick__tickIdx',
  TickLiquidityGross = 'tick__liquidityGross',
  TickLiquidityNet = 'tick__liquidityNet',
  TickPrice0 = 'tick__price0',
  TickPrice1 = 'tick__price1',
  TickVolumeToken0 = 'tick__volumeToken0',
  TickVolumeToken1 = 'tick__volumeToken1',
  TickVolumeUsd = 'tick__volumeUSD',
  TickUntrackedVolumeUsd = 'tick__untrackedVolumeUSD',
  TickFeesUsd = 'tick__feesUSD',
  TickCollectedFeesToken0 = 'tick__collectedFeesToken0',
  TickCollectedFeesToken1 = 'tick__collectedFeesToken1',
  TickCollectedFeesUsd = 'tick__collectedFeesUSD',
  TickCreatedAtTimestamp = 'tick__createdAtTimestamp',
  TickCreatedAtBlockNumber = 'tick__createdAtBlockNumber',
  TickLiquidityProviderCount = 'tick__liquidityProviderCount',
  TickFeeGrowthOutside0X128 = 'tick__feeGrowthOutside0X128',
  TickFeeGrowthOutside1X128 = 'tick__feeGrowthOutside1X128',
  LiquidityGross = 'liquidityGross',
  LiquidityNet = 'liquidityNet',
  VolumeToken0 = 'volumeToken0',
  VolumeToken1 = 'volumeToken1',
  VolumeUsd = 'volumeUSD',
  FeesUsd = 'feesUSD',
  FeeGrowthOutside0X128 = 'feeGrowthOutside0X128',
  FeeGrowthOutside1X128 = 'feeGrowthOutside1X128'
}

export type TickHourData = {
  __typename?: 'TickHourData';
  id: Scalars['ID'];
  periodStartUnix: Scalars['Int'];
  pool: Pool;
  tick: Tick;
  liquidityGross: Scalars['BigInt'];
  liquidityNet: Scalars['BigInt'];
  volumeToken0: Scalars['BigDecimal'];
  volumeToken1: Scalars['BigDecimal'];
  volumeUSD: Scalars['BigDecimal'];
  feesUSD: Scalars['BigDecimal'];
};

export type TickHourData_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  periodStartUnix?: Maybe<Scalars['Int']>;
  periodStartUnix_not?: Maybe<Scalars['Int']>;
  periodStartUnix_gt?: Maybe<Scalars['Int']>;
  periodStartUnix_lt?: Maybe<Scalars['Int']>;
  periodStartUnix_gte?: Maybe<Scalars['Int']>;
  periodStartUnix_lte?: Maybe<Scalars['Int']>;
  periodStartUnix_in?: Maybe<Array<Scalars['Int']>>;
  periodStartUnix_not_in?: Maybe<Array<Scalars['Int']>>;
  pool?: Maybe<Scalars['String']>;
  pool_not?: Maybe<Scalars['String']>;
  pool_gt?: Maybe<Scalars['String']>;
  pool_lt?: Maybe<Scalars['String']>;
  pool_gte?: Maybe<Scalars['String']>;
  pool_lte?: Maybe<Scalars['String']>;
  pool_in?: Maybe<Array<Scalars['String']>>;
  pool_not_in?: Maybe<Array<Scalars['String']>>;
  pool_contains?: Maybe<Scalars['String']>;
  pool_contains_nocase?: Maybe<Scalars['String']>;
  pool_not_contains?: Maybe<Scalars['String']>;
  pool_not_contains_nocase?: Maybe<Scalars['String']>;
  pool_starts_with?: Maybe<Scalars['String']>;
  pool_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_not_starts_with?: Maybe<Scalars['String']>;
  pool_not_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_ends_with?: Maybe<Scalars['String']>;
  pool_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_not_ends_with?: Maybe<Scalars['String']>;
  pool_not_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_?: Maybe<Pool_Filter>;
  tick?: Maybe<Scalars['String']>;
  tick_not?: Maybe<Scalars['String']>;
  tick_gt?: Maybe<Scalars['String']>;
  tick_lt?: Maybe<Scalars['String']>;
  tick_gte?: Maybe<Scalars['String']>;
  tick_lte?: Maybe<Scalars['String']>;
  tick_in?: Maybe<Array<Scalars['String']>>;
  tick_not_in?: Maybe<Array<Scalars['String']>>;
  tick_contains?: Maybe<Scalars['String']>;
  tick_contains_nocase?: Maybe<Scalars['String']>;
  tick_not_contains?: Maybe<Scalars['String']>;
  tick_not_contains_nocase?: Maybe<Scalars['String']>;
  tick_starts_with?: Maybe<Scalars['String']>;
  tick_starts_with_nocase?: Maybe<Scalars['String']>;
  tick_not_starts_with?: Maybe<Scalars['String']>;
  tick_not_starts_with_nocase?: Maybe<Scalars['String']>;
  tick_ends_with?: Maybe<Scalars['String']>;
  tick_ends_with_nocase?: Maybe<Scalars['String']>;
  tick_not_ends_with?: Maybe<Scalars['String']>;
  tick_not_ends_with_nocase?: Maybe<Scalars['String']>;
  tick_?: Maybe<Tick_Filter>;
  liquidityGross?: Maybe<Scalars['BigInt']>;
  liquidityGross_not?: Maybe<Scalars['BigInt']>;
  liquidityGross_gt?: Maybe<Scalars['BigInt']>;
  liquidityGross_lt?: Maybe<Scalars['BigInt']>;
  liquidityGross_gte?: Maybe<Scalars['BigInt']>;
  liquidityGross_lte?: Maybe<Scalars['BigInt']>;
  liquidityGross_in?: Maybe<Array<Scalars['BigInt']>>;
  liquidityGross_not_in?: Maybe<Array<Scalars['BigInt']>>;
  liquidityNet?: Maybe<Scalars['BigInt']>;
  liquidityNet_not?: Maybe<Scalars['BigInt']>;
  liquidityNet_gt?: Maybe<Scalars['BigInt']>;
  liquidityNet_lt?: Maybe<Scalars['BigInt']>;
  liquidityNet_gte?: Maybe<Scalars['BigInt']>;
  liquidityNet_lte?: Maybe<Scalars['BigInt']>;
  liquidityNet_in?: Maybe<Array<Scalars['BigInt']>>;
  liquidityNet_not_in?: Maybe<Array<Scalars['BigInt']>>;
  volumeToken0?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_not?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_gt?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_lt?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_gte?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_lte?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken1?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_not?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_gt?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_lt?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_gte?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_lte?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_not?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feesUSD?: Maybe<Scalars['BigDecimal']>;
  feesUSD_not?: Maybe<Scalars['BigDecimal']>;
  feesUSD_gt?: Maybe<Scalars['BigDecimal']>;
  feesUSD_lt?: Maybe<Scalars['BigDecimal']>;
  feesUSD_gte?: Maybe<Scalars['BigDecimal']>;
  feesUSD_lte?: Maybe<Scalars['BigDecimal']>;
  feesUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feesUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<TickHourData_Filter>>>;
  or?: Maybe<Array<Maybe<TickHourData_Filter>>>;
};

export enum TickHourData_OrderBy {
  Id = 'id',
  PeriodStartUnix = 'periodStartUnix',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolCreatedAtTimestamp = 'pool__createdAtTimestamp',
  PoolCreatedAtBlockNumber = 'pool__createdAtBlockNumber',
  PoolFeeTier = 'pool__feeTier',
  PoolLiquidity = 'pool__liquidity',
  PoolSqrtPrice = 'pool__sqrtPrice',
  PoolFeeGrowthGlobal0X128 = 'pool__feeGrowthGlobal0X128',
  PoolFeeGrowthGlobal1X128 = 'pool__feeGrowthGlobal1X128',
  PoolToken0Price = 'pool__token0Price',
  PoolToken1Price = 'pool__token1Price',
  PoolTick = 'pool__tick',
  PoolObservationIndex = 'pool__observationIndex',
  PoolVolumeToken0 = 'pool__volumeToken0',
  PoolVolumeToken1 = 'pool__volumeToken1',
  PoolVolumeUsd = 'pool__volumeUSD',
  PoolUntrackedVolumeUsd = 'pool__untrackedVolumeUSD',
  PoolFeesUsd = 'pool__feesUSD',
  PoolTxCount = 'pool__txCount',
  PoolCollectedFeesToken0 = 'pool__collectedFeesToken0',
  PoolCollectedFeesToken1 = 'pool__collectedFeesToken1',
  PoolCollectedFeesUsd = 'pool__collectedFeesUSD',
  PoolTotalValueLockedToken0 = 'pool__totalValueLockedToken0',
  PoolTotalValueLockedToken1 = 'pool__totalValueLockedToken1',
  PoolTotalValueLockedEth = 'pool__totalValueLockedETH',
  PoolTotalValueLockedUsd = 'pool__totalValueLockedUSD',
  PoolTotalValueLockedUsdUntracked = 'pool__totalValueLockedUSDUntracked',
  PoolLiquidityProviderCount = 'pool__liquidityProviderCount',
  Tick = 'tick',
  TickId = 'tick__id',
  TickPoolAddress = 'tick__poolAddress',
  TickTickIdx = 'tick__tickIdx',
  TickLiquidityGross = 'tick__liquidityGross',
  TickLiquidityNet = 'tick__liquidityNet',
  TickPrice0 = 'tick__price0',
  TickPrice1 = 'tick__price1',
  TickVolumeToken0 = 'tick__volumeToken0',
  TickVolumeToken1 = 'tick__volumeToken1',
  TickVolumeUsd = 'tick__volumeUSD',
  TickUntrackedVolumeUsd = 'tick__untrackedVolumeUSD',
  TickFeesUsd = 'tick__feesUSD',
  TickCollectedFeesToken0 = 'tick__collectedFeesToken0',
  TickCollectedFeesToken1 = 'tick__collectedFeesToken1',
  TickCollectedFeesUsd = 'tick__collectedFeesUSD',
  TickCreatedAtTimestamp = 'tick__createdAtTimestamp',
  TickCreatedAtBlockNumber = 'tick__createdAtBlockNumber',
  TickLiquidityProviderCount = 'tick__liquidityProviderCount',
  TickFeeGrowthOutside0X128 = 'tick__feeGrowthOutside0X128',
  TickFeeGrowthOutside1X128 = 'tick__feeGrowthOutside1X128',
  LiquidityGross = 'liquidityGross',
  LiquidityNet = 'liquidityNet',
  VolumeToken0 = 'volumeToken0',
  VolumeToken1 = 'volumeToken1',
  VolumeUsd = 'volumeUSD',
  FeesUsd = 'feesUSD'
}

export type Tick_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  poolAddress?: Maybe<Scalars['String']>;
  poolAddress_not?: Maybe<Scalars['String']>;
  poolAddress_gt?: Maybe<Scalars['String']>;
  poolAddress_lt?: Maybe<Scalars['String']>;
  poolAddress_gte?: Maybe<Scalars['String']>;
  poolAddress_lte?: Maybe<Scalars['String']>;
  poolAddress_in?: Maybe<Array<Scalars['String']>>;
  poolAddress_not_in?: Maybe<Array<Scalars['String']>>;
  poolAddress_contains?: Maybe<Scalars['String']>;
  poolAddress_contains_nocase?: Maybe<Scalars['String']>;
  poolAddress_not_contains?: Maybe<Scalars['String']>;
  poolAddress_not_contains_nocase?: Maybe<Scalars['String']>;
  poolAddress_starts_with?: Maybe<Scalars['String']>;
  poolAddress_starts_with_nocase?: Maybe<Scalars['String']>;
  poolAddress_not_starts_with?: Maybe<Scalars['String']>;
  poolAddress_not_starts_with_nocase?: Maybe<Scalars['String']>;
  poolAddress_ends_with?: Maybe<Scalars['String']>;
  poolAddress_ends_with_nocase?: Maybe<Scalars['String']>;
  poolAddress_not_ends_with?: Maybe<Scalars['String']>;
  poolAddress_not_ends_with_nocase?: Maybe<Scalars['String']>;
  tickIdx?: Maybe<Scalars['BigInt']>;
  tickIdx_not?: Maybe<Scalars['BigInt']>;
  tickIdx_gt?: Maybe<Scalars['BigInt']>;
  tickIdx_lt?: Maybe<Scalars['BigInt']>;
  tickIdx_gte?: Maybe<Scalars['BigInt']>;
  tickIdx_lte?: Maybe<Scalars['BigInt']>;
  tickIdx_in?: Maybe<Array<Scalars['BigInt']>>;
  tickIdx_not_in?: Maybe<Array<Scalars['BigInt']>>;
  pool?: Maybe<Scalars['String']>;
  pool_not?: Maybe<Scalars['String']>;
  pool_gt?: Maybe<Scalars['String']>;
  pool_lt?: Maybe<Scalars['String']>;
  pool_gte?: Maybe<Scalars['String']>;
  pool_lte?: Maybe<Scalars['String']>;
  pool_in?: Maybe<Array<Scalars['String']>>;
  pool_not_in?: Maybe<Array<Scalars['String']>>;
  pool_contains?: Maybe<Scalars['String']>;
  pool_contains_nocase?: Maybe<Scalars['String']>;
  pool_not_contains?: Maybe<Scalars['String']>;
  pool_not_contains_nocase?: Maybe<Scalars['String']>;
  pool_starts_with?: Maybe<Scalars['String']>;
  pool_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_not_starts_with?: Maybe<Scalars['String']>;
  pool_not_starts_with_nocase?: Maybe<Scalars['String']>;
  pool_ends_with?: Maybe<Scalars['String']>;
  pool_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_not_ends_with?: Maybe<Scalars['String']>;
  pool_not_ends_with_nocase?: Maybe<Scalars['String']>;
  pool_?: Maybe<Pool_Filter>;
  liquidityGross?: Maybe<Scalars['BigInt']>;
  liquidityGross_not?: Maybe<Scalars['BigInt']>;
  liquidityGross_gt?: Maybe<Scalars['BigInt']>;
  liquidityGross_lt?: Maybe<Scalars['BigInt']>;
  liquidityGross_gte?: Maybe<Scalars['BigInt']>;
  liquidityGross_lte?: Maybe<Scalars['BigInt']>;
  liquidityGross_in?: Maybe<Array<Scalars['BigInt']>>;
  liquidityGross_not_in?: Maybe<Array<Scalars['BigInt']>>;
  liquidityNet?: Maybe<Scalars['BigInt']>;
  liquidityNet_not?: Maybe<Scalars['BigInt']>;
  liquidityNet_gt?: Maybe<Scalars['BigInt']>;
  liquidityNet_lt?: Maybe<Scalars['BigInt']>;
  liquidityNet_gte?: Maybe<Scalars['BigInt']>;
  liquidityNet_lte?: Maybe<Scalars['BigInt']>;
  liquidityNet_in?: Maybe<Array<Scalars['BigInt']>>;
  liquidityNet_not_in?: Maybe<Array<Scalars['BigInt']>>;
  price0?: Maybe<Scalars['BigDecimal']>;
  price0_not?: Maybe<Scalars['BigDecimal']>;
  price0_gt?: Maybe<Scalars['BigDecimal']>;
  price0_lt?: Maybe<Scalars['BigDecimal']>;
  price0_gte?: Maybe<Scalars['BigDecimal']>;
  price0_lte?: Maybe<Scalars['BigDecimal']>;
  price0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  price0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  price1?: Maybe<Scalars['BigDecimal']>;
  price1_not?: Maybe<Scalars['BigDecimal']>;
  price1_gt?: Maybe<Scalars['BigDecimal']>;
  price1_lt?: Maybe<Scalars['BigDecimal']>;
  price1_gte?: Maybe<Scalars['BigDecimal']>;
  price1_lte?: Maybe<Scalars['BigDecimal']>;
  price1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  price1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken0?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_not?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_gt?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_lt?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_gte?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_lte?: Maybe<Scalars['BigDecimal']>;
  volumeToken0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken1?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_not?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_gt?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_lt?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_gte?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_lte?: Maybe<Scalars['BigDecimal']>;
  volumeToken1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeToken1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_not?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  untrackedVolumeUSD?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_not?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_gt?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_lt?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_gte?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_lte?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  untrackedVolumeUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feesUSD?: Maybe<Scalars['BigDecimal']>;
  feesUSD_not?: Maybe<Scalars['BigDecimal']>;
  feesUSD_gt?: Maybe<Scalars['BigDecimal']>;
  feesUSD_lt?: Maybe<Scalars['BigDecimal']>;
  feesUSD_gte?: Maybe<Scalars['BigDecimal']>;
  feesUSD_lte?: Maybe<Scalars['BigDecimal']>;
  feesUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feesUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collectedFeesToken0?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_not?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_gt?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_lt?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_gte?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_lte?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken0_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collectedFeesToken0_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collectedFeesToken1?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_not?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_gt?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_lt?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_gte?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_lte?: Maybe<Scalars['BigDecimal']>;
  collectedFeesToken1_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collectedFeesToken1_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collectedFeesUSD?: Maybe<Scalars['BigDecimal']>;
  collectedFeesUSD_not?: Maybe<Scalars['BigDecimal']>;
  collectedFeesUSD_gt?: Maybe<Scalars['BigDecimal']>;
  collectedFeesUSD_lt?: Maybe<Scalars['BigDecimal']>;
  collectedFeesUSD_gte?: Maybe<Scalars['BigDecimal']>;
  collectedFeesUSD_lte?: Maybe<Scalars['BigDecimal']>;
  collectedFeesUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  collectedFeesUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  createdAtTimestamp?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_not?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_gt?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_lt?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_gte?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_lte?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  createdAtTimestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  createdAtBlockNumber?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_not?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_gt?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_lt?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_gte?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_lte?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_in?: Maybe<Array<Scalars['BigInt']>>;
  createdAtBlockNumber_not_in?: Maybe<Array<Scalars['BigInt']>>;
  liquidityProviderCount?: Maybe<Scalars['BigInt']>;
  liquidityProviderCount_not?: Maybe<Scalars['BigInt']>;
  liquidityProviderCount_gt?: Maybe<Scalars['BigInt']>;
  liquidityProviderCount_lt?: Maybe<Scalars['BigInt']>;
  liquidityProviderCount_gte?: Maybe<Scalars['BigInt']>;
  liquidityProviderCount_lte?: Maybe<Scalars['BigInt']>;
  liquidityProviderCount_in?: Maybe<Array<Scalars['BigInt']>>;
  liquidityProviderCount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthOutside0X128?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside0X128_not?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside0X128_gt?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside0X128_lt?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside0X128_gte?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside0X128_lte?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside0X128_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthOutside0X128_not_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthOutside1X128?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside1X128_not?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside1X128_gt?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside1X128_lt?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside1X128_gte?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside1X128_lte?: Maybe<Scalars['BigInt']>;
  feeGrowthOutside1X128_in?: Maybe<Array<Scalars['BigInt']>>;
  feeGrowthOutside1X128_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Tick_Filter>>>;
  or?: Maybe<Array<Maybe<Tick_Filter>>>;
};

export enum Tick_OrderBy {
  Id = 'id',
  PoolAddress = 'poolAddress',
  TickIdx = 'tickIdx',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolCreatedAtTimestamp = 'pool__createdAtTimestamp',
  PoolCreatedAtBlockNumber = 'pool__createdAtBlockNumber',
  PoolFeeTier = 'pool__feeTier',
  PoolLiquidity = 'pool__liquidity',
  PoolSqrtPrice = 'pool__sqrtPrice',
  PoolFeeGrowthGlobal0X128 = 'pool__feeGrowthGlobal0X128',
  PoolFeeGrowthGlobal1X128 = 'pool__feeGrowthGlobal1X128',
  PoolToken0Price = 'pool__token0Price',
  PoolToken1Price = 'pool__token1Price',
  PoolTick = 'pool__tick',
  PoolObservationIndex = 'pool__observationIndex',
  PoolVolumeToken0 = 'pool__volumeToken0',
  PoolVolumeToken1 = 'pool__volumeToken1',
  PoolVolumeUsd = 'pool__volumeUSD',
  PoolUntrackedVolumeUsd = 'pool__untrackedVolumeUSD',
  PoolFeesUsd = 'pool__feesUSD',
  PoolTxCount = 'pool__txCount',
  PoolCollectedFeesToken0 = 'pool__collectedFeesToken0',
  PoolCollectedFeesToken1 = 'pool__collectedFeesToken1',
  PoolCollectedFeesUsd = 'pool__collectedFeesUSD',
  PoolTotalValueLockedToken0 = 'pool__totalValueLockedToken0',
  PoolTotalValueLockedToken1 = 'pool__totalValueLockedToken1',
  PoolTotalValueLockedEth = 'pool__totalValueLockedETH',
  PoolTotalValueLockedUsd = 'pool__totalValueLockedUSD',
  PoolTotalValueLockedUsdUntracked = 'pool__totalValueLockedUSDUntracked',
  PoolLiquidityProviderCount = 'pool__liquidityProviderCount',
  LiquidityGross = 'liquidityGross',
  LiquidityNet = 'liquidityNet',
  Price0 = 'price0',
  Price1 = 'price1',
  VolumeToken0 = 'volumeToken0',
  VolumeToken1 = 'volumeToken1',
  VolumeUsd = 'volumeUSD',
  UntrackedVolumeUsd = 'untrackedVolumeUSD',
  FeesUsd = 'feesUSD',
  CollectedFeesToken0 = 'collectedFeesToken0',
  CollectedFeesToken1 = 'collectedFeesToken1',
  CollectedFeesUsd = 'collectedFeesUSD',
  CreatedAtTimestamp = 'createdAtTimestamp',
  CreatedAtBlockNumber = 'createdAtBlockNumber',
  LiquidityProviderCount = 'liquidityProviderCount',
  FeeGrowthOutside0X128 = 'feeGrowthOutside0X128',
  FeeGrowthOutside1X128 = 'feeGrowthOutside1X128'
}


export type Token = {
  __typename?: 'Token';
  id: Scalars['ID'];
  symbol: Scalars['String'];
  name: Scalars['String'];
  decimals: Scalars['BigInt'];
  totalSupply: Scalars['BigInt'];
  volume: Scalars['BigDecimal'];
  volumeUSD: Scalars['BigDecimal'];
  untrackedVolumeUSD: Scalars['BigDecimal'];
  feesUSD: Scalars['BigDecimal'];
  txCount: Scalars['BigInt'];
  poolCount: Scalars['BigInt'];
  totalValueLocked: Scalars['BigDecimal'];
  totalValueLockedUSD: Scalars['BigDecimal'];
  totalValueLockedUSDUntracked: Scalars['BigDecimal'];
  derivedETH: Scalars['BigDecimal'];
  whitelistPools: Array<Pool>;
  tokenDayData: Array<TokenDayData>;
};


export type TokenWhitelistPoolsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Pool_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Pool_Filter>;
};


export type TokenTokenDayDataArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TokenDayData_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<TokenDayData_Filter>;
};

export type TokenDayData = {
  __typename?: 'TokenDayData';
  id: Scalars['ID'];
  date: Scalars['Int'];
  token: Token;
  volume: Scalars['BigDecimal'];
  volumeUSD: Scalars['BigDecimal'];
  untrackedVolumeUSD: Scalars['BigDecimal'];
  totalValueLocked: Scalars['BigDecimal'];
  totalValueLockedUSD: Scalars['BigDecimal'];
  priceUSD: Scalars['BigDecimal'];
  feesUSD: Scalars['BigDecimal'];
  open: Scalars['BigDecimal'];
  high: Scalars['BigDecimal'];
  low: Scalars['BigDecimal'];
  close: Scalars['BigDecimal'];
};

export type TokenDayData_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  date?: Maybe<Scalars['Int']>;
  date_not?: Maybe<Scalars['Int']>;
  date_gt?: Maybe<Scalars['Int']>;
  date_lt?: Maybe<Scalars['Int']>;
  date_gte?: Maybe<Scalars['Int']>;
  date_lte?: Maybe<Scalars['Int']>;
  date_in?: Maybe<Array<Scalars['Int']>>;
  date_not_in?: Maybe<Array<Scalars['Int']>>;
  token?: Maybe<Scalars['String']>;
  token_not?: Maybe<Scalars['String']>;
  token_gt?: Maybe<Scalars['String']>;
  token_lt?: Maybe<Scalars['String']>;
  token_gte?: Maybe<Scalars['String']>;
  token_lte?: Maybe<Scalars['String']>;
  token_in?: Maybe<Array<Scalars['String']>>;
  token_not_in?: Maybe<Array<Scalars['String']>>;
  token_contains?: Maybe<Scalars['String']>;
  token_contains_nocase?: Maybe<Scalars['String']>;
  token_not_contains?: Maybe<Scalars['String']>;
  token_not_contains_nocase?: Maybe<Scalars['String']>;
  token_starts_with?: Maybe<Scalars['String']>;
  token_starts_with_nocase?: Maybe<Scalars['String']>;
  token_not_starts_with?: Maybe<Scalars['String']>;
  token_not_starts_with_nocase?: Maybe<Scalars['String']>;
  token_ends_with?: Maybe<Scalars['String']>;
  token_ends_with_nocase?: Maybe<Scalars['String']>;
  token_not_ends_with?: Maybe<Scalars['String']>;
  token_not_ends_with_nocase?: Maybe<Scalars['String']>;
  token_?: Maybe<Token_Filter>;
  volume?: Maybe<Scalars['BigDecimal']>;
  volume_not?: Maybe<Scalars['BigDecimal']>;
  volume_gt?: Maybe<Scalars['BigDecimal']>;
  volume_lt?: Maybe<Scalars['BigDecimal']>;
  volume_gte?: Maybe<Scalars['BigDecimal']>;
  volume_lte?: Maybe<Scalars['BigDecimal']>;
  volume_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volume_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_not?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  untrackedVolumeUSD?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_not?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_gt?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_lt?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_gte?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_lte?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  untrackedVolumeUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLocked?: Maybe<Scalars['BigDecimal']>;
  totalValueLocked_not?: Maybe<Scalars['BigDecimal']>;
  totalValueLocked_gt?: Maybe<Scalars['BigDecimal']>;
  totalValueLocked_lt?: Maybe<Scalars['BigDecimal']>;
  totalValueLocked_gte?: Maybe<Scalars['BigDecimal']>;
  totalValueLocked_lte?: Maybe<Scalars['BigDecimal']>;
  totalValueLocked_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLocked_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedUSD?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_not?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_gt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_lt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_gte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_lte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  priceUSD?: Maybe<Scalars['BigDecimal']>;
  priceUSD_not?: Maybe<Scalars['BigDecimal']>;
  priceUSD_gt?: Maybe<Scalars['BigDecimal']>;
  priceUSD_lt?: Maybe<Scalars['BigDecimal']>;
  priceUSD_gte?: Maybe<Scalars['BigDecimal']>;
  priceUSD_lte?: Maybe<Scalars['BigDecimal']>;
  priceUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  priceUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feesUSD?: Maybe<Scalars['BigDecimal']>;
  feesUSD_not?: Maybe<Scalars['BigDecimal']>;
  feesUSD_gt?: Maybe<Scalars['BigDecimal']>;
  feesUSD_lt?: Maybe<Scalars['BigDecimal']>;
  feesUSD_gte?: Maybe<Scalars['BigDecimal']>;
  feesUSD_lte?: Maybe<Scalars['BigDecimal']>;
  feesUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feesUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  open?: Maybe<Scalars['BigDecimal']>;
  open_not?: Maybe<Scalars['BigDecimal']>;
  open_gt?: Maybe<Scalars['BigDecimal']>;
  open_lt?: Maybe<Scalars['BigDecimal']>;
  open_gte?: Maybe<Scalars['BigDecimal']>;
  open_lte?: Maybe<Scalars['BigDecimal']>;
  open_in?: Maybe<Array<Scalars['BigDecimal']>>;
  open_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  high?: Maybe<Scalars['BigDecimal']>;
  high_not?: Maybe<Scalars['BigDecimal']>;
  high_gt?: Maybe<Scalars['BigDecimal']>;
  high_lt?: Maybe<Scalars['BigDecimal']>;
  high_gte?: Maybe<Scalars['BigDecimal']>;
  high_lte?: Maybe<Scalars['BigDecimal']>;
  high_in?: Maybe<Array<Scalars['BigDecimal']>>;
  high_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  low?: Maybe<Scalars['BigDecimal']>;
  low_not?: Maybe<Scalars['BigDecimal']>;
  low_gt?: Maybe<Scalars['BigDecimal']>;
  low_lt?: Maybe<Scalars['BigDecimal']>;
  low_gte?: Maybe<Scalars['BigDecimal']>;
  low_lte?: Maybe<Scalars['BigDecimal']>;
  low_in?: Maybe<Array<Scalars['BigDecimal']>>;
  low_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  close?: Maybe<Scalars['BigDecimal']>;
  close_not?: Maybe<Scalars['BigDecimal']>;
  close_gt?: Maybe<Scalars['BigDecimal']>;
  close_lt?: Maybe<Scalars['BigDecimal']>;
  close_gte?: Maybe<Scalars['BigDecimal']>;
  close_lte?: Maybe<Scalars['BigDecimal']>;
  close_in?: Maybe<Array<Scalars['BigDecimal']>>;
  close_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<TokenDayData_Filter>>>;
  or?: Maybe<Array<Maybe<TokenDayData_Filter>>>;
};

export enum TokenDayData_OrderBy {
  Id = 'id',
  Date = 'date',
  Token = 'token',
  TokenId = 'token__id',
  TokenSymbol = 'token__symbol',
  TokenName = 'token__name',
  TokenDecimals = 'token__decimals',
  TokenTotalSupply = 'token__totalSupply',
  TokenVolume = 'token__volume',
  TokenVolumeUsd = 'token__volumeUSD',
  TokenUntrackedVolumeUsd = 'token__untrackedVolumeUSD',
  TokenFeesUsd = 'token__feesUSD',
  TokenTxCount = 'token__txCount',
  TokenPoolCount = 'token__poolCount',
  TokenTotalValueLocked = 'token__totalValueLocked',
  TokenTotalValueLockedUsd = 'token__totalValueLockedUSD',
  TokenTotalValueLockedUsdUntracked = 'token__totalValueLockedUSDUntracked',
  TokenDerivedEth = 'token__derivedETH',
  Volume = 'volume',
  VolumeUsd = 'volumeUSD',
  UntrackedVolumeUsd = 'untrackedVolumeUSD',
  TotalValueLocked = 'totalValueLocked',
  TotalValueLockedUsd = 'totalValueLockedUSD',
  PriceUsd = 'priceUSD',
  FeesUsd = 'feesUSD',
  Open = 'open',
  High = 'high',
  Low = 'low',
  Close = 'close'
}

export type TokenHourData = {
  __typename?: 'TokenHourData';
  id: Scalars['ID'];
  periodStartUnix: Scalars['Int'];
  token: Token;
  volume: Scalars['BigDecimal'];
  volumeUSD: Scalars['BigDecimal'];
  untrackedVolumeUSD: Scalars['BigDecimal'];
  totalValueLocked: Scalars['BigDecimal'];
  totalValueLockedUSD: Scalars['BigDecimal'];
  priceUSD: Scalars['BigDecimal'];
  feesUSD: Scalars['BigDecimal'];
  open: Scalars['BigDecimal'];
  high: Scalars['BigDecimal'];
  low: Scalars['BigDecimal'];
  close: Scalars['BigDecimal'];
};

export type TokenHourData_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  periodStartUnix?: Maybe<Scalars['Int']>;
  periodStartUnix_not?: Maybe<Scalars['Int']>;
  periodStartUnix_gt?: Maybe<Scalars['Int']>;
  periodStartUnix_lt?: Maybe<Scalars['Int']>;
  periodStartUnix_gte?: Maybe<Scalars['Int']>;
  periodStartUnix_lte?: Maybe<Scalars['Int']>;
  periodStartUnix_in?: Maybe<Array<Scalars['Int']>>;
  periodStartUnix_not_in?: Maybe<Array<Scalars['Int']>>;
  token?: Maybe<Scalars['String']>;
  token_not?: Maybe<Scalars['String']>;
  token_gt?: Maybe<Scalars['String']>;
  token_lt?: Maybe<Scalars['String']>;
  token_gte?: Maybe<Scalars['String']>;
  token_lte?: Maybe<Scalars['String']>;
  token_in?: Maybe<Array<Scalars['String']>>;
  token_not_in?: Maybe<Array<Scalars['String']>>;
  token_contains?: Maybe<Scalars['String']>;
  token_contains_nocase?: Maybe<Scalars['String']>;
  token_not_contains?: Maybe<Scalars['String']>;
  token_not_contains_nocase?: Maybe<Scalars['String']>;
  token_starts_with?: Maybe<Scalars['String']>;
  token_starts_with_nocase?: Maybe<Scalars['String']>;
  token_not_starts_with?: Maybe<Scalars['String']>;
  token_not_starts_with_nocase?: Maybe<Scalars['String']>;
  token_ends_with?: Maybe<Scalars['String']>;
  token_ends_with_nocase?: Maybe<Scalars['String']>;
  token_not_ends_with?: Maybe<Scalars['String']>;
  token_not_ends_with_nocase?: Maybe<Scalars['String']>;
  token_?: Maybe<Token_Filter>;
  volume?: Maybe<Scalars['BigDecimal']>;
  volume_not?: Maybe<Scalars['BigDecimal']>;
  volume_gt?: Maybe<Scalars['BigDecimal']>;
  volume_lt?: Maybe<Scalars['BigDecimal']>;
  volume_gte?: Maybe<Scalars['BigDecimal']>;
  volume_lte?: Maybe<Scalars['BigDecimal']>;
  volume_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volume_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_not?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  untrackedVolumeUSD?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_not?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_gt?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_lt?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_gte?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_lte?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  untrackedVolumeUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLocked?: Maybe<Scalars['BigDecimal']>;
  totalValueLocked_not?: Maybe<Scalars['BigDecimal']>;
  totalValueLocked_gt?: Maybe<Scalars['BigDecimal']>;
  totalValueLocked_lt?: Maybe<Scalars['BigDecimal']>;
  totalValueLocked_gte?: Maybe<Scalars['BigDecimal']>;
  totalValueLocked_lte?: Maybe<Scalars['BigDecimal']>;
  totalValueLocked_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLocked_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedUSD?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_not?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_gt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_lt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_gte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_lte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  priceUSD?: Maybe<Scalars['BigDecimal']>;
  priceUSD_not?: Maybe<Scalars['BigDecimal']>;
  priceUSD_gt?: Maybe<Scalars['BigDecimal']>;
  priceUSD_lt?: Maybe<Scalars['BigDecimal']>;
  priceUSD_gte?: Maybe<Scalars['BigDecimal']>;
  priceUSD_lte?: Maybe<Scalars['BigDecimal']>;
  priceUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  priceUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feesUSD?: Maybe<Scalars['BigDecimal']>;
  feesUSD_not?: Maybe<Scalars['BigDecimal']>;
  feesUSD_gt?: Maybe<Scalars['BigDecimal']>;
  feesUSD_lt?: Maybe<Scalars['BigDecimal']>;
  feesUSD_gte?: Maybe<Scalars['BigDecimal']>;
  feesUSD_lte?: Maybe<Scalars['BigDecimal']>;
  feesUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feesUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  open?: Maybe<Scalars['BigDecimal']>;
  open_not?: Maybe<Scalars['BigDecimal']>;
  open_gt?: Maybe<Scalars['BigDecimal']>;
  open_lt?: Maybe<Scalars['BigDecimal']>;
  open_gte?: Maybe<Scalars['BigDecimal']>;
  open_lte?: Maybe<Scalars['BigDecimal']>;
  open_in?: Maybe<Array<Scalars['BigDecimal']>>;
  open_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  high?: Maybe<Scalars['BigDecimal']>;
  high_not?: Maybe<Scalars['BigDecimal']>;
  high_gt?: Maybe<Scalars['BigDecimal']>;
  high_lt?: Maybe<Scalars['BigDecimal']>;
  high_gte?: Maybe<Scalars['BigDecimal']>;
  high_lte?: Maybe<Scalars['BigDecimal']>;
  high_in?: Maybe<Array<Scalars['BigDecimal']>>;
  high_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  low?: Maybe<Scalars['BigDecimal']>;
  low_not?: Maybe<Scalars['BigDecimal']>;
  low_gt?: Maybe<Scalars['BigDecimal']>;
  low_lt?: Maybe<Scalars['BigDecimal']>;
  low_gte?: Maybe<Scalars['BigDecimal']>;
  low_lte?: Maybe<Scalars['BigDecimal']>;
  low_in?: Maybe<Array<Scalars['BigDecimal']>>;
  low_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  close?: Maybe<Scalars['BigDecimal']>;
  close_not?: Maybe<Scalars['BigDecimal']>;
  close_gt?: Maybe<Scalars['BigDecimal']>;
  close_lt?: Maybe<Scalars['BigDecimal']>;
  close_gte?: Maybe<Scalars['BigDecimal']>;
  close_lte?: Maybe<Scalars['BigDecimal']>;
  close_in?: Maybe<Array<Scalars['BigDecimal']>>;
  close_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<TokenHourData_Filter>>>;
  or?: Maybe<Array<Maybe<TokenHourData_Filter>>>;
};

export enum TokenHourData_OrderBy {
  Id = 'id',
  PeriodStartUnix = 'periodStartUnix',
  Token = 'token',
  TokenId = 'token__id',
  TokenSymbol = 'token__symbol',
  TokenName = 'token__name',
  TokenDecimals = 'token__decimals',
  TokenTotalSupply = 'token__totalSupply',
  TokenVolume = 'token__volume',
  TokenVolumeUsd = 'token__volumeUSD',
  TokenUntrackedVolumeUsd = 'token__untrackedVolumeUSD',
  TokenFeesUsd = 'token__feesUSD',
  TokenTxCount = 'token__txCount',
  TokenPoolCount = 'token__poolCount',
  TokenTotalValueLocked = 'token__totalValueLocked',
  TokenTotalValueLockedUsd = 'token__totalValueLockedUSD',
  TokenTotalValueLockedUsdUntracked = 'token__totalValueLockedUSDUntracked',
  TokenDerivedEth = 'token__derivedETH',
  Volume = 'volume',
  VolumeUsd = 'volumeUSD',
  UntrackedVolumeUsd = 'untrackedVolumeUSD',
  TotalValueLocked = 'totalValueLocked',
  TotalValueLockedUsd = 'totalValueLockedUSD',
  PriceUsd = 'priceUSD',
  FeesUsd = 'feesUSD',
  Open = 'open',
  High = 'high',
  Low = 'low',
  Close = 'close'
}

export type Token_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  symbol?: Maybe<Scalars['String']>;
  symbol_not?: Maybe<Scalars['String']>;
  symbol_gt?: Maybe<Scalars['String']>;
  symbol_lt?: Maybe<Scalars['String']>;
  symbol_gte?: Maybe<Scalars['String']>;
  symbol_lte?: Maybe<Scalars['String']>;
  symbol_in?: Maybe<Array<Scalars['String']>>;
  symbol_not_in?: Maybe<Array<Scalars['String']>>;
  symbol_contains?: Maybe<Scalars['String']>;
  symbol_contains_nocase?: Maybe<Scalars['String']>;
  symbol_not_contains?: Maybe<Scalars['String']>;
  symbol_not_contains_nocase?: Maybe<Scalars['String']>;
  symbol_starts_with?: Maybe<Scalars['String']>;
  symbol_starts_with_nocase?: Maybe<Scalars['String']>;
  symbol_not_starts_with?: Maybe<Scalars['String']>;
  symbol_not_starts_with_nocase?: Maybe<Scalars['String']>;
  symbol_ends_with?: Maybe<Scalars['String']>;
  symbol_ends_with_nocase?: Maybe<Scalars['String']>;
  symbol_not_ends_with?: Maybe<Scalars['String']>;
  symbol_not_ends_with_nocase?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_lt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_contains?: Maybe<Scalars['String']>;
  name_contains_nocase?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_not_contains_nocase?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_starts_with_nocase?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with_nocase?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_ends_with_nocase?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with_nocase?: Maybe<Scalars['String']>;
  decimals?: Maybe<Scalars['BigInt']>;
  decimals_not?: Maybe<Scalars['BigInt']>;
  decimals_gt?: Maybe<Scalars['BigInt']>;
  decimals_lt?: Maybe<Scalars['BigInt']>;
  decimals_gte?: Maybe<Scalars['BigInt']>;
  decimals_lte?: Maybe<Scalars['BigInt']>;
  decimals_in?: Maybe<Array<Scalars['BigInt']>>;
  decimals_not_in?: Maybe<Array<Scalars['BigInt']>>;
  totalSupply?: Maybe<Scalars['BigInt']>;
  totalSupply_not?: Maybe<Scalars['BigInt']>;
  totalSupply_gt?: Maybe<Scalars['BigInt']>;
  totalSupply_lt?: Maybe<Scalars['BigInt']>;
  totalSupply_gte?: Maybe<Scalars['BigInt']>;
  totalSupply_lte?: Maybe<Scalars['BigInt']>;
  totalSupply_in?: Maybe<Array<Scalars['BigInt']>>;
  totalSupply_not_in?: Maybe<Array<Scalars['BigInt']>>;
  volume?: Maybe<Scalars['BigDecimal']>;
  volume_not?: Maybe<Scalars['BigDecimal']>;
  volume_gt?: Maybe<Scalars['BigDecimal']>;
  volume_lt?: Maybe<Scalars['BigDecimal']>;
  volume_gte?: Maybe<Scalars['BigDecimal']>;
  volume_lte?: Maybe<Scalars['BigDecimal']>;
  volume_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volume_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_not?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  untrackedVolumeUSD?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_not?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_gt?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_lt?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_gte?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_lte?: Maybe<Scalars['BigDecimal']>;
  untrackedVolumeUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  untrackedVolumeUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feesUSD?: Maybe<Scalars['BigDecimal']>;
  feesUSD_not?: Maybe<Scalars['BigDecimal']>;
  feesUSD_gt?: Maybe<Scalars['BigDecimal']>;
  feesUSD_lt?: Maybe<Scalars['BigDecimal']>;
  feesUSD_gte?: Maybe<Scalars['BigDecimal']>;
  feesUSD_lte?: Maybe<Scalars['BigDecimal']>;
  feesUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feesUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  txCount?: Maybe<Scalars['BigInt']>;
  txCount_not?: Maybe<Scalars['BigInt']>;
  txCount_gt?: Maybe<Scalars['BigInt']>;
  txCount_lt?: Maybe<Scalars['BigInt']>;
  txCount_gte?: Maybe<Scalars['BigInt']>;
  txCount_lte?: Maybe<Scalars['BigInt']>;
  txCount_in?: Maybe<Array<Scalars['BigInt']>>;
  txCount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  poolCount?: Maybe<Scalars['BigInt']>;
  poolCount_not?: Maybe<Scalars['BigInt']>;
  poolCount_gt?: Maybe<Scalars['BigInt']>;
  poolCount_lt?: Maybe<Scalars['BigInt']>;
  poolCount_gte?: Maybe<Scalars['BigInt']>;
  poolCount_lte?: Maybe<Scalars['BigInt']>;
  poolCount_in?: Maybe<Array<Scalars['BigInt']>>;
  poolCount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  totalValueLocked?: Maybe<Scalars['BigDecimal']>;
  totalValueLocked_not?: Maybe<Scalars['BigDecimal']>;
  totalValueLocked_gt?: Maybe<Scalars['BigDecimal']>;
  totalValueLocked_lt?: Maybe<Scalars['BigDecimal']>;
  totalValueLocked_gte?: Maybe<Scalars['BigDecimal']>;
  totalValueLocked_lte?: Maybe<Scalars['BigDecimal']>;
  totalValueLocked_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLocked_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedUSD?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_not?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_gt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_lt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_gte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_lte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedUSDUntracked?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSDUntracked_not?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSDUntracked_gt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSDUntracked_lt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSDUntracked_gte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSDUntracked_lte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSDUntracked_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedUSDUntracked_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  derivedETH?: Maybe<Scalars['BigDecimal']>;
  derivedETH_not?: Maybe<Scalars['BigDecimal']>;
  derivedETH_gt?: Maybe<Scalars['BigDecimal']>;
  derivedETH_lt?: Maybe<Scalars['BigDecimal']>;
  derivedETH_gte?: Maybe<Scalars['BigDecimal']>;
  derivedETH_lte?: Maybe<Scalars['BigDecimal']>;
  derivedETH_in?: Maybe<Array<Scalars['BigDecimal']>>;
  derivedETH_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  whitelistPools?: Maybe<Array<Scalars['String']>>;
  whitelistPools_not?: Maybe<Array<Scalars['String']>>;
  whitelistPools_contains?: Maybe<Array<Scalars['String']>>;
  whitelistPools_contains_nocase?: Maybe<Array<Scalars['String']>>;
  whitelistPools_not_contains?: Maybe<Array<Scalars['String']>>;
  whitelistPools_not_contains_nocase?: Maybe<Array<Scalars['String']>>;
  whitelistPools_?: Maybe<Pool_Filter>;
  tokenDayData_?: Maybe<TokenDayData_Filter>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Token_Filter>>>;
  or?: Maybe<Array<Maybe<Token_Filter>>>;
};

export enum Token_OrderBy {
  Id = 'id',
  Symbol = 'symbol',
  Name = 'name',
  Decimals = 'decimals',
  TotalSupply = 'totalSupply',
  Volume = 'volume',
  VolumeUsd = 'volumeUSD',
  UntrackedVolumeUsd = 'untrackedVolumeUSD',
  FeesUsd = 'feesUSD',
  TxCount = 'txCount',
  PoolCount = 'poolCount',
  TotalValueLocked = 'totalValueLocked',
  TotalValueLockedUsd = 'totalValueLockedUSD',
  TotalValueLockedUsdUntracked = 'totalValueLockedUSDUntracked',
  DerivedEth = 'derivedETH',
  WhitelistPools = 'whitelistPools',
  TokenDayData = 'tokenDayData'
}

export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['ID'];
  blockNumber: Scalars['BigInt'];
  timestamp: Scalars['BigInt'];
  gasUsed: Scalars['BigInt'];
  gasPrice: Scalars['BigInt'];
  mints: Array<Maybe<Mint>>;
  burns: Array<Maybe<Burn>>;
  swaps: Array<Maybe<Swap>>;
  flashed: Array<Maybe<Flash>>;
  collects: Array<Maybe<Collect>>;
};


export type TransactionMintsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Mint_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Mint_Filter>;
};


export type TransactionBurnsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Burn_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Burn_Filter>;
};


export type TransactionSwapsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Swap_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Swap_Filter>;
};


export type TransactionFlashedArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Flash_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Flash_Filter>;
};


export type TransactionCollectsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Collect_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Collect_Filter>;
};

export type Transaction_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  blockNumber?: Maybe<Scalars['BigInt']>;
  blockNumber_not?: Maybe<Scalars['BigInt']>;
  blockNumber_gt?: Maybe<Scalars['BigInt']>;
  blockNumber_lt?: Maybe<Scalars['BigInt']>;
  blockNumber_gte?: Maybe<Scalars['BigInt']>;
  blockNumber_lte?: Maybe<Scalars['BigInt']>;
  blockNumber_in?: Maybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  gasUsed?: Maybe<Scalars['BigInt']>;
  gasUsed_not?: Maybe<Scalars['BigInt']>;
  gasUsed_gt?: Maybe<Scalars['BigInt']>;
  gasUsed_lt?: Maybe<Scalars['BigInt']>;
  gasUsed_gte?: Maybe<Scalars['BigInt']>;
  gasUsed_lte?: Maybe<Scalars['BigInt']>;
  gasUsed_in?: Maybe<Array<Scalars['BigInt']>>;
  gasUsed_not_in?: Maybe<Array<Scalars['BigInt']>>;
  gasPrice?: Maybe<Scalars['BigInt']>;
  gasPrice_not?: Maybe<Scalars['BigInt']>;
  gasPrice_gt?: Maybe<Scalars['BigInt']>;
  gasPrice_lt?: Maybe<Scalars['BigInt']>;
  gasPrice_gte?: Maybe<Scalars['BigInt']>;
  gasPrice_lte?: Maybe<Scalars['BigInt']>;
  gasPrice_in?: Maybe<Array<Scalars['BigInt']>>;
  gasPrice_not_in?: Maybe<Array<Scalars['BigInt']>>;
  mints_?: Maybe<Mint_Filter>;
  burns_?: Maybe<Burn_Filter>;
  swaps_?: Maybe<Swap_Filter>;
  flashed_?: Maybe<Flash_Filter>;
  collects_?: Maybe<Collect_Filter>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Transaction_Filter>>>;
  or?: Maybe<Array<Maybe<Transaction_Filter>>>;
};

export enum Transaction_OrderBy {
  Id = 'id',
  BlockNumber = 'blockNumber',
  Timestamp = 'timestamp',
  GasUsed = 'gasUsed',
  GasPrice = 'gasPrice',
  Mints = 'mints',
  Burns = 'burns',
  Swaps = 'swaps',
  Flashed = 'flashed',
  Collects = 'collects'
}

export type UniswapDayData = {
  __typename?: 'UniswapDayData';
  id: Scalars['ID'];
  date: Scalars['Int'];
  volumeETH: Scalars['BigDecimal'];
  volumeUSD: Scalars['BigDecimal'];
  volumeUSDUntracked: Scalars['BigDecimal'];
  feesUSD: Scalars['BigDecimal'];
  txCount: Scalars['BigInt'];
  tvlUSD: Scalars['BigDecimal'];
};

export type UniswapDayData_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  date?: Maybe<Scalars['Int']>;
  date_not?: Maybe<Scalars['Int']>;
  date_gt?: Maybe<Scalars['Int']>;
  date_lt?: Maybe<Scalars['Int']>;
  date_gte?: Maybe<Scalars['Int']>;
  date_lte?: Maybe<Scalars['Int']>;
  date_in?: Maybe<Array<Scalars['Int']>>;
  date_not_in?: Maybe<Array<Scalars['Int']>>;
  volumeETH?: Maybe<Scalars['BigDecimal']>;
  volumeETH_not?: Maybe<Scalars['BigDecimal']>;
  volumeETH_gt?: Maybe<Scalars['BigDecimal']>;
  volumeETH_lt?: Maybe<Scalars['BigDecimal']>;
  volumeETH_gte?: Maybe<Scalars['BigDecimal']>;
  volumeETH_lte?: Maybe<Scalars['BigDecimal']>;
  volumeETH_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeETH_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_not?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lt?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: Maybe<Scalars['BigDecimal']>;
  volumeUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSDUntracked?: Maybe<Scalars['BigDecimal']>;
  volumeUSDUntracked_not?: Maybe<Scalars['BigDecimal']>;
  volumeUSDUntracked_gt?: Maybe<Scalars['BigDecimal']>;
  volumeUSDUntracked_lt?: Maybe<Scalars['BigDecimal']>;
  volumeUSDUntracked_gte?: Maybe<Scalars['BigDecimal']>;
  volumeUSDUntracked_lte?: Maybe<Scalars['BigDecimal']>;
  volumeUSDUntracked_in?: Maybe<Array<Scalars['BigDecimal']>>;
  volumeUSDUntracked_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feesUSD?: Maybe<Scalars['BigDecimal']>;
  feesUSD_not?: Maybe<Scalars['BigDecimal']>;
  feesUSD_gt?: Maybe<Scalars['BigDecimal']>;
  feesUSD_lt?: Maybe<Scalars['BigDecimal']>;
  feesUSD_gte?: Maybe<Scalars['BigDecimal']>;
  feesUSD_lte?: Maybe<Scalars['BigDecimal']>;
  feesUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  feesUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  txCount?: Maybe<Scalars['BigInt']>;
  txCount_not?: Maybe<Scalars['BigInt']>;
  txCount_gt?: Maybe<Scalars['BigInt']>;
  txCount_lt?: Maybe<Scalars['BigInt']>;
  txCount_gte?: Maybe<Scalars['BigInt']>;
  txCount_lte?: Maybe<Scalars['BigInt']>;
  txCount_in?: Maybe<Array<Scalars['BigInt']>>;
  txCount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  tvlUSD?: Maybe<Scalars['BigDecimal']>;
  tvlUSD_not?: Maybe<Scalars['BigDecimal']>;
  tvlUSD_gt?: Maybe<Scalars['BigDecimal']>;
  tvlUSD_lt?: Maybe<Scalars['BigDecimal']>;
  tvlUSD_gte?: Maybe<Scalars['BigDecimal']>;
  tvlUSD_lte?: Maybe<Scalars['BigDecimal']>;
  tvlUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  tvlUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<UniswapDayData_Filter>>>;
  or?: Maybe<Array<Maybe<UniswapDayData_Filter>>>;
};

export enum UniswapDayData_OrderBy {
  Id = 'id',
  Date = 'date',
  VolumeEth = 'volumeETH',
  VolumeUsd = 'volumeUSD',
  VolumeUsdUntracked = 'volumeUSDUntracked',
  FeesUsd = 'feesUSD',
  TxCount = 'txCount',
  TvlUsd = 'tvlUSD'
}

export type YieldAggregator = KodiakProtocol & {
  __typename?: 'YieldAggregator';
  /**  Smart contract address of the protocol's main contract (Factory, Registry, etc)  */
  id: Scalars['ID'];
  /**  Name of the protocol, including version. e.g. Yearn v3  */
  name: Scalars['String'];
  /**  Slug of protocol, including version. e.g. yearn-v3  */
  slug: Scalars['String'];
  /**  Version of the subgraph schema, in SemVer format (e.g. 1.0.0)  */
  schemaVersion: Scalars['String'];
  /**  Version of the subgraph implementation, in SemVer format (e.g. 1.0.0)  */
  subgraphVersion: Scalars['String'];
  /**  Version of the methodology used to compute metrics, loosely based on SemVer format (e.g. 1.0.0)  */
  methodologyVersion: Scalars['String'];
  /**  The blockchain network this subgraph is indexing on  */
  network: Network;
  /**  The type of protocol (e.g. DEX, Lending, Yield, etc)  */
  type: KodiakProtocolType;
  /**  Current TVL (Total Value Locked) of the entire protocol  */
  totalValueLockedUSD: Scalars['BigDecimal'];
  /**  Current PCV (Protocol Controlled Value). Only relevant for protocols with PCV.  */
  protocolControlledValueUSD?: Maybe<Scalars['BigDecimal']>;
  /**  Revenue claimed by suppliers to the protocol. LPs on DEXs (e.g. 0.25% of the swap fee in Sushiswap). Depositors on Lending Protocols. NFT sellers on OpenSea.  */
  cumulativeSupplySideRevenueUSD: Scalars['BigDecimal'];
  /**  Gross revenue for the protocol (revenue claimed by protocol). Examples: AMM protocol fee (Sushi’s 0.05%). OpenSea 10% sell fee.  */
  cumulativeProtocolSideRevenueUSD: Scalars['BigDecimal'];
  /**  All revenue generated by the protocol. e.g. 0.30% of swap fee in Sushiswap, all yield generated by Yearn.  */
  cumulativeTotalRevenueUSD: Scalars['BigDecimal'];
  /**  Number of cumulative unique users  */
  cumulativeUniqueUsers: Scalars['Int'];
  /**  Total number of pools  */
  totalPoolCount: Scalars['Int'];
  /**  Daily usage metrics for this protocol  */
  dailyUsageMetrics: Array<KodiakUsageMetricsDailySnapshot>;
  /**  Hourly usage metrics for this protocol  */
  hourlyUsageMetrics: Array<KodiakUsageMetricsHourlySnapshot>;
  /**  Daily financial metrics for this protocol  */
  financialMetrics: Array<KodiakFinancialsDailySnapshot>;
  /**  All vaults that belong to this protocol  */
  vaults: Array<KodiakVault>;
  /**  Array of all vault IDs for iteration  */
  _vaultIDs?: Maybe<Array<Scalars['String']>>;
};


export type YieldAggregatorDailyUsageMetricsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakUsageMetricsDailySnapshot_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakUsageMetricsDailySnapshot_Filter>;
};


export type YieldAggregatorHourlyUsageMetricsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakUsageMetricsHourlySnapshot_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakUsageMetricsHourlySnapshot_Filter>;
};


export type YieldAggregatorFinancialMetricsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakFinancialsDailySnapshot_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakFinancialsDailySnapshot_Filter>;
};


export type YieldAggregatorVaultsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<KodiakVault_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<KodiakVault_Filter>;
};

export type YieldAggregator_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_lt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_contains?: Maybe<Scalars['String']>;
  name_contains_nocase?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_not_contains_nocase?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_starts_with_nocase?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with_nocase?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_ends_with_nocase?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with_nocase?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_contains_nocase?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_not_contains_nocase?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_starts_with_nocase?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with_nocase?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_ends_with_nocase?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with_nocase?: Maybe<Scalars['String']>;
  schemaVersion?: Maybe<Scalars['String']>;
  schemaVersion_not?: Maybe<Scalars['String']>;
  schemaVersion_gt?: Maybe<Scalars['String']>;
  schemaVersion_lt?: Maybe<Scalars['String']>;
  schemaVersion_gte?: Maybe<Scalars['String']>;
  schemaVersion_lte?: Maybe<Scalars['String']>;
  schemaVersion_in?: Maybe<Array<Scalars['String']>>;
  schemaVersion_not_in?: Maybe<Array<Scalars['String']>>;
  schemaVersion_contains?: Maybe<Scalars['String']>;
  schemaVersion_contains_nocase?: Maybe<Scalars['String']>;
  schemaVersion_not_contains?: Maybe<Scalars['String']>;
  schemaVersion_not_contains_nocase?: Maybe<Scalars['String']>;
  schemaVersion_starts_with?: Maybe<Scalars['String']>;
  schemaVersion_starts_with_nocase?: Maybe<Scalars['String']>;
  schemaVersion_not_starts_with?: Maybe<Scalars['String']>;
  schemaVersion_not_starts_with_nocase?: Maybe<Scalars['String']>;
  schemaVersion_ends_with?: Maybe<Scalars['String']>;
  schemaVersion_ends_with_nocase?: Maybe<Scalars['String']>;
  schemaVersion_not_ends_with?: Maybe<Scalars['String']>;
  schemaVersion_not_ends_with_nocase?: Maybe<Scalars['String']>;
  subgraphVersion?: Maybe<Scalars['String']>;
  subgraphVersion_not?: Maybe<Scalars['String']>;
  subgraphVersion_gt?: Maybe<Scalars['String']>;
  subgraphVersion_lt?: Maybe<Scalars['String']>;
  subgraphVersion_gte?: Maybe<Scalars['String']>;
  subgraphVersion_lte?: Maybe<Scalars['String']>;
  subgraphVersion_in?: Maybe<Array<Scalars['String']>>;
  subgraphVersion_not_in?: Maybe<Array<Scalars['String']>>;
  subgraphVersion_contains?: Maybe<Scalars['String']>;
  subgraphVersion_contains_nocase?: Maybe<Scalars['String']>;
  subgraphVersion_not_contains?: Maybe<Scalars['String']>;
  subgraphVersion_not_contains_nocase?: Maybe<Scalars['String']>;
  subgraphVersion_starts_with?: Maybe<Scalars['String']>;
  subgraphVersion_starts_with_nocase?: Maybe<Scalars['String']>;
  subgraphVersion_not_starts_with?: Maybe<Scalars['String']>;
  subgraphVersion_not_starts_with_nocase?: Maybe<Scalars['String']>;
  subgraphVersion_ends_with?: Maybe<Scalars['String']>;
  subgraphVersion_ends_with_nocase?: Maybe<Scalars['String']>;
  subgraphVersion_not_ends_with?: Maybe<Scalars['String']>;
  subgraphVersion_not_ends_with_nocase?: Maybe<Scalars['String']>;
  methodologyVersion?: Maybe<Scalars['String']>;
  methodologyVersion_not?: Maybe<Scalars['String']>;
  methodologyVersion_gt?: Maybe<Scalars['String']>;
  methodologyVersion_lt?: Maybe<Scalars['String']>;
  methodologyVersion_gte?: Maybe<Scalars['String']>;
  methodologyVersion_lte?: Maybe<Scalars['String']>;
  methodologyVersion_in?: Maybe<Array<Scalars['String']>>;
  methodologyVersion_not_in?: Maybe<Array<Scalars['String']>>;
  methodologyVersion_contains?: Maybe<Scalars['String']>;
  methodologyVersion_contains_nocase?: Maybe<Scalars['String']>;
  methodologyVersion_not_contains?: Maybe<Scalars['String']>;
  methodologyVersion_not_contains_nocase?: Maybe<Scalars['String']>;
  methodologyVersion_starts_with?: Maybe<Scalars['String']>;
  methodologyVersion_starts_with_nocase?: Maybe<Scalars['String']>;
  methodologyVersion_not_starts_with?: Maybe<Scalars['String']>;
  methodologyVersion_not_starts_with_nocase?: Maybe<Scalars['String']>;
  methodologyVersion_ends_with?: Maybe<Scalars['String']>;
  methodologyVersion_ends_with_nocase?: Maybe<Scalars['String']>;
  methodologyVersion_not_ends_with?: Maybe<Scalars['String']>;
  methodologyVersion_not_ends_with_nocase?: Maybe<Scalars['String']>;
  network?: Maybe<Network>;
  network_not?: Maybe<Network>;
  network_in?: Maybe<Array<Network>>;
  network_not_in?: Maybe<Array<Network>>;
  type?: Maybe<KodiakProtocolType>;
  type_not?: Maybe<KodiakProtocolType>;
  type_in?: Maybe<Array<KodiakProtocolType>>;
  type_not_in?: Maybe<Array<KodiakProtocolType>>;
  totalValueLockedUSD?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_not?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_gt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_lt?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_gte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_lte?: Maybe<Scalars['BigDecimal']>;
  totalValueLockedUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  totalValueLockedUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  protocolControlledValueUSD?: Maybe<Scalars['BigDecimal']>;
  protocolControlledValueUSD_not?: Maybe<Scalars['BigDecimal']>;
  protocolControlledValueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  protocolControlledValueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  protocolControlledValueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  protocolControlledValueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  protocolControlledValueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  protocolControlledValueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeSupplySideRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  cumulativeSupplySideRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeSupplySideRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeProtocolSideRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  cumulativeProtocolSideRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeProtocolSideRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeTotalRevenueUSD?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_not?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_gt?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_lt?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_gte?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_lte?: Maybe<Scalars['BigDecimal']>;
  cumulativeTotalRevenueUSD_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeTotalRevenueUSD_not_in?: Maybe<Array<Scalars['BigDecimal']>>;
  cumulativeUniqueUsers?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_not?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_gt?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_lt?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_gte?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_lte?: Maybe<Scalars['Int']>;
  cumulativeUniqueUsers_in?: Maybe<Array<Scalars['Int']>>;
  cumulativeUniqueUsers_not_in?: Maybe<Array<Scalars['Int']>>;
  totalPoolCount?: Maybe<Scalars['Int']>;
  totalPoolCount_not?: Maybe<Scalars['Int']>;
  totalPoolCount_gt?: Maybe<Scalars['Int']>;
  totalPoolCount_lt?: Maybe<Scalars['Int']>;
  totalPoolCount_gte?: Maybe<Scalars['Int']>;
  totalPoolCount_lte?: Maybe<Scalars['Int']>;
  totalPoolCount_in?: Maybe<Array<Scalars['Int']>>;
  totalPoolCount_not_in?: Maybe<Array<Scalars['Int']>>;
  dailyUsageMetrics_?: Maybe<KodiakUsageMetricsDailySnapshot_Filter>;
  hourlyUsageMetrics_?: Maybe<KodiakUsageMetricsHourlySnapshot_Filter>;
  financialMetrics_?: Maybe<KodiakFinancialsDailySnapshot_Filter>;
  vaults_?: Maybe<KodiakVault_Filter>;
  _vaultIDs?: Maybe<Array<Scalars['String']>>;
  _vaultIDs_not?: Maybe<Array<Scalars['String']>>;
  _vaultIDs_contains?: Maybe<Array<Scalars['String']>>;
  _vaultIDs_contains_nocase?: Maybe<Array<Scalars['String']>>;
  _vaultIDs_not_contains?: Maybe<Array<Scalars['String']>>;
  _vaultIDs_not_contains_nocase?: Maybe<Array<Scalars['String']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<YieldAggregator_Filter>>>;
  or?: Maybe<Array<Maybe<YieldAggregator_Filter>>>;
};

export enum YieldAggregator_OrderBy {
  Id = 'id',
  Name = 'name',
  Slug = 'slug',
  SchemaVersion = 'schemaVersion',
  SubgraphVersion = 'subgraphVersion',
  MethodologyVersion = 'methodologyVersion',
  Network = 'network',
  Type = 'type',
  TotalValueLockedUsd = 'totalValueLockedUSD',
  ProtocolControlledValueUsd = 'protocolControlledValueUSD',
  CumulativeSupplySideRevenueUsd = 'cumulativeSupplySideRevenueUSD',
  CumulativeProtocolSideRevenueUsd = 'cumulativeProtocolSideRevenueUSD',
  CumulativeTotalRevenueUsd = 'cumulativeTotalRevenueUSD',
  CumulativeUniqueUsers = 'cumulativeUniqueUsers',
  TotalPoolCount = 'totalPoolCount',
  DailyUsageMetrics = 'dailyUsageMetrics',
  HourlyUsageMetrics = 'hourlyUsageMetrics',
  FinancialMetrics = 'financialMetrics',
  Vaults = 'vaults',
  VaultIDs = '_vaultIDs'
}

export type _Block_ = {
  __typename?: '_Block_';
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>;
  /** The block number */
  number: Scalars['Int'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']>;
  /** The hash of the parent block */
  parentHash?: Maybe<Scalars['Bytes']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: '_Meta_';
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean'];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = 'allow',
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = 'deny'
}

export type ProtocolDataV3QueryVariables = KodiakV3.Exact<{ [key: string]: never; }>;


export type ProtocolDataV3Query = (
  { __typename?: 'Query' }
  & { uniswapDayDatas: Array<(
    { __typename?: 'UniswapDayData' }
    & Pick<KodiakV3.UniswapDayData, 'date' | 'volumeUSD' | 'feesUSD' | 'tvlUSD'>
  )> }
);

export type GetVaultSnapshotsQueryVariables = KodiakV3.Exact<{
  islandId: KodiakV3.Scalars['ID'];
}>;


export type GetVaultSnapshotsQuery = (
  { __typename?: 'Query' }
  & { kodiakVaultDailySnapshots: Array<(
    { __typename?: 'KodiakVaultDailySnapshot' }
    & Pick<KodiakV3.KodiakVaultDailySnapshot, 'id' | 'timestamp' | 'totalValueLockedUSD' | '_token0Amount' | '_token1Amount' | '_token0AmountUSD' | '_token1AmountUSD' | 'upperTick' | 'lowerTick' | 'volumeUSD' | 'apr' | 'outputTokenPriceUSD'>
    & { vault: (
      { __typename?: 'KodiakVault' }
      & { apr: (
        { __typename?: 'KodiakApr' }
        & Pick<KodiakV3.KodiakApr, 'averageApr'>
      ), pool?: KodiakV3.Maybe<(
        { __typename?: 'Pool' }
        & Pick<KodiakV3.Pool, 'volumeUSD' | 'volumeToken0' | 'volumeToken1' | 'tick'>
      )>, _token0: (
        { __typename?: 'Token' }
        & Pick<KodiakV3.Token, 'id' | 'symbol' | 'volume' | 'volumeUSD' | 'totalValueLocked' | 'totalValueLockedUSD'>
      ), _token1: (
        { __typename?: 'Token' }
        & Pick<KodiakV3.Token, 'id' | 'symbol' | 'volume' | 'volumeUSD' | 'totalValueLocked' | 'totalValueLockedUSD'>
      ) }
    ) }
  )> }
);

export type TokenPriceQueryVariables = KodiakV3.Exact<{
  tokenId: KodiakV3.Scalars['ID'];
}>;


export type TokenPriceQuery = (
  { __typename?: 'Query' }
  & { token?: KodiakV3.Maybe<(
    { __typename?: 'Token' }
    & Pick<KodiakV3.Token, 'id' | 'derivedETH'>
  )>, bundle?: KodiakV3.Maybe<(
    { __typename?: 'Bundle' }
    & Pick<KodiakV3.Bundle, 'ethPriceUSD'>
  )> }
);

export type TokenFieldsFragment = (
  { __typename?: 'Token' }
  & Pick<KodiakV3.Token, 'id' | 'symbol' | 'name' | 'decimals' | 'totalSupply' | 'volume' | 'volumeUSD' | 'untrackedVolumeUSD' | 'feesUSD' | 'txCount' | 'poolCount' | 'totalValueLocked' | 'totalValueLockedUSD' | 'totalValueLockedUSDUntracked' | 'derivedETH'>
);

export type PoolFieldsFragment = (
  { __typename?: 'Pool' }
  & Pick<KodiakV3.Pool, 'id' | 'createdAtTimestamp' | 'createdAtBlockNumber' | 'feeTier' | 'liquidity' | 'sqrtPrice' | 'feeGrowthGlobal0X128' | 'feeGrowthGlobal1X128' | 'token0Price' | 'token1Price' | 'tick' | 'observationIndex' | 'volumeToken0' | 'volumeToken1' | 'volumeUSD' | 'untrackedVolumeUSD' | 'feesUSD' | 'txCount' | 'collectedFeesToken0' | 'collectedFeesToken1' | 'collectedFeesUSD' | 'totalValueLockedToken0' | 'totalValueLockedToken1' | 'totalValueLockedETH' | 'totalValueLockedUSD' | 'totalValueLockedUSDUntracked' | 'liquidityProviderCount'>
);

export type AllV3TicksQueryVariables = KodiakV3.Exact<{
  poolAddress: KodiakV3.Scalars['String'];
  skip: KodiakV3.Scalars['Int'];
}>;


export type AllV3TicksQuery = (
  { __typename?: 'Query' }
  & { ticks: Array<(
    { __typename?: 'Tick' }
    & Pick<KodiakV3.Tick, 'liquidityNet' | 'price0' | 'price1'>
    & { tick: KodiakV3.Tick['tickIdx'] }
  )> }
);

export type FeeTierDistributionQueryVariables = KodiakV3.Exact<{
  token0: KodiakV3.Scalars['String'];
  token1: KodiakV3.Scalars['String'];
}>;


export type FeeTierDistributionQuery = (
  { __typename?: 'Query' }
  & { _meta?: KodiakV3.Maybe<(
    { __typename?: '_Meta_' }
    & { block: (
      { __typename?: '_Block_' }
      & Pick<KodiakV3._Block_, 'number'>
    ) }
  )>, asToken0: Array<(
    { __typename?: 'Pool' }
    & Pick<KodiakV3.Pool, 'feeTier' | 'totalValueLockedToken0' | 'totalValueLockedToken1'>
  )>, asToken1: Array<(
    { __typename?: 'Pool' }
    & Pick<KodiakV3.Pool, 'feeTier' | 'totalValueLockedToken0' | 'totalValueLockedToken1'>
  )> }
);

export type KodiakFinancialSnapshotQueryVariables = KodiakV3.Exact<{ [key: string]: never; }>;


export type KodiakFinancialSnapshotQuery = (
  { __typename?: 'Query' }
  & { kodiakFinancialsDailySnapshots: Array<(
    { __typename?: 'KodiakFinancialsDailySnapshot' }
    & Pick<KodiakV3.KodiakFinancialsDailySnapshot, 'id' | 'totalValueLockedUSD' | 'protocolControlledValueUSD' | 'dailySupplySideRevenueUSD' | 'cumulativeSupplySideRevenueUSD' | 'dailyProtocolSideRevenueUSD' | 'cumulativeProtocolSideRevenueUSD' | 'dailyTotalRevenueUSD' | 'cumulativeTotalRevenueUSD' | 'blockNumber' | 'timestamp'>
  )> }
);

export type KodiakIslandProtocolQueryVariables = KodiakV3.Exact<{
  id: KodiakV3.Scalars['ID'];
}>;


export type KodiakIslandProtocolQuery = (
  { __typename?: 'Query' }
  & { kodiakProtocol?: KodiakV3.Maybe<(
    { __typename?: 'YieldAggregator' }
    & Pick<KodiakV3.YieldAggregator, 'id' | 'name' | 'slug' | 'schemaVersion' | 'subgraphVersion' | 'methodologyVersion' | 'network' | 'type' | 'totalValueLockedUSD' | 'protocolControlledValueUSD' | 'cumulativeSupplySideRevenueUSD' | 'cumulativeProtocolSideRevenueUSD' | 'cumulativeTotalRevenueUSD' | 'cumulativeUniqueUsers' | 'totalPoolCount'>
  )> }
);

export type ProtocolDayDatasQueryVariables = KodiakV3.Exact<{ [key: string]: never; }>;


export type ProtocolDayDatasQuery = (
  { __typename?: 'Query' }
  & { uniswapDayDatas: Array<(
    { __typename?: 'UniswapDayData' }
    & Pick<KodiakV3.UniswapDayData, 'date' | 'volumeUSD' | 'feesUSD' | 'tvlUSD'>
  )> }
);

export type GetAllIslandsQueryVariables = KodiakV3.Exact<{
  allowedIds: Array<KodiakV3.Scalars['ID']> | KodiakV3.Scalars['ID'];
}>;


export type GetAllIslandsQuery = (
  { __typename?: 'Query' }
  & { kodiakVaults: Array<(
    { __typename?: 'KodiakVault' }
    & Pick<KodiakV3.KodiakVault, 'id' | 'name' | 'symbol' | 'depositLimit' | 'createdTimestamp' | 'createdBlockNumber' | 'totalValueLockedUSD' | 'cumulativeSupplySideRevenueUSD' | 'cumulativeProtocolSideRevenueUSD' | 'cumulativeTotalRevenueUSD' | 'inputTokenBalance' | 'outputTokenSupply' | 'outputTokenPriceUSD' | 'pricePerShare' | 'stakedOutputTokenAmount' | 'rewardTokenEmissionsAmount' | 'rewardTokenEmissionsUSD' | 'volumeToken0' | 'volumeToken1' | 'volumeUSD' | '_token0Amount' | '_token1Amount' | '_token0AmountUSD' | '_token1AmountUSD'>
    & { _token0: (
      { __typename?: 'Token' }
      & TokenFieldsFragment
    ), _token1: (
      { __typename?: 'Token' }
      & TokenFieldsFragment
    ), inputToken: (
      { __typename?: 'Token' }
      & TokenFieldsFragment
    ), outputToken?: KodiakV3.Maybe<(
      { __typename?: 'Token' }
      & TokenFieldsFragment
    )>, rewardTokens?: KodiakV3.Maybe<Array<(
      { __typename?: 'KodiakRewardToken' }
      & Pick<KodiakV3.KodiakRewardToken, 'type'>
      & { token: (
        { __typename?: 'Token' }
        & TokenFieldsFragment
      ) }
    )>>, fees: Array<(
      { __typename?: 'KodiakVaultFee' }
      & Pick<KodiakV3.KodiakVaultFee, 'id' | 'feePercentage' | 'feeType'>
    )>, pool?: KodiakV3.Maybe<(
      { __typename?: 'Pool' }
      & PoolFieldsFragment
    )>, apr: (
      { __typename?: 'KodiakApr' }
      & Pick<KodiakV3.KodiakApr, 'id' | 'averageApr' | 'timestamp'>
    ), dailySnapshots: Array<(
      { __typename?: 'KodiakVaultDailySnapshot' }
      & Pick<KodiakV3.KodiakVaultDailySnapshot, 'timestamp' | 'volumeUSD'>
    )> }
  )> }
);

export type GetIslandByIdQueryVariables = KodiakV3.Exact<{
  id: KodiakV3.Scalars['ID'];
}>;


export type GetIslandByIdQuery = (
  { __typename?: 'Query' }
  & { kodiakVault?: KodiakV3.Maybe<(
    { __typename?: 'KodiakVault' }
    & Pick<KodiakV3.KodiakVault, 'id' | 'name' | 'symbol' | 'depositLimit' | 'createdTimestamp' | 'createdBlockNumber' | 'totalValueLockedUSD' | 'cumulativeSupplySideRevenueUSD' | 'cumulativeProtocolSideRevenueUSD' | 'lowerTick' | 'upperTick' | 'cumulativeTotalRevenueUSD' | 'inputTokenBalance' | 'outputTokenSupply' | 'outputTokenPriceUSD' | 'pricePerShare' | 'stakedOutputTokenAmount' | 'rewardTokenEmissionsAmount' | 'rewardTokenEmissionsUSD' | 'volumeToken0' | 'volumeToken1' | 'volumeUSD' | '_token0Amount' | '_token1Amount' | '_token0AmountUSD' | '_token1AmountUSD'>
    & { _token0: (
      { __typename?: 'Token' }
      & TokenFieldsFragment
    ), _token1: (
      { __typename?: 'Token' }
      & TokenFieldsFragment
    ), inputToken: (
      { __typename?: 'Token' }
      & TokenFieldsFragment
    ), outputToken?: KodiakV3.Maybe<(
      { __typename?: 'Token' }
      & TokenFieldsFragment
    )>, rewardTokens?: KodiakV3.Maybe<Array<(
      { __typename?: 'KodiakRewardToken' }
      & Pick<KodiakV3.KodiakRewardToken, 'type'>
      & { token: (
        { __typename?: 'Token' }
        & TokenFieldsFragment
      ) }
    )>>, fees: Array<(
      { __typename?: 'KodiakVaultFee' }
      & Pick<KodiakV3.KodiakVaultFee, 'id' | 'feePercentage' | 'feeType'>
    )>, pool?: KodiakV3.Maybe<(
      { __typename?: 'Pool' }
      & PoolFieldsFragment
    )>, apr: (
      { __typename?: 'KodiakApr' }
      & Pick<KodiakV3.KodiakApr, 'id' | 'averageApr' | 'timestamp'>
    ), dailySnapshots: Array<(
      { __typename?: 'KodiakVaultDailySnapshot' }
      & Pick<KodiakV3.KodiakVaultDailySnapshot, 'timestamp' | 'volumeUSD' | 'totalValueLockedUSD'>
    )> }
  )> }
);

export const TokenFieldsFragmentDoc = `
    fragment TokenFields on Token {
  id
  symbol
  name
  decimals
  totalSupply
  volume
  volumeUSD
  untrackedVolumeUSD
  feesUSD
  txCount
  poolCount
  totalValueLocked
  totalValueLockedUSD
  totalValueLockedUSDUntracked
  derivedETH
}
    `;
export const PoolFieldsFragmentDoc = `
    fragment PoolFields on Pool {
  id
  createdAtTimestamp
  createdAtBlockNumber
  feeTier
  liquidity
  sqrtPrice
  feeGrowthGlobal0X128
  feeGrowthGlobal1X128
  token0Price
  token1Price
  tick
  observationIndex
  volumeToken0
  volumeToken1
  volumeUSD
  untrackedVolumeUSD
  feesUSD
  txCount
  collectedFeesToken0
  collectedFeesToken1
  collectedFeesUSD
  totalValueLockedToken0
  totalValueLockedToken1
  totalValueLockedETH
  totalValueLockedUSD
  totalValueLockedUSDUntracked
  liquidityProviderCount
}
    `;
export const ProtocolDataV3Document = `
    query protocolDataV3 {
  uniswapDayDatas {
    date
    volumeUSD
    feesUSD
    tvlUSD
  }
}
    `;
export const GetVaultSnapshotsDocument = `
    query GetVaultSnapshots($islandId: ID!) {
  kodiakVaultDailySnapshots(where: {vault_: {id: $islandId}}) {
    id
    timestamp
    totalValueLockedUSD
    _token0Amount
    _token1Amount
    _token0AmountUSD
    _token1AmountUSD
    upperTick
    lowerTick
    volumeUSD
    apr
    outputTokenPriceUSD
    volumeUSD
    vault {
      apr {
        averageApr
      }
      pool {
        volumeUSD
        volumeToken0
        volumeToken1
        tick
      }
      _token0 {
        id
        symbol
        volume
        volumeUSD
        totalValueLocked
        totalValueLockedUSD
      }
      _token1 {
        id
        symbol
        volume
        volumeUSD
        totalValueLocked
        totalValueLockedUSD
      }
    }
  }
}
    `;
export const TokenPriceDocument = `
    query tokenPrice($tokenId: ID!) {
  token(id: $tokenId) {
    id
    derivedETH
  }
  bundle(id: "1") {
    ethPriceUSD
  }
}
    `;
export const AllV3TicksDocument = `
    query allV3Ticks($poolAddress: String!, $skip: Int!) {
  ticks(
    first: 1000
    skip: $skip
    where: {poolAddress: $poolAddress}
    orderBy: tickIdx
  ) {
    tick: tickIdx
    liquidityNet
    price0
    price1
  }
}
    `;
export const FeeTierDistributionDocument = `
    query feeTierDistribution($token0: String!, $token1: String!) {
  _meta {
    block {
      number
    }
  }
  asToken0: pools(
    orderBy: totalValueLockedToken0
    orderDirection: desc
    where: {token0: $token0, token1: $token1}
  ) {
    feeTier
    totalValueLockedToken0
    totalValueLockedToken1
  }
  asToken1: pools(
    orderBy: totalValueLockedToken0
    orderDirection: desc
    where: {token0: $token1, token1: $token0}
  ) {
    feeTier
    totalValueLockedToken0
    totalValueLockedToken1
  }
}
    `;
export const KodiakFinancialSnapshotDocument = `
    query kodiakFinancialSnapshot {
  kodiakFinancialsDailySnapshots(first: 1) {
    id
    totalValueLockedUSD
    protocolControlledValueUSD
    dailySupplySideRevenueUSD
    cumulativeSupplySideRevenueUSD
    dailyProtocolSideRevenueUSD
    cumulativeProtocolSideRevenueUSD
    dailyTotalRevenueUSD
    cumulativeTotalRevenueUSD
    blockNumber
    timestamp
  }
}
    `;
export const KodiakIslandProtocolDocument = `
    query kodiakIslandProtocol($id: ID!) {
  kodiakProtocol(id: $id) {
    id
    name
    slug
    schemaVersion
    subgraphVersion
    methodologyVersion
    network
    type
    totalValueLockedUSD
    protocolControlledValueUSD
    cumulativeSupplySideRevenueUSD
    cumulativeProtocolSideRevenueUSD
    cumulativeTotalRevenueUSD
    cumulativeUniqueUsers
    totalPoolCount
  }
}
    `;
export const ProtocolDayDatasDocument = `
    query protocolDayDatas {
  uniswapDayDatas {
    date
    volumeUSD
    feesUSD
    tvlUSD
  }
}
    `;
export const GetAllIslandsDocument = `
    query getAllIslands($allowedIds: [ID!]!) {
  kodiakVaults(where: {id_in: $allowedIds}, orderBy: apr__averageApr) {
    id
    name
    symbol
    depositLimit
    createdTimestamp
    createdBlockNumber
    totalValueLockedUSD
    cumulativeSupplySideRevenueUSD
    cumulativeProtocolSideRevenueUSD
    cumulativeTotalRevenueUSD
    inputTokenBalance
    outputTokenSupply
    outputTokenPriceUSD
    pricePerShare
    stakedOutputTokenAmount
    rewardTokenEmissionsAmount
    rewardTokenEmissionsUSD
    volumeToken0
    volumeToken1
    volumeUSD
    _token0Amount
    _token1Amount
    _token0AmountUSD
    _token1AmountUSD
    _token0 {
      ...TokenFields
    }
    _token1 {
      ...TokenFields
    }
    inputToken {
      ...TokenFields
    }
    outputToken {
      ...TokenFields
    }
    rewardTokens {
      token {
        ...TokenFields
      }
      type
    }
    fees {
      id
      feePercentage
      feeType
    }
    pool {
      ...PoolFields
    }
    apr {
      id
      averageApr
      timestamp
    }
    dailySnapshots {
      timestamp
      volumeUSD
    }
  }
}
    ${TokenFieldsFragmentDoc}
${PoolFieldsFragmentDoc}`;
export const GetIslandByIdDocument = `
    query getIslandById($id: ID!) {
  kodiakVault(id: $id) {
    id
    name
    symbol
    depositLimit
    createdTimestamp
    createdBlockNumber
    totalValueLockedUSD
    cumulativeSupplySideRevenueUSD
    cumulativeProtocolSideRevenueUSD
    lowerTick
    upperTick
    cumulativeTotalRevenueUSD
    inputTokenBalance
    outputTokenSupply
    outputTokenPriceUSD
    pricePerShare
    stakedOutputTokenAmount
    rewardTokenEmissionsAmount
    rewardTokenEmissionsUSD
    volumeToken0
    volumeToken1
    volumeUSD
    _token0Amount
    _token1Amount
    _token0AmountUSD
    _token1AmountUSD
    _token0 {
      ...TokenFields
    }
    _token1 {
      ...TokenFields
    }
    inputToken {
      ...TokenFields
    }
    outputToken {
      ...TokenFields
    }
    rewardTokens {
      token {
        ...TokenFields
      }
      type
    }
    fees {
      id
      feePercentage
      feeType
    }
    pool {
      ...PoolFields
    }
    apr {
      id
      averageApr
      timestamp
    }
    dailySnapshots {
      timestamp
      volumeUSD
      totalValueLockedUSD
    }
  }
}
    ${TokenFieldsFragmentDoc}
${PoolFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    protocolDataV3: build.query<ProtocolDataV3Query, ProtocolDataV3QueryVariables | void>({
      query: (variables) => ({ document: ProtocolDataV3Document, variables })
    }),
    GetVaultSnapshots: build.query<GetVaultSnapshotsQuery, GetVaultSnapshotsQueryVariables>({
      query: (variables) => ({ document: GetVaultSnapshotsDocument, variables })
    }),
    tokenPrice: build.query<TokenPriceQuery, TokenPriceQueryVariables>({
      query: (variables) => ({ document: TokenPriceDocument, variables })
    }),
    allV3Ticks: build.query<AllV3TicksQuery, AllV3TicksQueryVariables>({
      query: (variables) => ({ document: AllV3TicksDocument, variables })
    }),
    feeTierDistribution: build.query<FeeTierDistributionQuery, FeeTierDistributionQueryVariables>({
      query: (variables) => ({ document: FeeTierDistributionDocument, variables })
    }),
    kodiakFinancialSnapshot: build.query<KodiakFinancialSnapshotQuery, KodiakFinancialSnapshotQueryVariables | void>({
      query: (variables) => ({ document: KodiakFinancialSnapshotDocument, variables })
    }),
    kodiakIslandProtocol: build.query<KodiakIslandProtocolQuery, KodiakIslandProtocolQueryVariables>({
      query: (variables) => ({ document: KodiakIslandProtocolDocument, variables })
    }),
    protocolDayDatas: build.query<ProtocolDayDatasQuery, ProtocolDayDatasQueryVariables | void>({
      query: (variables) => ({ document: ProtocolDayDatasDocument, variables })
    }),
    getAllIslands: build.query<GetAllIslandsQuery, GetAllIslandsQueryVariables>({
      query: (variables) => ({ document: GetAllIslandsDocument, variables })
    }),
    getIslandById: build.query<GetIslandByIdQuery, GetIslandByIdQueryVariables>({
      query: (variables) => ({ document: GetIslandByIdDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useProtocolDataV3Query, useLazyProtocolDataV3Query, useGetVaultSnapshotsQuery, useLazyGetVaultSnapshotsQuery, useTokenPriceQuery, useLazyTokenPriceQuery, useAllV3TicksQuery, useLazyAllV3TicksQuery, useFeeTierDistributionQuery, useLazyFeeTierDistributionQuery, useKodiakFinancialSnapshotQuery, useLazyKodiakFinancialSnapshotQuery, useKodiakIslandProtocolQuery, useLazyKodiakIslandProtocolQuery, useProtocolDayDatasQuery, useLazyProtocolDayDatasQuery, useGetAllIslandsQuery, useLazyGetAllIslandsQuery, useGetIslandByIdQuery, useLazyGetIslandByIdQuery } = injectedRtkApi;

