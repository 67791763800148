import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { ProtocolDataV3Type, setProtocolDataV3, setProtocolDataV3Loading } from '../actions'

export const useSetProtocolDataV3 = () => {
  const dispatch = useAppDispatch()

  const setProtocolData = useCallback(
    (protocolDataV3: ProtocolDataV3Type) => {
      dispatch(setProtocolDataV3(protocolDataV3))
    },
    [dispatch]
  )

  const setLoading = useCallback(
    (loading: boolean) => {
      dispatch(setProtocolDataV3Loading(loading))
    },
    [dispatch]
  )

  return { setProtocolData, setLoading }
}

export const useProtocolDataV3State = () => {
  return useAppSelector((state) => state.protocolData.protocolDataV3)
}
