import { Token } from '@kodiak-finance/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { setApprovalCache } from './actions'

export const useApprovalCache = (token?: Token | string, spender?: string) => {
  const tokenAddress = typeof token === 'string' ? token : token?.address
  const { chainId, account } = useWeb3React()
  const dispatch = useAppDispatch()

  const existsCache =
    useAppSelector((state) => {
      if (!chainId || !account || !tokenAddress || !spender) return undefined
      return state.approvalCache[chainId]?.[account]?.[spender]?.[tokenAddress]
    }) || false

  const confirm = useCallback(() => {
    if (!chainId || !account || !tokenAddress || !spender) return

    dispatch(
      setApprovalCache({
        address: account,
        chainId,
        tokenAddress,
        spender,
        cache: true,
      })
    )
  }, [dispatch, chainId, account, spender, tokenAddress])

  return [existsCache, confirm] as const
}
