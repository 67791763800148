import { CurrencyAmount, Token } from '@kodiak-finance/sdk-core'
import { ButtonPrimary } from 'components/Button'
import Column from 'components/Column'
import CurrencyInputPanel, { BalanceFlex } from 'components/CurrencyInputPanel'
import { useStepLineStateUpdate } from 'components/StepLine/context/StepLineContext'
import { useToken } from 'hooks/Tokens'
import { useXKDK } from 'hooks/useContract'
import { useDividends } from 'hooks/useStaking'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { useMemo, useState } from 'react'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'

import { ReactComponent as Wallet } from '../../assets/svg/icons/Wallet.svg'
export type DeallocationStepState = {
  amount: CurrencyAmount<Token> | undefined
}
const DeallocateViewStep = ({ next }: { next: () => void }) => {
  const [inputKdk, setInputKdk] = useState('')
  const xKdk = useXKDK()
  const xKdkToken = useToken(xKdk?.address)
  const { userUsageAllocation } = useDividends()

  const update = useStepLineStateUpdate<DeallocationStepState>()

  const amount = useMemo(() => {
    const amount = tryParseCurrencyAmount(inputKdk, xKdkToken || undefined)
    update({ amount })
    return amount
  }, [inputKdk, xKdkToken])

  return (
    <Column width="100%" gap="8px">
      <CurrencyInputPanel
        // hideBalance={true}
        value={inputKdk}
        onUserInput={setInputKdk}
        onMax={() => setInputKdk(userUsageAllocation?.toExact() ?? '0')}
        showMaxButton={true}
        currency={xKdkToken}
        renderBalance={() => (
          <BalanceFlex>
            <Wallet height={18} width={18} />
            <>{formatCurrencyAmount(userUsageAllocation, 4)}</>
          </BalanceFlex>
        )}
        label="kdk"
        id="convert-kdk"
      />
      <ButtonPrimary disabled={!amount || !amount.greaterThan('0')} onClick={next}>
        Deallocate
      </ButtonPrimary>
    </Column>
  )
}

export default DeallocateViewStep
