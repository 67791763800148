import { BigintIsh, CurrencyAmount, Token } from '@kodiak-finance/sdk-core'
import { useWeb3React } from '@web3-react/core'
import CommunalFarmABI from 'abis/communal-farm.json'
import { ISLANDS_TO_FARM } from 'constants/lists'
import { ethers } from 'ethers'
import { Interface } from 'ethers/lib/utils'
import { useMultipleContractSingleData, useSingleCallResult } from 'lib/hooks/multicall'
import sumCurrencyAmount from 'lib/utils/sumCurrencyAmount'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { farmsAddresses } from 'pages/Farms/constants'
import { useEffect, useMemo } from 'react'
import { setFarms, setLoading } from 'state/farms/actions'
import { Farm } from 'state/farms/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { useToken } from './Tokens'
import { useFarmContract } from './useContract'
import { useSubgraphTokenValue } from './useSubgraphTokenPrice'

export const ICommunalFarm = new Interface(CommunalFarmABI)
export const YEAR = 3600 * 24 * 365

export const useCommunalFarmByIsland = (island?: string) => {
  const farmAddress = useMemo(() => (island ? ISLANDS_TO_FARM[island.toLowerCase()] : undefined), [island])

  const farm = useCommunalFarm(farmAddress)
  if (farmAddress === undefined) {
    return undefined
  }

  return farm
}

export const useCalculationEarnings = ({
  inputValue,
  lockTimeValue,
  multiplier,
  farm,
}: {
  inputValue?: string | undefined | null
  lockTimeValue: number
  multiplier: number
  farm?: Farm | null
}): (CurrencyAmount<Token> | undefined)[] => {
  const stakingToken = useToken(farm?.stakingToken)
  const rewardTokens = farm?.rewardTokens.map(useToken) || []
  const rewardsDuration = parseFloat(farm?.rewardsDuration || '0')
  const rewardsPerDuration = useMemo(
    () =>
      rewardTokens.map((item, index) =>
        item && farm ? CurrencyAmount.fromRawAmount(item, farm.rewardsPerDuration[index]) : undefined
      ),
    [rewardTokens, farm]
  )

  const farmContract = useFarmContract(farm?.address)

  const totalCombinedWeightString = useSingleCallResult(farmContract, 'totalCombinedWeight')?.result?.[0].toString()

  const totalCombinedWeight = useMemo(() => parseFloat(totalCombinedWeightString), [totalCombinedWeightString])

  return useMemo(() => {
    const perYear = lockTimeValue === 0

    return rewardTokens.map((rewardToken, index) => {
      const rewardPerDuration = parseFloat(rewardsPerDuration[index]?.toExact() || '0')
      const rewardPerSecond = rewardPerDuration / rewardsDuration
      const rewardPerAllPeriod = rewardPerSecond * (perYear ? YEAR : lockTimeValue)
      const pendingWeight = parseFloat(inputValue || '0') * (10 ** (stakingToken?.decimals || 18) * multiplier)
      const totalCombinedWeightWithPending = totalCombinedWeight + pendingWeight
      if (totalCombinedWeightWithPending === 0) {
        return tryParseCurrencyAmount(rewardPerAllPeriod.toString(), rewardToken || undefined)
      }

      const reward = (rewardPerAllPeriod / totalCombinedWeightWithPending) * pendingWeight * multiplier
      return tryParseCurrencyAmount(reward.toFixed(rewardToken?.decimals || 18), rewardToken || undefined)
    })
  }, [rewardTokens, rewardsPerDuration, rewardsDuration, lockTimeValue, inputValue, multiplier, totalCombinedWeight])
}

export const useCommunalFarmYearEmission = (farm?: Farm) => {
  const farmContract = useFarmContract(farm?.address)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const rewardTokens = farm ? farm.rewardTokens.map((item) => useToken(item)) : []
  const rewards = useSingleCallResult(farmContract, 'getAllRewardRates')?.result?.[0] as string[] | undefined
  const yearRewards = rewards ? rewards.map((item) => parseFloat(item) * YEAR) : undefined

  return rewardTokens
    ?.map((item, index) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useSubgraphTokenValue(
        item && farm && yearRewards ? CurrencyAmount.fromRawAmount(item, yearRewards[index]) : undefined
      )
    )
    .reduce((memo, item) => (item === null ? null : item + (memo || 0)), 0)
}

export const useCommunalFarmAverageApr = (farm?: Farm) => {
  const emissionUSD = useCommunalFarmYearEmission(farm)
  const farmContract = useFarmContract(farm?.address)
  const lockedRaw = useSingleCallResult(farmContract, 'totalLiquidityLocked')?.result?.[0].toString()
  const stakingToken = useToken(farm?.stakingToken)
  const locked = stakingToken && lockedRaw ? CurrencyAmount.fromRawAmount(stakingToken, lockedRaw) : undefined
  const lockedUSD = useSubgraphTokenValue(locked)

  return ((emissionUSD || 0) / (lockedUSD || 1)) * 100
}

export const useCommunalFarmApr = (farm?: Farm, multiplier?: number, value?: CurrencyAmount<Token>) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const rewardTokens = farm ? farm.rewardTokens.map((item) => useToken(item)) : []
  const farmContract = useFarmContract(farm?.address)
  const totalCombinedWeightString =
    useSingleCallResult(farmContract, 'totalCombinedWeight')?.result?.[0].toString() || '1'
  const rewardsPerTokenValues = rewardTokens.map((item, index) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSubgraphTokenValue(item && farm ? CurrencyAmount.fromRawAmount(item, farm.rewardsPerDuration[index]) : undefined)
  )

  if (!farm) {
    return farm
  }

  const rewardPerTokenValueTotal = rewardsPerTokenValues.reduce(
    (memo, item) => (item === null ? null : item + (memo || 0)),
    0
  )
  if (rewardPerTokenValueTotal === null) {
    return null
  }
  const maxMultipilier = multiplier || farm.lockMultiplierMax / 1e18

  const userWeight = parseFloat(value?.quotient.toString() || '0') * maxMultipilier

  const totalCombinedWeight = (parseFloat(totalCombinedWeightString) || 1) + userWeight
  const rewardsDuration = parseInt(farm.rewardsDuration)
  const perYear = (rewardPerTokenValueTotal / rewardsDuration) * YEAR
  return (userWeight / totalCombinedWeight) * perYear
}

const farmAndAccountRequiredFunction =
  <T>(callback: (farm?: Farm | null, accountAddress?: string) => T) =>
  (farm?: Farm | null, accountAddress?: string) => {
    const { account: providedAccount } = useWeb3React()
    const account = useMemo(() => accountAddress || providedAccount, [accountAddress, providedAccount])

    return callback(farm, account)
  }

export type CommunalFarmStake = {
  kekId: string
  liquidity: CurrencyAmount<Token> | undefined
  endingTimestamp: Date
  lockMultiplier: number
}

export const useCommunalFarmEarned = farmAndAccountRequiredFunction((farm, account) => {
  const farmContract = useFarmContract(farm?.address)

  const { result, loading, error } = useSingleCallResult(farmContract, 'earned', [account])
  const rewardTokens = farm?.rewardTokens.map(useToken)

  return useMemo(() => {
    if (loading) {
      return null
    }
    if (error) {
      return undefined
    }
    const raws = (result as any[])[0] as BigintIsh[]
    const amounts = raws
      .map((raw, index) =>
        rewardTokens?.[index] ? CurrencyAmount.fromRawAmount(rewardTokens[index]!, raw) : undefined
      )
      .filter((item) => item !== undefined) as CurrencyAmount<Token>[]

    return amounts
  }, [result, loading, error])
})

export const useCommunalFarmLockedStakesOf = farmAndAccountRequiredFunction((farm, account) => {
  const farmContract = useFarmContract(farm?.address)

  const result = useSingleCallResult(farmContract, 'lockedStakesOf', [account])
  const token = useToken(farm?.stakingToken)

  const response = useMemo(() => {
    if (result.error || !result.valid) {
      return { stakes: undefined, totalLiquidity: undefined }
    }
    if (result.loading) {
      return { stakes: null, totalLiquidity: null }
    }

    const stakes = result.result?.[0]

    const items = stakes.map((stake: any) => {
      return {
        kekId: stake.kek_id,
        liquidity: token ? CurrencyAmount.fromRawAmount(token, stake.liquidity) : undefined,
        endingTimestamp: new Date(parseInt(stake.ending_timestamp.toString()) * 1000),
        lockMultiplier: parseFloat(stake.lock_multiplier.toString() || '0') / 1e18,
      }
    }) as CommunalFarmStake[]

    const totalLiquidity = token
      ? items.reduce(
          (memo, item) => (item.liquidity ? memo.add(item.liquidity) : memo),
          CurrencyAmount.fromRawAmount(token, 0)
        )
      : undefined

    const avaliableStakes = items.filter((item) => {
      return item.endingTimestamp.getTime() < Date.now() && item.liquidity && item.liquidity.greaterThan('0')
    })

    const totalAvailableLiquidity = sumCurrencyAmount(avaliableStakes.map((item) => item.liquidity))

    return { stakes: items, totalLiquidity, totalLiquidityUSD: 0, totalAvailableLiquidity, avaliableStakes }
  }, [result])

  response.totalLiquidityUSD = useSubgraphTokenValue(response.totalLiquidity) || undefined
  return response
})

export const useLockedPositions = farmAndAccountRequiredFunction((farm, account) => {
  const farmContract = useFarmContract(farm?.address)

  const {
    result: raw,
    error,
    loading,
    ...rest
  } = useSingleCallResult(farmContract, 'lockedStakesOf', [account], {
    blocksPerFetch: 25,
  })

  const stakingToken = useToken(farm?.stakingToken)

  return useMemo(() => {
    if (raw && raw.length > 0 && stakingToken) {
      return (raw[0] as any[]).map(
        (
          item: {
            kek_id: string
            liquidity: BigintIsh
            ending_timestamp: BigintIsh
            lock_multiplier: BigintIsh
          },
          index: number
        ) => ({
          kekId: item.kek_id,
          liquidity: CurrencyAmount.fromRawAmount(stakingToken, item.liquidity.toString()),
          endingTimestamp: new Date(parseInt(item.ending_timestamp.toString()) * 1000),
          lockMultiplier: parseFloat(item.lock_multiplier.toString() || '0') / 1e18,
        })
      )
    }
    return []
  }, [raw, stakingToken])
})

export const useLockedAmountOfAccount = farmAndAccountRequiredFunction((farm, account) => {
  const stakingToken = useToken(farm?.stakingToken)
  const farmContract = useFarmContract(farm?.address)
  const {
    result: rawLocked,
    error,
    loading,
  } = useSingleCallResult(farmContract, 'lockedLiquidityOf', [account], {
    blocksPerFetch: 10000,
  })

  return useMemo(() => {
    if (loading) return null
    if (error || !stakingToken) return undefined

    return CurrencyAmount.fromRawAmount(stakingToken, rawLocked?.toString() || '0')
  }, [stakingToken, rawLocked, loading, error])
})

const useCommunalFarmBlockchainInfo = (farmAddress?: string) => {
  const farm = useFarmContract(farmAddress)
  const calls = {
    lock_time_min: useSingleCallResult(farm, 'lock_time_min'),
    lock_time_for_max_multiplier: useSingleCallResult(farm, 'lock_time_for_max_multiplier'),
    getAllRewardTokens: useSingleCallResult(farm, 'getAllRewardTokens'),
    stakingToken: useSingleCallResult(farm, 'stakingToken'),
    totalLiquidityLocked: useSingleCallResult(farm, 'totalLiquidityLocked'),
    getRewardForDuration: useSingleCallResult(farm, 'getRewardForDuration'),
    rewardsDuration: useSingleCallResult(farm, 'rewardsDuration'),
    lock_max_multiplier: useSingleCallResult(farm, 'lock_max_multiplier'),
  }

  const isLoading = farm === null || Object.values(calls).some((call) => call.loading)

  if (isLoading) {
    return null
  }

  const lock_time_min = calls.lock_time_min.result?.[0] as ethers.BigNumber | undefined
  const lock_time_for_max_multiplier = calls.lock_time_for_max_multiplier.result?.[0] as ethers.BigNumber | undefined
  const getAllRewardTokens = calls.getAllRewardTokens.result?.[0] as string[] | undefined
  const stakingToken = calls.stakingToken.result?.[0] as string | undefined
  const totalLiquidityLocked = calls.totalLiquidityLocked.result?.[0] as ethers.BigNumber | undefined
  const getRewardForDuration = calls.getRewardForDuration.result?.[0] as ethers.BigNumber[] | undefined
  const rewardsDuration = calls.rewardsDuration.result?.[0] as ethers.BigNumber | undefined
  const lock_max_multiplier = calls.lock_max_multiplier.result?.[0] as ethers.BigNumber | undefined

  return {
    lock_time_min,
    lock_time_for_max_multiplier,
    getAllRewardTokens,
    stakingToken,
    totalLiquidityLocked,
    getRewardForDuration,
    rewardsDuration,
    lock_max_multiplier,
  }
}

export const useCommunalFarm = (farmAddress?: string): Farm | null | undefined => {
  const info = useCommunalFarmBlockchainInfo(farmAddress)

  const { farms } = useAppSelector((state) => state.farms)
  return useMemo(() => {
    const stateFarm = farms.find((item) => item.address === farmAddress)
    if (stateFarm) {
      return stateFarm
    }

    if (info === null) {
      return null
    }

    const {
      lock_time_min,
      lock_time_for_max_multiplier,
      getAllRewardTokens,
      stakingToken,
      totalLiquidityLocked,
      getRewardForDuration,
      rewardsDuration,
      lock_max_multiplier,
    } = info

    if (
      !lock_time_min ||
      !lock_time_for_max_multiplier ||
      !getAllRewardTokens ||
      !stakingToken ||
      !totalLiquidityLocked ||
      !lock_max_multiplier ||
      !rewardsDuration ||
      !getRewardForDuration ||
      !farmAddress
    ) {
      return undefined
    }

    return {
      address: farmAddress,
      lockTimeMin: lock_time_min.toNumber(),
      lockTimeMax: lock_time_for_max_multiplier.toNumber(),
      lockMultiplierMax: parseFloat(lock_max_multiplier.toString()),
      rewardTokens: getAllRewardTokens,
      stakingToken,
      totalLiquidityLocked: totalLiquidityLocked.toString(),
      rewardsDuration: rewardsDuration.toString(),
      rewardsPerDuration: getRewardForDuration.map((item) => item.toString()),
    }
  }, [farmAddress, info])
}

export const useCommunalFarms = () => {
  const { loaded, loading, farms } = useAppSelector((state) => state.farms)
  const { provider, chainId } = useWeb3React()
  const dispatch = useAppDispatch()

  const lockMinTimes = useMultipleContractSingleData(farmsAddresses, ICommunalFarm, 'lock_time_min')

  const lockMaxTimes = useMultipleContractSingleData(farmsAddresses, ICommunalFarm, 'lock_time_for_max_multiplier')

  const lockMaxMultipliers = useMultipleContractSingleData(farmsAddresses, ICommunalFarm, 'lock_max_multiplier')

  const allRewardTokens = useMultipleContractSingleData(farmsAddresses, ICommunalFarm, 'getAllRewardTokens')

  const stakingTokens = useMultipleContractSingleData(farmsAddresses, ICommunalFarm, 'stakingToken')

  const totalLiquidityLockeds = useMultipleContractSingleData(farmsAddresses, ICommunalFarm, 'totalLiquidityLocked')

  const rewardsPerDurations = useMultipleContractSingleData(farmsAddresses, ICommunalFarm, 'getRewardForDuration')

  const rewardsDurations = useMultipleContractSingleData(farmsAddresses, ICommunalFarm, 'rewardsDuration')

  useEffect(() => {
    const root = [
      lockMinTimes,
      lockMaxTimes,
      allRewardTokens,
      stakingTokens,
      totalLiquidityLockeds,
      rewardsPerDurations,
      rewardsDurations,
      lockMaxMultipliers,
    ]

    const dataLoading = root.some((item) => item.some((value) => value.loading))
    const error = root.some((item) => item.some((value) => value.error))
    const notValid = root.some((item) => item.some((value) => !value.valid))
    const done = root.some((item) => item.some((value) => value.result !== undefined))
    if (dataLoading || !done) {
      dispatch(setLoading(true))
      return
    }
    if (error || notValid) {
      dispatch(setLoading(false))
      return
    }

    const farms: Farm[] = farmsAddresses.map((address, index) => ({
      address,
      lockTimeMin: lockMinTimes[index].result![0].toNumber(),
      lockTimeMax: lockMaxTimes[index].result![0].toNumber(),
      rewardTokens: allRewardTokens[index].result![0] as string[],
      stakingToken: stakingTokens[index].result![0] as string,
      totalLiquidityLocked: totalLiquidityLockeds[index].result![0].toString() as string,
      rewardsPerDuration: rewardsPerDurations[index].result![0].map((item: any) => item.toString()) as string[],
      rewardsDuration: rewardsDurations[index].result![0].toString() as string,
      lockMultiplierMax: parseFloat(lockMaxMultipliers[index].result![0].toString()),
    }))
    dispatch(setFarms(farms))
  }, [lockMinTimes, lockMaxTimes, allRewardTokens, stakingTokens, chainId])

  return !loaded ? farmsAddresses.map(() => null) : farms
}
