import { createAction } from '@reduxjs/toolkit'

import { Farm } from './reducer'

export const resetFarmsState = createAction<void>('farms/resetFarmsState')
export const setLoaded = createAction<void>('farms/setLoaded')
export const addFarm = createAction<Farm>('farms/addFarm')
export const setFarms = createAction<Farm[]>('farms/setFarms')
export const setLoading = createAction<boolean>('farms/setLoading')
export const typeInput = createAction<string>('farms/typeInput')
export const changeLockTime = createAction<number>('farms/changeLockTime')
export const resetFarmInputs = createAction<void>('farms/resetFarmInputs')
