import React, { useEffect, useState } from 'react'
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useTheme } from 'styled-components'
import { ThemedText } from 'theme'
import { formatAmount } from 'utils/formatDollarAmt'

import { FlexRowCenter } from '../styleds'
import * as S from './styleds'
import { filterDataByTimeframe, selectTimeFormat, SnapshotType } from './utils/chartHelpers'

type AprViewType = {
  timeframe: '7' | '30' | '90' | 'max'
  snapshots: SnapshotType[]
}

type CustomTickLabelProps = {
  x?: number
  y?: number
  payload?: any
  axisType?: 'xAxis' | 'yAxis'
  theme: any
}

type CustomTooltipProps = {
  active?: boolean
  payload?: any[]
  label?: string
  theme?: any
  xAxisFormatter: any
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label, theme, xAxisFormatter }) => {
  if (active && payload && payload.length) {
    return (
      <S.StrategyTooltipFlex>
        <ThemedText.DeprecatedBody fontSize="14px" fontWeight="700" lineHeight="20px" color={theme.textPrimary}>
          {xAxisFormatter(payload[0].payload.timestamp)}
        </ThemedText.DeprecatedBody>
        <FlexRowCenter>
          <ThemedText.DeprecatedBody fontSize="12px" fontWeight="400" lineHeight="20px" color={theme.textSecondary}>
            APR:
          </ThemedText.DeprecatedBody>
          <ThemedText.DeprecatedBody fontSize="12px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
            {formatAmount(payload[0].value)}%
          </ThemedText.DeprecatedBody>
        </FlexRowCenter>
      </S.StrategyTooltipFlex>
    )
  }
  return null
}

const AprView: React.FC<AprViewType> = ({ timeframe, snapshots }) => {
  const theme = useTheme()
  // const tempSnapshots = TempVaultSnapshots.data.kodiakVaultDailySnapshots
  //   .map((snapshot) => ({
  //     ...snapshot,
  //     timestamp: Number(snapshot.timestamp), // Convert timestamp to number for sorting
  //   }))
  //   .sort((a, b) => a.timestamp - b.timestamp)

  const [filteredData, setFilteredData] = useState<SnapshotType[]>([])

  const xAxisFormatter = selectTimeFormat(timeframe)

  // useEffect(() => {
  //   const filteredData = filterDataByTimeframe(tempSnapshots, timeframe).map((snapshot) => ({
  //     ...snapshot,
  //     apr: parseFloat(snapshot.vault.apr.averageApr),
  //   }))
  //   setFilteredData(filteredData)
  // }, [timeframe])

  // REAL DATA

  useEffect(() => {
    if (snapshots && snapshots.length > 0) {
      // Create a copy of the snapshots array
      const snapshotsCopy = snapshots.map((snapshot) => ({
        ...snapshot,
        timestamp: Number(snapshot.timestamp),
      }))

      // Sort the copied array by timestamp in ascending order
      const sortedSnapshots = snapshotsCopy.sort((a, b) => a.timestamp - b.timestamp)

      // Filter the sorted snapshots based on the selected timeframe
      const newFilteredData = filterDataByTimeframe(sortedSnapshots, timeframe).map((snapshot) => ({
        ...snapshot,
        apr: parseFloat(snapshot.apr),
      }))

      // Update the state with the filtered data
      setFilteredData(newFilteredData)
    }
  }, [timeframe, snapshots])

  const CustomTickLabel: React.FC<CustomTickLabelProps> = ({ x, y, payload, axisType, theme }) => {
    const value =
      axisType === 'xAxis'
        ? xAxisFormatter(payload.value) // Format as time for x-axis
        : payload.value + '%' // Format as currency for y-axis

    return (
      <text
        x={x}
        y={y}
        dy={axisType === 'xAxis' ? 10 : 0}
        dx={axisType === 'xAxis' ? -10 : -14}
        textAnchor="middle"
        fill={theme.textSecondary}
        fontSize={12}
      >
        {value}
      </text>
    )
  }

  return (
    <S.GraphFlex style={{ overflow: 'hidden' }}>
      <ResponsiveContainer width="100%" height={182} minWidth="100%">
        <AreaChart data={filteredData} margin={{ top: 24, right: 8, left: -8, bottom: 0 }}>
          <defs>
            <linearGradient id="aprColor" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="timestamp"
            stroke="white"
            tickLine={false}
            axisLine={false}
            tick={<CustomTickLabel axisType="xAxis" theme={theme} />}
            minTickGap={25}
            tickCount={4}
            tickFormatter={(value) => selectTimeFormat(timeframe)(value)}
          />
          <YAxis
            dataKey="apr"
            stroke="white"
            tickLine={false}
            axisLine={false}
            tick={<CustomTickLabel axisType="yAxis" theme={theme} />}
            minTickGap={5}
            tickFormatter={(value) => `${value}%`}
          />
          <Tooltip content={<CustomTooltip theme={theme} xAxisFormatter={xAxisFormatter} />} />
          {/* eslint-disable-next-line react/prop-types */}
          <Area type="monotone" dataKey="apr" stroke={theme.buttonBorder} fillOpacity={1} fill="url(#aprColor)" />
        </AreaChart>
      </ResponsiveContainer>
    </S.GraphFlex>
  )
}

export default AprView
