import { MouseoverTooltip } from 'components/Tooltip'
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { ThemedText } from 'theme'

import * as S from './styled'

const HeaderRow = () => {
  const theme = useContext(ThemeContext)

  return (
    <S.HeaderRow>
      <S.HeaderCol>
        <MouseoverTooltip
          // disableHover={!tooltip}
          text={
            <ThemedText.DeprecatedBody fontSize="12px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
              User Concentrated Liquidity Position
            </ThemedText.DeprecatedBody>
          }
        >
          <ThemedText.DeprecatedBody fontSize="16px" fontWeight="700" lineHeight="20px" color={theme.textSecondary}>
            Position
          </ThemedText.DeprecatedBody>
        </MouseoverTooltip>
      </S.HeaderCol>

      <S.HeaderCol>
        <MouseoverTooltip
          // disableHover={!tooltip}
          text={
            <ThemedText.DeprecatedBody fontSize="12px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
              Range in which liquidity is deployed
            </ThemedText.DeprecatedBody>
          }
        >
          <ThemedText.DeprecatedBody fontSize="16px" fontWeight="700" lineHeight="20px" color={theme.textSecondary}>
            Range
          </ThemedText.DeprecatedBody>
        </MouseoverTooltip>
      </S.HeaderCol>

      {/* <S.HeaderCol>
        <ThemedText.DeprecatedBody fontSize={'16px'} fontWeight="700" lineHeight="20px" color={theme.textSecondary}>
          Liquidity
        </ThemedText.DeprecatedBody>
      </S.HeaderCol> */}

      <div></div>

      <S.HeaderCol>
        <MouseoverTooltip
          // disableHover={!tooltip}
          text={
            <ThemedText.DeprecatedBody fontSize="12px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
              Accrued but not yet claimed fees
            </ThemedText.DeprecatedBody>
          }
        >
          <ThemedText.DeprecatedBody fontSize="16px" fontWeight="700" lineHeight="20px" color={theme.textSecondary}>
            Unclaimed Fees
          </ThemedText.DeprecatedBody>
        </MouseoverTooltip>
      </S.HeaderCol>

      <S.HeaderCol>
        <MouseoverTooltip
          // disableHover={!tooltip}
          text={
            <ThemedText.DeprecatedBody fontSize="12px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
              User deposits
            </ThemedText.DeprecatedBody>
          }
        >
          <ThemedText.DeprecatedBody fontSize="16px" fontWeight="700" lineHeight="20px" color={theme.textSecondary}>
            My Deposits
          </ThemedText.DeprecatedBody>
        </MouseoverTooltip>
      </S.HeaderCol>
      {/* <S.HeaderCol></S.HeaderCol> */}
    </S.HeaderRow>
  )
}

export default HeaderRow
