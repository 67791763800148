import { Contract } from '@ethersproject/contracts'
import QuoterV2Json from '@kodiak-finance/swap-router-contracts/artifacts/contracts/lens/QuoterV2.sol/QuoterV2.json'
import IUniswapV2PairJson from '@kodiak-finance/v2-core/build/IUniswapV2Pair.json'
import IUniswapV2Router02Json from '@kodiak-finance/v2-periphery/build/IUniswapV2Router02.json'
import V3PoolJson from '@kodiak-finance/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json'
import V3FactoryJson from '@kodiak-finance/v3-core/artifacts/contracts/UniswapV3Factory.sol/UniswapV3Factory.json'
import QuoterJson from '@kodiak-finance/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json'
import TickLensJson from '@kodiak-finance/v3-periphery/artifacts/contracts/lens/TickLens.sol/TickLens.json'
import UniswapInterfaceMulticallJson from '@kodiak-finance/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json'
import NonfungiblePositionManagerJson from '@kodiak-finance/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json'
import V3MigratorJson from '@kodiak-finance/v3-periphery/artifacts/contracts/V3Migrator.sol/V3Migrator.json'
import { useWeb3React } from '@web3-react/core'
import ARGENT_WALLET_DETECTOR_ABI from 'abis/argent-wallet-detector.json'
import COMMUNAL_FARM_ABI from 'abis/communal-farm.json'
import EIP_2612 from 'abis/eip_2612.json'
import ENS_PUBLIC_RESOLVER_ABI from 'abis/ens-public-resolver.json'
import ENS_ABI from 'abis/ens-registrar.json'
import ERC20_ABI from 'abis/erc20.json'
import ERC20_BYTES32_ABI from 'abis/erc20_bytes32.json'
import ERC721_ABI from 'abis/erc721.json'
import ERC1155_ABI from 'abis/erc1155.json'
import ISLAND_ABI from 'abis/island.json'
import ISLAND_FACTORY_ABI from 'abis/island-factory.json'
import ISLAND_SWAP_ROUTER_ABI from 'abis/island-swap-router.json'
import KDK_ABI from 'abis/KDK.json'
import KODIAK_REWARDS_ABI from 'abis/KodiakRewards.json'
import {
  ArgentWalletDetector,
  CommunalFarm,
  EnsPublicResolver,
  EnsRegistrar,
  Erc20,
  Erc721,
  Erc1155,
  Island,
  IslandFactory,
  IslandSwapRouter,
  KDK,
  KodiakRewards,
  XKDK,
} from 'abis/types'
// import WETH_ABI from 'abis/weth.json'
import WETH_ABI from 'abis/weth.json'
import XKDK_ABI from 'abis/XKDK.json'
import {
  ARGENT_WALLET_DETECTOR_ADDRESS,
  ENS_REGISTRAR_ADDRESSES,
  KODIAK_REWARDS,
  KODIAK_TOKEN,
  KODIAK_V1_FACTORY,
  KODIAK_V1_ROUTER_STAKING,
  MULTICALL_ADDRESS,
  NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  QUOTER_ADDRESSES,
  TICK_LENS_ADDRESSES,
  V2_ROUTER_ADDRESS,
  V3_CORE_FACTORY_ADDRESSES,
  XKODIAK_TOKEN,
  // V3_MIGRATOR_ADDRESSES,
} from 'constants/addresses'
import { WRAPPED_NATIVE_CURRENCY } from 'constants/tokens'
import { useEffect, useMemo, useState } from 'react'
import { NonfungiblePositionManager, Quoter, QuoterV2, TickLens, UniswapInterfaceMulticall } from 'types/v3'

import { getContract } from '../utils'

const { abi: IUniswapV2PairABI } = IUniswapV2PairJson
const { abi: IUniswapV2Router02ABI } = IUniswapV2Router02Json
const { abi: QuoterABI } = QuoterJson
const { abi: QuoterV2ABI } = QuoterV2Json
const { abi: TickLensABI } = TickLensJson
const { abi: MulticallABI } = UniswapInterfaceMulticallJson
const { abi: NFTPositionManagerABI } = NonfungiblePositionManagerJson
const { abi: V2MigratorABI } = V3MigratorJson
const { abi: V3FactoryABI } = V3FactoryJson
const { abi: V3PoolABI } = V3PoolJson

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { provider, account, chainId } = useWeb3React()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !provider || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(address, ABI, provider, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, ABI, provider, chainId, withSignerIfPossible, account]) as T
}

// export function useV2MigratorContract() {
//   return useContract<V3Migrator>(V3_MIGRATOR_ADDRESSES, V2MigratorABI, true)
// }

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean) {
  return useContract<Erc20>(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean) {
  const { chainId } = useWeb3React()
  return useContract<Weth>(
    chainId ? WRAPPED_NATIVE_CURRENCY[chainId]?.address : undefined,
    WETH_ABI,
    withSignerIfPossible
  )
}

export function useERC721Contract(nftAddress?: string) {
  return useContract<Erc721>(nftAddress, ERC721_ABI, false)
}

export function useERC1155Contract(nftAddress?: string) {
  return useContract<Erc1155>(nftAddress, ERC1155_ABI, false)
}

export function useArgentWalletDetectorContract() {
  return useContract<ArgentWalletDetector>(ARGENT_WALLET_DETECTOR_ADDRESS, ARGENT_WALLET_DETECTOR_ABI, false)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean) {
  return useContract<EnsRegistrar>(ENS_REGISTRAR_ADDRESSES, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean) {
  return useContract<EnsPublicResolver>(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function useEIP2612Contract(tokenAddress?: string): Contract | null {
  return useContract(tokenAddress, EIP_2612, false)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function usePoolContract(poolAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(poolAddress, V3PoolABI, withSignerIfPossible)
}

export function useV2RouterContract(): Contract | null {
  return useContract(V2_ROUTER_ADDRESS, IUniswapV2Router02ABI, true)
}

export function useIslandRouterContract(): IslandSwapRouter | null {
  return useContract(KODIAK_V1_ROUTER_STAKING, ISLAND_SWAP_ROUTER_ABI, true)
}

export function useInterfaceMulticall() {
  return useContract<UniswapInterfaceMulticall>(MULTICALL_ADDRESS, MulticallABI, false) as UniswapInterfaceMulticall
}

export function useV3Factory(): Contract | null {
  return useContract(V3_CORE_FACTORY_ADDRESSES, V3FactoryABI, false)
}

export function useV3NFTPositionManagerContract(withSignerIfPossible?: boolean): NonfungiblePositionManager | null {
  return useContract<NonfungiblePositionManager>(
    NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
    NFTPositionManagerABI,
    withSignerIfPossible
  )
}

export function useQuoter(useQuoterV2: boolean) {
  return useContract<Quoter | QuoterV2>(QUOTER_ADDRESSES, useQuoterV2 ? QuoterV2ABI : QuoterABI)
}

export function useTickLens(): TickLens | null {
  const { chainId } = useWeb3React()
  const address = chainId ? TICK_LENS_ADDRESSES[chainId] : undefined
  return useContract(address, TickLensABI) as TickLens | null
}

export function useKDK(): KDK | null {
  const { chainId } = useWeb3React()
  const address = chainId ? KODIAK_TOKEN[chainId] : undefined
  return useContract(address, KDK_ABI)
}

export function useXKDK(): XKDK | null {
  const { chainId } = useWeb3React()
  const address = chainId ? XKODIAK_TOKEN[chainId] : undefined
  return useContract(address, XKDK_ABI)
}

export function useKodiakRewards(): KodiakRewards | null {
  const { chainId } = useWeb3React()
  const address = chainId ? KODIAK_REWARDS[chainId] : undefined
  return useContract(address, KODIAK_REWARDS_ABI)
}

// Islands

export function useIslandFactory(): IslandFactory | null {
  const { chainId } = useWeb3React()
  const address = chainId ? KODIAK_V1_FACTORY[chainId] : undefined
  return useContract<IslandFactory>(address, ISLAND_FACTORY_ABI, false)
}

export function useFarmContract(address?: string): CommunalFarm | null {
  return useContract<CommunalFarm>(address, COMMUNAL_FARM_ABI, true)
}

export function useIslandContract(islandAddress?: string): Island | null {
  return useContract<Island>(islandAddress, ISLAND_ABI, false)
}

interface MethodResults {
  [key: string]: any
}

type MultipleMethodsResponseExtended<
  C extends Contract,
  T extends string[],
  B extends { loading: true | false }
> = B extends { loading: true }
  ? {
      [P in T[number]]: NonNullable<Awaited<ReturnType<C[P]>>>
    }
  : B extends { loading: false }
  ? UndefinedResponses<T>
  : never

type MultipleMethodsResponseBaseTrue = {
  loading: true
}

type MultipleMethodsResponseBaseFalse = {
  loading: false
}

type UndefinedResponses<T extends string[]> = { [P in T[number]]: undefined }
type ValidResponses<
  C extends Contract | null | undefined,
  T extends string[],
  B extends MultipleMethodsResponseBaseTrue | MultipleMethodsResponseBaseFalse
> = MultipleMethodsResponseExtended<NonNullable<C>, T, B>

type MultipleMethodsResponse<C extends Contract | null | undefined, T extends string[]> = (C extends Contract
  ? ValidResponses<C, T, MultipleMethodsResponseBaseTrue | MultipleMethodsResponseBaseFalse>
  : UndefinedResponses<T>) &
  (MultipleMethodsResponseBaseTrue | MultipleMethodsResponseBaseFalse) & { error: Error | null }

export function useSingleContractMultipleMethods<C extends Contract | null | undefined, T extends string[]>(
  contract: C,
  ...methodNames: T
): MultipleMethodsResponse<C, T> {
  const [results, setResults] = useState<MethodResults>({})
  const [loading, setLoading] = useState<
    MultipleMethodsResponseBaseTrue['loading'] | MultipleMethodsResponseBaseFalse['loading']
  >(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    let isSubscribed = true

    const fetchData = async () => {
      try {
        if (contract === null) return
        if (contract === undefined) {
          setLoading(false)
          setError(new Error('Contract is undefined'))
          return
        }

        const methodResults: MethodResults = {}
        for (const methodName of methodNames) {
          methodResults[methodName] = await contract[methodName]()
        }

        if (isSubscribed) {
          setResults(methodResults)
          setLoading(false)
        }
      } catch (error) {
        console.error('Error fetching contract data', error)
        if (isSubscribed) {
          setError(error)
          setLoading(false)
        }
      }
    }

    fetchData()

    return () => {
      isSubscribed = false
    }
  }, [contract, ...methodNames])

  return { ...results, loading, error } as MultipleMethodsResponse<C, T>
}
