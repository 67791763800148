import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { MEDIA_WIDTHS } from 'theme'

import { ReactComponent as SwitchHorizontal } from '../../assets/svg/icons/SwitchHorizontal.svg'

// Subgrid for body
export const BodyRow = styled.div`
  grid-area: body;
  display: grid;
  grid-template-columns: subgrid;
  height: 60px;
`

// Subgrid for footer
export const FooterRow = styled.div`
  grid-area: footer;
  display: grid;
  grid-template-columns: subgrid;
  height: 60px;
`

export const FlexRowCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .range-badge {
    padding: 0;
  }
`

export const LinkRow = styled(Link)<{ row: number }>`
  min-height: 60px;
  display: grid;
  cursor: pointer;
  user-select: none;

  width: 100%;
  padding: 8px 16px;

  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.cardBorderSecondary};

  grid-template-columns: 260px 230px 20px repeat(2, 1fr);
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr repeat(3, 0px) repeat(1, 1fr)
  `}
  transition: background-color, border-color 0.3s ease-in-out;

  .badgeWrapper {
    justify-content: flex-start;
  }
  /* &:last-of-type {
    margin: 8px 0 0 0;
  }
  & > div:not(:first-child) {
    text-align: center;
  } */
  :hover {
    background-color: ${({ theme }) => theme.cardSecondary};
    border-color: ${({ theme }) => theme.cardBorderPrimary};
  }

  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    /* flex-direction: row; */
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    row-gap: 12px;
  `};
`

export const StategyBadge = styled.div`
  background-color: ${({ theme }) => theme.cardSecondary};
  width: fit-content;
  color: ${({ theme }) => theme.textSecondary};
  border-radius: 6px;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 16px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `};
`

export const DataLineItem = styled.div`
  font-size: 14px;
`

export const RangeLineItem = styled(DataLineItem)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  // background-color: ${({ theme }) => theme.textSecondary};
  //   border-radius: 12px;
  //   padding: 8px 0;
`};
`

export const DoubleArrow = styled(SwitchHorizontal)`
  /* margin: 0 2px; */
  fill: #64748b;
`

export const RangeText = styled.div`
  /* background-color: ${({ theme }) => theme.deprecated_bg2}; */
  padding: 0.25rem 0;
  font-size: 12px;

  border-radius: 8px;

  color: ${({ theme }) => theme.textPrimary};
`

export const ExtentsText = styled.span`
  color: ${({ theme }) => theme.textSecondary};
  font-size: 12px;
  margin-right: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

export const PlusButton = styled.div`
  color: ${({ theme }) => theme.textPrimary};
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  border-radius: 4px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 32px;
  width: 32px;

  &:hover {
    background: ${({ theme }) => theme.deprecated_bg2};
  }
`

export const PrimaryPositionIdData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-right: 16px;
  }

  ${FlexCol} {
    gap: 4px;
  }
`

export const PairText = styled.div`
  font-weight: 400;
  white-space: nowrap;
  font-size: 16px;
  color: ${({ theme }) => theme.textPrimary};
`

export const GridCol = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
`
export const BadgeText = styled.div`
  font-weight: 500;
  font-size: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `};
`

export const DataText = styled.div`
  font-weight: 600;
  font-size: 18px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
  `};
`

export const V2ListItemFlex = styled.div`
  min-height: 60px;
  display: grid;
  cursor: pointer;
  user-select: none;

  width: 100%;
  padding: 8px 16px;

  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.cardBorderSecondary};

  grid-template-columns: 260px 230px repeat(2, 1fr);
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr repeat(2, 0px) repeat(1, 1fr)
  `}

  transition: background-color, border-color 0.3s ease-in-out;

  .badgeWrapper {
    justify-content: flex-start;
  }
  /* &:last-of-type {
    margin: 8px 0 0 0;
  }
  & > div:not(:first-child) {
    text-align: center;
  } */
  /* :hover {
    background-color: ${({ theme }) => theme.cardSecondary};
    border-color: ${({ theme }) => theme.cardBorderPrimary};
  } */

  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    /* flex-direction: row; */
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    row-gap: 12px;
  `};
`

export const ManageButtonFlex = styled.div`
  display: flex;
  height: 100%;
  gap: 16px;
  align-items: center;
`
