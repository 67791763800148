import { CurrencyAmount, Token } from '@kodiak-finance/sdk-core'
import { useWeb3React } from '@web3-react/core'
import CopyHelper from 'components/AccountDetails/Copy'
import { formatToDecimal } from 'components/AmplitudeAnalytics/utils'
import CurrencyLogo from 'components/CurrencyLogo'
import { getConnection, getConnectionName, getIsCoinbaseWallet, getIsMetaMask } from 'connection/utils'
import { getChainInfoOrDefault } from 'constants/chainInfo'
import { useSubgraphTokenValueWithEth } from 'hooks/useSubgraphTokenPrice'
import { useNativeCurrencyBalances } from 'lib/hooks/useCurrencyBalance'
import { Context, useCallback, useContext, useState } from 'react'
import { ExternalLink as LinkIcon } from 'react-feather'
import { useAppDispatch } from 'state/hooks'
import { updateSelectedWallet } from 'state/user/reducer'
import { removeConnectedWallet } from 'state/wallets/reducer'
import styled, { DefaultTheme, ThemeContext } from 'styled-components/macro'
import { formatAmount, formatDollarAmount } from 'utils/formatDollarAmt'
import { isMobile } from 'utils/userAgent'

import { ReactComponent as Close } from '../../assets/images/x.svg'
import { clearAllTransactions } from '../../state/transactions/reducer'
import { ExternalLink, LinkStyledButton, ThemedText } from '../../theme'
import { shortenAddress } from '../../utils'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import { ButtonSecondary } from '../Button'
import StatusIcon from '../Identicon/StatusIcon'
import { AutoRow } from '../Row'
import Transaction from './Transaction'

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;
  color: ${(props) => (props.color === 'blue' ? ({ theme }) => theme.deprecated_primary1 : 'inherit')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const InfoCard = styled.div`
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.cardBorderSecondary};
  background: ${({ theme }) => theme.cardSecondary};
  border-radius: 8px;
  position: relative;
  display: grid;
  grid-row-gap: 12px;
  margin-bottom: 20px;
`

const AccountGroupingRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  color: ${({ theme }) => theme.deprecated_text1};

  div {
    ${({ theme }) => theme.flexRowNoWrap}
    align-items: center;
  }
`

const AccountSection = styled.div`
  /* padding: 0rem 0.5rem; */
  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 0rem 1rem 1.5rem 1rem;`};
`

const YourAccount = styled.div`
  h5 {
    margin: 0 0 1rem 0;
    font-weight: 400;
  }

  h4 {
    margin: 0;
    font-weight: 500;
  }
`

const LowerSection = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  padding: 1.5rem;
  flex-grow: 1;
  overflow: auto;
  min-height: 240px;
  /* background-color: ${({ theme }) => theme.deprecated_bg2}; */
  /* border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px; */

  h5 {
    margin: 0;
    font-weight: 400;
    color: ${({ theme }) => theme.deprecated_text3};
  }
`

const AccountControl = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 0;
  width: 100%;

  font-weight: 500;
  font-size: 1.25rem;

  a:hover {
    text-decoration: underline;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const AddressLink = styled(ExternalLink)`
  font-size: 0.825rem;
  color: ${({ theme }) => theme.buttonPrimary};
  margin-left: 1rem;
  font-size: 0.825rem;
  display: flex;
  :hover {
    color: ${({ theme }) => theme.textPrimary};
  }
`

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.deprecated_text4};
  }
`

const WalletName = styled.div`
  width: initial;
  font-size: 0.825rem;
  font-weight: 500;
  color: ${({ theme }) => theme.deprecated_text3};
`

const TransactionListWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
`

const WalletAction = styled(ButtonSecondary)`
  width: fit-content;
  font-weight: 400;
  margin-left: 8px;
  font-size: 0.825rem;
  padding: 4px 6px;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const TabRow = styled.div`
  display: flex;
  width: 100%;

  padding: 0.7rem 20% 1.5rem 20%;
  justify-content: space-evenly;
  font-weight: 500;
  color: ${(props) => (props.color === 'blue' ? ({ theme }) => theme.deprecated_primary1 : 'inherit')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};

  border-bottom: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  margin-bottom: 1rem;
`

const TabItem = styled(ThemedText.DeprecatedBody)`
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.textPrimary};
  }
`

const PorfolioTabFlex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const BalanceRow = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 10%;

  justify-content: space-between;
`

const BalanceTokens = styled.div`
  display: flex;
  gap: 14px;
  flex-wrap: nowrap;

  .tokens-and-amount {
    display: flex;
    flex-wrap: nowrap;
    gap: 6px;
  }
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

function renderTransactions(transactions: string[]) {
  return (
    <TransactionListWrapper>
      {transactions.map((hash, i) => {
        return <Transaction key={i} hash={hash} />
      })}
    </TransactionListWrapper>
  )
}

const PorfolioBalanceRow = ({ balance }: { balance: CurrencyAmount<Token> | null | undefined }) => {
  const balanceUSD = useSubgraphTokenValueWithEth(balance as CurrencyAmount<Token> | null | undefined)
  const theme = useContext(ThemeContext as Context<DefaultTheme>)
  if (!balance?.currency) return

  return (
    <BalanceRow>
      <BalanceTokens>
        <CurrencyLogo currency={balance.currency} size="24px" />

        <div className="tokens-and-amount">
          <ThemedText.DeprecatedBody color={theme.textPrimary} fontWeight={500}>
            {formatAmount(formatToDecimal(balance, balance.currency.decimals).toFixed(4))}
          </ThemedText.DeprecatedBody>

          <ThemedText.DeprecatedBody color={theme.textSecondary}>{balance.currency.symbol}</ThemedText.DeprecatedBody>
        </div>
      </BalanceTokens>

      <ThemedText.DeprecatedBody color={theme.textPrimary} fontWeight={500}>
        {formatDollarAmount(balanceUSD)}
      </ThemedText.DeprecatedBody>
    </BalanceRow>
  )
}

interface PortfolioContentProps {
  balances: any[]
}

const PortfolioContent: React.FC<PortfolioContentProps> = ({ balances }: { balances: any[] }) => {
  const theme = useContext(ThemeContext as Context<DefaultTheme>)

  return (
    <PorfolioTabFlex>
      {balances &&
        balances.length > 0 &&
        balances.map((balance, index) => <PorfolioBalanceRow key={index} balance={balance} />)}
    </PorfolioTabFlex>
  )
}

interface ActivityContentProps {
  pendingTransactions: string[]
  confirmedTransactions: string[]
  clearAllTransactionsCallback: () => void
}

const ActivityContent: React.FC<ActivityContentProps> = ({
  pendingTransactions,
  confirmedTransactions,
  clearAllTransactionsCallback,
}: ActivityContentProps) => {
  const theme = useContext(ThemeContext as Context<DefaultTheme>)

  return (
    <>
      {pendingTransactions.length > 0 || confirmedTransactions.length > 0 ? (
        <LowerSection>
          <AutoRow mb="1rem" style={{ justifyContent: 'space-between' }}>
            <ThemedText.DeprecatedBody>
              <>Recent Transactions</>
            </ThemedText.DeprecatedBody>
            <LinkStyledButton onClick={clearAllTransactionsCallback}>
              <>(clear all)</>
            </LinkStyledButton>
          </AutoRow>
          {renderTransactions(pendingTransactions)}
          {renderTransactions(confirmedTransactions)}
        </LowerSection>
      ) : (
        <LowerSection>
          <ThemedText.DeprecatedBody color={theme.deprecated_text1}>
            <>Your transactions will appear here...</>
          </ThemedText.DeprecatedBody>
        </LowerSection>
      )}
    </>
  )
}

interface AccountDetailsProps {
  toggleWalletModal: () => void
  pendingTransactions: string[]
  confirmedTransactions: string[]
  ENSName?: string
  openOptions: () => void
  balances?: any[]
}

export default function AccountDetails({
  toggleWalletModal,
  pendingTransactions,
  confirmedTransactions,
  ENSName,
  openOptions,
  balances,
}: AccountDetailsProps) {
  const { chainId, account, connector } = useWeb3React()
  const connectionType = getConnection(connector).type

  const [portfolioTab, setPortfolioTab] = useState(0)
  const theme = useContext(ThemeContext as Context<DefaultTheme>)
  const dispatch = useAppDispatch()

  const isMetaMask = getIsMetaMask()
  const isCoinbaseWallet = getIsCoinbaseWallet()
  const isInjectedMobileBrowser = (isMetaMask || isCoinbaseWallet) && isMobile

  const {
    infoLink,
    nativeCurrency: { symbol: nativeCurrencySymbol },
  } = getChainInfoOrDefault(chainId)

  const userEthBalance = useNativeCurrencyBalances(account ? [account] : [])?.[account ?? '']

  function formatConnectorName() {
    return (
      <WalletName>
        <>Connected with</> {getConnectionName(connectionType, isMetaMask)}
      </WalletName>
    )
  }

  const handlePortfolioTabClick = (tab: 0 | 1) => {
    setPortfolioTab(tab)
  }

  const clearAllTransactionsCallback = useCallback(() => {
    if (chainId) dispatch(clearAllTransactions({ chainId }))
  }, [dispatch, chainId])

  const renderTabContent = () => {
    switch (portfolioTab) {
      case 0:
        return <PortfolioContent balances={balances} />
      case 1:
        return (
          <ActivityContent
            pendingTransactions={pendingTransactions}
            confirmedTransactions={confirmedTransactions}
            clearAllTransactionsCallback={clearAllTransactionsCallback}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <UpperSection>
        <CloseIcon onClick={toggleWalletModal}>
          <CloseColor />
        </CloseIcon>
        <HeaderRow>
          <>Account</>
        </HeaderRow>
        <AccountSection>
          <YourAccount>
            <InfoCard>
              <AccountGroupingRow>
                {formatConnectorName()}
                <div>
                  {!isInjectedMobileBrowser && (
                    <>
                      <WalletAction
                        style={{ fontSize: '.825rem', fontWeight: 400, marginRight: '8px' }}
                        onClick={() => {
                          const walletType = getConnectionName(getConnection(connector).type, getIsMetaMask())
                          if (connector.deactivate) {
                            connector.deactivate()
                          } else {
                            connector.resetState()
                          }

                          dispatch(updateSelectedWallet({ wallet: undefined }))
                          dispatch(removeConnectedWallet({ account, walletType }))
                          openOptions()
                        }}
                      >
                        <>Disconnect</>
                      </WalletAction>
                      <WalletAction
                        style={{ fontSize: '.825rem', fontWeight: 400 }}
                        onClick={() => {
                          openOptions()
                        }}
                      >
                        <>Change</>
                      </WalletAction>
                    </>
                  )}
                </div>
              </AccountGroupingRow>
              <AccountGroupingRow data-testid="web3-account-identifier-row">
                <AccountControl>
                  <div style={{ display: 'flex', gap: '16px' }}>
                    <div>
                      <StatusIcon connectionType={connectionType} />
                      <p>{ENSName ? ENSName : account && shortenAddress(account)}</p>
                    </div>
                    {/* {account && userEthBalance ? (
                      <ThemedText.DeprecatedBody>
                        <>
                          {userEthBalance?.toSignificant(3)} {nativeCurrencySymbol}
                        </>
                      </ThemedText.DeprecatedBody>
                    ) : null} */}
                  </div>
                </AccountControl>
              </AccountGroupingRow>
              <AccountGroupingRow>
                <AccountControl>
                  <div>
                    {account && (
                      <CopyHelper toCopy={account} iconPosition="left">
                        <>Copy Address</>
                      </CopyHelper>
                    )}
                    {chainId && account && (
                      <AddressLink href={getExplorerLink(chainId, ENSName ?? account, ExplorerDataType.ADDRESS)}>
                        <LinkIcon size={16} />
                        <>View on Explorer</>
                      </AddressLink>
                    )}
                  </div>
                </AccountControl>
              </AccountGroupingRow>
            </InfoCard>
          </YourAccount>
        </AccountSection>
      </UpperSection>

      <TabRow>
        <TabItem
          fontWeight={500}
          fontSize={18}
          color={portfolioTab === 0 ? theme.textPrimary : theme.textSecondary}
          onClick={() => handlePortfolioTabClick(0)}
        >
          Portfolio
        </TabItem>
        <TabItem
          fontWeight={500}
          fontSize={18}
          color={portfolioTab === 1 ? theme.textPrimary : theme.textSecondary}
          onClick={() => handlePortfolioTabClick(1)}
        >
          Activity
        </TabItem>
      </TabRow>
      {renderTabContent()}
    </>
  )
}
