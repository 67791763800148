import { Currency, CurrencyAmount, Fraction, Percent } from '@kodiak-finance/sdk-core'
import { FlexCol } from 'pages/Farms'
import { useContext } from 'react'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components/macro'

import { ButtonPrimary } from '../../components/Button'
import CurrencyLogo from '../../components/CurrencyLogo'
import { RowBetween, RowFixed } from '../../components/Row'
import { Field } from '../../state/mint/actions'
import { ThemedText } from '../../theme'

const ModalSectionFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;

  padding: 4px 16px;
`
export function ConfirmAddModalBottom({
  noLiquidity,
  price,
  currencies,
  parsedAmounts,
  poolTokenPercentage,
  onAdd,
}: {
  noLiquidity?: boolean
  price?: Fraction
  currencies: { [field in Field]?: Currency }
  parsedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  poolTokenPercentage?: Percent
  onAdd: () => void
}) {
  const theme = useContext(ThemeContext)

  return (
    <ModalSectionFlex>
      <RowBetween>
        <ThemedText.DeprecatedBody>
          <>{currencies[Field.CURRENCY_A]?.symbol} Deposited</>
        </ThemedText.DeprecatedBody>
        <RowFixed>
          <CurrencyLogo currency={currencies[Field.CURRENCY_A]} style={{ marginRight: '8px' }} />
          <ThemedText.DeprecatedBody>{parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}</ThemedText.DeprecatedBody>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <ThemedText.DeprecatedBody>
          <>{currencies[Field.CURRENCY_B]?.symbol} Deposited</>
        </ThemedText.DeprecatedBody>
        <RowFixed>
          <CurrencyLogo currency={currencies[Field.CURRENCY_B]} style={{ marginRight: '8px' }} />
          <ThemedText.DeprecatedBody>{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}</ThemedText.DeprecatedBody>
        </RowFixed>
      </RowBetween>
      <FlexCol style={{ alignItems: 'center' }}>
        <ThemedText.DeprecatedBody color={theme.textSecondary}>
          <>Rates</>
        </ThemedText.DeprecatedBody>
        <ThemedText.DeprecatedBody>
          {`1 ${currencies[Field.CURRENCY_A]?.symbol} = ${price?.toSignificant(4)} ${
            currencies[Field.CURRENCY_B]?.symbol
          }`}
        </ThemedText.DeprecatedBody>
        <ThemedText.DeprecatedBody>
          {`1 ${currencies[Field.CURRENCY_B]?.symbol} = ${price?.invert().toSignificant(4)} ${
            currencies[Field.CURRENCY_A]?.symbol
          }`}
        </ThemedText.DeprecatedBody>
      </FlexCol>
      <RowBetween style={{ justifyContent: 'center', gap: '8px' }}>
        <ThemedText.DeprecatedBody>
          <>Share of Pool:</>
        </ThemedText.DeprecatedBody>
        <ThemedText.DeprecatedBody>
          <>{noLiquidity ? '100' : poolTokenPercentage?.toSignificant(4)}%</>
        </ThemedText.DeprecatedBody>
      </RowBetween>
      <ButtonPrimary style={{ margin: '20px 0 0 0' }} onClick={onAdd}>
        <Text fontWeight={500} fontSize={20}>
          {noLiquidity ? <>Create Pool & Supply</> : <>Confirm Supply</>}
        </Text>
      </ButtonPrimary>
    </ModalSectionFlex>
  )
}
