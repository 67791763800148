import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { typeInput } from '../actions'

export default function useInputValue(): [string, (value: string) => void] {
  const state = useAppSelector((state) => state.farms.inputValue)
  const dispatch = useAppDispatch()

  const setState = useCallback(
    (newValue: string) => {
      dispatch(typeInput(newValue))
    },
    [dispatch]
  )

  return [state, setState]
}
