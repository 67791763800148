import toSubscriptNumber from './toSubscriptNumber'

export default function formatSmallAmount(numberStr: string, sigFigs: number): string {
  // Regex to find the first non-zero digit and its position
  const firstNonZeroMatch = numberStr.match(/0\.0*([1-9])/)
  if (firstNonZeroMatch) {
    // Regex to count the number of zeros after the decimal point and before the first non-zero digit
    const hiddenZerosMatch = numberStr.match(/0\.0*/)
    const hiddenZeros = hiddenZerosMatch ? hiddenZerosMatch[0].length - 2 : 0 // Subtract 2 for '0.'

    // Extract the significant digits after the leading zero(s)
    const significantDigits = numberStr.substring(firstNonZeroMatch.index + firstNonZeroMatch[0].length - 1)

    // Take the first significant digit to display normally
    const leadingNormalDigit = significantDigits.slice(0, 1)

    // Take the next digits up to sigFigs
    const nextDigits = significantDigits.slice(1, sigFigs - hiddenZeros + 1)

    // Construct the formatted number string
    let formattedNumber = '0.00' // Start with the fixed prefix
    if (hiddenZeros > 2) {
      // Adjust for the two zeros in the fixed prefix
      // Add the count of hidden zeros as subscript
      formattedNumber += toSubscriptNumber(hiddenZeros - 2)
    }
    formattedNumber += leadingNormalDigit + nextDigits // Add the leading normal digit and next digits

    return formattedNumber
  }
  return numberStr // Return the original string if no pattern match is found
}
