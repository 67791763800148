import { LoadingRows as BaseLoadingRows } from 'components/Loader/styled'
import styled from 'styled-components/macro'

import { ReactComponent as Search } from '../../assets/svg/icons/Search.svg'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'

export const TextDot = styled.div`
  height: 3px;
  width: 3px;
  background-color: ${({ theme }) => theme.deprecated_text2};
  border-radius: 50%;
`

export const Checkbox = styled.input`
  border: 1px solid ${({ theme }) => theme.deprecated_red3};
  height: 20px;
  margin: 0;
`

export const PaddedColumn = styled(AutoColumn)`
  padding: 20px;
`

export const MenuItem = styled(RowBetween)<{ redesignFlag?: boolean }>`
  padding: 6px 8px;
  height: 32px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) auto minmax(0, 72px);
  grid-gap: 8px;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  :hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.hoverState};
  }
  opacity: ${({ disabled, selected }) => (disabled || selected ? 0.5 : 1)};
`

export const SearchInputWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
`

export const SearchIcon = styled(Search)`
  height: 20px;
  width: 20px;
  margin-right: 16px;
  position: absolute;
  left: 8px;
  fill: ${({ theme }) => theme.textSecondary};
  z-index: 100;
`

export const SearchInput = styled.input<{ redesignFlag?: boolean }>`
  position: relative;
  display: flex;
  padding: 8px 56px 8px 32px;
  height: 36px;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  background: ${({ theme }) => theme.cardSecondary};
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  outline: none;
  border-radius: 6px;
  color: ${({ theme }) => theme.textPrimary};
  border-style: solid;
  -webkit-appearance: none;

  font-size: 16px;

  ::placeholder {
    color: ##64748b;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  transition: border 100ms;
  :focus {
    border: 1px solid ${({ theme, redesignFlag }) => (redesignFlag ? 'transparent' : theme.deprecated_primary1)};
    background-color: ${({ theme, redesignFlag }) => redesignFlag && theme.accentActionSoft};
    outline: none;
  }
`
export const Separator = styled.div<{ redesignFlag?: boolean }>`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.cardBorderSecondary};
  margin: 20px 0;
`

export const SeparatorDark = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.deprecated_bg3};
`

export const LoadingRows = styled(BaseLoadingRows)`
  grid-column-gap: 0.5em;
  grid-template-columns: repeat(12, 1fr);
  max-width: 960px;
  padding: 12px 20px;

  & > div:nth-child(4n + 1) {
    grid-column: 1 / 8;
    height: 1em;
    margin-bottom: 0.25em;
  }
  & > div:nth-child(4n + 2) {
    grid-column: 12;
    height: 1em;
    margin-top: 0.25em;
  }
  & > div:nth-child(4n + 3) {
    grid-column: 1 / 4;
    height: 0.75em;
  }
`
