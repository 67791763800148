import { combineReducers, createReducer } from '@reduxjs/toolkit'

import {
  DayDataV2Type,
  ProtocolDataV3Type,
  setDayDataV2,
  setDayDataV2Loading,
  setProtocolDataV3,
  setProtocolDataV3Loading,
} from './actions'

export type DayDataV2State = {
  [timestamp: number]: {
    dayDatasV2: DayDataV2Type[]
    lastUpdate: number
    loading: boolean
  }
}

export type ProtocolDataV3State = {
  [timestamp: number]: {
    dayDatasV2: ProtocolDataV3Type[]
    lastUpdate: number
    loading: boolean
  }
}

export const initialDayDataV2State: DayDataV2State = {}
export const initialProtocolDataV3State: ProtocolDataV3State = {}

export const dayDataV2Reducer = createReducer<DayDataV2State>({ dayDatasV2: [], loading: false }, (builder) => {
  builder
    .addCase(setDayDataV2, (state, { payload }) => {
      state.dayDatasV2 = payload
      state.loading = false
    })
    .addCase(setDayDataV2Loading, (state, { payload }) => {
      state.loading = payload
    })
})

export const protocolDataV3Reducer = createReducer<ProtocolDataV3State>(
  { protocolDatasV3: [], loading: false },
  (builder) => {
    builder
      .addCase(setProtocolDataV3, (state, { payload }) => {
        state.protocolDatasV3 = payload
        state.loading = false
      })
      .addCase(setProtocolDataV3Loading, (state, { payload }) => {
        state.loading = payload
      })
  }
)
const protocolDataReducer = combineReducers({
  dayDataV2: dayDataV2Reducer,
  protocolDataV3: protocolDataV3Reducer,
})

export default protocolDataReducer
