import { CurrencyAmount } from '@kodiak-finance/sdk-core'
import Loader from 'components/Loader'
import { useToken } from 'hooks/Tokens'
import { useCommunalFarmYearEmission } from 'hooks/useCommunalFarms'
import { useSubgraphTokenValue } from 'hooks/useSubgraphTokenPrice'
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { ThemedText } from 'theme'

import formatMoney from '../../../utils/formatMoney'
import { useFarm } from './context/FarmContext'
import { FlexRowCenter, InfoInnerBox } from './styleds'

const InfoCard = () => {
  const theme = useContext(ThemeContext)
  const farm = useFarm()
  const { totalLiquidityLocked, stakingToken: stakingTokenAddress, address } = farm
  const stakingToken = useToken(stakingTokenAddress)

  const totalLiquidityLockedCurrencyAmount = stakingToken
    ? CurrencyAmount.fromRawAmount(stakingToken, totalLiquidityLocked)
    : undefined

  const apr = useCommunalFarmYearEmission(farm) //useCommunalFarmApr(farm)
  const value = useSubgraphTokenValue(totalLiquidityLockedCurrencyAmount)

  return (
    <FlexRowCenter>
      <InfoInnerBox>
        <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" lineHeight="14px" color={theme.textSecondary}>
          TVL
        </ThemedText.DeprecatedBody>
        <ThemedText.DeprecatedBody fontSize="20px" fontWeight="400" lineHeight="28px" color={theme.textPrimary}>
          {value ? formatMoney(value) : <Loader></Loader>}
        </ThemedText.DeprecatedBody>
      </InfoInnerBox>

      <InfoInnerBox>
        <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" lineHeight="14px" color={theme.textSecondary}>
          MAX APR
        </ThemedText.DeprecatedBody>
        <ThemedText.DeprecatedBody fontSize="20px" fontWeight="400" lineHeight="28px" color={theme.textPrimary}>
          {apr ? `${apr.toFixed(2)}%` : <Loader></Loader>}
        </ThemedText.DeprecatedBody>
      </InfoInnerBox>

      <InfoInnerBox>
        <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" lineHeight="14px" color={theme.textSecondary}>
          Allocation
        </ThemedText.DeprecatedBody>
        <ThemedText.DeprecatedBody fontSize="20px" fontWeight="400" lineHeight="28px" color={theme.textPrimary}>
          {`$${parseFloat('0').toFixed(2)}`}
        </ThemedText.DeprecatedBody>
      </InfoInnerBox>
    </FlexRowCenter>
  )
}

export default InfoCard
