import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { CurrencyAmount, Percent, Token } from '@kodiak-finance/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { ElementName, Event, EventName } from 'components/AmplitudeAnalytics/constants'
import { TraceEvent } from 'components/AmplitudeAnalytics/TraceEvent'
import { sendEvent } from 'components/analytics'
import CurrencyLogo from 'components/CurrencyLogo'
import FormattedCurrencyAmount from 'components/FormattedCurrencyAmount'
import Loader from 'components/Loader'
import { AutoRow, RowFixed } from 'components/Row'
import Slider from 'components/Slider'
import { useApproveStep } from 'components/StepLine/common/ApproveStep'
import useStepWithNext from 'components/StepLine/context/hooks/useStepWithNext'
import StepLineModal from 'components/StepLine/StepLineModal'
import { CommunalFarmStake, useCommunalFarmByIsland, useCommunalFarmLockedStakesOf } from 'hooks/useCommunalFarms'
import { useFarmContract } from 'hooks/useContract'
import useDebouncedChangeHandler from 'hooks/useDebouncedChangeHandler'
import useIslandPositionDetails, { useIslandUnderlyingBalances } from 'hooks/useIslandPositionDetails'
import { useIsland } from 'hooks/useIslands'
import useTheme from 'hooks/useTheme'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import sumCurrencyAmount from 'lib/utils/sumCurrencyAmount'
import React, { useMemo } from 'react'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Text } from 'rebass'
import { useDerivedIslandBurnInfo } from 'state/islands/burn/hooks'

import { ButtonError, ButtonLight, ButtonPrimary } from '../../../../components/Button'
import { LightCard } from '../../../../components/Card'
import { AutoColumn } from '../../../../components/Column'
import DoubleCurrencyLogo from '../../../../components/DoubleLogo'
import { RowBetween } from '../../../../components/Row'
import { WRAPPED_NATIVE_CURRENCY } from '../../../../constants/tokens'
import { useToggleWalletModal } from '../../../../state/application/hooks'
import { useTransactionAdder } from '../../../../state/transactions/hooks'
import { TransactionType } from '../../../../state/transactions/types'
import { useIsExpertMode } from '../../../../state/user/hooks'
import { ThemedText } from '../../../../theme'
import { calculateGasMargin } from '../../../../utils/calculateGasMargin'
import { IslandContextType } from '../context/IslandContextType'
import WithdrawBalanceConstructorStep from './steps/WithdrawBalanceConstructorStep'
import WithdrawFromIslandStep from './steps/WithdrawFromIslandStep'
import WithdrawFromStakeStep from './steps/WithdrawFromStakeStep'
import * as S from './styleds' // adjust the path if necessary

const WithdrawView: React.FC<IslandContextType> = ({
  currencyA,
  currencyB,
  wrappedNativeCurrency,
  feeAmount,
  islandId,
  router,
}) => {
  const toggleWalletModal = useToggleWalletModal() // toggle wallet when disconnected

  const expertMode = useIsExpertMode()
  const { account, chainId, provider } = useWeb3React()
  const navigate = useNavigate()

  const [percent, onPercentSelect] = useState(0)
  const selectedPercentage = new Percent(percent, 100)
  // flag for receiving WETH
  const [receiveWETH, setReceiveWETH] = useState(false)

  const { island } = useIsland(islandId)
  const farm = useCommunalFarmByIsland(islandId)
  const farmContract = useFarmContract(farm?.address)

  const { totalAvailableLiquidity: totalFarmLiquidity, avaliableStakes: stakes } = useCommunalFarmLockedStakesOf(
    farm,
    account
  )

  console.log('useIsland:island', island)

  const positionDetails = useIslandPositionDetails(island, currencyA, currencyB, account)

  const {
    islandCurrency,
    islandTotalSupply,
    islandUnderlyingBalances,
    userBalanceShares,
    userUnderlyingBalances,
    userLiquidityPercentage,
  } = positionDetails

  const totalUserBalanceShares = useMemo(() => {
    if (userBalanceShares && totalFarmLiquidity) {
      return userBalanceShares.add(totalFarmLiquidity)
    } else if (userBalanceShares) {
      return userBalanceShares
    } else if (totalFarmLiquidity) {
      return totalFarmLiquidity
    }
    return undefined
  }, [userBalanceShares, totalFarmLiquidity])

  const underlyingBalances = useIslandUnderlyingBalances(
    island,
    totalUserBalanceShares as CurrencyAmount<Token>,
    currencyA,
    currencyB
  )

  const userFarmUnderlyingBalances = useIslandUnderlyingBalances(island, totalFarmLiquidity, currencyA, currencyB)
  const userBalanceUnderlyingBalances = useIslandUnderlyingBalances(
    island,
    userBalanceShares as CurrencyAmount<Token>,
    currencyA,
    currencyB
  )

  const selectedShares = useMemo(() => {
    if (totalUserBalanceShares && percent) {
      return totalUserBalanceShares.multiply(selectedPercentage)
    } else if (totalUserBalanceShares?.currency) {
      return CurrencyAmount.fromRawAmount(totalUserBalanceShares.currency, '0')
    }

    return undefined
  }, [selectedPercentage, totalUserBalanceShares])

  const selectedUnderlyingBalances = useIslandUnderlyingBalances(
    island,
    selectedShares as CurrencyAmount<Token>,
    currencyA,
    currencyB
  )

  const {
    discountedAmount0,
    discountedAmount1,
    discountedAmountShares,
    liquidityValue0,
    liquidityValue1,
    amount0Min,
    amount1Min,
    outOfRange,
    error,
  } = useDerivedIslandBurnInfo({ position: positionDetails, asWETH: receiveWETH, feeAmount })

  const theme = useTheme()

  const nativeCurrency = useNativeCurrency()
  const nativeWrappedSymbol = nativeCurrency.wrapped.symbol

  // const removed = account && island?.liquidity?.eq(0)
  const removed = false

  console.log('useIsland:error', error)

  const [percentForSlider, onPercentSelectForSlider] = useDebouncedChangeHandler(percent, onPercentSelect)

  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txnHash, setTxnHash] = useState<string | undefined>()
  const addTransaction = useTransactionAdder()

  async function onBurn() {
    if (!chainId || !provider || !account || !router) return

    if (
      !liquidityValue0 ||
      !liquidityValue1 ||
      !account ||
      !chainId ||
      !selectedPercentage ||
      !provider ||
      !island ||
      !amount0Min ||
      !amount1Min ||
      !discountedAmountShares ||
      !userLiquidityPercentage ||
      !userBalanceShares ||
      !islandTotalSupply
    ) {
      return
    }

    let estimate: typeof router.estimateGas.removeLiquidity | typeof router.estimateGas.removeLiquidityETH,
      method: (...args: any) => Promise<TransactionResponse>,
      args: [string, string, string, string, string],
      value: BigNumber | null,
      functionName: 'removeLiquidityETH' | 'removeLiquidity'

    if (currencyA.isNative || currencyB.isNative) {
      estimate = router.estimateGas.removeLiquidityETH
      method = router.removeLiquidityETH
      functionName = 'removeLiquidityETH'
      args = [
        islandId,
        discountedAmountShares.toString(),
        amount0Min?.quotient.toString(),
        amount1Min?.quotient.toString(),
        account,
      ]
      value = null
    } else {
      estimate = router.estimateGas.removeLiquidity
      console.log('withdrawProps:estimate', estimate)
      method = router.removeLiquidity
      functionName = 'removeLiquidity'
      args = [
        islandId,
        discountedAmountShares.toString(),
        amount0Min?.quotient.toString(),
        amount1Min?.quotient.toString(),
        account,
      ]
      value = null
    }

    const transactionRequest = await router.populateTransaction[functionName](...args)
    const calldata = transactionRequest.data

    console.log('withdrawProps:Calldata:', calldata)
    console.log('withdrawProps:args', args)
    // console.log('withdrawProps:amountMaxes:', amount0Max, amount1Max)

    setAttemptingTxn(true)
    await estimate(...args)
      .then((estimatedGasLimit) =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit),
        }).then((response) => {
          setAttemptingTxn(false)

          addTransaction(response, {
            type: TransactionType.REMOVE_LIQUIDITY_ISLAND,
            islandId,
            tokenA: 'address' in currencyA ? currencyA.address : undefined,
            tokenB: 'address' in currencyB ? currencyB.address : undefined,
            sharesBurned: discountedAmountShares.toString(),
            tokenAAmount: amount0Min.quotient.toString(),
            tokenBAmount: amount1Min.quotient.toString(),
          })

          setTxnHash(response.hash)

          sendEvent({
            category: 'Liquidity',
            action: 'Remove',
            label: [currencyA?.symbol, currencyB?.symbol, islandCurrency?.symbol].join('/'),
          })
        })
      )
      .catch((error) => {
        setAttemptingTxn(false)
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txnHash || hashStake) {
      onPercentSelectForSlider(0)
    }
    setAttemptingTxn(false)
    setAttemptingTxnStake(false)
    setTxnHash('')
    setHashStake('')
  }, [onPercentSelectForSlider, txnHash])

  function modalHeader() {
    return (
      <AutoColumn gap="sm" style={{ padding: '16px' }}>
        <RowBetween align="flex-end">
          <Text fontSize={16} fontWeight={500}>
            <>Pooled {selectedUnderlyingBalances?.amount0?.currency?.symbol}:</>
          </Text>
          <RowFixed>
            <Text fontSize={16} fontWeight={500} marginLeft="6px">
              {selectedUnderlyingBalances?.amount0 && (
                <FormattedCurrencyAmount currencyAmount={selectedUnderlyingBalances.amount0} />
              )}
            </Text>
            <CurrencyLogo
              size="20px"
              style={{ marginLeft: '8px' }}
              currency={selectedUnderlyingBalances?.amount0?.currency}
            />
          </RowFixed>
        </RowBetween>
        <RowBetween align="flex-end">
          <Text fontSize={16} fontWeight={500}>
            <>Pooled {selectedUnderlyingBalances?.amount1?.currency?.symbol}:</>
          </Text>
          <RowFixed>
            <Text fontSize={16} fontWeight={500} marginLeft="6px">
              {selectedUnderlyingBalances?.amount1 && (
                <FormattedCurrencyAmount currencyAmount={selectedUnderlyingBalances.amount1} />
              )}
            </Text>
            <CurrencyLogo
              size="20px"
              style={{ marginLeft: '8px' }}
              currency={selectedUnderlyingBalances?.amount1?.currency}
            />
          </RowFixed>
        </RowBetween>
        {/* {feeValue0?.greaterThan(0) || feeValue1?.greaterThan(0) ? (
          <>
            <ThemedText.DeprecatedItalic
              fontSize={12}
              color={theme.deprecated_text2}
              textAlign="left"
              padding={'8px 0 0 0'}
            >
              <>You will also collect fees earned from this position.</>
            </ThemedText.DeprecatedItalic>
            <RowBetween>
              <Text fontSize={16} fontWeight={500}>
                <>{feeValue0?.currency?.symbol} Fees Earned:</>
              </Text>
              <RowFixed>
                <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                  {feeValue0 && <FormattedCurrencyAmount currencyAmount={feeValue0} />}
                </Text>
                <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={feeValue0?.currency} />
              </RowFixed>
            </RowBetween>
            <RowBetween>
              <Text fontSize={16} fontWeight={500}>
                <>{feeValue1?.currency?.symbol} Fees Earned:</>
              </Text>
              <RowFixed>
                <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                  {feeValue1 && <FormattedCurrencyAmount currencyAmount={feeValue1} />}
                </Text>
                <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={feeValue1?.currency} />
              </RowFixed>
            </RowBetween>
          </>
        ) : null} */}
        <ButtonPrimary mt="16px" onClick={onBurn}>
          <>Remove</>
        </ButtonPrimary>
      </AutoColumn>
    )
  }

  const showCollectAsWeth = Boolean(
    liquidityValue0?.currency &&
      liquidityValue1?.currency &&
      (liquidityValue0.currency.isNative ||
        liquidityValue1.currency.isNative ||
        WRAPPED_NATIVE_CURRENCY[liquidityValue0.currency.chainId]?.equals(liquidityValue0.currency.wrapped) ||
        WRAPPED_NATIVE_CURRENCY[liquidityValue1.currency.chainId]?.equals(liquidityValue1.currency.wrapped))
  )
  const approveStep = useApproveStep(totalUserBalanceShares, router?.address, true)

  const [islandLiquidityToWithdraw, setIslandLiquidityToWithdraw] = useState<CurrencyAmount<Token> | undefined>()
  const [stakesToWithdraw, setStakesToWithdraw] = useState<CommunalFarmStake[]>([])
  const onSelectShares = ({
    islandLiquidity,
    stakes: activeStakes,
  }: {
    islandLiquidity: CurrencyAmount<Token> | undefined
    stakes: CommunalFarmStake[]
  }) => {
    setStakesToWithdraw(activeStakes)

    const sum = sumCurrencyAmount(
      activeStakes.map((item) => item.liquidity),
      islandLiquidity
    )
    console.log(`Debug:onWithdraw`, sum?.toExact(), islandLiquidity?.toExact())

    setIslandLiquidityToWithdraw(sum)
  }

  const onConfirmWithdrawLiquidity = () => {
    if (!islandLiquidityToWithdraw) return

    router?.removeLiquidity
  }

  const withdrawConstructorStep = useStepWithNext('WithdrawBalanceConstructorStep', WithdrawBalanceConstructorStep, {
    selectedShares,
    userBalanceShares,
    stakes,
    island,
    onSelect: onSelectShares,
  })

  const [attemptingTxnStake, setAttemptingTxnStake] = useState(false)
  const [hashStake, setHashStake] = useState('')

  const onConfirmWithdrawStakes = async () => {
    if (!stakesToWithdraw || !farmContract) return

    setAttemptingTxnStake(true)
    const args = stakesToWithdraw.map((stake) => stake.kekId)
    try {
      const gas = await farmContract.estimateGas.withdrawLockedMultiple(args)
      const response = await farmContract.withdrawLockedMultiple(args, {
        gasLimit: calculateGasMargin(gas),
      })
      setHashStake(response.hash)
      const sum = sumCurrencyAmount(stakesToWithdraw.map((item) => item.liquidity))

      addTransaction(response, {
        type: TransactionType.UNSTAKE_LIQUIDITY_ISLAND,
        amount: `${sum?.toSignificant(6) || 0} ${sum?.currency?.symbol}`,
      })
    } catch (e) {
      console.error(e)
    } finally {
      setAttemptingTxnStake(false)
    }
  }

  const islandLiquidityUnderlyingToWithdraw = useIslandUnderlyingBalances(
    island,
    islandLiquidityToWithdraw,
    currencyA,
    currencyB
  )

  const onConfirmWithdrawIsland = async () => {
    if (!islandLiquidityUnderlyingToWithdraw || !islandLiquidityToWithdraw || !router || !account) return
    const amount0Min = islandLiquidityUnderlyingToWithdraw.amount0?.multiply('90').divide('100')
    const amount1Min = islandLiquidityUnderlyingToWithdraw.amount1?.multiply('90').divide('100')
    if (!amount0Min || !amount1Min) return

    let estimate: typeof router.estimateGas.removeLiquidity | typeof router.estimateGas.removeLiquidityETH,
      method: (...args: any) => Promise<TransactionResponse>,
      args: [string, string, string, string, string],
      value: BigNumber | null,
      functionName: 'removeLiquidityETH' | 'removeLiquidity'

    if (currencyA.isNative || currencyB.isNative) {
      estimate = router.estimateGas.removeLiquidityETH
      method = router.removeLiquidityETH
      functionName = 'removeLiquidityETH'
      args = [
        islandId,
        islandLiquidityToWithdraw.quotient.toString(),
        amount0Min?.quotient.toString(),
        amount1Min?.quotient.toString(),
        account,
      ]
      value = null
    } else {
      estimate = router.estimateGas.removeLiquidity
      console.log('withdrawProps:estimate', estimate)
      method = router.removeLiquidity
      functionName = 'removeLiquidity'
      args = [
        islandId,
        islandLiquidityToWithdraw.quotient.toString(),
        amount0Min?.quotient.toString(),
        amount1Min?.quotient.toString(),
        account,
      ]
      value = null
    }

    setAttemptingTxn(true)
    try {
      setAttemptingTxn(true)
      const gas = await estimate(...args)
      const response = await method(...args, {
        gasLimit: calculateGasMargin(gas),
      })
      addTransaction(response, {
        type: TransactionType.REMOVE_LIQUIDITY_ISLAND,
        islandId,
        tokenA: 'address' in currencyA ? currencyA.address : undefined,
        tokenB: 'address' in currencyB ? currencyB.address : undefined,
        sharesBurned: islandLiquidityToWithdraw.quotient.toString(),
        tokenAAmount: amount0Min.quotient.toString(),
        tokenBAmount: amount1Min.quotient.toString(),
      })

      setTxnHash(response.hash)
    } catch (e) {
      console.error(e)
    } finally {
      setAttemptingTxn(false)
    }

    // JSBI.divide(
    //  JSBI.multiply(liquidityValue0.quotient, JSBI.BigInt(99)),
    //  JSBI.BigInt('100')
    //)
  }

  const withdrawFromStakeStep = useStepWithNext(
    'WithdrawFromStakeStep',
    WithdrawFromStakeStep,
    {
      stakes: stakesToWithdraw,
      attemptingTxn: attemptingTxnStake,
      pendingText: <>Withdraw from staking {stakesToWithdraw.length} positions</>,
      hash: hashStake,
      onWithdraw: onConfirmWithdrawStakes,
      autoConfirm: true,
      waitForConfirmation: true,
    },
    stakesToWithdraw.length === 0
  )
  const withdrawFromIslandStep = useStepWithNext('WithdrawFromIslandStep', WithdrawFromIslandStep, {
    shares: islandLiquidityToWithdraw,
    attemptingTxn,
    pendingText: (
      <>
        Withdraw from island {islandLiquidityToWithdraw?.toSignificant(6)}{' '}
        {islandLiquidityToWithdraw?.currency.symbol?.replace('RAKIS', 'KODIAK')}
      </>
    ),
    hash: txnHash,
    onWithdraw: onConfirmWithdrawIsland,
    autoConfirm: false,
    waitForConfirmation: false,
  })

  const steps = [approveStep, withdrawConstructorStep, withdrawFromStakeStep, withdrawFromIslandStep]

  return (
    <>
      <StepLineModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        steps={steps}
        header={<>Withdraw from Island</>}
        onFinish={handleDismissConfirmation}
      />

      {positionDetails ? (
        <AutoColumn gap="lg">
          <RowBetween>
            <RowFixed>
              <DoubleCurrencyLogo
                currency0={liquidityValue0?.currency}
                currency1={liquidityValue1?.currency}
                size={20}
                margin={true}
              />
              <ThemedText.DeprecatedLabel
                ml="10px"
                fontSize="20px"
              >{`${currencyA?.symbol}/${currencyB?.symbol}`}</ThemedText.DeprecatedLabel>
            </RowFixed>
            {/* <RangeBadge removed={removed} inRange={!outOfRange} /> */}
          </RowBetween>
          <LightCard>
            <AutoColumn gap="md">
              <RowBetween>
                <ThemedText.DeprecatedMain fontWeight={400}>
                  <>Amount</>
                </ThemedText.DeprecatedMain>
                <S.ResponsiveHeaderText>
                  <>{percentForSlider}%</>
                </S.ResponsiveHeaderText>
              </RowBetween>

              <RowBetween>
                <AutoRow gap="4px" justify="flex-end">
                  <S.SmallMaxButton onClick={() => onPercentSelect(25)} width="25%">
                    <>25%</>
                  </S.SmallMaxButton>
                  <S.SmallMaxButton onClick={() => onPercentSelect(50)} width="25%">
                    <>50%</>
                  </S.SmallMaxButton>
                  <S.SmallMaxButton onClick={() => onPercentSelect(75)} width="25%">
                    <>75%</>
                  </S.SmallMaxButton>
                  <S.SmallMaxButton onClick={() => onPercentSelect(100)} width="25%">
                    <>Max</>
                  </S.SmallMaxButton>
                </AutoRow>
              </RowBetween>
              <Slider value={percentForSlider} onChange={onPercentSelectForSlider} />
            </AutoColumn>
          </LightCard>
          <LightCard>
            <AutoColumn gap="md">
              <RowBetween>
                <Text fontSize={16} fontWeight={500}>
                  <>Pooled {selectedUnderlyingBalances?.amount0?.currency?.symbol}:</>
                </Text>
                <RowFixed>
                  <Text fontSize={16} fontWeight={500} marginLeft="6px">
                    {selectedUnderlyingBalances?.amount0 && (
                      <FormattedCurrencyAmount
                        significantDigits={6}
                        currencyAmount={selectedUnderlyingBalances?.amount0}
                      />
                    )}
                  </Text>
                  <CurrencyLogo
                    size="20px"
                    style={{ marginLeft: '8px' }}
                    currency={selectedUnderlyingBalances?.amount0?.currency}
                  />
                </RowFixed>
              </RowBetween>
              <RowBetween>
                <Text fontSize={16} fontWeight={500}>
                  <>Pooled {selectedUnderlyingBalances?.amount1?.currency?.symbol}:</>
                </Text>
                <RowFixed>
                  <Text fontSize={16} fontWeight={500} marginLeft="6px">
                    {selectedUnderlyingBalances?.amount1 && (
                      <FormattedCurrencyAmount
                        significantDigits={6}
                        currencyAmount={selectedUnderlyingBalances?.amount1}
                      />
                    )}
                  </Text>
                  <CurrencyLogo
                    size="20px"
                    style={{ marginLeft: '8px' }}
                    currency={selectedUnderlyingBalances?.amount1?.currency}
                  />
                </RowFixed>
              </RowBetween>
              {/* {feeValue0?.greaterThan(0) || feeValue1?.greaterThan(0) ? (
                  <>
                    <Break />
                    <RowBetween>
                      <Text fontSize={16} fontWeight={500}>
                        <>{feeValue0?.currency?.symbol} Fees Earned:</>
                      </Text>
                      <RowFixed>
                        <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                          {feeValue0 && <FormattedCurrencyAmount significantDigits={6} currencyAmount={feeValue0} />}
                        </Text>
                        <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={feeValue0?.currency} />
                      </RowFixed>
                    </RowBetween>
                    <RowBetween>
                      <Text fontSize={16} fontWeight={500}>
                        <>{feeValue1?.currency?.symbol} Fees Earned:</>
                      </Text>
                      <RowFixed>
                        <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                          {feeValue1 && <FormattedCurrencyAmount significantDigits={6} currencyAmount={feeValue1} />}
                        </Text>
                        <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={feeValue1?.currency} />
                      </RowFixed>
                    </RowBetween>
                  </>
                ) : null} */}
            </AutoColumn>
          </LightCard>

          {/* {showCollectAsWeth && (
            <RowBetween>
              <ThemedText.DeprecatedMain>
                <>Collect as {nativeWrappedSymbol}</>
              </ThemedText.DeprecatedMain>
              <Toggle
                id="receive-as-weth"
                isActive={receiveWETH}
                toggle={() => setReceiveWETH((receiveWETH) => !receiveWETH)}
              />
            </RowBetween>
          )} */}

          {!account ? (
            <TraceEvent
              events={[Event.onClick]}
              name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
              properties={{ received_swap_quote: false }}
              element={ElementName.CONNECT_WALLET_BUTTON}
            >
              <ButtonLight onClick={toggleWalletModal}>
                <>Connect Wallet</>
              </ButtonLight>
            </TraceEvent>
          ) : (
            <div style={{ display: 'flex' }}>
              <AutoColumn gap="12px" style={{ flex: '1' }}>
                <ButtonError
                  onClick={() => setShowConfirm(true)}
                  disabled={removed || percent === 0 || !liquidityValue0}
                  error={!liquidityValue0 && !liquidityValue1 && !userBalanceShares}
                >
                  <Text fontSize={20} fontWeight={500}>
                    {percent === 0 ? <>Enter a percent</> : <>Remove</>}
                  </Text>
                </ButtonError>
              </AutoColumn>
            </div>
          )}
        </AutoColumn>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default WithdrawView
