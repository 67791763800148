import { gql, useQuery } from '@apollo/client'
import { useSubgraphClient } from 'index'
import { useEffect } from 'react'
import { useSetDayDataV2 } from 'state/protocolData/hooks/useSetDayDataV2'

const DAY_DATA_V2_QUERY = gql`
  query dayDataV2 {
    uniswapDayDatas {
      id
      date
      dailyVolumeETH
      dailyVolumeUSD
      totalLiquidityUSD
    }
  }
`

export const useProtocolDataV2 = () => {
  const apolloClient = useSubgraphClient('v2')

  const { setDayData, setLoading } = useSetDayDataV2()

  const { data, loading, error, refetch } = useQuery(DAY_DATA_V2_QUERY, {
    client: apolloClient,
    // pollingInterval: ms`90s`,
  })

  const allDatas = data?.uniswapDayDatas

  // Processing the received data
  useEffect(() => {
    if (!loading && data) {
      setDayData(data.uniswapDayDatas)
    }
    setLoading(loading)
  }, [data, loading, setDayData, setLoading])

  useEffect(() => {
    if (error) {
      console.error('Error fetching Day Data V2:', error)
    }
  }, [error])

  const tvlUSD = allDatas?.length ? parseFloat(allDatas[allDatas.length - 1].totalLiquidityUSD) : null

  const totalVolumeUSD = allDatas?.reduce((total, data) => {
    return total + parseFloat(data.dailyVolumeUSD)
  }, 0)

  const formattedData = {
    tvlUSD,
    totalVolumeUSD,
  }

  return {
    isLoading: loading,
    data: formattedData,
    isError: !!error,
    refetch,
  }
}
