import { FlexRowCenterBetween } from 'pages/Staking/styleds'
import styled from 'styled-components/macro'
import { opacify } from 'theme/utils'

import { FlexCol, FlexRowCenter } from '../styleds'

export const IslandCardFlex = styled.div`
  background: ${({ theme }) => theme.cardPrimary};
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  border-radius: 8px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09);
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const IslandAnalyticsCard = styled(IslandCardFlex)`
  height: fit-content;
  /* width: 560px; */
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
     width: 100%;
  `};
`

export const IslandAnalyticsCardHeader = styled(FlexRowCenterBetween)`
  width: 100%;
  height: 34px;
`

export const IslandAnalyticsCardFooter = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const GraphModifierFlex = styled(FlexCol)`
  width: calc(50% - 8px);
`

export const GraphTypeSwitchWrapper = styled.div`
  height: 100%;
  width: fit-content;

  .option {
    width: fit-content;
  }
`

export const GraphFlex = styled.div`
  width: 100%;
  height: 182px;

  background: ${({ theme }) => theme.cardSecondary};
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};

  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  .recharts-tooltip-cursor {
    fill: ${({ theme }) => opacify(20, theme.buttonPrimary)};
  }
`

export const StrategyTooltipFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 16px;

  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  border-radius: 8px;
  background: ${({ theme }) => theme.cardPrimary};

  ${FlexRowCenter} {
    gap: 4px;
  }
`
