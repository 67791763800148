import { createReducer } from '@reduxjs/toolkit'

import { setApprovalCache } from './actions'

export interface ApprovalCacheState {
  [chainId: number]: {
    [address: string]: {
      [spender: string]: {
        [tokenAddress: string]: boolean
      }
    }
  }
}

const initialState: ApprovalCacheState = {}

export default createReducer(initialState, (builder) => {
  builder.addCase(setApprovalCache, (state, action) => {
    const { address, chainId, spender, cache } = action.payload
    if (!state[chainId]) {
      state[chainId] = {}
    }
    if (!state[chainId][address]) {
      state[chainId][address] = {}
    }
    if (!state[chainId][address][spender]) {
      state[chainId][address][spender] = {}
    }
    state[chainId][address][spender][action.payload.tokenAddress] = cache
  })
})
