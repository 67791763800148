import { CurrencyAmount, Percent } from '@kodiak-finance/sdk-core'
import { FeeAmount } from '@kodiak-finance/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import { useToken } from 'hooks/Tokens'
import useIslandPositionDetails from 'hooks/useIslandPositionDetails'
import { usePool } from 'hooks/usePools'
import JSBI from 'jsbi'
import { ReactNode, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { unwrappedToken } from 'utils/unwrappedToken'

import { AppState } from '../../index'
import { selectPercent } from './actions'

export function useBurnIslandState(): AppState['burnIsland'] {
  return useAppSelector((state) => state.burnIsland)
}

interface DerivedIslandBurnInfoArgs {
  position: ReturnType<typeof useIslandPositionDetails>
  asWETH: boolean
  feeAmount: FeeAmount
}

export function useDerivedIslandBurnInfo({ position, asWETH, feeAmount }: DerivedIslandBurnInfoArgs) {
  const { percent } = useBurnIslandState()
  const { account } = useWeb3React()

  const token0 = useToken(position.island?.token0?.id)
  const token1 = useToken(position.island?.token1?.id)

  const [, pool] = usePool(token0 ?? undefined, token1 ?? undefined, Number(feeAmount))
  if (!position) return {}
  const selectedPercentage = new Percent(percent, 100)

  const discountedAmount0 = position?.userUnderlyingBalances?.amount0
    ? selectedPercentage.multiply(position.userUnderlyingBalances.amount0).quotient
    : undefined
  const discountedAmount1 = position?.userUnderlyingBalances?.amount1
    ? selectedPercentage.multiply(position.userUnderlyingBalances.amount1).quotient
    : undefined

  const discountedAmountShares = position?.userBalanceShares
    ? selectedPercentage.multiply(position?.userBalanceShares?.quotient).quotient
    : undefined

  const liquidityValue0 =
    token0 && discountedAmount0
      ? CurrencyAmount.fromRawAmount(asWETH ? token0 : unwrappedToken(token0), discountedAmount0)
      : undefined
  const liquidityValue1 =
    token1 && discountedAmount1
      ? CurrencyAmount.fromRawAmount(asWETH ? token1 : unwrappedToken(token1), discountedAmount1)
      : undefined

  const amount0MinJSBI = liquidityValue0
    ? JSBI.divide(JSBI.multiply(liquidityValue0.quotient, JSBI.BigInt(99)), JSBI.BigInt('100'))
    : undefined
  const amount1MinJSBI = liquidityValue1
    ? JSBI.divide(JSBI.multiply(liquidityValue1.quotient, JSBI.BigInt(99)), JSBI.BigInt('100'))
    : undefined

  const amount0Min =
    amount0MinJSBI && liquidityValue0
      ? CurrencyAmount.fromRawAmount(liquidityValue0.currency, amount0MinJSBI)
      : undefined
  const amount1Min =
    amount1MinJSBI && liquidityValue1
      ? CurrencyAmount.fromRawAmount(liquidityValue1.currency, amount1MinJSBI)
      : undefined

  console.log('withdrawProps:burnProps', {
    rawDiscountedAmount0: discountedAmount0?.toString(),
    rawDiscountedAmount1: discountedAmount1?.toString(),
    liquidityValue0,
    liquidityValue1,
    userLiquidityPercentage: selectedPercentage?.toFixed(),
    amount0Min,
    amount1Min,
    position,
    token0,
    token1,
    pool,
  })

  const outOfRange =
    pool && position
      ? pool.tickCurrent < position.island.tickLower || pool.tickCurrent > position.island.tickUpper
      : false
  let error: ReactNode | undefined
  if (!account) {
    error = <>Connect Wallet</>
  }
  if (percent === 0) {
    error = error ?? <>Continue</>
  }
  return {
    discountedAmount0,
    discountedAmount1,
    discountedAmountShares,
    liquidityValue0,
    liquidityValue1,
    amount0Min,
    amount1Min,
    outOfRange,
    error,
  }
}

export function useBurnIslandActionHandlers(): {
  onPercentSelect: (percent: number) => void
} {
  const dispatch = useAppDispatch()

  const onPercentSelect = useCallback(
    (percent: number) => {
      dispatch(selectPercent({ percent }))
    },
    [dispatch]
  )

  return {
    onPercentSelect,
  }
}
