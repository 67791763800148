import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

type Props = {
  onToggle: () => void
  isDarkMode: boolean
}

const Wrapper = styled.div`
  --light: #d8dbe0;
  --dark: #28292c;
  --link: rgb(27, 129, 112);
  --link-hover: rgb(24, 94, 82);
  position: relative;
  width: 44px;
  height: 24px;
`

const Input = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  display: none;
`

const Label = styled.label<{ isChecked: boolean }>`
  position: absolute;
  width: 44px;
  height: 24px;
  background-color: ${({ isChecked }) => (isChecked ? 'var(--light)' : 'var(--dark)')};
  border-radius: 12px;
  cursor: pointer;
  padding: 2px;
  transition: 0.3s;
`
const Slider = styled.span<{ isChecked: boolean }>`
  position: absolute;

  width: 20px;
  height: 20px;
  border-radius: 12px;
  transition: 0.3s;
  background-color: ${(props) => (props.isChecked ? 'var(--light)' : 'var(--dark)')};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--dark);
    transition: 0.3s;
    transform: ${(props) => (props.isChecked ? 'translateX(20px)' : 'translateX(0)')};
    box-shadow: ${(props) => (!props.isChecked ? 'inset 7px -4px 0px 0px var(--light)' : 'none')};
  }
`

const ThemeToggle: React.FC<Props> = ({ onToggle, isDarkMode }) => {
  const [isChecked, setIsChecked] = useState(isDarkMode)

  useEffect(() => {
    setIsChecked(isDarkMode)
  }, [isDarkMode])

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    // event.preventDefault() // Prevent default behavior
    console.log('clicked')
    setIsChecked(!isChecked)
    onToggle()
  }

  return (
    <Wrapper>
      <Label isChecked={!isDarkMode}>
        <Input checked={isChecked} onChange={handleToggle} readOnly />
        <Slider isChecked={!isDarkMode} />
      </Label>
    </Wrapper>
  )
}

export default ThemeToggle
