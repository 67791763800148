import { AutoRow } from 'components/Row'
import useStepWithNext from 'components/StepLine/context/hooks/useStepWithNext'
import { useStepLineState, useStepLineStateUpdate } from 'components/StepLine/context/StepLineContext'
import StepLineModal from 'components/StepLine/StepLineModal'
import { useKodiakRewards, useXKDK } from 'hooks/useContract'
import { useDividends } from 'hooks/useStaking'
import { useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { calculateGasMargin } from 'utils/calculateGasMargin'

import AllocateStep from './AllocateStep'
import AllocateViewStep, { AllocationStepState } from './AllocateViewStep'
import { useApproveUsageStep } from './ApproveUsageStep'
import DeallocateStep from './DeallocateStep'
import DeallocateViewStep from './DeallocateViewStep'
import { StyledController } from './styleds'

const useAllocateState = () => {
  const rewards = useKodiakRewards()
  const { amount } = useStepLineState<AllocationStepState>()

  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [hash, setHash] = useState('')
  const xKdk = useXKDK()

  const addTransaction = useTransactionAdder()

  const onAllocate = async () => {
    if (!xKdk || !amount || !rewards) return console.log('exit')
    const quotient = amount.quotient.toString()

    setAttemptingTxn(true)
    try {
      const gas = await xKdk.estimateGas.allocate(rewards.address, quotient, [])
      const res = await xKdk.allocate(rewards.address, quotient, [], {
        gasLimit: calculateGasMargin(gas),
      })
      if (res.hash) {
        setHash(res.hash)
        addTransaction(res, {
          type: TransactionType.DIVIDENDS_ALLOCATE,
          amount: `${amount?.toSignificant(6)} ${amount?.currency?.symbol}`,
        })
      }
    } catch (e) {
      console.error(e)
    } finally {
      setAttemptingTxn(false)
    }
  }

  return { amount, attemptingTxn, hash, onAllocate }
}

const useDeallocateState = () => {
  const rewards = useKodiakRewards()
  const xKdk = useXKDK()
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [hash, setHash] = useState('')
  const { amount } = useStepLineState<AllocationStepState>()

  const { userUsageAllocation } = useDividends()
  const addTransaction = useTransactionAdder()

  const onDeallocate = async () => {
    if (!xKdk || !amount || !rewards) return
    const quotient = amount.quotient.toString()

    setAttemptingTxn(true)
    try {
      const gas = await xKdk.estimateGas.deallocate(rewards.address, quotient, [])
      const res = await xKdk.deallocate(rewards.address, quotient, [], {
        gasLimit: calculateGasMargin(gas),
      })
      if (res.hash) {
        setHash(res.hash)
        addTransaction(res, {
          type: TransactionType.DIVIDENDS_DEALLOCATE,
          amount: `${amount?.toSignificant(6)} ${amount?.currency?.symbol}`,
        })
      }
    } catch (e) {
      console.error(e)
    } finally {
      setAttemptingTxn(false)
    }
  }

  return { amount, attemptingTxn, hash, onDeallocate }
}

const useOnBack = () => {
  const update = useStepLineStateUpdate()
  return () =>
    update({
      '__nexts/AllocateViewStep': false,
      '__nexts/DeallocateViewStep': false,
    })
}

const ManagerAllocationButtons = () => {
  const [isModalOpen, setIsModalOpen] = useState<false | 'allocate' | 'deallocate'>(false)
  const clear = () => setIsModalOpen(false)
  const rewards = useKodiakRewards()

  const onBack = useOnBack()
  const {
    amount: allocateAmount,
    attemptingTxn: allocateAttemptingTxn,
    hash: allocateHash,
    onAllocate,
  } = useAllocateState()
  const allocateStep = {
    ...useStepWithNext('AllocateViewStep', AllocateViewStep, {}),
    onBack,
  }
  const approveUsageStep = {
    ...useApproveUsageStep(allocateAmount, rewards?.address),
    onBack,
  }
  const allocateConfirmStep = useStepWithNext('allocate-step', AllocateStep, {
    amount: allocateAmount,
    attemptingTxn: allocateAttemptingTxn,
    pendingText: (
      <>
        Converting {allocateAmount?.toSignificant(6)} {allocateAmount?.currency.symbol}
      </>
    ),
    hash: allocateHash,
    autoConfirm: true,
    transactionWaiting: true,
    onAllocate,
  })
  const allocateSteps = [allocateStep, approveUsageStep, allocateConfirmStep]

  const {
    amount: deallocateAmount,
    attemptingTxn: deallocateAttemptingTxn,
    hash: deallocateHash,
    onDeallocate,
  } = useDeallocateState()

  const deallocateStep = {
    ...useStepWithNext('DeallocateViewStep', DeallocateViewStep, {}),
    onBack,
  }
  const deallocateConfirmStep = useStepWithNext('DeallocateStep', DeallocateStep, {
    amount: deallocateAmount,
    attemptingTxn: deallocateAttemptingTxn,
    pendingText: (
      <>
        Converting {deallocateAmount?.toSignificant(6)} {deallocateAmount?.currency.symbol}
      </>
    ),
    hash: deallocateHash,
    autoConfirm: true,
    transactionWaiting: true,
    onDeallocate,
  })
  const deallocateSteps = [deallocateStep, deallocateConfirmStep]
  const steps = isModalOpen === 'allocate' ? allocateSteps : deallocateSteps
  return (
    <>
      <StepLineModal
        steps={steps}
        isOpen={isModalOpen !== false}
        onDismiss={() => clear()}
        onFinish={() => clear()}
        header={isModalOpen === 'allocate' ? 'Allocate' : 'Deallocate'}
      />
      <AutoRow width="fit-content" gap="4px">
        <StyledController onClick={() => setIsModalOpen('deallocate')}>-</StyledController>
        <StyledController onClick={() => setIsModalOpen('allocate')}>+</StyledController>
      </AutoRow>
    </>
  )
}

export default ManagerAllocationButtons
