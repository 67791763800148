import { Currency, CurrencyAmount } from '@kodiak-finance/sdk-core'
import React, { useContext, useEffect, useState } from 'react'
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { ThemeContext } from 'styled-components'
import { ThemedText } from 'theme'
import { formatAmount, formatDollarAmount } from 'utils/formatDollarAmt'

import { FlexRowCenter } from '../styleds'
import * as S from './styleds'
import { filterDataByTimeframe, selectDataKey, selectTimeFormat, SnapshotType } from './utils/chartHelpers'

type TvlViewType = {
  unit: 'both' | 'token0' | 'token1'
  timeframe: '7' | '30' | '90' | 'max'
  period: '1' | '2'
  snapshots: SnapshotType[]
  currencyA?: Currency
  currencyB?: Currency
}

type CustomTickLabelProps = {
  x?: number
  y?: number
  payload?: any
  axisType?: 'xAxis' | 'yAxis'
  theme: any
}

type CustomTooltipProps = {
  active?: boolean
  payload?: any[]
  label?: string
  theme?: any
  xAxisFormatter: any
  activeToken: 'both' | string
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
  theme,
  xAxisFormatter,
  activeToken,
}) => {
  if (active && payload && payload.length) {
    return (
      <S.StrategyTooltipFlex>
        <ThemedText.DeprecatedBody fontSize="14px" fontWeight="700" lineHeight="20px" color={theme.textPrimary}>
          {xAxisFormatter(payload[0].payload.timestamp)}
        </ThemedText.DeprecatedBody>
        <FlexRowCenter>
          <ThemedText.DeprecatedBody fontSize="12px" fontWeight="400" lineHeight="20px" color={theme.textSecondary}>
            {activeToken === 'both' ? 'Total Value Locked (USD):' : `Amount (${activeToken})`}
          </ThemedText.DeprecatedBody>
          <ThemedText.DeprecatedBody fontSize="12px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
            {activeToken === 'both' ? formatDollarAmount(payload[0].value) : formatAmount(payload[0].value)}
          </ThemedText.DeprecatedBody>
        </FlexRowCenter>
      </S.StrategyTooltipFlex>
    )
  }
  return null
}

const TvlView: React.FC<TvlViewType> = ({ unit, timeframe, snapshots, currencyA, currencyB }) => {
  const theme = useContext(ThemeContext)
  const [filteredData, setFilteredData] = useState<SnapshotType[] | []>([])

  // const tempSnapshots = TempVaultSnapshots.data.kodiakVaultDailySnapshots
  //   .map((snapshot) => ({
  //     ...snapshot,
  //     timestamp: Number(snapshot.timestamp), // Convert timestamp to number for sorting
  //   }))
  //   .sort((a, b) => a.timestamp - b.timestamp)

  // useEffect(() => {
  //   const filteredData = filterDataByTimeframe(snapshots, timeframe)
  //   setFilteredData(filteredData)
  // }, [timeframe])

  const currencyWrappedA = currencyA?.wrapped
  const currencyWrappedB = currencyB?.wrapped

  const activeToken = unit === 'both' ? 'both' : unit === 'token0' ? currencyWrappedA?.symbol : currencyWrappedB?.symbol

  // REAL DATA

  useEffect(() => {
    if (snapshots && snapshots.length > 0) {
      // Create a copy of the snapshots array
      const snapshotsCopy = snapshots.map((snapshot) => ({
        ...snapshot,
        timestamp: Number(snapshot.timestamp),
        tvlToken0: CurrencyAmount.fromRawAmount(currencyWrappedA!, Number(snapshot._token0Amount)).toSignificant(
          currencyA?.decimals
        ),
        tvlToken1: CurrencyAmount.fromRawAmount(currencyWrappedB!, Number(snapshot._token1Amount)).toSignificant(
          currencyB?.decimals
        ),
      }))

      // Sort the copied array by timestamp in ascending order
      const sortedSnapshots = snapshotsCopy.sort((a, b) => a.timestamp - b.timestamp)

      // Filter the sorted snapshots based on the selected timeframe
      const newFilteredData = filterDataByTimeframe(sortedSnapshots, timeframe)

      // Update the state with the filtered data
      setFilteredData(newFilteredData)
    }
  }, [timeframe, snapshots])

  console.log('tvl:filtered:', filteredData)
  const xAxisFormatter = selectTimeFormat(timeframe)

  const CustomTickLabel: React.FC<CustomTickLabelProps> = ({ x, y, payload, axisType, theme }) => {
    const value =
      axisType === 'xAxis'
        ? xAxisFormatter(payload.value) // Format as time for x-axis
        : formatDollarAmount(payload.value) // Format as currency for y-axis

    return (
      <text
        x={x}
        y={y}
        dy={axisType === 'xAxis' ? 10 : 0}
        dx={axisType === 'xAxis' ? 0 : -14}
        textAnchor="middle"
        fill={theme.textSecondary}
        fontSize={12}
      >
        {value}
      </text>
    )
  }

  const CustomAssetTickLabel: React.FC<CustomTickLabelProps> = ({ x, y, payload, axisType, theme }) => {
    const value = formatAmount(payload.value) // Format as currency for y-axis

    return (
      <text x={x} y={y} dy={0} dx={-14} textAnchor="middle" fill={theme.textSecondary} fontSize={12}>
        {value}
      </text>
    )
  }

  const CustomXLabel = unit === 'both' ? CustomTickLabel : CustomAssetTickLabel

  // const tvlValues = TempVaultSnapshots.data.kodiakVaultDailySnapshots.map((snapshot) => {
  //   switch (unit) {
  //     case 'token0':
  //       return parseFloat(snapshot.vault._token0.totalValueLockedUSD)
  //     case 'token1':
  //       return parseFloat(snapshot.vault._token1.totalValueLockedUSD)
  //     default: // for 'both'
  //       return parseFloat(snapshot.totalValueLockedUSD)
  //   }
  // })

  // const averageTVL = tvlValues.reduce((a, b) => a + b, 0) / tvlValues.length
  // const maxTVL = Math.max(...tvlValues)
  // const minTVL = Math.max(Math.min(...tvlValues) - maxTVL * 0.1, 0) // Subtracts 10% of maxTVL from minTVL

  return (
    <S.GraphFlex style={{ overflow: 'hidden' }}>
      <ResponsiveContainer width="100%" height={182} minWidth="100%">
        <AreaChart data={filteredData} margin={{ top: 24, right: 8, left: -8, bottom: 0 }}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              {/* <stop offset="5%" stopColor="#3083DC" stopOpacity={0.9} />
              <stop offset="95%" stopColor="#3083DC" stopOpacity={0.4} /> */}
              <stop offset="5%" stopColor="#3083DC" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#3083DC" stopOpacity={0} />
            </linearGradient>
          </defs>

          <XAxis
            dataKey="timestamp"
            stroke="white"
            tickLine={false}
            axisLine={false}
            tick={<CustomTickLabel axisType="xAxis" theme={theme} />}
            minTickGap={5} // Adjust the minimum gap between ticks as needed
            tickFormatter={xAxisFormatter} // Ensure your xAxisFormatter is being used here
          />
          <YAxis
            dataKey={(snapshot) => selectDataKey(snapshot, unit)}
            stroke="white"
            tickLine={false}
            axisLine={false}
            tick={<CustomXLabel axisType="yAxis" theme={theme} />}
            minTickGap={5}
            // domain={[minTVL, maxTVL]}
          />
          <Tooltip
            content={<CustomTooltip theme={theme} xAxisFormatter={xAxisFormatter} activeToken={activeToken!} />}
          />
          <Area
            type="monotone"
            dataKey={(snapshot) => selectDataKey(snapshot, unit)}
            stroke="#82ca9e74"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </S.GraphFlex>
  )
}

export default TvlView
