import { ReactNode } from 'react'
import { Box } from 'rebass/styled-components'
import styled from 'styled-components/macro'
const Row = styled(Box)<{
  width?: string
  align?: string
  justify?: string
  padding?: string
  border?: string
  borderRadius?: string
  grow?: string
  shrink?: string
}>`
  width: ${({ width }) => width ?? '100%'};
  display: flex;
  padding: 0;
  align-items: ${({ align }) => align ?? 'center'};
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  flex-grow: ${({ grow }) => grow};
  flex-shrink: ${({ shrink }) => shrink};
`

export const RowBetween = styled(Row)`
  justify-content: space-between;
`

export const RowFlat = styled.div`
  display: flex;
  align-items: flex-end;
`

export const AutoRow = styled(Row)<{ gap?: string; justify?: string }>`
  flex-wrap: wrap;
  margin: ${({ gap }) => gap && `-${gap}`};
  justify-content: ${({ justify }) => justify && justify};

  & > * {
    margin: ${({ gap }) => gap} !important;
  }
`

export const RowFixed = styled(Row)<{ gap?: string; justify?: string }>`
  width: fit-content;
  margin: ${({ gap }) => gap && `-${gap}`};
`

type PropsOf<T> = T extends React.ComponentType<infer P> ? P : never

type RowTitledProps = {
  header?: ReactNode
  children?: ReactNode
  reverse?: boolean
} & PropsOf<typeof RowBetween>

export const RowTitled = ({ header, children, reverse = false, ...rest }: RowTitledProps) => {
  let elements = [
    <Row key="header" grow="0" shrink="0" width="fit-content">
      {header}
    </Row>,
    <Row
      key="content"
      grow="1"
      style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
      justify={reverse ? 'flex-start' : 'flex-end'}
      align="center"
    >
      {children}
    </Row>,
  ]

  if (reverse) {
    elements = elements.reverse()
  }

  return (
    <RowBetween {...rest}>
      {elements[0]}
      {elements[1]}
    </RowBetween>
  )
}

export default Row
