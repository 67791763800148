import { AutoColumn } from 'components/Column'
import { LoadingRows as BaseLoadingRows } from 'components/Loader/styled'
import { Text } from 'rebass'
import styled from 'styled-components/macro'

export const PageWrapper = styled(AutoColumn)`
  max-width: 912px;
  width: 100%;
  /* background: ${({ theme }) => theme.cardPrimary}; */
  /* border: 1px solid ${({ theme }) => theme.cardBorderPrimary}; */
  border-radius: 8px;

  padding: 40px 0;
  gap: 24px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};
`

export const FlexRow = styled.div`
  display: flex;
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled(FlexRow)`
  justify-content: space-between;
  background: ${({ theme }) => theme.cardPrimary};
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  border-radius: 8px;
  padding: 24px;
  gap: 16px;
  width: 100%;
  height: 130px;
`

export const HeaderTitleFlex = styled(FlexCol)`
  gap: 8px;
`

export const HeaderBoxFlex = styled(FlexRow)`
  gap: 16px;
  height: 100%;
`

export const HeaderBox = styled(FlexCol)`
  padding: 16px;
  gap: 8px;
  height: 100%;
  width: 200px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 120px;
  `};
  background: ${({ theme }) => theme.cardSecondary};
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  border-radius: 8px;
`

export const HeaderBoxDesktop = styled(HeaderBox)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

export const FilterFlex = styled(FlexRow)`
  width: 100%;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 36px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 2px;
  `}
`

export const SearchWrapper = styled.div`
  width: 300px;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

export const Option = styled.div<{ active: boolean }>`
  display: flex;
  width: auto;
  cursor: pointer;
  padding: 4px 8px;
  text-align: center;
  gap: 4px;
  border-radius: 3px;
  background: ${({ active, theme }) => (active ? theme.cardPrimary : 'transparent')};
  color: ${({ active, theme }) => (active ? theme.textPrimary : theme.textSecondary)};

  /* border: 1px solid ${({ theme }) => theme.backgroundOutline}; */

  pointer-events: ${({ active }) => active && 'none'};
`

export const Switch = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  padding: 6px 4px;
  border-radius: 6px;
  height: 36px;
  background: ${({ theme }) => theme.cardSecondary};
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  height: 100%;
`

export const SelectWrapper = styled.div`
  display: flex;
  padding: 6px 6px;
  align-items: center;
  /* gap: 10px; */
  border-radius: 4px;

  background: ${({ theme }) => theme.cardPrimary};
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  height: 100%;
`

export const OrientationButton = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 3px;
  background: ${({ active, theme }) => (active ? theme.cardSecondary : 'transparent')};
  padding: 4px;
  align-items: center;
  gap: 10px;
  pointer-events: ${({ active }) => active && 'none'};
`

export const ChangeOrientationButtonFlex = styled.div`
  cursor: pointer;

  display: flex;

  padding: 4px;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09);
  background: ${({ theme }) => theme.cardPrimary};
  height: 100%;
`

export const Wrapper = styled.div`
  position: relative;
  /* padding: 20px; */
`

export const ClickableText = styled(Text)`
  :hover {
    cursor: pointer;
  }
  color: ${({ theme }) => theme.deprecated_primary1};
`
export const MaxButton = styled.button<{ width: string }>`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.deprecated_primary5};
  border: 1px solid ${({ theme }) => theme.deprecated_primary5};
  border-radius: 0.5rem;
  font-size: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0.25rem 0.5rem;
  `};
  font-weight: 500;
  cursor: pointer;
  margin: 0.25rem;
  overflow: hidden;
  color: ${({ theme }) => theme.deprecated_primary1};
  :hover {
    border: 1px solid ${({ theme }) => theme.deprecated_primary1};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.deprecated_primary1};
    outline: none;
  }
`

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`

export const LoadingRows = styled(BaseLoadingRows)`
  min-width: 75%;
  max-width: 960px;
  grid-column-gap: 0.5em;
  grid-row-gap: 0.8em;
  grid-template-columns: repeat(3, 1fr);

  & > div:nth-child(4n + 1) {
    grid-column: 1 / 3;
  }
  & > div:nth-child(4n) {
    grid-column: 3 / 4;
    margin-bottom: 2em;
  }
`
