import { BigintIsh, CurrencyAmount } from '@kodiak-finance/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { ButtonPrimary } from 'components/Button'
import CurrencyLogo from 'components/CurrencyLogo'
import { useToken } from 'hooks/Tokens'
import { useFarmContract } from 'hooks/useContract'
import { CallStateResult, useSingleCallResult } from 'lib/hooks/multicall'
import { useContext, useMemo } from 'react'
import { useEffect, useState } from 'react'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { ThemedText } from 'theme'

import { useFarm } from './context/FarmContext'
import { CurrencyFlexBetween, FlexRowCenter, InnerCardFlex } from './styleds'

const EarnedCard = () => {
  const theme = useContext(ThemeContext)
  const farm = useFarm()
  const farmContract = useFarmContract(farm.address)
  const [rawEarned, setRawEarned] = useState<CallStateResult | undefined>(undefined)
  const { account } = useWeb3React()

  const { result: _rawEarned, error, ...rest } = useSingleCallResult(farmContract, 'earned', [account])
  useEffect(() => {
    setRawEarned((oldRawEarned) => {
      if (_rawEarned === undefined) {
        return oldRawEarned
      }
      return _rawEarned
    })
  }, [_rawEarned])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const rewardTokens = farm.rewardTokens.map((rewardToken) => useToken(rewardToken))

  const earned = useMemo(
    () =>
      (rawEarned as BigintIsh[][] | undefined)?.[0]?.map((item, index) => {
        return {
          token: rewardTokens[index],
          amount: rewardTokens[index] ? CurrencyAmount.fromRawAmount(rewardTokens[index]!, item) : undefined,
        }
      }),
    [rawEarned, rewardTokens]
  )

  return (
    <>
      <ThemedText.DeprecatedBody fontSize="14px" fontWeight="400" lineHeight="14px" color={theme.textPrimary}>
        Earned
      </ThemedText.DeprecatedBody>
      {earned?.map((item, index) => (
        <InnerCardFlex key={index}>
          <CurrencyFlexBetween>
            <FlexRowCenter key={`${index}-${item.token?.address}`}>
              <CurrencyLogo currency={item.token} size="20px" />
              <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
                {item.token?.symbol}
              </ThemedText.DeprecatedBody>
            </FlexRowCenter>
            <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
              {/* {userUnderlyingBalances?.amount0?.toFixed(2)} */}
              {item.amount?.toSignificant()}
            </ThemedText.DeprecatedBody>
          </CurrencyFlexBetween>
        </InnerCardFlex>
      ))}
      <ButtonPrimary
        onClick={() => {
          farmContract?.getReward()
        }}
        disabled={false}
      >
        <Text fontSize={20} fontWeight={500}>
          <>Claim</>
        </Text>
      </ButtonPrimary>
    </>
  )
}

export default EarnedCard
