import { Pair } from '@kodiak-finance/v2-sdk'
import V2PositionListItem from 'components/PositionListItem/v2'
import { RowBetween } from 'components/Row'
import React from 'react'
import { Link } from 'react-router-dom'

import * as S from './styled'
import V2HeaderRow from './V2HeaderRow'

type V2PositionListProps = React.PropsWithChildren<{
  v2PairsWithoutStakedAmount: any
  stakingPairs: any
  stakingInfosWithBalance: any
  account: any
}>

export default function V2PositionList({
  v2PairsWithoutStakedAmount,
  stakingPairs,
  stakingInfosWithBalance,
  account,
}: V2PositionListProps) {
  return (
    <S.MainContentWrapper>
      <V2HeaderRow />
      <>
        {v2PairsWithoutStakedAmount.map((v2Pair: Pair) => (
          <V2PositionListItem key={v2Pair.liquidityToken.address} pair={v2Pair} />
        ))}
        {/* {stakingPairs.map(
          (stakingPair, i) =>
            stakingPair[1] && ( // skip pairs that arent loaded
              <V2PositionListItem
                key={stakingInfosWithBalance[i].stakingRewardAddress}
                pair={stakingPair[1]}
                stakedBalance={stakingInfosWithBalance[i].stakedAmount}
              />
            )
        )} */}
      </>
      <S.PositionListFooter>
        <S.ResponsiveButtonPrimary
          id="find-pool-button"
          as={Link}
          to={`${process.env.REACT_APP_V2_ANALYTICS}/account/${account}`}
          target="_blank"
        >
          <RowBetween style={{ gap: '8px' }}>
            <span>Account Analytics and Accrued Fees</span>
            <span> ↗ </span>
          </RowBetween>
        </S.ResponsiveButtonPrimary>
        {/* <ResponsiveButtonSecondary as={Link} padding="6px 8px" to="/add/v2/ETH">
                      <>Create a pair</>
                    </ResponsiveButtonSecondary> */}
        <S.ResponsiveButtonPrimary id="find-pool-button" as={Link} to="/liquidity/v2/find" padding="6px 8px">
          {/* <Text fontWeight={500} fontSize={16}> */}
          <>Import Pool</>
          {/* </Text> */}
        </S.ResponsiveButtonPrimary>
        <S.ResponsiveButtonPrimary id="join-pool-button" as={Link} to="/liquidity/v2/add/ETH" padding="6px 8px">
          {/* <Text fontWeight={500} fontSize={16}> */}
          <>Add V2 Liquidity</>
          {/* </Text> */}
        </S.ResponsiveButtonPrimary>
      </S.PositionListFooter>
    </S.MainContentWrapper>
  )
}
