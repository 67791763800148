import { useEffect, useState } from 'react'

import useDebounce from './useDebounce'

export interface WindowDimension {
  width: number
  height: number
}

const useWindowDimensions = () => {
  const [rawDimensions, setRawDimensions] = useState<WindowDimension>({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  const debouncedDimensions = useDebounce(rawDimensions, 200)

  useEffect(() => {
    const handleResize = () => {
      setRawDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    // Immediately apply the current window dimensions
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Here, debouncedDimensions will only update after the debounce period has elapsed without any new resize events
  // This means your component using this hook will only re-render with the new dimensions after debouncing
  return debouncedDimensions
}

export default useWindowDimensions
