import { useWeb3React } from '@web3-react/core'
import { RowBetween } from 'components/Row'
import { useIslands } from 'hooks/useIslands'
import { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { formatDollarAmount } from 'utils/formatDollarAmt'

import * as S from '../Liquidity.styles'

export const TitleRow = styled(RowBetween)`
  color: ${({ theme }) => theme.deprecated_text2};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  `};
`
export const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 24px 0;
  max-width: 300px;
  /* min-height: 25vh; */
`

export default function IslandHeader() {
  const { account, chainId } = useWeb3React()

  const theme = useContext(ThemeContext)

  const { islands, isLoading, isFetching, isUninitialized, isError } = useIslands()

  // Success state
  const allIslands = islands ?? []
  const userIslands = islands?.filter((island) => island.userPosition) ?? []

  // Loading or uninitialized state
  //   if (isLoading || isFetching || isUninitialized) {
  //     return <Wrapper>{/* <PositionsLoadingPlaceholder /> */}</Wrapper>
  //   }

  const userTotalDeposits = userIslands?.reduce((total, island) => {
    const balanceShareUSD = island?.userPosition?.balanceSharesUSD

    return (
      total +
      (balanceShareUSD === undefined
        ? 0
        : typeof balanceShareUSD === 'string'
        ? parseFloat(balanceShareUSD)
        : balanceShareUSD)
    )
  }, 0)

  const kodiakTotalValueLocked = allIslands?.reduce((total, island) => {
    return total + (Number(island.totalValueLockedUSD) || 0)
  }, 0)

  const showConnectAWallet = Boolean(!account)

  return (
    <S.Header>
      <S.HeaderTitleFlex>
        <ThemedText.DeprecatedBody fontSize="18px" fontWeight="700">
          Islands
        </ThemedText.DeprecatedBody>

        <ThemedText.DeprecatedBody fontSize="14px" fontWeight="400" color={theme.textSecondary}>
          Deposit into Kodiak's automated liquidity management strategies.
        </ThemedText.DeprecatedBody>
      </S.HeaderTitleFlex>
      <S.HeaderBoxFlex>
        <S.HeaderBoxDesktop>
          <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" color={theme.textSecondary} lineHeight="14px">
            TVL
          </ThemedText.DeprecatedBody>

          <ThemedText.DeprecatedBody fontSize="20px" fontWeight="400" lineHeight="28px">
            {formatDollarAmount(kodiakTotalValueLocked) || '$0.00'}
          </ThemedText.DeprecatedBody>
        </S.HeaderBoxDesktop>

        <S.HeaderBox>
          <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" color={theme.textSecondary} lineHeight="14px">
            My Deposits
          </ThemedText.DeprecatedBody>

          <ThemedText.DeprecatedBody fontSize="20px" fontWeight="400" lineHeight="28px">
            {formatDollarAmount(userTotalDeposits) || '$0.00'}
          </ThemedText.DeprecatedBody>
        </S.HeaderBox>
      </S.HeaderBoxFlex>
    </S.Header>
  )
}
// ) : filteredPositions && closedPositions && tempIslandDetails.length > 0 ? (
