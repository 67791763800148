import { CurrencyAmount } from '@kodiak-finance/sdk-core'
import { PageName } from 'components/AmplitudeAnalytics/constants'
import { Trace } from 'components/AmplitudeAnalytics/Trace'
import { AutoColumn } from 'components/Column'
import Loader from 'components/Loader'
import { LoadingRows } from 'components/Loader/styled'
import MultiCurrencyLogo from 'components/MultiCurrencyLogo'
import PoweredByKodiak from 'components/PoweredByKodiak'
import { useToken } from 'hooks/Tokens'
import { useCommunalFarmApr, useCommunalFarms, useLockedAmountOfAccount } from 'hooks/useCommunalFarms'
import { useSubgraphTokenValue } from 'hooks/useSubgraphTokenPrice'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Farm } from 'state/farms/reducer'
import styled, { ThemeContext } from 'styled-components/macro'
import { MEDIA_WIDTHS, ThemedText } from 'theme'
import formatMoney from 'utils/formatMoney'

export const PageWrapper = styled(AutoColumn)`
  max-width: 912px;
  width: 100%;
  border-radius: 8px;

  padding: 40px 0;
  gap: 40px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};
`

export const MainContentWrapper = styled.main`
  background-color: ${({ theme }) => theme.cardPrimary};
  padding: 16px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09);

  width: 100%;
`
export const HeaderRow = styled.div`
  grid-area: header;
  display: grid;
  grid-template-columns: 340px repeat(4, 135px) 1fr;
  height: 60px;
`

export const HeaderCol = styled.div`
  display: flex;
  align-items: center;
`

const TableRow = styled.div`
  min-height: 60px;
  display: grid;
  user-select: none;
  width: 100%;
  grid-template-columns: 340px repeat(4, 135px) 1fr;
  transition: background-color, border-color 0.3s ease-in-out;

  /* &:last-of-type {
    margin: 8px 0 0 0;
  }
  & > div:not(:first-child) {
    text-align: center;
  } */

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    row-gap: 12px;
  `};

  & > div {
    padding-right: 16px;
  }
`

const LinkRow = styled(Link)`
  min-height: 60px;
  display: grid;
  cursor: pointer;
  user-select: none;

  width: 100%;

  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.cardBorderPrimary};

  grid-template-columns: 340px repeat(4, 135px) 1fr;
  transition: background-color, border-color 0.3s ease-in-out;

  /* &:last-of-type {
    margin: 8px 0 0 0;
  }
  & > div:not(:first-child) {
    text-align: center;
  } */
  :hover {
    background-color: ${({ theme }) => theme.cardSecondary};
    border-color: ${({ theme }) => theme.textPrimary};
  }

  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    /* flex-direction: row; */
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    row-gap: 12px;
  `};
`

const PrimaryPositionIdData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-right: 8px;
  }
`
export const FlexRowCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const PairText = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.textPrimary};
`
const GridCol = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const EarningsItem = ({ children }: { children: string }) => {
  const token = useToken(children)
  return token ? <>{token.name}</> : null
}

const FarmsRowLoader = () => {
  return (
    <TableRow>
      <LoadingRows>
        <div />
      </LoadingRows>
      <LoadingRows>
        <div />
      </LoadingRows>
      <LoadingRows>
        <div />
      </LoadingRows>
      <LoadingRows>
        <div />
      </LoadingRows>
    </TableRow>
  )
  // <LoadingRows>

  //   <div></div>
  //   <div></div>
  //   <div></div>
  // </LoadingRows>
}
const FarmsRow = ({ farm }: { farm: Farm }) => {
  const theme = useContext(ThemeContext)

  const stakingToken = useToken(farm?.stakingToken)

  const totalLiquidityLocked =
    stakingToken && farm ? CurrencyAmount.fromRawAmount(stakingToken, farm.totalLiquidityLocked) : undefined

  const apr = useCommunalFarmApr(farm)
  const locked = useLockedAmountOfAccount(farm)
  const value = useSubgraphTokenValue(totalLiquidityLocked)

  if (stakingToken === null || stakingToken === undefined) return null

  return (
    <LinkRow to={`/farms/${farm?.address}`} key={farm?.address}>
      <PrimaryPositionIdData>
        <MultiCurrencyLogo currencies={[stakingToken, ...(farm?.rewardTokens || [])]} size={32} margin />
        <FlexCol>
          <PairText>Stake {stakingToken.symbol}</PairText>
          <FlexRowCenter>
            <ThemedText.DeprecatedBody fontSize="10px" fontWeight="400" lineHeight="12px" color={theme.textSecondary}>
              Earn {farm?.rewardTokens.length} assets:{' '}
              {farm?.rewardTokens.map((token, index, arr) => (
                <>
                  <EarningsItem>{token}</EarningsItem>
                  {index !== arr.length - 1 && ', '}
                </>
              ))}
            </ThemedText.DeprecatedBody>
          </FlexRowCenter>
        </FlexCol>
      </PrimaryPositionIdData>

      <GridCol>
        <ThemedText.DeprecatedBody fontSize="16px" fontWeight="400" lineHeight="24px" color={theme.textPrimary}>
          {value === null ? <Loader /> : <>{formatMoney(value)}</>}
        </ThemedText.DeprecatedBody>
      </GridCol>

      <GridCol>
        <ThemedText.DeprecatedBody fontSize="16px" fontWeight="400" lineHeight="24px" color={theme.textPrimary}>
          {apr === null ? (
            <Loader />
          ) : (
            <>
              {totalLiquidityLocked?.equalTo('0') ? <>-</> : <>{apr !== 0 && apr < 0.01 ? '<0.01' : apr.toFixed(2)}%</>}
            </>
          )}

          {/* {apr !== 0 && apr < 0.01 ? "<0.01" : apr.toFixed(2)}% */}
        </ThemedText.DeprecatedBody>
      </GridCol>

      <GridCol>
        <ThemedText.DeprecatedBody fontSize="16px" fontWeight="400" lineHeight="24px" color={theme.textSecondary}>
          {locked?.toSignificant()}
        </ThemedText.DeprecatedBody>
      </GridCol>

      {/* <GridCol>
        <PlusButton>+</PlusButton>
      </GridCol> */}
    </LinkRow>
  )
}

const Farms = () => {
  const theme = useContext(ThemeContext)
  const farms = useCommunalFarms()
  return (
    <Trace page={PageName.FARMS_PAGE} shouldLogImpression>
      <PageWrapper>
        <MainContentWrapper>
          <HeaderRow>
            <HeaderCol>
              <ThemedText.DeprecatedBody fontSize="16px" fontWeight="700" lineHeight="24px" color={theme.textSecondary}>
                Farm
              </ThemedText.DeprecatedBody>
            </HeaderCol>

            <HeaderCol>
              <ThemedText.DeprecatedBody fontSize="16px" fontWeight="700" lineHeight="24px" color={theme.textSecondary}>
                TVL
              </ThemedText.DeprecatedBody>
            </HeaderCol>

            <HeaderCol>
              <ThemedText.DeprecatedBody fontSize="16px" fontWeight="700" lineHeight="24px" color={theme.textSecondary}>
                APR
              </ThemedText.DeprecatedBody>
            </HeaderCol>

            <HeaderCol>
              <ThemedText.DeprecatedBody fontSize="16px" fontWeight="700" lineHeight="24px" color={theme.textSecondary}>
                Total Deposit
              </ThemedText.DeprecatedBody>
            </HeaderCol>
            <HeaderCol></HeaderCol>
          </HeaderRow>

          {farms.map((farm, index) => (
            <>{farm ? <FarmsRow farm={farm} key={index} /> : <FarmsRowLoader key={index} />}</>
          ))}
        </MainContentWrapper>
      </PageWrapper>
      <PoweredByKodiak />
    </Trace>
  )
}

export default Farms

/*<FarmsRow farm={farm} key={index} />*/
