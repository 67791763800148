import { Connector } from '@web3-react/types'
import {
  coinbaseWalletConnection,
  fortmaticConnection,
  gnosisSafeConnection,
  injectedConnection,
  networkConnection,
  walletConnectConnection,
} from 'connection'
import { getChainInfo } from 'constants/chainInfo'
import { ALL_SUPPORTED_CHAIN_IDS, SupportedChainId } from 'constants/chains'
import { RPC_URLS } from 'constants/networks'

function getRpcUrls(chainId: SupportedChainId): [string] {
  switch (chainId) {
    // case SupportedChainId.MAINNET:
    // case SupportedChainId.RINKEBY:
    // case SupportedChainId.ROPSTEN:
    // case SupportedChainId.KOVAN:
    // case SupportedChainId.GOERLI:
    //   return [RPC_URLS[chainId]]
    // case SupportedChainId.OPTIMISM:
    //   return ['https://mainnet.optimism.io']
    // case SupportedChainId.OPTIMISTIC_KOVAN:
    //   return ['https://kovan.optimism.io']
    // case SupportedChainId.ARBITRUM_ONE:
    //   return ['https://arb1.arbitrum.io/rpc']
    // case SupportedChainId.ARBITRUM_RINKEBY:
    //   return ['https://rinkeby.arbitrum.io/rpc']
    // case SupportedChainId.POLYGON:
    //   return ['https://polygon-rpc.com/']

    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // BERA MIGRATION
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    case SupportedChainId.POLYGON_MUMBAI:
    case SupportedChainId.BERACHAIN_PRIVATE_TESTNET:
    case SupportedChainId.BERACHAIN_ARTIO_TESTNET:
      return [RPC_URLS[chainId]]

    default:
  }
  // Our API-keyed URLs will fail security checks when used with external wallets.
  throw new Error('RPC URLs must use public endpoints')
}

export function isChainAllowed(connector: Connector, chainId: number) {
  switch (connector) {
    case fortmaticConnection.connector:
      return chainId === SupportedChainId.BERACHAIN_ARTIO_TESTNET
    case injectedConnection.connector:
    case coinbaseWalletConnection.connector:
    case walletConnectConnection.connector:
    case networkConnection.connector:
    case gnosisSafeConnection.connector:
      return ALL_SUPPORTED_CHAIN_IDS.includes(chainId)
    default:
      return false
  }
}

export const switchChain = async (connector: Connector, chainId: SupportedChainId) => {
  if (!isChainAllowed(connector, chainId)) {
    throw new Error(`Chain ${chainId} not supported for connector (${typeof connector})`)
  } else if (connector === walletConnectConnection.connector || connector === networkConnection.connector) {
    await connector.activate(chainId)
  } else {
    const info = getChainInfo(chainId)
    const addChainParameter = {
      chainId,
      chainName: info.label,
      rpcUrls: getRpcUrls(chainId),
      nativeCurrency: info.nativeCurrency,
      blockExplorerUrls: [info.explorer],
    }
    await connector.activate(addChainParameter)
  }
}
