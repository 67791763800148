import { Currency, WETH9 } from '@kodiak-finance/sdk-core'
import { useWeb3React } from '@web3-react/core'
import Toggle from 'components/Toggle'
import { Context, useContext, useEffect, useState } from 'react'
import { Text } from 'rebass'
import styled, { DefaultTheme, ThemeContext } from 'styled-components/macro'

import { getTopPairs } from './defined'
import DefinedIFrame from './DefinedIFrame'

const ChartButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 1400px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    //   max-width: 1000px; 
  `}
`

const ChartFlipFlex = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  position: absolute;
  left: 0;
  height: fit-content;

  justify-content: center;
  align-items: center;
  gap: 4px;
`
const ChartWrapper = styled.div<{ open: boolean }>`
  width: ${({ open }) => (open ? '100%' : '0')};
  height: ${({ open }) => (open ? '700px' : '0')};
  /* max-width: 100vw; */
  max-width: 1440px;

  position: relative;

  ${({ theme }) => theme.mediaWidth.upToSmall`

    max-width: ${({ open }) => (open ? '100vw' : '0')};
    min-height: ${({ open }) => (open ? '70vh' : '0')};
  `}

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: ${({ open }) => (open ? '100vw' : '0')};
    height: ${({ open }) => (open ? '70vh' : '0')};
  `}
  ${({ theme }) => theme.mediaWidth.upToLarge`

width: ${({ open }) => (open ? '100%' : '0')};
  height: ${({ open }) => (open ? '700px' : '0')};

  `} /* #defined-embed {
    min-height: 35vh;
  } */

  #defined-embed {
    .MuiBox-root {
      padding: 8px;
    }
  }
`

interface DefinedChartProps {
  token0?: Currency
  token1?: Currency
  showChart: boolean
  handleToggleChart: () => void
}

const DefinedChart = ({ token0, token1, showChart, handleToggleChart }: DefinedChartProps) => {
  const theme = useContext(ThemeContext as Context<DefaultTheme>)
  const { chainId } = useWeb3React()

  const tokenAddress0 = token0?.wrapped?.address.toLowerCase() ?? '0x5806e416da447b267cea759358cf22cc41fae80f'
  const tokenAddress1 = token1?.wrapped?.address.toLowerCase() ?? '0x6581e59a1c8da66ed0d313a0d4029dce2f746cc5'

  const [pairInfo, setPairInfo] = useState() // State to hold the fetched tokens
  const [chartQuoteToken, setChartQuoteToken] = useState('token0')
  const [quoteCurrency, setQuoteCurrency] = useState<'TOKEN' | 'USD'>('USD')

  const weth9 = WETH9[chainId]?.wrapped?.address.toLowerCase() ?? '0x5806e416da447b267cea759358cf22cc41fae80f'

  const [searchToken, setSearchToken] = useState(
    token0?.wrapped?.address.toLowerCase() ?? '0x5806e416da447b267cea759358cf22cc41fae80f'
  )

  useEffect(() => {
    const fetchTopPairs = async () => {
      const pairs = await getTopPairs({
        searchToken,
        chainId,
      })

      if (pairs.length > 0) setPairInfo(pairs[0])
    }

    // if (topPairs.length === 0)
    fetchTopPairs()
  }, [token0, token1, searchToken, showChart])

  const handleFlipChartToken = (side: 'token0' | 'token1') => {
    setChartQuoteToken(side)
    setSearchToken(side === 'token0' ? tokenAddress0 : tokenAddress1)
  }

  useEffect(() => {
    if (!pairInfo) return

    if (searchToken === weth9) {
      setChartQuoteToken('token0')
      setQuoteCurrency('TOKEN')
      console.log('chart:TOKEN', true)
    } else {
      setChartQuoteToken('token0')
      setQuoteCurrency('USD')
    }
  }, [pairInfo, chainId, weth9])

  // console.log('chart:info', {
  //   pairInfo,
  //   weth9,
  //   chartQuoteToken,
  //   quoteCurrency,
  // })
  return (
    <>
      <ChartButtonWrapper>
        <ChartFlipFlex show={showChart}>
          <Text
            onClick={() => handleFlipChartToken('token0')}
            fontSize={searchToken === tokenAddress0 ? '20px' : '14px'}
            lineHeight={searchToken === tokenAddress0 ? '28px' : '20px'}
            fontWeight={searchToken === tokenAddress0 ? 600 : 400}
            color={searchToken === tokenAddress0 ? theme.buttonPrimary : theme.textSecondary}
            style={{ cursor: 'pointer' }}
          >
            {token0?.symbol || 'BERA'}
          </Text>
          <Text fontSize={14} lineHeight="20px">
            /
          </Text>
          <Text
            onClick={() => handleFlipChartToken('token1')}
            fontSize={searchToken === tokenAddress1 ? '20px' : '14px'}
            lineHeight={searchToken === tokenAddress1 ? '28px' : '20px'}
            fontWeight={searchToken === tokenAddress1 ? 600 : 400}
            color={searchToken === tokenAddress1 ? theme.buttonPrimary : theme.textSecondary}
            style={{ cursor: 'pointer' }}
          >
            {token1?.symbol || 'stgUSDC'}
          </Text>
        </ChartFlipFlex>

        <Text fontSize={14} lineHeight="20px" color="textSubtle" textAlign="center">
          {showChart ? 'Hide Chart' : 'Show Chart'}
        </Text>

        <Toggle id="toggle-defined-chart" isActive={showChart} toggle={handleToggleChart} />
      </ChartButtonWrapper>
      <ChartWrapper open={showChart}>
        {showChart && (
          <DefinedIFrame
            pairAddress={pairInfo?.pair?.address ?? '0x9e0759241a0fe84970318344a88dc517b7cbf5b1'}
            quoteToken={chartQuoteToken || 'token0'}
            // quoteToken={'token0'}
            quoteCurrency={quoteCurrency}
          />
        )}
      </ChartWrapper>
    </>
  )
}

export default DefinedChart
