import { useWeb3React } from '@web3-react/core'
import { useToken } from 'hooks/Tokens'
import { useCommunalFarmByIsland, useCommunalFarmLockedStakesOf } from 'hooks/useCommunalFarms'
import { useTokenBalance } from 'lib/hooks/useCurrencyBalance'
import { useCallback, useEffect, useState } from 'react'

import { useIslandContext } from '../hooks/useIslandContext'
import DepositView from './DepositView'
import StakeView from './StakeView'
import * as S from './styleds'
import UnstakeView from './UnstakeView'
import WithdrawView from './WithdrawView'

const IslandManagementCard = () => {
  const [direction, setDirection] = useState<'deposit' | 'withdraw' | 'stake' | 'unstake'>('deposit')
  const { account } = useWeb3React()
  const context = useIslandContext()
  const token = useToken(context.islandId)

  const lpBalance = useTokenBalance(account, token || undefined)
  const farm = useCommunalFarmByIsland(context.islandId)
  const { totalLiquidity } = useCommunalFarmLockedStakesOf(farm, account)

  const render = useCallback(() => {
    switch (direction) {
      case 'deposit':
        return <DepositView {...context} />
      case 'withdraw':
        return <WithdrawView {...context} />
      case 'stake':
        return <StakeView {...context} />
      case 'unstake':
        return <UnstakeView {...context} />
      default:
        return <>Error Direction</>
    }
  }, [context, direction])

  useEffect(() => {
    if ((!lpBalance || !lpBalance.greaterThan('0')) && direction === 'stake') {
      setDirection('deposit')
    }
    if ((!totalLiquidity || !totalLiquidity.greaterThan('0')) && direction === 'unstake') {
      setDirection('deposit')
    }
  }, [lpBalance, totalLiquidity])
  return (
    <S.IslandManagementCard>
      <S.DepositWithdrawSwitch>
        <S.DepositWithdrawOption
          onClick={() => {
            setDirection('deposit')
          }}
          active={direction === 'deposit'}
        >
          <>Deposit</>
        </S.DepositWithdrawOption>
        <S.DepositWithdrawOption
          onClick={() => {
            // When switching to custom slippage, use `auto` value as a default.
            setDirection('withdraw')
          }}
          active={direction === 'withdraw'}
          tabIndex={-1}
        >
          <>Withdraw</>
        </S.DepositWithdrawOption>
        {farm && (
          <>
            {lpBalance && lpBalance.greaterThan('0') && (
              <S.DepositWithdrawOption
                onClick={() => {
                  // When switching to custom slippage, use `auto` value as a default.
                  setDirection('stake')
                }}
                active={direction === 'stake'}
                tabIndex={-1}
              >
                <>Stake</>
              </S.DepositWithdrawOption>
            )}
            {totalLiquidity && totalLiquidity.greaterThan('0') && (
              <S.DepositWithdrawOption
                onClick={() => {
                  // When switching to custom slippage, use `auto` value as a default.
                  setDirection('unstake')
                }}
                active={direction === 'unstake'}
                tabIndex={-1}
              >
                <>Unstake</>
              </S.DepositWithdrawOption>
            )}
          </>
        )}
      </S.DepositWithdrawSwitch>
      {render()}
    </S.IslandManagementCard>
  )
}

export default IslandManagementCard
