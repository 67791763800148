import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 960px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
     width: 560px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
     width: 100%;
  `};
`
export const BackButton = styled.div`
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;

  align-self: flex-start;

  color: ${({ theme }) => theme.textSecondary};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  transition: all 0.3s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.textPrimary};
    font-weight: 700;
  }
`

export const CardRowFlex = styled.div`
  display: flex;
  gap: 16px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
     flex-direction: column;
  `};
`

export const StakeCardFlex = styled.div`
  background: ${({ theme }) => theme.cardPrimary};
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  border-radius: 8px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09);
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 24px;
`
export const StakeInfoCard = styled(StakeCardFlex)`
  width: 560px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
     width: 100%;
  `};
`

export const FarmTitleWrapper = styled.div`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 360px;
  background: ${({ theme }) => theme.cardSecondary};
  width: fit-content;
`
export const FarmTitle = styled.div``

export const FlexRowCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const CardFlex = styled.div`
  background: ${({ theme }) => theme.cardPrimary};
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  border-radius: 8px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09);
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const PositionCardFlex = styled(CardFlex)`
  width: 384px;
  justify-content: space-between;
  gap: 50px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
     width: 100%;
  `};
`
export const InnerCardFlex = styled.div`
  background: ${({ theme }) => theme.cardSecondary};
  border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  border-radius: 8px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

export const InnerCardFlexRow = styled(InnerCardFlex)`
  flex-direction: row;
`

export const PositionDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const InfoInnerBox = styled(InnerCardFlex)`
  width: 160px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
     width: 30%;
  `};
`
export const CurrencyFlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const CurrencyFlexColBetween = styled(CurrencyFlexBetween)`
  flex-direction: column;
  align-items: start;
`

export const ManagementCardFlex = styled(CardFlex)`
  height: fit-content;
  width: 384px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
     width: 100%;
  `};
`

export const Switch = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px;
  border-radius: 6px;
  height: 36px;
  background: ${({ theme }) => theme.cardSecondary};
`

export const Option = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  cursor: pointer;
  padding: 4px 12px;
  text-align: center;
  gap: 4px;
  border-radius: 3px;
  background: ${({ active, theme }) => (active ? theme.cardPrimary : 'transparent')};
  /* border: 1px solid ${({ theme }) => theme.backgroundOutline}; */

  pointer-events: ${({ active }) => active && 'none'};
`

export const DepositWithdrawSwitch = styled(Switch)`
  height: 46px;
`

export const DepositWithdrawOption = styled(Option)`
  height: 100%;
`

export const SecondCardRowFlex = styled(CardRowFlex)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
     flex-direction: column-reverse;
  `};
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const AdaptiveCardFlex = styled(CardFlex)<{ size?: 'md' | 'lg' }>`
  width: ${({ size }) => (size === 'md' ? '384px' : '560px')};

  ${({ theme }) => theme.mediaWidth.upToMedium`
     width: 100%;
  `};
`

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`

export const LockTimePositionHeader = styled.div`
  display: flex;
  color: ${({ theme }) => theme.textSecondary};
  justify-content: space-between;
`

export const EarningsCalculationHeader = styled.div`
  color: ${({ theme }) => theme.textPrimary};
`

export const EarningsCalculationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
`
export const EarningsCalculationCashFormat = styled.div`
  color: ${({ theme }) => theme.textSecondary};
  font-size: 70%;
`
export const EarningsCalculationTokenFormat = styled.div`
  color: ${({ theme }) => theme.textPrimary};
  font-size: 80%;
`
