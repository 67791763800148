import { CurrencyAmount } from '@kodiak-finance/sdk-core'
import { Token } from '@kodiak-finance/sdk-core'
import { ButtonError } from 'components/Button'
import { LightCard } from 'components/Card'
import Column, { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import Row, { RowBetween } from 'components/Row'
import ConfirmationTransactionStep, {
  ConfirmationTransactionStepProps,
} from 'components/StepLine/common/ConfirmationTransactionStep'
import { Text } from 'rebass'
import { useTheme } from 'styled-components'

type ConvertStepProps = {
  amount?: CurrencyAmount<Token>
  next: () => void
  onConvert: () => void
} & Omit<ConfirmationTransactionStepProps, 'children' | 'onConfirm'>

const ConvertStep = ({ amount, next, onConvert, ...rest }: ConvertStepProps) => {
  const theme = useTheme()

  return (
    <ConfirmationTransactionStep onConfirm={next} {...rest}>
      <Column gap="6px" width="100%">
        <LightCard>
          <AutoColumn gap="6px">
            <RowBetween>
              <Text color={theme.textPrimary} fontSize={18}>
                {amount?.toExact()}
              </Text>
              <Text>
                <Row>
                  <CurrencyLogo currency={amount?.currency} />
                  <Text marginLeft="4px" color={theme.textPrimary} fontSize={16}>
                    {amount?.currency.symbol}
                  </Text>
                </Row>
              </Text>
            </RowBetween>
          </AutoColumn>
        </LightCard>
        <ButtonError onClick={onConvert}>Convert</ButtonError>
      </Column>
    </ConfirmationTransactionStep>
  )
}

export default ConvertStep
