import { Currency, CurrencyAmount, Percent, Price, Token } from '@kodiak-finance/sdk-core'
import {
  encodeSqrtRatioX96,
  nearestUsableTick,
  Pool,
  Position,
  priceToClosestTick,
  TICK_SPACINGS,
  TickMath,
} from '@kodiak-finance/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import { BIG_INT_ZERO } from 'constants/misc'
import { BigNumber } from 'ethers'
import { useIslandContract } from 'hooks/useContract'
import { Island, useIsland, useIslandTicks } from 'hooks/useIslands'
import { PoolState, usePool } from 'hooks/usePools'
import JSBI from 'jsbi'
import { useSingleContractMultipleData } from 'lib/hooks/multicall'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { ReactNode, useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { formatTickPrice } from 'utils/formatTickPrice'
import { getTickToPrice } from 'utils/getTickToPrice'

import { useCurrencyBalances } from '../../connection/hooks'
import { AppState } from '../../index'
import { Bound, Field, typeInput } from './actions'
const ZERO = JSBI.BigInt(0)

export function useMintState(): AppState['mintIsland'] {
  return useAppSelector((state) => state.mintIsland)
}

export function useMintActionHandlers(noLiquidity: boolean | undefined): {
  onFieldAInput: (typedValue: string) => void
  onFieldBInput: (typedValue: string) => void
} {
  const dispatch = useAppDispatch()

  const onFieldAInput = useCallback(
    (typedValue: string) => {
      dispatch(typeInput({ field: Field.CURRENCY_A, typedValue, noLiquidity: noLiquidity === true }))
    },
    [dispatch, noLiquidity]
  )

  const onFieldBInput = useCallback(
    (typedValue: string) => {
      dispatch(typeInput({ field: Field.CURRENCY_B, typedValue, noLiquidity: noLiquidity === true }))
    },
    [dispatch, noLiquidity]
  )

  return {
    onFieldAInput,
    onFieldBInput,
  }
}

export function useDerivedMintInfo(
  islandAddress: string | undefined,
  currencyA: Currency | undefined,
  currencyB: Currency | undefined,
  baseCurrency: Currency | undefined
): {
  dependentField: Field
  currencies: { [field in Field]?: Currency }
  island: Island | null
  pool: Pool | null
  position?: Position
  poolState: PoolState
  ticks?: { [key: string]: number | undefined }
  pricesAtTicks: { [bound in Bound]?: Price<Token, Token> }
  currencyBalances: { [field in Field]?: CurrencyAmount<Currency> }
  parsedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  invalidPool: boolean
  invalidRange: boolean
  outOfRange: boolean
  depositADisabled: boolean
  depositBDisabled: boolean
  invertPrice: boolean
  ticksAtLimit: { [bound in Bound]?: boolean }
  price?: Price<Currency, Currency>
  noLiquidity?: boolean
  liquidityMinted?: CurrencyAmount<Token>
  poolTokenPercentage?: Percent
  error?: ReactNode
  amount0Max?: CurrencyAmount<Currency>
  amount1Max?: CurrencyAmount<Currency>
  amount0Min?: BigNumber
  amount1Min?: BigNumber
  amountSharesMin?: BigNumber
  amountShares?: BigNumber
  singleSided?: boolean
} {
  const { account } = useWeb3React()

  const { independentField, typedValue, otherTypedValue } = useMintState()

  const dependentField = useMemo(() => {
    return independentField === Field.CURRENCY_A ? Field.CURRENCY_B : Field.CURRENCY_A
  }, [independentField])
  // const dependentField = independentField === Field.CURRENCY_A ? Field.CURRENCY_B : Field.CURRENCY_A

  const islandInterface = useIslandContract(islandAddress)

  // tokens
  const currencies: { [field in Field]?: Currency } = useMemo(
    () => ({
      [Field.CURRENCY_A]: currencyA ?? undefined,
      [Field.CURRENCY_B]: currencyB ?? undefined,
    }),
    [currencyA, currencyB]
  )

  // formatted with tokens
  const [tokenA, tokenB, baseToken] = useMemo(
    () => [currencyA?.wrapped, currencyB?.wrapped, baseCurrency?.wrapped],
    [currencyA, currencyB, baseCurrency]
  )

  const [token0, token1] = useMemo(
    () =>
      tokenA && tokenB ? (tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]) : [undefined, undefined],
    [tokenA, tokenB]
  )

  // balances
  const balances = useCurrencyBalances(
    account ?? undefined,
    useMemo(() => [currencies[Field.CURRENCY_A], currencies[Field.CURRENCY_B]], [currencies])
  )
  const currencyBalances: { [field in Field]?: CurrencyAmount<Currency> } = useMemo(
    () => ({
      [Field.CURRENCY_A]: balances[0],
      [Field.CURRENCY_B]: balances[1],
    }),
    [balances[0], balances[1]]
  )

  // island
  // const { island, loading: islandLoading, error: islandError } = useIsland(islandAddress ?? null)
  const { island, isLoading, isFetching, isUninitialized, isError } = useIsland(islandAddress)

  const feeAmount = Number(island?.pool?.feeTier)

  // pool
  const [poolState, pool] = usePool(currencies[Field.CURRENCY_A], currencies[Field.CURRENCY_B], feeAmount)
  const noLiquidity = poolState === PoolState.NOT_EXISTS

  // note to parse inputs in reverse
  const invertPrice = Boolean(baseToken && token0 && !baseToken.equals(token0))

  // always returns the price with 0 as base token
  const price: Price<Token, Token> | undefined = useMemo(() => {
    // get the amount of quote currency
    return pool && token0 ? pool.priceOf(token0) : undefined
  }, [invertPrice, token1, token0, pool, feeAmount])

  // check for invalid price input (converts to invalid ratio)
  const invalidPrice = useMemo(() => {
    const sqrtRatioX96 = price ? encodeSqrtRatioX96(price.numerator, price.denominator) : undefined
    return (
      price &&
      sqrtRatioX96 &&
      !(
        JSBI.greaterThanOrEqual(sqrtRatioX96, TickMath.MIN_SQRT_RATIO) &&
        JSBI.lessThan(sqrtRatioX96, TickMath.MAX_SQRT_RATIO)
      )
    )
  }, [price])

  // used for ratio calculation when pool not initialized
  const mockPool = useMemo(() => {
    if (tokenA && tokenB && feeAmount && price && !invalidPrice) {
      const currentTick = priceToClosestTick(price)
      const currentSqrt = TickMath.getSqrtRatioAtTick(currentTick)
      return new Pool(tokenA, tokenB, feeAmount, currentSqrt, JSBI.BigInt(0), currentTick, [])
    } else {
      return undefined
    }
  }, [feeAmount, invalidPrice, price, tokenA, tokenB])

  // if pool exists use it, if not use the mock pool
  const poolForPosition: Pool | undefined = pool ?? mockPool

  console.log('pool:args', [currencies[Field.CURRENCY_A], currencies[Field.CURRENCY_B], feeAmount])
  console.log('island:price', price)
  console.log('island:invertPrice', invertPrice)
  console.log('island:pool', pool)
  console.log('island:noLiquidity', noLiquidity)
  console.log('island:noLiquidity', feeAmount)
  console.log('island:island', island)
  console.log('island:token0', token0)
  console.log('island:token1', token1)
  console.log('island:typedValue', typedValue)
  console.log('island:mockPool', mockPool)
  console.log('island:poolForPosition', poolForPosition)

  const { lowerTick, upperTick, loading, error: tickError } = useIslandTicks(islandInterface)

  // const lowerTick = Number(island?.lowerTick)
  // const upperTick = Number(island?.upperTick)

  // lower and upper limits in the tick space for `feeAmoun<>
  const tickSpaceLimits: {
    [bound in Bound]: number | undefined
  } = useMemo(() => {
    const tickSpacing = TICK_SPACINGS[feeAmount as keyof typeof TICK_SPACINGS]
    return {
      [Bound.LOWER]: feeAmount && tickSpacing ? nearestUsableTick(TickMath.MIN_TICK, tickSpacing) / 10 : undefined,
      [Bound.UPPER]: feeAmount && tickSpacing ? nearestUsableTick(TickMath.MAX_TICK, tickSpacing) / 10 : undefined,
    }
  }, [feeAmount])

  // parse typed range values and determine closest ticks
  // lower should always be a smaller tick
  const ticks: {
    [key: string]: number | undefined
  } = useMemo(() => {
    return {
      [Bound.LOWER]: lowerTick ?? undefined,
      [Bound.UPPER]: upperTick ?? undefined,
    }
  }, [lowerTick, upperTick, feeAmount, token0, token1, tickSpaceLimits])

  const { [Bound.LOWER]: tickLower, [Bound.UPPER]: tickUpper } = ticks || {}

  // specifies whether the lower and upper ticks is at the exteme bounds
  const ticksAtLimit = useMemo(
    () => ({
      [Bound.LOWER]: !!feeAmount && tickLower === tickSpaceLimits.LOWER,
      [Bound.UPPER]: !!feeAmount && tickUpper === tickSpaceLimits.UPPER,
    }),
    [tickSpaceLimits, tickLower, tickUpper, feeAmount]
  )

  // mark invalid range
  // const invalidRange = Boolean(typeof tickLower === 'number' && typeof tickUpper === 'number' && tickLower >= tickUpper)
  const invalidRange = false

  // always returns the price with 0 as base token
  const pricesAtTicks = useMemo(() => {
    return {
      [Bound.LOWER]: getTickToPrice(token0, token1, ticks[Bound.LOWER]),
      [Bound.UPPER]: getTickToPrice(token0, token1, ticks[Bound.UPPER]),
    }
  }, [token0, token1, ticks])

  const { [Bound.LOWER]: lowerPrice, [Bound.UPPER]: upperPrice } = pricesAtTicks

  // liquidity range warning
  // const outOfRange = Boolean(
  //   !invalidRange && price && lowerPrice && upperPrice && (price.lessThan(lowerPrice) || price.greaterThan(upperPrice))
  // )
  const outOfRange = false

  const formatLower = formatTickPrice(lowerPrice, ticksAtLimit, Bound.LOWER)

  const formatHigher = formatTickPrice(upperPrice, ticksAtLimit, Bound.LOWER)

  // const formatCurrent = formatTickPrice(price, ticksAtLimit, pool?.tickCurrent)
  console.log('island-tick:tickSpaceLimits', tickSpaceLimits)
  console.log('island-tick:ticks', ticks)
  console.log('island-tick:currentTick', pool?.tickCurrent)
  console.log('island-tick:ticksAtLimit', ticksAtLimit)
  console.log('island-tick:pricesAtTicks', pricesAtTicks)
  console.log('island-tick:invalidRange', invalidRange)
  console.log('island-tick:outOfRange', outOfRange)
  console.log('island-tick:formatLower', formatLower)
  console.log('island-tick:formatHigher', formatHigher)
  // console.log('island-tick:formatCurrent', formatCurrent)

  // console.log('island:token1', token1)
  // console.log('island:typedValue', typedValue)

  // amounts
  // amounts
  const independentAmount: CurrencyAmount<Currency> | undefined = tryParseCurrencyAmount(
    typedValue,
    currencies[independentField]
  )

  const dependentAmount: CurrencyAmount<Currency> | undefined = useMemo(() => {
    // we wrap the currencies just to get the price in terms of the other token
    const wrappedIndependentAmount = independentAmount?.wrapped
    const dependentCurrency = dependentField === Field.CURRENCY_B ? currencyB : currencyA
    if (
      independentAmount &&
      wrappedIndependentAmount &&
      typeof tickLower === 'number' &&
      typeof tickUpper === 'number' &&
      poolForPosition
    ) {
      // if price is out of range or invalid range - return 0 (single deposit will be independent)
      if (outOfRange || invalidRange) {
        return undefined
      }

      const position: Position | undefined = wrappedIndependentAmount.currency.equals(poolForPosition.token0)
        ? Position.fromAmount0({
            pool: poolForPosition,
            tickLower,
            tickUpper,
            amount0: independentAmount.quotient,
            useFullPrecision: true, // we want full precision for the theoretical position
          })
        : Position.fromAmount1({
            pool: poolForPosition,
            tickLower,
            tickUpper,
            amount1: independentAmount.quotient,
          })

      const dependentTokenAmount = wrappedIndependentAmount.currency.equals(poolForPosition.token0)
        ? position.amount1
        : position.amount0
      return dependentCurrency && CurrencyAmount.fromRawAmount(dependentCurrency, dependentTokenAmount.quotient)
    }

    return undefined
  }, [
    independentAmount,
    outOfRange,
    dependentField,
    currencyB,
    currencyA,
    tickLower,
    tickUpper,
    poolForPosition,
    invalidRange,
  ])

  const parsedAmounts: { [field in Field]: CurrencyAmount<Currency> | undefined } = useMemo(() => {
    return {
      [Field.CURRENCY_A]: independentField === Field.CURRENCY_A ? independentAmount : dependentAmount,
      [Field.CURRENCY_B]: independentField === Field.CURRENCY_A ? dependentAmount : independentAmount,
    }
  }, [dependentAmount, independentAmount, independentField])
  // }, [typedValue, dependentAmount, independentAmount, dependentField, independentField])

  // single deposit only if price is out of range
  const deposit0Disabled = Boolean(
    typeof tickUpper === 'number' && poolForPosition && poolForPosition.tickCurrent >= tickUpper
  )
  const deposit1Disabled = Boolean(
    typeof tickLower === 'number' && poolForPosition && poolForPosition.tickCurrent <= tickLower
  )

  // sorted for token order
  const depositADisabled =
    invalidRange ||
    Boolean(
      (deposit0Disabled && poolForPosition && tokenA && poolForPosition.token0.equals(tokenA)) ||
        (deposit1Disabled && poolForPosition && tokenA && poolForPosition.token1.equals(tokenA))
    )
  const depositBDisabled =
    invalidRange ||
    Boolean(
      (deposit0Disabled && poolForPosition && tokenB && poolForPosition.token0.equals(tokenB)) ||
        (deposit1Disabled && poolForPosition && tokenB && poolForPosition.token1.equals(tokenB))
    )

  const singleSided = deposit0Disabled || deposit1Disabled

  // create position entity based on users selection
  const position: Position | undefined = useMemo(() => {
    if (
      !poolForPosition ||
      !tokenA ||
      !tokenB ||
      typeof tickLower !== 'number' ||
      typeof tickUpper !== 'number' ||
      invalidRange
    ) {
      return undefined
    }

    // mark as 0 if disabled because out of range
    const amount0 = !deposit0Disabled
      ? parsedAmounts?.[tokenA.equals(poolForPosition.token0) ? Field.CURRENCY_A : Field.CURRENCY_B]?.quotient
      : BIG_INT_ZERO
    const amount1 = !deposit1Disabled
      ? parsedAmounts?.[tokenA.equals(poolForPosition.token0) ? Field.CURRENCY_B : Field.CURRENCY_A]?.quotient
      : BIG_INT_ZERO

    if (amount0 !== undefined && amount1 !== undefined) {
      return Position.fromAmounts({
        pool: poolForPosition,
        tickLower,
        tickUpper,
        amount0,
        amount1,
        useFullPrecision: true, // we want full precision for the theoretical position
      })
    } else {
      return undefined
    }
  }, [
    parsedAmounts,
    poolForPosition,
    tokenA,
    tokenB,
    deposit0Disabled,
    deposit1Disabled,
    invalidRange,
    tickLower,
    tickUpper,
  ])

  console.log('island-tick:parsedAmounts', parsedAmounts)
  console.log('island-tick:deposit0Disabled', deposit0Disabled)
  console.log('island-tick:deposit1Disabled', deposit1Disabled)
  console.log('island-tick:position', position)

  // Extract amounts from parsedAmounts
  const { [Field.CURRENCY_A]: amount0Max, [Field.CURRENCY_B]: amount1Max } = parsedAmounts

  // Only call the contract method if both amounts are defined
  const mintInfoArgs = useMemo(() => {
    const amountA = parsedAmounts[Field.CURRENCY_A]?.quotient.toString()
    const amountB = parsedAmounts[Field.CURRENCY_B]?.quotient.toString()
    return amountA && amountB ? [amountA, amountB] : []
  }, [parsedAmounts])

  const mintInfoResults = useSingleContractMultipleData(
    islandInterface,
    'getMintAmounts',
    mintInfoArgs.length > 0 ? [mintInfoArgs] : []
  )
  // The `useSingleContractMultipleData` hook should return an array with one result per call
  const mintInfo = useMemo(() => {
    // Make sure that the result is available and valid
    if (mintInfoResults && mintInfoResults[0]?.result) {
      const [amount0, amount1, mintAmount] = mintInfoResults[0].result // Assuming the result is an array with three elements
      // Ensure that amount0, amount1, and mintAmount are properly converted to BigNumbers if necessary
      return {
        amount0,
        amount1,
        mintAmount,
      }
    }
    // Return a default value when there is no result
    return {
      amount0: BigNumber.from(0),
      amount1: BigNumber.from(0),
      mintAmount: BigNumber.from(0),
    }
  }, [mintInfoResults])

  console.log('hook:mintInfoResults', mintInfoResults)
  console.log('hook:mintInfo', mintInfo)

  const amount0Min = mintInfo?.amount0?.mul(99).div(100)
  const amount1Min = mintInfo?.amount1?.mul(99).div(100)
  const amountSharesMin = mintInfo?.mintAmount?.mul(99).div(100)

  let error: ReactNode | undefined
  if (!account) {
    error = <>Connect Wallet</>
  }

  if (poolState === PoolState.INVALID) {
    error = error ?? <>Invalid pair</>
  }

  if (!parsedAmounts[Field.CURRENCY_A] || !parsedAmounts[Field.CURRENCY_B]) {
    error = error ?? <>Enter an amount</>
  }

  const { [Field.CURRENCY_A]: currencyAAmount, [Field.CURRENCY_B]: currencyBAmount } = parsedAmounts

  if (currencyAAmount && currencyBalances?.[Field.CURRENCY_A]?.lessThan(currencyAAmount)) {
    error = <>Insufficient {currencies[Field.CURRENCY_A]?.symbol} balance</>
  }

  if (currencyBAmount && currencyBalances?.[Field.CURRENCY_B]?.lessThan(currencyBAmount)) {
    error = <>Insufficient {currencies[Field.CURRENCY_B]?.symbol} balance</>
  }

  if (poolState === PoolState.INVALID) {
    error = error ?? <>Invalid pair</>
  }

  if (invalidPrice) {
    error = error ?? <>Invalid price input</>
  }

  if (
    (!parsedAmounts[Field.CURRENCY_A] && !depositADisabled) ||
    (!parsedAmounts[Field.CURRENCY_B] && !depositBDisabled)
  ) {
    error = error ?? <>Enter an amount</>
  }

  const invalidPool = poolState === PoolState.INVALID

  return {
    dependentField,
    currencies,
    island,
    pool,
    poolState,
    position,
    ticks,
    price,
    pricesAtTicks,
    noLiquidity,
    invalidPool,
    invalidRange,
    outOfRange,
    depositADisabled,
    depositBDisabled,
    invertPrice,
    ticksAtLimit,
    currencyBalances,
    parsedAmounts,
    error,
    amount0Max,
    amount1Max,
    amount0Min,
    amount1Min,
    amountSharesMin,
    amountShares: mintInfo?.mintAmount,
    singleSided,
  }
}
