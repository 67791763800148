import React from 'react'
import styled from 'styled-components'

// Styled components
const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`

const Icon = styled.svg<{ isVisible: boolean }>`
  fill: none;
  stroke: ${({ theme }) => theme.buttonPrimary};
  stroke-width: 2px;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: transparent;
  border-radius: 3px;
  transition: all 150ms;
  border: 2px solid ${({ checked, theme }) => (checked ? theme.buttonPrimary : theme.cardBorderPrimary)};
`

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  checked: boolean
}

const Checkbox: React.FC<CheckboxProps> = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24" isVisible={checked}>
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
)

const AcceptTermsCheckbox = ({ ...props }) => <Checkbox {...props} />

export default AcceptTermsCheckbox
