import PositionListItem from 'components/PositionListItem'
import Toggle from 'components/Toggle'
import React from 'react'
import { Link } from 'react-router-dom'
import { PositionDetails } from 'types/position'

import HeaderRow from './HeaderRow'
import * as S from './styled'

type PositionListProps = React.PropsWithChildren<{
  positions: PositionDetails[]
  setUserHideClosedPositions: any
  userHideClosedPositions: boolean
}>

export default function PositionList({
  positions,
  setUserHideClosedPositions,
  userHideClosedPositions,
}: PositionListProps) {
  return (
    <S.MainContentWrapper>
      <HeaderRow />
      {positions.map((p, index) => {
        return <PositionListItem key={p.tokenId.toString()} positionDetails={p} row={index} />
      })}
      <S.PositionListFooter>
        <S.ToggleWrap>
          <S.ToggleLabel>
            <>Show closed positions</>
          </S.ToggleLabel>
          <Toggle
            id="desktop-hide-closed-positions"
            isActive={!userHideClosedPositions}
            toggle={() => {
              setUserHideClosedPositions(!userHideClosedPositions)
            }}
          />
        </S.ToggleWrap>
        <S.ResponsiveButtonPrimary
          data-cy="join-pool-button"
          id="join-pool-button"
          as={Link}
          to="/liquidity/v3/add/ETH"
        >
          New Position
        </S.ResponsiveButtonPrimary>
      </S.PositionListFooter>
    </S.MainContentWrapper>
  )
}
