import styled from 'styled-components'

export const Option = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  cursor: pointer;

  ${({ theme }) => theme.mediaWidth.upToSmall`
      padding: 4px 4px;
  `};
  padding: 4px 12px;
  text-align: center;
  gap: 4px;
  border-radius: 3px;
  background: ${({ active, theme }) => (active ? theme.cardPrimary : 'transparent')};
  white-space: nowrap;
  /* border: 1px solid ${({ theme }) => theme.backgroundOutline}; */

  pointer-events: ${({ active }) => active && 'none'};
`

export const Switch = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px;
  border-radius: 6px;
  height: 36px;
  background: ${({ theme }) => theme.cardSecondary};
`
