import { useWeb3React } from '@web3-react/core'
import { ElementName, Event, EventName } from 'components/AmplitudeAnalytics/constants'
import { TraceEvent } from 'components/AmplitudeAnalytics/TraceEvent'
import { ButtonPrimary } from 'components/Button'
import { MouseoverTooltip } from 'components/Tooltip'
import { Island } from 'hooks/useIslands'
import { useContext } from 'react'
import { useToggleWalletModal } from 'state/application/hooks'
import styled, { css, ThemeContext } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { ReactComponent as Pools } from '../../../assets/svg/icons/Pools.svg'
import IslandListItem from './IslandListItem'
import * as S from './styled'

type IslandListProps = React.PropsWithChildren<{
  islands: Island[]
}>

export const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 24px 0;
  max-width: 300px;
`

const IconStyle = css`
  width: 40px;
  height: 40px;
  margin-bottom: 0.5rem;
`

const InboxIcon = styled(Pools)`
  ${IconStyle}
`

export default function IslandList({ islands }: IslandListProps) {
  const theme = useContext(ThemeContext)
  const { account, chainId } = useWeb3React()
  const toggleWalletModal = useToggleWalletModal()

  const showConnectAWallet = Boolean(!account)

  return (
    <S.MainContentWrapper>
      <S.HeaderRow>
        <S.HeaderCol>
          <MouseoverTooltip
            // disableHover={!tooltip}
            text={
              <ThemedText.DeprecatedBody fontSize="12px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
                Automated Liquidity Management Vault
              </ThemedText.DeprecatedBody>
            }
          >
            <ThemedText.DeprecatedBody
              fontSize="16px"
              fontWeight="700"
              lineHeight="24px"
              color={theme.textSecondary}
              className="title"
            >
              Island
            </ThemedText.DeprecatedBody>
          </MouseoverTooltip>
        </S.HeaderCol>

        <S.HeaderCol>
          <MouseoverTooltip
            // disableHover={!tooltip}
            text={
              <ThemedText.DeprecatedBody fontSize="12px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
                Range in which liquidity is deployed
              </ThemedText.DeprecatedBody>
            }
          >
            <ThemedText.DeprecatedBody
              fontSize="16px"
              fontWeight="700"
              lineHeight="24px"
              color={theme.textSecondary}
              className="title"
            >
              Range
            </ThemedText.DeprecatedBody>
          </MouseoverTooltip>
        </S.HeaderCol>

        <S.HeaderCol>
          <MouseoverTooltip
            // disableHover={!tooltip}
            text={
              <ThemedText.DeprecatedBody fontSize="12px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
                Total dollar TVL in the island
              </ThemedText.DeprecatedBody>
            }
          >
            <ThemedText.DeprecatedBody
              fontSize="16px"
              fontWeight="700"
              lineHeight="24px"
              color={theme.textSecondary}
              className="title"
            >
              TVL
            </ThemedText.DeprecatedBody>
          </MouseoverTooltip>
        </S.HeaderCol>

        <S.HeaderCol>
          <MouseoverTooltip
            // disableHover={!tooltip}
            text={
              <ThemedText.DeprecatedBody fontSize="12px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
                All time volume traded with island liquidity
              </ThemedText.DeprecatedBody>
            }
          >
            <ThemedText.DeprecatedBody
              fontSize="16px"
              fontWeight="700"
              lineHeight="24px"
              color={theme.textSecondary}
              className="title"
            >
              Volume
            </ThemedText.DeprecatedBody>
          </MouseoverTooltip>
        </S.HeaderCol>

        <S.HeaderCol>
          <MouseoverTooltip
            // disableHover={!tooltip}
            text={
              <ThemedText.DeprecatedBody fontSize="12px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
                Average APR based on fees generated by the island and/or farm emissions
              </ThemedText.DeprecatedBody>
            }
          >
            <ThemedText.DeprecatedBody
              fontSize="16px"
              fontWeight="700"
              lineHeight="24px"
              color={theme.textSecondary}
              className="title"
            >
              APR
            </ThemedText.DeprecatedBody>
          </MouseoverTooltip>
        </S.HeaderCol>
        <S.HeaderCol>
          <MouseoverTooltip
            // disableHover={!tooltip}
            text={
              <ThemedText.DeprecatedBody fontSize="12px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
                User deposits in the island
              </ThemedText.DeprecatedBody>
            }
          >
            <ThemedText.DeprecatedBody
              fontSize="16px"
              fontWeight="700"
              lineHeight="24px"
              color={theme.textSecondary}
              className="title"
            >
              My Deposits
            </ThemedText.DeprecatedBody>
          </MouseoverTooltip>
        </S.HeaderCol>

        {/* <S.HeaderCol></S.HeaderCol> */}
      </S.HeaderRow>

      {islands && islands.length > 0 ? (
        islands.map((island: Island, index: number) => (
          <IslandListItem key={island.id.toString()} island={island} row={index} />
        ))
      ) : (
        <ErrorContainer>
          <ThemedText.DeprecatedBody color={theme.deprecated_text3} textAlign="center">
            <InboxIcon strokeWidth={1} />
            <div>
              <>No islands found.</>
            </div>
          </ThemedText.DeprecatedBody>
          {/* {!showConnectAWallet && userIslands.length > 0 && (
          <ButtonText
            style={{ marginTop: '.5rem' }}
            onClick={() => setUserHideClosedPositions(!userHideClosedPositions)}
          >
            <>Show user islands</>
          </ButtonText>
        )} */}
          {showConnectAWallet && (
            <TraceEvent
              events={[Event.onClick]}
              name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
              properties={{ received_swap_quote: false }}
              element={ElementName.CONNECT_WALLET_BUTTON}
            >
              <ButtonPrimary
                style={{ marginTop: '2em', padding: '8px 16px', width: '140px' }}
                onClick={toggleWalletModal}
              >
                <>Connect wallet</>
              </ButtonPrimary>
            </TraceEvent>
          )}
        </ErrorContainer>
      )}

      {/* <S.FooterRow /> */}
    </S.MainContentWrapper>
  )
}

{
  // <MobileHeader>
  //    <>Your positions</>
  //    <ToggleWrap>
  //      <ToggleLabel>
  //        <>Show closed positions</>
  //      </ToggleLabel>
  //      <MobileTogglePosition>
  //        <Toggle
  //          id="mobile-hide-closed-positions"
  //          isActive={!userHideClosedPositions}
  //          toggle={() => {
  //            setUserHideClosedPositions(!userHideClosedPositions)
  //          }}
  //        />
  //      </MobileTogglePosition>
  //    </ToggleWrap>
  //  </MobileHeader>
}
