import { LightCard } from 'components/Card'
import Column, { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import FiatValue from 'components/FiatValue'
import Loader from 'components/Loader'
import Row, { AutoRow } from 'components/Row'
import { useDividends } from 'hooks/useStaking'
import { Text } from 'rebass'
import { useTheme } from 'styled-components'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
const UserClaimDividends = () => {
  const { pendingRewardsAmount } = useDividends()
  const theme = useTheme()
  if (pendingRewardsAmount.some((item) => item === undefined)) {
    return <Loader />
  }
  return (
    <AutoColumn gap="20px">
      {pendingRewardsAmount.map((info, index) => (
        <LightCard key={index}>
          <AutoRow gap="8px">
            <div>
              <CurrencyLogo size="32px" currency={info?.currency} />
            </div>
            <Column>
              <Text fontSize={14} color={theme.textSecondary}>
                {info?.currency?.symbol}
              </Text>
              <Row>
                <Text fontSize={14} color={theme.textPrimary}>
                  {formatCurrencyAmount(info, 4)}
                </Text>
                <Text fontSize={14} color={theme.textSecondary} marginLeft="4px">
                  (<FiatValue currencyAmount={info} />)
                </Text>
              </Row>
            </Column>
          </AutoRow>
        </LightCard>
      ))}
    </AutoColumn>
  )
}

export default UserClaimDividends
