import { ColumnCenter } from 'components/Column'
import Modal from 'components/Modal'
import Row, { RowBetween } from 'components/Row'
import { ReactNode, useEffect } from 'react'
import { ChevronLeft } from 'react-feather'
import styled from 'styled-components'
import { CloseIcon } from 'theme'

import StepLine, { StepLineProps } from '.'
import Step from './common/Step'
import useAllowStepLineStateChange from './context/hooks/useAllowStepLineStateChange'
import { useStepLineState, useStepLineStateClean } from './context/StepLineContext'

type StepLineModalProps = {
  onDismiss: () => void
  isOpen: boolean
  header?: ReactNode
  onStart?: () => void
  onClose?: () => void
} & StepLineProps

const BackButton = styled(ChevronLeft)`
  opacity: 0.8;
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
  margin-right: 4px;
`

const StepLineModal = ({ isOpen, onDismiss, onStart, onClose, header, ...rest }: StepLineModalProps) => {
  const clean = useStepLineStateClean()
  const { __currentStep: currentStep } = useStepLineState<{ __currentStep: Step }>()
  const { __prevStep: prevStep } = useStepLineState<{ __prevStep: Step }>()
  const setAllow = useAllowStepLineStateChange()
  useEffect(() => {
    clean()

    if (isOpen) {
      setAllow(true)
      onStart && onStart()
    } else {
      setAllow(false)
      onClose && onClose()
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ColumnCenter gap="8px">
        <RowBetween width="100%">
          <Row>
            {prevStep?.onBack && <BackButton onClick={prevStep.onBack} size={19} />}
            <div>{currentStep?.title || header}</div>
          </Row>
          <CloseIcon onClick={onDismiss} data-cy="confirmation-close-icon" />
        </RowBetween>
        <StepLine {...rest} />
      </ColumnCenter>
    </Modal>
  )
}

export default StepLineModal
