import { Currency, CurrencyAmount, Fraction, Token } from '@kodiak-finance/sdk-core'
import { LightCard } from 'components/Card'
import Column from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { RowFlat } from 'components/Row'
import ConfirmationTransactionStep, {
  ConfirmationTransactionStepProps,
} from 'components/StepLine/common/ConfirmationTransactionStep'
import { StepRequired } from 'components/StepLine/common/Step'
import { useStepLineNext } from 'components/StepLine/context/hooks/useStepLineNext'
import { Text } from 'rebass'
import { Field } from 'state/islands/mint/actions'

import { ConfirmAddModalBottom } from '../../ConfirmAddModalBottom'

type IslandDepositStepProps = {
  noLiquidity?: boolean
  currencies?: { [field in Field]?: Currency }
  liquidityMinted?: CurrencyAmount<Token>
  parsedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  price?: Fraction
  amountSharesMin?: string
  onAdd: () => void
} & ConfirmationTransactionStepProps

export const useIslandDepositStep = (props: Omit<IslandDepositStepProps, 'onConfirm'>): StepRequired => {
  const { condition, next } = useStepLineNext('IslandDepositStepDone')
  return {
    title: 'Confirm Deposit',
    condition,
    component: <IslandDepositStep onConfirm={next} {...props} />,
  }
}

const IslandDepositStep = ({
  noLiquidity,
  currencies,
  liquidityMinted,
  amountSharesMin,
  price,
  parsedAmounts,
  attemptingTxn,
  pendingText,
  hash,
  onAdd,
  onConfirm,
  autoConfirm,
}: IslandDepositStepProps) => {
  if (!currencies) {
    return <></>
  }
  return (
    <ConfirmationTransactionStep
      attemptingTxn={attemptingTxn}
      pendingText={pendingText}
      hash={hash}
      transactionWaiting={true}
      onConfirm={onConfirm}
      autoConfirm={autoConfirm}
    >
      <>
        {noLiquidity ? (
          <Column width="100%" gap="20px">
            <LightCard mt="20px" $borderRadius="20px">
              <RowFlat>
                <Text fontSize="48px" fontWeight={500} lineHeight="42px" marginRight={10}>
                  {currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol}
                </Text>
                <DoubleCurrencyLogo
                  currency0={currencies[Field.CURRENCY_A]}
                  currency1={currencies[Field.CURRENCY_B]}
                  size={30}
                />
              </RowFlat>
            </LightCard>
          </Column>
        ) : (
          <></>
        )}
        <ConfirmAddModalBottom
          price={price}
          currencies={currencies}
          parsedAmounts={parsedAmounts}
          noLiquidity={noLiquidity}
          onAdd={onAdd}
          amountSharesMin={amountSharesMin}
        />
      </>
    </ConfirmationTransactionStep>
  )
}

export default IslandDepositStep
