import { CurrencyAmount, Token } from '@kodiak-finance/sdk-core'
import { ButtonPrimary } from 'components/Button'
import { LightCard } from 'components/Card'
import Column from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import { AutoRow, RowBetween } from 'components/Row'
import ConfirmationTransactionStep, {
  ConfirmationTransactionStepProps,
} from 'components/StepLine/common/ConfirmationTransactionStep'
import { useCurrency } from 'hooks/Tokens'
import { useIsland } from 'hooks/useIslands'
import { Text } from 'rebass'

import { useIslandUnderlyingBalances } from '../../../../../hooks/useIslandPositionDetails'

type WithdrawFromIslandStepProps = {
  shares?: CurrencyAmount<Token> | null
  next: () => void
  onWithdraw: () => void
} & Omit<ConfirmationTransactionStepProps, 'children' | 'onConfirm'>

export default function WithdrawFromIslandStep({ shares, next, onWithdraw, ...rest }: WithdrawFromIslandStepProps) {
  const { island } = useIsland(shares?.currency.address.toLowerCase())
  const token0 = useCurrency(island?.token0.id)
  const token1 = useCurrency(island?.token1.id)
  const underlyingBalances = useIslandUnderlyingBalances(island, shares, token0, token1)

  console.log(`Debug:WithdrawFromIslandStep`, { island, token0, token1, underlyingBalances, shares })
  return (
    <ConfirmationTransactionStep {...rest} onConfirm={next}>
      <Column width="100%" gap="8px">
        <LightCard>
          <RowBetween>
            <AutoRow gap="4px">
              <CurrencyLogo currency={token0} size="21px" />
              <Text fontSize="14px">{token0?.symbol}</Text>
            </AutoRow>
            <Text>{underlyingBalances.amount0?.toSignificant(6)}</Text>
          </RowBetween>
        </LightCard>
        <LightCard>
          <RowBetween>
            <AutoRow gap="4px">
              <CurrencyLogo currency={token1} size="21px" />
              <Text fontSize="14px">{token1?.symbol}</Text>
            </AutoRow>
            <Text>{underlyingBalances.amount1?.toSignificant(6)}</Text>
          </RowBetween>
        </LightCard>

        <ButtonPrimary onClick={onWithdraw}>
          Withdraw {shares?.toSignificant(6)} {shares?.currency?.symbol}
        </ButtonPrimary>
      </Column>
    </ConfirmationTransactionStep>
  )
}
