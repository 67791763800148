import { Currency } from '@kodiak-finance/sdk-core'
import { SupportedChainId } from 'constants/chains'
import useHttpLocations from 'hooks/useHttpLocations'
import { useMemo } from 'react'
import { WrappedTokenInfo } from 'state/lists/wrappedTokenInfo'

import MaticLogo from '../../assets/svg/matic-token-icon.svg'

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// BERA MIGRATION: Add berachains to these helpers where needed
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

type Network = 'mumbai' | 'berachain-internal' | 'berachain_artio'

function chainIdToNetworkName(networkId: SupportedChainId): Network {
  switch (networkId) {
    case SupportedChainId.POLYGON_MUMBAI:
      return 'mumbai'
    case SupportedChainId.BERACHAIN_PRIVATE_TESTNET:
      return 'berachain-internal'
    case SupportedChainId.BERACHAIN_ARTIO_TESTNET:
      return 'berachain_artio'
    // case SupportedChainId.ARBITRUM_ONE:
    //   return 'arbitrum'
    // case SupportedChainId.OPTIMISM:
    //   return 'optimism'
    default:
      return 'mumbai'
  }
}

function getNativeLogoURI(chainId: SupportedChainId = SupportedChainId.POLYGON_MUMBAI): string {
  switch (chainId) {
    // case SupportedChainId.POLYGON:
    case SupportedChainId.POLYGON_MUMBAI:
      return MaticLogo
    case SupportedChainId.BERACHAIN_PRIVATE_TESTNET:
      return 'https://raw.githubusercontent.com/berachain/default-token-list/main/src/assets/bera.png'
    case SupportedChainId.BERACHAIN_ARTIO_TESTNET:
      return 'https://raw.githubusercontent.com/berachain/default-token-list/main/src/assets/bera.png'
    // case SupportedChainId.BERA_MAINNET:
    // case SupportedChainId.BERA_TESTNET:
    // return CeloLogo
    default:
      return BeraLogo
  }
}

function getTokenLogoURI(
  symbol: string,
  chainId: SupportedChainId = SupportedChainId.POLYGON_MUMBAI |
    SupportedChainId.BERACHAIN_PRIVATE_TESTNET |
    SupportedChainId.BERACHAIN_ARTIO_TESTNET
): string | void {
  const networkName = chainIdToNetworkName(chainId)
  const networksWithUrls = [SupportedChainId.BERACHAIN_PRIVATE_TESTNET, SupportedChainId.BERACHAIN_ARTIO_TESTNET]

  if (symbol.startsWith('kodiak-')) {
    return 'https://app.kodiak.finance/images/KodiakToken.svg'
  } else if (networksWithUrls.includes(chainId)) {
    return `https://raw.githubusercontent.com/berachain/default-token-list/main/src/assets/${symbol}.png`
  }
}

export default function useCurrencyLogoURIs(currency?: Currency | null): string[] {
  const locations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  return useMemo(() => {
    const logoURIs = [...locations]
    if (currency) {
      if (currency.isNative) {
        logoURIs.push(getNativeLogoURI(currency.chainId))
      } else if (currency.isToken) {
        const logoURI = getTokenLogoURI(currency.symbol.toLowerCase(), currency.chainId)
        if (logoURI) {
          logoURIs.push(logoURI)
        }
      }
    }
    return logoURIs
  }, [currency, locations])
}
