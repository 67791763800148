import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { DayDataV2Type, setDayDataV2, setDayDataV2Loading } from '../actions'

export const useSetDayDataV2 = () => {
  const dispatch = useAppDispatch()

  const setDayData = useCallback(
    (dayDataV2: DayDataV2Type) => {
      dispatch(setDayDataV2(dayDataV2))
    },
    [dispatch]
  )

  const setLoading = useCallback(
    (loading: boolean) => {
      dispatch(setDayDataV2Loading(loading))
    },
    [dispatch]
  )

  return { setDayData, setLoading }
}

export const useDayDataV2State = () => {
  return useAppSelector((state) => state.dayDataV2)
}
