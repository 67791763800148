import { CurrencyAmount, Token } from '@kodiak-finance/sdk-core'
import { useSubgraphTokenValue } from 'hooks/useSubgraphTokenPrice'
import { useMemo } from 'react'
import formatMoney from 'utils/formatMoney'

type FiatValueCurrencyAmountProps = {
  currencyAmount: CurrencyAmount<Token> | undefined | null
}
type FiatValueFormatProps = {
  value: (string | number | null | undefined) | (string | number | null | undefined)[]
}

export type FiatValueProps = FiatValueCurrencyAmountProps | FiatValueFormatProps

export function FiatValue(props: FiatValueCurrencyAmountProps): React.ReactNode
export function FiatValue(props: FiatValueFormatProps): React.ReactNode
export function FiatValue(props: FiatValueProps): React.ReactNode {
  const currencyAmount = 'currencyAmount' in props ? props.currencyAmount : undefined
  const value = 'value' in props ? props.value : undefined

  const tokenValue = useSubgraphTokenValue(currencyAmount)
  const formatted = useMemo(() => {
    if (value) {
      if (Array.isArray(value)) {
        return formatMoney(
          value
            .map((val) => Number(val))
            .filter((item) => !isNaN(item))
            .reduce((a, b) => a + b, 0)
        )
      }
      return formatMoney(value)
    } else {
      return formatMoney(tokenValue || '0')
    }
  }, [tokenValue, value])

  return <>{formatted}</>
}

export default FiatValue
