import { BaseQueryFn } from '@reduxjs/toolkit/query'
import { createApi } from '@reduxjs/toolkit/query/react'
import { SupportedChainId } from 'constants/chains'
import { ALLOWED_ISLANDS } from 'constants/lists'
import { DocumentNode } from 'graphql'
import { ClientError, gql, GraphQLClient } from 'graphql-request'
import { AppState } from 'state'

// List of supported subgraphs. Note that the app currently only support one active subgraph at a time
const CHAIN_SUBGRAPH_URL: Record<number, string> = {
  [SupportedChainId.POLYGON_MUMBAI]: 'https://api.thegraph.com/subgraphs/name/kodiak-finance/kodiak-v3-dev',
  [SupportedChainId.BERACHAIN_PRIVATE_TESTNET]:
    'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/kodiak-v3-testnet/latest/gn',
  [SupportedChainId.BERACHAIN_ARTIO_TESTNET]:
    'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/kodiak-v3-berachain-public-testnet/latest/gn',

  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // BERA MIGRATION: Add Bera deployed subgraphs
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
}

const TOKEN_FIELDS = gql`
  fragment TokenFields on Token {
    id
    symbol
    name
    decimals
    totalSupply
    volume
    volumeUSD
    untrackedVolumeUSD
    feesUSD
    txCount
    poolCount
    totalValueLocked
    totalValueLockedUSD
    totalValueLockedUSDUntracked
    derivedETH
  }
`

const POOL_FIELDS = gql`
  fragment PoolFields on Pool {
    id
    createdAtTimestamp
    createdAtBlockNumber
    feeTier
    liquidity
    sqrtPrice
    feeGrowthGlobal0X128
    feeGrowthGlobal1X128
    token0Price
    token1Price
    tick
    observationIndex
    volumeToken0
    volumeToken1
    volumeUSD
    untrackedVolumeUSD
    feesUSD
    txCount
    collectedFeesToken0
    collectedFeesToken1
    collectedFeesUSD
    totalValueLockedToken0
    totalValueLockedToken1
    totalValueLockedETH
    totalValueLockedUSD
    totalValueLockedUSDUntracked
    liquidityProviderCount
  }
`

export const api = createApi({
  reducerPath: 'dataApi',
  baseQuery: graphqlRequestBaseQuery(),
  endpoints: (builder) => ({
    allV3Ticks: builder.query({
      query: ({ poolAddress, skip = 0 }) => ({
        document: gql`
          query allV3Ticks($poolAddress: String!, $skip: Int!) {
            ticks(first: 1000, skip: $skip, where: { poolAddress: $poolAddress }, orderBy: tickIdx) {
              tick: tickIdx
              liquidityNet
              price0
              price1
            }
          }
        `,
        variables: {
          poolAddress,
          skip,
        },
      }),
    }),
    feeTierDistribution: builder.query({
      query: ({ token0, token1 }) => ({
        document: gql`
          query feeTierDistribution($token0: String!, $token1: String!) {
            _meta {
              block {
                number
              }
            }
            asToken0: pools(
              orderBy: totalValueLockedToken0
              orderDirection: desc
              where: { token0: $token0, token1: $token1 }
            ) {
              feeTier
              totalValueLockedToken0
              totalValueLockedToken1
            }
            asToken1: pools(
              orderBy: totalValueLockedToken0
              orderDirection: desc
              where: { token0: $token1, token1: $token0 }
            ) {
              feeTier
              totalValueLockedToken0
              totalValueLockedToken1
            }
          }
        `,
        variables: {
          token0,
          token1,
        },
      }),
    }),
    islandFinancialsDailySnapshot: builder.query({
      query: () => ({
        document: gql`
          ${TOKEN_FIELDS}
          query kodiakFinancialSnapshot {
            kodiakFinancialsDailySnapshots(first: 1) {
              id
              totalValueLockedUSD
              protocolControlledValueUSD
              dailySupplySideRevenueUSD
              cumulativeSupplySideRevenueUSD
              dailyProtocolSideRevenueUSD
              cumulativeProtocolSideRevenueUSD
              dailyTotalRevenueUSD
              cumulativeTotalRevenueUSD
              blockNumber
              timestamp
            }
          }
        `,
      }),
    }),
    kodiakIslandProtocol: builder.query({
      query: ({ id }) => ({
        document: gql`
          query kodiakIslandProtocol($id: ID!) {
            kodiakProtocol(id: $id) {
              id
              name
              slug
              schemaVersion
              subgraphVersion
              methodologyVersion
              network
              type
              totalValueLockedUSD
              protocolControlledValueUSD
              cumulativeSupplySideRevenueUSD
              cumulativeProtocolSideRevenueUSD
              cumulativeTotalRevenueUSD
              cumulativeUniqueUsers
              totalPoolCount
            }
          }
        `,
      }),
    }),
    islands: builder.query({
      query: () => ({
        document: QUERY_ALL_ISLANDS,
        variables: { allowedIds: ALLOWED_ISLANDS },
      }),
    }),

    islandById: builder.query({
      query: (id: string) => ({
        document: QUERY_ISLAND_BY_ID,
        variables: { id },
      }),
      transformResponse: (response, meta, arg) => {
        console.log('allowlist:debug', { id: arg, ALLOWED_ISLANDS })
        // Check if the requested island ID is in the allowlist
        if (ALLOWED_ISLANDS.includes(arg)) {
          return response
        } else {
          // Throw an error if the ID is not in the allowlist
          throw new Error(`Island with ID ${arg} is not allowed.`)
        }
      },
    }),

    poolDayDatas: builder.query({
      query: () => ({
        document: gql`
          query protocolDayDatas {
            uniswapDayDatas {
              date
              volumeUSD
              feesUSD
              tvlUSD
            }
          }
        `,
      }),
    }),
  }),
})

// Graphql query client wrapper that builds a dynamic url based on chain id
function graphqlRequestBaseQuery(): BaseQueryFn<
  { document: string | DocumentNode; variables?: any },
  unknown,
  Pick<ClientError, 'name' | 'message' | 'stack'>,
  Partial<Pick<ClientError, 'request' | 'response'>>
> {
  return async ({ document, variables }, { getState }) => {
    try {
      const chainId = (getState() as AppState).application.chainId

      const subgraphUrl = chainId ? CHAIN_SUBGRAPH_URL[chainId] : undefined

      if (!subgraphUrl) {
        return {
          error: {
            name: 'UnsupportedChainId',
            message: `Subgraph queries against ChainId ${chainId} are not supported.`,
            stack: '',
          },
        }
      }

      return { data: await new GraphQLClient(subgraphUrl).request(document, variables), meta: {} }
    } catch (error) {
      if (error instanceof ClientError) {
        const { name, message, stack, request, response } = error
        return { error: { name, message, stack }, meta: { request, response } }
      }
      throw error
    }
  }
}

const QUERY_ALL_ISLANDS = gql`
  ${TOKEN_FIELDS}
  ${POOL_FIELDS}
  query getAllIslands($allowedIds: [ID!]!) {
    kodiakVaults(where: { id_in: $allowedIds }, orderBy: apr__averageApr) {
      id
      name
      symbol
      depositLimit
      createdTimestamp
      createdBlockNumber
      totalValueLockedUSD
      cumulativeSupplySideRevenueUSD
      cumulativeProtocolSideRevenueUSD
      cumulativeTotalRevenueUSD
      inputTokenBalance
      outputTokenSupply
      outputTokenPriceUSD
      pricePerShare
      stakedOutputTokenAmount
      rewardTokenEmissionsAmount
      rewardTokenEmissionsUSD
      volumeToken0
      volumeToken1
      volumeUSD
      _token0Amount
      _token1Amount
      _token0AmountUSD
      _token1AmountUSD
      _token0 {
        ...TokenFields
      }
      _token1 {
        ...TokenFields
      }

      inputToken {
        ...TokenFields
      }
      outputToken {
        ...TokenFields
      }
      rewardTokens {
        token {
          ...TokenFields
        }
        type
      }

      fees {
        id
        feePercentage
        feeType
      }
      pool {
        ...PoolFields
      }
      apr {
        id
        averageApr
        timestamp
      }
      dailySnapshots {
        timestamp
        volumeUSD
      }
    }
  }
`

const QUERY_ISLAND_BY_ID = gql`
  ${TOKEN_FIELDS}
  ${POOL_FIELDS}
  query getIslandById($id: ID!) {
    kodiakVault(id: $id) {
      id
      name
      symbol
      depositLimit
      createdTimestamp
      createdBlockNumber
      totalValueLockedUSD
      cumulativeSupplySideRevenueUSD
      cumulativeProtocolSideRevenueUSD
      lowerTick
      upperTick
      cumulativeTotalRevenueUSD
      inputTokenBalance
      outputTokenSupply
      outputTokenPriceUSD
      pricePerShare
      stakedOutputTokenAmount
      rewardTokenEmissionsAmount
      rewardTokenEmissionsUSD
      volumeToken0
      volumeToken1
      volumeUSD
      _token0Amount
      _token1Amount
      _token0AmountUSD
      _token1AmountUSD
      _token0 {
        ...TokenFields
      }
      _token1 {
        ...TokenFields
      }

      inputToken {
        ...TokenFields
      }
      outputToken {
        ...TokenFields
      }
      rewardTokens {
        token {
          ...TokenFields
        }
        type
      }

      fees {
        id
        feePercentage
        feeType
      }
      pool {
        ...PoolFields
      }
      apr {
        id
        averageApr
        timestamp
      }
      dailySnapshots {
        timestamp
        volumeUSD
        totalValueLockedUSD
      }
    }
  }
`
