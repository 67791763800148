import { Trans } from '@lingui/macro'
import useScrollPosition from '@react-hook/window-scroll'
import { useWeb3React } from '@web3-react/core'
import ThemeToggle from 'components/Toggle/ThemeToggle'
import * as T from 'components/Typography'
import { useExploreFlag } from 'featureFlags/flags/explore'
import useTheme from 'hooks/useTheme'
import { darken } from 'polished'
import { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Text } from 'rebass'
import { useShowClaimPopup, useToggleSelfClaimModal } from 'state/application/hooks'
import { useUserHasAvailableClaim } from 'state/claim/hooks'
import { useNativeCurrencyBalances } from 'state/connection/hooks'
import { useUserHasSubmittedClaim } from 'state/transactions/hooks'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { isDevelopmentEnv, isStagingEnv } from 'utils/env'
import { isMobile } from 'utils/userAgent'

import { ReactComponent as DarkLogo } from '../../assets/svg/kodiak-logo-default.svg'
import { ReactComponent as LightLogo } from '../../assets/svg/kodiak-logo-invert.svg'
import { ExternalLink } from '../../theme'
import ClaimModal from '../claim/ClaimModal'
import Menu from '../Menu'
import Row from '../Row'
import { BurgerButton, BurgerMenu } from './BurgerMenu'
import HolidayOrnament from './HolidayOrnament'
import NavDropdown from './NavDropdown'
import NetworkSelector from './NetworkSelector'
import { useSchema } from './Schema'
import Web3Status from './Web3Status'

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  display: grid;
  grid-template-columns: 150px 1fr 210px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding: 20px 80px;
  z-index: 21;
  position: relative;
  /* Background slide effect on scroll. */
  /* background-image: ${({ theme }) =>
    `linear-gradient(to bottom, transparent 50%, ${theme.backgroundSurface} 50% )}}`};
  background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
  background-size: 100% 200%; */

  background: ${({ theme }) => theme.backgroundSurface};
  box-shadow: 0px 0px 0px 1px ${({ theme, showBackground }) => (showBackground ? theme.deprecated_bg2 : 'transparent;')};
  transition: background-position 0.1s, box-shadow 0.1s;
  background-blend-mode: hard-light;

  border-bottom: 1px solid ${({ theme }) => theme.cardBorderPrimary};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-columns: 48px 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 20px 40px;
    grid-template-columns: 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding:  20px;
    grid-template-columns: 36px 1fr;
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 0.5em;
  }

  /* addresses safaris lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};
`

const HeaderLinks = styled(Row)`
  padding: 0px 40px;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex: 1 0 0;
  display: flex;
  ${({ theme }) => theme.mediaWidth.upToMedium`
      display: none;

  `}/* justify-self: center; */
  /* background-color: ${({ theme }) => theme.deprecated_bg0}; */
  /* width: max-content; */
  /* padding: 2px; */
  /* border-radius: 16px; */
  /* display: grid; */
  /* grid-auto-flow: column; */
  /* grid-gap: 10px; */
  /* overflow: auto; */
  /* align-items: center; */
  /* ${({ theme }) => theme.mediaWidth.upToLarge`
    justify-self: start;
    `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: center;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    z-index: 99;
    position: fixed;
    bottom: 0; right: 50%;
    transform: translate(50%,-50%);
    margin: 0 auto;
    background-color: ${({ theme }) => theme.deprecated_bg0};
    border: 1px solid ${({ theme }) => theme.deprecated_bg2};
    box-shadow: 0px 6px 10px rgb(0 0 0 / 2%);
  `}; */
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: ${({ theme, active }) => (!active ? theme.deprecated_bg0 : theme.deprecated_bg0)}; */
  border-radius: 6px;
  white-space: nowrap;
  width: 100%;
  height: 40px;

  :focus {
    border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
  }
`

const UNIAmount = styled(AccountElement)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.deprecated_bg3};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #2172e5 100%), #edeef2;
`

const UNIWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: flex-end;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  gap: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};

  :hover {
    cursor: pointer;
  }

  h2 {
    color: ${({ theme }) => theme.textPrimary};
    font-weight: 500;
    line-height: 32px;
    margin: 0;

    ${({ theme }) => theme.mediaWidth.upToLarge`
        line-height: 25px;
        display: none;
  `};
  }
`

const KodiakIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }

  position: relative;
`

// can't be customized under react-router-dom v6
// so we have to persist to the default one, i.e., .active
const activeClassName = 'active'

export const StyledNavLink = styled(NavLink)<{ isActive?: boolean; width?: string }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme, isActive }) => (isActive ? theme.textPrimary : theme.textSecondary)};
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  /* padding: 8px 12px; */
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
  width: ${({ width }) => width};
  &.${activeClassName} {
    font-weight: 600;
    color: ${({ theme }) => theme.textPrimary};
  }
  :hover {
    color: ${({ theme }) => theme.textPrimary} !important;
  }
  :focus {
    color: ${({ theme }) => darken(0.1, theme.textPrimary)};
  }
`

export const StyledExternalLink = styled(ExternalLink)`
  ${({ theme }) => theme.flexRowNoWrap}
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  gap: 2px;

  &.${activeClassName} {
    font-weight: 600;
    color: ${({ theme }) => theme.textPrimary};
  }

  :hover {
    color: ${({ theme }) => theme.textPrimary} !important;
  }

  :focus {
    color: ${({ theme }) => darken(0.1, theme.textPrimary)};
    text-decoration: none;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 0px;

  // position: relative;
`

export default function Header() {
  const exploreFlag = useExploreFlag()

  const { account, chainId } = useWeb3React()

  const userEthBalance = useNativeCurrencyBalances(account ? [account] : [])?.[account ?? '']
  const { deprecated_white, deprecated_black } = useTheme()
  const [darkMode, toggleDarkMode] = useDarkModeManager()

  const toggleClaimModal = useToggleSelfClaimModal()

  const availableClaim: boolean = useUserHasAvailableClaim(account)

  const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)

  const showClaimPopup = useShowClaimPopup()

  const scrollY = useScrollPosition()

  const { pathname } = useLocation()

  // const {
  //   infoLink,
  //   nativeCurrency: { symbol: nativeCurrencySymbol },
  // } = getChainInfoOrDefault(chainId)

  const schema = useSchema()
  const [openBurgerMenu, setOpenBurgerMenu] = useState(false)
  const toggleBurgerMenu = () => setOpenBurgerMenu((prev) => !prev)

  // work around https://github.com/remix-run/react-router/issues/8161
  // as we can't pass function `({isActive}) => ''` to className with styled-components
  const isIslandsActive = pathname.startsWith('/islands')

  return (
    <HeaderWrapper>
      <HeaderFrame showBackground={scrollY > 45}>
        <ClaimModal />
        <Title href=".">
          <KodiakIcon>
            {darkMode ? (
              <DarkLogo height="30px" width="46.5px" title="logo" />
            ) : (
              <LightLogo height="30px" width="46.5px" title="logo" />
            )}
            {/* <Logo fill={darkMode ? deprecated_white : deprecated_black} width="24px" height="100%" title="logo" /> */}
            <HolidayOrnament />
          </KodiakIcon>
          <T.HeadingM>Kodiak</T.HeadingM>
        </Title>
        <HeaderLinks>
          {schema.map((item) => {
            return item.to ? (
              <StyledNavLink
                key={item.id}
                id={item.id}
                to={item.to}
                target={item.external ? '_' : null}
                className={item.isActive ? activeClassName : ''}
              >
                {item.label}
                {item.external && (
                  <>
                    <sup>&nbsp;↗</sup>
                  </>
                )}
              </StyledNavLink>
            ) : (
              <NavDropdown key={item.id} name={item.name!} dropdownLinks={item.sublinks!}>
                <StyledExternalLink href="#" id={item.id} className={item.isActive ? activeClassName : ''}>
                  <Trans>{item.label}</Trans>
                </StyledExternalLink>
              </NavDropdown>
            )
          })}
          {/* <NavDropdown
            name={ApplicationModal.EARN_DROPDOWN}
            dropdownLinks={[
              { label: 'Islands', href: '/islands' },
              { label: 'Staking', href: '/staking' },
            ]}
          >
            <StyledExternalLink id={`earn-nav-link`} className={isEarnDropdownLinkActive() ? activeClassName : ''}>
              <Trans>Earn</Trans>
            </StyledExternalLink>
          </NavDropdown>
          {/* <StyledNavLink
            data-cy="pool-nav-link"
            id={`pool-nav-link`}
            to={'/pool'}
            className={isPoolActive ? activeClassName : undefined}
          >
            <Trans>Pool</Trans>
          </StyledNavLink> */}
          {/* 
          {exploreFlag === ExploreVariant.Enabled && (
            <StyledNavLink id={`explore-nav-link`} to={'/explore'}>
              <Trans>Explore</Trans>
            </StyledNavLink>
          )} */}
          {/* <StyledNavLink id={`pool-nav-link`} to={'/pool'} isActive={isPoolActive}>
            <Trans>Analytics</Trans>
          </StyledNavLink> */}
          {/* <NavDropdown
            name={ApplicationModal.ANALYTICS_DROPDOWN}
            dropdownLinks={[
              // { label: 'Tokens', href: '/explore' },
              { label: 'Info', href: 'https://v3-analytics.vercel.app/#/polygon-mumbai/', external: true },
            ]}
          > */}
          {/* </NavDropdown> */}
        </HeaderLinks>
        <HeaderControls>
          {isMobile && <BurgerButton onClick={toggleBurgerMenu} opened={openBurgerMenu} />}

          {!isMobile && (
            <>
              <ThemeToggle onToggle={toggleDarkMode} isDarkMode={darkMode} />
              <HeaderElement>
                <NetworkSelector />
              </HeaderElement>
              <HeaderElement>
                <Web3Status />
              </HeaderElement>
            </>
          )}
          {(isDevelopmentEnv() || isStagingEnv()) && (
            <HeaderElement>
              <Menu />
            </HeaderElement>
          )}
        </HeaderControls>
      </HeaderFrame>
      <BurgerMenu show={openBurgerMenu} onClose={() => setOpenBurgerMenu(false)} />
    </HeaderWrapper>
  )
}
