import { Defined } from '@definedfi/sdk'
import { WETH9 } from '@kodiak-finance/sdk-core'
import { V2_FACTORY_ADDRESSES, V3_CORE_FACTORY_ADDRESSES } from 'constants/addresses'
const sdk = new Defined(process.env.REACT_APP_DEFINED_API_KEY ?? '')

export const getTopTokens = async () => {
  try {
    console.log('defined:sdk', sdk)

    const res = await sdk.queries.topTokens({
      networkFilter: [80085], // Use the actual network IDs you're interested in
    })
    console.log('defined:res', res)
    return res?.listTopTokens
  } catch (err) {
    console.error('getTopTokens error', err)
    return []
  }
}

export const getTopPairs = async ({ searchToken, chainId }) => {
  try {
    console.log('defined:sdk', sdk)
    console.log('defined:Launched', searchToken?.toLowerCase(), chainId)
    // const query =

    // Using the SDK's send method to execute the query
    const res = await sdk.send(
      `
		query FilterPairs($tokenAddress: [String], $network: [Int]){
		  filterPairs(
			filters: { tokenAddress: $tokenAddress, network: $network }
			rankings: { attribute: liquidity, direction: DESC }
		  ) {
			count
			offset
			results {
			  txnCount1
			  highPrice1
			  lowPrice1
			  uniqueTransactions1
			  volumeUSD1
			  price
			  marketCap
			  liquidity
			  liquidityToken
			  quoteToken
			  exchange {
				name
			  }
			  pair {
				address
				token0 
				token1 
			  }
			}
		  }
		}
	  `,
      {
        tokenAddress: [searchToken?.toLowerCase() ?? WETH9[chainId].address.toLowerCase()],
        network: [chainId],
        exchangeAddress: [V3_CORE_FACTORY_ADDRESSES[chainId], V2_FACTORY_ADDRESSES[chainId]],
      }
    )

    // Assuming 'filterPairs' is the correct key based on your query
    console.log('defined:res', res.filterPairs)
    return res.filterPairs.results
  } catch (err) {
    console.error('defined:getTopPairs error', err)
    return []
  }
}
