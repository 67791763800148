import { Pair } from '@kodiak-finance/v2-sdk'
import { PageName } from 'components/AmplitudeAnalytics/constants'
import { Trace } from 'components/AmplitudeAnalytics/Trace'
import { ButtonPrimary } from 'components/Button'
import { MainContentWrapper, PositionListFooter } from 'components/PositionList/styled'
import V2PositionList from 'components/PositionList/v2'
import V2HeaderRow from 'components/PositionList/V2HeaderRow'
import { RowBetween } from 'components/Row'
import { Dots } from 'components/swap/styleds'
import { UNSUPPORTED_V2POOL_CHAIN_IDS } from 'constants/chains'
import { BIG_INT_ZERO } from 'constants/misc'
import { useV2Pairs } from 'hooks/useV2Pairs'
import JSBI from 'jsbi'
import { useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useTokenBalancesWithLoadingIndicator } from 'state/connection/hooks'
import { useStakingInfo } from 'state/stake/hooks'
import { toV2LiquidityToken, useTrackedTokenPairs } from 'state/user/hooks'
import styled, { css, ThemeContext } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { ReactComponent as Pools } from '../../../../assets/svg/icons/Pools.svg'

export const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  padding: 6px 16px;
  border: none;
  width: fit-content;

  background-color: transparent;

  border-radius: 4px;
  border: 1px solid #64748b;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex: 1 1 auto;
    width: 100%;
  `};

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  color: ${({ theme }) => theme.textPrimary};

  &:hover {
    background: ${({ theme }) => theme.stateOverlayHover};
  }
`
const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 24px 0;
  max-width: 300px;
  /* min-height: 25vh; */
`

const EmptyProposals = styled.div`
  border: 1px solid ${({ theme }) => theme.deprecated_text4};
  padding: 16px 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const IconStyle = css`
  width: 40px;
  height: 40px;
  margin-bottom: 0.5rem;
`
const InboxIcon = styled(Pools)`
  ${IconStyle}
`

// const Wrapper = styled(AutoColumn)`
//   width: 100%;
//   gap: 8px;

//   background-color: ${({ theme }) => theme.cardPrimary};
//   padding: 16px;
//   border-radius: 16px;
//   border: 1px solid ${({ theme }) => theme.cardBorderPrimary};
//   box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09);

//   width: 100%;
// `
const Wrapper = styled.div`
  width: 100%;
`

export default function V2Pools({
  account,
  chainId,
  toggleWalletModal,
  searchParam,
}: {
  account?: string | null
  chainId?: number | null
  toggleWalletModal?: () => void
  searchParam?: string | null
}) {
  const theme = useContext(ThemeContext)
  // const { account, chainId } = useWeb3React()
  // const { account, chainId } = useWeb3React()
  const unsupportedV2Network = chainId && UNSUPPORTED_V2POOL_CHAIN_IDS.includes(chainId)

  // fetch the user's balances of all tracked V2 LP tokens
  let trackedTokenPairs = useTrackedTokenPairs()
  if (unsupportedV2Network) trackedTokenPairs = []
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  )
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = useV2Pairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some((V2Pair) => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  // show liquidity even if its deposited in rewards contract
  const stakingInfo = useStakingInfo()
  const stakingInfosWithBalance = stakingInfo?.filter((pool) =>
    JSBI.greaterThan(pool.stakedAmount.quotient, BIG_INT_ZERO)
  )
  const stakingPairs = useV2Pairs(stakingInfosWithBalance?.map((stakingInfo) => stakingInfo.tokens))

  // remove any pairs that also are included in pairs with stake in mining pool
  const v2PairsWithoutStakedAmount = allV2PairsWithLiquidity.filter((v2Pair) => {
    return (
      stakingPairs
        ?.map((stakingPair) => stakingPair[1])
        .filter((stakingPair) => stakingPair?.liquidityToken.address === v2Pair.liquidityToken.address).length === 0
    )
  })

  const showConnectAWallet = Boolean(!account)

  return (
    <Trace page={PageName.POOL_PAGE} shouldLogImpression>
      <>
        <Wrapper>
          {v2IsLoading ? (
            <EmptyProposals>
              <ThemedText.DeprecatedBody color={theme.textPrimary} textAlign="center">
                <Dots>
                  <>Loading</>
                </Dots>
              </ThemedText.DeprecatedBody>
            </EmptyProposals>
          ) : allV2PairsWithLiquidity?.length > 0 || stakingPairs?.length > 0 ? (
            <V2PositionList
              v2PairsWithoutStakedAmount={v2PairsWithoutStakedAmount}
              stakingPairs={stakingPairs}
              stakingInfosWithBalance={stakingInfosWithBalance}
              account={account}
            />
          ) : (
            <MainContentWrapper>
              <V2HeaderRow />
              <ErrorContainer>
                <ThemedText.DeprecatedBody color={theme.textPrimary} textAlign="center">
                  <InboxIcon strokeWidth={1} />
                  <div>
                    <>Your active V2 liquidity positions will appear here.</>
                  </div>
                </ThemedText.DeprecatedBody>
                {showConnectAWallet && (
                  <ButtonPrimary
                    style={{ marginTop: '1em', padding: '8px 16px', width: '140px' }}
                    onClick={toggleWalletModal}
                  >
                    Connect wallet
                  </ButtonPrimary>
                )}
              </ErrorContainer>
              <PositionListFooter>
                <ResponsiveButtonPrimary
                  id="find-pool-button"
                  as={Link}
                  to={`${process.env.REACT_APP_V2_ANALYTICS}/account/${account}`}
                  target="_blank"
                >
                  <RowBetween style={{ gap: '8px' }}>
                    <span>Account Analytics and Accrued Fees</span>
                    <span> ↗ </span>
                  </RowBetween>
                </ResponsiveButtonPrimary>
                {/* <ResponsiveButtonSecondary as={Link} padding="6px 8px" to="/add/v2/ETH">
                      <>Create a pair</>
                    </ResponsiveButtonSecondary> */}
                <ResponsiveButtonPrimary id="find-pool-button" as={Link} to="/liquidity/v2/find" padding="6px 8px">
                  {/* <Text fontWeight={500} fontSize={16}> */}
                  <>Import Pool</>
                  {/* </Text> */}
                </ResponsiveButtonPrimary>
                <ResponsiveButtonPrimary id="join-pool-button" as={Link} to="/liquidity/v2/add/ETH" padding="6px 8px">
                  {/* <Text fontWeight={500} fontSize={16}> */}
                  <>Add V2 Liquidity</>
                  {/* </Text> */}
                </ResponsiveButtonPrimary>
              </PositionListFooter>
            </MainContentWrapper>
          )}
        </Wrapper>
        {/* <SwitchLocaleLink /> */}
      </>
    </Trace>
  )
}
