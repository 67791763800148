import { Currency, CurrencyAmount, Price, Token, TradeType } from '@kodiak-finance/sdk-core'
import { useWeb3React } from '@web3-react/core'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { useMemo, useRef } from 'react'
import { RouterPreference, useRoutingAPITrade } from 'state/routing/useRoutingAPITrade'

import { SupportedChainId } from '../constants/chains'
import {
  USDC_BERACHAIN_ARTIO_TESTNET,
  USDC_BERACHAIN_PRIVATE_TESTNET,
  // CUSD_CELO,
  // DAI_OPTIMISM,
  // USDC_ARBITRUM,
  // USDC_MAINNET,
  // USDC_POLYGON,
  USDC_POLYGON_MUMBAI,
} from '../constants/tokens'

// Stablecoin amounts used when calculating spot price for a given currency.
// The amount is large enough to filter low liquidity pairs.

export const STABLECOIN_AMOUNT_OUT: { [chainId: number]: CurrencyAmount<Token>[] } = {
  [SupportedChainId.POLYGON_MUMBAI]: [CurrencyAmount.fromRawAmount(USDC_POLYGON_MUMBAI, 10_000e6)],

  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // BERA MIGRATION: Add honey/usdc here following the format above.
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  [SupportedChainId.BERACHAIN_PRIVATE_TESTNET]: [CurrencyAmount.fromRawAmount(USDC_BERACHAIN_PRIVATE_TESTNET, 1e18)],
  [SupportedChainId.BERACHAIN_ARTIO_TESTNET]: [CurrencyAmount.fromRawAmount(USDC_BERACHAIN_ARTIO_TESTNET, 1e18)],
}

/**
 * Returns the price in USDC of the input currency
 * @param currency currency to compute the USDC price of
 */
export default function useStablecoinPrice(currency?: Currency): Price<Currency, Token> | undefined {
  const chainId = currency?.chainId

  const amountOut = chainId ? STABLECOIN_AMOUNT_OUT[chainId] : []
  const stablecoins = amountOut?.map((item) => item.currency)

  const { trade } = useRoutingAPITrade(
    TradeType.EXACT_OUTPUT,
    amountOut.length > 0 ? amountOut[0] : undefined,
    currency,
    RouterPreference.CLIENT
  )
  const price = useMemo(() => {
    if (!currency || stablecoins.length === 0) {
      return undefined
    }

    const stablecoin = stablecoins.find((stablecoin) => stablecoin?.equals(currency?.wrapped))

    if (stablecoin !== undefined) {
      return new Price(stablecoin, stablecoin, '1', '1')
    }

    if (trade) {
      const { numerator, denominator } = trade.routes[0].midPrice
      return new Price(currency, stablecoins[0], denominator, numerator)
    }

    return undefined
  }, [currency, stablecoins, trade])

  const lastPrice = useRef(price)
  if (!price || !lastPrice.current || !price.equalTo(lastPrice.current)) {
    lastPrice.current = price
  }
  return lastPrice.current
}

export function useStablecoinValue(currencyAmount: CurrencyAmount<Currency> | undefined | null) {
  const price = useStablecoinPrice(currencyAmount?.currency)

  return useMemo(() => {
    if (!price || !currencyAmount) return null
    try {
      return price.quote(currencyAmount)
    } catch (error) {
      return null
    }
  }, [currencyAmount, price])
}

/**
 *
 * @param fiatValue string representation of a USD amount
 * @returns CurrencyAmount where currency is stablecoin on active chain
 */
export function useStablecoinAmountFromFiatValue(fiatValue: string | null | undefined) {
  const { chainId } = useWeb3React()
  const stablecoin = chainId ? STABLECOIN_AMOUNT_OUT[chainId]?.currency : undefined

  return useMemo(() => {
    if (fiatValue === null || fiatValue === undefined || !chainId || !stablecoin) {
      return undefined
    }

    // trim for decimal precision when parsing
    const parsedForDecimals = parseFloat(fiatValue).toFixed(stablecoin.decimals).toString()
    try {
      // parse USD string into CurrencyAmount based on stablecoin decimals
      return tryParseCurrencyAmount(parsedForDecimals, stablecoin)
    } catch (error) {
      return undefined
    }
  }, [chainId, fiatValue, stablecoin])
}
