import { createAction } from '@reduxjs/toolkit'

export enum Field {
  CURRENCY_A = 'CURRENCY_A',
  CURRENCY_B = 'CURRENCY_B',
}

export enum Bound {
  LOWER = 'LOWER',
  UPPER = 'UPPER',
}

export const typeInput = createAction<{ field: Field; typedValue: string; noLiquidity: boolean }>(
  'mintIsland/typeInputMint'
)
export const resetMintState = createAction<void>('mintIsland/resetMintState')
