import { Currency, CurrencyAmount, Percent, Token } from '@kodiak-finance/sdk-core'
import JSBI from 'jsbi'
import { useSingleCallResult } from 'lib/hooks/multicall'
import useCurrencyBalance from 'lib/hooks/useCurrencyBalance'
import { useState } from 'react'
import { KodiakVault } from 'state/data/generatedV3'
import { unwrappedToken } from 'utils/unwrappedToken'

import { useToken } from './Tokens'
import { useIslandContract } from './useContract'

const DENOMINATOR = JSBI.BigInt('100000000000000000000000')

function useIslandPositionDetails(
  island: KodiakVault | null,
  currencyA: Currency | null,
  currencyB: Currency | null,
  account: any
) {
  const islandInterface = useIslandContract(island?.id)
  const islandToken = useToken(island?.id)

  const islandCurrency = (islandToken && unwrappedToken(islandToken)) || undefined
  // if (islandCurrency?.symbol)
  //   islandCurrency = { ...islandCurrency, symbol: islandCurrency?.symbol?.replace('RAKIS', 'KODIAK') }

  const [error, setError] = useState<Error | null>(null)

  const islandUnderlyingBalances = useSingleCallResult(islandInterface, 'getUnderlyingBalances').result

  const userBalanceShares = useCurrencyBalance(account, islandCurrency)

  const islandTotalSupply =
    island && islandCurrency && island?.outputTokenSupply
      ? CurrencyAmount.fromRawAmount(islandCurrency, island.outputTokenSupply.toString())
      : undefined

  const amount0Current: CurrencyAmount<Currency> | undefined =
    currencyA && islandUnderlyingBalances?.amount0Current
      ? CurrencyAmount.fromRawAmount(currencyA, islandUnderlyingBalances.amount0Current.toString())
      : undefined

  const amount1Current: CurrencyAmount<Currency> | undefined =
    currencyB && islandUnderlyingBalances?.amount1Current
      ? CurrencyAmount.fromRawAmount(currencyB, islandUnderlyingBalances.amount1Current.toString())
      : undefined

  const userLiquidityPercentage =
    userBalanceShares && islandTotalSupply && island?.outputTokenSupply !== '0'
      ? new Percent(
          JSBI.divide(JSBI.multiply(userBalanceShares.quotient, DENOMINATOR), islandTotalSupply.quotient),
          DENOMINATOR
        )
      : undefined

  const userAmount0 =
    islandUnderlyingBalances && userLiquidityPercentage && amount0Current
      ? userLiquidityPercentage.multiply(amount0Current?.quotient).quotient
      : undefined
  const userAmount1 =
    islandUnderlyingBalances && userLiquidityPercentage && amount1Current
      ? userLiquidityPercentage.multiply(amount1Current?.quotient).quotient
      : undefined
  const userUnderlyingBalances = {
    amount0:
      currencyA && userAmount0 && userLiquidityPercentage
        ? CurrencyAmount.fromRawAmount(currencyA, userAmount0)
        : undefined,
    amount1:
      currencyB && userAmount1 && userLiquidityPercentage
        ? CurrencyAmount.fromRawAmount(currencyB, userAmount1)
        : undefined,
  }

  const userBalanceSharesUSD =
    island?.outputTokenPriceUSD && userBalanceShares
      ? island.outputTokenPriceUSD * Number(userBalanceShares.toFixed(2))
      : undefined

  return {
    error,
    islandCurrency,
    islandTotalSupply,
    islandUnderlyingBalances,
    userBalanceShares,
    userBalanceSharesUSD,
    userUnderlyingBalances,
    userLiquidityPercentage,
    island,
  }
}

export const useIslandUnderlyingBalances = (
  island: KodiakVault | null,
  userBalanceShares: CurrencyAmount<Token> | undefined | null,
  currencyA?: Currency | null,
  currencyB?: Currency | null
) => {
  const islandInterface = useIslandContract(island?.id)
  const islandUnderlyingBalances = useSingleCallResult(islandInterface, 'getUnderlyingBalances').result
  const islandToken = userBalanceShares?.currency
  const islandCurrency = (islandToken && unwrappedToken(islandToken)) || undefined

  const islandTotalSupply =
    island && islandCurrency && island?.outputTokenSupply
      ? CurrencyAmount.fromRawAmount(islandCurrency, island.outputTokenSupply.toString())
      : undefined

  const userLiquidityPercentage =
    userBalanceShares && islandTotalSupply && island?.outputTokenSupply !== '0'
      ? new Percent(
          JSBI.divide(JSBI.multiply(userBalanceShares.quotient, DENOMINATOR), islandTotalSupply.quotient),
          DENOMINATOR
        )
      : undefined
  const amount0Current: CurrencyAmount<Currency> | undefined =
    currencyA && islandUnderlyingBalances?.amount0Current
      ? CurrencyAmount.fromRawAmount(currencyA, islandUnderlyingBalances.amount0Current.toString())
      : undefined

  const amount1Current: CurrencyAmount<Currency> | undefined =
    currencyB && islandUnderlyingBalances?.amount1Current
      ? CurrencyAmount.fromRawAmount(currencyB, islandUnderlyingBalances.amount1Current.toString())
      : undefined

  const userAmount0 =
    islandUnderlyingBalances && userLiquidityPercentage && amount0Current
      ? userLiquidityPercentage.multiply(amount0Current?.quotient).quotient
      : undefined
  const userAmount1 =
    islandUnderlyingBalances && userLiquidityPercentage && amount1Current
      ? userLiquidityPercentage.multiply(amount1Current?.quotient).quotient
      : undefined

  const userUnderlyingBalances = {
    amount0:
      currencyA && userAmount0 && userLiquidityPercentage
        ? CurrencyAmount.fromRawAmount(currencyA, userAmount0)
        : undefined,
    amount1:
      currencyB && userAmount1 && userLiquidityPercentage
        ? CurrencyAmount.fromRawAmount(currencyB, userAmount1)
        : undefined,
  }

  return userUnderlyingBalances
}

export default useIslandPositionDetails
