import { MouseoverTooltip } from 'components/Tooltip'
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { ThemedText } from 'theme'

import * as S from './styled'

const V2HeaderRow = () => {
  const theme = useContext(ThemeContext)

  return (
    <S.V2HeaderRowFlex>
      <S.HeaderCol>
        <MouseoverTooltip
          // disableHover={!tooltip}
          text={
            <ThemedText.DeprecatedBody fontSize="12px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
              Full range liquidity pair
            </ThemedText.DeprecatedBody>
          }
        >
          <ThemedText.DeprecatedBody
            fontSize="16px"
            fontWeight="700"
            lineHeight="20px"
            color={theme.textSecondary}
            className="title"
          >
            Pair
          </ThemedText.DeprecatedBody>
        </MouseoverTooltip>
      </S.HeaderCol>

      <S.HeaderCol>
        <MouseoverTooltip
          // disableHover={!tooltip}
          text={
            <ThemedText.DeprecatedBody fontSize="12px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
              User V2 Liquidity token amounts
            </ThemedText.DeprecatedBody>
          }
        >
          <ThemedText.DeprecatedBody
            fontSize="16px"
            fontWeight="700"
            lineHeight="20px"
            color={theme.textSecondary}
            className="title"
          >
            My Deposits
          </ThemedText.DeprecatedBody>
        </MouseoverTooltip>
      </S.HeaderCol>

      {/* <S.HeaderCol>
        <ThemedText.DeprecatedBody fontSize={'16px'} fontWeight="700" lineHeight="20px" color={theme.textSecondary} className='title'>
          Liquidity
        </ThemedText.DeprecatedBody>
      </S.HeaderCol> */}

      <S.HeaderCol>
        <MouseoverTooltip
          // disableHover={!tooltip}
          text={
            <ThemedText.DeprecatedBody fontSize="12px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
              User share of all liquidity in this pair
            </ThemedText.DeprecatedBody>
          }
        >
          <ThemedText.DeprecatedBody
            fontSize="16px"
            fontWeight="700"
            lineHeight="20px"
            color={theme.textSecondary}
            className="title"
          >
            % Share
          </ThemedText.DeprecatedBody>
        </MouseoverTooltip>
      </S.HeaderCol>

      <S.HeaderCol>
        <MouseoverTooltip
          // disableHover={!tooltip}
          text={
            <ThemedText.DeprecatedBody fontSize="12px" fontWeight="500" lineHeight="20px" color={theme.textPrimary}>
              Click to add or remove liquidity
            </ThemedText.DeprecatedBody>
          }
        >
          <ThemedText.DeprecatedBody
            fontSize="16px"
            fontWeight="700"
            lineHeight="20px"
            color={theme.textSecondary}
            className="title"
          >
            Manage
          </ThemedText.DeprecatedBody>
        </MouseoverTooltip>
      </S.HeaderCol>
      {/* <S.HeaderCol></S.HeaderCol> */}
    </S.V2HeaderRowFlex>
  )
}

export default V2HeaderRow
