import { SnapshotType } from 'pages/Islands/IslandPage/IslandAnalyticsCard/utils/chartHelpers'
import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { setLoading, setSnapshotData } from '../actions'

export const useSetSnapshotData = () => {
  const dispatch = useAppDispatch()

  const _setLoading = useCallback(
    (islandId: string, loading: boolean) => {
      dispatch(setLoading({ islandId, loading }))
    },
    [dispatch]
  )

  const setSnapshots = useCallback(
    (islandId: string, snapshots: SnapshotType[]) => {
      dispatch(setSnapshotData({ islandId, snapshots }))
    },
    [dispatch]
  )

  return { setSnapshots, setLoading: _setLoading }
}

export const useSnapshotState = (islandId: any) => {
  const snapshotData = useAppSelector((state) => state.islandSnapshots[islandId])

  if (!snapshotData) {
    return { loading: true, data: null }
  }

  return snapshotData
}
