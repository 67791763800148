import { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'

import { useFarm } from './context/FarmContext'
import DepositView from './DepositView'
import { DepositWithdrawOption, DepositWithdrawSwitch, ManagementCardFlex } from './styleds'
import WithdrawView from './WithdrawView'

const ManagementCard = () => {
  const [direction, setDirection] = useState<'deposit' | 'withdraw'>('deposit')
  const theme = useContext(ThemeContext)
  const farm = useFarm()
  return (
    <ManagementCardFlex>
      <DepositWithdrawSwitch>
        <DepositWithdrawOption
          onClick={() => {
            setDirection('deposit')
          }}
          active={direction === 'deposit'}
        >
          <>Deposit</>
        </DepositWithdrawOption>
        <DepositWithdrawOption
          onClick={() => {
            // When switching to custom slippage, use `auto` value as a default.
            setDirection('withdraw')
          }}
          active={direction === 'withdraw'}
          tabIndex={-1}
        >
          <>Withdraw</>
        </DepositWithdrawOption>
      </DepositWithdrawSwitch>

      {direction === 'withdraw' ? <WithdrawView /> : <DepositView />}
    </ManagementCardFlex>
  )
}

export default ManagementCard
