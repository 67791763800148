import { useWeb3React } from '@web3-react/core'
import { ButtonPrimary } from 'components/Button'
import { AutoColumn, ColumnCenter } from 'components/Column'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { ArrowUpCircle } from 'react-feather'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { CustomLightSpinner, ExternalLink } from 'theme'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

import Circle from '../../../assets/images/blue-loader.svg'

export type ConfirmationTransactionStepProps = {
  attemptingTxn: boolean
  pendingText: ReactNode
  hash: string | undefined
  onConfirm: () => void
  children?: ReactNode | ReactNode[]
  transactionWaiting?: boolean
  autoConfirm?: boolean
}

const Wrapper = styled.div`
  width: 100%;
`
const Section = styled(AutoColumn)<{ inline?: boolean }>`
  padding: ${({ inline }) => (inline ? '0' : '0')};
`

const BottomSection = styled(Section)`
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 32px 0;
`

function ConfirmationPendingContent({ pendingText }: { pendingText: ReactNode }) {
  return (
    <Wrapper>
      <AutoColumn gap="md">
        <ConfirmedIcon>
          <CustomLightSpinner src={Circle} alt="loader" size="90px" />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify="center">
          <Text fontWeight={500} fontSize={20} textAlign="center">
            Waiting For Confirmation
          </Text>
          <Text fontWeight={400} fontSize={16} textAlign="center">
            {pendingText}
          </Text>
          <Text fontWeight={500} fontSize={14} color="#565A69" textAlign="center" marginBottom="12px">
            Confirm this transaction in your wallet
          </Text>
        </AutoColumn>
      </AutoColumn>
    </Wrapper>
  )
}

function TransactionSubmittedContent({
  chainId,
  hash,
  onConfirm,
}: {
  hash: string | undefined
  chainId: number
  onConfirm: () => void
}) {
  const theme = useContext(ThemeContext)

  return (
    <Wrapper>
      <Section>
        <ConfirmedIcon>
          <ArrowUpCircle strokeWidth={0.5} size="90px" color={theme.buttonPrimary} />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify="center">
          <Text fontWeight={500} fontSize={20} textAlign="center">
            Transaction Submitted
          </Text>
          {chainId && hash && (
            <ExternalLink href={getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)}>
              <Text fontWeight={500} fontSize={14} color={theme.buttonPrimary}>
                View on Explorer
              </Text>
            </ExternalLink>
          )}
          <ButtonPrimary onClick={onConfirm} style={{ margin: '20px 0 0 0' }}>
            <Text fontWeight={500} fontSize={20}>
              Confirm
            </Text>
          </ButtonPrimary>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

const ConfirmationTransactionStep = ({
  attemptingTxn,
  children,
  hash,
  pendingText,
  transactionWaiting,
  autoConfirm,
  onConfirm,
}: ConfirmationTransactionStepProps) => {
  const { chainId, provider } = useWeb3React()
  const [waited, setWaited] = useState<boolean>(false)
  useEffect(() => {
    if (!provider || !hash) return

    if (!transactionWaiting && autoConfirm) {
      onConfirm()
    }

    provider.waitForTransaction(hash, 1).then((tx) => {
      if (tx.transactionHash === hash) {
        setWaited(true)
        if (transactionWaiting && autoConfirm) {
          onConfirm()
        }
      }
    })
  }, [hash])

  if (!chainId) return null

  return (
    <>
      {attemptingTxn || (hash && transactionWaiting && !waited) ? (
        <ConfirmationPendingContent pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent hash={hash} chainId={chainId} onConfirm={onConfirm} />
      ) : (
        <>{children}</>
      )}
    </>
  )
}

export default ConfirmationTransactionStep
