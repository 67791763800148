import { CurrencyAmount, Percent } from '@kodiak-finance/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { AutoColumn } from 'components/Column'
import FiatValue from 'components/FiatValue'
import Loader from 'components/Loader'
// import { ConfirmAddModalBottom } from './ConfirmAddModalBottom'
// import { PoolPriceBar } from './PoolPriceBar'
import PoweredByKodiak from 'components/PoweredByKodiak'
import { RowBetween } from 'components/Row'
import { isSupportedChain } from 'constants/chains'
import {
  useCommunalFarmAverageApr,
  useCommunalFarmByIsland,
  useCommunalFarmLockedStakesOf,
} from 'hooks/useCommunalFarms'
import useIslandPositionDetails, { useIslandUnderlyingBalances } from 'hooks/useIslandPositionDetails'
// import IslandSearchModal from 'components/SearchModal/IslandSearchModal'
import { useIsland } from 'hooks/useIslands'
import { useSubgraphTokenValue } from 'hooks/useSubgraphTokenPrice'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { MainContentWrapper } from 'pages/Farms'
import { useCallback, useContext, useState } from 'react'
import { useMemo } from 'react'
import { AlertTriangle, ArrowLeft } from 'react-feather'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Farm } from 'state/farms/reducer'
import styled, { css, ThemeContext } from 'styled-components/macro'
import { ExternalLink } from 'theme/components'
import { formatAmount, formatDollarAmount } from 'utils/formatDollarAmt'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import { unwrappedToken } from 'utils/unwrappedToken'

import DoubleCurrencyLogo from '../../../components/DoubleLogo'
import { WRAPPED_NATIVE_CURRENCY } from '../../../constants/tokens'
import { useToken } from '../../../hooks/Tokens'
import { useIslandRouterContract } from '../../../hooks/useContract'
import { ThemedText } from '../../../theme'
import { IslandProvider } from './context/IslandProvider'
import IslandAnalyticsCard from './IslandAnalyticsCard'
import IslandFarmEarnedCard from './IslandFarmEarnedCard'
import IslandManagementCard from './IslandManagementCard'
import IslandPositionCard from './IslandPositionCard'
import * as S from './styleds'

const TitleRow = styled(RowBetween)`
  color: ${({ theme }) => theme.deprecated_text2};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  `};
`

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 24px 0;
  max-width: 300px;
  /* min-height: 25vh; */
`

const IconStyle = css`
  width: 40px;
  height: 40px;
  margin-bottom: 0.5rem;
`

const NetworkIcon = styled(AlertTriangle)`
  ${IconStyle}
`

const StategyBadge = styled.div`
  background-color: ${({ theme }) => theme.cardSecondary};
  color: ${({ theme }) => theme.textSecondary};
  border-radius: 6px;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `};
`

const ErrorWrapper = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`

type AprCardProps = {
  baseApr?: string | number
  farm?: Farm | undefined | null
  loading?: boolean
}
enum AprType {
  BASE_APR = 'BASE_APR',
  FARM_APR = 'FARM_APR',
}
const BaseApr = ({ providedApr, aprType = AprType.BASE_APR }: { providedApr: number | null; aprType?: AprType }) => {
  const theme = useContext(ThemeContext)

  return (
    <S.IslandInfoInnerBox>
      <ThemedText.DeprecatedBody fontSize="14px" fontWeight="500" lineHeight="14px" color={theme.textSecondary}>
        {aprType === AprType.BASE_APR && 'Base APR'}
        {aprType === AprType.FARM_APR && 'Farm APR'}
      </ThemedText.DeprecatedBody>

      <ThemedText.DeprecatedBody
        fontSize="20px"
        fontWeight="400"
        lineHeight="28px"
        color={theme.textPrimary}
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {providedApr === null ? <Loader /> : `${formatAmount(providedApr.toFixed(2))}%`}
      </ThemedText.DeprecatedBody>
    </S.IslandInfoInnerBox>
  )
}

const AprWithFarm = ({ farm, apr }: { farm: Farm; apr: number | null }) => {
  const farmApr = useCommunalFarmAverageApr(farm)

  const totalApr = useMemo(() => {
    if (apr === null || farmApr === null) {
      return null
    }

    return farmApr || 0
  }, [farmApr, apr])
  return <BaseApr providedApr={totalApr} aprType={AprType.FARM_APR} />
}

function AprCard({ baseApr, farm, loading }: AprCardProps) {
  const apr = useMemo(() => (typeof baseApr === 'string' ? parseFloat(baseApr) : baseApr || 0), [baseApr])

  return <>{farm ? <AprWithFarm farm={farm} apr={apr} /> : <BaseApr providedApr={apr} />}</>
}
function WrongNetworkCard() {
  const theme = useContext(ThemeContext)
  return (
    <>
      <S.Wrapper>
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            <TitleRow style={{ marginTop: '1rem' }} padding="0">
              <ThemedText.DeprecatedBody fontSize="20px">
                <>Islands Overview</>
              </ThemedText.DeprecatedBody>
            </TitleRow>

            <MainContentWrapper>
              <ErrorContainer>
                <ThemedText.DeprecatedBody color={theme.deprecated_text3} textAlign="center">
                  <NetworkIcon strokeWidth={1.2} />
                  <div data-testid="pools-unsupported-err">
                    <>Your connected network is unsupported.</>
                  </div>
                </ThemedText.DeprecatedBody>
              </ErrorContainer>
            </MainContentWrapper>
          </AutoColumn>
        </AutoColumn>
      </S.Wrapper>
      <PoweredByKodiak />
    </>
  )
}

export default function IslandPage() {
  const { tokenId: islandId } = useParams<{ tokenId?: string }>()
  const navigate = useNavigate()
  const { account, chainId, provider } = useWeb3React()

  const { island, isLoading, isFetching, isUninitialized, isError, error } = useIsland(islandId)

  console.log(`ISLAND->Detail->`, island)
  const theme = useContext(ThemeContext)

  const token0 = useToken(island?.token0?.id)
  const token1 = useToken(island?.token1?.id)

  const currencyA = (token0 && unwrappedToken(token0)) || null
  const currencyB = (token1 && unwrappedToken(token1)) || null

  const router = useIslandRouterContract()

  const wrappedNativeCurrency = chainId ? WRAPPED_NATIVE_CURRENCY[chainId] : undefined

  const oneCurrencyIsWETH = Boolean(
    chainId &&
      wrappedNativeCurrency &&
      ((currencyA && currencyA.equals(wrappedNativeCurrency)) || (currencyB && currencyB.equals(wrappedNativeCurrency)))
  )

  const feeAmount = island?.pool?.feeTier

  const {
    islandCurrency,
    islandTotalSupply,
    islandUnderlyingBalances,
    userBalanceShares,
    userBalanceSharesUSD,
    userUnderlyingBalances,
    userLiquidityPercentage,
  } = useIslandPositionDetails(island, currencyA, currencyB, account)

  const value0 = useSubgraphTokenValue(
    tryParseCurrencyAmount(userUnderlyingBalances.amount0?.toExact() || '0', token0 || undefined)
  )
  const value1 = useSubgraphTokenValue(
    tryParseCurrencyAmount(userUnderlyingBalances.amount1?.toExact() || '0', token1 || undefined)
  )
  const value = useMemo(() => (value0 || 0) + (value1 || 0), [value0, value1])

  const [, setModalOpen] = useState(false)
  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const farm = useCommunalFarmByIsland(islandId)
  const {
    totalLiquidity: totalFarmLiquidity,
    totalLiquidityUSD: totalFarmLiquidityUSD,
    stakes,
  } = useCommunalFarmLockedStakesOf(farm, account)

  const userFarmUnderlyingBalances = useIslandUnderlyingBalances(island, totalFarmLiquidity, currencyA, currencyB)

  const combinedUserUnderlyingBalances = useMemo(() => {
    if (!userUnderlyingBalances.amount0 || !userUnderlyingBalances.amount1) {
      return { amount0: undefined, amount1: undefined }
    }
    if (!userFarmUnderlyingBalances.amount0 || !userFarmUnderlyingBalances.amount1) {
      return userUnderlyingBalances
    }
    return {
      amount0: userUnderlyingBalances.amount0.add(userFarmUnderlyingBalances.amount0),
      amount1: userUnderlyingBalances.amount1.add(userFarmUnderlyingBalances.amount1),
    }
  }, [userUnderlyingBalances, userFarmUnderlyingBalances])

  const combinedUserBalanceShares = useMemo(() => {
    if (!islandCurrency) return undefined
    let shares = CurrencyAmount.fromRawAmount(islandCurrency, '0')
    if (userBalanceShares) {
      shares = shares.add(userBalanceShares)
    }
    if (totalFarmLiquidity) {
      shares = shares.add(totalFarmLiquidity)
    }
    return shares
  }, [userBalanceShares, totalFarmLiquidity, islandCurrency])

  const combinedUserBalanceSharesUSD = useMemo(
    () => (userBalanceSharesUSD || 0) + (totalFarmLiquidityUSD || 0),
    [userBalanceSharesUSD, totalFarmLiquidityUSD]
  )

  const contextValues = useMemo(() => {
    console.log(`contextValues->`, {
      island,
      currencyA,
      currencyB,
      islandId,
    })
    if (!island || !currencyA || !currencyB || !islandId) {
      // Return some loading or error state here
      return null // or <Loading /> or some other JSX
    }

    return {
      currencyA,
      currencyB,
      wrappedNativeCurrency,
      feeAmount,
      islandId: islandId as string,
      router,
    }
  }, [currencyA, currencyB, wrappedNativeCurrency, feeAmount, island, router, islandId])

  console.log('index:positionn', {
    islandCurrency,
    islandTotalSupply,
    islandUnderlyingBalances,
    userBalanceShares,
    userUnderlyingBalances,
    userLiquidityPercentage,
    value,
  })

  console.log('index:subgraph', {
    island,
    isLoading,
    isFetching,
    isUninitialized,
    isError,
    error,
  })
  console.log('index:contextValues', contextValues)

  if (account && !isSupportedChain(chainId)) {
    return <WrongNetworkCard />
  }

  console.log(error)

  if (isLoading || isFetching || (isUninitialized && !error)) {
    return (
      <S.Wrapper>
        <div>Loading...</div>
      </S.Wrapper>
    )
  }

  if (isError && error?.name !== 'UnsupportedChainId') {
    return (
      <ErrorWrapper>
        <ThemedText.DeprecatedBody fontSize="20px" fontWeight="500" lineHeight="14px" color={theme.textPrimary}>
          Error loading island. Please double check the address and try again
        </ThemedText.DeprecatedBody>
      </ErrorWrapper>
    )
  }

  if (contextValues === null) {
    return null
  }

  // const onIslandSelect = (islandId) => {
  //   navigate(`islands/${tokenId}`)
  // }

  // const handleIslandSelect = useCallback(
  //   (islandId: number | string) => {
  //     onIslandSelect(islandId)
  //   },
  //   [onIslandSelect]
  // )

  return (
    <IslandProvider value={contextValues}>
      <S.Wrapper>
        <Link
          data-cy="visit-islands"
          style={{ textDecoration: 'none', width: 'fit-content', marginBottom: '0.5rem' }}
          to="/liquidity/islands"
        >
          <S.BackButton>
            <ArrowLeft color={theme.textSecondary} size={20} />
            Islands
          </S.BackButton>
        </Link>

        {island && (
          <>
            <S.IslandInfoCard>
              <S.IslandSelect
                disabled={false}
                visible={island !== undefined}
                selected={!!island}
                hideInput={false}
                className="open-island-select-button"
                // onClick={() => {
                //   if (handleIslandSelect) {
                //     setModalOpen(true)
                //   }
                // }}
              >
                <S.FlexRowCenter>
                  {island && currencyA && currencyB && (
                    <>
                      <span style={{ marginRight: '0.5rem' }}>
                        <DoubleCurrencyLogo currency0={currencyA} currency1={currencyB} size={24} margin={true} />
                      </span>
                      <ThemedText.DeprecatedBody
                        fontSize="20px"
                        fontWeight="600"
                        lineHeight="20px"
                        color={theme.textPrimary}
                      >
                        {currencyA.symbol}-{currencyB.symbol}
                      </ThemedText.DeprecatedBody>
                    </>
                  )}

                  {/* {onIslandSelect && <StyledDropDown selected={!!island} />} */}
                  <StategyBadge>{new Percent(island?.pool?.feeTier, 1_000_000).toSignificant()}%</StategyBadge>
                </S.FlexRowCenter>
              </S.IslandSelect>
              {chainId && island?.pool?.id && (
                <S.FlexRowCenter>
                  <ExternalLink href={getExplorerLink(chainId, island.id, ExplorerDataType.ADDRESS)}>
                    Island Contract ↗
                  </ExternalLink>

                  <ExternalLink href={getExplorerLink(chainId, island.pool.id, ExplorerDataType.ADDRESS)}>
                    Pool Contract ↗
                  </ExternalLink>
                  {farm && (
                    <>
                      <ExternalLink href={getExplorerLink(chainId, farm.address, ExplorerDataType.ADDRESS)}>
                        Farm Contract ↗
                      </ExternalLink>
                    </>
                  )}
                </S.FlexRowCenter>
              )}

              <S.FlexRowCenter style={{ flexWrap: 'wrap' }}>
                <S.IslandInfoInnerBox>
                  <ThemedText.DeprecatedBody
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="14px"
                    color={theme.textSecondary}
                  >
                    TVL
                  </ThemedText.DeprecatedBody>

                  <ThemedText.DeprecatedBody
                    fontSize="20px"
                    fontWeight="400"
                    lineHeight="28px"
                    color={theme.textPrimary}
                  >
                    <FiatValue value={island?.totalValueLockedUSD} />
                  </ThemedText.DeprecatedBody>
                </S.IslandInfoInnerBox>

                <S.IslandInfoInnerBox>
                  <ThemedText.DeprecatedBody
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="14px"
                    color={theme.textSecondary}
                  >
                    Island APR
                  </ThemedText.DeprecatedBody>

                  <ThemedText.DeprecatedBody
                    fontSize="20px"
                    fontWeight="400"
                    lineHeight="28px"
                    color={theme.textPrimary}
                  >
                    {`${formatAmount(parseFloat(island?.apr?.averageApr || 0).toFixed(2))}%`}
                  </ThemedText.DeprecatedBody>
                </S.IslandInfoInnerBox>

                <AprCard baseApr={island?.apr?.averageApr} loading={farm === null} farm={farm} />

                <S.IslandInfoInnerBox>
                  <ThemedText.DeprecatedBody
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="14px"
                    color={theme.textSecondary}
                  >
                    Volume (All Time)
                  </ThemedText.DeprecatedBody>

                  <ThemedText.DeprecatedBody
                    fontSize="20px"
                    fontWeight="400"
                    lineHeight="28px"
                    color={theme.textPrimary}
                  >
                    {formatDollarAmount(Number(island?.volumeUSD) || 0)}
                  </ThemedText.DeprecatedBody>
                </S.IslandInfoInnerBox>
              </S.FlexRowCenter>
            </S.IslandInfoCard>
            <S.FlexCol>
              <IslandAnalyticsCard island={island} />
              {/* <IslandStrategyCard /> */}
            </S.FlexCol>
            <S.AnalyticsAndManagementFlex>
              <S.CardRowFlex>
                <S.FlexCol>
                  <IslandPositionCard
                    totalFarmLiquidityUSD={totalFarmLiquidityUSD}
                    userBalanceSharesUSD={userBalanceSharesUSD}
                    combinedUserBalanceSharesUSD={combinedUserBalanceSharesUSD}
                    userBalanceShares={userBalanceShares}
                    combinedUserBalanceShares={combinedUserBalanceShares}
                    totalFarmLiquidity={totalFarmLiquidity}
                    islandCurrency={islandCurrency}
                    userFarmUnderlyingBalances={userFarmUnderlyingBalances}
                    combinedUserUnderlyingBalances={combinedUserUnderlyingBalances}
                    userUnderlyingBalances={userUnderlyingBalances}
                    currencyA={currencyA}
                    currencyB={currencyB}
                    token0={token0}
                    token1={token1}
                    stakes={stakes}
                  />
                  {farm && <IslandFarmEarnedCard farm={farm} />}
                </S.FlexCol>
                <IslandManagementCard />
              </S.CardRowFlex>
            </S.AnalyticsAndManagementFlex>
          </>
        )}
      </S.Wrapper>

      {/* {onIslandSelect && (
        <IslandSearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onIslandSelect={handleIslandSelect}
          selectedIsland={island}
          showCommonBases={true}
          showIslandAmount={true}
          disableNonToken={true}
        />
      )} */}
      <PoweredByKodiak />
    </IslandProvider>
  )
}
