import { Currency } from '@kodiak-finance/sdk-core'
import { useToken } from 'hooks/Tokens'
import styled from 'styled-components/macro'

import CurrencyLogo from '../CurrencyLogo'

const Wrapper = styled.div<{ sizeraw: number; count: number }>`
  position: relative;
  display: flex;
  flex-direction: row;
  width: ${({ sizeraw, count }) => ((sizeraw / 2) * (count + 1)).toString() + 'px'};
  height: 100%;
  align-items: center;
`

interface DoubleCurrencyLogoProps {
  margin?: boolean
  size?: number
  currencies: (Currency | string)[]
}

const CoveredLogo = styled(CurrencyLogo)<{ sizeraw: number; index: number }>`
  position: absolute;
  left: ${({ sizeraw, index }) => '' + ((sizeraw / 2) * index).toString() + 'px'} !important;
  z-index: ${({ index }) => `${10 - index}`};
`

function Logo({ currency, index, size }: { currency: string | Currency; index: number; size: number }) {
  if (typeof currency === 'string') {
    return <LogoByAddress address={currency} index={index} size={size} />
  }
  return <LogoByCurrency currency={currency} index={index} size={size} />
}

function LogoByCurrency({ currency, index, size }: { currency: Currency; index: number; size: number }) {
  return <CoveredLogo index={index} currency={currency} size={size.toString() + 'px'} sizeraw={size} />
}

function LogoByAddress({ address, index, size }: { address: string; index: number; size: number }) {
  const token = useToken(address)
  if (!token) return null
  return <LogoByCurrency currency={token} index={index} size={size} />
}

export default function MultiCurrencyLogo({ currencies, size = 16, margin = false }: DoubleCurrencyLogoProps) {
  return (
    <Wrapper sizeraw={size} count={currencies.length}>
      {currencies.map((currency, index) => (
        // <CoveredLogo index={index} key={currency.symbol} currency={currency} size={size.toString() + 'px'} sizeraw={size} />
        <Logo
          key={typeof currency === 'string' ? currency : currency.symbol}
          currency={currency}
          index={index}
          size={size}
        />
      ))}
    </Wrapper>
  )
}
