import AddressManager, { ContractType } from '@kodiak-finance/address-manager'

import { constructSameAddressMap } from '../utils/constructSameAddressMap'
import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

const Mumbai = AddressManager.network(SupportedChainId.POLYGON_MUMBAI)
const BerachainPrivate = AddressManager.network(SupportedChainId.BERACHAIN_PRIVATE_TESTNET)
const BerachainArtio = AddressManager.network(SupportedChainId.BERACHAIN_ARTIO_TESTNET)

export const V2_FACTORY_ADDRESSES: AddressMap = {
  [SupportedChainId.POLYGON_MUMBAI]: Mumbai.require(ContractType.UniswapV2Factory),
  [SupportedChainId.BERACHAIN_PRIVATE_TESTNET]: BerachainPrivate.require(ContractType.UniswapV2Factory),
  [SupportedChainId.BERACHAIN_ARTIO_TESTNET]: BerachainArtio.require(ContractType.UniswapV2Factory),
  // [SupportedChainId.BERACHAIN_MAINNET]: AddressManager.network(SupportedChainId.BERACHAIN_MAINNET).require(ContractType.UniswapV2Factory),
}

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  [SupportedChainId.POLYGON_MUMBAI]: Mumbai.require(ContractType.UniswapV3Factory),
  [SupportedChainId.BERACHAIN_PRIVATE_TESTNET]: BerachainPrivate.require(ContractType.UniswapV3Factory),
  [SupportedChainId.BERACHAIN_ARTIO_TESTNET]: BerachainArtio.require(ContractType.UniswapV3Factory),
  // [SupportedChainId.BERACHAIN_MAINNET]: BerachainMainnet.require(ContractType.UniswapV3Factory),
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  [SupportedChainId.POLYGON_MUMBAI]: Mumbai.require(ContractType.NonfungiblePositionManager),
  [SupportedChainId.BERACHAIN_PRIVATE_TESTNET]: BerachainPrivate.require(ContractType.NonfungiblePositionManager),
  [SupportedChainId.BERACHAIN_ARTIO_TESTNET]: BerachainArtio.require(ContractType.NonfungiblePositionManager),
  // [SupportedChainId.BERACHAIN_MAINNET]: BerachainMainnet.require(ContractType.NonfungiblePositionManager),
}

export const V3_ROUTER_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON_MUMBAI]: Mumbai.require(ContractType.SwapRouter),
  [SupportedChainId.BERACHAIN_PRIVATE_TESTNET]: BerachainPrivate.require(ContractType.SwapRouter),
  [SupportedChainId.BERACHAIN_ARTIO_TESTNET]: BerachainArtio.require(ContractType.SwapRouter),
  // [SupportedChainId.BERACHAIN_MAINNET]: BerachainMainnet.require(ContractType.SwapRouter),
}

// this needs to be the SwapRouter02
export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  [SupportedChainId.POLYGON_MUMBAI]: Mumbai.require(ContractType.SwapRouter02),
  [SupportedChainId.BERACHAIN_PRIVATE_TESTNET]: BerachainPrivate.require(ContractType.SwapRouter02),
  [SupportedChainId.BERACHAIN_ARTIO_TESTNET]: BerachainArtio.require(ContractType.SwapRouter02),
  // [SupportedChainId.BERACHAIN_MAINNET]: BerachainMainnet.require(ContractType.SwapRouter02),
}

export const V2_ROUTER_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON_MUMBAI]: Mumbai.require(ContractType.UniswapV2Router02),
  [SupportedChainId.BERACHAIN_PRIVATE_TESTNET]: BerachainPrivate.require(ContractType.UniswapV2Router02),
  [SupportedChainId.BERACHAIN_ARTIO_TESTNET]: BerachainArtio.require(ContractType.UniswapV2Router02),
  // [SupportedChainId.BERACHAIN_MAINNET]: BerachainMainnet.require(ContractType.UniswapV2Router02),
}

export const QUOTER_ADDRESSES: AddressMap = {
  [SupportedChainId.POLYGON_MUMBAI]: Mumbai.require(ContractType.QuoterV2),
  [SupportedChainId.BERACHAIN_PRIVATE_TESTNET]: BerachainPrivate.require(ContractType.QuoterV2),
  [SupportedChainId.BERACHAIN_ARTIO_TESTNET]: BerachainArtio.require(ContractType.QuoterV2),
  // [SupportedChainId.BERACHAIN_MAINNET]: BerachainMainnet.require(ContractType.QuoterV2),
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  [SupportedChainId.POLYGON_MUMBAI]: Mumbai.require(ContractType.TickLens),
  [SupportedChainId.BERACHAIN_PRIVATE_TESTNET]: BerachainPrivate.require(ContractType.TickLens),
  [SupportedChainId.BERACHAIN_ARTIO_TESTNET]: BerachainArtio.require(ContractType.TickLens),
  // [SupportedChainId.BERACHAIN_MAINNET]: BerachainMainnet.require(ContractType.TickLens),
}

export const MULTICALL_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON_MUMBAI]: Mumbai.require(ContractType.Multicall),
  [SupportedChainId.BERACHAIN_PRIVATE_TESTNET]: BerachainPrivate.require(ContractType.Multicall),
  [SupportedChainId.BERACHAIN_ARTIO_TESTNET]: BerachainArtio.require(ContractType.Multicall),
  // [SupportedChainId.BERACHAIN_MAINNET]: BerachainMainnet.require(ContractType.Multicall),
}

export const KODIAK_V1_FACTORY: AddressMap = {
  [SupportedChainId.POLYGON_MUMBAI]: Mumbai.require(ContractType.KodiakFactoryV1),
  [SupportedChainId.BERACHAIN_PRIVATE_TESTNET]: BerachainPrivate.require(ContractType.KodiakFactoryV1),
  [SupportedChainId.BERACHAIN_ARTIO_TESTNET]: BerachainArtio.require(ContractType.KodiakFactoryV1),
  // [SupportedChainId.BERACHAIN_MAINNET]: BerachainMainnet.require(ContractType.KodiakFactoryV1),
}

export const KODIAK_TOKEN: AddressMap = {
  [SupportedChainId.BERACHAIN_ARTIO_TESTNET]: BerachainArtio.require(ContractType.KDK),
}

export const XKODIAK_TOKEN: AddressMap = {
  [SupportedChainId.BERACHAIN_ARTIO_TESTNET]: BerachainArtio.require(ContractType.XKDK),
}

export const KODIAK_REWARDS: AddressMap = {
  [SupportedChainId.BERACHAIN_ARTIO_TESTNET]: BerachainArtio.require(ContractType.KodiakRewards),
}

export const KODIAK_V1_VAULT: AddressMap = {
  [SupportedChainId.POLYGON_MUMBAI]: Mumbai.require(ContractType.KodiakVaultV1),
  [SupportedChainId.BERACHAIN_PRIVATE_TESTNET]: BerachainPrivate.require(ContractType.KodiakVaultV1),
  [SupportedChainId.BERACHAIN_ARTIO_TESTNET]: BerachainArtio.require(ContractType.KodiakVaultV1),
  // [SupportedChainId.BERACHAIN_MAINNET]: BerachainMainnet.require(ContractType.KodiakVaultV1),
}

export const KODIAK_V1_RESOLVER: AddressMap = {
  [SupportedChainId.POLYGON_MUMBAI]: Mumbai.require(ContractType.KodiakResolverV1),
  [SupportedChainId.BERACHAIN_PRIVATE_TESTNET]: BerachainPrivate.require(ContractType.KodiakResolverV1),
  [SupportedChainId.BERACHAIN_ARTIO_TESTNET]: BerachainArtio.require(ContractType.KodiakResolverV1),
  // [SupportedChainId.BERACHAIN_MAINNET]: BerachainMainnet.require(ContractType.KodiakResolverV1),
}

export const KODIAK_V1_ROUTER_STAKING: AddressMap = {
  [SupportedChainId.POLYGON_MUMBAI]: Mumbai.require(ContractType.KodiakRouterStakingV1),
  [SupportedChainId.BERACHAIN_PRIVATE_TESTNET]: BerachainPrivate.require(ContractType.KodiakRouterStakingV1),
  [SupportedChainId.BERACHAIN_ARTIO_TESTNET]: BerachainArtio.require(ContractType.KodiakRouterStakingV1),
  // [SupportedChainId.BERACHAIN_MAINNET]: BerachainMainnet.require(ContractType.KodiakRouterStakingV1),
}

// ------------------------------------------
// UNUSED FROM UNI
// ------------------------------------------

// export const V3_MIGRATOR_ADDRESSES: AddressMap = {
//   ...constructSameAddressMap('0xA5644E29708357803b5A882D272c41cC0dF92B34', [
//     // SupportedChainId.ARBITRUM_ONE,
//     // SupportedChainId.ARBITRUM_RINKEBY,
//     // SupportedChainId.POLYGON,
//     SupportedChainId.POLYGON_MUMBAI,
//   ]),
// }

export const UNI_ADDRESS: AddressMap = constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984')

/**
 * The oldest V0 governance address
 */
export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap = constructSameAddressMap(
  '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'
)
/**
 * The older V1 governance address
 */
export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {
  // [SupportedChainId.MAINNET]: '0xC4e172459f1E7939D522503B81AFAaC1014CE6F6',
}
/**
 * The latest governor bravo that is currently admin of timelock
 */
export const GOVERNANCE_BRAVO_ADDRESSES: AddressMap = {
  // [SupportedChainId.MAINNET]: '0x408ED6354d4973f66138C91495F2f2FCbd8724C3',
}

export const TIMELOCK_ADDRESS: AddressMap = constructSameAddressMap('0x1a9C8182C09F50C8318d769245beA52c32BE35BC')

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
  // [SupportedChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e',
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  // [SupportedChainId.MAINNET]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8',
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  // [SupportedChainId.MAINNET]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  // [SupportedChainId.ROPSTEN]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  // [SupportedChainId.GOERLI]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  // [SupportedChainId.RINKEBY]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
}

// ----------------------------------
// Fallback to before address manager
// -----------------------------------
// import { constructSameAddressMap } from '../utils/constructSameAddressMap'
// import { SupportedChainId } from './chains'
// import {
// import { SwapRouter02 } from '../types/v3/SwapRouter02.d';
// KODIAK_FACTORY_ADDRESS,
//   KODIAK_NONFUNGIBLE_ADDRESS,
//   KODIAK_QUOTER_ADDRESS,
//   KODIAK_SWAP_ROUTER_02_ADDRESS,
//   KODIAK_SWAP_ROUTER_ADDRESS,
//   KODIAK_V2_FACTORY_ADDRESS,
// } from './kodiak'

// type AddressMap = { [chainId: number]: string }

// export const UNI_ADDRESS: AddressMap = constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984')

// export const V2_FACTORY_ADDRESSES: AddressMap = {
//   ...constructSameAddressMap(KODIAK_V2_FACTORY_ADDRESS, [
//     SupportedChainId.POLYGON_MUMBAI,
//     // SupportedChainId.POLYGON
//   ]),
// }
// export const V2_ROUTER_ADDRESS: AddressMap = constructSameAddressMap('0x22704908fb549921beeab77aca4d2bf044e9958e', [
//   SupportedChainId.POLYGON_MUMBAI,
//   // SupportedChainId.POLYGON,
// ])

// /* V3 Contract Addresses */
// export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
//   // ...constructSameAddressMap(KODIAK_FACTORY_ADDRESS, [
//   ...constructSameAddressMap(KODIAK_FACTORY_ADDRESS, [
//     // SupportedChainId.OPTIMISM,
//     // SupportedChainId.OPTIMISTIC_KOVAN,
//     // SupportedChainId.ARBITRUM_ONE,
//     // SupportedChainId.ARBITRUM_RINKEBY,
//     SupportedChainId.POLYGON_MUMBAI,
//     // SupportedChainId.POLYGON,
//   ]),
// }

// export const V3_ROUTER_ADDRESS: AddressMap = {
//   // ...constructSameAddressMap('0xE592427A0AEce92De3Edee1F18E0157C05861564', [
//   ...constructSameAddressMap(KODIAK_SWAP_ROUTER_ADDRESS, [
//     // SupportedChainId.OPTIMISM,
//     // SupportedChainId.OPTIMISTIC_KOVAN,
//     // SupportedChainId.ARBITRUM_ONE,
//     // SupportedChainId.ARBITRUM_RINKEBY,
//     // SupportedChainId.POLYGON,
//     SupportedChainId.POLYGON_MUMBAI,
//   ]),
// }

// export const V3_MIGRATOR_ADDRESSES: AddressMap = {
//   ...constructSameAddressMap('0xA5644E29708357803b5A882D272c41cC0dF92B34', [
//     // SupportedChainId.ARBITRUM_ONE,
//     // SupportedChainId.ARBITRUM_RINKEBY,
//     // SupportedChainId.POLYGON,
//     SupportedChainId.POLYGON_MUMBAI,
//   ]),
// }

// export const MULTICALL_ADDRESS: AddressMap = {
//   ...constructSameAddressMap('0x1F98415757620B543A52E61c46B32eB19261F984', [
//     // SupportedChainId.OPTIMISTIC_KOVAN,
//     // SupportedChainId.OPTIMISM,
//     // SupportedChainId.POLYGON,
//     SupportedChainId.POLYGON_MUMBAI,
//   ]),
//   // [SupportedChainId.ARBITRUM_ONE]: '0xadF885960B47eA2CD9B55E6DAc6B42b7Cb2806dB',
// }

// export const SWAP_ROUTER_ADDRESSES: AddressMap = {
//   // ...constructSameAddressMap('0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45', [
//   // uni default
//   ...constructSameAddressMap(KODIAK_SWAP_ROUTER_02_ADDRESS, [
//     // SupportedChainId.OPTIMISM,
//     // SupportedChainId.OPTIMISTIC_KOVAN,
//     // SupportedChainId.ARBITRUM_ONE,
//     // SupportedChainId.ARBITRUM_RINKEBY,
//     // SupportedChainId.POLYGON,
//     SupportedChainId.POLYGON_MUMBAI,
//   ]),
//   // [SupportedChainId.CELO]: CELO_V3_ROUTER_ADDRESS,
// }

// /**
//  * The oldest V0 governance address
//  */
// export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap = constructSameAddressMap(
//   '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'
// )
// /**
//  * The older V1 governance address
//  */
// export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {
//   // [SupportedChainId.MAINNET]: '0xC4e172459f1E7939D522503B81AFAaC1014CE6F6',
// }
// /**
//  * The latest governor bravo that is currently admin of timelock
//  */
// export const GOVERNANCE_BRAVO_ADDRESSES: AddressMap = {
//   // [SupportedChainId.MAINNET]: '0x408ED6354d4973f66138C91495F2f2FCbd8724C3',
// }

// export const TIMELOCK_ADDRESS: AddressMap = constructSameAddressMap('0x1a9C8182C09F50C8318d769245beA52c32BE35BC')

// export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
//   // [SupportedChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e',
// }

// export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
//   // [SupportedChainId.MAINNET]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8',
// }

// export const QUOTER_ADDRESSES: AddressMap = {
//   // ...constructSameAddressMap('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6', [
//   ...constructSameAddressMap(KODIAK_QUOTER_ADDRESS, [
//     // SupportedChainId.POLYGON,
//     SupportedChainId.POLYGON_MUMBAI,
//   ]),
//   // [SupportedChainId.CELO]: CELO_QUOTER_ADDRESSES,
// }

// export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
//   // ...constructSameAddressMap('0xC36442b4a4522E871399CD717aBDD847Ab11FE88', [
//   // uni default
//   ...constructSameAddressMap(KODIAK_NONFUNGIBLE_ADDRESS, [
//     // SupportedChainId.POLYGON,
//     SupportedChainId.POLYGON_MUMBAI,
//   ]),
//   // [SupportedChainId.CELO]: CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
// }

// export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
//   // [SupportedChainId.MAINNET]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
//   // [SupportedChainId.ROPSTEN]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
//   // [SupportedChainId.GOERLI]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
//   // [SupportedChainId.RINKEBY]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
// }

// export const TICK_LENS_ADDRESSES: AddressMap = {
//   // [SupportedChainId.ARBITRUM_ONE]: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
//   // [SupportedChainId.ARBITRUM_RINKEBY]: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
//   [SupportedChainId.POLYGON_MUMBAI]: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
// }
