import { useMemo } from 'react'

import useTimeBetweenText from './hooks/useTimeBetweenText'

type TimeBetweenText = (
  | {
      date0: Date
      date1: Date
    }
  | { date: Date }
) & {
  children?: React.ReactNode | React.ReactNode[]
}
const TimeBetweenText = ({ children, ...props }: TimeBetweenText) => {
  const date0 = useMemo(() => ('date' in props ? props.date : props.date0), [props])
  const date1 = useMemo(() => ('date1' in props ? props.date1 : undefined), [props])

  const text = useTimeBetweenText(date0, date1)
  return text ? text : children
}

export default TimeBetweenText
