import { CurrencyAmount, Token } from '@kodiak-finance/sdk-core'
import { ButtonPrimary } from 'components/Button'
import { LightCard } from 'components/Card'
import Column, { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import FiatValue from 'components/FiatValue'
import Row, { RowBetween, RowTitled } from 'components/Row'
import ConfirmationTransactionStep, {
  ConfirmationTransactionStepProps,
} from 'components/StepLine/common/ConfirmationTransactionStep'
import { useCurrency } from 'hooks/Tokens'
import { CommunalFarmStake, useCommunalFarmByIsland, useCommunalFarmEarned } from 'hooks/useCommunalFarms'
import { useIslandUnderlyingBalances } from 'hooks/useIslandPositionDetails'
import { useIsland } from 'hooks/useIslands'
import sumCurrencyAmount from 'lib/utils/sumCurrencyAmount'
import { useContext, useMemo } from 'react'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { Separator } from 'theme'

import { UnderlyingView } from './WithdrawBalanceConstructorStep'

type WithdrawFromStakeStepProps = {
  stakes: CommunalFarmStake[]
  next: () => void
  onWithdraw: () => void
} & Omit<ConfirmationTransactionStepProps, 'children' | 'onConfirm'>

const StakeView = ({ stake }: { stake: CommunalFarmStake }) => {
  const liquidity = stake.liquidity as CurrencyAmount<Token>
  const { island } = useIsland(liquidity?.currency?.address?.toLowerCase())
  const currency0 = useCurrency(island?.token0.id)
  const currency1 = useCurrency(island?.token1.id)
  const underlyingBalances = useIslandUnderlyingBalances(island, liquidity, currency0, currency1)

  return (
    <RowTitled
      reverse
      header={
        <Text fontSize="14px" style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
          {stake.liquidity?.toSignificant(6)} {stake.liquidity?.currency?.symbol}
        </Text>
      }
    >
      <UnderlyingView
        amount0={underlyingBalances?.amount0}
        amount1={underlyingBalances?.amount1}
        currency0={currency0}
        currency1={currency1}
      />
    </RowTitled>
  )
}
const WithdrawFromStakeStep = ({ stakes, onWithdraw, next, ...rest }: WithdrawFromStakeStepProps) => {
  const sum = useMemo(() => {
    return sumCurrencyAmount(stakes.map((item) => item.liquidity))
  }, [stakes])

  const theme = useContext(ThemeContext)
  const islandAddress = (stakes?.length || 0) > 0 ? stakes[0].liquidity?.currency.address : undefined
  const farm = useCommunalFarmByIsland(islandAddress)
  const earned = useCommunalFarmEarned(farm)

  return (
    <ConfirmationTransactionStep {...rest} onConfirm={next}>
      <Column width="100%" gap="8px">
        <LightCard>
          <AutoColumn gap="8px">
            {stakes.map((stake) => {
              return <StakeView stake={stake} key={stake.kekId} />
            })}
            <Separator />

            <RowBetween>
              <Row grow="1">
                <Text fontSize={14} color={theme.textSecondary}>
                  Total
                </Text>
              </Row>
              <Row
                grow="0"
                shrink="0"
                width="fit-content"
                style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
                justify="flex-end"
              >
                <Text fontSize={14} marginRight="4px" color={theme.textSecondary}>
                  (<FiatValue currencyAmount={sum} />)
                </Text>
                <Text fontSize={14}>
                  {sum?.toSignificant(6)} {sum?.currency?.symbol}
                </Text>
              </Row>
            </RowBetween>
          </AutoColumn>
        </LightCard>
        <LightCard>
          <AutoColumn gap="8px">
            <Text fontSize={14} color={theme.textSecondary}>
              You will also unlock the following tokens as rewards
            </Text>
            {earned?.map((e) => {
              return (
                <RowBetween key={e.currency.address}>
                  <Row grow="0" shrink="0" width="fit-content">
                    <CurrencyLogo currency={e.currency} size="21px" style={{ marginRight: '8px' }} />
                    <Text fontSize={14}>{e.currency.symbol}</Text>
                  </Row>
                  <Row grow="1" style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} justify="flex-end">
                    <Text fontSize={14} marginRight="4px" color={theme.textSecondary}>
                      (<FiatValue currencyAmount={e} />)
                    </Text>
                    <Text fontSize={14}>{e.toSignificant(6)}</Text>
                  </Row>
                </RowBetween>
              )
            })}
          </AutoColumn>
        </LightCard>
        <ButtonPrimary onClick={onWithdraw}>
          Withdraw {sum?.toSignificant(6)} {sum?.currency?.symbol}
        </ButtonPrimary>
      </Column>
    </ConfirmationTransactionStep>
  )
}

export default WithdrawFromStakeStep
